import { DialogFooter, FontSizes, getTheme, Icon, ILabelStyles, mergeStyleSets, Modal, PrimaryButton, Label } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Coachmark from '../../components/controls/Coachmark';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { itemData } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';
import { filterForm } from '../operation/thunks/filterThunk';

export const IsPrivacyAccepted = createAsyncThunk(
    'item/InsuredAcceptedPrivacy',
    async (args: { token: string | undefined, page: any, insuredID: any }) => {
        const response = await client.post("QuoteManagement", { form: { insuredID: args.insuredID }, page: args.page }, 'InsuredAcceptedPrivacy', args.token)
        return response
    }
)
const DownloadZip = createAsyncThunk(
    'item/DownloadInsuredNotAcceptedPrivacies',
    async (args: { token: string | undefined, page: any, id: any }) => {
        const response = await client.downloadPost("QuoteManagement", { form: { id: args.id, fileName: "privacyNonAccettate.zip" }, page: args.page }, 'DownloadInsuredNotAcceptedPrivacies', undefined, args.token)
        return response
    }
)
export const HealthFacilityAcceptsInsuredsPrivacy = createAsyncThunk(
    'item/HealthFacilityAcceptsInsuredsPrivacy',
    async (args: { token: string | undefined, page: any, insuredID: any }) => {
        const response = await client.post("QuoteManagement", { form: { insuredID: args.insuredID }, page: args.page }, 'HealthFacilityAcceptsInsuredsPrivacy', args.token)
        return response
    }
)

function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '40vw'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.openSans,
            fontSize: FontSizes.xxLargePlus,
            fontVariant: 'small-caps',
            fontWeight: 500,
            lineHeight: 44,
            paddingLeft: 20,
            paddingTop: 10,
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "justify",
        whiteSpace: "pre-wrap"
    }
}

export default () => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [didDownload, { setTrue: downloaded }] = useBoolean(false);

    const roles = useSecurityRoles()
    const isHF: boolean = roles.isHFMaster || roles.isHFAdmin || roles.isHFUser || roles.isHFOperator;
    const credential = useSelector(security)?.credential

    const [privacyAccepted, setPrivacyAccepted] = React.useState<boolean | undefined>(undefined)

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === "QuoteManagement")?.value
    const page = dItem?.metadata?.page

    const fForm = useSelector(filterForm)
    const form = fForm.find(i => i.key === "QuoteManagement")?.value

    React.useEffect(() => {
        if (isHF) {
            dispatch(IsPrivacyAccepted(
                {
                    token: credential.token,
                    page,
                    insuredID: form?.insuredID
                }))
                .then((response) => {
                    setPrivacyAccepted(response.payload.data)
                })
        }
    }, [isHF, page])

    React.useEffect(() => {
        privacyAccepted === false ? showModal() : hideModal()
    }, [privacyAccepted])

    React.useEffect(() => {
        timeout(1000).then(() => {
            if (privacyAccepted === false) {
                showCoachmark()
            }
        })
    }, [privacyAccepted])



    const downloadFile = () => {
        const args = {
            token: credential.token,
            page,
            id: dItem?.data?.id
        }

        dispatch(DownloadZip(args))
            .then(() => downloaded())
    }
    const acceptPrivacy = () => {
        dispatch(HealthFacilityAcceptsInsuredsPrivacy({
            token: credential.token,
            page,
            insuredID: form?.insuredID
        }))
            .then(hideModal)
            .then(hideCoachmark)
    }

    const [coachmarkVisible, { setTrue: showCoachmark, setFalse: hideCoachmark }] = useBoolean(false);

    return (
        <>
            {coachmarkVisible && privacyAccepted === false && !didDownload && (
                <Coachmark
                    target={"privacy_non_accettate"}
                    onDismiss={hideCoachmark}
                    title="Accettare la privacy"
                    content={<>per poter accettare la privacy, <br />si prega di scaricare questo file zip, <br /> premendo <Icon iconName="Download" /></>}
                    isWide={false} />
            )}
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} isBlocking={true} containerClassName={contentStyles.container} ignoreExternalFocusing
                onDismiss={(ev) => {
                    if (privacyAccepted === false)
                        ev?.preventDefault()
                    else {
                        hideModal()
                    }
                }}>
                <div className={contentStyles.header}>
                    <span id={"InformativaPrivacy"}>{"Privacy non accettata"}</span>
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <>
                            <Row>
                                <Label styles={noDataFoundLabel}>L'assistito collegato a questo preventivo non ha accettato la privacy.<br /><br />
                                    Per poter procedere, <br />
                                    si prega di scaricare lo zip contentente tutti i file di privacy non accettati,<br />
                                    premendo l'apposito pulsante </Label>
                            </Row>
                            <Row>
                                <Text
                                    onChange={() => { }}
                                    className="ms-sm11"
                                    label=""
                                    name=""
                                    type="plainWithIcon"
                                    value="Privacy non accettate"
                                    readOnly
                                    onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile()} style={{ cursor: "pointer", background: "transparent" }} id={"privacy_non_accettate"} /></> }} />
                            </Row>
                            <div style={{ paddingBottom: 8 }}></div>
                        </>
                    </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={acceptPrivacy} text="accetta privacy" disabled={!didDownload} />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}
