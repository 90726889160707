import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Branca' fieldName='branchID' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column2' name='Prestazioni' fieldName='healthServiceID' minWidth={200} align='left' />
                <DataColumn kid='column3' name='Frequenza Mensile' fieldName='monthlyFrequency' minWidth={110} maxWidth={110} align='right' />
                <DataColumn kid='column4' name='Numero di Volte' fieldName='numberOfTimes' minWidth={110} maxWidth={110} align='right' />
                <DataColumn kid='column5' name='Importo' fieldName='roof' minWidth={70} maxWidth={70} align='right' />
                <DataColumn kid='column6' name='Percentuale Massima di Erosione' fieldName='maxErosionPercentage' minWidth={210} maxWidth={210} align='right' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
