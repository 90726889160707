import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/';
import { useDispatch, useSelector } from 'react-redux';
import { ModalityType } from '../../../defs/modalities';
import { Permission } from '../../../defs/permission';
import { setOperation } from '../../../features/operation/operationSlice';
import { filterForm, filterValidation } from '../../../features/operation/thunks/filterThunk';
import { resultDownload } from '../../../features/operation/thunks/resultThunk';
import { setResponse } from '../../../features/response/responseSlice';
import { security, units } from '../../../features/security/thunks/securityThunk';
import { useSecurityRoles } from '../../../hooks/useSecurityRoles';
import { isLocked, lock, unlock } from '../../../features/operation/lockSlice';
import { AppDispatch } from '../../../app/store';
import { useLocation } from 'react-router';

export const ModalityToolbar = (props: { type: ModalityType, controller: string, reloadResult?: boolean, noResult?: boolean }) => {
    const location = useLocation()

    const dispatch: AppDispatch = useDispatch();
    const unitsContext = useSelector(units)
    const credential = useSelector(security)?.credential

    const fForm = useSelector(filterForm)
    const formF = fForm.find(i => i.key === props.controller)?.value

    const validation = useSelector(filterValidation)

    const roles = useSecurityRoles()
    const isONHC: boolean = roles.isONHCAccount || roles.isONHCAdmin || roles.isONHCMaster || roles.isONHCOperator;

    const _isLocked = useSelector(isLocked)

    const hasPermission = (permission: Permission): boolean => {
        var permissions = unitsContext?.units.permissions
        if (!permissions) return false

        var controllerPermissions = permissions[props.controller.toLowerCase()]
        if (!controllerPermissions) return false;

        if (!controllerPermissions.find((cp: Permission) => cp == permission)) return false;

        return true
    }


    const newItem: ICommandBarItemProps = {
        key: 'newItem',
        text: 'nuovo',
        iconOnly: true,
        iconProps: { iconName: 'CalculatorAddition' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()

            const mandatory = validation.find(v => v.key === props.controller)?.value.mandatory
            if (!mandatory) {
                dispatch(setResponse("Impossibile procedere. Compilare tutti i dati richiesti.", 5, true))
                return;
            }

            dispatch(lock())
            dispatch(setOperation("new", props.controller))
        },
        onRender: hasPermission(Permission.Create) ? undefined : () => { return <></> }
    };
    const clearFilter: ICommandBarItemProps = {
        key: 'clearFilter',
        text: 'pulisci',
        iconOnly: true,
        iconProps: { iconName: 'ClearFilter' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("reset", props.controller))
        },

    };
    const applyFilter: ICommandBarItemProps = {
        key: 'applyFilter',
        text: 'ricerca',
        iconOnly: true,
        iconProps: { iconName: 'Search' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()

            const mandatory = validation.find(v => v.key === props.controller)?.value.mandatory
            if (!mandatory) {
                dispatch(setResponse("Impossibile procedere. Compilare tutti i dati richiesti.", 5, true))
                return;
            }

            dispatch(lock())
            dispatch(setOperation("search", props.controller))
        },
    };
    const modifyFilter: ICommandBarItemProps = {
        key: 'modifyFilter',
        text: 'modifica ricerca',
        iconOnly: true,
        iconProps: { iconName: 'Filter' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("filter", props.controller))
        },
    };
    const viewResult: ICommandBarItemProps = {
        key: 'viewResult',
        text: 'visualizza risultati',
        iconOnly: true,
        iconProps: { iconName: 'List' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("search", props.controller))
        },
    };
    const reloadResult: ICommandBarItemProps = {
        key: 'reloadResult',
        text: 'ricarica risultati',
        iconOnly: true,
        iconProps: { iconName: 'Refresh' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("search", props.controller))
        },
    };
    const downloadResult: ICommandBarItemProps = {
        key: 'downloadResult',
        text: 'scarica risultati di ricerca',
        iconOnly: true,
        iconProps: { iconName: 'Installation' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(resultDownload({ controller: props.controller, body: { form: { ...formF }, page: {} }, token: credential.token }))
                .then(() => dispatch(unlock()))
        },
    };

    switch (props.type) {
        case "item":
        case "new":
            return (
                <CommandBar className="leap-commandbar" items={[]} farItems={[modifyFilter, viewResult]} />
            );
        case "result":
            const resultFarItems = [newItem, modifyFilter]

            if (isONHC || (roles.isHF && location.pathname === '/AccountingSituation/' )) {
                resultFarItems.unshift(downloadResult)
            }

            if (props.reloadResult) {
                resultFarItems.push(reloadResult)
            }

            return (
                <CommandBar className="leap-commandbar" items={[]} farItems={resultFarItems} />
            );
        case "filter":
            const filterFarItems = [newItem, clearFilter]
            if (!props.noResult) {
                filterFarItems.push(applyFilter)
            }

            return (
                <CommandBar className="leap-commandbar" items={[]} farItems={filterFarItems} />
            );
        default:
            return (
                <></>
            );
    }
}