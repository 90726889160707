import * as React from 'react';
import DateRange from '../../components/controls/DateRange';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import SearchBox from '../../components/controls/SearchBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthFacility = useLeapData("healthFacility", "struttura sanitaria", page, "healthFacilties")
    const startDate = useLeapData("startDate", "data inizio valitità", page)
    const endDate = useLeapData("endDate", "data fine valitità", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...healthFacility} className="ms-sm10" required={page.modality === "new"} disabled={page.modality === "item"} />
                    </Row>
                    <Row>
                        <DateRange
                            dateMin={{ ...startDate, className: "ms-sm3", required: true }}
                            separator={{ className: "ms-sm1" }}
                            dateMax={{ ...endDate, className: "ms-sm3" }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
