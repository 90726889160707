import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import InsuredFileModal from '../features/mydocs/InsuredFileModal';
import MyDocsResultList from '../features/mydocs/MyDocsResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import OdontoAndCompanyList from '../features/mydocs/OdontoAndCompanyFileList';
import PlanDocumentsFileList from '../features/mydocs/PlanDocumentsFileList';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import PlanFileList from '../features/mydocs/PlanFileList';

export default () => {

    const controller = "MyDocs"

    const roles = useSecurityRoles()
    const isInsuredUser: boolean = roles.isInsuredUser
    const page = useLeapState(controller, roles.role !== undefined, isInsuredUser ? "result" : "filter")

    const insuredFileController = "InsuredFile"
    const planFile = "PlanFile"
    const insured = useLeapData("insured", "assistito", page, "insured")
    const code = useLeapData("code", "codice", page, "insured")
    const fiscalCode = useLeapData("fiscalCode", "codice fiscale", page, "insured")
    const lastName = useLeapData("lastName", "cognome", page)
    const firstName = useLeapData("firstName", "nome", page)

    const showPlanDocumentsFileList = Object.keys(page.item.state?.lists?.PlanDocumentsFileList ?? {}).length > 0;
    const showPlanFileList = Object.keys(page.item.state?.lists?.PlanFileList ?? {}).length > 0;

    return <>
        <Content title={isInsuredUser ? "I Miei Documenti" : "Documenti Assistito"} controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...insured} className="ms-sm6" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <MyDocsResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" readOnly />
                            <Text {...fiscalCode} className="ms-sm3" readOnly />
                            <Text {...lastName} className="ms-sm3" readOnly />
                            <Text {...firstName} className="ms-sm3" readOnly />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="informativa privacy" modal={<InsuredFileModal state={page} controller={insuredFileController} />} controller={insuredFileController} modaltitle="Carica file firmato" state={page} modalwidth="40vw">
                        <Row>
                            <OdontoAndCompanyList items={page.item.state?.lists.OdontonetworkFileList} modal={<InsuredFileModal state={page} controller={insuredFileController} />} controller={insuredFileController} modaltitle="Carica file firmato" modalwidth="40vw" state={page} />
                        </Row>
                    </Section>)}
                    {page.modality === "item" && showPlanDocumentsFileList && (<Section title="informativa privacy di piano sanitario" modal={<InsuredFileModal state={page} controller={insuredFileController} />} controller={insuredFileController} modaltitle="Carica file firmato" state={page} modalwidth="40vw">
                        <Row>
                            <PlanDocumentsFileList items={page.item.state?.lists.PlanDocumentsFileList} modal={<InsuredFileModal state={page} controller={insuredFileController} />} controller={insuredFileController} modaltitle="Carica file firmato" modalwidth="40vw" state={page} />
                        </Row>
                    </Section>)}
                    {page.modality === "item" && showPlanFileList && (<Section title="documenti di piano sanitario">
                        <Row>
                            <PlanFileList items={page.item.state?.lists.PlanFileList} controller={planFile} state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
}
