import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import MultiBox from '../../components/controls/MultiBox';
import SearchBox from '../../components/controls/SearchBox';
import Separator from '../../components/controls/grid/Separator';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const leap_HealthFacilityDoctorTypeID = useLeapData("leap_HealthFacilityDoctorTypeID", "tipo", page, "doctorsTypeOption")
    const nationID = useLeapData("nationID", "nazione iscrizione albo", page, "nations")
    const provinceID = useLeapData("provinceID", "provincia iscrizione albo", page, "provinces")
    const numberRegistration = useLeapData("numberRegistration", "n. iscrizione albo", page)
    const leap_HealthFacilityDoctorMemberScientificAccademiesID = useLeapData("leap_HealthFacilityDoctorMemberScientificAccademiesID", "socio accademie scientifiche", page, "memberScientificAccademiesOption")
    const scientificAccademyID = useLeapData("scientificAccademyID", "accademie scientifiche", page, "ScAc")
    const otherAccademy = useLeapData("otherAccademy", "altro", page)

    const isOtherSelected: boolean = page.item?.values?.scientificAccademyID?.value.includes("9999");

    const roles = useSecurityRoles()
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFOperator: boolean = roles.isHFOperator;
    const isInsuredUser: boolean = roles.isInsuredUser;

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...lastname} className="ms-sm4" required />
                        <Separator className=""></Separator>
                        <Text {...firstname} className="ms-sm4" required />
                        <Separator className=""></Separator>
                        <Option {...leap_HealthFacilityDoctorTypeID} className="ms-sm1" type="horizontal" required />
                    </Row>
                    <Row>
                        <SearchBox {...nationID} className="ms-sm4" />
                        <Separator className=""></Separator>
                        <SearchBox {...provinceID} className="ms-sm4" />
                        <Separator className=""></Separator>
                        <Text {...numberRegistration} className="ms-sm3" required />
                    </Row>

                    <Row>
                        <Option {...leap_HealthFacilityDoctorMemberScientificAccademiesID} className="ms-sm1" type="horizontal" required />
                    </Row>
                    <Row hidden={page.item?.values?.leap_HealthFacilityDoctorMemberScientificAccademiesID?.value !== "1"}>
                        <MultiBox {...scientificAccademyID} className="ms-sm6" titleType="text" required={page.item?.values?.leap_HealthFacilityDoctorMemberScientificAccademiesID?.value === "1"} />
                        <Row hidden={!isOtherSelected} >
                            <Text {...otherAccademy} className="ms-sm6" hidden={isInsuredUser} readOnly={isHFAdmin || isHFOperator || isInsuredUser} />
                        </Row>
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
