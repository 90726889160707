import { DefaultButton, DialogFooter, FontSizes, FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Separator from '../../components/controls/grid/Separator';
import MultiBox from '../../components/controls/MultiBox';
import Option from '../../components/controls/Option';
import MultiSearchBox from '../../components/controls/MultiSearchBox';
import Text from '../../components/controls/Text';
import Theme from '../../components/themes/Theme';
import { useDatagridOperation } from '../../hooks/useDatagridOperation';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import { setOperation } from '../operation/operationSlice';
import { itemForm, itemValidation } from '../operation/thunks/itemThunk';
import { resetResponse, setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';

type Props = {
    hidden?: boolean
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }
    const credential = useSelector(security)?.credential

    const controller = "InitialComunication"
    const datagridOperation = useDatagridOperation(controller, credential.token)
    const iForm = useSelector(itemForm)
    const page = useLeapState(controller, false)
    const validation = useSelector(itemValidation)

    const openModal = () => {
        const parentItemForm = iForm.find(i => i.key === "Insured")?.value
        let parentItemPage: any = {}

        let body = {
            form: {},
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }

        datagridOperation.new(body)
            .then(() => showModal())
    }

    const Send = () => {
        const gridItemForm = iForm.find(i => i.key === controller)?.value
        const parentItemForm = iForm.find(i => i.key === "Insured")?.value
        let parentItemPage: any = {}

        let body = {
            form: gridItemForm,
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }

        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere al salvataggio.", 5, true))
            return;
        }

        datagridOperation.create(body)
            .then((response) => {
                if (response.payload.response.level === 4) {
                    hideModal()
                    return true
                }
                else {
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(setOperation("reload", "Insured"))
                }
            })
    }

    const email = useLeapData("email", "email", page)
    const files = useLeapData("planFiles", "documenti allegati", page, "files")
    const sendHF = useLeapData("sendHF", "invio strutture sanitarie", page, "yesno")
    const management = useLeapData("management", "tipo gestione", page, "management")
    const province = useLeapData("province", "provincia", page, "provinces")

    const hasBoth: boolean = page?.item?.state?.data?.hasBoth;
    const sendHFSelected: boolean = page.item?.values?.sendHF?.value === "1";

    return (
        <>
            <div className="ms-Grid-col ms-sm3">
                <PrimaryButton text="invio documenti e strutture sanitarie" onClick={() => openModal()} style={props.hidden ? { display: "none" } : { marginTop: "20px" }} />
            </div>
            <Modal titleAriaId={"initialComunication"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span id={"invio documenti e strutture sanitarie"}>{"invio documenti e strutture sanitarie"}</span>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <Row>
                            <Text {...email} className="ms-sm4" maxlength={255} required />
                        </Row>
                        <Row>
                            <MultiBox {...files} className="ms-sm10" titleType="text" />
                        </Row>
                        <Row>
                            <Option {...sendHF} className="ms-sm2" type="horizontal" required />
                            {hasBoth && sendHFSelected && <Option {...management} className="ms-sm2" type="horizontal" required={isModalOpen && hasBoth && sendHFSelected} />}
                        </Row>
                        <Row hidden={!sendHFSelected}>
                            <MultiSearchBox {...province} className="ms-sm7" required={isModalOpen && page?.item?.values?.sendHF?.value === "1"} hidden={!sendHFSelected} />
                        </Row>
                    </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={Send} text="invia documenti e strutture sanitarie" />
                        <DefaultButton onClick={onCancel} text="Annulla" />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}
