import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import DateRange from '../../components/controls/DateRange';
import FileUpload from '../../components/controls/FileUpload';

import { PrimaryButton } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { itemDownload } from '../operation/thunks/itemThunk';
import { currentModality } from '../modality/modalitySlice';
import { filterForm } from '../operation/thunks/filterThunk';
import { security } from '../security/thunks/securityThunk';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const dispatch = useDispatch()
    const controller = props.controller
    const modality = useSelector(currentModality)
    const fForm = useSelector(filterForm)
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const fileDescription = useLeapData("fileDescription", "descrizione", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const fileID = useLeapData("fileID", "fileID", page)
    const note = useLeapData("note", "note", page)

    const channel = useLeapData("privacyChannelType", "modalità d'uso", page, "indirectManagementTypeOption")

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileID,
            fileName: page?.item?.state?.data?.fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...fileDescription} className="ms-sm12" maxlength={250} required />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm4", required: true }} dateMax={{ ...endDate, className: "ms-sm4" }} />
                        <Option{...channel} type="horizontal" className="ms-sm4" required />
                    </Row>
                    <Row>
                        <Text {...note} type="multiline" className="ms-sm12" maxlength={1000} />
                    </Row>

                    {(page?.item?.state?.data?.fileID === undefined || page?.item?.state?.data?.fileID === null) && <>
                        <Row>
                            <FileUpload {...fileID} className="ms-sm8" label="upload file" controller={props.controller} required />
                        </Row>
                    </>}
                    {(page?.item?.state?.data?.fileID !== undefined && page?.item?.state?.data?.fileID !== null) && <>
                        <Row>
                            <PrimaryButton text="download file" onClick={downloadFile} style={{ marginTop: "20px" }} />
                        </Row>
                    </>}
                </Section>
            </Grid>
        </>
    );
}
