import { FontSizes, IImageStyles, Image, ImageFit, PrimaryButton, Text as TextTitle, Dialog, DialogFooter, FontWeights, DialogType, IDialogProps } from '@fluentui/react/';
import * as React from 'react';
import { useRef, useState } from 'react';
import Text from '../components/controls/Text';
import { unitTheme } from '../components/themes/Fonts';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecuritydOperation } from '../hooks/useSecurityOperation';
import { useBoolean } from '@uifabric/react-hooks';

export default () => {
    const controller = "Security"
    const page = useLeapState(controller)
    const securityOperation = useSecuritydOperation();

    const username = useLeapData("username", "nome utente", page)
    const email = useLeapData("email", "email", page)

    const onResetPassword = () => {
        setButtonDisabled(true)
        securityOperation.forgotPassword(page?.filter?.values.username?.value, page?.filter?.values.email?.value, undefined)
            .then((response) => {
                setButtonDisabled(false)
                toggleHideMessageBox();
            })
    }


    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione completata",
            subText: "La password temporanea e\' stata generata e inoltrata nel caso in cui l'email e il nome utente corrispondessero a quelle di un utente presente a sistema.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    const buttonRef: any = useRef(null)
    const handleKeyboard = (event: any) => {
        if (event.key === "Enter") {
            buttonRef.current.click()
        }
    }
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);
        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [])

    const gotoLogin = <><TextTitle style={{ fontFamily: FontFaces.montserrat, fontWeight: 500 }}>Vuoi accedere? &nbsp;</TextTitle ><TextTitle nowrap onClick={() => { document.location.href = '/login/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Clicca qui</TextTitle></>

    const mainContainerStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: 32
    }
    const logoExternalContainerStyle: React.CSSProperties = {
        backgroundColor: process.env.REACT_APP_STYLE_BODY_BACKGROUNDCOLOR,
        borderRadius: "50%",
        padding: 16,
        zIndex: 1
    }
    const logoInternalContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.white,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: "50%",
        zIndex: 1
    }
    const formContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.themeLight,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: "128px 24px 8px",
        position: "relative",
        top: -96,
    }

    const buttonContainerStyle: React.CSSProperties = {
        marginTop: 32,
        textAlign: "center"
    }

    const buttonStyle: React.CSSProperties = {
        width: 256
    }

    const textContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }

    return <>
        <Dialog {...dialogProps} onDismiss={() => { document.location.href = '/login/'; }}>
            <DialogFooter >
                <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <div style={mainContainerStyle}>
            <div style={logoExternalContainerStyle}>
                <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                    <Image src="/assets/media/images/icon.png"
                        draggable={false}
                        style={{
                            margin: 17,
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }} />
                </div>
            </div>
            <div className="splashForm ms-depth-16" style={formContainerStyle} >
                <Text {...username} className="ms-sm12" required />
                <Text {...email} className="ms-sm12" required />
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, fontFamily: FontFaces.montserrat, fontSize: 15, marginTop: 24, textAlign: "justify" }}>
                    <span style={{ display: "inline-block" }}>
                        Per resettare la password,
                        si prega di inserire il nome utente e l'email.
                    </span>
                    <span style={{ display: "inline-block", marginTop: 8 }}>
                        Se corrisponderanno, verrá inviata una password temporanea
                        all'email associata all'utente.
                    </span>
                </div>
                <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                    <PrimaryButton style={buttonStyle} onClick={onResetPassword} text="resetta password" elementRef={buttonRef} disabled={buttonDisabled} />
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 40 }}>
                    {gotoLogin}
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 32 }}>
                    <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                </div>
            </div>
        </div>
    </>
    /*
    return <>
        <Dialog {...dialogProps} onDismiss={() => { document.location.href = '/login/'; }}>
            <DialogFooter >
                <PrimaryButton onClick={() => { toggleHideMessageBox(); document.location.href = '/login/'; }} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", margin: "30px auto 10px", padding: "10px 20px 0", height: 410, width: 500 }} >
            <div style={{ float: "left" }}>
                <Image styles={imageStyles} src="/assets/media/images/onhc-logo.png" imageFit={ImageFit.contain} />
            </div>
            <div style={{ float: "left" }}>
                <TextTitle style={titleStyles} theme={unitTheme}>Password Dimenticata</TextTitle>
            </div>
            <div className='ms-Grid-col ms-sm12' style={{ clear: "both", }}>
                Per resettare la password,<br />
                si prega di inserire il nome utente e l'email.<br /><br />

                Se corrisponderanno, verr&aacute; inviata una password temporanea<br />
                all'email associata all'utente.<br />
            </div>
            <div style={{ clear: "both" }}>
                <Text {...username} className="ms-sm12" required />
                <Text {...email} className="ms-sm12" required />
            </div>
            <div>
                <div style={{ display: 'inline-block', textAlign: "center", width: '66%' }}>
                </div>
                <div style={{ display: 'inline-block', textAlign: "left", width: '50%', paddingLeft: 10 }}>
                    <TextTitle nowrap block onClick={() => { document.location.href = '/login/' }} style={{ color: "#4B858E", textDecorationLine: 'underline', cursor: "pointer" }}>
                        torna al login
                    </TextTitle>
                </div>
                <div style={{ display: 'inline-block', textAlign: "right", width: '45%', paddingLeft: 9 }}>
                    <PrimaryButton onClick={onResetPassword} text="resetta password" elementRef={buttonRef} disabled={buttonDisabled} />
                </div>
            </div>
        </div>
    </>
    */
}