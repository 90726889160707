import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
    readOnly?: boolean
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} className={"ms-Grid-col ms-sm6"}>
                <DataColumn kid='column1' name='Giorno' fieldName='day' minWidth={80} align='left' />
                <DataColumn kid='column2' name='Inizio Mattino' fieldName='morningStartTime' minWidth={100} maxWidth={120} align='center' />
                <DataColumn kid='column3' name='Fine Mattino' fieldName='morningEndTime' minWidth={100} maxWidth={120} align='center' />
                <DataColumn kid='column4' name='Inizio Pomeriggio' fieldName='afternoonStartTime' minWidth={100} maxWidth={120} align='center' />
                <DataColumn kid='column5' name='Fine Pomeriggio' fieldName='afternoonEndTime' minWidth={100} maxWidth={120} align='center' />
                {!props.readOnly && <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            </DataList>
        </>
    );
}
