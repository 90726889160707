import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return <>
        <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} >
            <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
            <DataColumn kid='column1' name='Link' fieldName='link' minWidth={250} maxWidth={250} align='left' />
            <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={250} align='left' />
            <DataColumn kid='column4' name='Valore quota' fieldName='membershipFee' minWidth={100} maxWidth={100} align='right' />
            <DataColumn kid='column5' name='Durata' fieldName='duration' minWidth={100} maxWidth={100} align='right' />
            <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
        </DataList>
    </>
}
