import * as React from 'react';

import { IPersonaStyles, Persona, PersonaSize } from '@fluentui/react/lib/Persona';

import { useSelector } from 'react-redux';
import { security } from '../../features/security/thunks/securityThunk';

import Theme from '../themes/Theme';
import { FontFaces } from '../../defs/fonts';

import { useSecuritydOperation } from '../../hooks/useSecurityOperation';
import { IPersonaProps, ContextualMenu, IContextualMenuItem, DirectionalHint, FontIcon } from '@fluentui/react';

const personaStyles: Partial<IPersonaStyles> = {
    root: {
        fontFamily: FontFaces.montserrat,
        paddingLeft: 10,
        paddingTop: 4,
        minWidth: Theme.leap.header.account.minWidth,
    },
    primaryText: {
        color: Theme.leap.header.account.color,
        fontWeight: 500
    },
    secondaryText: {
        color: Theme.leap.header.account.color,
    },
};

export default () => {
    const securityContext = useSelector(security)
    const securityOperation = useSecuritydOperation();

    const acc: IPersonaProps = {
        initialsColor: Theme.leap.header.account.initialColor,
        text: securityContext?.credential?.username,
        secondaryText: securityContext?.credential?.rolename,
        size: PersonaSize.size32,
        showSecondaryText: true,
    }

    const onLogout = () => {
        securityOperation.logout(securityContext?.credential?.token)
            .then(() => {
                document.location.href = '/login/'
            })
    }

    const onChangePassword = () => {
        document.location.href = '/changepassword/'
    }

    const personaRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = () => {
        setShowContextualMenu(true);
    }
    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'changePassword',
            text: 'cambia password',
            iconProps: { iconName: "PermissionsSolid" },
            onClick: () => onChangePassword(),
        },
        {
            key: 'logout',
            text: 'esci',
            iconProps: { iconName: "SignOut" },
            onClick: () => onLogout(),
        },
    ];

    return <>
        {securityContext?.credential?.username && <>
            <div style={{
                cursor: "pointer",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none"
            }}>
                <span ref={personaRef} style={{ position: "relative", left: 6, top: 30 }}></span>
                <Persona {...acc} onClick={onShowContextualMenu} styles={personaStyles} />
            </div>

            <ContextualMenu
                directionalHint={DirectionalHint.bottomLeftEdge}
                items={menuItems}
                hidden={!showContextualMenu}
                target={personaRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </>}
    </>
}