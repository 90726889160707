import * as React from 'react';
import { useDispatch } from 'react-redux';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { setOperation } from '../operation/operationSlice';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }
    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column3' name='Stato di importazione' fieldName='importState' minWidth={200} maxWidth={250} align='center' />
                <DataColumn kid='column4' name='Tipo di importazione' fieldName='importType' minWidth={200} maxWidth={250} align='center' />
                <DataColumn kid='column5' name='Data di creazione' fieldName='createDate' minWidth={200} maxWidth={250} align='center' />
                <DataColumn kid='column6' name='Nome file' fieldName='fileName' minWidth={200} maxWidth={250} align='left' />
                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
