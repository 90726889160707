import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';
import UnitResultList from '../features/unit/UnitResultList';
import UnitPrivilegeList from '../features/unit/UnitPrivilegeList';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';
import UnitPermissionModal from '../features/unit/UnitPermissionModal'
import UnitPermissionModalEdit from '../features/unit/UnitPermissionModalEdit'


export default () => {
    const controller = "Unit";
    const unitPermissionModal = "UnitPermissionModal";
    const page = useLeapState(controller)

    const name = useLeapData("name", "name", page)
    const context = useLeapData("context", "context", page)

    return <>
        <Content title="Funzioni men&ugrave;" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm4" maxlength={50} />
                            <Text {...context} className="ms-sm4" maxlength={50} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <UnitResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...name} className="ms-sm4" required maxlength={50} />
                            <Text {...context} className="ms-sm4" required maxlength={50} />
                        </Row>
                    </Section>
                    {page.modality === "item" && (<Section title="Elenco Privilegi" toolbar="grid" modal={<UnitPermissionModal state={page} controller={unitPermissionModal} />} controller={unitPermissionModal} modalwidth="600px" modaltitle="Inserisci Privilegio" state={page} >
                        <Row>
                            <UnitPrivilegeList items={page.item.state?.lists.Privileges} modal={<UnitPermissionModalEdit state={page} controller={unitPermissionModal} />} controller={unitPermissionModal} modalwidth="600px" modaltitle="Modifica Privilegio" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
