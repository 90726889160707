import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller : any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modalController={props.controller}>
                <DataColumn kid='column1' name='Descrizione' fieldName='description' minWidth={200} align='left' />
                <DataColumn kid='column4' name='Data Invio' fieldName='dispatchDate' minWidth={120} maxWidth={150} align='center' />
                <DataColumn kid='columnDL' name='' fieldName='' minWidth={10} maxWidth={10} align='center' type='download' />
            </DataList>
        </>
    );
}
