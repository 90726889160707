import { Guid } from "guid-typescript";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { FontFaces } from '../../defs/fonts';
import { lock, unlock } from '../../features/operation/lockSlice';
import { itemData, itemUpload, itemDownload } from '../../features/operation/thunks/itemThunk';
import { setResponse } from '../../features/response/responseSlice';
import { security } from '../../features/security/thunks/securityThunk';

import { FontSizes, Icon, IRawStyle, ITextFieldProps, Label, TextField } from '@fluentui/react/';

import Theme from '../themes/Theme';

const IconRawStyle: IRawStyle = {
    cursor: "pointer",
    display: "block",
    fontSize: 16,
    paddingRight: 9
}

type Props = {
    controller: string,
    name: string,
    label: string,
    className: string,
    required?: boolean,
    readOnly?: boolean,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void
}

const GetTextFieldProps = (props: Props, file: File | undefined, onRenderSuffix: () => JSX.Element): Partial<ITextFieldProps> => {
    return {
        label: props.label,
        readOnly: true,
        required: props.required,
        value: file?.name,
        autoComplete: "off",
        onRenderSuffix: onRenderSuffix,
        styles: {
            field: {
                cursor: "default"
            },
            fieldGroup: {
                cursor: "default"
            },
            subComponentStyles: {
                label: {
                    root: {
                        fontFamily: FontFaces.montserrat,
                        fontSize: FontSizes.medium,
                        fontWeight: 500,
                        paddingBottom: 2,
                        paddingLeft: 4,
                    },
                },
            },
            suffix: {
                backgroundColor: "unset",
                cursor: "default",
                padding: "1px 0 0",
                selectors: {
                    "label": {
                        color: "unset",
                        padding: 0
                    },
                    "i": IconRawStyle
                }
            },
        }
    };
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const iForm = useSelector(itemData)

    const fileID = iForm.find(i => i.key === props.controller)?.value?.data?.[props.name + "ID"]
    const fileName = iForm.find(i => i.key === props.controller)?.value?.data?.[props.name + "Name"]

    const [fileObject, setFileObject] = React.useState({ id: fileID, name: fileName })

    const [file, setFile] = useState<File | undefined>(undefined)
    const [content, setContent] = useState(undefined as any)
    const [uid] = useState(Guid.create().toString())

    useEffect(() => {
        if (props.onInit) {
            props.onInit(props.name, fileID, props.required)
            props.onInit(props.name + "ID", fileID, props.required)
            props.onInit(props.name + "Name", fileName, props.required)
        }
    }, [fileObject, props.required])

    useEffect(() => {
        if (props.onChange) {
            props.onChange(props.name, fileObject.id, props.required)
            props.onChange(props.name + "ID", fileObject.id, props.required)
            props.onChange(props.name + "Name", fileObject.name, props.required)
        }
    }, [fileObject])

    useEffect(() => {
        setFileObject({ id: fileID, name: fileName })
    }, [fileID, fileName])

    const onUpload = (e: any) => {
        const fileReader = new FileReader();

        fileReader.onloadend = () => setContent(fileReader.result)
        fileReader.readAsArrayBuffer(e.target.files[0]);

        setFile(e.target.files[0]);
    }

    const onClickCancel = () => {
        setContent(undefined);
        setFile(undefined)
        setFileObject({ id: undefined, name: undefined })
    }

    const executeUpload = () => {
        dispatch(lock())

        const args = {
            controller: props.controller,
            lookup: props.controller,
            name: file?.name ?? "",
            type: file?.type ?? "",
            file: Array.from(new Uint8Array(content)),
            idCaller: iForm.find(i => i.key === props.controller)?.value.data.id,
            token: credential?.token
        }

        dispatch(itemUpload(args))
            .then((response: any) => { dispatch(unlock()); return response; })
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then((response: any) => {
                setFileObject({ id: response.payload.data, name: file?.name })
            })
            .then(() => {
                setContent(undefined)
            })
            .catch(() => dispatch(unlock()))
    }

    useEffect(() => {
        file && content && executeUpload()
    }, [file, content])

    const onClickDownload = () => {
        const args = {
            controller: props.controller,
            fileID: Number.parseInt(fileID),
            fileName: fileName,
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    const onUploderRenderSuffix = () => {
        if (!!fileObject.id) {
            return <>
                <Icon iconName="Cancel" onClick={onClickCancel} style={{ background: "transparent", color: Theme.fluent.palette.redDark, cursor: "pointer" }} />
            </>
        }
        return <Label htmlFor={uid} style={{ color: Theme.fluent.palette.themePrimary, marginTop: 2 }}><Icon iconName="OpenFolderHorizontal" style={{ background: "transparent", color: Theme.fluent.palette.themePrimary, cursor: "pointer" }} /></Label>
    }

    const onDownloaderRenderSuffix = () => {
        return <>
            {!props.readOnly && <Icon iconName="Cancel" onClick={onClickCancel} style={{ background: "transparent", color: Theme.fluent.palette.redDark, cursor: "pointer" }} />}
            <Icon iconName="Download" onClick={onClickDownload} style={{ background: "transparent", color: Theme.fluent.palette.themePrimary, cursor: "pointer" }} />
        </>
    }

    const uploaderProps: Partial<ITextFieldProps> = GetTextFieldProps(props, file, onUploderRenderSuffix)
    const downloaderprops: Partial<ITextFieldProps> = GetTextFieldProps(props, file, onDownloaderRenderSuffix)

    return <>
        {!!!fileObject.name && <>
            <div className={"ms-Grid-col " + props.className} >
                <TextField {...uploaderProps} />
                <input id={uid} type="file" onChange={onUpload} hidden />
            </div>
        </>}
        {!!fileObject.name && <>
            <div className={"ms-Grid-col " + props.className} >
                <TextField {...downloaderprops} value={fileObject.name} onChange={() => { }} type="plainWithIcon" />
            </div>
        </>}
    </>
}