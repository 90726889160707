import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import { security } from '../security/thunks/securityThunk';
import { itemDownload } from '../operation/thunks/itemThunk';

import { PrimaryButton } from '@fluentui/react';

import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import FileUpload from '../../components/controls/FileUpload';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const dispatch = useDispatch()
    const controller = props.controller
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const description = useLeapData("description", "descrizione", page)
    const reportDate = useLeapData("reportDate", "data allegato", page)
    const fileID = useLeapData("fileID", "fileID", page)

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileID,
            fileName: page?.item?.state?.data?.fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...description} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <Text {...reportDate} className="ms-sm4" type="calendar" dateRange={{ maxDate: Date().toLocaleString() }} required />
                    </Row>
                    {(page?.item?.state?.data?.fileID === undefined || page?.item?.state?.data?.fileID === null) && (
                        <Row>
                            <FileUpload {...fileID} className="ms-sm12" label="upload file" controller={props.controller} required />
                        </Row>
                    )}
                    {(page?.item?.state?.data?.fileID !== undefined && page?.item?.state?.data?.fileID !== null) && (
                        <Row>
                            <PrimaryButton text="download file" onClick={downloadFile} style={{ marginTop: "20px" }} />
                        </Row>
                    )}
                </Section>
            </Grid>
        </>
    );
}
