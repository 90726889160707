import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import DentalChart from '../components/controls/dental/DentalChart';

import StubResultList from '../features/stub/StubResultList'

export default () => {
    const controller = "Stub";
    const page = useLeapState(controller)

    const teeth = useLeapData("teeth", "dentatura", page)

    return <>
        <Content title="Dental" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <DentalChart {...teeth} value={["11", "22"]} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <StubResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
