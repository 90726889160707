import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import MultiBox from '../../components/controls/MultiBox';
import Option from '../../components/controls/Option';
import Separator from '../../components/controls/grid/Separator';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { setFormData, setFormItem } from '../operation/thunks/itemThunk';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const day = useLeapData("days", "giorno", page, "days")
    const closed = useLeapData("closed", "chiuso", page, "yesno")
    const morningStartTime = useLeapData("morningStartTime", "inizio mattino", page)
    const morningEndTime = useLeapData("morningEndTime", "fine mattino", page)
    const afternoonStartTime = useLeapData("afternoonStartTime", "inizio pomeriggio", page)
    const afternoonEndTime = useLeapData("afternoonEndTime", "fine pomeriggio", page)
    const isclosed = page.item?.values?.closed?.value === "1";
    const dispatch: AppDispatch = useDispatch();

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <MultiBox {...day} titleType="text" className="ms-sm4" required />
                        <Separator className="ms-sm3" />
                        <Option {...closed} className="ms-sm2" type="horizontal"
                            onChange={(name: any, value: any, required: any) => {
                                page.onchange(name, value, required)
                                if (value === "1") {
                                    dispatch(setFormData(props.controller, { name: "morningStartTime", value: "" }))
                                    dispatch(setFormItem(props.controller, { name: "morningStartTime", value: "" }))

                                    dispatch(setFormData(props.controller, { name: "morningEndTime", value: "" }))
                                    dispatch(setFormItem(props.controller, { name: "morningEndTime", value: "" }))

                                    dispatch(setFormData(props.controller, { name: "afternoonStartTime", value: "" }))
                                    dispatch(setFormItem(props.controller, { name: "afternoonStartTime", value: "" }))

                                    dispatch(setFormData(props.controller, { name: "afternoonEndTime", value: "" }))
                                    dispatch(setFormItem(props.controller, { name: "afternoonEndTime", value: "" }))
                                }
                            }} />
                    </Row>
                    <Row>
                        <Text {...morningStartTime} className="ms-sm4" type="time" readOnly={isclosed} />
                        <Separator className="ms-sm3" />
                        <Text {...morningEndTime} className="ms-sm4" type="time" readOnly={isclosed} />
                    </Row>
                    <Row>
                        <Text {...afternoonStartTime} className="ms-sm4" type="time" readOnly={isclosed} />
                        <Separator className="ms-sm3" />
                        <Text {...afternoonEndTime} className="ms-sm4" type="time" readOnly={isclosed} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
