export const DentalElements = [
    { key: '1', text: '1 [tutta la bocca]' },
    { key: '2', text: '2 [quadrante sup. sinistro]' },
    { key: '3', text: '3 [quadrante sup. destro]' },
    { key: '4', text: '4 [quadrante inf. sinistro]' },
    { key: '5', text: '5 [quadrante inf. destro]' },
    { key: '6', text: '6 [arcata superiore]' },
    { key: '7', text: '7 [arcata inferiore]' },

    { key: '91', text: '91 [sestante 1]' },
    { key: '92', text: '92 [sestante 2]' },
    { key: '93', text: '93 [sestante 3]' },
    { key: '94', text: '94 [sestante 4]' },
    { key: '95', text: '95 [sestante 5]' },
    { key: '96', text: '96 [sestante 6]' },

    { key: '11', text: '11' },
    { key: '12', text: '12' },
    { key: '13', text: '13' },
    { key: '14', text: '14' },
    { key: '15', text: '15' },
    { key: '16', text: '16' },
    { key: '17', text: '17' },
    { key: '18', text: '18' },
    { key: '21', text: '21' },
    { key: '22', text: '22' },
    { key: '23', text: '23' },
    { key: '24', text: '24' },
    { key: '25', text: '25' },
    { key: '26', text: '26' },
    { key: '27', text: '27' },
    { key: '28', text: '28' },
    { key: '31', text: '31' },
    { key: '32', text: '32' },
    { key: '33', text: '33' },
    { key: '34', text: '34' },
    { key: '35', text: '35' },
    { key: '36', text: '36' },
    { key: '37', text: '37' },
    { key: '38', text: '38' },
    { key: '41', text: '41' },
    { key: '42', text: '42' },
    { key: '43', text: '43' },
    { key: '44', text: '44' },
    { key: '45', text: '45' },
    { key: '46', text: '46' },
    { key: '47', text: '47' },
    { key: '48', text: '48' },

    { key: '51', text: '51' },
    { key: '52', text: '52' },
    { key: '53', text: '53' },
    { key: '54', text: '54' },
    { key: '55', text: '55' },
    { key: '61', text: '61' },
    { key: '62', text: '62' },
    { key: '63', text: '63' },
    { key: '64', text: '64' },
    { key: '65', text: '65' },
    { key: '71', text: '71' },
    { key: '72', text: '72' },
    { key: '73', text: '73' },
    { key: '74', text: '74' },
    { key: '75', text: '75' },
    { key: '81', text: '81' },
    { key: '82', text: '82' },
    { key: '83', text: '83' },
    { key: '84', text: '84' },
    { key: '85', text: '85' }
];
