import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import { IComboBoxOption } from '@fluentui/react';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import ComboBox from '../../components/controls/ComboBox';
import SearchBox from '../../components/controls/SearchBox';
import MultiBox from '../../components/controls/MultiBox';

import DentalChart from '../../components/controls/dental/DentalChart';
import { DentalElements } from '../../components/controls/dental/dentalElements';

type Props = {
    state: any,
    controller: any,
    parentController: any,
    canViewRefounds?: boolean
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branchID = useLeapData("branchID", "branca", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServices")
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)

    const amount = useLeapData("amount", "importo", page)
    const refound = useLeapData("refound", "rimborso", page)

    const items: IComboBoxOption[] = DentalElements
    const selectedTeeth = page.item.values?.dentalElement ? page.item.values.dentalElement.value : (dentalElement ? (dentalElement.value ?? []) : []);

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...branchID} className="ms-sm12" children={[{ name: "healthServiceID", lookup: "healthServices", controller: props.controller }]} />
                    </Row>
                    <Row>
                        <SearchBox {...healthServiceID} className="ms-sm12" parentController={props.parentController} required />
                    </Row>
                    <Row>
                        <MultiBox {...dentalElement} className="ms-sm12" options={items} selectedKey={selectedTeeth} required />
                    </Row>
                    <Row>
                        <Text {...amount} className="ms-sm2" type="number" required />
                        {props.canViewRefounds && <Text {...refound} className="ms-sm2" type="number" />}
                    </Row>
                </Section>
                <Section title="elementi dentali">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Row>
                            <DentalChart {...dentalElement} parentController={props.parentController} selectionMode="multiple" value={selectedTeeth} required />
                        </Row>
                    </div>
                </Section>
            </Grid>
        </>
    );
}