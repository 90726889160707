import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Section from '../../components/controls/grid/Section';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import ComboBox from '../../components/controls/ComboBox';
import SearchBox from '../../components/controls/SearchBox';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const roles = useSecurityRoles()

    const page = useLeapState(props.controller, false)

    const searchbox_fiscalcode = useLeapData("searchbox_fiscalcode", "ricerca tramite codice fiscale", page, "insureds")
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const leap_relationshipTierID = useLeapData("leap_relationshipTierID", "grado di parentela", page, "familydegree")

    const searchboxHasValue = page.item?.values?.searchbox_fiscalcode?.value !== undefined && page.item?.values?.searchbox_fiscalcode?.value !== null && page.item?.values?.searchbox_fiscalcode?.value?.length !== 0;

    return (
        <>
            <Grid>
                <Section>
                    {!roles.isInsuredUser && <>
                        <Row>
                            <SearchBox {...searchbox_fiscalcode} className="ms-sm12" />
                        </Row>
                    </>}
                    <Row>
                        <Text {...fiscalcode} className="ms-sm4" required={!searchboxHasValue} readOnly={searchboxHasValue} maxlength={16} />
                        <Text {...lastname} className="ms-sm4" required={!searchboxHasValue} readOnly={searchboxHasValue} maxlength={50} />
                        <Text {...firstname} className="ms-sm4" required={!searchboxHasValue} readOnly={searchboxHasValue} maxlength={50} />
                    </Row>
                    <Row>
                        <ComboBox {...leap_relationshipTierID} className="ms-sm4" required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
