import { createTheme } from '@fluentui/react';
import { FontSizes, FontWeights } from '@fluentui/react/lib/';

import Theme from '../themes/Theme';
import { FontFaces } from '../../defs/fonts';

export const titleTheme = createTheme({
    defaultFontStyle: {
        fontFamily: FontFaces.montserrat,
        fontSize: 34,
        fontWeight: 600,        
        letterSpacing: "0px",
    },
});

export const unitTheme = createTheme({
    defaultFontStyle: {
        color: Theme.fluent.palette.themePrimary,
        fontFamily: FontFaces.openSans,
        fontSize: FontSizes.xxLargePlus,
        fontVariant: 'small-caps',
        fontWeight: 500,
        lineHeight: 44,
        paddingLeft: 8,
        paddingTop: 2
    },
});

export const modalityTheme = createTheme({
    defaultFontStyle: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.openSans,
        fontSize: FontSizes.mediumPlus,
        fontWeight: 500,
        paddingLeft: 4,
    },
});

export const sectionTheme = createTheme({
    defaultFontStyle: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.openSans,
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold,
    },
});

export const resultMessageTheme = createTheme({
    defaultFontStyle: {
        backgroundColor: Theme.fluent.palette.white,
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.openSans,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        lineHeight: 44,
        paddingLeft: 8,
        paddingRight: 8,
    },
});

export const versionTitle = createTheme({
    defaultFontStyle: {
        fontFamily: FontFaces.roboto,
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
        lineHeight: 20,
        paddingLeft: 8,
    },
});

export const currentDateTimeTheme = createTheme({
    defaultFontStyle: {
        fontFamily: FontFaces.roboto,
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
        lineHeight: 20,
        paddingRight: 8,
    },
});
export const NavMenuTitle = createTheme({
    defaultFontStyle: {
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.mediumPlus,
        fontWeight: FontWeights.semibold,
        lineHeight: '2.4em',
        paddingLeft: 8,
    },
});