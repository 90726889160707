import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { AppDispatch } from '../../app/store'

import { currentModality, setModality } from '../modality/modalitySlice'
import { currentOperation, currentController, currentData, resetOperation, currentParentController } from './operationSlice'

import { clearFormFilter, clearDataFilter, filterReset, filterLoad, filterForm, filterResponse, filterData, } from './thunks/filterThunk'
import { resultLoad, resultData, resultFirst, resultPrevious, resultNext, resultLast, resultResponse, clearDataResult, clearFormResult } from './thunks/resultThunk'
import { itemLoad, itemData, itemNew, itemCreate, itemCopy, itemClone, itemSave, itemDelete, itemFirst, itemPrevious, itemNext, itemLast, itemForm, clearDataItem, itemResponse, itemNavigate, setListResponse } from './thunks/itemThunk'

import { setResponse } from '../response/responseSlice'
import { security } from '../security/thunks/securityThunk';
import { useCookies } from 'react-cookie';
import { lock, unlock } from './lockSlice';

export default () => {
    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality)
    const operation = useSelector(currentOperation)
    const controller = useSelector(currentController)
    const parentController = useSelector(currentParentController)
    const data = useSelector(currentData)

    const securityContext = useSelector(security)

    const fForm = useSelector(filterForm)
    const fData = useSelector(filterData);
    const dFilter = fData.find(d => d.key === controller)?.value

    const rData = useSelector(resultData);
    const dResult = rData.find(d => d.key === controller)?.value

    const iForm = useSelector(itemForm)
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value

    let body = null
    let page = null

    const [cookies, setCookie, removeCookie] = useCookies(["session"])
    const token = cookies.session?.token

    const getAsyncBody = (page: any) => {

        if (parentController && (parentController !== "unset")) {
            const parentItemForm = iForm.find(i => i.key === parentController)?.value
            const parentItemData = iData.find(d => d.key === parentController)?.value

            let parentItemPage: any = {}
            if (parentItemData?.metadata?.page) {
                Object.assign(parentItemPage, parentItemData?.metadata?.page)

                if (parentItemPage.index > 0) {
                    parentItemPage.index = parentItemPage.index - 1
                } else {
                    parentItemPage.index = 0
                }
            }

            return {
                page: page,
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }
            }
        }

        body = { page: page }
    }
    switch (operation) {
        case "reset":
            dispatch(clearDataFilter(controller))
            dispatch(clearFormFilter(controller))
            dispatch(filterReset({ controller, modality, operation, token: token }))
                .then(() => dispatch(setModality("filter")))
                .then(() => dispatch(unlock()))
            break;
        case "filter":
           dispatch(filterLoad({ controller: controller, modality: modality, operation: operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then(() => dispatch(setModality("filter")))
                .then(() => dispatch(unlock()))
               .then(() => dispatch(clearDataResult(controller)))
               .then(() => dispatch(clearFormResult(controller)))          
            break;
        case "search":
            page = dResult?.metadata?.page
            dispatch(resultLoad({ controller, modality, operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then(() => dispatch(setModality("result")))
                .then(() => dispatch(unlock()))
            break;
        case "read":
            let index = dResult?.metadata?.page.index ?? 0
            let rows = dResult?.metadata?.page.rows ?? 1
            body = (index * rows) + ((data?.index ?? 0) % rows)

            dispatch(clearDataItem(controller))
            dispatch(itemLoad({ controller, modality, operation, body, token: token }))
                .then(() => dispatch(setModality("item")))
                .then(() => dispatch(unlock()))
            break;
        case "navigate":
            page = dItem?.metadata?.page
            dispatch(itemNavigate({ controller, modality, operation, body: { form: iForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then(() => dispatch(unlock()))
            break;
        case "reload":
            page = dItem?.metadata?.page

            dispatch(clearDataItem(controller))
            dispatch(itemLoad({ controller, modality, operation, body: page.index - 1, token: token }))
                .then(() => dispatch(setModality("item")))
                .then(() => dispatch(unlock()))
            break;
        case "new":
            if (modality === "filter" || modality === "result") {
                body = { form: fForm.find(i => i.key === controller)?.value, page: {} }
            } else {
                body = { form: {}, page: {} }
            }

            dispatch(itemNew({ controller, modality, operation, body, token: token }))
                .then(() => dispatch(setModality("new")))
                .then(() => dispatch(unlock()))
            break;
        case "create":
            dispatch(itemCreate({ controller, modality, operation, body: { form: iForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then((response) => { if (response.payload?.response?.level === 4) dispatch(setModality("item")) })
                .then(() => dispatch(unlock()))
            break;
        case "copy":
            page = dItem?.metadata?.page

            dispatch(clearDataItem(controller))
            dispatch(itemCopy({ controller, modality, operation, body: page.index - 1, token: token }))
                .then(() => dispatch(setModality("new")))
                .then(() => dispatch(unlock()))
            break;
        case "clone":
            page = dItem?.metadata?.page
            let ind = dItem?.metadata?.page.index;
            dispatch(itemClone({ controller, modality, operation, body: { form: iForm.find(i => i.key === controller)?.value, page: page }, index: ind - 1, token: token }))
                .then((response) => dispatch(setModality("item")))
                .then(() => dispatch(unlock()))
            break;
        case "save":            
            page = dItem?.metadata?.page

            dispatch(itemSave({ controller, modality, operation, body: { form: iForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then(() => dispatch(setModality("item")))
                .then(() => dispatch(unlock()))
            break;

        case "delete":
            page = dItem?.metadata?.page

            dispatch(itemDelete({ controller, modality, operation, body: { form: iForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                .then(() => dispatch(setModality("filter")))
                .then(() => dispatch(unlock()))
            break;
        case "first":
            if (modality === "result") {
                page = dResult?.metadata?.page

                dispatch(resultFirst({ controller, modality, operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                    .then(() => dispatch(setModality(modality)))
                    .then(() => dispatch(unlock()))

            } else if (modality === "item") {
                page = dItem?.metadata?.page

                dispatch(clearDataItem(controller))
                dispatch(itemFirst({ controller, modality, operation, body: { page: page }, token: token }))
                    .then(() => dispatch(setModality("item")))
                    .then(() => dispatch(unlock()))
            }
            break;
        case "previous":
            if (modality === "result") {
                page = dResult?.metadata?.page

                dispatch(resultPrevious({ controller, modality, operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                    .then(() => dispatch(setModality(modality)))
                    .then(() => dispatch(unlock()))
            } else if (modality === "item") {
                page = dItem?.metadata?.page

                dispatch(clearDataItem(controller))
                dispatch(itemPrevious({ controller, modality, operation, body: { page: page }, token: token }))
                    .then(() => dispatch(setModality("item")))
                    .then(() => dispatch(unlock()))
            }

            break;
        case "next":
            if (modality === "result") {
                page = dResult?.metadata?.page

                dispatch(resultNext({ controller, modality, operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                    .then(() => dispatch(setModality(modality)))
                    .then(() => dispatch(unlock()))

            } else if (modality === "item") {
                page = dItem?.metadata?.page

                dispatch(clearDataItem(controller))
                dispatch(itemNext({ controller, modality, operation, body: { page: page }, token: token }))
                    .then(() => dispatch(setModality("item")))
                    .then(() => dispatch(unlock()))
            }
            break;

        case "last":
            if (modality === "result") {
                page = dResult?.metadata?.page

                dispatch(resultLast({ controller, modality, operation, body: { form: fForm.find(i => i.key === controller)?.value, page: page }, token: token }))
                    .then(() => dispatch(setModality(modality)))
                    .then(() => dispatch(unlock()))

            } else if (modality === "item") {
                page = dItem?.metadata?.page

                dispatch(clearDataItem(controller))
                dispatch(itemLast({ controller, modality, operation, body: { page: page }, token: token }))
                    .then(() => dispatch(setModality("item")))
                    .then(() => dispatch(unlock()))
            }
            break;
        case "firstAsync":
            page = dItem?.metadata?.page
            body = getAsyncBody(page)
            dispatch(resultFirst({ controller, modality, operation, body: body, token: token }))
                .then((res) => dispatch(setListResponse(controller, parentController ?? "", res.payload)))
                .then(() => dispatch(unlock()))
            break;
        case "previousAsync":
            page = dItem?.metadata?.page
            body = getAsyncBody(page)
            dispatch(resultPrevious({ controller, modality, operation, body: body, token: token }))
                .then((res) => dispatch(setListResponse(controller, parentController ?? "", res.payload)))
                .then(() => dispatch(unlock()))
            break;
        case "nextAsync":
            page = dItem?.metadata?.page
            body = getAsyncBody(page)
            dispatch(resultNext({ controller, modality, operation, body: body, token: token }))
                .then((res) => dispatch(setListResponse(controller, parentController ?? "", res.payload)))
                .then(() => dispatch(unlock()))
            break;

        case "lastAsync":
            page = dItem?.metadata?.page
            body = getAsyncBody(page)
            dispatch(resultLast({ controller, modality, operation, body: body, token: token }))
                .then((res) => dispatch(setListResponse(controller, parentController ?? "", res.payload)))
                .then(() => dispatch(unlock()))
            break;
    }

    const fResponse = useSelector(filterResponse);
    const rResponse = useSelector(resultResponse);
    const iResponse = useSelector(itemResponse);

    let response
    switch (modality) {
        case "filter":
            response = fResponse
            break;
        case "result":
            response = rResponse
            break;
        case "item":
        case "new":
            response = iResponse
            break;
        default:
            response = { message: '', level: -1 }
            break;
    }

    if (operation === "unset") {
        dispatch(setResponse(response.message, response.level, null, true))
    }
    else {
        dispatch(setResponse(response.message, response.level))
    }
    dispatch(resetOperation())

    return <></>
};
