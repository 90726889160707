import * as React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { SelectionMode, PrimaryButton, mergeStyleSets, values, Label, Icon, Dialog, DialogFooter, DialogType, IDialogProps, FontSizes, FontWeights, IButtonProps, DefaultButton } from '@fluentui/react';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import SearchBox from '../../components/controls/SearchBox';
import { security } from '../security/thunks/securityThunk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import { filterForm, filterLoad } from '../operation/thunks/filterThunk';
import EditInvoiceData from './EditInvoiceData';
import EditInvoiceInsuredData from './EditInvoiceInsuredData';
import EditInvoiceONHCData from './EditInvoiceONHCData';
import { useLeapState } from '../../hooks/useLeapState';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { resultLoad } from '../operation/thunks/resultThunk';
import { useLeapData } from '../../hooks/useLeapData';
import Theme from '../../components/themes/Theme';
import { itemPrint, setFormItem } from '../operation/thunks/itemThunk';
import { useDatagridOperation } from '../../hooks/useDatagridOperation';
import { setResponse } from '../response/responseSlice';
import { useState } from 'react';

type Props = {
    items: any,
    name?: string
    onChange?: any,
    controller: string,
    searchedECCode: string,
    state?: any
}

export const gotoElimina = createAsyncThunk(
    'item/gotoElimina',
    async (args: { EcID: string, token: string | undefined }) => {
        const response = await client.post("AccountingSituation", { form: { accountStatementCodeID: args.EcID } }, 'DeleteEC', args.token)
        return response
    })

export const gotoAssocia = createAsyncThunk(
    'item/gotoAssocia',
    async (args: { selectedID: string, accountStatementCodeID: string, token: string | undefined }) => {
        const response = await client.post("AccountingSituation", { form: { ids: args.selectedID, accountStatementCodeID: args.accountStatementCodeID } }, 'AssociaAdEC', args.token)
        return response
    })


export default (props: Props) => {

    //#region roles
    const roles = useSecurityRoles()

    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHCMaster: boolean = roles.isONHCMaster;

    const isONHCRole: boolean = isONHCAdmin || isONHCAccount || isONHCMaster;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = roles.isHF;

    const isHFRole: boolean = isHFMaster || isHFOperator || isHF;

    //#endregion

    const dispatch: AppDispatch = useDispatch()
    const dispatchReload = (response: any) => {
        dispatch(setOperation("filter", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const buttonsStyles = mergeStyleSets({
        body: {
            marginTop: 20,
            marginRight: 2
        },
    });

    const controller = props.controller
    const credential = useSelector(security)?.credential

    const datagridOperation = useDatagridOperation(controller, credential.token)
    const fForm = useSelector(filterForm)
    const form = fForm.find(i => i.key === controller)?.value
    const page = useLeapState(props.controller, false)

    let index = page?.result?.state?.metadata?.page?.index ?? 0
    const isRefundONHC = form?.refundFeeTypeID === "1"
    const isRefundInsured = form?.refundFeeTypeID === "2"
    const isRefundSelected = isRefundONHC || isRefundInsured

    const isWelfare = form?.leap_PlanManagementID === "2"
    const isPaidSellingWelfare = form?.leap_PlanManagementID === "2" && form?.leap_WelfarePlanInvoiceAmountID == "2" && form?.paymentDateStartDate && Object.keys(props.items ?? {}).length > 0

    const isSearchByASCode = !(form?.accountStatementCodeID === undefined || form?.accountStatementCodeID?.length === 0)
    const isSearchByPiva: boolean = !(form?.piva === undefined || form?.piva?.length === 0)
    const isSearchByHF = !(form?.healthFacilityID === undefined || form?.healthFacilityID?.length === 0)

    const amount: boolean = (page?.result?.state?.lookups?.amount != undefined ? (page?.result?.state?.lookups?.amount[0]) : 0);
    const quoteONHCIVA: boolean = (page?.result?.state?.lookups?.quoteONHCIVA != undefined ? (page?.result?.state?.lookups?.quoteONHCIVA[0]) : 0);

    const isEndOfCureSearch = form.leap_AccountingSituationQuoteStateID == "7" && form.leap_FundAutorizzationStateID != "2"

    //#region grid click/selection
    const onRowClick = (e: any, data: any) => {
        if (e.preventDefault)
            e.preventDefault()

        if (isHFMaster && e.quoteManagementStateID == "7") {
            setMsgBoxMessageValue("Il Piano di Cure è concluso.")
            showMessageBox()
            return;
        }

        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const getSelectionMode = isSearchByASCode ?
        SelectionMode.none :
        (isONHCRole ? SelectionMode.multiple : SelectionMode.single);

    const [selectedGridValues, setValues] = React.useState<any>()
    const setSelectedGridValues = (_name: any, values: any, _required: any) => {
        setValues(values.map((i: any) => i))
    }

    const [currentSelected, setCurrentSelectedValue] = React.useState<any>([])
    const setCurrentSelected = (_name: any, values: any, _required: any) => {
        setCurrentSelectedValue(values.map((i: any) => i))
    }
    //#endregion

    //#region generic msgBox
    const msgBoxMessageItem = "testo della messagebox";
    const [msgBoxMessageValue, setMsgBoxMessageValue] = React.useState<any>()
    React.useEffect(() => { setMsgBoxMessageValue(msgBoxMessageItem) }, [msgBoxMessageItem])
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: msgBoxMessageValue,
            title: "Impossibile procedere",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-error"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    //#endregion

    //#region generazione estratto conto/ bozza estratto conto
    const generateAccountStatement = (isIndividuale: boolean | undefined, isDraft: boolean | undefined, isHfVersion: boolean | undefined) => {
        const args = {
            controller: controller,
            token: credential.token,
            isIndividuale: isIndividuale,
            isDraft: isDraft,
            isHfVersion: isHfVersion,
        }
        dispatch(generateAS(args))
            .then((response) => {

                if (response.payload === 0) {
                    setMsgBoxMessageValue("Impossibile generare un estratto conto per la selezione attuale")
                    showMessageBox()
                }
                if (!hideChooseASDialog)
                    toggleChooseASDialog()

            });

    }

    const [isDraft, setisDraft] = React.useState<boolean>()
    const [isIndividual, setisIndividual] = React.useState<boolean>()
    const [isHfVersion, setIsHfVersion] = React.useState<boolean>()

    const doGenerateDraft = () => {
        setisDraft(true);
        setisIndividual(false);
        setIsHfVersion(isHFRole);

        //isIndividual, isDraft, isHfVersion
        if (isHFRole) {
            generateAccountStatement(false, true, true)
        }
        else {
            toggleChooseASDialog()
        }

    }

    const doGenerateAS = () => {
        setisDraft(false);
        setIsHfVersion(isHFRole);
        if (isHFRole) {
            generateAccountStatement(isIndividual, isDraft, isHfVersion)
        }
        else {
            toggleChooseASDialog()
        }
    }

    const generateAS = createAsyncThunk(
        'item/generateAccountStatement',
        async (args: { controller: string, isIndividuale: boolean | undefined, isDraft: boolean | undefined, isHfVersion: boolean | undefined }) => {
            const ext = args.isIndividuale ? ".zip" : ".csv";
            const data = new Date().toLocaleDateString('ko-KR')

            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": data + " - Estratto Conto" + ext, "ids": selectedGridValues, "isIndividuale": args.isIndividuale, "isDraft": args.isDraft, "isHfVersion": args.isHfVersion }, page: page }, 'generateAccountStatement', undefined, credential.token)

            dispatch(resultLoad({ controller: props.controller, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === props.controller)?.value, page: { index: index } }, token: credential.token }))
            return response.size
        }
    )
    //#endregion

    //#region estrazione estratto conto 
    const doDownloadAS = () => {
        setIsHfVersion(isHFRole);
        if (isHFRole) {
            downloadCSVAccountStatement(isHfVersion)
        }
        else {
            toggleChooseDownloadASDialog()
        }
    }

    const downloadCSVAccountStatement = (isHfVersion: boolean | undefined) => {
        const args = {
            controller: controller,
            token: credential.token,
            isHfVersion: isHfVersion,
        }
        if (form?.accountStatementCodeID === undefined || form?.accountStatementCodeID?.length === 0) {
            setMsgBoxMessageValue("Selezionare il numero di estratto conto che si desidera estrarre")
            showMessageBox();
        }
        else {
            dispatch(CSVDownloadAccountStatement(args))
        }
        if (!hideChooseDownloadASDialog)
            toggleChooseDownloadASDialog()
    }

    const CSVDownloadAccountStatement = createAsyncThunk(
        'item/downloadAccountStatement',
        async (args: { controller: string, token: string, isHfVersion: boolean | undefined }) => {
            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": "export.csv", isHfVersion: args.isHfVersion }, page: page }, 'downloadSingleAccountStatement', undefined, credential.token)
            return response
        }
    )

    //#endregion

    const canChangePaymentDate: boolean = true//form?.paymentDateStartDate && Object.keys(props.items ?? {}).length > 0

    //#region elenco rimborsi
    const canDownloadRefundList: boolean = true//form?.paymentDateStartDate && Object.keys(props.items ?? {}).length > 0
    const downloadRefundList = () => {
        const args = {
            controller: controller,
            fileName: "estrattoBancario.csv",
            token: credential.token,
        }
        dispatch(DownloadRefundList(args))
    }
    const DownloadRefundList = createAsyncThunk(
        'item/DownloadRefundList',
        async (args: { controller: string }) => {
            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": "elencoRimborsi.csv" }, page: page }, 'DownloadRefundList', undefined, credential.token)
            return response
        }
    )
    //#endregion

    //#region estratto bancario
    const canDownloadRefundSS: boolean = form?.paymentDateStartDate && Object.keys(props.items ?? {}).length > 0
    const downloadRefundSS = () => {
        const args = {
            controller: controller,
            fileName: "estrattoBancario.csv",
            token: credential.token,
        }
        dispatch(DownloadRefundSS(args))
    }
    const DownloadRefundSS = createAsyncThunk(
        'item/DownloadRefundSS',
        async (args: { controller: string }) => {
            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": "estrattoBancario.csv" }, page: page }, 'DownloadRefundSS', undefined, credential.token)
            return response
        }
    )
    //#endregion

    //#region fatture ONHC
    const canDownloadInvoiceONHC: boolean = true//form?.paymentDateStartDate && Object.keys(props.items ?? {}).length > 0
    const downloadInvoiceONHC = () => {
        const args = {
            controller: "EditInvoiceONHCData",
            fileName: "FattureONHC.zip",
            token: credential.token,
        }
        dispatch(DownloadInvoiceONHC(args))
    }
    const DownloadInvoiceONHC = createAsyncThunk(
        'item/DownloadInvoiceONHC',
        async (args: { controller: string, fileName: string }) => {
            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": args.fileName }, page: page }, 'DownloadInvoiceONHC', undefined, credential.token)
            return response
        }
    )
    //#endregion

    //#region print result list
    const PrintButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }
    const [hidePrintDialog, { toggle: togglePrintDialog, setFalse: showPrintDialog }] = useBoolean(true)
    const dialogPrintProps: IDialogProps = {
        hidden: hidePrintDialog,
        onDismiss: togglePrintDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il tipo di stampa",
            title: "Stampa",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }


    //controlla la visibilità del pulsante
    const canPrint = ((form?.accountStatementCodeID && page?.result?.state?.data.length !== 0) ||
        (!form?.accountStatementCodeID && selectedGridValues?.length === 1 && !currentSelected[0]?.accountStatementCode)) &&
        form.leap_FundAutorizzationStateID != "2"

    //stampo chiedendo in che modo
    //se non ho selezionato un ec, ho selezionato una riga, la riga non ha dati fattura
    //se ho selezionato un ec, l'ec non ha dati fattura
    const canPrintByChoosing = (!form?.accountStatementCodeID && selectedGridValues?.length === 1 && currentSelected[0]?.invoiceDataString === " - ") ||
        (form?.accountStatementCodeID && page?.result?.state?.data.length !== 0 && page?.result?.state?.data[0]?.invoiceDataString === " - ")

    const onClickPrint = () => {
        dispatch(itemPrint({ controller, lookup: "print", body: { form: { ...form, id: (form?.accountStatementCodeID != null && page?.result?.state?.data.length !== 0) ? undefined : selectedGridValues[0] }, page: {} }, token: credential.token }))
    }
    const onClickPrintByChoosing = (withHoldingTax: boolean) => {
        dispatch(itemPrint({ controller, lookup: "print", body: { form: { ...form, id: (form?.accountStatementCodeID != null && page?.result?.state?.data.length !== 0) ? undefined : selectedGridValues[0], withHoldingTax: withHoldingTax }, page: {} }, token: credential.token }))
        togglePrintDialog()
    }
    //#endregion

    //#region delete ec
    const [EcToDeleteID, setEcToDeleteID] = React.useState<any>();

    const onDeleting = (e: any) => {
        setEcToDeleteID(e?.nativeEvent?.id);
    }

    const onDelete = (e: any) => {
        datagridOperation.delete({ form: { id: EcToDeleteID }, page: {} })
            .then((response) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
                if (response.payload.response.level === 4) {
                    return true
                }
                return false
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(resultLoad({ controller, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === controller)?.value, page: { index: index } }, token: credential.token }))
                }
            })
    }

    const onClickElimina = (e: any) => {
        e.preventDefault()
        toggleHideDialogElimina()
    }
    const [hideDialogElimina, { toggle: toggleHideDialogElimina }] = useBoolean(true);
    const dialogContentPropsElimina = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con l\'eliminazione dell\'estratto conto?',
    };
    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const argsDelete = { EcID: form?.accountStatementCodeID, token: credential?.token }

    const onExecuteElimina = (e: any) => {
        toggleHideDialogElimina()
        if (page.result.data[0].value.data[0].invoiceNumber != undefined && page.result.data[0].value.data[0].invoiceNumber != "") {
            setMsgBoxMessageValue("l'estratto conto selezionato ha una fattura associata, impossibile rimuoverlo");
            showMessageBox()
        }
        else {

            dispatch(gotoElimina(argsDelete))
                .then((res) => {
                    //aggiustare controllo lato server

                    return
                }).then(dispatchReload)
        }
    }

    //#endregion

    //#region associa qm a un ec esistente
    const accountStatementCodeID = useLeapData("accountStatementCodeID", "numero estratto conto", { ...page, controller: props.controller, modality: "item" }, "accountStatementCodes")

    const ASCodeID = page?.item?.state?.data?.accountStatementCodeID
    const [ASCodeIDValue, setASCodeIDValue] = React.useState("")
    React.useEffect(() => { setASCodeIDValue(ASCodeID) }, [ASCodeID])

    const argsAssocia = { selectedID: selectedGridValues, accountStatementCodeID: ASCodeIDValue, token: credential?.token }
    React.useEffect(() => { dispatch(setFormItem(controller, { name: "healthFacilityIDSearch", value: currentSelected[0]?.healthFacilityCode })) }, [currentSelected[0]])

    const onClickAssocia = () => {
        if (currentSelected[0]?.invoiceDataString === " - ") {
            dispatch(gotoAssocia(argsAssocia))
                .then((res) => {

                    dispatch(setResponse(res.payload.response.message, res.payload.response.level, true))
                    if (res.payload.response.level === 4) {
                        toggleAssociaDialog()
                        return true
                    }
                    else {
                        return false
                    }
                })
                .then((proceed) => {
                    if (proceed) {
                        dispatch(resultLoad({ controller: props.controller, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === props.controller)?.value, page: { page, index: index } }, token: credential?.token }))

                    }
                })
        }
    }

    const [hideAssociaDialog, { toggle: toggleAssociaDialog, setFalse: showAssociaDialog }] = useBoolean(true)
    const dialogAssociaProps: IDialogProps = {
        hidden: hideAssociaDialog,
        onDismiss: toggleAssociaDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare l'estratto conto cui associare il preventivo",
            title: "Associa",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    //#endregion

    //#region esporta un ec esistente permettendo la scelta se per struttura o per onhc
    const [hideChooseASDialog, { toggle: toggleChooseASDialog, setFalse: showChooseASDialog }] = useBoolean(true)
    const dialogChooseASProps: IDialogProps = {
        hidden: hideChooseASDialog,
        onDismiss: () => toggleChooseASDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il tipo di estrazione desiderato",
            title: "Estrazione",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const [hideChooseDownloadASDialog, { toggle: toggleChooseDownloadASDialog, setFalse: showChooseDownloadASDialog }] = useBoolean(true)
    const dialogChooseDownloadASProps: IDialogProps = {
        hidden: hideChooseDownloadASDialog,
        onDismiss: () => toggleChooseDownloadASDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il tipo di estrazione desiderato",
            title: "Estrazione",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }


    const [hideChooseExportDialog, { toggle: toggleChooseExportDialog, setFalse: showChooseExportDialog }] = useBoolean(true)
    const dialogChooseExportProps: IDialogProps = {
        hidden: hideChooseExportDialog,
        onDismiss: toggleChooseExportDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il tipo di estrazione",
            title: "Estrazione",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    //#endregion

    const onRenderIconDocumentatioRequiredState = (e: any) => {
        if (e.leap_DocumentationVerificationID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_DocumentationVerificationID === "2") {
            return <Icon iconName={"CircleFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_DocumentationVerificationID === "3") {
            return <Icon iconName={"CircleFill"} style={{ color: 'red' }} />;
        } else if (e.leap_DocumentationRequiredID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"CircleRing"} style={{ color: '#d0d0d0' }} />;
        }
    }

    const onRenderIconInsuredBillingVerificationState = (e: any) => {
        if (e.leap_InsuredInvoiceVerificationID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "2") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "3") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'red' }} />;
        } else if (e.leap_InsuredInvoiceRequiredID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"Checkbox"} style={{ color: '#d0d0d0' }} />;
        }
    }

    return (
        <>
            <Dialog {...dialogProps} >
                <DialogFooter >
                    <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
                </DialogFooter>
            </Dialog>
            <Dialog {...dialogPrintProps} >
                <DialogFooter>
                    <PrimaryButton onClick={() => onClickPrintByChoosing(true)} text="con ritenuta d'acconto" />
                    <PrimaryButton onClick={() => onClickPrintByChoosing(false)} text="senza ritenuta d'acconto" />
                </DialogFooter>
            </Dialog>
            <Dialog {...dialogChooseASProps} >
                <DialogFooter >
                    <PrimaryButton onClick={() => generateAccountStatement(isIndividual, isDraft, false)} text="onhc" />
                    <PrimaryButton onClick={() => generateAccountStatement(isIndividual, isDraft, true)} text="struttura" />
                    <DefaultButton onClick={toggleChooseASDialog} text="Annulla" />
                </DialogFooter>
            </Dialog>
            <Dialog {...dialogChooseDownloadASProps} >
                <DialogFooter >
                    <PrimaryButton onClick={() => downloadCSVAccountStatement(false)} text="onhc" />
                    <PrimaryButton onClick={() => downloadCSVAccountStatement(true)} text="struttura" />
                    <DefaultButton onClick={toggleChooseDownloadASDialog} text="Annulla" />
                </DialogFooter>
            </Dialog>
            <Dialog {...dialogAssociaProps} >
                <SearchBox {...accountStatementCodeID} className="ms-sm8" onChange={(_name: any, value: any, _required: any) => { setASCodeIDValue(value) }} />
                <DialogFooter >
                    <PrimaryButton onClick={onClickAssocia} text="associa" />
                    <PrimaryButton onClick={toggleAssociaDialog} text="Chiudi" />
                </DialogFooter>
            </Dialog>
            <Dialog hidden={hideDialogElimina} onDismiss={toggleHideDialogElimina} dialogContentProps={dialogContentPropsElimina} modalProps={modalProps} >
                <DialogFooter>
                    <PrimaryButton onClick={onExecuteElimina} text='elimina' />
                    <DefaultButton onClick={toggleHideDialogElimina} text="Annulla" />
                </DialogFooter>
            </Dialog>
            <Section>
                <Row>
                    <div className={isONHCRole && isWelfare ? "ms-Grid-col ms-sm7" : "ms-Grid-col ms-sm9"}>
                        {isRefundONHC && !isSearchByASCode && form.leap_FundAutorizzationStateID != "2" && (isHFRole || isONHCRole) &&
                            <PrimaryButton text="scarica bozza estratto conto" onClick={doGenerateDraft} className={buttonsStyles.body} />}
                        {isRefundONHC && !isSearchByASCode && isSearchByPiva && isONHCRole &&
                            <PrimaryButton text="genera estratto conto individuale" onClick={() => { setisIndividual(true); doGenerateAS() }} className={buttonsStyles.body} />}
                        {isRefundONHC && !isSearchByASCode && form.leap_FundAutorizzationStateID != "2" && ((isSearchByHF || isSearchByPiva) && (isONHCRole || (isHFMaster && isEndOfCureSearch))) &&
                            <PrimaryButton text="genera estratto conto di gruppo" onClick={() => { setisIndividual(false); doGenerateAS() }} className={buttonsStyles.body} />}
                        {isRefundONHC && !isSearchByASCode && form.leap_FundAutorizzationStateID != "2" && isHF &&
                            <PrimaryButton text="genera estratto conto" onClick={() => generateAccountStatement(false, false, false)} className={buttonsStyles.body} />}
                        {isRefundONHC && isSearchByASCode && (isHFRole || isONHCRole) &&
                            <PrimaryButton text="estrazione estratto conto" onClick={doDownloadAS} className={buttonsStyles.body} />}
                        {isRefundONHC && (isSearchByASCode || (selectedGridValues?.length === 1 && !currentSelected[0]?.accountStatementCode && form.leap_FundAutorizzationStateID != "2")) && (isHFRole || isONHCRole) &&
                            <EditInvoiceData controller="EditInvoiceData" parentController={props.controller} selectedValues={selectedGridValues} index={index} />}
                        {isRefundInsured && isONHCRole &&
                            <EditInvoiceInsuredData controller="EditInvoiceData" parentController={props.controller} selectedValues={selectedGridValues} index={index} />}
                        {isRefundONHC && !isSearchByASCode && selectedGridValues?.length === 1 && !currentSelected[0]?.accountStatementCode && currentSelected[0]?.invoiceDataString === " - " && isONHCRole &&
                            <PrimaryButton text="associa a e.c." onClick={toggleAssociaDialog} style={{ marginTop: 20 }} />}
                        {isRefundONHC && isSearchByASCode && isONHCRole &&
                            <PrimaryButton text="elimina" onClick={onClickElimina} className={buttonsStyles.body} />}
                        {isRefundInsured && canDownloadRefundList && (isHFRole || isONHCRole) &&
                            <PrimaryButton text="scarica elenco rimborsi" onClick={downloadRefundList} className={buttonsStyles.body} style={{ marginTop: 20 }} />}
                        {isRefundSelected && canDownloadRefundSS && isONHCRole &&
                            <PrimaryButton text="estratto bancario" onClick={downloadRefundSS} className={buttonsStyles.body} style={{ marginTop: 20 }} />}
                        {isPaidSellingWelfare && isONHCRole &&
                            <EditInvoiceONHCData controller="EditInvoiceONHCData" parentController={props.controller} selectedValues={selectedGridValues} index={index} />}
                        {isPaidSellingWelfare && isONHCRole &&
                            <PrimaryButton text="scarica fatture ONHC" onClick={downloadInvoiceONHC} className={buttonsStyles.body} style={{ marginTop: 20 }} />}
                    </div>
                    <div className="ms-Grid-col ms-sm1" >
                        {(canPrint) &&
                            <PrimaryButton onClick={!canPrintByChoosing ? onClickPrint : togglePrintDialog} style={{ marginTop: 20, display: canPrint ? undefined : "none" }}>stampa</PrimaryButton>}
                    </div>
                    {isONHCRole && isWelfare &&
                        <Text onChange={() => { }}
                            className="ms-sm2"
                            label="Totale Fattura ONHC"
                            name="quoteONHCIVA"
                            type="plainWithIcon"
                            value={quoteONHCIVA}
                            readOnly
                            onRenderSuffix={() => { return <><Icon iconName="AllCurrency" onClick={() => { }} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                    }
                    <Text onChange={() => { }}
                        className="ms-sm2"
                        label="Totale"
                        name="amount"
                        type="plainWithIcon"
                        value={amount}
                        readOnly
                        onRenderSuffix={() => { return <><Icon iconName="AllCurrency" onClick={() => { }} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                </Row>
            </Section>
            <div style={{ paddingTop: 10 }}></div>
            <DataList items={props.items} onNavigating={onRowClick} selectionName={props.name} selectionMode={getSelectionMode} onSelectionChange={setSelectedGridValues} onSelectionChangeFull={setCurrentSelected} modalController={props.controller} state={props.state} onDelete={onDelete} onDeleting={onDeleting}>
                <DataColumn kid='columnN' name='' fieldName='navigate' minWidth={10} maxWidth={10} type='navigate' />
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column3' type="icon" name='' fieldName='leap_DocumentationVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconDocumentatioRequiredState} />
                <DataColumn kid='column2' type="icon" name='' fieldName='leap_InsuredInvoiceVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconInsuredBillingVerificationState} />
                <DataColumn kid='column1' name='Codice ASS' fieldName='insuredCode' align='center' minWidth={100} maxWidth={120} />
                <DataColumn kid='column4' name='Nome e Cognome' fieldName='insuredFirstNameLastName' align='left' minWidth={220} maxWidth={250} />
                {(isONHCRole || isHFMaster) && <DataColumn kid='column5' name='Codice STS' fieldName='healthFacilityCode' align='left' minWidth={100} maxWidth={160} />}
                <DataColumn kid='column21' name='N. Preventivo' fieldName='quoteManagementNumber' align='left' minWidth={200} maxWidth={200} />
                {!isRefundInsured && <DataColumn kid='column6' name='N. Estratto Conto' fieldName='accountStatementCode' align='left' minWidth={140} maxWidth={150} />}
                <DataColumn kid='column7' name='Fattura' fieldName='invoiceDataString' align='left' minWidth={160} maxWidth={200} />
                <DataColumn kid='column8' name='Pagamento' fieldName='paymentDate' align='left' minWidth={100} maxWidth={120} />
                {isONHCRole && <DataColumn kid='column9' name='Tipo Gestione' fieldName='leap_AccountingSituationManagementTypeID' align='left' minWidth={100} maxWidth={100} />}
                {isONHCRole && <DataColumn kid='column10' name='Compagnia di Welfare' fieldName='welfareCompany' align='left' minWidth={130} maxWidth={150} />}
                {/*<DataColumn kid='column16' name='Rimborso SS' fieldName='refundSS' align='right' minWidth={100} maxWidth={120} />*/}
                <DataColumn kid='column11' name='Totale' fieldName='cost' align='right' minWidth={100} maxWidth={120} />
                {isHF && <DataColumn kid='column21' name='Commissione&#13;&#13;Welfare' fieldName='commissionONHC' align='right' minWidth={140} maxWidth={180} />}
                {isONHCRole && <DataColumn kid='column12' name='Quota STS' fieldName='refundSTS' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column13' name='Quota Welfare' fieldName='welfareQuote' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column14' name='Quota ONHC' fieldName='onhsQuote' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column15' name='IVA' fieldName='iva' align='right' minWidth={100} maxWidth={120} />}
                <DataColumn kid='column16' name='Fattura ONHC' fieldName='invoiceONHC' align='right' minWidth={160} maxWidth={200} />
                {isONHCRole && <DataColumn kid='column17' name='Voucher' fieldName='voucherImport' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column18' name='Costo Voucher' fieldName='voucherCost' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column19' name='Sconto' fieldName='discountImport' align='right' minWidth={100} maxWidth={120} />}
                {isONHCRole && <DataColumn kid='column20' name='Quota Assistito' fieldName='insuredQuote' align='right' minWidth={100} maxWidth={120} />}
            </DataList>
        </>
    );
}
