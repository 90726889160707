import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column5' name='Operatore' fieldName='userNickname' minWidth={100} maxWidth={120} align='left' />
                <DataColumn kid='column1' name='Data Invio' fieldName='date' minWidth={100} maxWidth={120} align='right' />
                <DataColumn kid='column2' name='Email' fieldName='targetEmail' minWidth={300} maxWidth={300} align='left' />
                <DataColumn kid='column3' name='Documenti Selezionati' fieldName='documentsDescription' minWidth={300} align='left' />
                <DataColumn kid='column4' name='Province' fieldName='provincesDescription' maxWidth={300} align='left' />
            </DataList>
        </>
    );
}
