import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';
import FileUpload from '../../components/controls/FileUpload';
import { PrimaryButton } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { itemDownload } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const dispatch = useDispatch()
    const controller = props.controller
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const filedescription = useLeapData("filedescription", "descrizione", page)
    const fileStartDate = useLeapData("fileStartDate", "data inizio validità", page)
    const fileEndDate = useLeapData("fileEndDate", "data fine validità", page)
    const fileID = useLeapData("fileID", "fileID", page)

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileID,
            fileName: page?.item?.state?.data?.fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...filedescription} className="ms-sm12" />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...fileStartDate, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...fileEndDate, className: "ms-sm5" }} />
                    </Row>
                    {(page?.item?.state?.data?.fileID === undefined || page?.item?.state?.data?.fileID === null) && (
                        <Row>
                            <FileUpload {...fileID} className="ms-sm12" label="upload file" controller={props.controller} />
                        </Row>
                    )}
                    {(page?.item?.state?.data?.fileID !== undefined && page?.item?.state?.data?.fileID !== null) && (
                        <Row>
                            <PrimaryButton text="download file" onClick={downloadFile} style={{ marginTop: "20px" }} />
                        </Row>
                    )}
                </Section>
            </Grid>
        </>
    );
}
