import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from '../../app/store'
import { Label, TagPicker, ITag } from '@fluentui/react/'
import { security } from '../../features/security/thunks/securityThunk'
import { filterSearch, filterForm, filterData } from '../../features/operation/thunks/filterThunk'
import { itemSearch, setFormItem, itemForm, itemData } from '../../features/operation/thunks/itemThunk'
import { currentResponse } from '../../features/response/responseSlice'
import { currentModality } from '../../features/modality/modalitySlice'

type Props = {
    controller: string,
    name: string,
    label: string,
    lookup: string,
    className: string
    required?: boolean
    mandatory?: (name: string) => boolean
    value?: any,
    onChange?: (name: string | undefined, value: string[] | number[] | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string[] | number[] | undefined, required?: boolean | undefined) => void,
    disabled?: boolean,
    hidden?: boolean,
    itemLimit?: number,
}

class ITAG implements ITag {
    constructor(name: string, key: string | number) {
        this.name = name;
        this.key = key;
    }
    name: string;
    key: string | number;
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality)

    const response = useSelector(currentResponse)

    const iForm = useSelector(itemForm)
    const fForm = useSelector(filterForm)
    const iData = useSelector(itemData);
    const fData = useSelector(filterData);
    const dItem = iData.find(d => d.key === props.controller)?.value
    const dFilter = fData.find(d => d.key === props.controller)?.value

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const [selectedItem, setSelectedItem] = useState<ITag[] | undefined>()

    useEffect(() => {
        if (props.onInit) {
            props.onInit(props.name, props.value?.map((i: any) => i.key), props.required)
        }

        dispatch(setFormItem(props.controller, { name: props.name, value: props.value?.map((i: any) => i.key) }))
        const itags: ITAG[] = props.value?.map((i: any)=> new ITAG(i.text, i.key))

        setSelectedItem(itags)

    }, [props.value, props.required])

    const onChange = (items?: ITag[]) => {

        let keys = items?.map((i: ITag) => i.key.toString())
        if (props.onChange)
            props.onChange(props.name, keys, props.required)

        setSelectedItem(items)
    }

    const onResolveSuggestions = async (filter: string) => {
        if (filter) {
            const form = iForm.find(i => i.key === props.controller)?.value
            const page = dItem?.metadata?.page
            const body = { form: { ...form, [props.name + "Search"]: filter }, page: page }

            const formF = fForm.find(i => i.key === props.controller)?.value
            const pageF = dFilter?.metadata?.page
            const bodyF = { form: { ...formF, [props.name + "Search"]: filter }, page: pageF }

            if (modality === "filter")
                return dispatch(filterSearch({ controller: props.controller, lookup: props.lookup, body: bodyF, token: token }))
                    .then((response) => {
                        const items = response.payload.lookups?.[props.lookup]
                        return items.map((item: any): ITag => {
                            return {
                                name: item.text,
                                key: item.key
                            };
                        });
                    })
            else if (modality === "item" || modality === "new")
                return dispatch(itemSearch({ controller: props.controller, lookup: props.lookup, body: body, token: token }))
                    .then((response) => {
                        const items = response.payload.lookups?.[props.lookup]
                        return items.map((item: any): ITag => {
                            return {
                                name: item.text,
                                key: item.key
                            };
                        });
                    })
        }
        else {
            return []
        }
    }
    const onEmptyResolveSuggestions = async () => {
        const form = iForm.find(i => i.key === props.controller)?.value
        const page = dItem?.metadata?.page
        const body = { form: { ...form, [props.name + "Search"]: "" }, page: page }

        const formF = fForm.find(i => i.key === props.controller)?.value
        const pageF = dFilter?.metadata?.page
        const bodyF = { form: { ...formF, [props.name + "Search"]: "" }, page: pageF }

        if (modality === "filter")
            return dispatch(filterSearch({ controller: props.controller, lookup: props.lookup, body: bodyF, token: token }))
                .then((response) => {
                    const items = response.payload.lookups?.[props.lookup]
                    return items.map((item: any): ITag => {
                        return {
                            name: item.text,
                            key: item.key
                        };
                    });
                })
        else if (modality === "item" || modality === "new")
            return dispatch(itemSearch({ controller: props.controller, lookup: props.lookup, body: body, token: token }))
                .then((response) => {
                    const items = response.payload.lookups?.[props.lookup]
                    return items?.map((item: any): ITag => {
                        return {
                            name: item.text,
                            key: item.key
                        };
                    });
                })
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const labelClassName = response.notified && !mandatory ? "leap-search-box-label leap-search-box-label-error" : "leap-search-box-label"
    const inputClassName = response.notified && !mandatory ? "leap-search-box-input leap-search-box-input-error" : "leap-search-box-input"
    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <div style={{ marginTop: 8 }}>
                <Label className={labelClassName} required={props.required}>{props.label}</Label>
                <TagPicker
                    inputProps={{ autoComplete: "off" }}
                    className={inputClassName}
                    onEmptyResolveSuggestions={onEmptyResolveSuggestions}
                    onChange={onChange}
                    disabled={props.disabled}
                    onResolveSuggestions={onResolveSuggestions}
                    resolveDelay={1500}
                    itemLimit={props.itemLimit ?? 3}
                    selectedItems={selectedItem} />
            </div>
        </div>
    </>
}
