import { DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, IButtonProps, Pivot, PivotItem, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Separator from '../components/controls/grid/Separator';
import MultiBox from '../components/controls/MultiBox';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import HealthFacilityTimetableList from '../features/healthFacility/HealthFacilityTimetableList';
import HealthFacilityTimetableModal from '../features/healthFacility/HealthFacilityTimetableModal';
import HealthFacilityAffiliationContractFolderList from '../features/healthFacilityAffiliation/HealthFacilityAffiliationContractFolderList';
import HealthFacilityAffiliationContractFolderModal from '../features/healthFacilityAffiliation/HealthFacilityAffiliationContractFolderModal';
import HealthFacilityAffiliationContractFolderModalEdit from '../features/healthFacilityAffiliation/HealthFacilityAffiliationContractFolderModalEdit';
import HealthFacilityAffiliationDoctorList from '../features/healthFacilityAffiliation/HealthFacilityAffiliationDoctorList';
import HealthFacilityAffiliationDoctorModal from '../features/healthFacilityAffiliation/HealthFacilityAffiliationDoctorModal';
import HealthFacilityAffiliationHealthServiceAssociateList from '../features/healthFacilityAffiliation/HealthFacilityAffiliationHealthServiceAssociateList';
import HealthFacilityAffiliationHealthServiceAssociateModal from '../features/healthFacilityAffiliation/HealthFacilityAffiliationHealthServiceAssociateModal';
import HealthFacilityAffiliationHealthServiceAssociateModalEdit from '../features/healthFacilityAffiliation/HealthFacilityAffiliationHealthServiceAssociateModalEdit';
import HealthFacilityAffiliationResultList from '../features/healthFacilityAffiliation/HealthFacilityAffiliationResultList';
import HealthFacilityAffiliationStateButtons from '../features/healthFacilityAffiliation/HealthFacilityAffiliationStateButtons';
import RejectedHealthFacilityAffiliationHealthServiceAssociateList from '../features/healthFacilityAffiliation/RejectedHealthFacilityAffiliationHealthServiceAssociateList';

import { isLocked, lock, unlock } from '../features/operation/lockSlice';
import { setOperation } from '../features/operation/operationSlice';
import { itemData } from '../features/operation/thunks/itemThunk';
import { setResponse } from '../features/response/responseSlice';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

export enum States {
    Sent = "1",
    Rejected = "2",
    AwaitingData = "3",
    CheckingDocumentation = "4",
    Accepted = "5"
}

const PivotButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
        verticalAlign: "bottom"
    },
    styles: {
        root: {
            backgroundColor: Theme.fluent.palette.themeTertiary,
            color: Theme.fluent.palette.neutralLighter,
            height: 38,
            marginTop: 12,
            minWidth: "12%",
        },
        rootDisabled: {
            backgroundColor: Theme.fluent.palette.themePrimary,
            color: Theme.fluent.palette.white,
            height: 42,
            marginTop: 8,
            minWidth: "15%",
        },
        rootHovered: {
            backgroundColor: Theme.fluent.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: Theme.fluent.palette.themeLight,
        }
    }
}

const ButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 2,
        borderStyle: "solid",
        borderLeftWidth: 1,
        fontWeight: 600,
        verticalAlign: "bottom",
    },
    styles: {
        root: {
            height: 34,
            marginTop: 16,
            minWidth: "12%",
            marginRight: 4
        }
    }
}

export const GenerateContract = createAsyncThunk(
    'item/GenerateContract',
    async (args: { id: string, controller: string, token: string | undefined }) => {
        const response = await client.post(args.controller, { form: { id: args.id } }, 'GenerateContract', args.token)
        return response
    }
)

export const affiliationGuideDownload = createAsyncThunk(
    'affliationGuide/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'AffiliationGuide', args.token)
        return response
    }
)
export const systemGuideDownload = createAsyncThunk(
    'affliationGuide/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'Help', args.token)
        return response
    }
)

export default () => {
    const controller = "HealthFacilityAffiliation"
    const contractFolderController = "HealthFacilityAffiliationContractFolder"
    const healthFacilityAffiliationDoctorController = "HealthFacilityAffiliationDoctor"
    const hsAssociate = "HealthFacilityAffiliationHealthServiceAssociate"
    const timeTableController = "HealthFacilityAffiliationTimetable"

    const roles = useSecurityRoles()
    const isHFAffiliation = roles.isHFAffiliation
    const isONHC = roles.isONHC
    const page = useLeapState(controller, roles.role !== undefined, isHFAffiliation ? "item" : "filter")

    const dispatch: AppDispatch = useDispatch()
    const _isLocked = useSelector(isLocked)
    const credential = useSelector(security)?.credential

    const healthFacilityCode = useLeapData("healthFacilityCode", "codice", page)

    const firstnameLegal = useLeapData("firstnameLegal", "nome rappresentante legale", page)
    const lastnameLegal = useLeapData("lastnameLegal", "cognome rappresentante legale ", page)
    const fiscalCodeLegal = useLeapData("fiscalCodeLegal", "codice fiscale", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const telephoneNumber = useLeapData("telephoneNumber", "telefono", page)
    const mobilePhone = useLeapData("mobilePhone", "cellulare", page)
    const email = useLeapData("email", "email", page)
    const emailPubblica = useLeapData("publicEmail", "email pubblica", page)
    const cf = useLeapData("cf", "codice fiscale", page)
    const piva = useLeapData("piva", "partita iva", page)
    const sdi = useLeapData("sdi", "SDI", page)
    const numberRegistrationClinicalManager = useLeapData("numberRegistrationClinicalManager", "n. iscrizione albo", page)
    const provinceRegistrationClinicalManagerID = useLeapData("provinceRegistrationClinicalManagerID", "provincia iscrizione albo", page, "provinces")
    const leap_AffiliationStateID = useLeapData("leap_AffiliationStateDescription", "stato", page)
    const filterState = useLeapData("leap_AffiliationStateID", "stato", page, "states")
    const rates = useLeapData("rates", "", page)

    const denomination = useLeapData("denomination", "denominazione", page)
    const numberInsurancePolicy = useLeapData("numberInsurancePolicy", "n. di polizza assicurativa", page)
    const nameInsurancePolicy = useLeapData("nameInsurancePolicy", "compagnia", page)
    const clinicalManagerLastname = useLeapData("clinicalManagerLastname", "cognome responsabile clinico", page)
    const clinicalManagerFirstname = useLeapData("clinicalManagerFirstname", "nome responsabile clinico", page)
    const birthdateClinicalManager = useLeapData("birthdateClinicalManager", "data di nascita", page)

    const address = useLeapData("address", "indirizzo", page)
    const streetAddress = useLeapData("streetAddress", "civico", page)
    const postalCode = useLeapData("postalCode", "cap", page)
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    const addressLegal = useLeapData("addressLegal", "indirizzo sede legale", page)
    const streetAddressLegal = useLeapData("streetAddressLegal", "civico", page)
    const postalCodeLegal = useLeapData("postalCodeLegal", "cap", page)
    const municipalityLegalID = useLeapData("municipalityLegalID", "comune sede legale", page, "municipalityLegals")
    const nationLegalID = useLeapData("nationLegalID", "nazione sede legale", page, "nationLegals")
    const pec = useLeapData("pec", "PEC", page)
    const administrativeEmail = useLeapData("administrativeEmail", "email amministrativa", page)

    const website = useLeapData("website", "sito web", page)
    const numberDoctors = useLeapData("numberDoctors", "n. di medici", page)
    const numberAggregate = useLeapData("numberAggregate", "n. riuniti", page)
    const leap_HealthFacilityStructureTypeID = useLeapData("leap_HealthFacilityStructureTypeID", "tipo di struttura", page, "healthFacilityStructureTypeOption")
    const leap_OrthopantomographID = useLeapData("leap_HealthFacilityOrthopantomographID", "ortopantomografo", page, "healthFacilityOrthopantomographOption")
    const tipoImpianto = useLeapData("dentalImplant", "tipologia impianti", page)
    const leap_HealthFacilityCertificationQualityID = useLeapData("leap_HealthFacilityCertificationQualityID", "certificazione di qualità", page, "healthFacilityCertificationQualityOption")
    const bodyCertification = useLeapData("bodyCertification", "ente certificatore", page)
    const numberCertification = useLeapData("numberCertification", "n. certificazione", page)
    const healthAgreementID = useLeapData("agreements", "convenzioni attive", page, "healthAgreement")
    const other = useLeapData("other", "altre", page)

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = React.useState<"general" | "rate" | "rejectedRates">("general")

    const HealthFacilityAffiliationState: string = page.item?.state?.data?.leap_AffiliationStateID;
    const currentState = Object.entries(States).find(([, value]) => value === HealthFacilityAffiliationState)?.[1];
    
    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const iForm = useSelector(itemData)
    const args = { id: iForm.find(i => i.key === controller)?.value?.data?.id, controller: contractFolderController, token: credential?.token }

    const iconStyle: React.CSSProperties = {
        color: Theme.fluent.palette.themePrimary,
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        fontSize: '1.2em'
    }
    const divStyle: React.CSSProperties = {
        flexDirection: "row",
        fontSize: '14px',
        marginBottom: 10,
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        paddingLeft: 10,
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        cursor: "pointer",
    }
    const textStyle: React.CSSProperties = {
        verticalAlign: "top",
        paddingLeft: 10,
        fontFamily: 'Montserrat',
        fontWeight: "normal",
        fontSize: "14"
    }
    const [hideDialogGenerateContract, { toggle: toggleHideDialogGenerateContract }] = useBoolean(true);
    const dialogContentPropsGenerateContract = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con la generazione del contratto?',
    };
    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const onExecuteGenerateContract = (e: any) => {
        toggleHideDialogGenerateContract()
        dispatch(lock())
        dispatch(GenerateContract(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }
    const farItems = currentState === States.Sent ? [<div style={divStyle} onClick={(e) => { e?.preventDefault(); toggleHideDialogGenerateContract() }}><FontIcon iconName={"InsertSignatureLine"} style={iconStyle} /><span style={textStyle}>stampa contratto</span></div>] : []

    const idx = page?.item?.state?.metadata?.page?.index;
    React.useEffect(() => { setPivotItemSelectedKey("general") }, [idx]);

    const hideExtraFields = currentState === States.Sent;
    const readOnlyExtraFields = !(currentState === States.AwaitingData && isHFAffiliation);

    const isOtherSelected: boolean = page.item?.values?.agreements?.value?.includes("999");
    const acceptDisabled: boolean = currentState === States.CheckingDocumentation && page.item.state?.lists?.HSList?.some((i: any) => i.state != "Accepted")

    const showRateDialogOnAccept: boolean = Object.keys(page.item.state?.lists?.HSList ?? {}).length > 0

    const fromPublicPageReadOnly = page.item?.state?.data?.fromPublicPage || currentState === States.CheckingDocumentation;
    const tempHideFields = currentState === States.Sent && !fromPublicPageReadOnly;
    const notFromPublicPageRequired = page.modality === "item" && !fromPublicPageReadOnly && isHFAffiliation;

    return <>
        <Content title="Affiliazione Struttura" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...businessName} className="ms-sm6" maxlength={50} />
                            <ComboBox {...filterState} className="ms-sm2" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <HealthFacilityAffiliationResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        {page.modality === "item" && <>
                            {currentState && currentState !== States.Sent && <div className="ms-Grid-col ms-sm6" style={{ height: 50 }}>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "general"} onClick={() => setPivotItemSelectedKey("general")}>dati generali</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "rate"} onClick={() => setPivotItemSelectedKey("rate")}>tariffario</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "rejectedRates"} onClick={() => setPivotItemSelectedKey("rejectedRates")}>tariffe rifiutate</DefaultButton>
                            </div>}
                            {isHFAffiliation && <>
                                <div className="ms-Grid-col ms-sm6" style={{ height: 50, textAlign: "right" }}>
                                    <PrimaryButton  {...ButtonProps} onClick={() => { dispatch(affiliationGuideDownload({ controller: "Files", body: { form: { fileName: "GuidaAffiliazione.pdf" }, page: {} }, token: credential.token })) }}>scarica guida &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>
                                    <PrimaryButton  {...ButtonProps} onClick={() => { dispatch(systemGuideDownload({ controller: "Files", body: { form: { fileName: "Manuale.pdf" }, page: {} }, token: credential.token })) }}>scarica manuale &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>
                                </div>
                            </>
                            }
                        </>}
                        {
                            page.modality === "new" &&
                            <>
                                <Row hidden={hideExtraFields}>
                                    <Text {...denomination} className="ms-sm10" required={page.modality === "new"} maxlength={50} />
                                </Row>
                                <Row hidden={hideExtraFields}>
                                    <Text {...email} className="ms-sm5" required={page.modality === "new"} maxlength={50} />
                                </Row>
                            </>
                        }
                    </Section>
                    {
                        page.modality === "item" && <>
                            <Section title=" ">
                                <Row>
                                    <Text {...leap_AffiliationStateID} className="ms-sm2" readOnly />
                                    <HealthFacilityAffiliationStateButtons controller={controller} currentState={currentState} acceptDisabled={acceptDisabled} showRateDialogOnAccept={showRateDialogOnAccept} />
                                </Row>
                            </Section>
                            <Pivot selectedKey={pivotItemSelectedKey}>
                                <PivotItem headerText="dati generali" itemKey="general">
                                    <Section title=" ">
                                        <Row>
                                            <Text {...healthFacilityCode} className="ms-sm2" hidden={isHFAffiliation || currentState !== States.Accepted} readOnly />
                                            <Text {...denomination} className="ms-sm6" readOnly required={notFromPublicPageRequired} />
                                        </Row>
                                    </Section>
                                    <Section title="recapiti">
                                        <Row hidden={tempHideFields}>
                                            <Text {...address} className="ms-sm4" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired && isHFAffiliation} />
                                            <Text {...streetAddress} className="ms-sm1" maxlength={8} readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...postalCode} className="ms-sm1" maxlength={5} readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <SearchBox {...municipalityID} className="ms-sm4" disabled={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                        </Row>
                                        <Row>
                                            <Text {...telephoneNumber} className="ms-sm2" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...mobilePhone} className="ms-sm2" readOnly={readOnlyExtraFields} hidden={tempHideFields} />
                                        </Row>
                                        <Row>
                                            <Text {...email} className="ms-sm3" readOnly={!isONHC} />
                                            <Text {...emailPubblica} className="ms-sm3" readOnly={readOnlyExtraFields} hidden={tempHideFields} />
                                            <Text {...pec} className="ms-sm3" readOnly={readOnlyExtraFields} hidden={tempHideFields} required={notFromPublicPageRequired} maxlength={50} />
                                        </Row>
                                        <Row>
                                            <Text {...website} className="ms-sm6" readOnly={readOnlyExtraFields} hidden={tempHideFields} />
                                            <Text {...administrativeEmail} className="ms-sm3" readOnly={readOnlyExtraFields} hidden={tempHideFields} required={notFromPublicPageRequired} maxlength={50} />
                                        </Row>
                                    </Section>
                                    <Section title="sede legale" hidden={tempHideFields}>
                                        <Row hidden={tempHideFields}>
                                            <Text {...businessName} className="ms-sm6" required={isHFAffiliation && page.modality === "item"} maxlength={50} hidden={tempHideFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields}>
                                            <Text {...addressLegal} className="ms-sm4" hidden={hideExtraFields} maxlength={50} readOnly={readOnlyExtraFields} required={notFromPublicPageRequired} />
                                            <Text {...streetAddressLegal} className="ms-sm1" maxlength={8} hidden={hideExtraFields} readOnly={readOnlyExtraFields} required={notFromPublicPageRequired} />
                                            <Text {...postalCodeLegal} className="ms-sm1" maxlength={5} hidden={hideExtraFields} readOnly={readOnlyExtraFields} required={notFromPublicPageRequired} />
                                        </Row>
                                        <Row hidden={tempHideFields}>
                                            <SearchBox {...nationLegalID} className="ms-sm4" hidden={hideExtraFields} disabled={readOnlyExtraFields} required={notFromPublicPageRequired} />
                                            <SearchBox {...municipalityLegalID} className="ms-sm4" hidden={hideExtraFields} disabled={readOnlyExtraFields} required={notFromPublicPageRequired} />
                                        </Row>
                                        <Row hidden={tempHideFields}>
                                            <Text {...cf} className="ms-sm3 ms-xxl2" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...piva} className="ms-sm3 ms-xxl2" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...sdi} className="ms-sm3 ms-xxl2" readOnly={readOnlyExtraFields} hidden={tempHideFields} />
                                        </Row>
                                    </Section>
                                    <Section title="rappresentante legale" hidden={tempHideFields}>
                                        <Row hidden={tempHideFields}>
                                            <Text {...lastnameLegal} className="ms-sm3" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...firstnameLegal} className="ms-sm3" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...fiscalCodeLegal} className="ms-sm3 ms-xxl2" readOnly={readOnlyExtraFields} hidden={hideExtraFields} required={notFromPublicPageRequired} maxlength={16} />
                                        </Row>
                                    </Section>
                                    <Section title="informazioni della struttura" hidden={hideExtraFields}>
                                        <Row hidden={hideExtraFields}>
                                            <ComboBox {...leap_HealthFacilityStructureTypeID} className="ms-sm4" hidden={hideExtraFields} disabled={readOnlyExtraFields} />
                                            <Text {...numberDoctors} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 6, decimal: 0, min: 0 }} hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                            <Text {...numberAggregate} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 6, decimal: 0, min: 0 }} hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields}>
                                            <Option {...leap_HealthFacilityCertificationQualityID} className="ms-sm2" type="horizontal" hidden={hideExtraFields} disabled={readOnlyExtraFields} />
                                            <Text {...bodyCertification} className="ms-sm4" hidden={hideExtraFields} maxlength={50} readOnly={readOnlyExtraFields} />
                                            <Text {...numberCertification} className="ms-sm2" hidden={hideExtraFields} maxlength={50} readOnly={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields}>
                                            <Option {...leap_OrthopantomographID} className="ms-sm2" type="horizontal" hidden={hideExtraFields} disabled={readOnlyExtraFields} />
                                            <Text {...tipoImpianto} className="ms-sm6" hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields}>
                                            <Text {...numberInsurancePolicy} className="ms-sm3 ms-xxl2" required={page.modality === "item" && isHFAffiliation} hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                            <Text {...nameInsurancePolicy} className="ms-sm6" required={page.modality === "item" && isHFAffiliation} hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields}>
                                            <MultiBox {...healthAgreementID} className="ms-sm8" titleType='text' hidden={hideExtraFields} disabled={readOnlyExtraFields} />
                                        </Row>
                                        <Row hidden={hideExtraFields || !isOtherSelected} >
                                            <Text {...other} className="ms-sm8" hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                    </Section>
                                    <Section title="responsabile clinico" hidden={tempHideFields}>
                                        <Row hidden={tempHideFields}>
                                            <SearchBox {...provinceRegistrationClinicalManagerID} className="ms-sm3" disabled={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...numberRegistrationClinicalManager} className="ms-sm2" type="number" precision={{ integer: 16, decimal: 0 }} readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                        </Row>
                                        <Row hidden={tempHideFields}>
                                            <Text {...clinicalManagerLastname} className="ms-sm3" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...clinicalManagerFirstname} className="ms-sm3" readOnly={fromPublicPageReadOnly} hidden={tempHideFields} required={notFromPublicPageRequired} />
                                            <Text {...birthdateClinicalManager} dateRange={{ minDate: undefined, maxDate: Date().toLocaleString() }} className="ms-sm2" type="calendar" required={page.modality === "item" && isHFAffiliation} hidden={hideExtraFields} readOnly={readOnlyExtraFields} />
                                        </Row>
                                    </Section>
                                    {currentState !== States.Sent &&
                                        <Section title="elenco medici" toolbar="grid" modal={<HealthFacilityAffiliationDoctorModal state={page} controller={healthFacilityAffiliationDoctorController} />} controller={healthFacilityAffiliationDoctorController} modalwidth="800px" modaltitle="Inserisci un Medico" state={page} >
                                            <Row>
                                                <HealthFacilityAffiliationDoctorList items={page.item.state?.lists.Doctors} modal={<HealthFacilityAffiliationDoctorModal state={page} controller={healthFacilityAffiliationDoctorController} />} controller={healthFacilityAffiliationDoctorController} modalwidth="800px" modaltitle="Modifica un Medico" state={page} />
                                            </Row>
                                        </Section>
                                    }
                                    <Section title="cartella contratto" toolbar="grid" modal={<HealthFacilityAffiliationContractFolderModal state={page} controller={contractFolderController} />} controller={contractFolderController} modalwidth="600px" modaltitle="Inserisci File" state={page}>
                                        <Row>
                                            <HealthFacilityAffiliationContractFolderList currentState={currentState} items={page.item.state?.lists.ContractFolder} modal={<HealthFacilityAffiliationContractFolderModalEdit state={page} currentState={currentState} controller={contractFolderController} />} controller={contractFolderController} modalwidth="600px" modaltitle="Modifica File" state={page} />
                                        </Row>
                                        <Dialog hidden={hideDialogGenerateContract} onDismiss={toggleHideDialogGenerateContract} dialogContentProps={dialogContentPropsGenerateContract} modalProps={modalProps} >
                                            <DialogFooter>
                                                <PrimaryButton onClick={onExecuteGenerateContract} text='stampa contratto' disabled={_isLocked} />
                                                <DefaultButton onClick={toggleHideDialogGenerateContract} text="annulla" disabled={_isLocked} />
                                            </DialogFooter>
                                        </Dialog>
                                    </Section>
                                    {currentState !== States.Sent &&
                                        <Section title="orari" >
                                            <Row>
                                                <HealthFacilityTimetableList items={page.item.state?.lists.Timetables} modal={<HealthFacilityTimetableModal state={page} controller={timeTableController} />} controller={timeTableController} modalwidth="600px" modaltitle="Modifica l'orario" state={page} readOnly={currentState === States.CheckingDocumentation} />
                                            </Row>
                                        </Section>
                                    }
                                </PivotItem>
                                <PivotItem headerText="tariffario" itemKey="rate">
                                    <Section hidden={false} title="tariffario" toolbar="grid" modal={<HealthFacilityAffiliationHealthServiceAssociateModal state={page} controller={hsAssociate} />} controller={hsAssociate} modalwidth="400px" modaltitle="Inserisci tariffa" state={page} >
                                        <Row hidden={false}>
                                            <HealthFacilityAffiliationHealthServiceAssociateList currentState={currentState} parentController={controller} {...rates} items={page.item.state?.lists.HSList} modal={<HealthFacilityAffiliationHealthServiceAssociateModalEdit state={page} controller={hsAssociate} />} controller={hsAssociate} modalwidth="600px" modaltitle="Modifica tariffa" state={page} />
                                        </Row>
                                    </Section>
                                </PivotItem>
                                <PivotItem headerText="tariffe rifiutate" itemKey="rejectedRates">
                                    <Section title=" " >
                                        <Row>
                                            <RejectedHealthFacilityAffiliationHealthServiceAssociateList items={page.item.state?.lists.RejectedHSList} />
                                        </Row>
                                    </Section>
                                </PivotItem>
                            </Pivot>
                        </>
                    }
                </Grid>
            </Item>
        </Content>
    </>
};
