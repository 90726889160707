import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    const showTimesNumber = props.state.item.values.leap_DiscountCategoryID &&
        (
            props.state.item.values.leap_DiscountCategoryID.value === "1" ||
            props.state.item.values.leap_DiscountCategoryID.value === "2" ||
            props.state.item.values.leap_DiscountCategoryID.value === "5" ||
            props.state.item.values.leap_DiscountCategoryID.value === "6"
        )
    const showFixedAmount = props.state.item.values.leap_DiscountCategoryID &&
        (
            props.state.item.values.leap_DiscountCategoryID.value === "1" ||
            props.state.item.values.leap_DiscountCategoryID.value === "5" ||
            props.state.item.values.leap_DiscountCategoryID.value === "6"
        )
    const showOdQuote = props.state.item.values.leap_DiscountCategoryID &&
        (
            props.state.item.values.leap_DiscountCategoryID.value === "1" ||
            props.state.item.values.leap_DiscountCategoryID.value === "2"
        )
    const showInsuredQuote = props.state.item.values.leap_DiscountCategoryID &&
        (props.state.item.values.leap_DiscountCategoryID.value === "1")

    return (
        <>
            <DataList {...props} modalController={props.controller} modalwidth={props.modalwidth} >
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Tipo' fieldName='leap_DiscountTimesNumberTypeID' minWidth={120} align='left' />
                {showTimesNumber && <DataColumn kid='column2' name='Numero di Volte' fieldName='timesNumber' minWidth={120} maxWidth={150} align='right' />}
                {showFixedAmount && <DataColumn kid='column3' name='Importo Fisso' fieldName='fixedAmount' minWidth={120} maxWidth={150} align='right' />}
                {showOdQuote && <DataColumn kid='column4' name='Quota OD' fieldName='odQuote' minWidth={120} maxWidth={150} align='right' />}
                {showInsuredQuote && <DataColumn kid='column5' name='Quota Assistito' fieldName='insuredQuote' minWidth={120} maxWidth={150} align='right' />}
                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
