import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../app/rootReducer'
import { OperationType } from '../../defs/operations'

interface OperationTypePayload {
    Operation: OperationType,
    Controller: string | "unset",
    ParentController?: string | "unset",
    Data?: any,
}

const OperationSlice = createSlice({
    name: 'operation',
    initialState: { Operation: "unset" as OperationType } as OperationTypePayload,
    reducers: {
        resetOperation: {
            reducer(state, action: PayloadAction<OperationTypePayload>) {
                const { Operation, Controller } = action.payload
                state.Operation = Operation
                state.Controller = Controller
            },
            prepare() {
                return { payload: { Operation: "unset" as OperationType, Controller: '' } }
            }
        },
        setOperation: {
            reducer(state, action: PayloadAction<OperationTypePayload>) {
                const { Operation, Controller, ParentController, Data } = action.payload
                state.Operation = Operation
                state.Controller = Controller
                state.ParentController = ParentController
                state.Data = Data
            },
            prepare(operation: OperationType, controller: string, data?: any, parentController?: string) {
                return { payload: { Operation: operation, Controller: controller, Data: data, ParentController: parentController } }
            }
        },
    }
})

export const { resetOperation, setOperation } = OperationSlice.actions

export const currentOperation = (state: RootState) => state.operation.Operation
export const currentController = (state: RootState) => state.operation.Controller
export const currentParentController = (state: RootState) => state.operation.ParentController
export const currentData = (state: RootState) => state.operation.Data

export default OperationSlice.reducer