import * as React from 'react';
import { useSelector } from 'react-redux';
import { isLocked } from '../../features/operation/lockSlice';

export default () => {
    const _isLocked = useSelector(isLocked)

    const [mousePosition, setMousePosition] = React.useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const handleMouse = (ev: MouseEvent) => setMousePosition({ x: ev.x, y: ev.y })

    React.useEffect(() => {
        document.addEventListener("mousemove", handleMouse)
    }, [])

    React.useEffect(() => {
        const element = document.getElementById("root");

        if (_isLocked) {
            element?.classList.add("cursorNone");
        } else {
            element?.classList.remove("cursorNone");
        }
    }, [_isLocked])

    return (<>{_isLocked &&
        <div style={{ position: "absolute", top: mousePosition?.y, left: mousePosition.x, zIndex: 1000000000000, width: "64px", height: "64px" }}>
            <img src="/assets/media/images/resized.png"
                style={{
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    animation: `spin 1s linear infinite`,
                    position: "inherit",
                }}
            >
            </img>
        </div >}</>);
}

//to postion over top right of cursor
//y -15
//x +6

//<Spinner size={SpinnerSize.medium} type={SpinnerType.large} styles={{ circle: { borderWidth: 0, backgroundImage: image } }} />