import * as React from 'react';
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../app/store'

import { getSession, loginSecurity, getRoles, setRole, changePassword, logoutSecurity, forgotPassword, forgotUsername, validateEmail } from '../features/security/thunks/securityThunk';

export const useSecuritydOperation = () => {
    const controller = "Security"
    const dispatch: AppDispatch = useDispatch();

    return {
        getSession: (body: any) => {
            return dispatch(getSession({ controller, body }))
        },
        login: (username: string, password: string | undefined, token: string | undefined, otp: string | undefined) => {
            return dispatch(loginSecurity({ username, password, token, otp }))
        },
        logout: (token: string) => {
            return dispatch(logoutSecurity({ token }))
        },
        loadRoles: (username: string, password: string, token: string) => {
            return dispatch(getRoles({ username, password, token }))
        },
        setRole: (token: string, role: string) => {
            return dispatch(setRole({ token, role }))
        },
        changePassword: (token: string, vecchiaPassword: string, nuovaPassword: string) => {
            return dispatch(changePassword({ vecchiaPassword, nuovaPassword, token }))
        },
        forgotPassword: (username: string, email: string, token: string | undefined) => {
            return dispatch(forgotPassword({ username, email, token }))
        },
        forgotUsername: (firstname: string, lastname: string, fiscalcode: string, email: string, token: string | undefined) => {
            return dispatch(forgotUsername({ firstname, lastname, email, fiscalcode, token }))
        },
        validateEmail: (confirmationCode: string) => {
            return dispatch(validateEmail({ confirmationCode: confirmationCode }))
        }
    }
}