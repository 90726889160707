import * as React from 'react';

type Props = {
    className: string,
    hidden?: boolean
}

export default (props: Props) => {
    const hidden = props.hidden ? { display: "none" } : undefined

    return <div className={"ms-Grid-col " + props.className} style={hidden} />
};
