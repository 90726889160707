import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { resultData } from '../../../features/operation/thunks/resultThunk';
import { itemData } from '../../../features/operation/thunks/itemThunk';

import { currentModality } from '../../../features/modality/modalitySlice';
import { setOperation, currentParentController } from '../../../features/operation/operationSlice';

import { CommandBar, ICommandBarItemProps } from '@fluentui/react/';

import Theme from '../../themes/Theme';
import { isLocked, lock } from '../../../features/operation/lockSlice';

export default (props: { controller: string, parentController?: string, useMetadata?: boolean }) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageRows, setPageRows] = useState(0);

    const dispatch = useDispatch()

    const rData = useSelector(resultData);
    const iData = useSelector(itemData);
    const dResult = rData.find(d => d.key === props.controller)?.value
    const dItem = iData.find(d => d.key === props.controller)?.value
    const dIndexed = iData.find(d => d.key === props.controller)?.value

    const modality = useSelector(currentModality);
    const _isLocked = useSelector(isLocked)

    let index = 0
    let count = 0
    let rows = 0
    if (modality === "item" && props.useMetadata) {
        index = dIndexed?.metadata?.page?.index ?? 0
        count = dIndexed?.metadata?.page?.count ?? 0
        rows = dIndexed?.metadata?.page?.rows ?? 1
    } else if (modality === "result") {
        index = dResult?.metadata?.page?.index ?? 0
        count = dResult?.metadata?.page?.count ?? 0
        rows = dResult?.metadata?.page?.rows ?? 1
    } else if (modality === "item") {
        index = dItem?.metadata.page.index - 1 //to be zero-based
        count = dItem?.metadata.page.count //to be zero-based
        rows = dItem?.metadata.page.rows
    } else if (modality === "new") {
        index = 0
        count = 0
        rows = 1
    }

    index++;
    useEffect(() => {
        setPageIndex(index)
        setPageCount(count)
        setPageRows(rows)
    }, [index, count, rows])
    const _items: ICommandBarItemProps[] = []
    const _farItems: ICommandBarItemProps[] = [
        {
            key: 'position',
            disabled: true,
            style: { color: Theme.leap.customization.pagerToolbar?.label.color },
            text: pageIndex + " / " + (Math.trunc(Math.max(0, pageCount -1) / pageRows) + 1),
        },
        {
            key: 'goFirst',
            iconOnly: true,
            iconProps: { iconName: 'DoubleChevronLeft' },
            disabled: _isLocked,
            onClick: (e: any) => {
                e.preventDefault()
                dispatch(lock())
                dispatch(setOperation(props.useMetadata ? "firstAsync" : "first", props.controller, undefined, props.parentController))
            },
        },
        {
            key: 'goPrevious',
            iconOnly: true,
            iconProps: { iconName: 'ChevronLeft' },
            disabled: _isLocked,
            onClick: (e: any) => {
                e.preventDefault()
                dispatch(lock())
                dispatch(setOperation(props.useMetadata ? "previousAsync" : "previous", props.controller, undefined, props.parentController))
            },
        },
        {
            key: 'goNext',
            iconOnly: true,
            iconProps: { iconName: 'ChevronRight' },
            disabled: _isLocked,
            onClick: (e: any) => {
                e.preventDefault()
                dispatch(lock())
                dispatch(setOperation(props.useMetadata ? "nextAsync" : "next", props.controller, undefined, props.parentController))
            },
        },
        {
            key: 'goLast',
            iconOnly: true,
            iconProps: { iconName: 'DoubleChevronRight' },
            disabled: _isLocked,
            onClick: (e: any) => {
                e.preventDefault()
                dispatch(lock())
                dispatch(setOperation(props.useMetadata ? "lastAsync" : "last", props.controller, undefined, props.parentController))
            },
        },
    ];

    return (
        <CommandBar className="leap-commandbar" items={_items} farItems={_farItems} />
    );
};