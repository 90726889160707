import * as React from 'react';
import { useEffect } from 'react';

import { TextField, MaskedTextField } from '@fluentui/react/';

import DatePicker from './DatePicker'
import NumberText from './NumberText'
import TimeField from './TimeField'

import { useSelector } from 'react-redux';
import { currentResponse } from '../../features/response/responseSlice';

import { TextFieldProps, ErrorTextFieldProps, TimeFieldProps } from '../../defs/props';

const searchIconProps = { iconName: 'Search' };

type Props = {
    name?: string,
    className: string,
    label: string,
    readOnly?: boolean,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    mask?: string,
    type?: "calendar" | "time" | "mask" | "number" | "plain" | "search" | "password" | "multiline" | "plainWithIcon",
    value?: any,
    defaultDate?: any
    maxlength?: number | undefined;
    precision?: {
        min?: number;
        max?: number;
        integer: number;
        decimal: number;
    },
    dateRange?: {
        minDate?: string,
        maxDate?: string,
    }
    time?: TimeFieldProps,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    hidden?: boolean,
    onRenderSuffix?: any,
    rows?: number
}
//mask="m\ask: ****" maskFormat={maskFormat} maskChar="?"
export default (props: Props) => {
    const response = useSelector(currentResponse)

    const [textValue, setTextValue] = React.useState<string | undefined>('');

    useEffect(() => {
        setTextValue(props.value)

        if (props.onInit) {
            props.onInit(props.name, props.value, props.required)
        }
    }, [props.value, props.required])

    const onChangeHandler = (event: any) => {
        setTextValue(event.target?.value)

        if (props.onChange)
            props.onChange(props.name, event.target?.value, props.required)
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const textStyle = response.notified && !mandatory ? { ...ErrorTextFieldProps } : { ...TextFieldProps };
    const hidden = props.hidden ? { display: "none" } : undefined

    switch (props.type) {
        case "calendar":
            return <>
                <DatePicker {...props} value={textValue ? new Date(textValue) : undefined} defaultDate={props.defaultDate} onChange={onChangeHandler} minDate={props.dateRange?.minDate} maxDate={props.dateRange?.maxDate} />
            </>
        case "time":
            return <>
                <TimeField {...props} />
            </>
        case "search":
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <TextField {...TextFieldProps} {...props} className="" iconProps={searchIconProps} value={textValue} onChange={onChangeHandler} autoComplete="off" />
                </div>
            </>
        case "mask":
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <MaskedTextField {...textStyle} {...props} className="" maskChar=" " value={textValue} onChange={onChangeHandler} autoComplete="off" />
                </div>
            </>
        case "number":
            return <>
                <NumberText{...textStyle} {...props} value={textValue} mandatory={mandatory} onChange={onChangeHandler} />
            </>
        case "password":
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <TextField {...textStyle} {...props} className="" value={textValue} onChange={onChangeHandler} autoComplete="off" type="password" canRevealPassword maxLength={props.maxlength} />
                </div>
            </>
        case "multiline":
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <TextField {...textStyle} {...props} className="" value={textValue} onChange={onChangeHandler} autoComplete="off" maxLength={props.maxlength} multiline />
                </div>
            </>
        case "plainWithIcon":
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <TextField {...textStyle} {...props} className="" value={textValue} onChange={onChangeHandler} autoComplete="off" maxLength={props.maxlength} onRenderSuffix={props.onRenderSuffix} />
                </div>
            </>
        case "plain":
        default:
            return <>
                <div className={"ms-Grid-col " + props.className} style={hidden}>
                    <TextField {...textStyle} {...props} className="" value={textValue} onChange={onChangeHandler} autoComplete="off" maxLength={props.maxlength} />
                </div>
            </>
    }
};
