import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { SelectionMode, ActionButton, Dialog, DialogFooter, PrimaryButton, DialogType, DefaultButton, Icon, IDialogProps, FontWeights, FontSizes } from '@fluentui/react';
import { useLeapData } from '../../hooks/useLeapData';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { isLocked, lock, unlock } from '../operation/lockSlice';
import { AppDispatch } from '../../app/store';
import { setOperation } from '../operation/operationSlice';
import { setResponse } from '../response/responseSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { itemForm, itemData, itemPrint } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';
import { States } from '../../pages/HealthFacilityAffiliation';
import Theme from '../../components/themes/Theme';

type Props = {
    items: any,
    controller: string,
    parentController: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,

    name: string,
    onChange?: any,
    readOnly?: boolean,
    currentState: States | undefined
}

export const accept = createAsyncThunk(
    'item/Accept',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliationHealthServiceAssociate", { form: args.form, page: args.page }, 'Accept', args.token)
        return response
    }
)
export const approveRate = createAsyncThunk(
    'item/ApproveRate',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliationHealthServiceAssociate", { form: args.form, page: args.page }, 'ApproveRate', args.token)
        return response
    }
)

export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount
    const isAffiliation: boolean = roles.isHFAffiliation

    const DataListProps = {
        items: props.items,
        modaltitle: props.modaltitle,
        modalController: props.controller,
        modal: props.modal,
        state: props.state,
        selectionName: props.name,
        selectionMode: isONHCPower ? SelectionMode.multiple : SelectionMode.none,
        onSelectionChange: props.onChange,
        canSelectItem: (data: any, index: any): boolean => true
    }

    const ActionButtonProps = {
        style: {
            fontFamily: 'Montserrat',
            margin: "0 4px"
        }
    }
    type dialogPropsType = {
        text: string,
        message: string,
        onExecute: any
    }
    const [dialogProps, setDialogProps] = React.useState({} as dialogPropsType)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const _isLocked = useSelector(isLocked)
    const dispatch: AppDispatch = useDispatch()

    const selectedIds: any[] = props.state.item?.values?.[props.name]?.value;
    const anySelected = selectedIds?.length > 0;

    const iData = useSelector(itemData);
    const iForm = useSelector(itemForm);
    const dItem = iData.find(d => d.key === 'HealthFacilityAffiliation')?.value
    const credential = useSelector(security)?.credential

    const HealthFacilityAffiliationID = iForm.find(i => i.key === 'HealthFacilityAffiliation')?.value?.id?.toString()

    const form = { healthFacilityAffiliationID: HealthFacilityAffiliationID, ids: selectedIds }
    const page = dItem?.metadata?.page

    const onExecute = (e: any, dialogProps: dialogPropsType) => {
        setDialogProps(dialogProps)
        e.preventDefault()
        toggleHideDialog()
    }
    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.parentController))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }
    const args = {
        form: form, page: page, token: credential?.token
    }
    const [hidePrintDialog, { toggle: togglePrintDialog, setFalse: showPrintDialog }] = useBoolean(true)
    const dialogPrintProps: IDialogProps = {
        hidden: hidePrintDialog,
        onDismiss: togglePrintDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il tipo di stampa",
            title: "Stampa",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const acceptDialogProps: dialogPropsType = {
        text: 'approva tariffe',
        message: 'Approvare le tariffe selezionate?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(accept(args))
                .then(dispatchReload)
                .then(dispatchMessage)
                .then(() => dispatch(unlock()))
                .catch(() => dispatch(unlock()))
        }
    }
    const approveRateDialogProps: dialogPropsType = {
        text: 'approva tariffario',
        message: 'Procedere con l\'accettazione di tutte le tariffe?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(approveRate(args))
                .then(dispatchReload)
                .then(dispatchMessage)
                .catch(() => dispatch(unlock()))
        }
    }

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: dialogProps?.message,
    };
    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const onClickPrint = (includeAll: boolean) => {
        dispatch(lock())
        dispatch(itemPrint({ controller: props.controller, lookup: "print", body: { form: { ...form, includeAll, fileName: "Tariffario.pdf" }, page: {} }, token: credential.token }))
            .then(togglePrintDialog)
            .then(() => dispatch(unlock()))
    }

    const canEdit = props.currentState === States.AwaitingData && isAffiliation;
    const canDelete = isONHCPower && (props.currentState === States.Sent || props.currentState === States.CheckingDocumentation);
    const canUseApprovationButton = props.currentState === States.CheckingDocumentation && isONHCPower

    const approvationButton =
        canUseApprovationButton &&
        <>
            <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'Accept', style: { color: 'mediumseagreen' } }} text={"approva tariffa/e"} onClick={(e) => onExecute(e, acceptDialogProps)} disabled={_isLocked || !anySelected} />
            <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'EventAccepted', style: { color: 'mediumseagreen' } }} text={"approva tariffario"} onClick={(e) => onExecute(e, approveRateDialogProps)} disabled={_isLocked} />
        </>

    const printRate = (props.currentState === States.Accepted || props.currentState === States.CheckingDocumentation) && isONHCPower && <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'CloudDownload', style: { color: 'mediumseagreen' } }} text={"scarica tariffario"} onClick={togglePrintDialog} disabled={_isLocked} />

    const onRenderIcon = (e: any) => {
        if (e?.state === 'Accepted') {
            return <Icon iconName={"Accept"} style={{ color: 'green' }} />;
        }
        else if (e?.state === 'Pending') {
            return <Icon iconName={"Help"} style={{ color: 'black' }} />;
        }
        else if (e?.state === 'Rejected') {
            return <Icon iconName={"Cancel"} style={{ color: 'red' }} />;
        }
        else {
            return <Icon iconName={"CalculatorSubtract"} style={{ color: 'darkgray' }} />;
        }
    }

    return (
        <>
            <div className="ms-Grid-col ms-sm12" style={{ margin: "12px 8px 0", textAlign: "center" }}>
                {approvationButton}
                {printRate}
            </div>
            <DataList {...DataListProps} modalwidth={props.modalwidth} paginate>
                {canDelete && <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />}
                <DataColumn kid='column0' name='Branca' fieldName='branch' minWidth={200} maxWidth={300} align='left' />
                <DataColumn kid='column1' name='Prestazione' fieldName='healthservice' minWidth={200} align='left' />
                <DataColumn kid='column2' name='Massimale' fieldName='maximalRate' minWidth={80} maxWidth={100} align='right' />
                <DataColumn kid='column2' name='Tariffa' fieldName='rate' minWidth={80} maxWidth={100} align='right' />
                {!isAffiliation && <DataColumn kid='column3' name='Elementi Dentali' fieldName='dentalElement' minWidth={200} align='left' />}
                <DataColumn kid='column19' type="icon" name='Approvata' fieldName='accepted' minWidth={80} maxWidth={100} align='center' onRenderIcon={onRenderIcon} />
                {canEdit && <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            </DataList>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} >
                <DialogFooter>
                    <PrimaryButton onClick={dialogProps?.onExecute} text={dialogProps?.text} />
                    <DefaultButton onClick={toggleHideDialog} text="Annulla" />
                </DialogFooter>
            </Dialog>
            <Dialog {...dialogPrintProps} >
                <DialogFooter>
                    <PrimaryButton onClick={() => onClickPrint(true)} text="tutte le tariffe" />
                    <PrimaryButton onClick={() => onClickPrint(false)} text="tariffe diverse dal massimale" />
                </DialogFooter>
            </Dialog>
        </>
    );
}
