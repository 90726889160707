import * as React from 'react';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
}

export default (props: Props) =>
    <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} readOnly={props.readOnly} paginate>
        <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
        <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={80} maxWidth={100} align='left' />
        <DataColumn kid='column2' name='Nome' fieldName='firstnameLegal' minWidth={250} maxWidth={250} align='left' />
        <DataColumn kid='column3' name='Cognome' fieldName='lastnameLegal' minWidth={250} maxWidth={250} align='left' />
        <DataColumn kid='column4' name='Ragione Sociale' fieldName='businessName' minWidth={250} maxWidth={250} align='left' />
        <DataColumn kid='column5' name='Denominazione' fieldName='denomination' minWidth={250} maxWidth={250} align='left' />
        <DataColumn kid='column6' name='Telefono' fieldName='telephoneNumber' minWidth={100} maxWidth={100} align='left' />
        <DataColumn kid='column7' name='E-mail' fieldName='email' minWidth={250} maxWidth={250} align='left' />
        <DataColumn kid='column8' name='Provincia' fieldName='provinceID' minWidth={150} maxWidth={150} align='left' />
        <DataColumn kid='column9' name='Comune' fieldName='municipalityID' minWidth={150} maxWidth={150} align='left' />
        <DataColumn kid='column10' name='Indirizzo' fieldName='address' minWidth={180} maxWidth={200} align='left' />
        <DataColumn kid='column11' name='Cap' fieldName='postalCode' minWidth={80} maxWidth={100} align='left' />
    </DataList>
