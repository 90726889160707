import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useSecurityRoles } from '../../hooks/useSecurityRoles';

import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { Icon } from '@fluentui/react';

export default (props: { controller: string, items: any }) => {
    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator

    const isInsuredUser: boolean = roles.isInsuredUser;

    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const onRenderIconInsuredBillingVerificationState = (e: any) => {
        if (e.leap_InsuredInvoiceVerificationID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "2") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "3") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'red' }} />;
        } else if (e.leap_InsuredInvoiceRequiredID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"Checkbox"} style={{ color: '#d0d0d0' }} />;
        }
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick}>
                {!isHF && < DataColumn kid='column4' name='Struttura' fieldName='healthFacilityID' minWidth={80} maxWidth={80} align='center' />}
                {isONHC && <DataColumn kid='column2' type="icon" name='' fieldName='leap_InsuredInvoiceVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconInsuredBillingVerificationState} />}
                <DataColumn kid='column3' name='Assistito' fieldName='insuredID' minWidth={80} maxWidth={80} align='center' />
                <DataColumn kid='column3' name='Nome e Cognome' fieldName='firstNameLastName' minWidth={200} align='left' />
                <DataColumn kid='column5' name='Piano di Cure' fieldName='quoteManagementNumber' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column6' name='Data Fattura' fieldName='insuredInvoiceDate' minWidth={90} maxWidth={90} align='center' />
                <DataColumn kid='column7' name='N° Fattura' fieldName='insuredInvoiceNumber' minWidth={100} maxWidth={100} align='left' />
                <DataColumn kid='column8' name='Importo' fieldName='insuredInvoiceImport' minWidth={70} maxWidth={70} align='right' />
                <DataColumn kid='column8' name='Fine Cure' fieldName='cureEndDate' minWidth={110} maxWidth={110} align='center' />
                <DataColumn kid='column8' name='Autorizzazione' fieldName='authorizeDate' minWidth={110} maxWidth={110} align='center' />
                <DataColumn kid='column9' name='Stato' fieldName='leap_QuoteManagementQuoteStateID' minWidth={90} maxWidth={90} align='left' />
                <DataColumn kid='column10' name='Gestione' fieldName='leap_PlanManagementDescr' minWidth={90} maxWidth={90} align='left' />
            </DataList>
        </>
    );
}