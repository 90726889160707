import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={150} maxWidth={300} align='left' />
                <DataColumn kid='column1' name='Acronimo' fieldName='acronym' minWidth={150} maxWidth={300} align='left' />
                <DataColumn kid='column1' name='Descrizione' fieldName='description' minWidth={250} align='left' />
            </DataList>
        </>
    );
}
