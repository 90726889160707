import * as React from 'react';
import { Dialog, DialogFooter, DialogType, FontSizes, FontWeights, IDialogProps, IImageStyles, Image, ImageFit, PrimaryButton, Text as TextTitle, Checkbox } from '@fluentui/react/';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import { unitTheme } from '../components/themes/Fonts';
import Theme from '../components/themes/Theme';
import { filterForm, filterValidation } from '../features/operation/thunks/filterThunk';
import { setResponse } from '../features/response/responseSlice';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import Section from '../components/controls/grid/Section';
import Separator from '../components/controls/grid/Separator';

export const Affiliate = createAsyncThunk(
    'item/Affiliate',
    async (args: {
        denomination: string,
        businessName: string,
        cf: string,
        piva: string,

        address: string,
        streetAddress: string,
        postalCode: string,
        municipalityID: string,
        telephoneNumber: string,
        email: string,
        pec: string,
        administrativeEmail: string,

        lastnameLegal: string,
        firstnameLegal: string,
        fiscalCodeLegal: string,

        provinceRegistrationClinicalManagerID: string,
        numberRegistrationClinicalManager: string,
        clinicalManagerLastname: string,
        clinicalManagerFirstname: string
    }) => {
        const response = await client.post("HealthFacilityAffiliation", {
            form: {
                denomination: args.denomination,
                businessName: args.businessName,
                cf: args.cf,
                piva: args.piva,

                address: args.address,
                streetAddress: args.streetAddress,
                postalCode: args.postalCode,
                municipalityID: args.municipalityID,
                telephoneNumber: args.telephoneNumber,
                email: args.email,
                pec: args.pec,
                administrativeEmail: args.administrativeEmail,

                lastnameLegal: args.lastnameLegal,
                firstnameLegal: args.firstnameLegal,
                fiscalCodeLegal: args.fiscalCodeLegal,

                provinceRegistrationClinicalManagerID: args.provinceRegistrationClinicalManagerID,
                numberRegistrationClinicalManager: args.numberRegistrationClinicalManager,
                clinicalManagerLastname: args.clinicalManagerLastname,
                clinicalManagerFirstname: args.clinicalManagerFirstname
            }
        }, 'Affiliate', undefined)
        return response
    }
)

export default () => {
    const controller = "HealthFacilityAffiliation"
    const page = useLeapState(controller)

    const denomination = useLeapData("denomination", "denominazione", page)
    const lastnameLegal = useLeapData("lastnameLegal", "cognome rappresentante legale", page)
    const firstnameLegal = useLeapData("firstnameLegal", "nome rappresentante legale", page)
    const fiscalCodeLegal = useLeapData("fiscalCodeLegal", "codice fiscale rappresentante legale", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const telephoneNumber = useLeapData("telephoneNumber", "telefono", page)
    const email = useLeapData("email", "email", page)
    const pec = useLeapData("pec", "pec", page)
    const administrativeEmail = useLeapData("administrativeEmail", "email amministrativa", page)
    const cf = useLeapData("cf", "codice fiscale", page)
    const piva = useLeapData("piva", "partita iva", page)
    const numberRegistrationClinicalManager = useLeapData("numberRegistrationClinicalManager", "n. iscrizione albo resp clinico", page)
    const provinceRegistrationClinicalManagerID = useLeapData("provinceRegistrationClinicalManagerID", "provincia iscrizione albo resp clinico", page, "provinces")
    const clinicalManagerLastname = useLeapData("clinicalManagerLastname", "cognome responsabile clinico", page)
    const clinicalManagerFirstname = useLeapData("clinicalManagerFirstname", "nome responsabile clinico", page)

    const address = useLeapData("address", "indirizzo", page)
    const streetAddress = useLeapData("streetAddress", "civico", page)
    const postalCode = useLeapData("postalCode", "cap", page)
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    const dispatch: AppDispatch = useDispatch()
    const validation = useSelector(filterValidation)

    const onAffiliation = () => {
        setButtonDisabled(true)

        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere all'invio della richiesta, compilare tutti i campi obbligatori.", 5, true))
            toggleHideMessageBoxMandatory()
            setButtonDisabled(false)
            return;
        }

        setButtonDisabled(true)

        var body = {
            denomination: page?.filter?.values.denomination?.value,
            businessName: page?.filter?.values.businessName?.value,
            cf: page?.filter?.values.cf?.value,
            piva: page?.filter?.values.piva?.value,

            address: page?.filter?.values.address?.value,
            streetAddress: page?.filter?.values.streetAddress?.value,
            postalCode: page?.filter?.values.postalCode?.value,
            municipalityID: page?.filter?.values.municipalityID?.value,
            telephoneNumber: page?.filter?.values.telephoneNumber?.value,
            email: page?.filter?.values.email?.value,
            pec: page?.filter?.values.pec?.value,
            administrativeEmail: page?.filter?.values.administrativeEmail?.value,

            lastnameLegal: page?.filter?.values.lastnameLegal?.value,
            firstnameLegal: page?.filter?.values.firstnameLegal?.value,
            fiscalCodeLegal: page?.filter?.values.fiscalCodeLegal?.value,

            provinceRegistrationClinicalManagerID: page?.filter?.values.provinceRegistrationClinicalManagerID?.value,
            numberRegistrationClinicalManager: page?.filter?.values.numberRegistrationClinicalManager?.value,
            clinicalManagerLastname: page?.filter?.values.clinicalManagerLastname?.value,
            clinicalManagerFirstname: page?.filter?.values.clinicalManagerFirstname?.value
        }

        dispatch(Affiliate(body))
            .then((response) => {
                setMessage(response?.payload?.response?.message)
                return response
            })
            .then((response) => {
                setSuccess(response?.payload?.response?.level === 4)
                return response
            })
            .then((response) => {
                setCheckboxDisabled(response?.payload?.response?.level === 4)
                setButtonDisabled(response?.payload?.response?.level === 4)
                toggleHideMessageBox();
            })
    }

    const titleStyles: React.CSSProperties = {
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        color: Theme.fluent.palette.neutralPrimaryAlt,
        display: "inline-block",
        marginLeft: 8,
        marginTop: 40,
        lineHeight: "80px",
    }
    const imageStyles: Partial<IImageStyles> = {
        root: {
            display: "inline-block",
            height: 80,
            width: 110
        },
        image: {
            display: "inline-block"
        }
    }

    const [checkboxDisabled, setCheckboxDisabled] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const [hideMessageBoxMandatory, { toggle: toggleHideMessageBoxMandatory, setFalse: showMessageBoxMandatory }] = useBoolean(true)

    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    const onDismiss = () => {
        if (!success) {
            toggleHideMessageBox()
            return
        }

        window.history.forward()
        document.location.href = "https://www.onhc.it/odontonetwork/"
    }

    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: onDismiss,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: success ? "Operazione completata" : "Operazione annullata",
            subText: message,
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-" + (success ? "success" : "error")
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const dialogPropsMandatory: IDialogProps = {
        hidden: hideMessageBoxMandatory,
        onDismiss: toggleHideMessageBoxMandatory,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione non riuscita",
            subText: "Impossibile procedere all'invio della richiesta, compilare tutti i campi obbligatori.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-warning"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    const [acceptedDeclaration, setAcceptedDeclaration] = React.useState(false);
    const onChangeDeclaration = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setAcceptedDeclaration(!!checked);
        }, []);
    const declarationText = "La informiamo che, ai sensi dell’art. 13 del Codice Privacy e dell’art.13 del GDPR, recanti disposizioni a tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali, i dati personali forniti in sede di richiesta di affiliazione a ONHC Servizi Sanitari e Welfare srl saranno raccolti e registrati da ONHC Servizi Sanitari e Welfare srl in qualità di Titolare del trattamento, con sede legale in Milano, Via Uberto Visconti di Modrone 28, rappresentata pro-tempore nella persona del legale rappresentante."

    return <>
        <Dialog {...dialogProps} onDismissed={() => success ? () => { } : () => { }}>
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <Dialog {...dialogPropsMandatory} onDismissed={() => success ? () => { } : () => { }}>
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBoxMandatory} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", margin: "30px auto 10px", padding: "10px 20px 0", width: "60%", height: "auto" }} >
            <div style={{ backgroundImage: "url('/assets/media/images/onhc-logo.png')", backgroundPosition: "20px center", backgroundRepeat: "no-repeat", backgroundSize: "180px", margin: "10px 0 20px", textAlign: "center" }}>
                <TextTitle style={titleStyles} theme={unitTheme}>Affiliazione Struttura Sanitaria</TextTitle>
            </div>
            <Grid>
                <Row>
                    <span style={{ fontSize: 16 }}>
                        Qualora siate interessati a far parte del nostro Network vi preghiamo di contattarci affinché possa essere valutata la vostra richiesta di affiliazione compilando il form seguente.
                    </span>
                </Row>
                <Section title="dati struttura">
                    <Row>
                        <Text {...denomination} className="ms-sm8" required maxlength={50} />
                    </Row>
                    <Row>
                        <Text {...businessName} className="ms-sm8" required maxlength={50} />
                    </Row>
                    <Row>
                        <Text {...cf} className="ms-sm4" required maxlength={50} />
                        <Text {...piva} className="ms-sm4" required maxlength={50} />
                    </Row>
                </Section>
                <Section title="recapiti">
                    <Row>
                        <Text {...address} className="ms-sm8" required maxlength={50} />
                        <Text {...streetAddress} className="ms-sm2" required maxlength={8} />
                        <Text {...postalCode} className="ms-sm1 ms-xxl2" required maxlength={5} />
                    </Row>
                    <Row>
                        <SearchBox {...municipalityID} className="ms-sm8" required />
                    </Row>
                    <Row>
                        <Text {...telephoneNumber} className="ms-sm4" required maxlength={50} />
                        <Text {...pec} className="ms-sm4" required maxlength={50} />
                    </Row>
                    <Row>
                        <Text {...email} className="ms-sm4" required maxlength={50} />
                        <Text {...administrativeEmail} className="ms-sm4" required maxlength={50} />
                    </Row>
                </Section>
                <Section title="rappresentante legale">
                    <Row>
                        <Text {...lastnameLegal} className="ms-sm4" required />
                        <Text {...firstnameLegal} className="ms-sm4" required />
                        <Text {...fiscalCodeLegal} className="ms-sm3" required maxlength={16} />
                    </Row>
                </Section>
                <Section title="responsabile clinico">
                    <Row>
                        <Text {...clinicalManagerLastname} className="ms-sm6" required />
                        <Text {...clinicalManagerFirstname} className="ms-sm6" required />
                    </Row>
                    <Row>
                        <SearchBox {...provinceRegistrationClinicalManagerID} className="ms-sm6" required />
                        <Text {...numberRegistrationClinicalManager} className="ms-sm3" required type="number" precision={{ integer: 16, decimal: 0 }} />
                    </Row>
                </Section>
                <Section title="privacy">
                    <Row>
                        <div className="ms-Grid-col ms-sm12" style={{ border: "1px solid #000", padding: "4px 6px", textAlign: "justify" }}>
                            <span style={{ display: "inline-block" }}>{declarationText}</span>
                            <span style={{ display: "inline-block", margin: "4px 0" }}>Per visionare l'infomativa privacy <a style={{ color: "#000", fontWeight: 600, textDecoration: "underline" }} target="_blank" href="/assets/media/docs/informativaprivacypub.pdf">cliccare qui</a>.</span>
                        </div>
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        <Separator className="ms-sm6" />
                        <Checkbox className="ms-Grid-col ms-sm6" label="Dichiaro di aver preso visione dell’informativa privacy." checked={acceptedDeclaration} onChange={onChangeDeclaration} disabled={checkboxDisabled} />
                    </Row>
                </Section>
                <Row>
                    <div style={{ margin: "30px 15px 10px 0", textAlign: "right" }}>
                        <PrimaryButton onClick={onAffiliation} text="invia richiesta" disabled={buttonDisabled || !acceptedDeclaration} />
                    </div>
                </Row>
            </Grid>
        </div>
    </>
}
