import * as React from 'react';
import { CommandBar, ICommandBarItemProps, ICommandBarProps } from '@fluentui/react/lib/CommandBar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import { security, units } from '../../features/security/thunks/securityThunk';
import Theme from '../themes/Theme';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { ProgressIndicator } from '@fluentui/react';
import { isLocked } from '../../features/operation/lockSlice';

const _horizontalMenuProps: Partial<ICommandBarProps> = {
    styles: {
        root: {
            height: 40,
            padding: 0,
        },
    }
};

const homeItem: ICommandBarItemProps[] = [{
    key: "9999999999",
    href: "/",
    name: "home",
    url: "/",
    iconOnly: true,
    iconProps: { iconName: "HomeSolid", style: { fontSize: 24, paddingBottom: 4 } }
}]

export const helpDownload = createAsyncThunk(
    'help/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'Help', args.token)
        return response
    }
)
const helpIconProps = {
    iconName: 'Help', style: {
        color: "white",
        backgroundColor: Theme.fluent.palette.themePrimary,
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "22px",
        borderRadius: "50%",
        width: 22,
        height: 22,
        verticalAlign: "baseline"
    }
}

function HMenu() {
    const unitsContext = useSelector(units)
    const dispatch: AppDispatch = useDispatch()
    const credential = useSelector(security)?.credential

    const roles = useSecurityRoles()
    const isInsuredUser: boolean = roles.isInsuredUser

    const _isLocked = useSelector(isLocked)

    if (unitsContext?.units.units === undefined || unitsContext?.units.units.length === 0) {
        return <>
        </>
    }

    const unitItems = unitsContext?.units.units.map((u: ICommandBarItemProps) => u)
    const menuItems: ICommandBarItemProps[] = homeItem.concat(unitItems)

    const helpItem: ICommandBarItemProps = {
        key: 'help',
        text: '',
        iconProps: helpIconProps,
        onClick: (e: any) => { dispatch(helpDownload({ controller: "Files", body: { form: { fileName: "ONHC-Help.pdf" }, page: {} }, token: credential.token })) },
        iconOnly: true,
        name: "help"
    }

//TODO: LOCK
/*
    return <>
        <CommandBar className="leap-horizontal-menu" {..._horizontalMenuProps} items={menuItems} farItems={isInsuredUser ? undefined : [helpItem]} />
        < div style={{ margin: "0 auto" }}>
            <ProgressIndicator styles={{ root: { margin: 0, padding: 0 }, itemProgress: { padding: 0 }, progressTrack: { backgroundColor: "#fff" } }} barHeight={1.5} percentComplete={_isLocked ? undefined : 0} />
        </div >
    </>
*/

    return <CommandBar className="leap-horizontal-menu" {..._horizontalMenuProps} items={menuItems} farItems={isInsuredUser ? undefined : [helpItem]} />

}

export default HMenu;