import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';
import SearchBox from '../../components/controls/SearchBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const insured = useLeapData("insured", "assistito", page, "insureds")
    const corporation = useLeapData("corporation", "azienda", page, "corporations")
    const registerNumber = useLeapData("registerNumber", "matricola", page)
    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)
    const policy = useLeapData("policy", "polizza", page)

    const isWelfare: boolean = props.state.item.values.leap_PlanManagementID.value === "2"

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...insured} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <SearchBox {...corporation} className="ms-sm12" required={isWelfare} />
                    </Row>
                    <Row>
                        <Text {...registerNumber} className="ms-sm4" maxlength={12} />
                        <DateRange dateMin={{ ...start_date, className: "ms-sm4", required: true }} dateMax={{ ...end_date, className: "ms-sm4" }} />
                    </Row>
                    <Row>
                        <Text {...policy} className="ms-sm12" maxlength={50} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
