import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DentalChart from '../../components/controls/dental/DentalChart';
import { DentalElements } from '../../components/controls/dental/dentalElements';
import { IComboBoxOption } from '@fluentui/react';
import MultiBox from '../../components/controls/MultiBox';

import LogList from './RateTableHealthServiceLogList';
import RateTableHealthServiceSuspensionList from './RateTableHealthServiceSuspensionList';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthServiceID = useLeapData("healthServiceID", "prestazione", page)
    const rate = useLeapData("rate", "tariffa", page)
    const dentalElement = useLeapData("dentalElement", "elementi dentali", page)
    const endDate = useLeapData("endDate", "data fine validità", page)

    const items: IComboBoxOption[] = DentalElements
    const selectedTeeth = page.item.values?.dentalElement?.value != null ? page.item.values.dentalElement?.value : (dentalElement?.value[0] !== "" ? dentalElement?.value : []);

    const log_show = page.item.state.lists?.Log !== null && page.item.state.lists?.Log.length !== 0

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...healthServiceID} className="ms-sm10" readOnly />
                    </Row>
                    <Row>
                        <Text {...rate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                        <Text {...endDate} className="ms-sm3" type="calendar" />
                    </Row>
                    <Row>
                        <MultiBox {...dentalElement} className="ms-sm10" options={items} selectedKey={selectedTeeth} />
                    </Row>
                    <Section title="elementi dentali" collapsable={true}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <Row>
                                <DentalChart {...dentalElement} selectionMode="multiple" value={selectedTeeth} />
                            </Row>
                        </div>
                    </Section>
                </Section>

                <Section title="log" hidden={!log_show}>
                    <Row hidden={!log_show}>
                        <LogList items={page.item.state?.lists.Log} controller={props.controller} />
                    </Row>
                </Section>

                <Section title="sospensioni">
                    <Row>
                        <RateTableHealthServiceSuspensionList items={page.item.state?.lists.Suspension} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
