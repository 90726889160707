export enum Roles {
    ONHCMaster = 1,
    ONHCAccount = 2,
    ONHCAdmin = 3,
    ONHCOperator = 4,
    HFMaster = 5,
    HFAdmin = 6,
    HFOperator = 7,
    HFUser = 8,
    HFAccount = 13,
    InsuredUser = 9,
    Corporation = 10,
    Company = 11,
    Consultant = 12,
    HFAffiliation = 14,
    SYS = 99,
    ONHCAffiliationOperator = 15,
    ONHCAffiliationAccount = 16
}