import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';
import PermissionsList from '../features/role/PermissionsList';
import RoleResultList from '../features/role/RoleResultList';
import PermissionModal from '../features/role/PermissionModal';
import PermissionModalEdit from '../features/role/PermissionModalEdit';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';
import Checkbox from '../components/controls/CheckBox';

export default () => {
    const controller = "Role";
    const PermissionModalController = "PermissionModal";
    const page = useLeapState(controller)
    const name = useLeapData("name", "ruolo", page, 'roles')
    const unit_name = useLeapData("unit_name", "Funzioni", page, 'units')
    const user_nickname = useLeapData("user_nickname", "utente", page, 'users')
    const organization_name = useLeapData("organization_name", "organizzazione", page, 'organizations')
    const start_date = useLeapData("start_date", "inizio validità", page)
    const end_date = useLeapData("end_date", "fine validità", page)
    const uid = useLeapData("uid", "uid", page)
    const mfa = useLeapData("totpEnabled", "mfa abilitato", page)

    return <>
        <Content title="Ruolo" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm5" maxlength={50} />
                            <Text {...unit_name} className="ms-sm5" maxlength={50} />
                        </Row>
                        <Row>
                            <Text {...user_nickname} className="ms-sm5" maxlength={50} />
                            <Text {...organization_name} className="ms-sm5" maxlength={50} />
                        </Row>
                        <Row>
                            <Text {...start_date} className="ms-sm2" type="calendar" />
                            <Text {...end_date} className="ms-sm2" type="calendar" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <RoleResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...name} className="ms-sm6" required maxlength={50} />
                            <Checkbox {...mfa} className="ms-sm6" />
                        </Row>
                        <Row>
                            <Text {...start_date} className="ms-sm2" type="calendar" required />
                            <Text {...end_date} className="ms-sm2" type="calendar" />
                            <Text {...uid} className="ms-sm2" type="number" precision={{ integer: 3, decimal: 0 }} readOnly />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="Permessi" toolbar="grid" modal={<PermissionModal state={page} controller={PermissionModalController} />} controller={PermissionModalController} modalwidth="600px" modaltitle="Inserisci Permesso" state={page} >
                        <Row>
                            <PermissionsList items={page.item.state?.lists.Permissions} modal={<PermissionModalEdit state={page} controller={PermissionModalController} />} controller={PermissionModalController} modalwidth="600px" modaltitle="Modifica Permesso" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
