import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState'
import { useLeapData } from '../hooks/useLeapData'
import { useSecurityRoles } from '../hooks/useSecurityRoles';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Separator from '../components/controls/grid/Separator';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import DateRange from '../components/controls/DateRange';

import PlafondResultList from '../features/plafond/PlafondResultList';
import PlafondHealthServiceList from '../features/plafond/PlafondHealthServiceList';
import PlafondHealthServiceModal from '../features/plafond/PlafondHealthServiceModal';
import PlafondHealthServiceModalEdit from '../features/plafond/PlafondHealthServiceModalEdit';

export default () => {
    const controller = "Plafond"
    const plafondsHealthServiceController = "PlafondHealthService"
    const page = useLeapState(controller)
    const roles = useSecurityRoles()
    //const endDate_state: string = page?.item?.state?.data?.endDate;

    const code = useLeapData("code", "codice", page)
    const name = useLeapData("name", "nome", page)
    const description = useLeapData("description", "descrizione", page)

    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const leap_PlafondTypeID = useLeapData("leap_PlafondTypeID", "tipo plafond", page, "plafondTypeOption")

    const duration = useLeapData("duration", "durata (in mesi)", page)
    const timesNumber = useLeapData("timesNumber", "numero di volte", page)
    const qmTimesNumber = useLeapData("qmTimesNumber", "numero piani cure", page)
    const roof = useLeapData("roof", "tetto", page)
    const maxErosionPercentage = useLeapData("maxErosionPercentage", "percentuale massima di erosione", page)
    const leap_IndirectManagementTypeID = useLeapData("leap_IndirectManagementTypeID", "gestione indiretta", page, "indirectManagementTypeOption")

    //documentazione obbligatoria
    const leap_DocumentationRequiredID = useLeapData("leap_DocumentationRequiredID", "documentazione obbligatoria", page, "documentationRequiredOption")

    const healthServices_empty = page?.item?.state?.lists?.HealthServices === null || page?.item?.state?.lists?.HealthServices?.length === 0

    return <>
        <Content title="Plafonds" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm2" type="number" precision={{ integer: 6, decimal: 0 }} />
                            <Text {...name} className="ms-sm8" />
                        </Row>

                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <PlafondResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" type="number" precision={{ integer: 6, decimal: 0 }} readOnly maxlength={6} />
                            <Text {...name} className="ms-sm8" required />
                        </Row>
                        <Row>
                            <Text {...description} className="ms-sm10" required />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2", required: page.modality === "new", readOnly: page.modality === "item" }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                            <Text {...duration} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 2, decimal: 0, max: 96 }} required />
                            <Separator className="ms-sm1 ms-hiddenXlDown" />
                            <Option {...leap_PlafondTypeID} className="ms-sm3" type="horizontal" required />
                            <Option {...leap_DocumentationRequiredID} className="ms-sm3" type="horizontal" required />
                        </Row>
                        <Row>
                            <Text {...timesNumber} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 3, decimal: 0, min: page?.item?.values?.roof?.value === '0' ? 1 : 0 }} required={(page.item?.values?.roof?.value === "" || page.item?.values?.roof?.value === undefined)} />
                            <Separator className="ms-sm1 ms-hiddenXlDown" />
                            <Text {...qmTimesNumber} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 3, decimal: 0, min: page?.item?.values?.roof?.value === '0' ? 1 : 0 }} />
                            <Separator className="ms-sm1 ms-hiddenXlDown" />
                            <Text {...roof} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 9, decimal: 2, min: page?.item?.values?.timesNumber?.value === '0' ? 1 : 0 }} />
                            <Separator className="ms-sm1 ms-hiddenXlDown" />
                            <Text {...maxErosionPercentage} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 2, decimal: 0 }} />
                            <Separator className="ms-sm2" />
                            <Option {...leap_IndirectManagementTypeID} className="ms-sm3" type="horizontal"/>
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section hidden={roles.isONHCAccount && healthServices_empty} title="elenco prestazioni" toolbar="grid" modal={<PlafondHealthServiceModal state={page} controller={plafondsHealthServiceController} />} controller={plafondsHealthServiceController} modalwidth="600px" modaltitle="Inserisci una Prestazione" state={page} >
                        <Row hidden={roles.isONHCAccount && healthServices_empty}>
                            <PlafondHealthServiceList items={page.item.state?.lists.HealthServices} modal={<PlafondHealthServiceModalEdit state={page} controller={plafondsHealthServiceController} />} controller={plafondsHealthServiceController} modalwidth="600px" modaltitle="Modifica una Prestazione" state={page} />
                        </Row>
                    </Section>)}

                </Grid>
            </Item>
        </Content>
    </>
}
