import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { render } from '@testing-library/react';

type Props = {
    items: any,
    controller: any,
    state?: any
}

export default (props: Props) => {
    const onDeleting = (e: any) => {
        if (!e.nativeEvent.deletable) {
            e.cancel = true
            e.message = "Impossibile eliminare una prestazione di un piano di cure"
        }
    }

    const isONHC = useSecurityRoles()?.isONHC;

    return (
        <>
            <DataList items={props.items} onDeleting={onDeleting} modalController={props.controller} state={props.state}>
                {isONHC && <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />}
                <DataColumn kid='column1' name='Elemento Dentale' fieldName='dentalElement' minWidth={110} maxWidth={110} align='center' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthService' minWidth={190} align='left' />
                <DataColumn kid='column3' name='Fine Cure' fieldName='endOfCureDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column4' name='Data Cancellazione' fieldName='rejectionDate' minWidth={120} maxWidth={120} align='center' />
            </DataList>
        </>
    );
}
