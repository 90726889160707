import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const code = useLeapData("code", "codice", page)
    const leap_HealthFacilityOperatorAccessID = useLeapData("leap_HealthFacilityOperatorAccessID", "accesso", page, "accessOptions")
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const email = useLeapData("email", "email", page)

    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...code} className="ms-sm6" readOnly />
                    </Row>
                    <Row>
                        <Text {...lastname} className="ms-sm6" required />
                        <Text {...firstname} className="ms-sm6" required />
                    </Row>
                    <Row>
                        <Text {...email} className="ms-sm6" required />
                        <Option {...leap_HealthFacilityOperatorAccessID} className="ms-sm4" type="horizontal" required />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...start_date, className: "ms-sm4", required: true }} separator={{className : "ms-sm2"}} dateMax={{ ...end_date, className: "ms-sm4"}} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
