import { Stack, StackItem, Text as Text2 } from '@fluentui/react/';
import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import { unitTheme } from '../components/themes/Fonts';
import Theme from '../components/themes/Theme';
import MedicalRecordsFiles from '../features/medicalRecords/MedicalRecordsFiles';
import MedicalRecordsModal from '../features/medicalRecords/MedicalRecordsModal';
import MedicalRecordsModalEdit from '../features/medicalRecords/MedicalRecordsModalEdit';
import MedicalRecordsResultList from '../features/medicalRecords/MedicalRecordsResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import Text from '../components/controls/Text';

export default () => {
    const headerSectionStyles: React.CSSProperties = {
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        margin: Theme.leap.main.content.section.margin,
        padding: Theme.leap.main.content.section.padding,
    };
    const controller = "MedicalRecords"

    const MedicalRecordsModalController = "MedicalRecordsModal"

    const roles = useSecurityRoles()
    const isInsured: boolean = roles.isInsuredUser
    const isHF: boolean = roles.isHFUser || roles.isHFOperator || roles.isHFMaster || roles.isHFAdmin || roles.isHFAccount;
    const page = useLeapState(controller, roles.role !== undefined, isInsured ? "item" : "filter")

    const insuredFiscalCode = useLeapData("insured", "assistito", page, "insured")
    const lastName = useLeapData("lastName", "cognome", page)
    const firstName = useLeapData("firstName", "nome", page)

    return <>
        {/* {isInsured && <>
            <section style={headerSectionStyles}>
                <Stack horizontal style={{ paddingRight: 8, width: "100%" }}>
                    <StackItem grow align="start">
                        <Text2 theme={unitTheme}>Cartella Clinica</Text2>
                    </StackItem>
                </Stack>
                <Filter>
                    <Grid>
                        <Section title="elenco files caricati" toolbar="grid">
                            <Row>
                                <MedicalRecordsFiles items={page.filter.state?.lists.Files} controller={controller} state={page} />
                            </Row>
                        </Section>
                    </Grid>
                </Filter>
            </section>
        </>}
        {!isInsured && <>*/}
        <Content title={isInsured ? "La mia cartella clinica" : "Cartella Clinica"} controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...insuredFiscalCode} className="ms-sm6" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <MedicalRecordsResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...insuredFiscalCode} className="ms-sm3" readOnly />
                            <Text {...lastName} className="ms-sm3" readOnly />
                            <Text {...firstName} className="ms-sm3" readOnly />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="elenco files caricati" toolbar="grid" modal={<MedicalRecordsModal state={page} controller={MedicalRecordsModalController} />} controller={MedicalRecordsModalController} modaltitle="Inserisci un file" state={page} modalwidth="600px">
                        <Row>
                            <MedicalRecordsFiles items={page.item.state?.lists.Files} modal={<MedicalRecordsModalEdit state={page} controller={MedicalRecordsModalController} />} controller={MedicalRecordsModalController} modaltitle="Modifica un file" modalwidth="600px" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
}
