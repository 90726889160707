import * as React from 'react';
import { useSelector } from 'react-redux'

import { userRoles } from '../features/security/thunks/securityThunk';
import { Roles } from '../defs/roles';

export const useSecurityRoles = () => {
    const roles = useSelector(userRoles)
    const role = Number(roles)
    return {
        role: roles ? role : undefined,
        isONHCMaster: (role === Roles.ONHCMaster),
        isONHCAccount: (role === Roles.ONHCAccount),
        isONHCAdmin: (role === Roles.ONHCAdmin),
        isONHCOperator: (role === Roles.ONHCOperator),
        isHFMaster: (role === Roles.HFMaster),
        isHFAdmin: (role === Roles.HFAdmin),
        isHFOperator: (role === Roles.HFOperator),
        isHFUser: (role === Roles.HFUser),
        isHFAccount: (role === Roles.HFAccount),
        isInsuredUser: (role === Roles.InsuredUser),
        isCorporation: (role === Roles.Corporation),
        isCompany: (role === Roles.Company),
        isConsultant: (role === Roles.Consultant),
        isSYS: (role === Roles.SYS),
        isONHCAffiliationAccount: (role === Roles.ONHCAffiliationAccount),
        isONHCAffiliationOperator: (role === Roles.ONHCAffiliationOperator),
        isHFAffiliation: (role === Roles.HFAffiliation),

        isONHC: (role === Roles.ONHCMaster || role === Roles.ONHCAccount || role === Roles.ONHCAdmin || role === Roles.ONHCOperator || role === Roles.ONHCAffiliationAccount || role === Roles.ONHCAffiliationOperator),
        isHF: (role === Roles.HFMaster || role === Roles.HFAdmin || role === Roles.HFOperator || role === Roles.HFUser || role === Roles.HFAccount),
    }
}