import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/rootReducer'
import { LockPayload } from '../../defs/thunks'

const LockSlice = createSlice({
    name: 'locked',
    initialState: { locked: false } as LockPayload,
    reducers: {
        lock: {
            reducer(state, action: PayloadAction<LockPayload>) {
                state.locked = action.payload.locked;
            },
            prepare() {
                return { payload: { locked: true } }
            }
        },
        unlock: {
            reducer(state, action: PayloadAction<LockPayload>) {
                state.locked = action.payload.locked;
            },
            prepare() {
                return { payload: { locked: false } }
            }
        },
    }
})

export const { lock, unlock } = LockSlice.actions

export const isLocked = (state: RootState) => state.lock.locked

export default LockSlice.reducer