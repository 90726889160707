import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any,
}

export default (props: Props) => {
  
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='column1' name='Codice Fiscale' fieldName='fiscalcode' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Cognome' fieldName='lastname' minWidth={220} maxWidth={220}align='left' />
                <DataColumn kid='column3' name='Nome' fieldName='firstname' minWidth={220} maxWidth={220} align='left' />
                <DataColumn kid='column4' name='Grado di Parentela' fieldName='relationshipTierDescr' minWidth={100} align='left' />
            </DataList>
        </>
    );
}
