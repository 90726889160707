import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

export default (props: { items: any }) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Utente' fieldName='user' minWidth={100} align='left' />

                <DataColumn kid='column2' name='Data A.W. Precedente' fieldName='previousMembershipWelfareDate' minWidth={200} align='center' />
                <DataColumn kid='column3' name='Data A.W. Nuova' fieldName='newMembershipWelfareDate' minWidth={200} align='center' />

                <DataColumn kid='column4' name='A.W.' fieldName='previousLeap_HealthFacilityMembershipWelfare' minWidth={100} align='center' />
                <DataColumn kid='column5' name='A.W.' fieldName='newLeap_HealthFacilityMembershipWelfare' minWidth={100} align='center' />

                <DataColumn kid='column6' name='Mandato W.' fieldName='previousLeap_HealthFacilityWelfareWarrant' minWidth={100} align='center' />
                <DataColumn kid='column7' name='Mandato W.' fieldName='newLeap_HealthFacilityWelfareWarrant' minWidth={100} align='center' />
            </DataList>
        </>
    );
}