import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';

import { AppDispatch } from '../../app/store';

import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';
import { useDatagridOperation } from '../../hooks/useDatagridOperation';
import { resetResponse, setResponse } from '../response/responseSlice';

import { security } from '../security/thunks/securityThunk';
import { resultLoad } from '../operation/thunks/resultThunk';
import { filterForm } from '../operation/thunks/filterThunk';

import { DefaultButton, DialogFooter, FontSizes, FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton, IDialogProps, DialogType, Dialog } from '@fluentui/react';

import Theme from '../../components/themes/Theme';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import Separator from '../../components/controls/grid/Separator';

type Props = {
    hidden?: boolean,
    controller: string,
    parentController: string,
    selectedValues: any,
    index: any,
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const datagridOperation = useDatagridOperation(props.controller, credential.token)

    const page = useLeapState(props.controller, false)
    const invoiceDate = useLeapData("invoiceDate", "data fattura", page)
    const paymentDate = useLeapData("paymentDate", "data pagamento", page)
    const invoiceDescription = useLeapData("invoiceDescription", "descrizione fattura", page)

    const fForm = useSelector(filterForm)
    const securityContext = useSelector(security)
    const token = securityContext?.credential?.token

    //campi della modale
    const invoiceDateItem = page?.item?.state?.data?.invoiceDate
    const paymentDateItem = page?.item?.state?.data?.paymentDate
    const invoiceDescriptionItem = page?.item?.state?.data?.invoiceDescription

    const [invoiceDateValue, setInvoiceDateValue] = React.useState<any>()
    React.useEffect(() => {
        setInvoiceDateValue(invoiceDateItem)
    }, [invoiceDateItem])

    const [invoiceDescriptionValue, setInvoiceDescriptionValue] = React.useState<any>()
    React.useEffect(() => {
        setInvoiceDescriptionValue(invoiceDescriptionItem)
    }, [invoiceDescriptionItem])

    const hasPaymentDate = !(paymentDateItem === undefined || paymentDateItem === null || paymentDateItem === "" || paymentDateItem.toString().trim() === "")

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: hasPaymentDate ? '400px' : '360px'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }

    // apertura modale invoice data
    const openModal = () => {
        const parentItemForm: any = {}
        let parentItemPage: any = {}

        let body = {
            form: { selectedIDs: props.selectedValues },
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }

        datagridOperation.new(body)
            .then((res) => {
                if (res.payload.data.error === 1) {
                    setMsgBoxMessageValue("per procedere selezionare dei piani di cura");
                    showMessageBox()
                    return
                }
                else if (res.payload.data.error === 2) {
                    setMsgBoxMessageValue("i piani di cura compresi nella selezione sono già associati a una fattura ONHC");
                    showMessageBox()
                    return
                }
                else if (res.payload.data.error === 3) {
                    setMsgBoxMessageValue("i piani di cura compresi nella selezione devono avere impostata la data di pagamento");
                    showMessageBox()
                    return
                }
                else if (res.payload.data.error === 4) {
                    setMsgBoxMessageValue("i piani di cura compresi nella selezione non sono rimborsabili");
                    showMessageBox()
                    return
                }

                showModal()
            })
    }

    //salvataggio dati nella modale invoice data
    const Save = () => {
        const hasInvoiceDate = !(invoiceDateValue === undefined || invoiceDateValue === null || invoiceDateValue === "" || invoiceDateValue.toString().trim() === "")

        if (!hasInvoiceDate) {
            dispatch(setResponse("Per procedere alla generazione delle fatture è necessario indicare la data fattura.", 5, true))
            return;
        }

        const hasInvoiceDescription = !(invoiceDescriptionValue === undefined || invoiceDescriptionValue === null || invoiceDescriptionValue === "" || invoiceDescriptionValue.toString().trim() === "")

        if (!hasInvoiceDescription) {
            dispatch(setResponse("Per procedere alla generazione delle fatture è necessario indicare la descrizione della fattura.", 5, true))
            return;
        }

        const gridItemForm = {
            invoiceDate: invoiceDateValue,
            invoiceDescription: invoiceDescriptionValue,
            selectedIDs: props.selectedValues
        }
        const parentItemForm = {}
        let parentItemPage = {}

        let body = {
            form: gridItemForm,
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }

        datagridOperation.save(body)
            .then((response) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
                if (response.payload.response.level === 4) {
                    hideModal()
                    return true
                }
                else {
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(resultLoad({ controller: props.parentController, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === props.parentController)?.value, page: { page, index: props.index } }, token: token }))
                }
            })
    }

    /*dialog per gestione messaggi errore nel l'apertura della invoice data*/
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const msgBoxMessageItem = "testo della messagebox";
    React.useEffect(() => { setMsgBoxMessageValue(msgBoxMessageItem) }, [msgBoxMessageItem])
    const [msgBoxMessageValue, setMsgBoxMessageValue] = React.useState<any>()
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: () => {
            setInvoiceDateValue(null)
            setInvoiceDescriptionValue(null)
            toggleHideMessageBox()
        },
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: msgBoxMessageValue,//"Impossibile modificare i dati fattura precedentemente inseriti",
            title: "Impossibile procedere",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-error"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    return (
        <>
            <Dialog {...dialogProps} >
                <DialogFooter >
                    <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
                </DialogFooter>
            </Dialog>

            <PrimaryButton text="emissione fattura ONHC" onClick={() => openModal()} style={props.hidden ? { display: "none" } : { marginRight: 2 }} />
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container} >
                <div className={contentStyles.header}>
                    <span>{"Emissione fattura ONHC"}</span>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <Row>
                            <Text {...invoiceDescription} value={invoiceDescriptionItem} className="ms-sm12" required
                                onChange={(_name: any, value: any, _required: any) => { setInvoiceDescriptionValue(value) }} />
                        </Row>
                        <Row>
                            {hasPaymentDate && <Text {...paymentDate} value={paymentDateItem} className="ms-sm6" type="calendar" readOnly />}
                            {!hasPaymentDate && <Text {...paymentDate} value={"{multiple}"} className="ms-sm6" readOnly />}
                            <Text {...invoiceDate} value={invoiceDateItem} dateRange={{ minDate: Date().toLocaleString(), maxDate: undefined, }} className="ms-sm6" type="calendar" required
                                onChange={(_name: any, value: any, _required: any) => { setInvoiceDateValue(value) }} />
                        </Row>
                    </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={Save} text="Genera le fatture ONHC" />
                        <DefaultButton onClick={onCancel} text="Annulla" />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}
