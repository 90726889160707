import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../app/store';
import { security } from '../features/security/thunks/securityThunk';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import { itemDownload, itemData } from '../features/operation/thunks/itemThunk';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import { PrimaryButton } from '@fluentui/react';

import ADEResultList from '../features/ade/ADEResultList';

import Text from '../components/controls/Text';
import ComboBox from '../components/controls/ComboBox';
import SearchBox from '../components/controls/SearchBox';
import Option from '../components/controls/Option';

export default () => {
    const controller = "ADE"
    const page = useLeapState(controller)

    const credential = useSelector(security)?.credential
    const dispatch: AppDispatch = useDispatch()

    const iForm = useSelector(itemData)

    const downloadFile = () => {
        const args = {
            controller: controller,
            id: iForm.find(i => i.key === controller)?.value?.data?.id,
            fileID: '0',
            fileName: page?.item?.state?.data?.description + " - export.csv",
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    const description = useLeapData("description", "descrizione", page)
    const year = useLeapData("year", "anno di competenza", page, "years")
    const plan = useLeapData("plan", "piano sanitario", page, "plans")
    const planRefundManagement = useLeapData("planRefundManagement", "gestione rimborso", page, "planRefundManagements")
    const createDate = useLeapData("createDate", "data creazione", page)

    return <>
        <Content title="ADE - Agenzia delle Entrate" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...description} className="ms-sm12" />
                        </Row>
                        <Row>
                            <ComboBox {...year} className="ms-sm2" />
                            <SearchBox {...plan} className="ms-sm10" />
                        </Row>
                        <Row>
                            <Option {...planRefundManagement} className="ms-sm4" type="horizontal" required />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <ADEResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...description} className="ms-sm12" required maxlength={100} />
                        </Row>
                        <Row>
                            <ComboBox {...year} className="ms-sm2" required={page.modality === "new"} disabled={page.modality === "item"} />
                            <SearchBox {...plan} className="ms-sm10" disabled={page.modality === "item"} />
                        </Row>
                        <Row>
                            <Option {...planRefundManagement} className="ms-sm4" type="horizontal" required={page.modality === "new"} disabled={page.modality === "item"} />
                        </Row>
                        <Row>
                            {page.modality === "item" && <>
                                <Text {...createDate} className="ms-sm2" readOnly />
                                <div className="ms-Grid-col ms-sm2">
                                    <PrimaryButton onClick={downloadFile} style={{ marginTop: 23 }}>download</PrimaryButton>                                    
                                </div>
                            </>}
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
