import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { setOperation } from '../operation/operationSlice';
import { itemDownload } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';
import { AppDispatch } from '../../app/store';
import { unlock } from '../operation/lockSlice';

export default (props: { controller: string, items: any }) => {
    const dispatch: AppDispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const downloadFile = (_e: any, _data: any, item: any) => {
        const args = {
            controller: props.controller,
            fileID: item?.fileID,
            fileName: item?.fileName,

            //fileID: 2339,
            //fileName: "ckemi.docx",
        }

        console.log(args)

        dispatch(itemDownload(args))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={downloadFile} >
                <DataColumn kid='column1' name='Documento' fieldName='description' minWidth={250} align='left' />
            </DataList>
        </>
    );
}
