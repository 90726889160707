import { DefaultButton, DialogFooter, FontSizes, FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton, IDialogProps, DialogType, Dialog } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import Theme from '../../components/themes/Theme';
import { useDatagridOperation } from '../../hooks/useDatagridOperation';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import { resetResponse, setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';
import { resultLoad } from '../operation/thunks/resultThunk';
import { filterForm } from '../operation/thunks/filterThunk';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';

type Props = {
    hidden?: boolean,
    controller: string,
    parentController: string,
    selectedValues: any,
    index: any,
}

export const eliminaDatiPagamento = createAsyncThunk(
    'item/eliminaDatiPagamento',
    async (args: { EcID: string, selectedID: any, token: string | undefined }) => {
        const response = await client.post("EditInvoiceData", { form: { selectedIDs: args.selectedID, selectedEC: args.EcID } }, 'eliminaDatiPagamento', args.token)
        return response
    }
)

export default (props: Props) => {
    const roles = useSecurityRoles()

    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCAccount: boolean = roles.isONHCAccount;

    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '30vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }
    const credential = useSelector(security)?.credential

    const datagridOperation = useDatagridOperation(props.controller, credential.token)
    const page = useLeapState(props.controller, false)

    const fForm = useSelector(filterForm)
    const securityContext = useSelector(security)
    const token = securityContext?.credential?.token

    const selectedEC = fForm.find(i => i.key === "AccountingSituation")?.value.accountStatementCodeID
    const isSelectedEC = selectedEC != undefined

    // apertura modale invoice data
   
    const [saveAllowed, setSaveAllowed] = React.useState<boolean>()
    const [isDeletable, setIsDeletable] = React.useState<boolean>()

    const openModal = () => {

        const parentItemForm: any = {}
        let parentItemPage: any = {}

        let body = {
            form: { selectedIDs: props.selectedValues, selectedEC: selectedEC },
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }

        datagridOperation.new(body)

            .then((res) => {
                setSaveAllowed(true)
                setIsDeletable(false)

                if ((isONHCAccount || isONHCAdmin || isONHCMaster)) {
                    let fields = !(res.payload.data.invoiceNumber === undefined || res.payload.data.invoiceNumber === null || res.payload.data.invoiceNumber === "" || res.payload.data.invoiceNumber.toString().trim() === "")
                        && !(res.payload.data.invoiceDate === undefined || res.payload.data.invoiceDate === null || res.payload.data.invoiceDate === "" || res.payload.data.invoiceDate.toString().trim() === "")

                    if (fields) {
                        //vedo il tasto elimina
                        setIsDeletable(true)
                    }

                    if (!isSelectedEC) {
                        if (!(Object.keys(props.selectedValues ?? {}).length > 0)) {
                            setMsgBoxMessageValue("filtrare per estratto conto o selezionare righe non appartenti a un estratto conto cui associare i dati della fattura");
                            showMessageBox()
                            return
                        }
                        else {
                            //la finestra deve essere apribile in visualizzazione per poter eliminare i dati del pagamento
                            if (res.payload.data.error === 1) {
                                setMsgBoxMessageValue("selezionare delle righe cui associare i dati fattura");
                                showMessageBox()
                                return
                            }
                            else if (res.payload.data.error === 2) {
                                setMsgBoxMessageValue("una delle righe comprese nella selezione e' associata a un estratto conto. Filtrare per estratto conto o selezionare righe non appartenti a un estratto conto");
                                showMessageBox()
                                return
                            }
                            else if (res.payload.data.error === 3) {
                                setMsgBoxMessageValue("le righe selezionate sono relative a piu' di una partita iva");
                                showMessageBox()
                                return
                            }
                            else if (res.payload.data.error === 4) {
                                setMsgBoxMessageValue("le righe selezionate contengono piani di cura non rimborsabili");
                                showMessageBox()
                                return
                            }
                        }
                    }
                }
                else { //se non si � un onhc non � possibile cancellare i dati quindi non apro la finestra in visualizzazione
                    if (!isSelectedEC) {
                        if (!(Object.keys(props.selectedValues ?? {}).length > 0)) {
                            setMsgBoxMessageValue("Filtrare per estratto conto o selezionare righe non appartenti a un estratto conto");
                            showMessageBox()
                            return
                        }
                        else {
                            if (res.payload.data.error === 1) {
                                setMsgBoxMessageValue("selezionare delle righe cui associare i dati fattura");
                                showMessageBox()
                                return
                            }
                            else if (res.payload.data.error === 2) {
                                setMsgBoxMessageValue("una delle righe comprese nella selezione e' associata a un estratto conto. Filtrare per estratto conto o selezionare righe non appartenti a un estratto conto");
                                showMessageBox()
                                return
                            }
                            else if (res.payload.data.error === 3) {
                                setMsgBoxMessageValue("le righe selezionate sono relative a pi� di una partita iva");
                                showMessageBox()
                                return
                            }
                        }
                    }
                    
                    let fields = !(res.payload.data.invoiceNumber === undefined || res.payload.data.invoiceNumber === null || res.payload.data.invoiceNumber === "" || res.payload.data.invoiceNumber.toString().trim() === "")
                        && !(res.payload.data.invoiceDate === undefined || res.payload.data.invoiceDate === null || res.payload.data.invoiceDate === "" || res.payload.data.invoiceDate.toString().trim() === "")

                    if (fields) {
                        setMsgBoxMessageValue("Impossibile aggiornare dati fattura gia' presenti a sistema");
                        showMessageBox()
                        return
                    }

                }
                showModal()        
            })
    }

    //salvataggio dati nella modale invoice data
    const Save = () => {
 
        let mandatory = !(invoiceNumberValue === undefined || invoiceNumberValue === null || invoiceNumberValue === "" || invoiceNumberValue.toString().trim() === "")
            && !(invoiceDateValue === undefined || invoiceDateValue === null || invoiceDateValue === "" || invoiceDateValue.toString().trim() === "")
            && !(withholdingTaxValue === undefined || withholdingTaxValue === null || withholdingTaxValue === "" || withholdingTaxValue.toString().trim() === "")
            && !(ibanValue === undefined || ibanValue === null || ibanValue === "" || ibanValue.toString().trim() === "")
            && !(headerCCValue === undefined || headerCCValue === null || headerCCValue === "" || headerCCValue.toString().trim() === "")

        if (isONHCMaster)
            mandatory = mandatory && !(paymentDateValue === undefined || paymentDateValue === null || paymentDateValue === "" || paymentDateValue.toString().trim() === "")

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere al salvataggio.", 5, true))
            return;
        }

        const gridItemForm = {
            invoiceNumber: invoiceNumberValue,
            invoiceDate: invoiceDateValue,
            paymentDate: paymentDateValue,
            selectedIDs: props.selectedValues,
            accountStatementID: selectedEC,
            withholdingTax: withholdingTaxValue,
            iban: ibanValue,
            header_cc: headerCCValue

        }
        const parentItemForm = {}
        let parentItemPage = {}

        let body = {
            form: gridItemForm,
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }


        datagridOperation.save(body)
            .then((response) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
                if (response.payload.response.level === 4) {
                    hideModal()
                    return true
                }
                else {
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(resultLoad({ controller: props.parentController, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === props.parentController)?.value, page: { page, index: props.index } }, token: token }))
                       // dispatch(resultLoad({ controller: props.controller, modality: "filter", operation: "search", body: { form: {  ...form, accountStatementCodeID: "1111111111"  }, page: { index: index } }, token: credential.token }))
                }
            })
    }

    /*dialog per gestione messaggi errore nel l'apertura della invoice data*/
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const msgBoxMessageItem = "testo della messagebox";
    React.useEffect(() => { setMsgBoxMessageValue(msgBoxMessageItem) }, [msgBoxMessageItem])
    const [msgBoxMessageValue, setMsgBoxMessageValue] = React.useState<any>()
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: msgBoxMessageValue,//"Impossibile modificare i dati fattura precedentemente inseriti",
            title: "Impossibile procedere",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-error"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    //dialog per richiesta conferma per l'eliminazione dei dati del pagamento
    const args = { EcID: selectedEC, selectedID: props.selectedValues, token: credential?.token }
    const onClickEliminaDatiPagamento = (e: any) => {
        e.preventDefault()
        toggleHideDialogEliminaDatiPagamento()
    }
    const onExecuteEliminaDatiPagamento = (e: any) => {
        dispatch(eliminaDatiPagamento(args))
            .then((response) => {
                toggleHideDialogEliminaDatiPagamento()
             dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
             if (response.payload.response.level === 4) {
                 hideModal()
                 return true
             }
             else {
                 return false
             }
         })
        .then((proceed) => {
            if (proceed) {
                hideModal()
                dispatch(resultLoad({ controller: props.parentController, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === props.parentController)?.value, page: { page, index: props.index } }, token: token }))
            }
        })


    }
    const dialogStyles = { main: { maxWidth: 450 } };
    const [hideDialogEliminaDatiPagamento, { toggle: toggleHideDialogEliminaDatiPagamento }] = useBoolean(true)
    const dialogPropsEliminaDatiPagamento = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con l\'eliminazione dei dati pagamento precedentemente inseriti?',
    };
    const modalPropsEliminaDatiPagamento = React.useMemo(
        () => ({
            title: "Richiesta Conferma",
            isBlocking: false,
            styles: dialogStyles,
        }),
        [dialogStyles],
    );


    //campi della modale
    const invoiceNumberItem = page?.item?.state?.data?.invoiceNumber
    const invoiceDateItem = page?.item?.state?.data?.invoiceDate
    const paymentDateItem = page?.item?.state?.data?.paymentDate
    const amountItem = page?.item?.state?.data?.amount
    const withholdingTaxItem = page?.item?.state?.data?.withholdingTax
    const ibanItem = page?.item?.state?.data?.iban
    const headerCCItem = page?.item?.state?.data?.header_cc

    const invoiceNumber = useLeapData("invoiceNumber", "numero fattura", page)
    const invoiceDate = useLeapData("invoiceDate", "data fattura", page)
    const paymentDate = useLeapData("paymentDate", "data pagamento", page)
    const amount = useLeapData("amount", "totale", page)
    const withholdingTax = useLeapData("withholdingTax", "ritenuta d'acconto", page, "withholdingTaxOptions")
    const header_cc = useLeapData("header_cc", "intestazione c/c", page)
    const iban = useLeapData("iban", "IBAN", page)


    const [invoiceNumberValue, setInvoiceNumberValue] = React.useState("")
    const [invoiceDateValue, setInvoiceDateValue] = React.useState<any>()
    const [paymentDateValue, setPaymentDateValue] = React.useState<any>()
    const [withholdingTaxValue, setWithholdingTax] = React.useState<any>()
    const [ibanValue, setibanValue] = React.useState("")
    const [headerCCValue, setHeaderCCValue] = React.useState("")
 
    React.useEffect(() => { setWithholdingTax(withholdingTaxItem) }, [withholdingTaxItem])
    React.useEffect(() => { setInvoiceDateValue(invoiceDateItem) }, [invoiceDateItem])
    React.useEffect(() => { setInvoiceNumberValue(invoiceNumberItem) }, [invoiceNumberItem])
    React.useEffect(() => { setPaymentDateValue(paymentDateItem) }, [paymentDateItem])
    React.useEffect(() => { setibanValue(ibanItem) }, [ibanItem])
    React.useEffect(() => { setHeaderCCValue(headerCCItem) }, [headerCCItem])



    return (
        <>
            <Dialog {...dialogProps} >
                <DialogFooter >
                    <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
                </DialogFooter>
            </Dialog>

            <PrimaryButton text="gestione dati pagamento" onClick={() => openModal()} style={props.hidden ? { display: "none" } : { marginRight: 2 }} />
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container} >
                <div className={contentStyles.header}>
                    <span id={"inserisci dati fattura"}>{"inserisci dati fattura"}</span>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <Row>

                        </Row>
                        <Row>
                            <Text {...invoiceNumber} value={invoiceNumberItem} className="ms-sm6" maxlength={255} required
                                onChange={(_name: any, value: any, _required: any) => { setInvoiceNumberValue(value) }} />

                            <Text {...invoiceDate} value={invoiceDateItem} dateRange={{ minDate: undefined, maxDate: Date().toLocaleString() }} className="ms-sm6" type="calendar" required
                                onChange={(_name: any, value: any, _required: any) => { setInvoiceDateValue(value) }} />
                        </Row>
                        <Row>
                            <Text {...amount} value={amountItem} className="ms-sm6" readOnly type="number" />
                            {(isONHCMaster || isONHCAccount || isONHCAdmin) &&
                                <Text {...paymentDate} value={paymentDateItem} dateRange={{ minDate: Date().toLocaleString(), maxDate: undefined }} className="ms-sm6" type="calendar" required
                                    onChange={(_name: any, value: any, _required: any) => { setPaymentDateValue(value) }} />
                            }
                        </Row>
                        <Row>
                            <Option {...withholdingTax} className="ms-sm2" required type="horizontal"
                                selectedKey={withholdingTaxValue}
                                options={page?.item?.state?.lookups?.withholdingTaxOptions}
                                onChange={(_name: any, value: any, _required: any) => { setWithholdingTax(value) }} />
                        </Row>
                        <Row hidden={page?.item?.state?.data?.iban && page?.item?.state?.data?.header_cc}>
                            <Text {...iban} value={ibanItem} className="ms-sm6" readOnly={page?.item?.state?.data?.iban} required onChange={(_name: any, value: any, _required: any) => { setibanValue(value) }} />
                            <Text {...header_cc} value={headerCCItem} className="ms-sm6" readOnly={page?.item?.state?.data?.header_cc} required onChange={(_name: any, value: any, _required: any) => { setHeaderCCValue(value) }} />
                        </Row>
                    </Grid>
                    <DialogFooter>
                        {isDeletable && <PrimaryButton onClick={onClickEliminaDatiPagamento} text="Elimina Dati Pagamento" />}
                        {saveAllowed && <PrimaryButton onClick={Save} text="Salva" />}
                        <Dialog hidden={hideDialogEliminaDatiPagamento} onDismiss={toggleHideDialogEliminaDatiPagamento} dialogContentProps={dialogPropsEliminaDatiPagamento} modalProps={modalPropsEliminaDatiPagamento} >
                            <DialogFooter>
                                <PrimaryButton onClick={onExecuteEliminaDatiPagamento} text='Elimina' />
                                <DefaultButton onClick={toggleHideDialogEliminaDatiPagamento} text="Annulla" />
                            </DialogFooter>
                        </Dialog>
                        <DefaultButton onClick={onCancel} text="Annulla" />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}
