import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';
import FileUpload from '../../components/controls/FileUpload';
import { PrimaryButton, Icon } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { itemDownload } from '../operation/thunks/itemThunk';
import { currentModality } from '../modality/modalitySlice';
import { filterForm } from '../operation/thunks/filterThunk';
import { security } from '../security/thunks/securityThunk';
import ComboBox from '../../components/controls/ComboBox';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const dispatch = useDispatch()
    const controller = props.controller
    const modality = useSelector(currentModality)
    const fForm = useSelector(filterForm)
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const description = useLeapData("description", "descrizione", page)
    const reportDate = useLeapData("reportDate", "data documento", page)
    const uploadDate = useLeapData("uploadDate", "data caricamento", page)
    const quoteManagement = useLeapData("quoteManagementNumber", "numero preventivo", page);
    const fileID = useLeapData("fileID", "fileID", page)

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileID,
            fileName: page?.item?.state?.data?.fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args))
    }
    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...quoteManagement} className="ms-sm8" readOnly />
                        <Text {...reportDate} className="ms-sm4" type="calendar" dateRange={{ maxDate: Date().toLocaleString() }} />
                    </Row>
                    <Row>
                        <Text {...description} className="ms-sm12" required />
                    </Row>
                    {(page?.item?.state?.data?.fileID === undefined || page?.item?.state?.data?.fileID === null) && <Row>
                        <FileUpload {...fileID} className="ms-sm12" label="upload file" controller={props.controller} required />
                    </Row>}
                    {(page?.item?.state?.data?.fileID !== undefined && page?.item?.state?.data?.fileID !== null) && <Row>
                        <div className={"ms-Grid-col ms-sm12"} >
                            <PrimaryButton text="download file" onClick={downloadFile} style={{ marginTop: "23px" }} />
                        </div>
                    </Row>}
                </Section>
            </Grid>
        </>
    );
}
