import { PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import PrivacyFileList from '../features/privacy/PrivacyFileList';
import PrivacyFileModal from '../features/privacy/PrivacyFileModal';
import PrivacyResultList from '../features/privacy/PrivacyResultList';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useMailingOperation } from '../hooks/useMailingOperation';

export default () => {
    const controller = "Privacy"
    const page = useLeapState(controller)

    const privacyFileController = "PrivacyFile"

    const description = useLeapData("description", "descrizione", page)
    const insertionDate = useLeapData("insertionDate", "data inserimento", page)
    const insertionStartDate = useLeapData("insertionStartDate", "data inserimento dal", page)
    const insertionEndDate = useLeapData("insertionEndDate", "data inserimento al ", page)
    const leap_PrivacyDocumentTypeID = useLeapData("leap_PrivacyDocumentTypeID", "tipo documento", page, "privacyDocumentTypeOption")
    const planID = useLeapData("planID", "piano sanitario", page, "plans")
    const companyID = useLeapData("companyID", "compagnia", page, "companies")

    const planID_show = page.item.values.leap_PrivacyDocumentTypeID?.value === "1"
    const companyID_show = page.item.values.leap_PrivacyDocumentTypeID?.value === "5"

    return <>
        <Content title="Privacy" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...description} className="ms-sm5 ms-xxl4" />
                            <ComboBox {...leap_PrivacyDocumentTypeID} className="ms-sm3 ms-xxl2" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...insertionStartDate, className: "ms-sm2" }} dateMax={{ ...insertionEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <PrivacyResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>                       
                        <Row>
                            <Text {...description} className="ms-sm5 ms-xxl4" />
                            <ComboBox {...leap_PrivacyDocumentTypeID} className="ms-sm3 ms-xxl2" />
                        </Row>
                        <Row hidden={!planID_show}>
                            <SearchBox {...planID} className="ms-sm8 ms-xxl6" />
                        </Row>
                        <Row hidden={!companyID_show}>
                            <SearchBox {...companyID} className="ms-sm8 ms-xxl6" />
                        </Row>
                        <Row>
                            <Text {...insertionDate} className="ms-sm2" type="calendar" />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="elenco files caricati" toolbar="grid" modal={<PrivacyFileModal state={page} controller={privacyFileController} />} controller={privacyFileController} modaltitle="Inserisci un file" state={page} modalwidth="600px">
                        <Row>
                            <PrivacyFileList items={page.item.state?.lists.privacyFile} modal={<PrivacyFileModal state={page} controller={privacyFileController} />} controller={privacyFileController} modaltitle="Modifica un file" modalwidth="600px" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
}
