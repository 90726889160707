import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Utente' fieldName='userID' minWidth={250} align='center' />
                <DataColumn kid='column2' name='Stato precedente' fieldName='leap_QuoteManagementQuoteStateID' minWidth={250} maxWidth={250} align='center' />
                <DataColumn kid='column3' name='Data cambiamento di stato' fieldName='stateChangeDate' minWidth={250} maxWidth={250} align='center' />
            </DataList>
        </>
    );
}
