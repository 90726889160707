import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import HealthFacilityDocumentationConsultResultList from '../features/healthFacilityDocumentationConsult/healthFacilityDocumentationConsultResultList';
import { useLeapState } from '../hooks/useLeapState';

export default () => {
    const controller = "HealthFacilityDocumentationConsult"
    const page = useLeapState(controller, true, "result")

    //const dispatch: AppDispatch = useDispatch()
    //const modality = page.modality
    //React.useEffect(() => {
    //    if (modality === 'filter') {
    //        dispatch(setModality("result"))
    //    }
    //}, [modality])

    return <>
        <Content title="Documentazione Struttura Sanitaria" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>

                        <Row>
                            <HealthFacilityDocumentationConsultResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}
