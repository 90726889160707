import * as React from 'react';
import GitInfo from 'react-git-info/macro';

import { Text } from '@fluentui/react/lib/Text';
import { versionTitle } from '../themes/Fonts';

const gitInfo = GitInfo()



export default () => (
    <Text theme={versionTitle}>
        <span style={{ paddingRight: 16 }}>version: {process.env.REACT_APP_VERSION}</span>
        <span style={{ paddingRight: 16 }}>build: {gitInfo.commit.shortHash}</span>
        <span style={{ color: "transparent", }}>env: {process.env.REACT_APP_ENV}</span>
    </Text>
);