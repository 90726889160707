import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} >
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={350} align='left' />
            </DataList>
        </>
    );
}
