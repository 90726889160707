import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import DateRange from '../components/controls/DateRange';
import SearchBox from '../components/controls/SearchBox';

import PermissionResultList from '../features/insuredPermission/InsuredPermissionResultList';

export default () => {
    const controller = "InsuredPermission"
    const page = useLeapState(controller)

    const leap_InsuredPermissionStateID = useLeapData("leap_InsuredPermissionStateID", "attivo", page, "insuredPermissionStateOption")
    const activeFromDate = useLeapData("activeFromDate", "attivato dalla data", page)
    const activeToDate = useLeapData("activeToDate", "attivato alla data", page)

    const healthFacilityID = useLeapData("healthFacilityID", "codice struttura sanitaria", page, "healthFaclityAssociates")
    const insuredID = useLeapData("insuredID", "codice assistito", page, "insuredAssociates")
    const healthFacilityIDFilter = useLeapData("healthFacilityIDFilter", "codice struttura sanitaria", page, "healthFaclityAssociatesFilter")
    const insuredIDFilter = useLeapData("insuredIDFilter", "codice assistito", page, "insuredAssociatesFilter")
    const startDate = useLeapData("startDate", "data inizio permesso", page)
    const endDate = useLeapData("endDate", "data fine permesso", page)
    const creationDate = useLeapData("creationDate", "data creazione", page)
    const dateLastChange = useLeapData("dateLastChange", "data ultima modifica", page)
    const author = useLeapData("author", "autore", page)
    const authorLastChange = useLeapData("authorLastChange", "autore ultima modifica", page)

    return <>
        <Content title="Permessi" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...healthFacilityIDFilter} className="ms-sm6" />
                            <SearchBox {...insuredIDFilter} className="ms-sm6" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...activeFromDate, className: "ms-sm2" }} dateMax={{ ...activeToDate, className: "ms-sm2" }} />
                            <Option {...leap_InsuredPermissionStateID} className="ms-sm3" type="horizontal" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <PermissionResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm6" required disabled={page.modality === "item"} />
                            <SearchBox {...insuredID} className="ms-sm6" required disabled={page.modality === "item"} />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2", readOnly: true }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <Text {...creationDate} type="calendar" className="ms-sm2" readOnly />
                            <Text {...dateLastChange} type="calendar" className="ms-sm2" readOnly />
                        </Row>
                    </Section>
                    <Section title="autore">
                        <Row>
                            <Text {...author} className="ms-sm4" readOnly />
                            <Text {...authorLastChange} className="ms-sm4" readOnly />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
