import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DentalChart from '../../components/controls/dental/DentalChart';
import { Label } from '@fluentui/react';

import RateTableHealthServiceSuspensionList from './RateTableHealthServiceSuspensionList';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthServiceID = useLeapData("healthServiceID", "prestazione", page)
    const rate = useLeapData("rate", "tariffa", page)
    const dentalElement = useLeapData("dentalElement", "elementi dentali", page)
    const endDate = useLeapData("endDate", "data fine validita", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...healthServiceID} className="ms-sm10" readOnly />
                    </Row>
                    <Row>
                        <Text {...rate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} readOnly />
                        <Text {...endDate} className="ms-sm3" type="calendar" readOnly />
                    </Row>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Row>
                            <Label>Elementi Dentali</Label>
                        </Row>
                        <Row>
                            <DentalChart {...dentalElement} readOnly />
                        </Row>
                    </div>
                </Section>

                {(<Section title="sospensioni">
                    <Row>
                        <RateTableHealthServiceSuspensionList items={page.item.state?.lists.Suspension} />
                    </Row>
                </Section>)}
            </Grid>
        </>
    );
}
