import * as React from 'react';

import { Image } from '@fluentui/react/';

import Theme from '../themes/Theme';

export default () => (
    <>
        <Image src="/assets/media/images/logo.png"
            
            draggable={false}
            style={{
                maxHeight: Theme.leap.header.logo.height,
                width: "85%",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none"
            }} />
    </>
);