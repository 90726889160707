import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { client } from '../../api/client';

import { AppDispatch } from '../../app/store';
import { units, security } from '../../features/security/thunks/securityThunk';
import { lock } from '../../features/operation/lockSlice';

import Theme from '../themes/Theme';

import { IconButton, IIconProps, Nav, INavStyles, INavLinkGroup, INavLink } from '@fluentui/react/';

export const helpDownload = createAsyncThunk(
    'help/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'Help', args.token)
        return response
    }
)

const hamburgerIcon: IIconProps = {
    iconName: 'CollapseMenu',
    style: {
        fontSize: 32
    }
}

const navStyles: Partial<INavStyles> = {
    root: {
        backgroundColor: Theme.fluent.palette.white,
        border: "solid 1px #edebe9",
        borderRadius: "2px",
        overflowY: 'visible',
        width: 'auto',
    },
    chevronButton: {
        height: '2.2em',
        lineHeight: '2.2em',
    },
    chevronIcon: {
        height: '2.2em',
        lineHeight: '2.2em',
    },
    link: {
        height: '2.2em',
    },
};

export default () => {
    const [isVisible, setVisibility] = useState<boolean>(false)
    const divRef = useRef<HTMLDivElement>(null)

    const dispatch: AppDispatch = useDispatch()

    const credential = useSelector(security)?.credential
    const unitsContext = useSelector(units)

    const evalUrl = (l: INavLink) => {
        return {
            ...l,
            collapseByDefault: (l.links?.length ?? 0) > 0 ? true : undefined,
            isExpanded: (l.links?.length ?? 0) > 0 ? false : undefined,
            url: (l.url !== '' && l.url.indexOf('/') === -1 ? '/' + l.url + '/' : l.url),
            target: (l.url.startsWith('http') ? '_blank' : (l.url === '' ? undefined : '_self'))
        }
    }

    const onLinkClick = (e: any, item: INavLink | undefined) => {
        if (item?.target === '_self')
            dispatch(lock())
    }

    const navLinkGroups: INavLink[] = unitsContext?.units?.units?.map((nlg: INavLinkGroup) => { return { ...nlg, links: nlg.links.map(evalUrl) } })

    const homeItem: INavLink = {
        icon: "home",
        name: "Pagina iniziale",
        url: "/"
    }

    const helpItem: INavLink = {
        icon: "info",
        onClick: (e: any) => { dispatch(helpDownload({ controller: "Files", body: { form: { fileName: "ONHC-Help.pdf" }, page: {} }, token: credential.token })) },
        name: "Guida all'uso",
        url: ""
    }

    const mappedNavLinkGroups: INavLinkGroup[] = [{ links: [homeItem].concat(navLinkGroups?.concat([helpItem])) }]

    useEffect(() => {
        function handler(event: any) {
            if (!divRef.current?.contains(event.target) && isVisible) {
                setVisibility(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [isVisible])

    return <>
        <IconButton iconProps={hamburgerIcon} style={{ padding: "24px" }} title="Hamburger" ariaLabel="Hamburger" onClick={() => setVisibility(!isVisible)} />
        {isVisible && <>
            <div ref={divRef} className="hamburger" style={{ position: "fixed", top: 64 }}>
                <Nav styles={navStyles} groups={mappedNavLinkGroups} onLinkClick={onLinkClick} isOnTop />
            </div>
        </>}
    </>
}