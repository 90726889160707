import * as React from 'react';
import { useSelector } from 'react-redux'

import { currentModality } from '../../../features/modality/modalitySlice';

import { Stack, StackItem, Text, Image, ImageFit } from '@fluentui/react/';
import MessageBox from '../../controls/MessageBox'
import { ModalityToolbar } from '../../controls/toolbar/ModalityToolbar'

import Theme from '../../themes/Theme';
import { unitTheme, modalityTheme } from '../../themes/Fonts';
import { ModalityType } from '../../../defs/modalities';


type Props = {
    children: React.ReactNode,
    title?: string,
    controller: string,
    reloadResult?: boolean
    noResult?: boolean
}

const headerSectionStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    margin: Theme.leap.main.content.section.margin,
    padding: Theme.leap.main.content.section.padding,
};

const bodySectionStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    margin: Theme.leap.main.content.section.body.margin,
    padding: Theme.leap.main.content.section.body.padding,
};

export function Header(props: React.Component<{}> | any) { return props.children }
export function Filter(props: React.Component<{}> | any) { return props.children }
export function Result(props: React.Component<{}> | any) { return props.children }
export function Item(props: React.Component<{}> | any) { return props.children }

export default (props: Props) => {
    const modality: ModalityType = useSelector(currentModality);

    const HeaderType = Header
    const FilterType = Filter
    const ResultType = Result
    const ItemType = Item

    const children = React.Children.map(props.children, (child => child))

    const header: React.Component<{}> | any = children!.find((child: any) => child.type === HeaderType)
    const filter: React.Component<{}> | any = children!.find((child: any) => child.type === FilterType)
    const result: React.Component<{}> | any = children!.find((child: any) => child.type === ResultType)
    const item: React.Component<{}> | any = children!.find((child: any) => child.type === ItemType)

    var modalitySection;
    var titleSection;
    switch (modality) {
        case "item":
        case "new":
            modalitySection =
                <section style={bodySectionStyles}>
                    {item ? item.props.children : null}
                </section>
            titleSection = "dettaglio"
            break;
        case "result":
            modalitySection =
                <section style={bodySectionStyles}>
                    {result ? result.props.children : null}
                </section>
            titleSection = "risultati di ricerca"
            break;
        case "filter":
            modalitySection =
                <section style={bodySectionStyles}>
                    <form id="filterForm">
                        {filter ? filter.props.children : null}
                    </form>
                </section>
            titleSection = "opzioni di ricerca"
            break;
        case "none":
        default:
            modalitySection =
                <div style={{ backgroundColor: "#fff", borderRadius: 4, marginTop: 40, padding: "0 20px" }}>
                    <Image
                        src="/assets/media/images/odontonetwork-oral-health-care-management-logo-vector.png"
                        alt="Odontonetwork Oral Health Care Management"
                        imageFit={ImageFit.centerContain}
                        height="60vh"
                        style={{
                            width: "85%",
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }}
                        draggable={false}
                    />
                </div>
            titleSection = ""
            break;
    }

    return (
        <>
            <MessageBox />
            {modality !== 'none' && <section style={headerSectionStyles}>
                {
                    header ?
                        header.props.children :
                        <>
                            <Stack horizontal style={{ paddingRight: 8, width: "100%" }}>
                                <StackItem grow align="start">
                                    <Text theme={unitTheme}>{props.title}</Text>
                                    <Text theme={modalityTheme}>{titleSection}</Text>
                                </StackItem>
                                <StackItem align="end">
                                    <ModalityToolbar type={modality} controller={props.controller} reloadResult={props.reloadResult} noResult={props.noResult} />
                                </StackItem>
                            </Stack>
                        </>
                }
            </section>}
            {modalitySection}
        </>
    );
};