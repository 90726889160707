import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import SearchBox from '../../components/controls/SearchBox';
import ComboBox from '../../components/controls/ComboBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any, parentController?: string }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branch1 = useLeapData("branch1", "branca", page, "branches")
    const healthService1 = useLeapData("healthService1", "prestazione", page, "healthServices1")

    const branch2 = useLeapData("branch2", "branca", page, "branches")
    const healthService2 = useLeapData("healthService2", "prestazione", page, "healthServices2")

    return (
        <>
            <Grid>
                <Section title="prestazione 1">
                    <Row>
                        <ComboBox {...branch1} className="ms-sm12" />
                    </Row>
                    <Row>
                        <SearchBox {...healthService1} className="ms-sm12" required parentController={props.parentController} />
                    </Row>
                </Section>
                <Section title="prestazione 2">
                    <Row>
                        <ComboBox {...branch2} className="ms-sm12" />
                    </Row>
                    <Row>
                        <SearchBox {...healthService2} className="ms-sm12" required parentController={props.parentController} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
