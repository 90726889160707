import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../app/rootReducer'

import { ModalityType } from '../../../defs/modalities'
import { OperationType } from '../../../defs/operations'

import { initialStateThunks, iPayloadThunks, iMinPayloadThunks, iExtendedPayloadThunks } from '../../../defs/thunks'

import { addBuilderFull, addBuilderMin, addBuilderClean, addBuilderLookup } from './itemThunkHelpers'

import { ResponsePayload } from '../../../defs/thunks'

import { client } from '../../../api/client'

export const itemLoad = createAsyncThunk(
    'item/load',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.get(args.controller, null, 'Get/' + args.body, args.token)
        return response
    }
)
export const itemFirst = createAsyncThunk(
    'item/first',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.get(args.controller, null, 'Get/0', args.token)
        return response
    }
)
export const itemPrevious = createAsyncThunk(
    'item/previous',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        let index

        if (args.body.page) {
            index = args.body.page.index - 2
            if (index < 0) index = 0
        } else {
            index = 0
        }

        const response = await client.get(args.controller, null, 'Get/' + index, args.token)
        return response
    }
)
export const itemNext = createAsyncThunk(
    'item/next',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        let index

        if (args.body.page) {
            index = args.body.page.index
            if (index >= args.body.page.count) index = args.body.page.count - 1
        } else {
            index = 0
        }

        const response = await client.get(args.controller, null, 'Get/' + index, args.token)
        return response
    }
)
export const itemLast = createAsyncThunk(
    'item/last',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        let index

        if (args.body.page) {
            index = args.body.page.count - 1
        } else {
            index = 0
        }

        const response = await client.get(args.controller, null, 'Get/' + index, args.token)
        return response
    }
)

export const itemNew = createAsyncThunk(
    'item/new',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'New', args.token)
        return response
    }
)
export const itemCreate = createAsyncThunk(
    'item/create',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Create', args.token)
        return response
    }
)
export const itemCopy = createAsyncThunk(
    'item/copy',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.get(args.controller, null, 'Copy/' + args.body, args.token)
        return response
    }
)

export const itemClone = createAsyncThunk(
    'item/clone',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, index?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Clone/' + args.index, args.token)
        return response
    }
)
export const itemNavigate = createAsyncThunk(
    'item/navigate',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.post(args.controller, { form: { id: args.body.id, quoteid: args.body.quoteid } }, 'Navigate', args.token)
        return response
    })

export const itemSave = createAsyncThunk(
    'item/save',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        let index

        if (args.body.page) {
            index = args.body.page.index - 1
        } else {
            index = 0
        }

        const response = await client.put(args.controller, args.body, 'Save/' + index, args.token)
        return response
    }
)
export const itemDelete = createAsyncThunk(
    'item/delete',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        let index

        if (args.body.page) {
            index = args.body.page.index - 1
        } else {
            index = 0
        }

        const response = await client.delete(args.controller, args.body, 'Delete/' + index, args.token)
        return response
    }
)

export const itemLoadNoIndex = createAsyncThunk(
    'item/loadnoindex',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.post(args.controller, { form: { id: args.body } }, 'Get', args.token)
        return response
    }
)
export const itemSaveNoIndex = createAsyncThunk(
    'item/savenoindex',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.put(args.controller, args.body, 'Save', args.token)
        return response
    }
)
export const itemDeleteNoIndex = createAsyncThunk(
    'item/deletenoindex',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body?: any, token?: string }) => {
        const response = await client.delete(args.controller, args.body, 'Delete', args.token)
        return response
    }
)
export const itemLookup = createAsyncThunk(
    'item/lookup',
    async (args: { controller: string, target: string, lookup: string, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Lookup/' + args.lookup, args.token)
        return response
    }
)
export const itemSearch = createAsyncThunk(
    'item/search',
    async (args: { controller: string, lookup: string, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Search/' + args.lookup, args.token)
        return response
    }
)

export const itemPrint = createAsyncThunk(
    'item/print',
    async (args: { controller: string, lookup: string, body?: any, token?: string }) => {
        const response = await client.printPost(args.controller, args.body, 'Print/' + args.lookup, undefined, args.token)
        return response
    }
)

export const itemUpload = createAsyncThunk(
    'item/upload',
    async (args: { controller: string, lookup: string, name: string, type: string, file: any, idCaller: string, token?: string }) => {
        const response = await client.post(args.controller, { form: { id: 0, fileExtension: args.name, file: args.file, idCaller: args.idCaller, controller: args.controller } }, 'Upload/' + args.lookup, args.token)
        return response
    }
)

export const itemDownload = createAsyncThunk(
    'item/download',
    async (args: { controller: string, id?: any, fileID?: any, fileName: string }) => {
        const response = await client.downloadPost(args.controller, { form: { fileID: args.fileID, ID: args.id, fileName: args.fileName } }, 'Download')
        return response
    }
)

//export const itemDownloadMultiselection = createAsyncThunk(
//    'item/downloadMultiselection',
//    async (args: { controller: string, fileID?: any, fileName: string }) => {
//        const response = await client.downloadPost(args.controller, { form: { fileID: args.fileID, fileName: args.fileName } }, 'DownloadMultiselection')
//        return response
//    }
//)


const ItemSlice = createSlice({
    name: 'item',
    initialState: { ...initialStateThunks },
    reducers: {
        clearDataItem: {
            reducer(state, action: PayloadAction<iMinPayloadThunks>) {
                let controller = action.payload.controller

                let data = state.data

                let dataController = data.filter(d => d.key === controller)
                let dataControllers = data.filter(d => d.key !== controller)

                if (dataController.length > 0) {
                    let metadata = dataController[0].value?.metadata
                    dataControllers.push({ key: controller, value: { metadata: metadata, lists: {}, lookups: {} } })
                }

                state.data = dataControllers
            },
            prepare(controller: string) {
                return { payload: { controller: controller } }
            }
        },
        setFormItem: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let item = action.payload.data

                let form = state.form

                let formController = form.find(f => f.key === controller)
                if (formController) {
                    formController.value = { ...formController.value, [item.name]: item.value }
                } else {
                    form.push({ key: controller, value: { [item.name]: item.value } })
                }

                state.form = form
            },
            prepare(controller: string, item: { name: any, value: any }) {
                return { payload: { controller: controller, data: item } }
            }
        },

        setFormData: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let item = action.payload.data

                let data = state.data.map(d => d.key === controller ? { key: controller, value: { ...d.value, data: { ...d.value.data, [item.name]: item.value } } } : d)
                if (!data.find(d => d.key === controller)) {
                    data.push({ key: controller, value: { data: item, lists: {}, lookups: {} } })
                }

                state.data = data
            },
            prepare(controller: string, item: { name: any, value: any }) {
                return { payload: { controller: controller, data: item } }
            }
        },

        setRequiredItem: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let item = action.payload.data

                let validation = state.validation

                let validationController = validation.find(f => f.key === controller)
                if (validationController) {
                    validationController.value = { ...validationController.value, mandatory: item.value }
                } else {
                    validation.push({ key: controller, value: { mandatory: item.value, validity: undefined } })
                }

                state.validation = validation
            },
            prepare(controller: string, item: { name: "mandatory", value: boolean }) {
                return { payload: { controller: controller, data: item } }
            }
        },
        setValidateItem: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let item = action.payload.data

                let validation = state.validation

                let validationController = validation.find(f => f.key === controller)
                if (validationController) {
                    validationController.value = { ...validationController.value, validity: item.value }
                } else {
                    validation.push({ key: controller, value: { mandatory: undefined, validity: item.value, } })
                }

                state.validation = validation
            },
            prepare(controller: string, item: { name: "validity", value: any }) {
                return { payload: { controller: controller, data: item } }
            }
        },
        resetItemResponse: {
            reducer(state, action: PayloadAction<ResponsePayload>) {
                const { response } = action.payload
                let newResponse = state.response

                newResponse.message = response.message
                newResponse.level = response.level


                state.response = newResponse
            },
            prepare() {
                return { payload: { response: { message: '', level: -1 } } }
            }
        },
        setListResponse: {
            reducer(state, action: PayloadAction<iExtendedPayloadThunks>) {
                let controller = action.payload.controller
                const parentController = action.payload.parentController
                let item = action.payload.data
                let data = state.data

                data = data.map(d => d.key === parentController ? {
                    key: parentController, value: {
                        ...d.value,
                        lists: { ...d.value.lists, [controller]: item.data },
                    }
                } : d)
                data = data.map(d => d.key === controller ? { key: controller, value: { metadata: { page: item.metadata.page } } } : d)

                state.data = data
            },
            prepare(controller: string, parentController: string, data: any) {
                return { payload: { controller, parentController, data } }
            }
        },
    },
    extraReducers: builder => {
        addBuilderFull(builder, itemLoad)
        addBuilderFull(builder, itemLoadNoIndex)
        addBuilderFull(builder, itemFirst)
        addBuilderFull(builder, itemPrevious)
        addBuilderFull(builder, itemNext)
        addBuilderFull(builder, itemLast)
        addBuilderClean(builder, itemNew)
        addBuilderFull(builder, itemCreate)
        addBuilderFull(builder, itemCopy)
        addBuilderFull(builder, itemClone)
        addBuilderFull(builder, itemSave)
        addBuilderFull(builder, itemSaveNoIndex)
        addBuilderMin(builder, itemDelete)
        addBuilderMin(builder, itemDeleteNoIndex)
        addBuilderLookup(builder, itemLookup)
    }
})

export const { clearDataItem, setFormItem, setFormData, setRequiredItem, setValidateItem, resetItemResponse, setListResponse } = ItemSlice.actions

export const itemData = (state: RootState) => state.item.data
export const itemForm = (state: RootState) => state.item.form
export const itemValidation = (state: RootState) => state.item.validation
export const itemResponse = (state: RootState) => state.item.response

export default ItemSlice.reducer