import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { security } from '../features/security/thunks/securityThunk';
import { useSecuritydOperation } from '../hooks/useSecurityOperation';
import Theme from '../components/themes/Theme';
import { unitTheme } from '../components/themes/Fonts';
import { Text as TextTitle, FontSizes, DefaultButton } from '@fluentui/react/';
import { Image, IImageStyles, ImageFit, PrimaryButton } from '@fluentui/react/';
import Text from '../components/controls/Text';

import { FontFaces } from '../defs/fonts';

export default () => {
    const controller = "SecurityChangePassword"
    const page = useLeapState(controller)

    const securityOperation = useSecuritydOperation();
    const credential = useSelector(security)?.credential

    const oldPassword = useLeapData("vecchiaPassword", "password", page)
    const newPassword = useLeapData("nuovaPassword", "nuova password", page)
    const confirmNewPassword = useLeapData("nuovaPassword2", "ripeti nuova password", page)

    const expired: string = credential?.expiredPassword;

    const [message, setMessage] = useState('')
    const [isPasswordChanged, setIsPasswordChanged] = useState('')


    const onChangePassword = () => {
        if (page?.filter?.values.nuovaPassword?.value !== page?.filter?.values.nuovaPassword2?.value) {
            setMessage("nuova password e ripeti password non coincidono")
        } else {

            securityOperation.changePassword(credential.token, page?.filter?.values.vecchiaPassword?.value, page?.filter?.values.nuovaPassword?.value)
                .then((response) => {
                    if (response.payload.response.level !== 4) {
                        setMessage(response.payload.response.message)
                    }
                    else {
                        setIsPasswordChanged("true");
                    }
                })
        }
    }

    const onPasswordChanged = () => {
        securityOperation.logout(undefined)
            .then((response) => {
                document.location.href = '/login/'
                setMessage("")
            })
    }

    const gotoHomepage = () => {
        if (document.location.pathname == '/ChangePassword/') {
            securityOperation.logout(undefined)
                .then((response) => {
                    document.location.href = '/login/'
                })
        }
        else {
            document.location.href = '/'
        }
        setMessage("")
    }

    const titleStyles: React.CSSProperties = {
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        color: Theme.fluent.palette.neutralPrimaryAlt,
        display: "inline-block",
        marginLeft: 8,
        marginTop: 40,
        lineHeight: "80px",
    }
    const imageStyles: Partial<IImageStyles> = {
        root: {
            display: "inline-block",
            height: 80,
            width: 110
        },
        image: {
            display: "inline-block"
        }
    }    

    const infoMessageStyle: React.CSSProperties = {
        color: Theme.fluent.palette.neutralDark,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: 'right'
    }

    const errorMessageStyle: React.CSSProperties = {
        color: Theme.leap.customization.error.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: 'right'
    }

    const mainContainerStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: 32
    }
    const logoExternalContainerStyle: React.CSSProperties = {
        backgroundColor: process.env.REACT_APP_STYLE_BODY_BACKGROUNDCOLOR,
        borderRadius: "50%",
        padding: 16,
        zIndex: 1
    }
    const logoInternalContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.white,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: "50%",
        zIndex: 1
    }
    const formContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.themeLight,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: "128px 24px 8px",
        position: "relative",
        top: -96,
    }

    const buttonContainerStyle: React.CSSProperties = {
        marginTop: 12,
        textAlign: "center"
    }

    const buttonStyle: React.CSSProperties = {
        width: 192
    }

    const textContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }

    return <>
        <div style={mainContainerStyle}>
            <div style={logoExternalContainerStyle}>
                <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                    <Image src="/assets/media/images/icon.png"
                        draggable={false}
                        style={{
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }} />
                </div>
            </div>
            {isPasswordChanged && <>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <div style={{ textAlign: "center" }}>
                        <span style={infoMessageStyle}>La Password è stata aggiornata.</span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <span style={infoMessageStyle}>Accedere al sistema utilizzando le nuove credenziali.</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 24 }} />
                    <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                        <PrimaryButton onClick={onPasswordChanged} text="accedi" style={buttonStyle} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 32 }} />
                </div>
            </>}
            {!isPasswordChanged && <>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <Text {...oldPassword} label={expired === null ? "password corrente" : "password temporanea"} className="ms-sm12" required type="password" />
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 16 }} />
                    <Text {...newPassword} className="ms-sm12" required type="password" maxlength={20} />
                    <Text {...confirmNewPassword} className="ms-sm12" required type="password" maxlength={20} />
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 24 }} />
                    <div style={{ textAlign: "center" }}>
                        <span style={errorMessageStyle}>{message.toLowerCase()}</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 8 }} />
                    <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                        <PrimaryButton onClick={onChangePassword} text="modifica password" style={buttonStyle} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                        <DefaultButton onClick={gotoHomepage} text={"torna alla " + (document.location.pathname == '/ChangePassword/' ? "login" : "homepage")} style={buttonStyle} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, height: 32 }} />
                </div>
            </>}
        </div>
    </>
}
