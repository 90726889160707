import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppDispatch } from '../../app/store'

import { Label, TagPicker, ITag, Modal } from '@fluentui/react/'

import { security } from '../../features/security/thunks/securityThunk'
import { filterSearch, filterForm, filterData, filterLookup } from '../../features/operation/thunks/filterThunk'
import { itemSearch, setFormItem, itemForm, itemData, itemLookup } from '../../features/operation/thunks/itemThunk'

import { currentResponse } from '../../features/response/responseSlice'
import { currentModality } from '../../features/modality/modalitySlice'

type Props = {
    controller: string,
    name: string,
    label: string,
    lookup: string,
    className: string
    required?: boolean
    mandatory?: (name: string) => boolean
    value?: string | number | any,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    disabled?: boolean,
    hidden?: boolean,
    children?: { name: string, lookup: string, controller: string, required?: boolean, defaultValue?: any }[],
    parentController?: string
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality) == "result" ? "item" : useSelector(currentModality)
    
    const response = useSelector(currentResponse)

    const iForm = useSelector(itemForm)
    const fForm = useSelector(filterForm)
    const iData = useSelector(itemData);
    const fData = useSelector(filterData);
    const dItem = iData.find(d => d.key === props.controller)?.value
    const dFilter = fData.find(d => d.key === props.controller)?.value

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const [selectedItem, setSelectedItem] = useState<ITag | undefined>()

    useEffect(() => {
        var item = props.value?.key ? { name: props.value?.text, key: props.value?.key } : props.value === selectedItem?.key ? { name: selectedItem?.name, key: selectedItem?.key } : undefined

        if (props.onInit) {
            props.onInit(props.name, item?.key, props.required)
        }

        if (item?.key === undefined && item?.name === undefined) {
            item = undefined
        }

        dispatch(setFormItem(props.controller, { name: props.name, value: item ? item.key : undefined }))

        setSelectedItem(item)

    }, [props.value, props.required])

    const onChange = (items?: ITag[]) => {
        const selected = items ? items[0] : undefined
        const key = selected?.key

        setSelectedItem(selected)

        if (props.onChange)
            props.onChange(props.name, key, props.required)

        if (props.children) {
            let parentItemForm: any = {}
            let parentItemData: any = {}
            let parentItemPage: any = {}

            if (props.parentController) {
                parentItemForm = iForm.find(i => i.key === props.parentController)?.value
                parentItemData = iData.find(d => d.key === props.parentController)?.value

                if (parentItemData?.metadata?.page) {
                    Object.assign(parentItemPage, parentItemData?.metadata?.page)

                    if (parentItemPage.index > 0) {
                        parentItemPage.index = parentItemPage.index - 1
                    } else {
                        parentItemPage.index = 0
                    }
                }
            }

            const form = iForm.find(i => i.key === props.controller)?.value
            const page = dItem?.metadata?.page
            const body = {
                form: {
                    ...form, [props.name]: key
                },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: page
            }

            const formF = fForm.find(i => i.key === props.controller)?.value
            const pageF = dFilter?.metadata?.page
            const bodyF = {
                form: { ...formF, [props.name]: key },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: pageF
            }

            if (modality === "filter") {
                props.children.forEach((c) => {
                    dispatch(filterLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: bodyF, token: token }))
                })
            }
            else if (modality === "item" || modality === "new") {
                props.children.forEach((c) => {
                    dispatch(itemLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: body, token: token }))
                })
            }
        }
    }

    const onResolveSuggestions = async (filter: string) => {
        if (filter) {
            let parentItemForm: any = {}
            let parentItemData: any = {}
            let parentItemPage: any = {}

            if (props.parentController) {
                parentItemForm = iForm.find(i => i.key === props.parentController)?.value
                parentItemData = iData.find(d => d.key === props.parentController)?.value

                if (parentItemData?.metadata?.page) {
                    Object.assign(parentItemPage, parentItemData?.metadata?.page)

                    if (parentItemPage.index > 0) {
                        parentItemPage.index = parentItemPage.index - 1
                    } else {
                        parentItemPage.index = 0
                    }
                }
            }


            const form = iForm.find(i => i.key === props.controller)?.value
            const page = dItem?.metadata?.page
            const body = {
                form: {
                    ...form, [props.name + "Search"]: filter
                },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: page
            }

            const formF = fForm.find(i => i.key === props.controller)?.value
            const pageF = dFilter?.metadata?.page
            const bodyF = {
                form: { ...formF, [props.name + "Search"]: filter },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: pageF
            }


            if (modality === "filter")
                return dispatch(filterSearch({ controller: props.controller, lookup: props.lookup, body: bodyF, token: token }))
                    .then((response) => {
                        const items = response.payload.lookups?.[props.lookup]
                        return items.map((item: any): ITag => {
                            return {
                                name: item.text,
                                key: item.key
                            };
                        });
                    })
            else if (modality === "item" || modality === "new")
                return dispatch(itemSearch({ controller: props.controller, lookup: props.lookup, body: body, token: token }))
                    .then((response) => {
                        const items = response.payload.lookups?.[props.lookup]
                        return items.map((item: any): ITag => {
                            return {
                                name: item.text,
                                key: item.key
                            };
                        });
                    })
        }
        else {
            return []
        }
    }

    const onEmptyResolveSuggestions = async () => {
        let parentItemForm: any = {}
        let parentItemData: any = {}
        let parentItemPage: any = {}

        if (props.parentController) {
            parentItemForm = iForm.find(i => i.key === props.parentController)?.value
            parentItemData = iData.find(d => d.key === props.parentController)?.value

            if (parentItemData?.metadata?.page) {
                Object.assign(parentItemPage, parentItemData?.metadata?.page)

                if (parentItemPage.index > 0) {
                    parentItemPage.index = parentItemPage.index - 1
                } else {
                    parentItemPage.index = 0
                }
            }
        }


        const form = iForm.find(i => i.key === props.controller)?.value
        const page = dItem?.metadata?.page
        const body = {
            form: {
                ...form, [props.name + "Search"]: ""
            },
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }, page: page
        }

        const formF = fForm.find(i => i.key === props.controller)?.value
        const pageF = dFilter?.metadata?.page
        const bodyF = {
            form: { ...formF, [props.name + "Search"]: "" },
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }, page: pageF
        }

        if (modality === "filter")
            return dispatch(filterSearch({ controller: props.controller, lookup: props.lookup, body: bodyF, token: token }))
                .then((response) => {
                    const items = response.payload.lookups?.[props.lookup]
                    return items.map((item: any): ITag => {
                        return {
                            name: item.text,
                            key: item.key
                        };
                    });
                })
        else if (modality === "item" || modality === "new")
            return dispatch(itemSearch({ controller: props.controller, lookup: props.lookup, body: body, token: token }))
                .then((response) => {
                    const items = response.payload.lookups?.[props.lookup]
                    return items.map((item: any): ITag => {
                        return {
                            name: item.text,
                            key: item.key
                        };
                    });
                })
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const labelClassName = response.notified && !mandatory ? "leap-search-box-label" : "leap-search-box-label"
    const inputClassName = response.notified && !mandatory ? "leap-search-box-input leap-search-box-input-error" : "leap-search-box-input"
    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <div style={{ marginTop: 8 }}>
                <Label className={labelClassName} required={props.required}>{props.label}</Label>
                <TagPicker inputProps={{ autoComplete: "off" }} className={inputClassName} onEmptyResolveSuggestions={onEmptyResolveSuggestions} onChange={onChange} disabled={props.disabled} onResolveSuggestions={onResolveSuggestions} resolveDelay={1500} itemLimit={1} selectedItems={selectedItem ? [{ key: selectedItem.key, name: selectedItem.name }] : []} />
            </div>
        </div>
    </>
}
