import { DefaultButton, FontSizes, IButtonProps, Icon, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import MultiBox from '../components/controls/MultiBox';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import { lock, unlock } from '../features/operation/lockSlice';
import { filterForm } from '../features/operation/thunks/filterThunk';
import { resultDownload } from '../features/operation/thunks/resultThunk';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

const PivotButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
        verticalAlign: "bottom"
    },
    styles: {
        root: {
            backgroundColor: Theme.fluent.palette.themeTertiary,
            color: Theme.fluent.palette.neutralLighter,
            height: 38,
            marginTop: 12,
            minWidth: "12%",
        },
        rootDisabled: {
            backgroundColor: Theme.fluent.palette.themePrimary,
            color: Theme.fluent.palette.white,
            height: 42,
            marginTop: 8,
            minWidth: "15%",
        },
        rootHovered: {
            backgroundColor: Theme.fluent.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: Theme.fluent.palette.themeLight,
        }
    }
}
const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "inherit",
        whiteSpace: "pre-wrap",
        lineHeight: 30
    }
}
const multiSearchBoxItemLimit = 99;

export default () => {
    const controller = "DEOther"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch();

    const userStartDate = useLeapData("userStartDate", "da", page)
    const userEndDate = useLeapData("userEndDate", "a", page)
    const userPlans = useLeapData("userPlans", "piani", page, "userPlans")

    const healthFacilityStartDate = useLeapData("healthFacilityStartDate", "da", page)
    const healthFacilityEndDate = useLeapData("healthFacilityEndDate", "a", page)

    const healthServiceStartDate = useLeapData("healthServiceStartDate", "da", page)
    const healthServiceEndDate = useLeapData("healthServiceEndDate", "a", page)
    const healthServicePlans = useLeapData("healthServicePlans", "piani", page, "healthServicePlans")
    const healthServiceHealthServices = useLeapData("healthServiceHealthServices", "prestazioni", page, "healthServiceHealthServices")

    const beneficiaryStartDate = useLeapData("beneficiaryStartDate", "da", page)
    const beneficiaryEndDate = useLeapData("beneficiaryEndDate", "a", page)
    const beneficiaryPlans = useLeapData("beneficiaryPlans", "piani", page, "beneficiaryPlans")
    const beneficiaryFamilyKinshipDegrees = useLeapData("beneficiaryFamilyKinshipDegrees", "grado di parentela", page, "beneficiaryFamilyKinshipDegrees")

    const noBeneficiaryStartDate = useLeapData("noBeneficiaryStartDate", "da", page)
    const noBeneficiaryEndDate = useLeapData("noBeneficiaryEndDate", "a", page)
    const noBeneficiaryPlans = useLeapData("noBeneficiaryPlans", "piani", page, "noBeneficiaryPlans")

    const credential = useSelector(security)?.credential

    const fForm = useSelector(filterForm)
    const formF = fForm.find(i => i.key === controller)?.value

    const user = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEOtherUser", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const healthFacility = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEOtherHealthFacility", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const healthService = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEOtherHealthService", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const beneficiary = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEOtherBeneficiary", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const noBeneficiary = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEOtherNoBeneficiary", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = React.useState<"user" | "healthFacility" | "healthService" | "beneficiary" | "noBeneficiary">("user")

    return <>
        <Content title="Estrazione dati - Altre" controller={controller} noResult>
            <Filter>
                <Grid>
                    <Section toolbar="filter" controller={controller}>
                        <div className="ms-Grid-col ms-sm10" style={{ height: "auto" }}>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "user"} onClick={() => setPivotItemSelectedKey("user")}>utilizzatori del servizio</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "healthFacility"} onClick={() => setPivotItemSelectedKey("healthFacility")}>utilizzo strutture sanitarie per numero assistiti</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "healthService"} onClick={() => setPivotItemSelectedKey("healthService")}>numero di prestazioni richieste in un determinato periodo</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "beneficiary"} onClick={() => setPivotItemSelectedKey("beneficiary")}>piani di cura (welfare) con beneficiario</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "noBeneficiary"} onClick={() => setPivotItemSelectedKey("noBeneficiary")}>piani di cura (welfare) senza beneficiario</DefaultButton>
                        </div>
                    </Section>
                    <Pivot selectedKey={pivotItemSelectedKey}>
                        <PivotItem headerText="numero utilizzatori del servizio e numero di volte utilizzato da ogni utilizzatore" itemKey="user">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm5"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="numero utilizzatori del servizio e numero di volte utilizzato da ogni utilizzatore"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={user} style={{ cursor: "pointer", background: "transparent" }} id={"user"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...userStartDate, className: "ms-sm2" }}
                                        dateMax={{ ...userEndDate, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...userPlans} className="ms-sm10" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="utilizzo strutture sanitarie per numero assistiti" itemKey="healthFacility">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm5"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="utilizzo strutture sanitarie per numero assistiti"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={healthFacility} style={{ cursor: "pointer", background: "transparent" }} id={"healthFacility"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...healthFacilityStartDate, className: "ms-sm2" }}
                                        dateMax={{ ...healthFacilityEndDate, className: "ms-sm2" }} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="numero di prestazioni richieste in un determinato periodo e su quanti assistiti" itemKey="healthService">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm5"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="numero di prestazioni richieste in un determinato periodo e su quanti assistiti"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={healthService} style={{ cursor: "pointer", background: "transparent" }} id={"healthService"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...healthServiceStartDate, className: "ms-sm2" }}
                                        dateMax={{ ...healthServiceEndDate, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...healthServicePlans} className="ms-sm10" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...healthServiceHealthServices} className="ms-sm10" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="piani di cura (welfare) con beneficiario" itemKey="beneficiary">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm5"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="piani di cura (welfare) con beneficiario"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={beneficiary} style={{ cursor: "pointer", background: "transparent" }} id={"beneficiary"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...beneficiaryStartDate, className: "ms-sm2" }}
                                        dateMax={{ ...beneficiaryEndDate, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...beneficiaryPlans} className="ms-sm10" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                                <Row>
                                    <MultiBox {...beneficiaryFamilyKinshipDegrees} className="ms-sm10" titleType="text" />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="piani di cura (welfare) senza beneficiario" itemKey="noBeneficiary">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm5"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="piani di cura (welfare) senza beneficiario"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={noBeneficiary} style={{ cursor: "pointer", background: "transparent" }} id={"noBeneficiary"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...noBeneficiaryStartDate, className: "ms-sm2" }}
                                        dateMax={{ ...noBeneficiaryEndDate, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...noBeneficiaryPlans} className="ms-sm10" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem>
                    </Pivot>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
