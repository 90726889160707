import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import InsuredList from '../features/planPurchaseRequest/InsuredList';
import PlanList from '../features/planPurchaseRequest/PlanList';
import PlanPurchaseRequestStateButtons from '../features/planPurchaseRequest/PlanPurchaseRequestStateButtons';
import RelativeList from '../features/planPurchaseRequest/RelativeList';
import ResultList from '../features/planPurchaseRequest/ResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import ComboBox from '../components/controls/ComboBox';
import SearchBox from '../components/controls/SearchBox';
import { Icon } from '@fluentui/react';
import { AppDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { security } from '../features/security/thunks/securityThunk';
import { itemDownload } from '../features/operation/thunks/itemThunk';

export enum PlanPurchaseStates {
    Sent = "1",
    Accepted = "2",
    Rejected = "3",
    Suspended = "4"
}

export default () => {
    const controller = "PlanPurchaseRequest";

    const page = useLeapState(controller)

    const firstname = useLeapData("firstname", "nome", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)
    const email = useLeapData("email", "email", page)
    const corporation = useLeapData("corporation", "azienda", page)
    const membershipDate = useLeapData("membershipDate", "data adesione", page)
    const state = useLeapData("state", "stato", page, "states")
    const durationInMonths = useLeapData("durationInMonths", "durata mesi", page)
    const membersCount = useLeapData("membersCount", "numero aderenti", page)
    const membershipFeeValue = useLeapData("membershipFeeValue", "quota associativa", page)
    const membershipFeeTotal = useLeapData("membershipFeeTotal", "quota totale", page)
    const billingAddress = useLeapData("billingAddress", "indirizzo fatturazione", page)

    const plan = useLeapData("plan", "piano sanitario", page, "plans")

    const stateID: string = page.item?.state?.data?.stateID;
    const currentState = Object.entries(PlanPurchaseStates).find(([, value]) => value === stateID)?.[1];

    const allowDurationInMonths = !!page.item?.state?.data?.allowDurationInMonths;

    const credential = useSelector(security)?.credential
    const dispatch: AppDispatch = useDispatch()


    const fileID = page?.item?.state?.data?.attachmentFileID
    const fileExt = page?.item?.state?.data?.attachmentFileExt
    const showDownload = !!fileID && !!fileExt

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: fileID,
            fileName: `allegatoQuota - ${page?.item?.state?.data?.fiscalcode}.${fileExt}`,
            token: credential.token
        }
        dispatch(itemDownload(args))
    }

    return <>
        <Content title="Richieste Acquisto/Adesione" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...fiscalcode} className="ms-sm4" maxlength={50} />
                            <Text {...email} className="ms-sm4" maxlength={255} />
                            <ComboBox {...state} className="ms-sm2" />
                        </Row>
                        <Row>
                            <Text {...firstname} className="ms-sm4" maxlength={50} />
                            <Text {...lastname} className="ms-sm4" maxlength={50} />
                        </Row>
                        <Row>
                            <SearchBox {...plan} className="ms-sm4" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <ResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...state} className="ms-sm2" readOnly />
                            <PlanPurchaseRequestStateButtons controller={controller} currentState={currentState} />
                        </Row>
                        <Row>
                            <Text {...fiscalcode} className="ms-sm4" readOnly />
                            <Text {...email} className="ms-sm4" readOnly />
                            {showDownload &&
                                <Text
                                    onChange={() => { }}
                                    className="ms-sm2"
                                    label="allegato quota"
                                    name=""
                                    type="plainWithIcon"
                                    value="scarica allegato quota"
                                    readOnly
                                    onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                            }
                            {!showDownload &&
                                <Text
                                    onChange={() => { }}
                                    className="ms-sm2"
                                    label="allegato quota"
                                    name=""
                                    type="plainWithIcon"
                                    value="nessun allegato caricato"
                                    readOnly
                                    onRenderSuffix={() => { return <><Icon iconName="ReportWarning" onClick={() => { }} style={{ cursor: "default", background: "transparent" }} /></> }} />
                            }
                        </Row>
                        <Row>
                            <Text {...firstname} className="ms-sm4" readOnly />
                            <Text {...lastname} className="ms-sm4" readOnly />
                        </Row>
                        <Row>
                            <Text {...billingAddress} className="ms-sm8" readOnly />
                        </Row>
                        <Row>
                            <Text {...corporation} className="ms-sm4" readOnly />
                            <Text {...membershipDate} className="ms-sm4" type="calendar" readOnly />
                        </Row>
                        <Row>
                            <Text {...durationInMonths} className="ms-sm2" type="number" hidden={!allowDurationInMonths} />
                            <Text {...membersCount} className="ms-sm2" readOnly />
                            <Text {...membershipFeeValue} className="ms-sm2" readOnly />
                            <Text {...membershipFeeTotal} className="ms-sm2" readOnly />
                        </Row>
                    </Section>
                    <Section title="assistito corrispondente">
                        <Row>
                            <InsuredList items={page.item.state?.lists?.insured} />
                        </Row>
                    </Section>
                    <Section title="piano sanitario di riferimento">
                        <Row>
                            <PlanList items={page.item.state?.lists?.plan} />
                        </Row>
                    </Section>
                    <Section title="familiari">
                        <Row>
                            <RelativeList items={page.item.state?.lists?.relatives} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};