import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    items: any,
    navigateToController?: any,
    state?: any
}
export default (props: Props) => {
    const canNavigate = useSecurityRoles().isONHC;
    return (
        <>
            <DataList items={props.items} navigateToController={props.navigateToController} state={props.state} modalController={props.navigateToController}>
                {canNavigate && < DataColumn kid='columnN' name='' fieldName='navigate' minWidth={10} maxWidth={10} type='navigate' />}
                <DataColumn kid='column1' name='Preventivo' fieldName='quoteManagementNumber' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthService' minWidth={150} align='left' />
                <DataColumn kid='column3' name='Elemento Dentale' fieldName='dentalElement' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column4' name='Autorizzazione' fieldName='authorizeDate' minWidth={130} maxWidth={130} align='center' />
                <DataColumn kid='column5' name='Struttura Sanitaria' fieldName='healthFacilityDescr' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column6' name='Stato Preventivo' fieldName='leap_QuoteManagementQuoteStateDescr' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column7' name='Gestione' fieldName='leap_PlanManagementDescr' minWidth={100} maxWidth={100} align='center' />
            </DataList>
        </>
    );
}