import * as React from 'react';
import ComboBox from '../../components/controls/ComboBox';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import SearchBox from '../../components/controls/SearchBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any, parentController?: string }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branch = useLeapData("branch", "branca", page, "branches")
    const healthService = useLeapData("healthService", "prestazione", page, "healthServices")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...branch} className="ms-sm12" children={[{ name: "healthService", lookup: "healthServices", controller: props.controller }]} />
                    </Row>
                    <Row>
                        <SearchBox {...healthService} className="ms-sm12" required parentController={props.parentController} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
