import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Separator from '../components/controls/grid/Separator';
import SearchBox from '../components/controls/SearchBox';
import SystemLogConsultResultList from '../features/systemLogConsult/SystemLogConsultResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

export default () => {
    const controller = "SystemLogConsult";
    const page = useLeapState(controller)

    const insured = useLeapData("insuredID", "assistito", page, "insureds")
    const user = useLeapData("userID", "utente", page, "users")
    const quote = useLeapData("quoteID", "piano di cure", page, "quotes")
    const event = useLeapData("systemEventID", "tipo evento", page, "systemEvent")
    const minDate = useLeapData("minDate", "dal", page)
    const maxDate = useLeapData("maxDate", "al", page)

    return <>
        <Content title="Registro Eventi" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...insured} className="ms-sm5" />
                            <SearchBox {...user} className="ms-sm5" />
                        </Row>
                        <Row>
                            <SearchBox {...quote} className="ms-sm2" />
                            <ComboBox {...event} className="ms-sm3" />
                        </Row>
                        <Row>
                            <DateRange
                                dateMin={{ ...minDate, className: "ms-sm2" }}
                                dateMax={{ ...maxDate, className: "ms-sm2" }} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <SystemLogConsultResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
