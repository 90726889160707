import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import DateRange from '../../components/controls/DateRange';
import SearchBox from '../../components/controls/SearchBox';

import { setFormData } from '../operation/thunks/itemThunk';
import { AppDispatch } from '../../app/store';
import { useDispatch } from 'react-redux';
import Separator from '../../components/controls/grid/Separator';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const planID = useLeapData("planID", "piano sanitario", page, "fundPlans")
    const corporationID = useLeapData("corporationID", "azienda", page, "corporations")
    const registerNumber = useLeapData("registerNumber", "matricola", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const leap_PlanAcceptedPrivacyID = useLeapData("leap_PlanAcceptedPrivacyID", "privacy accettata", page, "planAcceptedPrivacyOption")
    const leap_PlanReceivedPrivacyID = useLeapData("leap_PlanReceivedPrivacyID", "privacy ricevuta", page, "planReceivedPrivacyOption")
    const policy = useLeapData("policy", "polizza", page)

    const dispatch: AppDispatch = useDispatch();

    var previousRegisterNumber = null
    var previousLeap_PlanAcceptedPrivacyID: any = null
    var previousLeap_PlanReceivedPrivacyID: any = null

    var coveragePlanList = page?.item?.data[0]?.value?.lists?.CoveragePlan ?? page?.item?.data[1]?.value?.lists?.CoveragePlan
    if (coveragePlanList !== undefined)
        coveragePlanList.forEach((coverage: any) => {
            if (coverage?.planID === page.item?.values?.planID?.value) {
                previousRegisterNumber = coverage?.registerNumber;
                previousLeap_PlanAcceptedPrivacyID = coverage?.leap_PlanAcceptedPrivacyID;
                previousLeap_PlanReceivedPrivacyID = coverage?.leap_PlanReceivedPrivacyID;
            }
        })

    var coverageHistoryList = page?.item?.data[0]?.value?.lists?.CoverageHistory ?? page?.item?.data[1]?.value?.lists?.CoverageHistory
    if (coverageHistoryList !== undefined)
        coverageHistoryList.forEach((coverage: any) => {
            if (coverage?.planID === page.item?.values?.planID?.value) {
                previousRegisterNumber = coverage?.registerNumber;
                previousLeap_PlanAcceptedPrivacyID = coverage?.leap_PlanAcceptedPrivacyID;
                previousLeap_PlanReceivedPrivacyID = coverage?.leap_PlanReceivedPrivacyID;
            }
        })

    var planAcceptedPrivacyOptionList = page.item?.data[0]?.value?.lookups?.planAcceptedPrivacyOption ?? page.item?.data[1]?.value?.lookups?.planAcceptedPrivacyOption
    if (previousLeap_PlanAcceptedPrivacyID !== null && planAcceptedPrivacyOptionList !== undefined)
        planAcceptedPrivacyOptionList.forEach((privacy: any) =>
            privacy?.text === previousLeap_PlanAcceptedPrivacyID ? previousLeap_PlanAcceptedPrivacyID = privacy.key : {})
    var planReceivedPrivacyOptionList = page.item?.data[0]?.value?.lookups?.planReceivedPrivacyOption ?? page.item?.data[1]?.value?.lookups?.planReceivedPrivacyOption
    if (previousLeap_PlanReceivedPrivacyID !== null && planReceivedPrivacyOptionList !== undefined)
        planReceivedPrivacyOptionList.forEach((privacy: any) =>
            privacy?.text === previousLeap_PlanReceivedPrivacyID ? previousLeap_PlanReceivedPrivacyID = privacy.key : {})

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...planID} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <SearchBox {...corporationID} className="ms-sm12" />
                    </Row>
                    <Row>
                        <Text {...registerNumber} className="ms-sm5" value={previousRegisterNumber ?? registerNumber.value} readOnly={previousRegisterNumber !== null} />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm5" }} />
                    </Row>
                    <Row>
                        <Text {...policy} className="ms-sm12" maxlength={50} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
