import * as React from 'react';

import { Stack, StackItem, Text, ICommandBarItemProps } from '@fluentui/react/';

import { ToolbarType } from '../../../../defs/toolbars'

import CommandToolbar from '../../../controls/toolbar/CommandToolbar'

import Separator from '../../customization/Separator';
import Row from '../Row';

import Theme from '../../../themes/Theme';
import { sectionTheme } from '../../../themes/Fonts';

const headerToolbarSectionStyles: React.CSSProperties = {
    padding: Theme.leap.main.content.section.header.topPadded,
};

const titleStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    color: Theme.fluent.palette.neutralPrimaryAlt,
    display: "inline-block",
    lineHeight: "44px",
    paddingRight: "12px",
};

type Props = {
    title?: string,
    toolbar?: ToolbarType,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean,
    farItems?: JSX.Element[],
    parentDatagridController?: string,
    parentDatagridId?: string | number
}

export default (props: Props) => {
    return <>
        <Row isTitleRow>
            <div className="ms-Grid-col ms-sm12" style={headerToolbarSectionStyles}>
                <Separator>
                    <Stack horizontal style={{ width: "100%" }}>
                        <StackItem className="left-command-toolbar" align="start">
                            <Text style={titleStyles} theme={sectionTheme}>{props.title}</Text>
                        </StackItem>
                        <StackItem className="left-command-toolbar" grow align="start">
                            {
                                !props.readOnly &&
                                <CommandToolbar type="grid" controller={props.controller} modal={props.modal} modaltitle={props.modaltitle} modalwidth={props.modalwidth} state={props.state} parentDatagridController={props.parentDatagridController} parentDatagridId={props.parentDatagridId} />
                            }
                        </StackItem>
                        <StackItem className="right-command-toolbar" align="end">
                            {props.farItems}
                        </StackItem>
                    </Stack>
                </Separator>
            </div>
        </Row>
    </>
};