import { DefaultButton, Dialog, DialogFooter, DialogType, IButtonProps, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Text from '../../components/controls/Text';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { PlanPurchaseStates as States } from '../../pages/PlanPurchaseRequest';
import { lock, unlock } from '../operation/lockSlice';
import { setOperation } from '../operation/operationSlice';
import { itemData } from '../operation/thunks/itemThunk';
import { setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';

export const Accept = createAsyncThunk(
    'item/Accept',
    async (args: { ID: string, token: string | undefined }) => {
        const response = await client.post("PlanPurchaseRequest", { form: { id: args.ID } }, 'Accept', args.token)
        return response
    }
)
export const Reject = createAsyncThunk(
    'item/Reject',
    async (args: { ID: string, Notes: string | undefined, token: string | undefined }) => {
        const response = await client.post("PlanPurchaseRequest", { form: { id: args.ID, Notes: args.Notes } }, 'Reject', args.token)
        return response
    }
)
export const Suspend = createAsyncThunk(
    'item/Suspend',
    async (args: { ID: string, Notes: string | undefined, token: string | undefined }) => {
        const response = await client.post("PlanPurchaseRequest", { form: { id: args.ID, Notes: args.Notes } }, 'Suspend', args.token)
        return response
    }
)

const dialogStyles = { main: { maxWidth: 450 } };
const ButtonProps: IButtonProps = {
    styles: {
        root: {
            marginLeft: 4,
            marginRight: 4
        }
    }
}

export default (props: {
    controller: string,
    currentState: States | undefined
}) => {
    const roles = useSecurityRoles()
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount

    const currentState = props.currentState

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const _isLocked: boolean = false//useSelector(isLocked)

    const iForm = useSelector(itemData)
    const args = { ID: iForm.find(i => i.key === props.controller)?.value?.data?.id, token: credential?.token }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    React.useEffect(() => {
        if (isONHCPower) {
            switch (currentState) {
                case States.Sent:
                    setButtonsToRender([_accept, _reject, _suspend])
                    break;

                case States.Suspended:
                    setButtonsToRender([_accept, _reject])
                    break;

                default:
                    setButtonsToRender([])
                    break;
            }
        }
        else {
            setButtonsToRender([])
        }
    }, [currentState])

    //accept
    const onClickAccept = (e: any) => {
        e.preventDefault()
        toggleHideDialogAccept()
    }
    const [hideDialogAccept, { toggle: toggleHideDialogAccept }] = useBoolean(true);
    const dialogContentPropsAccept = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Accettata"?',
    };
    const onExecuteAccept = (e: any) => {
        toggleHideDialogAccept()
        dispatch(lock())
        dispatch(Accept(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }

    //reject
    const onClickReject = (e: any) => {
        e.preventDefault()
        toggleHideDialogReject()
    }
    const [hideDialogReject, { toggle: toggleHideDialogReject }] = useBoolean(true);
    const dialogContentPropsReject = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Rifiutata"?',
    };
    const onExecuteReject = (e: any) => {
        toggleHideDialogReject()
        dispatch(lock())
        dispatch(Reject({ ...args, Notes: notes }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }

    //suspend
    const onClickSuspend = (e: any) => {
        e.preventDefault()
        toggleHideDialogSuspend()
    }
    const [hideDialogSuspend, { toggle: toggleHideDialogSuspend }] = useBoolean(true);
    const dialogContentPropsSuspend = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Sospesa"?',
    };
    const onExecuteSuspend = (e: any) => {
        toggleHideDialogReject()
        dispatch(lock())
        dispatch(Suspend({ ...args, Notes: notes }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }

    const [buttonsToRender, setButtonsToRender] = React.useState<JSX.Element[]>([]);
    const _accept = <PrimaryButton {...ButtonProps} text='accetta' onClick={onClickAccept} disabled={_isLocked} />
    const _reject = <PrimaryButton {...ButtonProps} text='rifiuta' onClick={onClickReject} disabled={_isLocked} />
    const _suspend = <PrimaryButton {...ButtonProps} text='sospendi' onClick={onClickSuspend} disabled={_isLocked} />

    const [notes, setNotes] = React.useState('');

    React.useEffect(() => {
        setNotes('');
    }, [currentState])

    const dialogs = (<>
        <Dialog hidden={hideDialogAccept} onDismiss={toggleHideDialogAccept} dialogContentProps={dialogContentPropsAccept} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteAccept} text="Accetta" disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogAccept} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>

        <Dialog hidden={hideDialogReject} onDismiss={toggleHideDialogReject} dialogContentProps={dialogContentPropsReject} modalProps={modalProps}>
            <Text
                className="ms-sm12"
                onChange={(_name, value, _req) => setNotes(value?.toString() ?? '')}
                label="note"
                name=""
                type="multiline"
                value={notes}
                rows={2}
            />
            <DialogFooter>
                <PrimaryButton onClick={onExecuteReject} text="Accetta" disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogReject} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>

        <Dialog hidden={hideDialogSuspend} onDismiss={toggleHideDialogSuspend} dialogContentProps={dialogContentPropsSuspend} modalProps={modalProps}>
            <Text
                className="ms-sm12"
                onChange={(_name, value, _req) => setNotes(value?.toString() ?? '')}
                label="note"
                name=""
                type="multiline"
                value={notes}
                rows={2}
            />
            <DialogFooter>
                <PrimaryButton onClick={onExecuteSuspend} text="Sospendi" disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogSuspend} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
    </>);

    return (<>
        <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 18, textAlign: "right" }}>
            {buttonsToRender}
            {dialogs}
        </div>
    </>);
}