import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
    readOnly?: boolean
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} readOnly={props.readOnly}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice Prestazione' fieldName='healthService_code' minWidth={130} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Descrizione Prestazione' fieldName='healthService_description' minWidth={250} align='left' />
                <DataColumn kid='column3' name='Codice Sconto' fieldName='discount_code' minWidth={130} maxWidth={150} align='left' />
                <DataColumn kid='column4' name='Descrizione Sconto' fieldName='discount_description' minWidth={250} align='left' />
                <DataColumn kid='column5' name='Data Inizio Validità' fieldName='startDate' minWidth={150} align='center' />
                <DataColumn kid='column6' name='Data Fine Validità' fieldName='endDate' minWidth={150} align='center' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
