import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (

        <DataList items={props.items} onRowClick={onRowClick} >
            <DataColumn kid='column1' name='Stato' fieldName='leap_BorderauStateID' minWidth={100} maxWidth={300} align='center' />
            <DataColumn kid='column13' name='Nome' fieldName='fileName' minWidth={100} maxWidth={300} align='center' />
            <DataColumn kid='column2' name='Data Creazione' fieldName='creationDate' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column3' name='Piano' fieldName='plan' minWidth={200} maxWidth={300} align='left' />
            <DataColumn kid='column9' name='Data Autorizzazione Da' fieldName='startDateAuthorized' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column10' name='Data Autorizzazione A' fieldName='endDateAuthorized' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column11' name='Data Fine Cure Da' fieldName='startDateCure' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column12' name='Data Fine Cure a' fieldName='endDateCure' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column4' name='Struttura' fieldName='healthFacility' minWidth={150} maxWidth={300} align='left' />
            <DataColumn kid='column5' name='Data Bozza Da' fieldName='startDateDraft' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column6' name='Data Bozza a' fieldName='endDateDraft' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column7' name='Data Lavorazione Da' fieldName='startDateProcessing' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column8' name='Data Lavorazione A' fieldName='endDateProcessing' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column14' name='Data Fattura Da' fieldName='invoiceDateFrom' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column15' name='Data Fattura A' fieldName='invoiceDateTo' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column16' name='Verifica Fattura' fieldName='leap_InsuredInvoiceVerificationID' minWidth={100} maxWidth={300} align='center' />
            <DataColumn kid='column17' name='Data Fattura Da' fieldName='invoiceVerificationDateFrom' minWidth={150} maxWidth={300} align='center' />
            <DataColumn kid='column18' name='Data Fattura A' fieldName='invoiceVerificationDateTo' minWidth={150} maxWidth={300} align='center' />
        </DataList>
    );
}
