import * as React from 'react';
import { CheckboxVisibility, CommandBar, DetailsList, DetailsListLayoutMode, DetailsRow, IColumn, ICommandBarItemProps, Icon, IDetailsListProps, IDetailsRowStyles, SelectionMode, Stack, StackItem, Text as T2 } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { resultMessageTheme } from '../../themes/Fonts';
import Theme from '../../themes/Theme';
import Separator from '../customization/Separator';

type Props = {
    items?: any[],
    columns: IColumn[],
    onRowClick?: any,
    selectionMode?: SelectionMode,
    selection?: any
}

export default (props: Props) => {
    const _onRenderRow: IDetailsListProps['onRenderRow'] = eventProps => {
        const customStyles: Partial<IDetailsRowStyles> = {}

        if (eventProps) {
            if (eventProps.itemIndex % 2 === 0) {
                customStyles.root = { backgroundColor: Theme.fluent.palette.themeLighterAlt, color: Theme.fluent.palette.neutralPrimaryAlt };
            }
            else {
                customStyles.root = { color: Theme.fluent.palette.neutralPrimaryAlt };
            }

            if (props.onRowClick) {
                const rowClick = props.onRowClick ?? ((e: any, data: any) => { })
                return <a style={{ cursor: 'pointer', display: 'block' }} onClick={e => { rowClick(e, eventProps.itemIndex, eventProps.item) }}><DetailsRow {...eventProps} styles={customStyles} /></a>
            }
            else {
                customStyles.cell = { cursor: 'default' }
                return <DetailsRow {...eventProps} styles={customStyles} />
            }
        }
        return null
    };

    const DetailsListProps: Partial<IDetailsListProps> = {
        className: "alarms-datails-list",
        compact: true,
        layoutMode: DetailsListLayoutMode.justified,
        setKey: "set",
        checkboxVisibility: CheckboxVisibility.always,
        selectionMode: props.selectionMode ?? SelectionMode.none,
        selectionPreservedOnEmptyClick: true,
        onRenderRow: _onRenderRow,
    }

    const [pageIndex, setPageIndex] = useState(1);
    const pageCount = props.items?.length ?? 0;

    const [items, setItems] = useState(props.items)
    const [paginated, setPaginated] = useState<any[]>([])
    const pageRows = 15

    useEffect(() => {
        let tPaginated: any[] = [];
        for (let i = 0; i < pageCount; i += pageRows) {
            tPaginated.push(items?.slice(i, i + pageRows));
        }
        setPaginated(tPaginated)
        setItems(tPaginated[0])
    }, [props.items])

    const _items: ICommandBarItemProps[] = []
    const _farItems: ICommandBarItemProps[] = [
        {
            key: 'position',
            disabled: true,
            style: { color: Theme.leap.customization.pagerToolbar?.label.color },
            text: pageIndex + " / " + paginated.length,
        },
        {
            key: 'goFirst',
            iconOnly: true,
            iconProps: { iconName: 'DoubleChevronLeft' },
            onClick: (e: any) => {
                e.preventDefault()
                setItems(paginated[0])
                setPageIndex(1)
            },
        },
        {
            key: 'goPrevious',
            iconOnly: true,
            iconProps: { iconName: 'ChevronLeft' },
            onClick: (e: any) => {
                e.preventDefault()
                if (pageIndex === 1) {
                    return
                }
                setItems(paginated[pageIndex - 2])
                setPageIndex(pageIndex - 1)
            },
        },
        {
            key: 'goNext',
            iconOnly: true,
            iconProps: { iconName: 'ChevronRight' },
            onClick: (e: any) => {
                e.preventDefault()
                if (pageIndex === paginated.length) {
                    return
                }
                setItems(paginated[pageIndex])
                setPageIndex(pageIndex + 1)

            },
        },
        {
            key: 'goLast',
            iconOnly: true,
            iconProps: { iconName: 'DoubleChevronRight' },
            onClick: (e: any) => {
                e.preventDefault()
                setItems(paginated[paginated.length - 1])
                setPageIndex(paginated.length)
            },
        },
    ];

    return <>
        <Separator>
            <Stack horizontal style={{ width: "100%" }}>
                <StackItem className="left-command-toolbar" grow align="start">
                    <Icon iconName="Info" style={{ backgroundColor: Theme.fluent.palette.white, paddingLeft: 8, }} />
                    <T2 theme={resultMessageTheme}>{props.items?.length ?? 0} risultati</T2>
                </StackItem>
                <StackItem className="right-command-toolbar" align="end">
                    <CommandBar className="leap-commandbar" items={_items} farItems={_farItems} />
                </StackItem>
            </Stack>
        </Separator>
        <DetailsList
            {...DetailsListProps}
            columns={props.columns ?? [{ key: 'NO_KEY', name: 'NO_NAME', fieldName: 'NO_FIELDNAME', minWidth: 0 }]}
            items={items ?? []}
            selection={props.selection}
        />
    </>
}
