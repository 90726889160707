import * as React from 'react';

import { Stack, IStackStyles, IStackItemStyles } from '@fluentui/react/lib/Stack';

import Theme from '../../themes/Theme';
import { useLocation } from 'react-router';
import { authorized } from '../../../features/security/thunks/securityThunk';
import { useSelector } from 'react-redux';

const stackStyles: IStackStyles = {
    root: {
        margin: '0 auto',
    },
};

const contentStyles: IStackItemStyles = {
    root: {
        marginBottom: 8,
        marginTop: 0,
        width: '100%',
    },
};

const contentStyle: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.backgroundColor,
    paddingBottom: 24,
    paddingTop: 2,
    paddingLeft: 24,
    paddingRight: 24,
};

export default (props: { children?: React.ReactNode }) => {
    const location = useLocation()
    const isAuthorized = useSelector(authorized)

    let customContentStyle: React.CSSProperties = { ...contentStyle }
    if (!isAuthorized || location.pathname == '/changepassword/' || location.pathname == '/ChangePassword/') {
        customContentStyle.backgroundColor = "unset"
    }

    return <>
        <Stack className={Theme.leap.main.className} horizontal verticalFill styles={stackStyles}>
            <Stack.Item grow styles={contentStyles}>
                <div style={customContentStyle}>
                    {props.children}
                </div>
            </Stack.Item>
        </Stack>
    </>
}