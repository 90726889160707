import * as React from 'react';
import { useDispatch } from 'react-redux'
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { setOperation } from '../operation/operationSlice';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';


type Props = {
    items: any,
    controller: any,
    state?: any
}

export default (props: Props) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data.id }))
    }

    const roles = useSecurityRoles()

    return (
        <>
            <DataList items={props.items} modalController={props.controller} state={props.state}>
                <DataColumn kid='column1' name='Elemento Dentale' fieldName='dentalElement' minWidth={140} maxWidth={140} align='center' />
                {!roles.isHF && <DataColumn kid='column2' name='Data' fieldName='stateDate' minWidth={120} maxWidth={120} align='center' />}
                < DataColumn kid='column3' name='Stato' fieldName='dentalState' minWidth={160} maxWidth={160} align='left' />
                <DataColumn kid='column4' name='Operatore' fieldName='user' minWidth={80} align='left' />
            </DataList>
        </>
    );
}
