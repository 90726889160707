import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...healthFacilityID} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm6", required: true }} dateMax={{ ...endDate, className: "ms-sm6" }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
