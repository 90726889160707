import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const discount = useLeapData("discount", "scontistiche", page)
    const branchID = useLeapData("branchID", "branca", page)
    const healthservice = useLeapData("healthservice", "prestazione", page)
    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)


    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...discount} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...branchID} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...healthservice} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...start_date, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...end_date, className: "ms-sm5" }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
