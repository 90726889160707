import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';

type Props = {
    state: any,
    controller: any,
    canViewRefounds?: boolean
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branchDescr = useLeapData("branchDescr", "branca", page)
    const healthServiceID = useLeapData("healthServiceDescr", "prestazione", page)
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)

    const amount = useLeapData("amount", "importo", page)
    const refound = useLeapData("refound", "rimborso", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...branchDescr} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...healthServiceID} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...dentalElement} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...amount} className="ms-sm2" type="number" />
                        {props.canViewRefounds && <Text {...refound} className="ms-sm2" type="number" />}
                    </Row>
                </Section>
            </Grid>
        </>
    );
}