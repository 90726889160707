import { FontIcon, IButtonProps, PrimaryButton, Pivot, PivotItem, DefaultButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import FeedbackPlanList from '../features/feedback/FeedbackPlanList';
import FeedbackPlanModal from '../features/feedback/FeedbackPlanModal';
import FeedbackResultList from '../features/feedback/FeedbackResultList';
import FeedbackHealthFacilityList from '../features/feedback/FeedbackHealthFacilityList';
import FeedbackHealthFacilityModal from '../features/feedback/FeedbackHealthFacilityModal';

import FormList from '../features/feedback/FormList';
import FormModal from '../features/feedback/FormModal';
import { isLocked, lock, unlock } from '../features/operation/lockSlice';
import { itemData, itemPrint } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import MultiSearchBox from '../components/controls/MultiSearchBox';

export const getAnswers = createAsyncThunk(
    'Feedback/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'Download', undefined, args.token)
        return response
    }
)

const ButtonProps: IButtonProps = {
    style: {
        verticalAlign: "bottom",
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        marginTop: 20
    }
}
const PivotButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
        verticalAlign: "bottom"
    },
    styles: {
        root: {
            backgroundColor: Theme.fluent.palette.themeTertiary,
            color: Theme.fluent.palette.neutralLighter,
            height: 38,
            marginTop: 12,
            minWidth: "12%",
        },
        rootDisabled: {
            backgroundColor: Theme.fluent.palette.themePrimary,
            color: Theme.fluent.palette.white,
            height: 42,
            marginTop: 8,
            minWidth: "15%",
        },
        rootHovered: {
            backgroundColor: Theme.fluent.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: Theme.fluent.palette.themeLight,
        }
    }
}

export default () => {
    const controller = "Feedback"
    const feedbackPlan = "FeedbackPlan"
    const feedbackHealthFacility = "FeedbackHealthFacility"
    const form = "Form"

    const multiSearchBoxItemLimit = 99;

    const roles = useSecurityRoles()
    const page = useLeapState(controller, roles.role !== undefined)

    const province = useLeapData("provinceID", "provincia", page, "provinces")
    const municipality = useLeapData("municipalityID", "comune", page, "municipalities")

    const title = useLeapData("title", "titolo", page)
    const code = useLeapData("code", "codice", page)
    const startDate = useLeapData("startDate", "data inizio", page)
    const endDate = useLeapData("endDate", "data fine", page)
    const leap_FeedbackRequestTypeID = useLeapData("leap_FeedbackRequestTypeID", "oggetto", page, "requestTypes")
    const leap_FeedbackRecipentID = useLeapData("leap_FeedbackRecipentID", "destinatario", page, "recipientTypes")
    const leap_QuoteStateID = useLeapData("leap_QuoteStateID", "stato preventivo", page, "quoteStates")
    const annualFrequency = useLeapData("annualFrequency", "cadenza mensile", page)
    const minDate = useLeapData("minDate", "dal", page)
    const maxDate = useLeapData("maxDate", "al", page)

    const recipientHF = page.item?.values?.leap_FeedbackRecipentID?.value === '1';
    const recipientInsured = page.item?.values?.leap_FeedbackRecipentID?.value === '2';
    const requestToHf = page.item?.values?.leap_FeedbackRequestTypeID?.value === '1';

    const quoteStateSelected = !!page.item?.values?.leap_QuoteStateID?.value
    const annualFreqSelected = !!page.item?.values?.annualFrequency?.value

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential

    const _isLocked: boolean = useSelector(isLocked)
    //page.item.values.dentalElement?.value

    const iForm = useSelector(itemData)
    const downloadAnswers = () => {
        dispatch(lock())
        dispatch(getAnswers({
            controller, body: {
                form: {
                    id: iForm.find(i => i.key === controller)?.value?.data?.id,
                    fileName: `${page.item?.state?.data?.code ?? "RisposteFeedback"}.csv`,
                    minDate: page.item?.values?.minDate?.value,
                    maxDate: page.item?.values?.maxDate?.value
                }, page: {}
            }, token: credential.token
        }))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const print = () => {
        dispatch(lock())
        dispatch(itemPrint({ controller, lookup: "lookup", body: { form: { id: iForm.find(i => i.key === controller)?.value?.data?.id }, page: {} }, token: credential?.token }))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = React.useState<"general" | "statistics">("general")

    const provinceDisabled = !!page.item?.values.municipalityID?.value && page.item?.values.municipalityID?.value?.length > 0

    return <>
        <Content title="Feedback" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...title} className="ms-sm5" maxlength={50} />
                        </Row>

                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <FeedbackResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    {page.modality === "item" && <>
                        <Section toolbar="item" controller={controller}>
                            <div className="ms-Grid-col ms-sm12" style={{ height: 50 }}>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "general"} onClick={() => setPivotItemSelectedKey("general")}>configurazione</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "statistics"} onClick={() => setPivotItemSelectedKey("statistics")}>statistiche</DefaultButton>
                            </div>
                        </Section>
                    </>}
                    <Pivot selectedKey={pivotItemSelectedKey}>
                        <PivotItem headerText="configurazione" itemKey="general">
                            <Section toolbar={page.modality === "item" ? undefined : "item"} controller={controller}>
                                <Row>
                                    {page.modality === "item" && <Text {...code} className="ms-sm2" readOnly />}
                                    <Text {...title} className="ms-sm6" required />
                                </Row>
                                <Row>
                                    <DateRange dateMin={{ ...startDate, className: "ms-sm2", required: true, readOnly: page.modality === 'item' }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                                    <Text {...annualFrequency} className="ms-sm2" type='number' hidden={requestToHf} readOnly={page.modality === 'item' || quoteStateSelected} />
                                </Row>
                                <Row>
                                    <ComboBox {...leap_FeedbackRequestTypeID} className="ms-sm3" required disabled={page.modality === 'item'} />
                                    <ComboBox {...leap_FeedbackRecipentID} className="ms-sm3" required disabled={page.modality === 'item'} />
                                    <ComboBox {...leap_QuoteStateID} className="ms-sm3" hidden={!recipientInsured || (!quoteStateSelected && page.modality === 'item')} disabled={annualFreqSelected || (page.modality === 'item')} />
                                </Row>
                                {recipientHF && <>
                                    <Row>
                                        <MultiSearchBox {...province} className="ms-sm6" itemLimit={multiSearchBoxItemLimit} />
                                        <MultiSearchBox {...municipality} className="ms-sm6" itemLimit={multiSearchBoxItemLimit} />
                                    </Row>
                                </>}
                                {page.modality === 'item' &&
                                    <Section title="Moduli" toolbar="grid" modal={<FormModal state={page} controller={form} />} controller={form} modalwidth="800px" modaltitle="Inserisci Modulo" state={page} >
                                        <Row>
                                            <FormList items={page.item.state?.lists?.forms} modal={<FormModal state={page} controller={form} />} controller={form} modalwidth="800px" modaltitle="Modifica Modulo" state={page} />
                                        </Row>
                                    </Section>}
                                {page.modality === 'item' && recipientInsured &&
                                    <Section title="Destinatari Piano" toolbar="grid" modal={<FeedbackPlanModal state={page} controller={feedbackPlan} />} controller={feedbackPlan} modalwidth="800px" modaltitle="Inserisci Piano Destinatario" state={page} >
                                        <Row>
                                            <FeedbackPlanList items={page.item.state?.lists?.feedbackPlanData} modal={<FeedbackPlanModal state={page} controller={feedbackPlan} />} controller={feedbackPlan} modalwidth="800px" modaltitle="Modifica Piano" state={page} />
                                        </Row>
                                    </Section>}
                                {page.modality === 'item' && recipientHF &&
                                    <Section title="Strutture Sanitarie" toolbar="grid" modal={<FeedbackHealthFacilityModal state={page} controller={feedbackHealthFacility} />} controller={feedbackHealthFacility} modalwidth="800px" modaltitle="Inserisci Piano Destinatario" state={page} >
                                        <Row>
                                        <FeedbackHealthFacilityList items={page.item.state?.lists?.feedbackHealthFacilityData} modal={<FeedbackHealthFacilityModal state={page} controller={feedbackHealthFacility} />} controller={feedbackHealthFacility} modalwidth="800px" modaltitle="Modifica Struttura Sanitaria" state={page} />
                                        </Row>
                                    </Section>}
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="statistiche" itemKey="statistics">
                            <Section title="risposte (csv)">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...minDate, className: "ms-sm2" }}
                                        dateMax={{ ...maxDate, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    {page.modality === "item" && <PrimaryButton className="ms-Grid-col ms-sm2" {...ButtonProps} onClick={downloadAnswers} disabled={_isLocked}>scarica risposte &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>}
                                </Row>
                            </Section>
                            <Section title="statistiche (pdf)">
                                <Row>
                                    {page.modality === "item" && <PrimaryButton className="ms-Grid-col ms-sm2" {...ButtonProps} onClick={print} disabled={_isLocked}>scarica statistiche &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>}
                                </Row>
                            </Section>
                        </PivotItem>
                    </Pivot>
                </Grid>
            </Item>
        </Content>
    </>
}