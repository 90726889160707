import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setOperation } from '../operation/operationSlice';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

export default (props: { controller: string, items: any, isConsultant?: boolean, isCorporation?: boolean, isCompany?: boolean }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const canViewColumns = !props.isConsultant && !props.isCorporation && !props.isCompany

    const roles = useSecurityRoles()

    const isONHC = roles.isONHC
    const isInsuredUser = roles.isInsuredUser

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                {canViewColumns && !isInsuredUser && <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={80} maxWidth={100} align='left' />}
                {canViewColumns && !isInsuredUser && <DataColumn kid='column2' name='Nome' fieldName='firstnameLegal' minWidth={250} maxWidth={250} align='left' />}
                {canViewColumns && !isInsuredUser && <DataColumn kid='column3' name='Cognome' fieldName='lastnameLegal' minWidth={250} maxWidth={250} align='left' />}
                {canViewColumns && !isInsuredUser && <DataColumn kid='column4' name='Ragione Sociale' fieldName='businessName' minWidth={250} maxWidth={250} align='left' />}

                <DataColumn kid='column5' name='Denominazione' fieldName='denomination' minWidth={250} maxWidth={canViewColumns ? 250 : undefined} align='left' />
                <DataColumn kid='column6' name='Telefono' fieldName='telephoneNumber' minWidth={100} maxWidth={100} align='left' />

                <DataColumn kid='column7' name='E-mail' fieldName='email' minWidth={250} maxWidth={250} align='left' />
                {roles.isONHC && <DataColumn kid='column8' name='E-mail Pubblica' fieldName='publicEmail' minWidth={250} maxWidth={250} align='left' />}
                <DataColumn kid='column9' name='Provincia' fieldName='provinceID' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column10' name='Comune' fieldName='municipalityID' minWidth={150} maxWidth={150} align='left' />
                {isInsuredUser && <DataColumn kid='column11' name='Indirizzo' fieldName='address' minWidth={180} maxWidth={200} align='left' />}
                {isInsuredUser && <DataColumn kid='column12' name='Cap' fieldName='postalCode' minWidth={80} maxWidth={100} align='left' />}

                {canViewColumns && !isInsuredUser && <DataColumn kid='column13' type="icon" name='Adesione Welfare' fieldName='leap_HealthFacilityMembershipWelfareID' minWidth={120} maxWidth={150} align='center' />}
                {canViewColumns && !isInsuredUser && <DataColumn kid='column14' type="icon" name='Adesione Agenda Online' fieldName='leap_HealthFacilityOnlineAgendaID' minWidth={150} maxWidth={150} align='center' />}
                {canViewColumns && <DataColumn kid='column15' name='Tipo di struttura' fieldName='leap_HealthFacilityStructureTypeID' minWidth={150} maxWidth={150} align='left' />}
                {canViewColumns && isONHC && <DataColumn kid='column16' type="icon" name='Variazione Tariffario' fieldName='leap_HealthFacilityVariationAcceptanceLowerRateID' minWidth={150} maxWidth={150} align='center' />}
                {isInsuredUser && <DataColumn kid='column17' type="icon" name='OPX' fieldName='leap_HealthFacilityOrthopantomographID' minWidth={80} maxWidth={100} align='center' />}
                {isInsuredUser && <DataColumn kid='column18' name='Sito web' fieldName='website' minWidth={180} maxWidth={200} align='left' />}
            </DataList>
        </>
    );
}