import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from '../app/store';
import { client } from '../api/client';

import { FontSizes, IImageStyles, Image, ImageFit, PrimaryButton, Text } from '@fluentui/react/';

import { unitTheme } from '../components/themes/Fonts';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';

export const acceptReservation = createAsyncThunk(
    'item/AcceptReservation',
    async (args: { guid: string | undefined }) => {
        const response = await client.get("Reservation", undefined, 'Accept/' + args.guid, undefined)
        return response
    }
)

export default () => {

    const dispatch: AppDispatch = useDispatch();

    const [state, setState] = React.useState<"loading" | "verified" | "unverified">("loading")
    const search = useLocation()?.search;
    const guid = new URLSearchParams(search)?.get('g');


    React.useEffect(() => {
        if (!!guid) {
            dispatch(acceptReservation({ guid }))
                .then((response) => {
                    setState(response.payload.response.level === 4 ? "verified" : "unverified")
                });
        }
    }, [guid])

    const titleStyles: React.CSSProperties = {
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        color: Theme.fluent.palette.neutralPrimaryAlt,
        display: "inline-block",
        marginLeft: 8,
        marginTop: 30,
        lineHeight: "80px",
    }
    const imageStyles: Partial<IImageStyles> = {
        root: {
            display: "inline-block",
            height: 80,
            marginLeft: 50,
            width: 110
        },
        image: {
            display: "inline-block"
        }
    }

    const messageStyle: React.CSSProperties = {        
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.mediumPlus,
        fontWeight: 500,
        textAlign: 'left'
    }

    switch (state) {
        case "loading":
            return <>
                <div style={{ backgroundColor: "#fff", borderRadius: 4, marginTop: 40, padding: "0 20px" }}>
                    <Image
                        src="/assets/media/images/odontonetwork-oral-health-care-management-logo-vector.png"
                        alt="Odontonetwork Oral Health Care Management"
                        imageFit={ImageFit.centerContain}
                        height="60vh"
                        style={{
                            width: "85%",
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }}
                        draggable={false}
                    />
                </div>
            </>
        case "unverified":
            return <>
                <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", margin: "30px auto 10px", padding: "20px 0 20px", width: 500 }} >
                    <div style={{ float: "left" }}>
                        <Image styles={imageStyles} src="/assets/media/images/onhc-logo.png" imageFit={ImageFit.contain} />
                    </div>
                    <div style={{ float: "left" }}>
                        <Text style={titleStyles} theme={unitTheme}>Servizio Prenotazione</Text>
                    </div>
                    <div style={{ margin: "0 auto" }}>
                        <div style={{ display: 'inline-block', margin: "10px 0 0", textAlign: "center", width: '100%' }}>
                            <span style={messageStyle}>Codice non valido. </span>
                        </div>
                        <div style={{ display: 'inline-block', margin:"10px 0 0", textAlign: "center", width: '100%' }}>
                            <span style={messageStyle}>Si prega di contattare ONHC.</span>
                        </div>
                    </div>
                </div>

            </>
        case "verified":
            return <>
                <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", margin: "30px auto 10px", padding: "20px 0 20px", width: 500 }} >
                    <div style={{ float: "left" }}>
                        <Image styles={imageStyles} src="/assets/media/images/onhc-logo.png" imageFit={ImageFit.contain} />
                    </div>
                    <div style={{ float: "left" }}>
                        <Text style={titleStyles} theme={unitTheme}>Servizio Prenotazione</Text>
                    </div>
                    <div style={{ margin: "0 auto" }}>
                        <div style={{ display: 'inline-block', margin: "10px 0 0", textAlign: "center", width: '100%' }}>
                            <span style={messageStyle}>La sua prenotazione è stata confermata.</span>
                        </div>
                    </div>
                </div>

            </>
        default:
            return <></>
    }
}
