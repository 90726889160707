import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Separator from '../../components/controls/grid/Separator';

import Text from '../../components/controls/Text';
import MultiBox from '../../components/controls/MultiBox';
import Option from '../../components/controls/Option';
import FileUploader from '../../components/controls/FileUploader';
import ComboBox from '../../components/controls/ComboBox';

type Props = {
    state: any,
    controller: any,
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const ID = useLeapData("planAgeID", "età", page, "planAges")
    const kinshipDegreeID = useLeapData("kinshipDegreeIDs", "grado di parentela", page, "kinshipDegrees")
    const planAgeID = useLeapData("planAgeID", "età", page, "planAges")
    const minAge = useLeapData("minAge", "età minima", page)
    const maxAge = useLeapData("maxAge", "età massima", page)
    const threshold = useLeapData("threshold", "threshold", page)
    const membershipFee = useLeapData("membershipFee", "valore quota", page)

    const ageID = page.item.values.planAgeID?.value
    const minAge_value = page.item.values.minAge?.value
    const maxAge_value = page.item.values.maxAge?.value
    const minAge_show = ageID === "2" || ageID === "4"
    const maxAge_show = ageID === "3" || ageID === "4"

    return <>
        <Grid>
            <Section>
                <Row>
                    <MultiBox {...kinshipDegreeID} className="ms-sm12" titleType="text" />
                </Row>
                <Row>
                    <ComboBox {...planAgeID} className="ms-sm3" />
                    <Text {...minAge} className="ms-sm2" type="number" hidden={!minAge_show} required={minAge_show} precision={{ integer: 3, decimal: 0, min: 0, max: maxAge_value ? Number(maxAge_value) : 120 }} />
                    <Text {...maxAge} className="ms-sm2" type="number" hidden={!maxAge_show} required={maxAge_show} precision={{ integer: 3, decimal: 0, min: minAge_value ? Number(minAge_value) : 0, max: 120 }} />
                </Row>
                <Row>
                    <Text {...threshold} className="ms-sm2" type="number" precision={{ integer: 2, decimal: 0, min: 0 }} />
                    <Text {...membershipFee} className="ms-sm2" type="number" required precision={{ integer: 9, decimal: 2, min: 0 }} />
                </Row>
            </Section>
        </Grid>
    </>

}