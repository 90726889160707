import * as React from 'react';

import { Text } from '@fluentui/react/';

import { ToolbarType } from '../../../../defs/toolbars'

import Separator from '../../../../components/controls/customization/Separator';
import Row from '../Row';

import Theme from '../../../themes/Theme';
import { sectionTheme } from '../../../themes/Fonts';

const headerTitleSectionStyles: React.CSSProperties = {
    padding: Theme.leap.main.content.section.header.topPadded,
};

const titleStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    color: Theme.fluent.palette.neutralPrimaryAlt,
    display: "inline-block",
    paddingRight: "12px",
};

type Props = { title?: string, toolbar?: ToolbarType, controller?: string }

export default (props: Props) => {
    return props.title ?
        <>
            <Row isTitleRow>
                <div className="ms-Grid-col ms-sm12" style={headerTitleSectionStyles}>
                    <Separator>
                        <Text style={titleStyles} theme={sectionTheme}>{props.title}</Text>
                    </Separator>
                </div>
            </Row>
        </> :
        null
};