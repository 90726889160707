import { IComboBoxOption } from '@fluentui/react';
import * as React from 'react';
import ComboBox from '../../components/controls/ComboBox';
import DentalChart from '../../components/controls/dental/DentalChart';
import { DentalElements } from '../../components/controls/dental/dentalElements';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import MultiBox from '../../components/controls/MultiBox';
import SearchBox from '../../components/controls/SearchBox';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = {
    state: any,
    controller: any
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const endOfCureDate = useLeapData("endOfCureDate", "data fine cura", page)
    const branchID = useLeapData("branchID", "branca", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServices")
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)

    const items: IComboBoxOption[] = DentalElements
    const selectedTeeth = page.item.values?.dentalElement ? page.item.values.dentalElement.value : (dentalElement ? (dentalElement.value ?? []) : []);

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...endOfCureDate} type="calendar" className="ms-sm3" />
                        <ComboBox {...branchID} className="ms-sm9" />
                    </Row>
                    <Row>
                        <SearchBox {...healthServiceID} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <MultiBox {...dentalElement} className="ms-sm12" options={items} selectedKey={selectedTeeth} required />
                    </Row>
                </Section>
                <Section title="elementi dentali">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Row>
                            <DentalChart {...dentalElement} selectionMode="multiple" value={selectedTeeth} required />
                        </Row>
                    </div>
                </Section>
            </Grid>
        </>
    );
}