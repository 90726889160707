import * as React from 'react';

import { useSelector } from 'react-redux';

import { resultData } from '../../../../features/operation/thunks/resultThunk';

import { Stack, StackItem, Text, Icon } from '@fluentui/react/';

import { ToolbarType } from '../../../../defs/toolbars'

import PagerToolbar from '../../../controls/toolbar/PagerToolbar'

import Separator from '../../customization/Separator';
import Row from '../Row';

import Theme from '../../../themes/Theme';
import { resultMessageTheme } from '../../../themes/Fonts';

const headerToolbarSectionStyles: React.CSSProperties = {
    padding: Theme.leap.main.content.section.header.bottomPadded,
};

type Props = { title?: string, toolbar?: ToolbarType, controller: string }

export default (props: Props) => {
    const rData = useSelector(resultData);
    const dResult = rData.find(d => d.key === props.controller)?.value

    return <>
        <Row isTitleRow>
            <div className="ms-Grid-col ms-sm12" style={headerToolbarSectionStyles}>
                <Separator>
                    <Stack horizontal style={{ width: "100%" }}>
                        <StackItem className="left-command-toolbar" grow align="start">
                            <Icon iconName="Info" style={{ backgroundColor: Theme.fluent.palette.white, paddingLeft: 8, }} />
                            <Text theme={resultMessageTheme}>sono stati trovati {dResult?.metadata?.page?.count ?? 0} risultati</Text>
                        </StackItem>
                        <StackItem className="right-command-toolbar" align="end">
                            <PagerToolbar controller={props.controller} />
                        </StackItem>
                    </Stack>
                </Separator>
            </div>
        </Row>
    </>
};