import * as React from 'react';
import { useEffect } from 'react';
//import { useSelector } from 'react-redux';
import { Checkbox } from '@fluentui/react/';
//import { currentResponse } from '../../features/response/responseSlice';

type Props = {
    name?: string,
    className: string,
    label: string,
    value: string | number | undefined,
    required?: boolean,
    disabled?: boolean,
    mandatory?: (name: string) => boolean,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined, e?: { cancel: boolean }) => void;
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    hidden?: boolean,
}

export default (props: Props) => {

    const [checked, setChecked] = React.useState<string | number | undefined>(0);

    useEffect(() => {
        setChecked(props.value)

        if (props.onInit) {
            props.onInit(props.name, props.value, props.required)
        }
    }, [props.name, props.value, props.required, props.disabled])

    const onChange = (event: any, checked?: boolean) => {
        var e = { cancel: false }

        setChecked(checked ? 1 : 0)

        if (props.onChange)
            props.onChange(props.name, checked.toString(), props.required, e)
    }

    const hidden: React.CSSProperties = props.hidden ? { display: "none" } : { marginTop: 8 }

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <Checkbox {...props} onChange={onChange} checked={!!checked} styles={{ label: { flexDirection: "column-reverse" }, text: { fontFamily: "Montserrat", fontWeight: 500, margin: 0, padding: 0 } }} />
        </div>
    </>
};
