import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ITextFieldStyles, MaskedTextField } from '@fluentui/react/';

import { currentResponse } from '../../features/response/responseSlice';
import { TextFieldProps, ErrorTextFieldProps, TimeFieldProps } from '../../defs/props';

type Props = {
    name?: string,
    className: string,
    label: string,
    readOnly?: boolean,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    value?: any,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    hidden?: boolean,
    buttonRef?: React.MutableRefObject<any>
}

export default (props: Props) => {
    const response = useSelector(currentResponse)

    const onChangeHandler = (event: any, ref: any) => {
        var value = undefined

        if (!!event.target?.value) {
            if (ref.current.tagName != "BUTTON") setTimeout(() => ref.current._textField.current._textElement.current.value = null, 1)
            ref.current.focus()
        }

        if (!!!digit0.current.value) value = undefined
        else if (!!!digit1.current.value) value = undefined
        else if (!!!digit2.current.value) value = undefined
        else if (!!!digit3.current.value) value = undefined
        else if (!!!digit4.current.value) value = undefined
        else if (!!!digit5.current.value) value = undefined
        else value = '' + digit0.current.value + digit1.current.value + digit2.current.value + digit3.current.value + digit4.current.value + digit5.current.value

        if (props.onChange)
            props.onChange(props.name, value, props.required)
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const textStyle = response.notified && !mandatory ? { ...ErrorTextFieldProps } : { ...TextFieldProps };
    const hidden = props.hidden ? { display: "none" } : undefined

    const maskedTextFieldStyle: Partial<ITextFieldStyles> = {
        root: {
            display: 'inline-block',
            marginLeft: 4,
            marginRight: 4,
            width: '2em'
        },
        field: {
            textAlign: 'center'
        }
    }

    const digit0 = useRef(null)
    const digit1 = useRef(null)
    const digit2 = useRef(null)
    const digit3 = useRef(null)
    const digit4 = useRef(null)
    const digit5 = useRef(null)

    return <>
        <div className={"ms-Grid-col " + props.className} style={{ ...hidden, textAlign: 'center' }}>
            <MaskedTextField ref={digit0} onChange={(e) => onChangeHandler(e, digit1)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} label={props.label} required={props.required} autoComplete="off" />
            <MaskedTextField ref={digit1} onChange={(e) => onChangeHandler(e, digit2)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} autoComplete="off" />
            <MaskedTextField ref={digit2} onChange={(e) => onChangeHandler(e, digit3)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} autoComplete="off" />
            <MaskedTextField ref={digit3} onChange={(e) => onChangeHandler(e, digit4)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} autoComplete="off" />
            <MaskedTextField ref={digit4} onChange={(e) => onChangeHandler(e, digit5)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} autoComplete="off" />
            <MaskedTextField ref={digit5} onChange={(e) => onChangeHandler(e, props.buttonRef ?? digit0)} {...textStyle} mask={'9'} maskChar=" " styles={maskedTextFieldStyle} autoComplete="off" />
        </div>
    </>
}