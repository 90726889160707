import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';

import store from './app/store'

import { loadTheme } from './components/themes/Theme';
import loadIcon from './components/themes/Icons';


loadTheme();
loadIcon();

const render = () => {
    const App = require('./app/App').default;

    ReactDOM.render(
        <Provider store={store}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </Provider>,
        document.getElementById('root')
    );
};

render();