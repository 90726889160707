import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

export default (props: { controller: string, items: any }) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Branca' fieldName='branchID' minWidth={120} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthserviceID' minWidth={250} maxWidth={300} align='left' />
                {useSecurityRoles().isONHCMaster && <DataColumn kid='column3' name='Tariffa Massimale' fieldName='maxRate' minWidth={100} maxWidth={120} align='right' />}
                <DataColumn kid='column4' name='Tariffa Struttura Sanitaria' fieldName='ssRate' minWidth={150} maxWidth={170} align='right' />
                <DataColumn kid='column5' name='Elementi Dentali' fieldName='dentalElement' minWidth={250} align='left' />
            </DataList>
        </>
    );
}
