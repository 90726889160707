import { DialogFooter, FontSizes, getTheme, Icon, mergeStyleSets, Modal, PrimaryButton, ILabelStyles, Label, Checkbox } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Coachmark from '../../components/controls/Coachmark';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Option from '../../components/controls/Option';
import Text from '../../components/controls/Text';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { itemDownload } from '../operation/thunks/itemThunk';
import { authorized, security } from '../security/thunks/securityThunk';
import Separator from '../../components/controls/grid/Separator';

export const IsPrivacyAccepted = createAsyncThunk(
    'item/InsuredAcceptedPrivacy',
    async (args: { token: string | undefined }) => {
        const response = await client.post("Insured", { form: {}, page: {} }, 'InsuredAcceptedPrivacy', args.token)
        return response
    }
)
export const FindNotAcceptedPrivacies = createAsyncThunk(
    'item/FindNotAcceptedPrivacies',
    async (args: { token: string | undefined }) => {
        const response = await client.post("Insured", { form: {}, page: {} }, 'FindNotAcceptedPrivacies', args.token)
        return response
    }
)
export const InsuredAcceptsPrivacy = createAsyncThunk(
    'item/InsuredAcceptsPrivacy',
    async (args: { token: string | undefined }) => {
        const response = await client.post("Insured", { form: {}, page: {} }, 'InsuredAcceptsPrivacy', args.token)
        return response
    }
)

function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '40vw'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.openSans,
            fontSize: FontSizes.xxLargePlus,
            fontVariant: 'small-caps',
            fontWeight: 500,
            lineHeight: 44,
            paddingLeft: 20,
            paddingTop: 10,
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "justify",
        whiteSpace: "pre-wrap"
    }
}

export default () => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const roles = useSecurityRoles()
    const isInsured = roles.isInsuredUser;
    const credential = useSelector(security)?.credential

    const [privacyAccepted, setPrivacyAccepted] = React.useState<boolean | undefined>(undefined)
    const [files, setFiles] = React.useState<any>()
    const [filesToDownload, setFilesToDownload] = React.useState<any>(null)
    const [privacyToAccept, setPrivacyToAccept] = React.useState<any>(null)

    React.useEffect(() => {
        if (isInsured) {
            dispatch(IsPrivacyAccepted(
                {
                    token: credential.token
                }))
                .then((response) => {
                    setPrivacyAccepted(response.payload.data)
                })
        }
    }, [isInsured])
    React.useEffect(() => {
        if (privacyAccepted === false) {
            dispatch(FindNotAcceptedPrivacies(
                {
                    token: credential.token
                }))
                .then((response) => {
                    setFiles(response.payload.data);
                    return response.payload.data;
                })
                .then((data) => {
                    var keys: any = [];
                    data.map((i: any) => i?.items.map((f: any) => keys.push(f?.fileID)))

                    setFilesToDownload(keys)
                    setPrivacyToAccept(keys)
                    showModal()
                })
        } else {
            hideModal()
        }
    }, [privacyAccepted])
    React.useEffect(() => {
        timeout(1000).then(() => {
            if (privacyAccepted === false &&
                Object.keys(files ?? {}).length !== 0) {
                showCoachmark()
            }
        })
    }, [files, privacyAccepted])

    const downloadFile = (f: any) => {
        setFilesToDownload(filesToDownload?.filter((fid: number) => fid !== f?.fileID))
        const args = {
            controller: "PrivacyFile",
            fileID: f?.fileID,
            fileName: f?.fileDescription,
            token: credential.token
        }
        dispatch(itemDownload(args))
    }
    const acceptPrivacy = () => {
        dispatch(InsuredAcceptsPrivacy({ token: credential.token }))
            .then(hideModal)
            .then(hideCoachmark)
    }

    const isAuthorized = useSelector(authorized)

    const [coachmarkVisible, { setTrue: showCoachmark, setFalse: hideCoachmark }] = useBoolean(false);
    const targetid: string = files ? "key" + files[0]?.items[0]?.fileID : ""

    const [acknowledgment, setAcknowledgment] = React.useState(false);
    const onChangeAcknowledgment = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setAcknowledgment(!!checked);
        }, []);

    const [consent, setConsent] = React.useState(false);
    const onChangeConsent = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setConsent(!!checked);
        }, []);

    return (
        <>
            {coachmarkVisible && privacyAccepted === false && (
                <Coachmark
                    target={targetid}
                    onDismiss={hideCoachmark}
                    title="Accettare la privacy"
                    content={<>per poter accettare la privacy, <br />si prega di scaricare il file corrispondente, <br /> premendo <Icon iconName="Download" /></>}
                    isWide={false} />
            )}
            {isAuthorized &&
                <Modal titleAriaId={"maps"} isOpen={isModalOpen} isBlocking={true} containerClassName={contentStyles.container} ignoreExternalFocusing
                    onDismiss={(ev) => {
                        if (privacyAccepted === false)
                            ev?.preventDefault()
                        else {
                            hideModal()
                        }
                    }}>
                    <div className={contentStyles.header}>
                        <span id={"InformativaPrivacy"}>{"Informativa Privacy"}</span>
                    </div>
                    <div className={contentStyles.body}>
                        <Grid>
                            {files?.map((file: any) => {
                                return (
                                    <>
                                        <Section title={file?.header}>
                                            {
                                                file?.items?.map((f: any) => {
                                                    return (
                                                        <>
                                                            {f?.note &&
                                                                <Row>
                                                                    <Label styles={noDataFoundLabel}>{f?.note}</Label>
                                                                </Row>}
                                                            <Row>
                                                                <Text
                                                                    onChange={() => { }}
                                                                    className="ms-sm11"
                                                                    label=""
                                                                    name=""
                                                                    type="plainWithIcon"
                                                                    value={f?.fileDescription}
                                                                    readOnly
                                                                    onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile(f)} style={{ cursor: "pointer", background: "transparent" }} id={"key" + f?.fileID} /></> }} />
                                                            </Row>
                                                            <Row>
                                                                <Option
                                                                    className="ms-sm3 ms-smPush7"
                                                                    label="esprimi preferenza"
                                                                    options={[{ key: '1', text: 'Accetta\u00A0\u00A0' }, { key: '2', text: 'Rifiuta' }]}
                                                                    type="horizontal"
                                                                    required
                                                                    disabled={filesToDownload?.includes(f?.fileID)}
                                                                    onChange={(_name, value, _req) => {
                                                                        if (value === '1') {
                                                                            setPrivacyToAccept(privacyToAccept?.filter((fid: number) => fid !== f?.fileID))
                                                                        }
                                                                        else {
                                                                            if (!privacyToAccept?.includes(f?.fileID)) {
                                                                                setPrivacyToAccept([...privacyToAccept, f?.fileID])
                                                                            }
                                                                        }
                                                                    }} />
                                                            </Row>
                                                            <div style={{ paddingBottom: 8 }}></div>
                                                        </>);
                                                })}
                                        </Section>
                                    </>)
                            })}
                            <Section title=" ">
                                <Row>
                                    <Checkbox className="ms-Grid-col ms-sm12" label="presa visione" checked={acknowledgment} onChange={onChangeAcknowledgment} />
                                </Row>
                                <div style={{ paddingBottom: 10 }} />
                                <Row>
                                    <Checkbox className="ms-Grid-col ms-sm12" label="esprimo consenso" checked={consent} onChange={onChangeConsent} />
                                </Row>
                            </Section>
                        </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={acceptPrivacy} text="accetta privacy" disabled={Object.keys(filesToDownload ?? {}).length !== 0 || Object.keys(privacyToAccept ?? {}).length !== 0 || !acknowledgment || !consent} />
                        </DialogFooter>
                    </div>
                </Modal>}
        </>
    );
}
