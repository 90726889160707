import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} paginate >
                <DataColumn kid='column1' name='denominazione' fieldName='denomination' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column2' name='telefono' fieldName='phone' minWidth={100} maxWidth={100} align='left' />
                <DataColumn kid='column2' name='cellulare' fieldName='mobilePhone' minWidth={100} maxWidth={100} align='left' />
                <DataColumn kid='column4' name='email' fieldName='email' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column7' name='provincia' fieldName='province' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column6' name='comune' fieldName='municipality' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column5' name='indirizzo' fieldName='address' minWidth={180} maxWidth={200} align='left' />
                <DataColumn kid='column5' name='CAP' fieldName='postalCode' minWidth={80} maxWidth={100} align='left' />
                <DataColumn kid='column5' name='Tipo Struttura' fieldName='structureType' minWidth={150} align='left' />
                <DataColumn kid='column5' name='OPX' fieldName='opx' minWidth={80} maxWidth={100} align='center' type="icon" />
                <DataColumn kid='column5' name='Sito Web' fieldName='webSite' minWidth={180} maxWidth={200} align='left' />
            </DataList>
        </>
    );
}
