import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
}

export default (props: Props) => {
    const roles = useSecurityRoles()
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} readOnly={props.readOnly}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice Piano' fieldName='code' minWidth={100} maxWidth={120} align='right' />
                <DataColumn kid='column2' name='Descrizione Piano' fieldName='description' minWidth={300} maxWidth={500} align='left' />
                <DataColumn kid='column3' name='Matricola' fieldName='registerNumber' minWidth={300} maxWidth={500} align='left' />
                <DataColumn kid='column4' name='Azienda' fieldName='corporationID' minWidth={200} align='left' />
                <DataColumn kid='column5' name='Data Inizio Validità' fieldName='startDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column6' name='Data Fine Validità' fieldName='endDate' minWidth={150} maxWidth={200} align='center' />
                {!roles.isInsuredUser && <DataColumn kid='column7' type="icon" name='Privacy Accettata' fieldName='leap_PlanAcceptedPrivacyID' minWidth={120} maxWidth={150} align='center' />}
                {!roles.isInsuredUser && <DataColumn kid='column8' type="icon" name='Privacy Ricevuta' fieldName='leap_PlanReceivedPrivacyID' minWidth={120} maxWidth={150} align='center' />}
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
