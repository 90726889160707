import { Loader } from "@googlemaps/js-api-loader";
import * as React from 'react';
import { FontIcon } from "@fluentui/react";
import ReactDOMServer from 'react-dom/server';

type Props = {
    controller: string,
    required?: boolean
    mandatory?: (name: string) => boolean
    value?: string | number | any,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    disabled?: boolean,
    hidden?: boolean,
    longitude?: any,
    latitude?: any,
    markers?: any,
    readonly?: boolean
}

export default (props: Props) => {
    const rome = { lat: 41.87194, lng: 12.56738 };

    const loader = new Loader({
        apiKey: "AIzaSyDYejXRlDJHgXjAR1fRVizz1qgBp2FsP4g",
        version: "weekly",
        language: "it",
        region: "IT"
    });

    let map: any;
    let marker: google.maps.Marker
    let markerPlaced: boolean = false;
    let mapInitialized: boolean = false;

    const latitudeAndLongitudeAsProps: boolean = props.latitude?.value && props.longitude?.value

    React.useEffect(() => {
        loader.load().then(() => {
            if (!mapInitialized) {
                map = new google.maps.Map(document.getElementById("map") as HTMLDivElement, {
                    center: latitudeAndLongitudeAsProps ? { lat: props.latitude.value, lng: props.longitude.value } : rome,
                    zoom: latitudeAndLongitudeAsProps ? 13 : 6,                    
                });                
                mapInitialized = true;
            }
            if (Object.keys(props.markers ?? {}).length > 0) {
                //arrivano i markers, 
                //li creo,
                //li aggiungo alla mappa,
                //sposto la mappa sul primo puntatore

                let customMarker: any = [];
                let infowindow: any = [];
                let bounds = new google.maps.LatLngBounds();

                const phoneIcon = ReactDOMServer.renderToString(<FontIcon iconName="Phone" style={{ overflow: "visible" }} />);
                const mailIcon = ReactDOMServer.renderToString(<FontIcon iconName="Mail" style={{ overflow: "visible" }} />);
                const yes = ReactDOMServer.renderToString(<FontIcon iconName="Accept" style={{ overflow: "visible" }} />);
                const no = ReactDOMServer.renderToString(<FontIcon iconName="Cancel" style={{ overflow: "visible" }} />);

                props.markers.map((item: any, index: any) => {
                    let mailto = "mailto:" + item?.email;
                    infowindow[index] = new google.maps.InfoWindow({
                        content:
                            "<style>table {border- collapse: collapse;}td, th {border: 1px solid #999;padding: 0.1rem;text- align: left;}</style>" +
                            "<b>" + item?.buisnessName + "</b>" +
                            "<br/><i>" + item?.structureType + "</i>" +
                            "<br/>" + item?.infoWindowSubHeading + "<br/><br/>" +
                            mailIcon + "&nbsp;&nbsp;&nbsp;" + "<a href=" + mailto + ">" + item?.email + "</a><br/>" +
                            phoneIcon + "&nbsp;&nbsp;&nbsp;" + item?.phone + "<br/>" +
                            (item?.opx === "Si" ? yes : no) + "&nbsp;&nbsp;&nbsp;Ortopantomografo<br/><br/>" +
                            item?.vacations +
                            "Orari:" +
                            "<table>" +
                            "<thead>" +
                            "<tr>" +
                            "<th>giorno</th>" +
                            "<th>mattino</th>" +
                            "<th>pomeriggio</th>" +
                            "</tr> " +
                            "</thead>" +
                            "<tbody>" +
                            item?.tableRow +
                            "</tbody>" +
                            "</table>"
                    });

                    customMarker[index] = new google.maps.Marker({
                        position: { lat: item?.latitude, lng: item?.longitude },
                        title: " ",
                        clickable: true,
                        icon: "/assets/media/images/maps.marker.48.png"
                    });
                    customMarker[index]?.addListener("click", () => {
                        infowindow.map((i: any) => i?.close());
                        infowindow[index]?.open(map, customMarker[index]);
                    });

                    if (item.latitude && item.longitude) {
                        bounds.extend({ lat: item?.latitude, lng: item?.longitude });
                    }

                    customMarker[index]?.setMap(map)
                    return
                })

                map.fitBounds(bounds);
                //map.setCenter({lat: props.markers[0]?.latitude, lng: props.markers[0]?.longitude });

                google.maps.event.addListener(map, 'click', (mapsMouseEvent) => {
                    infowindow.map((i: any) => i?.close());
                });

            }
            else if (latitudeAndLongitudeAsProps) {
                //mi arrivano correttamente le coordinate:
                //piazzo il marker e creo il listener per l'onchange

                marker = new google.maps.Marker({
                    position: { lat: props.latitude?.value, lng: props.longitude?.value },
                    map,
                    clickable: true,
                    draggable: !props.readonly,
                    icon: "/assets/media/images/maps.marker.48.png"
                });

                google.maps.event.addListener(marker, 'dragend', function (ev: any) {
                    props.latitude?.onChange(props.latitude.name, marker.getPosition()?.lat(), false)
                    props.longitude?.onChange(props.longitude.name, marker.getPosition()?.lng(), false)
                    map.setCenter({ lat: marker.getPosition()?.lat(), lng: marker.getPosition()?.lng() });
                });

                markerPlaced = true;
            } else {
                //non mi arrivano coordinate,
                //creo un listener sul click della mappa che crea un marker sulle coordinate del click

                google.maps.event.addListener(map, 'click', (mapsMouseEvent) => {
                    //il marker non è ancora stato piazzato
                    if (!markerPlaced) {

                        marker = new google.maps.Marker({
                            position: mapsMouseEvent.latLng,
                            map,
                            title: " ",
                            clickable: true,
                            draggable: true,
                            label: " ",
                            animation: 2,
                            icon: "/assets/media/images/maps.marker.48.png"
                        });

                        markerPlaced = true;

                        //faccio subito la onchange per segnare dove è stato cliccato
                        props.latitude?.onChange(props.latitude.name, mapsMouseEvent.latLng.lat(), false)
                        props.longitude?.onChange(props.longitude.name, mapsMouseEvent.latLng.lng(), false)


                        google.maps.event.addListener(marker, 'dragend', function (ev: any) {
                            props.latitude?.onChange(props.latitude.name, marker.getPosition()?.lat(), false)
                            props.longitude?.onChange(props.longitude.name, marker.getPosition()?.lng(), false)
                        });
                    } else {
                        //il marker è stato piazzato, posso togliere il listener
                        google.maps.event.clearListeners(map, 'click');
                    }
                });
            }
        });
    }, [props.markers])

    return <div id="map" style={{ height: "68vh" }}></div>
}
