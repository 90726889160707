import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any, criteria: any, onDelete: any }) => {

    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const canDelete = props.criteria.indirectManagementStateID === "8";
    return (
        <>
            <DataList modalController={props.controller} items={props.items} deleteText={{ buttonText: "Procedi", messageText: "Procedo ad annullare la trasmissione del rimborso?" }} onDelete={ props.onDelete} >
                {canDelete && <DataColumn kid='columnD' name='' fieldName='' type='delete' minWidth={10} maxWidth={10} />}
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={250} align='left' />
                <DataColumn kid='column3' name='Codice Assistito' fieldName='insuredCode' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column4' name='Assistito' fieldName='insured' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column5' name='Codice Fiscale' fieldName='fiscalCode' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column6' name='Piano Sanitario' fieldName='plan' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column7' name='Stato' fieldName='state' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column8' name='Inserimento' fieldName='createDate' minWidth={160} maxWidth={160} align='center' />
                <DataColumn kid='column9' name='Ultimo Aggiornamento' fieldName='updateDate' minWidth={160} maxWidth={160} align='center' />
                <DataColumn kid='column10' name='Data Pagamento' fieldName='arrangeDate' minWidth={160} maxWidth={160} align='center' />
            </DataList>
        </>
    );
}
