import { Dialog, DialogFooter, DialogType, FontSizes, FontWeights, IDialogProps, mergeStyleSets, PrimaryButton, Icon, IButtonProps, DefaultButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Separator from '../components/controls/grid/Separator';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import AccountingSituationHealthServiceList from '../features/accountingSituation/AccountingSituationHealthServiceList';
import AccountingSituationResultList from '../features/accountingSituation/AccountingSituationResultList';
import { filterForm } from '../features/operation/thunks/filterThunk';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { lock, isLocked } from '../features/operation/lockSlice';
import { setResponse } from '../features/response/responseSlice';
import { setOperation } from '../features/operation/operationSlice';
import { itemData } from '../features/operation/thunks/itemThunk';
import DatePicker from '../components/controls/DatePicker';
import ComboBox from '../components/controls/ComboBox';

export const gotoFineCure = createAsyncThunk(
    'item/gotoFineCure',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'FineCure', args.token)
        return response
    }
)

export default () => {
    const controller = "AccountingSituation"
    const page = useLeapState(controller)
    const credential = useSelector(security)?.credential
    const dispatch: AppDispatch = useDispatch()

    const quoteManagementHealthServiceController = "QuoteManagementHealthService"

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHCPower: boolean = isONHCMaster || isONHCAccount
    const isONHC: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHFAccount: boolean = roles.isHFAccount;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator || isHFAccount

    const fForm = useSelector(filterForm)
    const form = fForm.find(i => i.key === controller)?.value

    const isSearchByASCode = !(form?.accountStatementCodeID === undefined || form?.accountStatementCodeID?.length === 0)

    const _isLocked = useSelector(isLocked)

    const iForm = useSelector(itemData)
    const args = { quoteManagementID: iForm.find(i => i.key === controller)?.value?.data?.id, token: credential?.token }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const quoteManagementState: string = page.item?.state?.data?.leap_QuoteManagementQuoteStateID;
    const hasHealthServicesToApprove = !!page.item?.state?.data?.hasHealthServicesToApprove;
    const quoteManagementFineCure_check: boolean = eval_fineCure(quoteManagementState);
    function eval_fineCure(quoteManagementState: string) {
        if (!hasHealthServicesToApprove && quoteManagementState === "4")
            return true;

        return false;
    }

    const onClickFineCure = (e: any) => {
        e.preventDefault()
        toggleHideDialogFineCure()
    }
    const [hideDialogFineCure, { toggle: toggleHideDialogFineCure }] = useBoolean(true);
    const dialogContentPropsFineCure = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Fine Cure" del preventivo?',
    };

    const onExecuteFineCure = (e: any) => {
        toggleHideDialogFineCure()
        dispatch(lock())
        dispatch(gotoFineCure(args)).then(dispatchMessage).then(dispatchReload)
    }

    const downloadCSVAccountStatement = () => {
        const args = {
            controller: controller,
            fileName: "export.csv",
            token: credential.token,
        }
        if (!isSearchByASCode) {
            showMessageBox()
        }
        else {
            dispatch(CSVDownloadAccountStatement(args))
        }
    }

    const CSVDownloadAccountStatement = createAsyncThunk(
        'item/downloadAccountStatement',
        async (args: { controller: string }) => {
            const response = await client.downloadPost(args.controller, { form: { ...form, "fileName": "export.csv" }, page: page }, 'downloadSingleAccountStatement', undefined, credential.token)
            return response
        }
    )

    const downloadFile = (methodName: string, fileDescription: string) => {
        client.downloadPost(controller, { form: { ...form, fileName: `${fileDescription}.csv` }, page: page }, methodName, undefined, credential.token)
    }

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    //assistito
    const insuredID = useLeapData("insuredID", "assistito", page, "insureds")
    const leap_PlanManagementTypeID = useLeapData("leap_PlanManagementID", "tipo gestione", page, "planManagementTypeOption")
    const leap_WelfarePlanInvoiceAmountID = useLeapData("leap_WelfarePlanInvoiceAmountID", "importo fatturazione welfare", page, "welfarePlanInvoiceAmountOption")

    const authorizationStartDate = useLeapData("authorizationStartDate", "data autorizzazione da", page)
    const authorizationEndDate = useLeapData("authorizationEndDate", "data autorizzazione a", page)

    const dataExtractionStartDate = useLeapData("dataExtractionStartDate", "data generazione estratto conto da", page)
    const dataExtractionEndDate = useLeapData("dataExtractionEndDate", "data generazione estratto conto a", page)

    //pagamento
    const paymentDateStartDate = useLeapData("paymentDateStartDate", "data pagamento da", page)
    const paymentDateEndDate = useLeapData("paymentDateEndDate", "data pagamento a", page)
    const leap_AccountStatementPaymentID = useLeapData("leap_AccountStatementPaymentID", "pagamento", page, "paymentOption")
    const leap_AccountStatementPaymentStateID = useLeapData("leap_AccountStatementPaymentStateID", "stato pagamento", page, "paymentStateOption")
    const invoiceNumber = useLeapData("invoiceNumber", "numero fattura", page)
    const invoiceDateStartDate = useLeapData("invoiceDateStartDate", "data fattura da", page)
    const invoiceDateEndDate = useLeapData("invoiceDateEndDate", "data fattura a", page)
    const leap_FundAutorizzationStateID = useLeapData("leap_FundAutorizzationStateID", "visualizza solo autorizzati fondo da mettere in fine cure per rimborso", page, "leap_FundAutorizzationStateOption")

    //opzioni aggiuntive
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")
    const piva = useLeapData("piva", "P. IVA", page)
    const quoteManagementID = useLeapData("quoteManagementID", "preventivo", page, "quoteManagements")
    const leap_AccountingSituationQuoteStateID = useLeapData("leap_AccountingSituationQuoteStateID", "stato preventivo", page, "quoteStateOption")
    const accountStatementCodeID = useLeapData("accountStatementCodeID", "numero estratto conto", page, "accountStatementCodes")
    const welfareCompanyID = useLeapData("welfareCompanyID", "compagnia welfare", page, "welfareCompanies")
    const leap_InsuredInvoiceVerificationID = useLeapData("leap_InsuredInvoiceVerificationID", "stato di verifica della fattura assistito", page, "documentationVerificationOption")
    const refundFeeType = useLeapData("refundFeeTypeID", "tipo fatturazione", page, "refundFeeType")

    //da preventivo
    const quoteManagementNumber = useLeapData("quoteManagementNumber", "numero piano di cure", page)
    const insuredFiscalcode = useLeapData("insuredFiscalcode", "cf assistito", page)
    const lastnamePersonal = useLeapData("lastnamePersonal", "cognome anagrafico", page)
    const firstnamePersonal = useLeapData("firstnamePersonal", "nome anagrafico", page)
    const authorization = useLeapData("authorization", "data di autorizzazione", page)
    const startTreatment = useLeapData("startTreatment", "data inizio cura", page)
    const endTreatment = useLeapData("endTreatment", "data fine cura", page)
    const quoteManagementHealthServices = useLeapData("quoteManagementHealthServices", "", page)

    const numberInvoice = useLeapData("numberInvoice", "numero fattura", page)
    const dateInvoice = useLeapData("dateInvoice", "data fattura", page)
    const paymentDate = useLeapData("paymentDate", "data pagamento", page)

    const leap_QuoteManagementQuoteStateDescr = useLeapData("leap_QuoteManagementQuoteStateDescr", "stato piano di cure", page)

    const contentStyles = mergeStyleSets({
        body: {
            marginRight: '10px',
            marginTop: '20px',
        },
    });

    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "Selezionare il numero di estratto conto che si desidera estrarre",
            title: "Impossibile procedere",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-error"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    const amount: boolean = (page?.item?.state?.lookups?.amount != undefined ? (page?.item?.state?.lookups?.amount[0]) : 0);

    const accountStatementPaymentID: any = isONHC ? "1" : "2";
    const accountStatementPaymentStateID: any = isONHC ? "1" : "3";

    return <>
        <Dialog {...dialogProps} >
            <DialogFooter >
                <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <Content title="situazione contabile" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Section title="">
                            <Row>
                                <SearchBox {...quoteManagementID} className="ms-sm5" />
                                <Option {...leap_AccountingSituationQuoteStateID} className="ms-sm3" type="horizontal" />
                            </Row>
                            {(isONHC || isHFMaster) && <>
                                <Row>
                                    <SearchBox {...healthFacilityID} className="ms-sm5" />
                                    {isONHC && <Text {...piva} className="ms-sm2" type="plain" />}
                                </Row>
                            </>}
                            {isONHC &&
                                <Row>
                                    <SearchBox {...welfareCompanyID} className="ms-sm5" />
                                </Row>}
                            <Row>
                                <SearchBox {...accountStatementCodeID} className="ms-sm4" />
                                <PrimaryButton text="scarica estratto conto" onClick={downloadCSVAccountStatement} className={contentStyles.body} />
                            </Row>
                        </Section>
                        <Section title="pagamento">
                            <Row>
                                <DateRange dateMin={{ ...paymentDateStartDate, className: "ms-sm2" }} dateMax={{ ...paymentDateEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                                <Option {...leap_AccountStatementPaymentID} selectedKey={accountStatementPaymentID} className="ms-sm2" type="horizontal" />
                                <Separator className="ms-sm2 ms-xxl1" />
                                <Option {...leap_AccountStatementPaymentStateID} selectedKey={accountStatementPaymentStateID} className="ms-sm3" type="horizontal" />
                            </Row>
                            <Row>
                                <DateRange dateMin={{ ...invoiceDateStartDate, className: "ms-sm2" }} dateMax={{ ...invoiceDateEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                                <Text {...invoiceNumber} className="ms-sm2" type="plain" />
                                <Separator className="ms-sm2 ms-xxl1" />
                                <Option {...refundFeeType} className="ms-sm3" type="horizontal" />
                            </Row>
                        </Section>
                        <Section title="opzioni aggiuntive">
                            <Row>
                                <DateRange dateMin={{ ...authorizationStartDate, className: "ms-sm2" }} dateMax={{ ...authorizationEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                                <Option {...leap_PlanManagementTypeID} className="ms-sm3" type="horizontal" />
                                {isONHC && <>
                                    <Option {...leap_WelfarePlanInvoiceAmountID} className="ms-sm3" type="horizontal" />
                                </>}
                                {!isONHC && <>
                                    <Option {...leap_FundAutorizzationStateID} className="ms-sm5" type="horizontal" />
                                </>}
                            </Row>
                            {isONHC && <>
                                <Row>
                                    <ComboBox {...leap_InsuredInvoiceVerificationID} className="ms-sm4" />
                                    <Option {...leap_FundAutorizzationStateID} className="ms-sm8" type="horizontal" />
                                </Row>
                            </>}
                            <Row>
                                <SearchBox {...insuredID} className="ms-sm5 ms-xxl4" />
                            </Row>
                        </Section>
                    </Section>
                    {isONHC && <Section title="estrazione dati">
                        <Row>
                            <DateRange dateMin={{ ...dataExtractionStartDate, className: "ms-sm4" }} dateMax={{ ...dataExtractionEndDate, className: "ms-sm4" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                            <Text
                                onChange={() => { }}
                                className="ms-sm3"
                                label="estratti conto generati dalle SS"
                                name=""
                                type="plainWithIcon"
                                value="scarica estratti conto SS"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile("DownloadSSAccountingSituationCSV", "estratti conto generati dalle SS")} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                        </Row>
                    </Section>}
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller} state={page}>
                        <AccountingSituationResultList controller={controller} items={page.result?.state?.data} searchedECCode={form?.accountStatementCodeID} state={page} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section title="dati generali">
                        <Row>
                            <Text {...quoteManagementNumber} className="ms-sm3 ms-xxl2" readOnly />
                            <Text {...leap_QuoteManagementQuoteStateDescr} className="ms-sm2" readOnly />
                            {(isONHCPower || isHF) && quoteManagementFineCure_check && (<>
                                <PrimaryButton {...ButtonProps} text='passa a "Fine Cure"' onClick={onClickFineCure} disabled={_isLocked} className={contentStyles.body} />
                                <Dialog hidden={hideDialogFineCure} onDismiss={toggleHideDialogFineCure} dialogContentProps={dialogContentPropsFineCure} modalProps={modalProps} >
                                    <DialogFooter>
                                        <PrimaryButton onClick={onExecuteFineCure} text='passa a "Fine Cure"' disabled={_isLocked} />
                                        <DefaultButton onClick={toggleHideDialogFineCure} text="Annulla" disabled={_isLocked} />
                                    </DialogFooter>
                                </Dialog>
                            </>)}
                        </Row>
                    </Section>
                    <Section title="dati assistito">
                        <Row>
                            <Text {...insuredFiscalcode} className="ms-sm2" readOnly />
                            <Text {...lastnamePersonal} className="ms-sm3" readOnly />
                            <Text {...firstnamePersonal} className="ms-sm3" readOnly />
                        </Row>
                    </Section>
                    <Section title="periodo di cura">
                        <Row>
                            <Text {...authorization} className="ms-sm2" type="calendar" readOnly />
                            <DateRange dateMin={{ ...startTreatment, className: "ms-sm2", readOnly: true }} dateMax={{ ...endTreatment, className: "ms-sm2", readOnly: true }} />

                            <div style={{ position: "relative", float: "right", minWidth: "1050px", marginTop: "0px", marginRight: "-850px" }}>
                                <Text
                                    onChange={() => { }}
                                    className="ms-sm2"
                                    label="Totale"
                                    name="amount"
                                    type="plainWithIcon"
                                    value={amount}
                                    readOnly
                                    onRenderSuffix={() => { return <><Icon iconName="AllCurrency" onClick={() => { }} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                            </div>
                        </Row>
                    </Section>
                    <Section title="pagamento">
                        <Row>
                            {page.item.state?.data?.leap_PlanRefundFeeID === "1" && <Text {...numberInvoice} className="ms-sm2" readOnly />}
                            {page.item.state?.data?.leap_PlanRefundFeeID === "1" && <Text {...dateInvoice} className="ms-sm2" type="calendar" dateRange={{ maxDate: Date().toLocaleString() }} readOnly />}
                            <Text {...paymentDate} className="ms-sm2" type="calendar" readOnly />
                        </Row>
                    </Section>
                    <Section title="elenco prestazioni" toolbar={undefined} controller={quoteManagementHealthServiceController} state={page.result?.state?.data} >
                        <Row>
                            <AccountingSituationHealthServiceList {...quoteManagementHealthServices} readonly={true} listType='new' items={page.item.state?.lists.healthServices} controller={quoteManagementHealthServiceController} state={page} />
                        </Row>
                    </Section>

                </Grid>
            </Item>
        </Content>
    </>
};
