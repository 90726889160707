import * as React from 'react';
import { useSelector } from 'react-redux';
import { authorized } from '../../../features/security/thunks/securityThunk';

import { Stack, IStackStyles, IStackItemStyles, StackItem } from '@fluentui/react/lib/Stack';

import Hamburger from '../../controls/Hamburger';
import Logo from '../../controls/Logo';
import AppTitle from '../../controls/AppTitle';
import HMenu from '../../controls/HMenu';
import Account from '../../controls/Account';

import Theme from '../../themes/Theme';

const topStackStyles: IStackStyles = {
    root: {
        margin: '0 auto',
    },
};
const bottomStackStyles: IStackStyles = {
    root: {
        backgroundImage: Theme.leap.header.menu?.backgroundImage,
        margin: '0 auto',
    },
};

const TopStackItemStyle: IStackItemStyles = {
    root: {
        marginBottom: 0,
        marginTop: 16
    }
}

const BottomStackItemStyle: IStackItemStyles = {
    root: {
        backgroundImage: Theme.leap.header.menu?.content?.backgroundImage,
        margin: 0,
    }
}

const HamburgerStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        backgroundColor: Theme.leap.header.logo.backgroundColor,
        display: 'flex',
        overflow: 'hidden',
        padding: '6px 12px 6px',
        width: Theme.leap.header.logo.width,
    },
};

const LogoStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        backgroundColor: Theme.leap.header.logo.backgroundColor,
        display: 'flex',
        overflow: 'hidden',
        padding: '8px 12px 12px',
        width: Theme.leap.header.logo.width,
    },
};

const AppTitleStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'flex-end',
        backgroundColor: Theme.leap.header.title.backgroundColor,
        color: Theme.leap.header.title.color,
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
};

const AccountStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        backgroundColor: Theme.leap.header.account.backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        paddingTop: 10,
        minWidth: Theme.leap.header.account.minWidth,
    },
};

export default () => {
    const isAuthorized = useSelector(authorized)

    return <>
        <Stack className={Theme.leap.header.className} styles={topStackStyles}>
            <Stack.Item styles={TopStackItemStyle}>
                <Stack horizontal>
                    <Stack.Item className="viewHamburger" styles={HamburgerStackItemStyles}>
                        <Hamburger />
                    </Stack.Item>
                    <Stack.Item styles={LogoStackItemStyles}>
                        <Logo />
                    </Stack.Item>
                    <Stack.Item grow styles={AppTitleStackItemStyles}>
                        <AppTitle />
                    </Stack.Item>
                    {isAuthorized && <Stack.Item styles={AccountStackItemStyles}>
                        <Account />
                    </Stack.Item>}
                </Stack>
            </Stack.Item>
        </Stack>
        {isAuthorized && <Stack className={Theme.leap.header.className + " viewMenu"} styles={bottomStackStyles}>
            <Stack.Item styles={BottomStackItemStyle}>
                <HMenu />
            </Stack.Item>
        </Stack>}
    </>
}