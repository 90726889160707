import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Data Invio' fieldName='date' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Stato' fieldName='state' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column1' name='Denominazione' fieldName='denomination' minWidth={250} align='left' />
                <DataColumn kid='column1' name='Ragione Sociale' fieldName='businessName' minWidth={250} align='left' />
                <DataColumn kid='column1' name='Email' fieldName='email' minWidth={250} maxWidth={250} align='left' />
            </DataList>
        </>
    );
}
