import * as React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { SelectionMode, ActionButton, Icon, Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, cssColor, Tooltip, TooltipHost, ITooltipHostStyles } from '@fluentui/react';
import { setOperation } from '../operation/operationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { security } from '../security/thunks/securityThunk';
import { itemData, itemForm } from '../operation/thunks/itemThunk';
import { setResponse } from '../response/responseSlice';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { lock, isLocked } from '../operation/lockSlice';

export const accept = createAsyncThunk(
    'item/accept',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'Accept', args.token)
        return response
    }
)
export const reject = createAsyncThunk(
    'item/reject',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'Reject', args.token)
        return response
    }
)
export const voucher = createAsyncThunk(
    'item/voucher',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'Voucher', args.token)
        return response
    }
)
export const noVoucher = createAsyncThunk(
    'item/noVoucher',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'NoVoucher', args.token)
        return response
    }
)
export const endOfCure = createAsyncThunk(
    'item/endOfCure',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'EndOfCure', args.token)
        return response
    }
)
export const withdraw = createAsyncThunk(
    'item/withdraw',
    async (args: { form: any, page: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form, page: args.page }, 'Withdraw', args.token)
        return response
    }
)

type Props = {
    items: any,
    name: string
    onChange?: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
    listType: 'new' | 'healthServices' | 'novoucher' | 'deleted'
    readonly: boolean
}

const ActionButtonProps = {
    style: {
        fontFamily: 'Montserrat',
        margin: "0 4px"
    }
}

type dialogPropsType = {
    text: string,
    message: string,
    onExecute: any
}

export default (props: Props) => {
    const iData = useSelector(itemData);
    const iForm = useSelector(itemForm)
    const dItem = iData.find(d => d.key === 'QuoteManagement')?.value

    const form = { ...iForm.find(i => i.key === 'QuoteManagement')?.value, quoteManagementHealthServices: props.state.item?.values?.[props.name]?.value }
    const page = dItem?.metadata?.page

    const hasHealthServicesToApprove = !!props.state.item?.state?.data?.hasHealthServicesToApprove

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONCH: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHFAccount: boolean = roles.isHFAccount;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator || isHFAccount

    const isInsuredUser: boolean = roles.isInsuredUser;

    const _isLocked = useSelector(isLocked)

    if (isInsuredUser && props.listType === 'deleted') {
        return <></>
    }

    const [dialogProps, setDialogProps] = React.useState({} as dialogPropsType)
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: dialogProps?.message,
    };

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const args = {
        form: form, page: page, token: credential?.token
    }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", "QuoteManagement"))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const acceptDialogProps: dialogPropsType = {
        text: 'accetta modifiche',
        message: 'Accettare le modifiche delle prestazioni selezionate?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(accept(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }

    const rejectDialogProps: dialogPropsType = {
        text: 'rifiuta modifiche',
        message: 'Rifiutare le modifiche delle prestazioni selezionate?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(reject(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }
    const voucherDialogProps: dialogPropsType = {
        text: 'copertura voucher',
        message: 'Dichiarare le prestazioni selezionate coperte da voucher?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(voucher(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }
    const noVoucherDialogProps: dialogPropsType = {
        text: 'no copertura voucher',
        message: 'Dichiarare le prestazioni selezionate non coperte da voucher?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(noVoucher(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }
    const endOfCureDialogProps: dialogPropsType = {
        text: 'fine cura',
        message: 'Dichiarare le prestazioni selezionate a fine cura?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(endOfCure(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }
    const withdrawDialogProps: dialogPropsType = {
        text: 'revoca fine cura',
        message: 'Revocare alle prestazioni selezionate la dichiarazione di fine cura?',
        onExecute: (e: any) => {
            toggleHideDialog()
            dispatch(lock())
            dispatch(withdraw(args))
                .then(dispatchReload)
                .then(dispatchMessage)
        }
    }
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    const onExecute = (e: any, dialogProps: dialogPropsType) => {
        setDialogProps(dialogProps)

        e.preventDefault()
        toggleHideDialog()
    }

    const leap_PlanManagementID = props.state.item?.state?.data?.leap_PlanManagementID
    const leap_QuoteManagementQuoteStateID = props.state.item?.state?.data?.leap_QuoteManagementQuoteStateID

    const isRefoundVisibleToHF = isHF && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7")

    const isMultiSelection = props.items?.length > 0 && !isInsuredUser &&
        (
            isONCH && (
                (leap_PlanManagementID === "1" && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7")) ||
                (leap_PlanManagementID === "2" && (leap_QuoteManagementQuoteStateID === "2" || leap_QuoteManagementQuoteStateID === "3")) ||
                (hasHealthServicesToApprove && leap_PlanManagementID === "2" && (props.listType === "novoucher" || props.listType === "deleted") && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7"))
            )
        )

    const selectionMode = isMultiSelection ? SelectionMode.multiple : SelectionMode.none

    const canViewQAS = props.items?.length > 0 && !isInsuredUser && leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7"

    const canSelectItem = (data: any, index: any): boolean => {
        return true;
    }

    const canEdit: boolean = (
        (isONCH && (
            leap_QuoteManagementQuoteStateID === "1" ||
            leap_QuoteManagementQuoteStateID === "2" ||
            leap_QuoteManagementQuoteStateID === "3" ||
            leap_QuoteManagementQuoteStateID === "4" ||
            (props.listType === 'healthServices' && leap_PlanManagementID === "2" && leap_QuoteManagementQuoteStateID === "7")
        )) ||
        (isHF && (
            (leap_QuoteManagementQuoteStateID === "1") ||
            (leap_QuoteManagementQuoteStateID === "4")
        ))
    )
    const canDelete: boolean = (
        (isONCH && (
            leap_QuoteManagementQuoteStateID === "1" ||
            leap_QuoteManagementQuoteStateID === "2" ||
            leap_QuoteManagementQuoteStateID === "3" ||
            leap_QuoteManagementQuoteStateID === "4" ||
            leap_QuoteManagementQuoteStateID === "7"
        )) ||
        (isHF && (
            (leap_QuoteManagementQuoteStateID === "1") ||
            (leap_QuoteManagementQuoteStateID === "4")
        ))
    )

    const DataListProps = {
        items: props.items,
        modaltitle: props.modaltitle,
        modalController: props.controller,
        modal: props.modal,
        state: props.state,
        selectionName: props.name,
        selectionMode: selectionMode,
        onSelectionChange: props.onChange,
        canSelectItem: canSelectItem
    }

    const onDeleting = (e: any) => {
        if (leap_PlanManagementID === "2" && e.nativeEvent?.useVoucher === true && isHF && leap_QuoteManagementQuoteStateID !== "1") {
            e.cancel = true
            e.message = "Impossibile eliminare la prestazione selezionata."
        }
    }

    const onRenderIconOperationState = (e: any) => {
        if (e.leap_QuoteManagementHealthServiceStateID === '1')
            return <Icon iconName={"Page"} style={{ color: 'cornflowerblue' }} />;
        else if (e.leap_QuoteManagementHealthServiceStateID === '2')
            return <Icon iconName={"TextDocument"} style={{ color: 'cornflowerblue' }} />
        else if (e.leap_QuoteManagementHealthServiceStateID === '3')
            return <Icon iconName={"RemoveFilter"} style={{ color: 'red' }} />;
        else
            return <Icon iconName={"Teeth"} style={{ color: 'cornflowerblue' }} />;
    }
    const onRenderIconApproveState = (e: any) => {
        if (e.leap_QuoteManagementHealthServiceApprovationStateID === '1')
            return <Icon iconName={"Accept"} style={{ color: 'mediumseagreen' }} />;
        else if (e.leap_QuoteManagementHealthServiceApprovationStateID === '2' && props.listType !== 'deleted')
            return <Icon iconName={"HandsFree"} style={{ color: 'mediumseagreen' }} />;
        else if (e.leap_QuoteManagementHealthServiceApprovationStateID === '2')
            return <Icon iconName={"HandsFree"} style={{ color: 'red' }} />;
        else
            return <Icon iconName={"Help"} style={{ color: 'black' }} />;
    }

    const approvationButton =
        !isHF && (leap_PlanManagementID === "1" || props.listType === "novoucher" || props.listType === "deleted") && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7") &&
        <>
            <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'Accept', style: { color: 'mediumseagreen' } }} text={"accetta"} onClick={(e) => onExecute(e, acceptDialogProps)} disabled={_isLocked} />
            <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'HandsFree', style: { color: 'red' } }} text={"rifiuta"} onClick={(e) => onExecute(e, rejectDialogProps)} disabled={_isLocked} />
        </>

    const voucherButton =
        !isHF && leap_PlanManagementID === "2" && (leap_QuoteManagementQuoteStateID === "2" || leap_QuoteManagementQuoteStateID === "3") &&
        <>
            <>
                {
                    props.listType === 'healthServices' &&
                    <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'DeactivateOrders', style: { color: 'darkorange' } }} text={"senza voucher"} onClick={(e) => onExecute(e, noVoucherDialogProps)} disabled={_isLocked} />
                }
            </>
            <>
                {
                    props.listType === 'novoucher' &&
                    <ActionButton iconProps={{ iconName: 'ActivateOrders', style: { color: 'darkorange' } }} text={"con voucher"} onClick={(e) => onExecute(e, voucherDialogProps)} disabled={_isLocked} />
                }
            </>
        </>

    const cureButton =
        props.listType !== 'deleted' && leap_QuoteManagementQuoteStateID === "7" &&
        <>
            <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'Teeth', style: { color: 'cornflowerblue' } }} text={"fine cura"} onClick={(e) => onExecute(e, endOfCureDialogProps)} disabled={_isLocked} />
            <>
                {
                    !isHF &&
                    <ActionButton {...ActionButtonProps} iconProps={{ iconName: 'Teeth', style: { color: 'red' } }} text={"revoca fine cura"} onClick={(e) => onExecute(e, withdrawDialogProps)} disabled={_isLocked} />
                }
            </>
        </>

    const onRenderIconDocumentatioRequiredState = (e: any) => {
        if (e.dentalState === 1) {
            return (
                <TooltipHost content="Elemento Mancante"
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}>
                <Icon iconName={"CircleFill"} style={{ color: '#999999' }} />
            </TooltipHost>
            );
        } else if (e.dentalState === 2) {
            return (
                <TooltipHost content="Otturazione/Ricostruzione"
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}>
                <Icon iconName={"CircleFill"} style={{ color: '#b9fafa' }} />
            </TooltipHost>
            );
        } else if (e.dentalState === 3) {
            return (
                <TooltipHost content="Devitalizzazione"
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}><Icon iconName={"CircleFill"} style={{ color: '#ffe900' }} />
            </TooltipHost>
            );
        } else if (e.dentalState === 4) {
            return (
                <TooltipHost content="Elemento di protesi fissa"
                id={tooltipId}
                calloutProps={calloutProps}
                styles={hostStyles}> <Icon iconName={"CircleFill"} style={{ color: '#ffc1cc' }} />
            </TooltipHost>
            );
        } else if (e.dentalState === 5) {
            return (
                <TooltipHost content="Impianto"
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={hostStyles}>
                    <Icon iconName={"CircleFill"} style={{ color: '#f4364c' }} />
                </TooltipHost>
            );
        }
        else {
            return (
                <TooltipHost content="Dente Naturale"
                    id={tooltipId}
                    calloutProps={calloutProps}
                    styles={hostStyles}>
                    <Icon iconName={"CircleRing"} style={{ color: '#d0d0d0' }} />

                </TooltipHost>
            );
        }
    }

    return (
        <>
            <DataList {...DataListProps} modalwidth={props.modalwidth} onDeleting={onDeleting}>
                {canViewQAS && <DataColumn kid='columnQAS' type="icon" name='' fieldName='leap_QuoteManagementHealthServiceApprovationStateID' minWidth={10} maxWidth={10} align='center' onRenderIcon={onRenderIconApproveState} />}
                {isMultiSelection && props.listType !== 'deleted' && <DataColumn kid='columnQS' type="icon" name='' fieldName='leap_QuoteManagementHealthServiceStateID' minWidth={10} maxWidth={10} align='center' onRenderIcon={onRenderIconOperationState} />}
                <DataColumn kid='column1' name='Branca' fieldName='branchDescr' minWidth={180} maxWidth={180} align='left' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthServiceDescr' minWidth={180} align='left' />
                <DataColumn kid='column3' type="icon" name='' fieldName='leap_dentalState' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconDocumentatioRequiredState} />
                <DataColumn kid='column4' name='Elemento Dentale' fieldName='dentalElement' minWidth={130} maxWidth={130} align='left' />
                <DataColumn kid='column5' name='Data Cancellazione' fieldName='rejectionDate' minWidth={120} maxWidth={120} align='center' />
                {props.listType !== 'deleted' && <DataColumn kid='column6' name='Quota Assistito' fieldName='amount' minWidth={100} maxWidth={100} align='right' />}
                {props.listType !== 'deleted' && (isONCH || isRefoundVisibleToHF) && <DataColumn kid='column7' name='Rimborso' fieldName='refound' minWidth={100} maxWidth={100} align='right' />}
                {props.listType !== 'deleted' && (isONCH || isInsuredUser) && <DataColumn kid='column8' name={isInsuredUser ? "Plafond" : "Erosione"} fieldName='erosion' minWidth={100} maxWidth={100} align='right' />}
                {props.listType !== 'deleted' && isONCH && <DataColumn kid='column9' name='Autorizzazione' fieldName='authorizeDate' minWidth={120} maxWidth={120} align='center' />}
                {props.listType !== 'deleted' && canDelete && <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />}
                {props.listType !== 'deleted' && canEdit && <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            </DataList>
            {
                isMultiSelection && <>
                    <div className="ms-Grid-col ms-sm12" style={{ margin: "12px 8px 0", textAlign: "center" }}>
                        {hasHealthServicesToApprove && approvationButton}
                        {voucherButton}
                        {false && cureButton}
                    </div>
                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={dialogProps?.onExecute} text={dialogProps?.text} />
                            <DefaultButton onClick={toggleHideDialog} text="Annulla" />
                        </DialogFooter>
                    </Dialog>
                </>
            }
        </>
    );
}
