import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../app/rootReducer'

import { client } from '../../../api/client'

import { initialStateThunks, iPayloadThunks } from '../../../defs/thunks'

export const loginSecurity = createAsyncThunk(
    'security/login',
    async (args: { username: string, password: string | undefined, token: string | undefined, otp: string | undefined }) => {
        const response = await client.post("security", { username: args.username, password: args.password, token: args.token, otp: args.otp }, 'login')
        return response
    }
)

export const logoutSecurity = createAsyncThunk(
    'security/logout',
    async (args: { token: string }) => {
        const response = await client.post("security", { token: args.token }, 'logout')
        return response
    }
)

export const getUnits = createAsyncThunk(
    'security/getUnits',
    async (args: { token: string }) => {
        const response = await client.post("security", { token: args.token }, 'units')
        return response
    }
)

export const getRoles = createAsyncThunk(
    'security/getRoles',
    async (args: { username: string, password: string | undefined, token: string }) => {
        const response = await client.post("security", { username: args.username, password: args.password, token: args.token }, 'roles')
        return response
    }
)

export const setRole = createAsyncThunk(
    'security/setRole',
    async (args: { token: string, role: string }) => {
        const response = await client.post("security", { token: args.token, roleID: args.role }, 'setRole')
        return response
    }
)

export const changePassword = createAsyncThunk(
    'security/changePassword',
    async (args: { vecchiaPassword: string | undefined, nuovaPassword: string | undefined, token: string }) => {
        const response = await client.post("security", { token: args.token, Password: args.vecchiaPassword, newPassword: args.nuovaPassword }, 'ChangePassword')
        return response
    }
)

export const forgotPassword = createAsyncThunk(
    'security/forgotPassword',
    async (args: { username: string, email: string, token: string | undefined }) => {
        const response = await client.post("security", { username: args.username, email: args.email, token: args.token }, 'forgotPassword')
        return response
    }
)
export const forgotUsername = createAsyncThunk(
    'security/forgotUsername',
    async (args: { firstname: string, lastname: string, email: string, fiscalcode: string, token: string | undefined }) => {
        const response = await client.post("security", { firstname: args.firstname, lastname: args.lastname, fiscalcode: args.fiscalcode, email: args.email, token: args.token }, 'forgotUsername')
        return response
    }
)
export const validateEmail = createAsyncThunk(
    'item/validateEmail',
    async (args: { confirmationCode: string }) => {
        const response = await client.post("security", { confirmationCode: args.confirmationCode }, 'validateEmail')
        return response
    }
)

export const getSession = createAsyncThunk(
    'item/getSession',
    async (args: { controller: string, body?: any }) => {
        const response = await client.post(args.controller, args.body, 'Login')
        return response
    }
)

const SecuritySlice = createSlice({
    name: 'security',
    initialState: { ...initialStateThunks },
    reducers: {
        setLoggedIn: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controllerKey = action.payload.controller
                let payload = action.payload.data

                let data = state.data

                let controller = data.find(f => f.key === controllerKey)
                if (controller) {
                    controller.value = { ...controller.value, [payload.name]: payload.value }
                } else {
                    data.push({ key: controllerKey, value: { [payload.name]: payload.value } })
                }

                state.data = data
            },
            prepare(username: string, token: string, roles: any, selectedRole: string, expiredPassword: any) {
                return { payload: { controller: "security", data: { name: "credential", value: { username: username, token: token, roles: roles, selectedRole: selectedRole, expiredPassword: expiredPassword } } } }
            }
        },
        setLoggedOut: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controllerKey = action.payload.controller
                let payload = action.payload.data

                let data = state.data

                let controller = data.find(f => f.key === controllerKey)
                if (controller) {
                    controller.value = { ...controller.value, [payload.name]: payload.value }
                } else {
                    data.push({ key: controllerKey, value: { [payload.name]: payload.value } })
                }

                state.data = data
            },
            prepare() {
                return { payload: { controller: "security", data: { name: "credential", value: undefined }, "roles": undefined } }
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(loginSecurity.pending, (state, action) => {
            state.status = 'loading'
            state.response = { message: '', level: -1 }
        })
            .addCase(loginSecurity.fulfilled, (state, action) => {
                state.status = 'fulfilled'

                let username = action.payload?.data?.username
                let token = action.payload?.data?.token
                let roles = action.payload?.lookups?.Roles
                let role = action.payload?.data?.roleID
                let rolename = action.payload?.data?.rolename
                let expiredPassword = action.payload?.data?.expiredPassword

                let data = state.data.filter(d => d.key !== "security")
                if (role) {
                    data.push({ key: "security", value: { credential: { username: username, token: token, role: role, rolename: rolename, expiredPassword: expiredPassword }, roles: roles } })
                } else {
                    data.push({ key: "security", value: { credential: { username: username, token: token, expiredPassword: expiredPassword }, roles: roles } })
                }

                state.data = data
            })
            .addCase(loginSecurity.rejected, (state, action) => {
                setLoggedOut()

                state.status = 'failed'
                state.error = action.error.message
            })

        builder.addCase(logoutSecurity.pending, (state, action) => {
            state.status = 'loading'
            state.response = { message: '', level: -1 }
        })
            .addCase(logoutSecurity.fulfilled, (state, action) => {
                state.status = 'fulfilled'

                setLoggedOut()
            })
            .addCase(logoutSecurity.rejected, (state, action) => {
                setLoggedOut()

                state.status = 'failed'
                state.error = action.error.message
            })

        builder.addCase(getUnits.pending, (state, action) => {
            state.status = 'loading'
            state.response = { message: '', level: -1 }
        })
            .addCase(getUnits.fulfilled, (state, action) => {
                state.status = 'fulfilled'

                let units = action.payload

                let data = state.data.map(d => d.key === "units" ? { key: "units", value: { units: units } } : d)
                if (!data.find(d => d.key === "units")) {
                    data.push({ key: "units", value: { units: units } })
                }

                state.data = data
            })
            .addCase(getUnits.rejected, (state, action) => {
                setLoggedOut()

                state.status = 'failed'
                state.error = action.error.message
            })


        builder.addCase(setRole.pending, (state, action) => {
            state.status = 'loading'
            state.response = { message: '', level: -1 }
        })
            .addCase(setRole.fulfilled, (state, action) => {
                state.status = 'fulfilled'
                let username = action.payload?.data?.username
                let token = action.payload?.data?.token
                let roles = action.payload?.lookups?.Roles
                let role = action.payload?.data?.roleID
                let rolename = action.payload?.data?.rolename
                let expiredPassword = action.payload?.data?.expiredPassword

                let data = state.data.filter(d => d.key !== "security")
                if (role) {
                    data.push({ key: "security", value: { credential: { username: username, token: token, role: role, rolename: rolename, expiredPassword: expiredPassword } } })
                } else {
                    data.push({ key: "security", value: { credential: { username: username, token: token }, roles: roles } })
                }

                state.data = data
            })
            .addCase(setRole.rejected, (state, action) => {
                setLoggedOut()
                state.status = 'failed'
                state.error = action.error.message
            })

        builder.addCase(changePassword.pending, (state, action) => {
            state.status = 'loading'
            state.response = { message: '', level: -1 }
        })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.status = 'fulfilled'

                if (action.payload?.response.level != 4) {
                    return
                }

                let username = action.payload?.data?.username
                let data = state.data.filter(d => d.key !== "security")
                data.push({ key: "security", value: { credential: { username: username, isPasswordChanged: "True" } } })

                state.data = data


            })
            .addCase(changePassword.rejected, (state, action) => {
                setLoggedOut()

                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const { setLoggedIn, setLoggedOut } = SecuritySlice.actions

export const authenticated = (state: RootState) => state.security.data.find(d => d.key === "security")?.value.credential.token !== undefined
export const authorized = authenticated && ((state: RootState) => state.security.data.find(d => d.key === "security")?.value.credential.role !== undefined)
export const userRoles = (state: RootState) => state.security.data.find(d => d.key === "security")?.value.credential.role
export const security = (state: RootState) => state.security.data.find(d => d.key === "security")?.value
export const units = (state: RootState) => state.security.data.find(d => d.key === "units")?.value

export const hasExpiredPassword = (state: RootState) => state.security.data.find(d => d.key === "security")?.value.credential.expiredPassword !== null;
export const passwordChanged = (state: RootState) => state.security.data.find(d => d.key === "security")?.value.credential.isPasswordChanged !== undefined;

export default SecuritySlice.reducer