import * as React from 'react';
import FileUpload from '../../components/controls/FileUpload';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const description = useLeapData("description", "descrizione", page)
    const fileID = useLeapData("fileID", "fileID", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...description} className="ms-sm8" readOnly />
                    </Row>
                    <Row>
                        <FileUpload {...fileID} className="ms-sm8" label="upload file" controller={props.controller} required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
