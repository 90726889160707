import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import DateRange from '../../components/controls/DateRange';
import SearchBox from '../../components/controls/SearchBox';

import { setFormData } from '../operation/thunks/itemThunk';
import { AppDispatch } from '../../app/store';
import { useDispatch } from 'react-redux';
import Separator from '../../components/controls/grid/Separator';

type Props = { state: any, controller: any, isONHCOperator?: boolean }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const planID = useLeapData("planID", "piano sanitario", page)
    const corporationID = useLeapData("corporationID", "azienda", page, "corporations")
    const registerNumber = useLeapData("registerNumber", "matricola", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const leap_PlanAcceptedPrivacyID = useLeapData("leap_PlanAcceptedPrivacyID", "privacy accettata", page, "planAcceptedPrivacyOption")
    const leap_PlanReceivedPrivacyID = useLeapData("leap_PlanReceivedPrivacyID", "privacy ricevuta", page, "planReceivedPrivacyOption")
    const policy = useLeapData("policy", "polizza", page)

    const dispatch: AppDispatch = useDispatch();

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...planID} className="ms-sm12" readOnly required />
                        <SearchBox {...corporationID} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <Text {...registerNumber} className="ms-sm5" readOnly={props.isONHCOperator} />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm5", required: true, readOnly: props.isONHCOperator }} separator={{ className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm5", readOnly: props.isONHCOperator }} />
                    </Row>
                    <Row>
                        <Text {...policy} className="ms-sm12" maxlength={50} readOnly={props.isONHCOperator} />
                    </Row>
                    <Row>
                        <Option {...leap_PlanAcceptedPrivacyID} className="ms-sm5" type="horizontal" disabled />
                        <Separator className="ms-sm2"></Separator>
                        <Option {...leap_PlanReceivedPrivacyID} className="ms-sm5" type="horizontal" disabled />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
