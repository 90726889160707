import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { Icon } from '@fluentui/react';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any,
    onDownload: any
}

export default (props: Props) => {

    const onRenderIcon = (e: any) => {
        if (e.downloaded) {
            return <Icon iconName={"Accept"} style={{ color: 'green' }} />;
        }
        return <Icon iconName={"Cancel"} style={{ color: 'red' }} />;
    }

    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} >
                <DataColumn kid='column2' name='descrizione' fieldName='description' minWidth={200} align='left' />
                <DataColumn kid='columnYN' type="icon" name='scaricato' fieldName='downloaded' minWidth={120} maxWidth={120} align='center' onRenderIcon={onRenderIcon}/>
                <DataColumn kid='columnDL' name='' fieldName='' minWidth={10} maxWidth={10} align='center' type='download' onClick={props.onDownload} />

            </DataList>
        </>
    );
}
