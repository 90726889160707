import * as React from 'react';
import { AppDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, PivotItem, Pivot, IButtonProps, DefaultButton, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import { security } from '../features/security/thunks/securityThunk';
import { filterForm, filterValidation } from '../features/operation/thunks/filterThunk';
import { resultDownload } from '../features/operation/thunks/resultThunk';
import { lock, unlock } from '../features/operation/lockSlice';
import Theme from '../components/themes/Theme';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { FontFaces } from '../defs/fonts';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import MultiBox from '../components/controls/MultiBox';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import DateRange from '../components/controls/DateRange';
import Text from '../components/controls/Text';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { setResponse } from '../features/response/responseSlice';

const PivotButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
        verticalAlign: "bottom"
    },
    styles: {
        root: {
            backgroundColor: Theme.fluent.palette.themeTertiary,
            color: Theme.fluent.palette.neutralLighter,
            height: 38,
            marginTop: 12,
            minWidth: "12%",
        },
        rootDisabled: {
            backgroundColor: Theme.fluent.palette.themePrimary,
            color: Theme.fluent.palette.white,
            height: 42,
            marginTop: 8,
            minWidth: "15%",
        },
        rootHovered: {
            backgroundColor: Theme.fluent.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: Theme.fluent.palette.themeLight,
        }
    }
}
const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "inherit",
        whiteSpace: "pre-wrap",
        lineHeight: 30
    }
}
const multiSearchBoxItemLimit = 99;

export default () => {
    const roles = useSecurityRoles()
    const isInsuredUser: boolean = roles.isInsuredUser
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isCorporation: boolean = roles.isCorporation;
    const isCompany: boolean = roles.isCompany;
    const isONHCOperator: boolean = roles.isONHCOperator

    const isONHC: boolean = roles.isONHC;
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount;
    const isHF: boolean = roles.isHF;

    const controller = "DEInsured"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch();

    const validation = useSelector(filterValidation)

    const plan = useLeapData("plan", "piano sanitario", page, "plans")

    const registration_number = useLeapData("registration_number", "matricola", page, /*"registerNumbers"*/)
    const corporation = useLeapData("corporation", "azienda", page, "corporations")

    const code = useLeapData("code", "codice", page)
    const leap_InsuredStateID = useLeapData("leap_InsuredStateID", "attivo", page, "insuredState")
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)

    //recapiti
    const nationID = useLeapData("nationID", "nazione", page, "residenceNations")
    const regionID = useLeapData("regionID", "regione", page, "residenceRegions")
    const provinceID = useLeapData("provinceID", "provincia", page, "residenceProvinces")
    const residenceMunicipalityID = useLeapData("municipality_ResidenceID", "comune", page, "residenceMunicipalities")
    const email = useLeapData("email", "email", page)

    //coverage
    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)

    //privacy
    const leap_InsuredAcceptedPrivacyID = useLeapData("leap_InsuredAcceptedPrivacyID", "privacy accettata", page, "insuredAcceptedPrivacy")

    const credential = useSelector(security)?.credential

    const fForm = useSelector(filterForm)
    const formF = fForm.find(i => i.key === controller)?.value

    const extract = () => {
        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere al salvataggio, selezionare un piano sanitario e una data di inizio copertura.", 5, true))
            return;
        }

        dispatch(lock())
        dispatch(resultDownload({ controller, body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    return <>
        <Content title="Estrazione dati - Assistito" controller={controller} noResult>
            <Filter>
                <Grid>
                    <Section toolbar="filter"><></></Section>
                    <Section title=" ">
                        <Row>
                            <Text
                                onChange={() => { }}
                                className="ms-sm3"
                                label=""
                                name=""
                                type="plainWithIcon"
                                value="estrai dati"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={extract} style={{ cursor: "pointer", background: "transparent" }} id={"extract"} /></> }} />
                        </Row>
                    </Section>
                    <Section title=" ">
                        <Row>
                            <Text {...code} className="ms-sm2" maxlength={12} />
                            <Option {...leap_InsuredStateID} className="ms-sm2" type="horizontal" hidden={isInsuredUser || isHF} disabled={isInsuredUser || isHFOperator || isHFUser} />
                            <Option {...leap_InsuredAcceptedPrivacyID} className="ms-sm2" type="horizontal" hidden={isHF} />
                        </Row>
                        <Row>
                            <Text {...fiscalcode} className="ms-sm2" maxlength={16} />
                            <Text {...lastname} className="ms-sm2" maxlength={50} />
                            <Text {...firstname} className="ms-sm2" maxlength={50} />
                        </Row>
                        <Row hidden={isHF}>
                            <SearchBox {...plan} className="ms-sm4" required />
                            <DateRange dateMin={{ ...start_date, className: "ms-sm2", required: true }} dateMax={{ ...end_date, className: "ms-sm2" }} />
                        </Row>
                    </Section>
                    <Section title="posizione lavorativa" hidden={isHF}>
                        <Row hidden={isHF}>
                            <Text {...registration_number} className="ms-sm2" />
                        </Row>
                        <Row hidden={isHF}>
                            <SearchBox {...corporation} className="ms-sm4" />
                        </Row>
                    </Section>
                    <Section title="recapiti" hidden={isHF}>
                        <Row hidden={isHF}>
                            <Text {...email} className="ms-sm4" maxlength={255} />
                        </Row>
                        <Row hidden={isHF}>
                            <SearchBox {...nationID} className="ms-sm4" />
                            <SearchBox {...regionID} className="ms-sm4" />
                        </Row>
                        <Row hidden={isHF}>
                            <SearchBox {...provinceID} className="ms-sm4" />
                            <SearchBox {...residenceMunicipalityID} className="ms-sm4" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
