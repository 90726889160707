import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import HealthFacilityMasterResultList from '../features/healthFacilityMaster/HealthFacilityMasterResultList';
import NetworkList from '../features/healthFacilityMaster/NetworkList';
import NetworModal from '../features/healthFacilityMaster/NetworModal';
import { setOperation } from '../features/operation/operationSlice';
import { itemData } from '../features/operation/thunks/itemThunk';
import { setResponse } from '../features/response/responseSlice';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { lock, unlock } from '../features/operation/lockSlice';
import { PivotItem, Pivot, IButtonProps, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';


export const RegeneratePassword = createAsyncThunk(
    'item/RegeneratePassword',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("HealthFacilityMaster", { form: {}, page: args.page }, 'RegeneratePassword', args.token)
        return response
    }
)

export const GenerateUser = createAsyncThunk(
    'item/GenerateUser',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("HealthFacilityMaster", { form: {}, page: args.page }, 'GenerateUser', args.token)
        return response
    }
)

export default () => {
    const roles = useSecurityRoles()
    const isHealthFacilityMaster: boolean = roles.isHFMaster;

    const controller = "HealthFacilityMaster"
    const modalController = "HealthFacilityMasterNetwork"
    const page = useLeapState(controller, roles.role !== undefined, isHealthFacilityMaster ? "item" : "filter")

    const code = useLeapData("code", "codice", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const firstname = useLeapData("firstname", "nome", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const email = useLeapData("email", "email", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const phoneNumber = useLeapData("phoneNumber", "telefono", page)
    const piva = useLeapData("piva", "partita iva", page)

    //domicilio
    const address = useLeapData("address", "indirizzo", page)
    const streetNumber = useLeapData("streetNumber", "civico", page)
    const cap = useLeapData("cap", "cap", page)
    const nationID = useLeapData("nationID", "nazione", page, "nations")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    const isONHC: boolean = roles.isONHCAccount || roles.isONHCAdmin || roles.isONHCMaster || roles.isONHCOperator;

    const hasUser: string = page?.item?.state?.data?.hasUser;

    const [hideDialogRegeneratePassword, { toggle: toggleHideDialogRegeneratePassword }] = useBoolean(true);
    const [hideDialogGenerateUser, { toggle: togglehideDialogGenerateUser }] = useBoolean(true);

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const dialogContentPropsRegeneratePassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla rigenerazione della password?',
    };

    const dialogContentPropsGenerateUser = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla generazione dell\'utente?',
    };

    const dispatch: AppDispatch = useDispatch();
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const metadataPage = dItem?.metadata?.page
    const credential = useSelector(security)?.credential

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteRegeneratePassword = () => {
        dispatch(lock())
        toggleHideDialogRegeneratePassword()
        dispatch(RegeneratePassword({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }
    const onExecuteGenerateUser = () => {
        dispatch(lock())
        togglehideDialogGenerateUser()
        dispatch(GenerateUser({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const noDetailAccessLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            textAlign: "center",
            whiteSpace: "nowrap"
        }
    }
    return <>
        <Content title={isHealthFacilityMaster ? "Il mio Profilo" : "Master Struttura Sanitaria"} controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        {isHealthFacilityMaster && < div className="ms-Grid-col ms-sm12">
                            <Label styles={noDetailAccessLabel}>non è possibile applicare filtri di ricerca</Label>
                        </div>}
                        <Row hidden={isHealthFacilityMaster}>
                            <Text {...code} className="ms-sm3" maxlength={12} />
                        </Row>
                        <Row hidden={isHealthFacilityMaster}>
                            <Text {...firstname} className="ms-sm3" />
                            <Text {...lastname} className="ms-sm3" />
                            <Text {...email} className="ms-sm3" />
                        </Row>
                        <Row hidden={isHealthFacilityMaster}>
                            <SearchBox {...nationID} className="ms-sm3" />
                            <SearchBox {...regionID} className="ms-sm3" />
                        </Row>
                        <Row hidden={isHealthFacilityMaster}>
                            <SearchBox {...provinceID} className="ms-sm3" />
                            <SearchBox {...municipalityID} className="ms-sm3" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <HealthFacilityMasterResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row hidden={page.modality === "new"}>
                            <Text {...code} className="ms-sm3" readOnly hidden={page.modality === "new"} />
                            {page.modality === "item" && isONHC && hasUser && (
                                <>
                                    <PrimaryButton text="Resetta password" onClick={toggleHideDialogRegeneratePassword} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogRegeneratePassword} onDismiss={toggleHideDialogRegeneratePassword} dialogContentProps={dialogContentPropsRegeneratePassword} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegeneratePassword} text="Resetta password" />
                                            <DefaultButton onClick={toggleHideDialogRegeneratePassword} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                            {page.modality === "item" && isONHC && !hasUser && (
                                <>
                                    <PrimaryButton text="Genera Utenza" onClick={togglehideDialogGenerateUser} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogGenerateUser} onDismiss={togglehideDialogGenerateUser} dialogContentProps={dialogContentPropsGenerateUser} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteGenerateUser} text="Genera Utente" />
                                            <DefaultButton onClick={togglehideDialogGenerateUser} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                        </Row>
                        {isONHC && hasUser && (
                            <>
                                <Row>
                                    <DateRange dateMin={{ ...startDate, className: "ms-sm2", readOnly: isHealthFacilityMaster }} dateMax={{ ...endDate, className: "ms-sm2", readOnly: isHealthFacilityMaster }} />
                                </Row>
                            </>)}
                        <Section title="dati anagrafici">
                            <Row>
                                <Text {...firstname} className="ms-sm3" required readOnly={isHealthFacilityMaster} />
                                <Text {...lastname} className="ms-sm3" required readOnly={isHealthFacilityMaster} />
                                <Text {...email} className="ms-sm3" required readOnly={isHealthFacilityMaster} />
                            </Row>
                            <Row>
                                <Text {...businessName} className="ms-sm3" readOnly={isHealthFacilityMaster} />
                                <Text {...phoneNumber} className="ms-sm3" readOnly={isHealthFacilityMaster} />
                                <Text {...piva} className="ms-sm3 ms-xxl2" maxlength={16} required readOnly={page.modality === "item"} />
                            </Row>
                        </Section>
                    </Section>
                    <Section title="domicilio">
                        <Row>
                            <Text {...address} className="ms-sm4" readOnly={isHealthFacilityMaster} />
                            <Text {...streetNumber} className="ms-sm1" readOnly={isHealthFacilityMaster} />
                            <Text {...cap} className="ms-sm1" readOnly={isHealthFacilityMaster} />
                        </Row>
                        <Row>
                            <SearchBox {...nationID} className="ms-sm3" disabled={isHealthFacilityMaster} />
                            <SearchBox {...municipalityID} className="ms-sm3" disabled={isHealthFacilityMaster} />
                        </Row>
                    </Section>
                    {page.modality === "item" && <Section title="network" toolbar="grid" modal={<NetworModal state={page} controller={modalController} />} controller={modalController} modalwidth="800px" modaltitle="Aggiungi Struttura" state={page} >
                        <Row>
                            <NetworkList items={page.item.state?.lists?.HealthFacility} modal={<NetworModal state={page} controller={modalController} />} controller={modalController} modalwidth="800px" modaltitle="Modifica Struttura" state={page} />
                        </Row>
                    </Section>}
                </Grid>
            </Item>
        </Content >
    </>
}
