import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import AssociateHealthService from '../features/dentalHistory/AssociateHealthService';
import DentalHistory from '../features/dentalHistory/DentalHistory';
import DentalStateHistory from '../features/dentalHistory/DentalStateHistory';
import DentalHistoryResultList from '../features/dentalHistory/DentalHistoryResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { ColorPickerGridCell, PrimaryButton, Icon, IconButton, IIconProps, DialogType, Dialog, DialogFooter, DefaultButton } from '@fluentui/react';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { useBoolean, useId } from '@uifabric/react-hooks';

import DentalChart from '../components/controls/dental/DentalChart';
import ComboBox from '../components/controls/ComboBox';
import Theme from '../components/themes/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { currentModality } from '../features/modality/modalitySlice';
import { itemValidation, itemData, itemDelete } from '../features/operation/thunks/itemThunk';
import { AppDispatch } from '../app/store';
import { lock, unlock } from '../features/operation/lockSlice';
import { setOperation } from '../features/operation/operationSlice';
import { setResponse } from '../features/response/responseSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { security } from '../features/security/thunks/securityThunk';
import { useDatagridOperation } from '../hooks/useDatagridOperation';

export const saveNewState = createAsyncThunk(
    'item/gotoSaveNewDentalState',
    async (args: { id: string, dentalElement: string, dentalStateID: number, token: string | undefined }) => {

        const response = await client.post("DentalHistory", {
            form: {
                id: args.id,
                dentalElement: args.dentalElement,
                dentalStateID: args.dentalStateID
            }
        }, 'SaveNewState', args.token)
        return response
    }
)

export const DeleteDentalState = createAsyncThunk(
    'item/gotoDeleteDentalState',
    async (args: { id: string, dentalElement: string, dentalStateID: number, token: string | undefined }) => {

        const response = await client.post("DentalHistory", {
            form: {
                id: args.id,
                dentalElement: args.dentalElement
            }
        }, 'DeleteDentalState', args.token)
        console.log(response)
        return response
    }
)

type Props = {
    state: any,
    controller: any,
    parentController: any
}

export default (props: Props) => {
    const controller = "DentalHistory";
    const modalController = "DentalHistoryModal";
    const page = useLeapState(controller)
    const roles = useSecurityRoles()

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token
    const datagridOperation = useDatagridOperation("QuoteManagement", token)

    const insured = useLeapData("insuredID", "assistito", page, "insureds")
    const code = useLeapData("code", "codice", page, "insured")
    const fiscalCode = useLeapData("fiscalCode", "codice fiscale", page, "insured")
    const lastName = useLeapData("lastName", "cognome", page)
    const firstName = useLeapData("firstName", "nome", page)

    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)

    const selectedTeeth = page.item.values?.dentalElement ? page.item.values.dentalElement.value : (dentalElement ? (dentalElement.value ?? []) : []);
    const dentalElementStatePrevious = useLeapData("leap_DentalElementStatePreviousId", "stato corrente", page, "dentalElementStatePrevious")
    const dentalElementStateNext = useLeapData("leap_DentalElementStateNextId", "nuovo stato", page, "dentalStates")

    const dentalStateHistory = useLeapData("dentalStateHistory", "storico stato", page, "DentalStateHistory")
    const dentalHistory = useLeapData("dentalStateHistory", "storico stato", page, "History")

    const validation = useSelector(itemValidation)
    const modality = useSelector(currentModality);
    const saveIcon: IIconProps = { iconName: 'Save' };
    const deleteIcon: IIconProps = { iconName: 'Delete' };
    const stateController = "DentalHistory";
    const credential = useSelector(security)?.credential;
    const dispatch: AppDispatch = useDispatch();

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value

    const [hideDialogDeleteDentalStates, { toggle: togglehideDialogDeleteDentalStates }] = useBoolean(true);

    const isFromQuoteManagement = window.location.search === "?item"
    const args = { id: dItem?.data?.id, dentalElement: page.item?.values?.dentalElement?.value?.toString(), dentalStateID: Number.parseInt(page.item?.values?.leap_DentalElementStateNextId?.value), token: credential?.token }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }

    const dialogContentPropsDeleteDentalStates = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo al ripristino degli stati del dente?',
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }
    const onClickgotoSaveNewDentalState = (e: any) => {
        e.preventDefault()

        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere al salvataggio, selezionare un elemento dentale e un nuovo stato.", 5, true))
            return;
        }

        dispatch(lock())
        dispatch(saveNewState(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => page.onchange("dentalElement", [], true))
    }

    const onClickgotoDeleteDentalState = (e: any) => {
        togglehideDialogDeleteDentalStates()
        e.preventDefault()

        let mandatory = selectedTeeth.length == 0 ? false : true

        if (mandatory === false) {
            dispatch(setResponse("Selezionare un elemento dentale per procedere.", 5, true))
            return;
        }

        dispatch(lock())
        dispatch(DeleteDentalState(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => page.onchange("dentalElement", [], true))
    }

    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '24px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };

    const navigateToQuoteManagement = () => {
        dispatch(lock())
        datagridOperation.navigate({ id: page.item?.state?.data?.quoteid })
            .then((response) => {
                document.location.href = '/QuoteManagement/?item'
            })
            .then(() => dispatch(unlock()))
    }

    const dentalStates = page.item.state?.lists.DentalChartStates
    const previousDentalState = page?.item?.state?.lookups?.["dentalElementStatePrevious"]?.length > 0 ? page.item.state.lookups?.["dentalElementStatePrevious"][0] : ""

    return <>
        <Content title="Storico Bocca" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...insured} className="ms-sm8" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <DentalHistoryResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" readOnly />
                            <Text {...fiscalCode} className="ms-sm3" readOnly />
                            <Text {...lastName} className="ms-sm3" readOnly />
                            <Text {...firstName} className="ms-sm3" readOnly />
                        </Row>
                    </Section>
                    <Section toolbar="grid" title="Storico" modal={<AssociateHealthService state={page} controller={modalController} />} controller={modalController} modaltitle="Inserimento amnesi" state={page} modalwidth="800px">
                        <Row>
                            {/*Colonna destra con dati*/}
                            <div className="ms-Grid-col ms-sm6">

                                {/*Intestazione con cambio stato*/}
                                <Row>
                                    <Text {...dentalElementStatePrevious} className="ms-sm5" readOnly value={previousDentalState} />
                                    <ComboBox {...dentalElementStateNext} className="ms-sm5" required />
                                    {(roles.isONHC || roles.isHF) && < IconButton styles={iconButtonStyles} iconProps={saveIcon} ariaLabel="salva" onClick={onClickgotoSaveNewDentalState} />}
                                    {roles.isONHC && <IconButton styles={iconButtonStyles} iconProps={deleteIcon} ariaLabel="elimina stati" onClick={togglehideDialogDeleteDentalStates} />}
                                    <Dialog hidden={hideDialogDeleteDentalStates} onDismiss={togglehideDialogDeleteDentalStates} dialogContentProps={dialogContentPropsDeleteDentalStates} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onClickgotoDeleteDentalState} text="Cancella Stati" />
                                            <DefaultButton onClick={togglehideDialogDeleteDentalStates} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </Row>

                                {/*Bottone per tornare al piano di cure*/}
                                <Row>
                                    <div className="ms-Grid-row ms-sm12" style={{ marginLeft: 9, marginTop: 20 }} >
                                        {isFromQuoteManagement && roles.isONHC && <PrimaryButton text="vai al piano cure" onClick={navigateToQuoteManagement} />}
                                    </div>
                                </Row>

                                {/*Tabella storico stati*/}
                                <div className="ms-Grid-row leap-grid-row-body" style={{ marginTop: "20px" }} >
                                    <DentalStateHistory items={page?.item?.state?.lookups?.DentalStateHistory} controller={stateController} state={page} />
                                </div>

                                {/*Tabella storico prestazioni*/}
                                {roles.isONHC && <div className="ms-Grid-row leap-grid-row-body" style={{ marginTop: "20px" }}>
                                    <DentalHistory items={page.item.state?.lookups.History} controller={modalController} state={page} />
                                </div>}
                            </div>
                            {/*Colonna sinistra con immagine bocca*/}
                            <div className="ms-Grid-col ms-sm6" >
                                <DentalChart {...dentalElement} parentController={props.parentController} state={dentalStates} selectionMode="single" value={selectedTeeth} required children={[{ ...dentalElementStatePrevious }, { ...dentalStateHistory }, { ...dentalHistory }]} />
                            </div>
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
