import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Option from '../../components/controls/Option';

import Text from '../../components/controls/Text';
import ComboBox from '../../components/controls/ComboBox';
import MultiBox from '../../components/controls/MultiBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branches = useLeapData("branchID", "branca", page, "branches")
    const healthService = useLeapData("healthServiceID", "prestazioni", page, "healthServices")
    const monthlyFrequency = useLeapData("monthlyFrequency", "frequenza mensile", page)
    const numberOfTimes = useLeapData("numberOfTimes", "numero di volte", page)
    const roof = useLeapData("roof", "importo", page)
    const maxErosionPercentage = useLeapData("maxErosionPercentage", "percentuale massima di erosione", page)

    //documentazione obbligatoria
    const leap_DocumentationRequiredIDModal = useLeapData("leap_DocumentationRequiredIDModal", "documentazione obbligatoria", page, "documentationRequiredOption")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox
                            children={[{ name: "healthService", lookup: "healthServices", controller: props.controller }]}
                            {...branches} className="ms-sm12" disabled />
                    </Row>
                    <Row>
                        <MultiBox
                            {...healthService} className="ms-sm12" required={page.item?.values?.branches?.value === "" || page.item?.values?.branches?.value === "0"} titleType={"text"} />
                    </Row>
                    <Row>
                        <Text {...monthlyFrequency} className="ms-sm4" type="number" precision={{ integer: 2, decimal: 0, max: 60 }} required />
                        <Text {...numberOfTimes} className="ms-sm4" type="number" precision={{ integer: 2, decimal: 0, max: 99 }} />
                    </Row>
                    <Row>
                        <Text {...roof} className="ms-sm4" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} />
                        <Text {...maxErosionPercentage} className="ms-sm4" type="number" precision={{ integer: 2, decimal: 0 }} />
                    </Row>
                    <Row>
                        <Option {...leap_DocumentationRequiredIDModal} className="ms-sm3" type="horizontal" required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
