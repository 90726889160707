import * as React from 'react'
import { DirectionalHint, Coachmark, TeachingBubbleContent } from '@fluentui/react/'
import Theme from '../themes/Theme'


type Props = {
    target: string,
    onDismiss?: () => void,
    title: string,
    content?: any,
    isWide?: boolean
}

export default (props: Props) => {
    return <>
        <Coachmark
            target={"#" + props.target}
            positioningContainerProps={{ directionalHint: DirectionalHint.rightCenter }}
        //styles={{ ariaContainer: { background: Theme.fluent.palette.themePrimary }, root: { background: Theme.fluent.palette.themePrimary } }}
        >
            <TeachingBubbleContent
                headline={props.title}
                hasCloseButton
                closeButtonAriaLabel="chiudi"
                onDismiss={props.onDismiss}
                isWide={props.isWide}
                styles={{ content: { background: Theme.fluent.palette.themePrimary } }}
            >
                {props.content}
            </TeachingBubbleContent>
        </Coachmark>
    </>
}
