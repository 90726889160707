import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from '../app/store';
import { client } from '../api/client';

import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

import { lock, unlock, isLocked } from '../features/operation/lockSlice';

import { security } from '../features/security/thunks/securityThunk';
import { itemDownload, itemPrint, itemForm, itemData } from '../features/operation/thunks/itemThunk';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';

import { Icon, PrimaryButton, IButtonProps } from '@fluentui/react';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import DateRange from '../components/controls/DateRange';
import SearchBox from '../components/controls/SearchBox';
import Separator from '../components/controls/grid/Separator';
import Uploader from '../components/controls/Uploader';

import InsuredBillingResultList from '../features/insuredBilling/InsuredBillingResultList';
import InsuredBillingHealthServiceList from '../features/insuredBilling/InsuredBillingHealthServiceList';
import { resultData } from '../features/operation/thunks/resultThunk';
import { filterForm } from '../features/operation/thunks/filterThunk';
import ComboBox from '../components/controls/ComboBox';

export const downloadBalance = createAsyncThunk(
    'item/downloadBalance',
    async (args: { body: { form: any, page: any }, token: string | undefined }) => {
        const response = await client.downloadPost("InsuredBilling", { form: { ...args.body.form, fileName: "estrattobancario.csv" }, page: args.body.page }, 'DownloadBalance', undefined, args.token)

        return response
    }
)

export default () => {
    const dispatch: AppDispatch = useDispatch()

    const controller = "InsuredBilling"
    const quoteManagementHealthServiceController = "QuoteManagementHealthService"

    const page = useLeapState(controller)

    const roles = useSecurityRoles()
    const isONHC: boolean = roles.isONHC
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isHFMaster: boolean = roles.isHFMaster

    const isHF: boolean = roles.isHF

    const _isLocked = useSelector(isLocked)

    const credential = useSelector(security)?.credential

    const endOfCureStartDate = useLeapData("endOfCureStartDate", "data fine cura da", page)
    const endOfCureEndDate = useLeapData("endOfCureEndDate", "data fine cura a", page)
    const paymentStartDate = useLeapData("paymentStartDate", "data pagamento da", page)
    const paymentEndDate = useLeapData("paymentEndDate", "data pagamento a", page)

    const planID = useLeapData("planID", "piano sanitario", page, "plans")

    const healthFacilityID = useLeapData("healthFacilityID", "codice struttura sanitaria", page, "healthFacilities")
    const insuredID = useLeapData("insuredID", "codice assistito", page, "insureds")

    const quoteManagementNumber = useLeapData("quoteManagementNumber", "numero piano di cure", page)
    const insuredFiscalcode = useLeapData("insuredFiscalcode", "cf assistito", page)
    const lastnamePersonal = useLeapData("lastnamePersonal", "cognome anagrafico", page)
    const firstnamePersonal = useLeapData("firstnamePersonal", "nome anagrafico", page)
    const authorization = useLeapData("authorization", "data di autorizzazione", page)
    const startTreatment = useLeapData("startTreatment", "data inizio cura", page)
    const endTreatment = useLeapData("endTreatment", "data fine cura", page)

    const totalCost = useLeapData("totalCost", "totale", page)
    const insuredCost = useLeapData("estimatedCost", "a carico assistito", page)
    const onhcCost = useLeapData("onhcCost", "a carico assicurazione / Fondo", page)

    const insuredInvoiceNumber = useLeapData("numberInsuredInvoice", "numero fattura", page)
    const insuredInvoiceDate = useLeapData("dateInsuredInvoice", "data fattura", page)
    const insuredInvoiceImport = useLeapData("importInsuredInvoice", "importo fattura", page)
    const insuredInvoiceFileID = useLeapData("insuredInvoice", "documento fattura", page)

    const leap_QuoteManagementQuoteStateDescr = useLeapData("leap_QuoteManagementQuoteStateDescr", "stato piano di cure", page)

    const leap_InsuredInvoiceVerificationID = useLeapData("leap_InsuredInvoiceVerificationID", "stato di verifica della fattura assistito", page, "documentationVerificationOption")

    const quoteManagementHealthServices = useLeapData("quoteManagementHealthServices", "", page)

    const insuredBillingState = useLeapData("insuredBillingState", "stato", page, "insuredBillingStates")

    const header_cc = useLeapData("header_cc", "intestazione c/c", page)
    const iban = useLeapData("iban", "IBAN", page)

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.insuredInvoiceFileID,
            fileName: page?.item?.state?.data?.insuredInvoiceFilename,
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    const fForm = useSelector(filterForm)
    const rData = useSelector(resultData);
    const dResult = rData.find(d => d.key === controller)?.value
    const resultPage = dResult?.metadata?.page
    const args = { body: { form: fForm.find(i => i.key === controller)?.value, page: resultPage }, token: credential?.token }

    const onExecuteDownloadBalance = (e: any) => {
        dispatch(lock())
        dispatch(downloadBalance(args))
            .then(() => dispatch(unlock()))
    }

    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const canDownload = page?.filter?.values?.insuredBillingState?.value === '1' && page.result?.state?.data.length > 0 && false

    const underRevision = !!page?.item?.state?.data?.leap_InsuredInvoiceVerificationID && isHF
    const revisionLocked = !isONHCMaster && (page?.item?.state?.data?.leap_InsuredInvoiceVerificationID === "1" || page?.item?.state?.data?.leap_InsuredInvoiceVerificationID === "3")

    const iForm = useSelector(itemForm)
    const form = iForm.find(i => i.key === controller)?.value

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const iPage = dItem?.metadata?.page

    const onClickPrint = () => {
        dispatch(lock())
        dispatch(itemPrint({ controller, lookup: "print", body: { form: form, page: iPage }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    return <>
        <Content title="Fatturazione Assistito" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            {(isONHC || isHFMaster) && <SearchBox {...healthFacilityID} className="ms-sm6" />}
                            <SearchBox {...insuredID} className="ms-sm6" />
                        </Row>
                        {isONHC && <>
                            <Row>
                                <SearchBox {...planID} className="ms-sm6" />
                            </Row>
                            <Row>
                                <DateRange dateMin={{ ...endOfCureStartDate, className: "ms-sm2" }} dateMax={{ ...endOfCureEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                                <Separator className="ms-sm1 ms-hiddenXxlUp" />
                                <DateRange dateMin={{ ...paymentStartDate, className: "ms-sm2" }} dateMax={{ ...paymentEndDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                            </Row>
                            <Row>
                                <ComboBox {...leap_InsuredInvoiceVerificationID} className="ms-sm4" />
                                <Option  {...insuredBillingState} className="ms-sm8" type="horizontal" />
                            </Row>
                        </>}
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        {(canDownload) && <>
                            <Row>
                                <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 18 }}>
                                    <PrimaryButton {...ButtonProps} className="margin--bottom--16" text='scarica riepilogo' onClick={onExecuteDownloadBalance} disabled={_isLocked} />
                                </div>
                            </Row>
                        </>}
                        <InsuredBillingResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...quoteManagementNumber} className="ms-sm3 ms-xxl2" readOnly />
                            <Text {...leap_QuoteManagementQuoteStateDescr} className="ms-sm2" readOnly />
                            <Separator className="ms-sm6 ms-xxl7" />
                            <div className="ms-Grid-col ms-sm1">
                                <PrimaryButton onClick={onClickPrint} style={{ marginTop: 22 }}>stampa</PrimaryButton>
                            </div>
                        </Row>
                        <Row>
                            <Text {...totalCost} className="ms-sm2" type="number" readOnly />
                            <Text {...insuredCost} className="ms-sm2" type="number" readOnly />
                            <Text {...onhcCost} className="ms-sm2" type="number" readOnly />
                            <Separator className="ms-sm2" />
                            <Option {...leap_InsuredInvoiceVerificationID} type="horizontal" className="ms-sm4" disabled={(!isONHCMaster && !isONHCAccount) || revisionLocked} />
                        </Row>
                    </Section>
                    <Section title="dati assistito">
                        <Row>
                            <Text {...insuredFiscalcode} className="ms-sm2" readOnly />
                            <Text {...lastnamePersonal} className="ms-sm3" readOnly />
                            <Text {...firstnamePersonal} className="ms-sm3" readOnly />
                        </Row>
                    </Section>
                    <Section title="periodo di cura">
                        <Row>
                            <Text {...authorization} className="ms-sm2" type="calendar" readOnly />
                            <DateRange dateMin={{ ...startTreatment, className: "ms-sm2", readOnly: true }} dateMax={{ ...endTreatment, className: "ms-sm2", readOnly: true }} />
                        </Row>
                    </Section>
                    <Section title="pagamento">
                        <Row>
                            <Text {...insuredInvoiceNumber} className="ms-sm2" required={!underRevision} readOnly={underRevision} />
                            <Text {...insuredInvoiceDate} className="ms-sm2" type="calendar" required={!underRevision} readOnly={underRevision} dateRange={{ maxDate: Date().toLocaleString() }} />
                            <Text {...insuredInvoiceImport} className="ms-sm2" type="number" required={!underRevision} readOnly={underRevision} />
                            {!!!page?.item?.state?.data?.insuredInvoiceFilename && <Uploader {...insuredInvoiceFileID} className="ms-sm6" label="documento fattura" />}
                            {!!page?.item?.state?.data?.insuredInvoiceFilename && <Text onChange={() => { }} className="ms-sm6" label="documento fattura" name="" type="plainWithIcon" value={page?.item?.state?.data?.insuredInvoiceFilename} readOnly onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />}
                        </Row>
                    </Section>
                    <Section title="estremi bancari" >
                        <Row>
                            <Text {...iban} className="ms-sm3" required={!underRevision} readOnly={underRevision} />
                            <Text {...header_cc} className="ms-sm3" required={!underRevision} readOnly={underRevision} />
                        </Row>
                    </Section>
                    <Section title="elenco prestazioni" toolbar={undefined} controller={quoteManagementHealthServiceController} state={page.result?.state?.data} >
                        <Row>
                            <InsuredBillingHealthServiceList {...quoteManagementHealthServices} readonly={true} listType='new' items={page.item.state?.lists.healthServices} controller={quoteManagementHealthServiceController} state={page} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
