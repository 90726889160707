import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Text from '../../components/controls/Text';

type Props = { state: any, controller: any }

export default (props: Props) => {

    const page = useLeapState(props.controller, false)

    const description = useLeapData("description", "descrizione", page)
    const reportDate = useLeapData("reportDate", "data allegato", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...description} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <Text {...reportDate} className="ms-sm4" type="calendar" required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
