
import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import HealthFacilityDocumentationFileList from '../features/healthFacilityDocumentation/HealthFacilityDocumentationFileList';
import HealthFacilityDocumentationFileModal from '../features/healthFacilityDocumentation/HealthFacilityDocumentationModal';
import HealthFacilityDocumentationResultList from '../features/healthFacilityDocumentation/HealthFacilityDocumentationResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import ComboBox from '../components/controls/ComboBox';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

export default () => {
    const controller = "HealthFacilityDocumentation"

    const roles = useSecurityRoles()
    const defaultToResult = roles.isHF && !roles.isHFMaster

    const page = useLeapState(controller, roles.role !== undefined, defaultToResult ? "result" : "filter")

    const healthFacilityDocumentationFileController = "HealthFacilityDocumentationFile"

    const description = useLeapData("description", "descrizione", page)
    const leap_HealthFacilityDocumentTypeID = useLeapData("leap_HealthFacilityDocumentTypeID", "tipo documento", page, "healthFacilityDocumentTypeOption")
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)

    return <>
        <Content title="Documentazione Struttura Sanitaria" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...description} className="ms-sm5 ms-xxl4" />
                            <ComboBox {...leap_HealthFacilityDocumentTypeID} className="ms-sm3 ms-xxl2" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm2" }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <HealthFacilityDocumentationResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...description} className="ms-sm5 ms-xxl4" />
                            <ComboBox {...leap_HealthFacilityDocumentTypeID} className="ms-sm3 ms-xxl2" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2", readOnly: true }} dateMax={{ ...endDate, className: "ms-sm2", readOnly: true }} separator={{ className: "ms-sm1 ms-hiddenXxlUp" }} />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="elenco files caricati" toolbar="grid" modal={<HealthFacilityDocumentationFileModal state={page} controller={healthFacilityDocumentationFileController} />} controller={healthFacilityDocumentationFileController} modaltitle="Inserisci un file" state={page} modalwidth="30vw">
                        <Row>
                            <HealthFacilityDocumentationFileList items={page.item.state?.lists.HealthFacilityDocumentationFile} modal={<HealthFacilityDocumentationFileModal state={page} controller={healthFacilityDocumentationFileController} />} controller={healthFacilityDocumentationFileController} modaltitle="Modifica un file" modalwidth="30vw" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
}
