import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} className={"ms-Grid-col ms-sm6"}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Inizio Ferie' fieldName='startDate' minWidth={100} maxWidth={120} align='center' />
                <DataColumn kid='column2' name='Fine Ferie' fieldName='endDate' minWidth={100} maxWidth={120} align='center' />
                <DataColumn kid='column3' name='Descrizione' fieldName='description' minWidth={250} align='left' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
