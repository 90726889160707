import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Titolo' fieldName='title' minWidth={250} align='left' />
                <DataColumn kid='column2' name='Indirizzo web' fieldName='url' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column2' name='Inizio Pub' fieldName='startDate' minWidth={100} maxWidth={100} align='center' />
                <DataColumn kid='column2' name='Fine Pub' fieldName='endDate' minWidth={100} maxWidth={100} align='center' />
            </DataList>
        </>
    );
}