import { DefaultButton, Dialog, DialogFooter, DialogType, FontSizes, getTheme, mergeStyleSets, Modal, PrimaryButton, Link } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { security } from '../security/thunks/securityThunk';

export const IsReservationNotifyEnabled = createAsyncThunk(
    'item/HealthFacilityReservationNotifyEnabled',
    async (args: { token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: {}, page: {} }, 'ReservationNotifyEnabled', args.token)
        return response
    }
)
export const DoReservationActivate = createAsyncThunk(
    'item/HealthFacilityReservationActivate',
    async (args: { token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: {}, page: {} }, 'ReservationActivate', args.token)
        return response
    }
)
export const DoReservationDisableNotify = createAsyncThunk(
    'item/ReservationDisableNotify',
    async (args: { token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: {}, page: {} }, 'ReservationDisableNotify', args.token)
        return response
    }
)

export const ReservationGuideDownload = createAsyncThunk(
    'item/ReservationGuideDownload',
    async (args: { token?: string | undefined }) => {
        const response = await client.downloadPost("Files", { form: { fileName: "GuidaPrenotazione.pdf" }, page: {} }, 'ReservationGuide', args.token)
        return response
    }
)

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        maxHeight: "80vh",
        padding: "30px 0 20px",
        width: 800
    },
    content: {
        overflowY: "visible",
    },
    header: {
        color: Theme.fluent.palette.themePrimary,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.xxLargePlus,
        fontVariant: 'small-caps',
        fontWeight: 600,
        padding: "20px 0 30px",
        textAlign: "center"
    },
    body: {
        fontFamily: FontFaces.openSans,
        fontSize: 16,
        overflowY: "visible",
        padding: "0 32px",
        textAlign: "center",
        selectors: {
            p: { margin: '16px 0' },
            'p:nth-child(1)': { margin: "0 0 16px" },
            'p:nth-child(5)': { margin: "40px 0" },
            'p:nth-child(6)': { margin: "40px 0 40px" },
            'span.orange': { color: "#ff8200", fontWeight: 500 },
            'span.download': { color: "#ff8200", cursor: "pointer", fontWeight: 500, textDecoration: "underline" },

        },
    },
    primaryButton: {
        padding: "0 0 50px",
        textAlign: "center",
        selectors: {
            button: { backgroundColor: "#ff8200", borderColor: "#ff8200", borderRadius: 4, color: Theme.fluent.palette.white, fontWeight: 600, width: 200 },
            'button:active': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" },
            'button:hover': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" }
        },
    },
    standardButton: {
        padding: "0 32px",
        textAlign: "right",
        selectors: {
            button: { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", borderRadius: 4, color: "#ff8200", fontSize: "0.7em", margin: '8px 0', width: 180 },
            'button:active': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white },
            'button:hover': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white }
        },
    }
});

export default () => {
    const dispatch: AppDispatch = useDispatch();

    const roles = useSecurityRoles()
    const isHF = roles.isHF;
    const credential = useSelector(security)?.credential

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isDialogOpen, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);

    const [dialogText, setDialogText] = React.useState("")

    React.useEffect(() => {
        if (!!!isHF) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(IsReservationNotifyEnabled({ token: credential.token }))
            .then((response) => {
                if (!!response?.payload?.data) {
                    showModal()
                }
            })

    }, [isHF, credential])

    const reservationActivate = () => {
        if (!!!isHF) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoReservationActivate({ token: credential.token }))
            .then((response) => {
                if (!!response?.payload?.data) {
                    hideModal()
                    setDialogText("L'adesione alla Prenotazione on-line è stata correttamente effettuata.")
                }
                else {
                    setDialogText("Si è verificato un errore. Contattare l'assistenza.")
                }
                return response
            })
            .then((response) => {
                if (!!response?.payload?.data) {
                    showDialog()
                }
            })
    }

    const reservationDisableNotify = () => {
        if (!!!isHF) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoReservationDisableNotify({ token: credential.token }))
            .then((response) => {
                if (!!response?.payload?.data) {
                    hideModal()
                    setDialogText("Il promemoria è stato disattivato.")
                }
                else {
                    setDialogText("Si è verificato un errore. Contattare l'assistenza.")
                }
                return response
            })
            .then((response) => {
                if (!!response?.payload?.data) {
                    showDialog()
                }
            })
    }

    const reservationGuideDownload = () => {
        if (!!!isHF) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(ReservationGuideDownload({ token: credential.token }))
    }


    const dialogStyles = { main: { maxWidth: 600 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Prenotazione on-line',
        closeButtonAriaLabel: 'Chiudi',
        subText: dialogText,
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    return <>
        <Modal titleAriaId={"ReservationRequest"} isOpen={isModalOpen} isBlocking containerClassName={contentStyles.container} scrollableContentClassName={contentStyles.content}>
            <div className={contentStyles.header}>
                <span>PRENOTAZIONE ON-LINE</span>
            </div>
            <div className={contentStyles.body}>
                <p>
                    Nei prossimi giorni sarà attivo il nuovo sistema di Prenotazione Online che consentirà ai nostri Assistiti di richiedere un appuntamento per una <span className="orange">Visita</span> e/o <span className="orange">una Seduta di igiene</span> presso la Vostra struttura direttamente dalla propria area riservata o dall’app mobile!
                </p>
                <p>
                    Riceverete notifica di richiesta via mail e, accedendo alla sezione dedicata del portale ONHC, potrete procedere, entro 3 giorni lavorativi, ad inserire la proposta di appuntamento e contestuale apertura automatica del piano di cure. (N.B. il termine dei 3 gg non si intende per l’appuntamento bensì per la proposta di appuntamento)
                </p>
                <p>
                    All’accettazione della data da parte dell’assistito, il piano di cure verrà direttamente inviato in lavorazione a ONHC Servizi Sanitari e Welfare (Odontonetwork).
                </p>
                <p>
                    Vi ricordiamo di verificare la corretta compilazione del campo <span className="orange">email pubblica</span> perché a questa verranno inviate le richieste di appuntamento. In caso di più studi gestiti da una segreteria unica potrete utilizzare lo stesso recapito mail.
                </p>
                <p>
                    Nel caso in cui il campo <span className="orange">email pubblica</span> non venga compilato le notifiche di richiesta prenotazione verranno inviate alla mail inserita nel campo <span className="orange">email</span>, verificate quindi che le persone addette alla gestione degli appuntamenti abbiano accesso a tale mail.
                </p>
                <p>
                    Cliccando su <span className="orange">ACCETTO</span> gli assistiti potranno inviare le richieste e potrete procedere direttamente alla gestione delle Prenotazioni on-line!
                </p>
                <p>
                    Trovate <span className="download" onClick={reservationGuideDownload}>qui</span> la procedura per la gestione della Prenotazione on-line.
                </p>
            </div>
            <div className={contentStyles.primaryButton}>
                <PrimaryButton text="ACCETTO" onClick={reservationActivate} />
            </div>
            <div className={contentStyles.standardButton}>
                <DefaultButton text="RICORDA PIÙ TARDI" onClick={hideModal} />
            </div>
            <div className={contentStyles.standardButton}>
                <DefaultButton text="NON RICORDARMELO PIÙ" onClick={reservationDisableNotify} />
            </div>
        </Modal>
        <Dialog hidden={!isDialogOpen} dialogContentProps={dialogContentProps} modalProps={modalProps} isBlocking>
            <DialogFooter>
                <DefaultButton onClick={hideDialog} text="Chiudi" />
            </DialogFooter>
        </Dialog>
    </>
}
