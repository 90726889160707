import { IIconProps } from '@fluentui/react'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/rootReducer'

interface PlanPayload {
    cancelIconoption: IIconProps[],
}

const planSlice = createSlice({
    name: 'PlanSlice',
    initialState: {
        cancelIconOption: [{ iconName: 'Cancel' }],
        isModalOpenOption: [{ setTrue: 'showModal', setFalse: 'hideModal' }]
    },
    reducers: {},
})

export const cancelIconOption = (state: RootState) => state.role.cancelIconOption
export const isModalOpenOption = (state: RootState) => state.role.isModalOpenOption
export default planSlice.reducer
