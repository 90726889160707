import * as React from 'react';

type State = typeof initialState
type Props = {}

const initialState = Object.freeze({})

export default class Grid extends React.PureComponent<Props, State> {
    static defaultProps = Object.freeze({})

    public render() {
        return <>
            <div className="ms-Grid leap-Grid" dir="ltr">
                {this.props.children}
            </div>
        </>
    }
};