import * as React from 'react';
import { useState, useEffect } from 'react';

import Theme from '../../themes/Theme'

type drawType = {
    parent?: boolean,
    style: React.CSSProperties,
    d: string
}
type textType = {
    label?: string,
    style?: React.CSSProperties,
    x: React.Key,
    y: React.Key
}

type Props = {
    id: string,
    selected: (id: string) => boolean,
    draw: drawType[],
    text: textType,
    unselectedColor?: (id: string) => string | undefined,
    onChange?: (name: string, value: boolean | undefined) => void,
}

export default (props: Props) => {
    const [selected, setSelected] = useState(props.selected(props.id));

    useEffect(() => {
        setSelected(props.selected(props.id))
    }, [props.selected(props.id)])

    const fillColor = {
        selected: Theme.fluent.palette.themeLight,
        unselected: !!props.unselectedColor ? props.unselectedColor(props.id) : Theme.fluent.palette.white
    }
    const strokeColor = {
        selected: Theme.fluent.palette.neutralDark,
        unselected: Theme.fluent.palette.black
    }
    const textColor = {
        selected: Theme.fluent.palette.neutralPrimary,
        unselected: Theme.fluent.palette.black
    }

    const onClickHandler = (event: any) => {
        var newSelected = !selected

        setSelected(newSelected)

        if (props.onChange) {
            props.onChange(props.id, newSelected)
        }
    }

    const parent = props?.draw.find(item => item.parent)
    const children = props?.draw.filter(item => !item.parent)

    const parentClassName = "tooth tooth-parent"
    const childClassName = "tooth tooth-child"

    const textStyle: React.CSSProperties = {
        ...props.text.style,
        color: Theme.fluent.palette.neutralPrimary,
        cursor: "pointer",
        font: Theme.fluent.fonts.medium.font,
        fontSize: "10px",
        lineHeight: "100%",
        letterSpacing: 0,
        wordSpacing: 0,
        fill: "#000",
        fillOpacity: 1,
        stroke: selected ? textColor.selected : textColor.unselected,
        strokeWidth: selected ? 0.6 : 0.4,
        textDecoration: selected ? "underline" : "none"
    }
    const parentStyle: React.CSSProperties = {
        ...parent?.style,
        cursor: "pointer",
        fill: selected ? fillColor.selected : fillColor.unselected,
        pointerEvents: "visibleFill",
        stroke: selected ? strokeColor.selected : strokeColor.unselected,
    }
    const childStyle = {
        cursor: "pointer",
        stroke: selected ? strokeColor.selected : strokeColor.unselected,
    }

    return <>
        <text x={props.text.x} y={props.text.y} style={textStyle} onClick={onClickHandler}>
            <tspan x={props.text.x} y={props.text.y}>{props.text.label ?? props.id}</tspan>
        </text>
        <path className={parentClassName} d={parent?.d} style={parentStyle} onClick={onClickHandler} />
        {children.map((item, index) => <path key={index} className={childClassName} d={item.d} style={{ ...item.style, ...childStyle }} onClick={onClickHandler} />)}
    </>
};