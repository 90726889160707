import * as React from 'react';
import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';
import { PrimaryButton } from '@fluentui/react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import Row from '../../components/controls/grid/Row';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';


export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const roles = useSecurityRoles()

    const isONHC: boolean = roles.isONHC
    const isInsured: boolean = roles.isInsuredUser
    const isHfUser: boolean = roles.isHF


    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const newReservation = () => {
        dispatch(setOperation("new", props.controller))
    }

    return (
        <>
            {isInsured && <div className="ms-Grid-col" style={{ marginTop: 20, marginBottom: 20, display: "inline-flex", justifyContent: "flex-end" }} >
                <PrimaryButton onClick={newReservation} >nuova prenotazione</PrimaryButton>
            </div>}
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={100} maxWidth={100} align='center' />
                <DataColumn kid='column2' name='Assistito' fieldName='insuredCode' minWidth={100} maxWidth={100} align='center' />
                <DataColumn kid='column3' name='Cognome' fieldName='lastName' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column4' name='Nome' fieldName='firstName' minWidth={120} maxWidth={120} align='left' />
                {isONHC && <DataColumn kid='column5' name='STS' fieldName='healthFacilityCode' minWidth={100} maxWidth={100} align='center' />}
                {!isHfUser && <DataColumn kid='column6' name='Struttura Sanitaria' fieldName='healthFacilityID' minWidth={150} align='left' />}
                <DataColumn kid='column7' name='Stato' fieldName='leap_reservationRequestStateID' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column8' name='Data Richiesta' fieldName='requestDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column9' name='Appuntamento' fieldName='appointment' minWidth={120} maxWidth={120} align='center' />
            </DataList>
        </>
    );
}

