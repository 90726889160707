import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useBoolean, useId } from '@uifabric/react-hooks';

import { AppDispatch } from '../app/store';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { lock, unlock, isLocked } from '../features/operation/lockSlice';

import { security } from '../features/security/thunks/securityThunk';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';

import IndirectManagementResultList from '../features/IndirectAccountingSituation/IndirectAccountingSituationResultList';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';

import SearchBox from '../components/controls/SearchBox';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Option from '../components/controls/Option';
import { PrimaryButton, IButtonProps, DialogType, Dialog, DialogFooter, DefaultButton } from '@fluentui/react';
import { createAsyncThunk, current } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { setOperation } from '../features/operation/operationSlice';
import { setResponse } from '../features/response/responseSlice';
import { itemData } from '../features/operation/thunks/itemThunk';
import { resultData, resultLoad } from '../features/operation/thunks/resultThunk';
import { filterForm, setFormFilter } from '../features/operation/thunks/filterThunk';
import { currentModality, setModality } from '../features/modality/modalitySlice';
import { useDatagridOperation } from '../hooks/useDatagridOperation';

export const gotoPaid = createAsyncThunk(
    'item/gotoPaid',
    async (args: { refoundDate: Date | undefined, page: any, token: string | undefined }) => {
        const response = await client.post("IndirectAccountingSituation", { form: { refoundDate: args.refoundDate }, page: args.page }, 'Pay', args.token)
        return response
    }
)

export const downloadBalance = createAsyncThunk(
    'item/downloadBalance',
    async (args: { refoundDate: Date | undefined, page: any, token: string | undefined }) => {
        const response = await client.downloadPost("IndirectAccountingSituation", { form: { fileName: "estrattobancario.csv" }, page: args.page }, 'DownloadBalance', undefined, args.token)

        return response
    }
)

export default () => {
    const dispatch: AppDispatch = useDispatch()

    const modality = useSelector(currentModality)
    const credential = useSelector(security)?.credential
    const _isLocked = useSelector(isLocked)

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = roles.isONHC;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = roles.isHF;

    const isInsured = roles.isInsuredUser;

    const controller = "IndirectAccountingSituation"

    const datagridOperation = useDatagridOperation(controller, credential?.token)

    const [isPaid, setPaid] = React.useState<boolean>(false)
    const [isDownloadable, setDownloadable] = React.useState<boolean>(false)
    const [refoundDateValue, setRefoundDateValue] = React.useState<Date | undefined>()

    const page = useLeapState(controller, roles.role !== undefined, isInsured ? "result" : "filter")

    const codeIndirect = useLeapData("indirectManagementNumber", "codice pratica", page)
    const codePolicy = useLeapData("insurancePolicyNumber", "codice assicurazione", page)
    const stateIndirect = useLeapData("indirectManagementStateID", "stato pratica", page, "indirectManagementStates")
    const insured = useLeapData("insuredID", "assistito", page, "insureds")
    const plan = useLeapData("planID", "piano sanitario", page, "plans")
    const createFromDate = useLeapData("createFromDate", "inserimento dal", page)
    const createToDate = useLeapData("createToDate", "al", page)
    const updateFromDate = useLeapData("updateFromDate", "aggiornamento dal", page)
    const updateToDate = useLeapData("updateToDate", "al", page)
    const refoundFromDate = useLeapData("refoundFromDate", "liquidazione dal", page)
    const refoundToDate = useLeapData("refoundToDate", "al", page)

    const refoundDate = useLeapData("refoundDate", "data liquidazione", page)

    const fForm = useSelector(filterForm)
    const rData = useSelector(resultData);

    const form = { ...fForm.find(i => i.key === controller)?.value }

    const dResult = rData.find(d => d.key === controller)?.value
    const resultPage = dResult?.metadata?.page
    const args = { refoundDate: refoundDateValue, page: resultPage, token: credential?.token }

    const dispatchPayReload = (response: any) => {
        form.refoundFromDate = refoundDateValue
        form.refoundToDate = refoundDateValue

        form.indirectManagementStateID = "7"

        dispatch(resultLoad({ controller, modality: "filter", operation: "search", body: { form: form }, token: credential.token }))
            .then(() => {
                dispatch(setFormFilter(controller, { name: "refoundFromDate", value: refoundDateValue }))
                dispatch(setFormFilter(controller, { name: "refoundToDate", value: refoundDateValue }))
                dispatch(setFormFilter(controller, { name: "indirectManagementStateID", value: "7" }))
            })
        return response
    }
    const dispatchDownloadBalanceReload = () => {
        form.indirectManagementStateID = "8"
        
        dispatch(resultLoad({ controller, modality: "filter", operation: "search", body: { form: form }, token: credential.token }))
            .then(() => {
                dispatch(setFormFilter(controller, { name: "indirectManagementStateID", value: "8" }))
            })
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response?.payload?.response?.message, response?.payload?.response?.level))
    }

    const onClickPay = (e: any) => {
        if (!!!refoundDateValue) {
            dispatch(setResponse("Specificare la data di liquidazione", 3))
            return
        }

        e.preventDefault()
        toggleHideDialogPay()
    }
    const [hideDialogPay, { toggle: toggleHideDialogPay }] = useBoolean(true);
    const dialogContentPropsPay = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedere alla messa in liquidazione delle pratiche?',
    };
    const onExecutePay = (e: any) => {
        toggleHideDialogPay()
        dispatch(lock())
        dispatch(gotoPaid(args))
            .then((response) => {
                dispatchMessage(response)
                return response
            })
            .then((response) => {
                if (response.payload.response.level === 4)
                    dispatchPayReload(response)

                return response
            })
            .then(() => setPaid(true))
            .then(() => setDownloadable(true))
            .then(() => dispatch(unlock()))
    }
    const onClickDownloadBalance = (e: any) => {
        e.preventDefault()
        toggleHideDialogDownloadBalance()
    }
    const [hideDialogDownloadBalance, { toggle: toggleHideDialogDownloadBalance }] = useBoolean(true);
    const dialogContentPropsDownloadBalance = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedere al download dell\'estratto bancario?',
    };
    const onExecuteDownloadBalance = (e: any) => {
        toggleHideDialogDownloadBalance()
        dispatch(lock())
        dispatch(downloadBalance(args))
            .then(() => dispatchDownloadBalanceReload())
            .then(() => setPaid(true))
            .then(() => setDownloadable(false))
            .then(() => dispatch(setModality("filter")))
            .then(() => stateIndirect.onInit(stateIndirect.name, "7", false))
            .then(() => dispatch(setModality("result")))
            .then(() => dispatch(unlock()))
    }

    const onExecuteDelete = (e: any, id: any) => {
        dispatch(lock())
        datagridOperation.delete({ form: { id: id } })
            .then((response) => {
                dispatchMessage(response)
                return response
            })
            .then((response) => {
                if (response.payload.response.level === 4)
                    dispatchPayReload(response)

                return response
            })
            .then(() => setPaid(true))
            .then(() => setDownloadable(true))
            .then(() => dispatch(unlock()))
    }

    const onRefoundDateChange = (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => {
        if (value)
            setRefoundDateValue(new Date(value))
        else
            setRefoundDateValue(undefined)
    }

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4,
                marginTop: 4
            }
        }
    }

    const canPay = !isPaid && page?.filter?.values?.indirectManagementStateID?.value === '6' && page.result?.state?.data.length > 0
    const canDownload =
        (isDownloadable || page?.filter?.values?.indirectManagementStateID?.value === '7' || ((!!page?.filter?.values?.refoundFromDate?.value || !!page?.filter?.values?.refoundFromDate?.value) && page?.filter?.values?.indirectManagementStateID?.value === '8')) &&
        page.result?.state?.data.length > 0

    useEffect(() => {
        if (modality === 'filter') {
            setPaid(false)
            setDownloadable(false)
        }

    }, [modality])

    return <>
        <Content title="Situazione Contabile Indiretta" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...codeIndirect} className="ms-lg3 ms-xl2" />
                            <Text {...codePolicy} className="ms-lg3 ms-xl2" />
                            <ComboBox {...stateIndirect} className="ms-lg3 ms-xl2" />
                        </Row>
                        {!isInsured &&
                            <Row>
                                <SearchBox {...insured} className="ms-sm6" />
                            </Row>
                        }
                        <Row>
                            <SearchBox {...plan} className="ms-sm6" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...createFromDate, className: "ms-sm2" }} dateMax={{ ...createToDate, className: "ms-sm2" }} />
                            <DateRange dateMin={{ ...updateFromDate, className: "ms-sm2" }} dateMax={{ ...updateToDate, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...refoundFromDate, className: "ms-sm2" }} dateMax={{ ...refoundToDate, className: "ms-sm2" }} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        {(canPay || canDownload) && <>
                            <Row>
                                {canPay && <Text {...refoundDate} className="ms-sm2 padding--bottom--16" type='calendar' onChange={onRefoundDateChange} dateRange={{ minDate: (new Date()).toDateString() }} />}
                                <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 18 }}>
                                    {canPay && <>
                                        <PrimaryButton {...ButtonProps} text='imposta data liquidazione' onClick={onClickPay} disabled={_isLocked} />
                                        <Dialog hidden={hideDialogPay} onDismiss={toggleHideDialogPay} dialogContentProps={dialogContentPropsPay} modalProps={modalProps} >
                                            <DialogFooter>
                                                <PrimaryButton onClick={onExecutePay} text='Procedi' disabled={_isLocked} />
                                                <DefaultButton onClick={toggleHideDialogPay} text="Annulla" disabled={_isLocked} />
                                            </DialogFooter>
                                        </Dialog>
                                    </>}
                                    {canDownload && <>
                                        <PrimaryButton {...ButtonProps} className="margin--bottom--16" text='estratto bancario' onClick={onClickDownloadBalance} disabled={_isLocked} />
                                        <Dialog hidden={hideDialogDownloadBalance} onDismiss={toggleHideDialogDownloadBalance} dialogContentProps={dialogContentPropsDownloadBalance} modalProps={modalProps} >
                                            <DialogFooter>
                                                <PrimaryButton onClick={onExecuteDownloadBalance} text='Download' disabled={_isLocked} />
                                                <DefaultButton onClick={toggleHideDialogDownloadBalance} text="Annulla" disabled={_isLocked} />
                                            </DialogFooter>
                                        </Dialog>
                                    </>}
                                </div>
                            </Row>
                        </>}
                        <IndirectManagementResultList controller={controller} items={page.result?.state?.data} criteria={form} onDelete={onExecuteDelete} />
                    </Section>
                </Grid>
            </Result>
        </Content>
    </>
};
