import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { unlock } from '../operation/lockSlice';

export default (props: { controller: string, items: any, isCompany?: boolean }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        if (!props.isCompany) {
            dispatch(setOperation("read", props.controller, { index: data }))
        } else {
            dispatch(unlock())
        }
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={200} align='left' />
                <DataColumn kid='column3' name='Data Fine' fieldName='endDate' minWidth={90} maxWidth={90} align='center' />
                <DataColumn kid='column4' name='Gestione' fieldName='leap_PlanManagementID' minWidth={110} maxWidth={110} align='left' />
                <DataColumn kid='column5' name='Piano Sanitario' fieldName='leap_PlanID' minWidth={110} maxWidth={110} align='left' />
                <DataColumn kid='column6' name='Attivo' fieldName='leap_PlanStateID' minWidth={50} maxWidth={50} align='center' type='icon' />
                <DataColumn kid='column7' name='Pazienti Attivi' fieldName='activePatients' minWidth={100} maxWidth={100} align='right' />
            </DataList>
        </>
    );
}