import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={150} align='left' />
                <DataColumn kid='column2' name='Nome' fieldName='firstname' minWidth={250} align='left' />
                <DataColumn kid='column3' name='Cognome' fieldName='lastname' minWidth={250} align='left' />
                <DataColumn kid='column4' name='Codice Fiscale' fieldName='fiscalcode' minWidth={250} align='left' />
                <DataColumn kid='column4' name='Email' fieldName='email' minWidth={250} align='left' />
            </DataList>
        </>
    );
}
