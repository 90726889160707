import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useLocation } from 'react-router';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from '../app/store';
import { client } from '../api/client';

import { DefaultButton, IButtonProps, Icon, Pivot, PivotItem, PrimaryButton, Rating, RatingSize, Text as Text2, DialogFooter, Dialog, DialogType, Image, ImageFit } from '@fluentui/react';

import { unitTheme } from '../components/themes/Fonts';
import { isLocked, lock, unlock } from '../features/operation/lockSlice';

import Text from '../components/controls/Text';
import Form from './Form';
import Theme from '../components/themes/Theme';

export const evaluateGuid = createAsyncThunk(
    'item/EvaluateGuid',
    async (args: { guid: string | undefined }) => {
        const response = await client.post("Answer", { form: { guid: args.guid } }, 'EvaluateGuid', undefined)
        return response
    }
)

export const retrieveFeedback = createAsyncThunk(
    'item/RetrieveFeedback',
    async (args: { guid: string | undefined }) => {
        const response = await client.post("Answer", { form: { guid: args.guid } }, 'RetrieveFeedback', undefined)
        return response
    }
)

export const answerFeedback = createAsyncThunk(
    'item/AnswerFeedback',
    async (args: { guid: string | undefined, answers: Question[] | undefined }) => {
        const response = await client.post("Answer", { form: { guid: args.guid, answers: args.answers } }, 'AnswerFeedback', undefined)
        return response
    }
)

type Feedback = {
    title: string
    forms: Form[]
}
type Form = {
    title: string
    questions: Question[]
}
type Question = {
    id: number
    text: string
    type: 1 | 2
    value?: string
}

const ButtonProps: IButtonProps = {
    styles: {
        root: {
            marginLeft: 4,
            marginRight: 4
        }
    }
}

export default () => {

    const dispatch: AppDispatch = useDispatch();

    const _isLocked = useSelector(isLocked)

    const [state, setState] = React.useState<"loading" | "verified" | "unverified">("loading")
    const [selectedKey, setSelectedKey] = React.useState(0);
    const [feedback, setFeedback] = React.useState<Feedback>()

    const formLength = feedback?.forms?.length ?? 1;
    const search = useLocation()?.search;
    const guid = new URLSearchParams(search)?.get('g');

    const DefaultButtonOnClick = (value: number) => {
        setSelectedKey((selectedKey + value) % formLength);
    };

    React.useEffect(() => {
        if (!!guid) {
            dispatch(evaluateGuid({ guid }))
                .then((response) => {
                    setState(response.payload.data ? "verified" : "unverified")
                });
        }
    }, [guid])

    React.useEffect(() => {
        if (state === "verified" && !!guid) {
            dispatch(retrieveFeedback({ guid }))
                .then((response) => {
                    setFeedback(response.payload.data as Feedback)
                });
        }
    }, [state])

    const send = () => {
        dispatch(lock())
        const answers = feedback?.forms.flatMap(f => f.questions);
        dispatch(answerFeedback({ guid: guid ?? undefined, answers }))
            .then(() => toggleHideDialog())
            .finally(() => dispatch(unlock()));
    }

    const onDismiss = () => {
        window.location.href = "/"
    }

    const dialogStyles = { main: { maxWidth: 600 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Feedback inviato',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Grazie per il Feedback, questo permetterà di migliorare costantemente il nostro servizio!',
    };

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const MainContainerStyle: React.CSSProperties = {
        backgroundColor: "#fff",
        border: "1px solid #d0d0d0",
        margin: "30px auto 10px",
        padding: "10px 20px 0",
        maxWidth: 800,
        flex: 1
    }

    const FooterContainerStyle: React.CSSProperties = {
        backgroundColor: "#fff",
        border: "1px solid #d0d0d0",
        margin: "10px auto 10px",
        padding: "15px 20px",
        maxHeight: 400,
        maxWidth: 800,
        flex: 1,
        display: "flex",
        justifyContent: "end"
    }

    switch (state) {
        case "loading":
            return <>
                <div style={{ backgroundColor: "#fff", borderRadius: 4, marginTop: 40, padding: "0 20px" }}>
                    <Image
                        src="/assets/media/images/odontonetwork-oral-health-care-management-logo-vector.png"
                        alt="Odontonetwork Oral Health Care Management"
                        imageFit={ImageFit.centerContain}
                        height="60vh"
                        style={{
                            width: "85%",
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }}
                        draggable={false}
                    />
                </div>
            </>
        case "unverified":
            return <>
                <div style={{ ...FooterContainerStyle, flexDirection: "column", width: 600, marginBottom: "5vh", marginTop: "8vh" }}>
                    <div style={{ margin: "12px auto 18px", textAlign: "center" }}>
                        <span style={{ fontSize: 18, fontWeight: 500, lineHeight: "24px"  }}>Testo codice già usato / non valido</span>
                    </div>
                    <div style={{ textAlign: "end" }}>
                        <PrimaryButton {...ButtonProps} text='ok' onClick={onDismiss} />
                    </div>
                </div>
            </>
        case "verified":
            return (
                <>
                    <div style={{ ...MainContainerStyle, margin: "15px auto 0" }}>
                        <div style={{ textAlign: "center" }}>
                            <Text2 theme={unitTheme} style={{ color: Theme.fluent.palette.themeDarkAlt, fontSize: 34, fontWeight: 600, lineHeight: "40px", padding: "6px 0 10px" }}>{feedback?.title} {selectedKey > 0 ? "(" + (selectedKey + 1) + " di " + formLength + ")" : ""}</Text2 >
                        </div>
                        <Pivot className="ms-Pivot-Feedback" selectedKey={String(selectedKey)} >
                            {feedback?.forms?.map(form => {
                                return (
                                    <PivotItem headerText={formLength > 1 ? form?.title : ""} headerButtonProps={{ style: { height: formLength > 1 ? undefined : 0 } }} style={{ marginTop: 10 }} itemKey={String(feedback?.forms?.indexOf(form))}>
                                        <div style={{}}>
                                            {form?.questions.map((question, index) => {
                                                const label = (index + 1) + ". " + question?.text.charAt(0).toUpperCase() + question?.text.slice(1);

                                                return <>
                                                    <div style={{ marginBottom: 24, textAlign: "justify" }}>
                                                        {question?.type === 1 ?
                                                            <>
                                                                <span style={{ marginBottom: 14 }}>{label}</span>
                                                                <Rating
                                                                    ariaLabelFormat="{0} of {1} stars"
                                                                    min={0} max={5} size={RatingSize.Large}
                                                                    styles={{ root: { marginTop: 4, textAlign: "center" }, ratingStarFront: { color: "#FF8200" }, ratingStarIsLarge: { fontSize: 24, lineHeight: 24 } }}
                                                                    rating={Number(feedback?.forms?.find(f => f.questions?.find(q => q.id === question.id))?.questions?.find(q => q.id === question.id)?.value ?? 0)}
                                                                    onChange={(_, ratingValue) => {
                                                                        setFeedback({
                                                                            ...feedback,
                                                                            forms: feedback?.forms.map(f => {
                                                                                return { ...f, questions: f?.questions.map(q => { return q.id === question.id ? { ...q, value: ratingValue?.toString() } as Question : q }) } as Form
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </> :
                                                            <>
                                                                <span style={{ marginBottom: 14 }}>{label}</span>
                                                                <Text
                                                                    className="ms-sm12"
                                                                    label=""
                                                                    name=""
                                                                    type="multiline"
                                                                    rows={2}
                                                                    value={feedback?.forms?.find(f => f.questions?.find(q => q.id === question.id))?.questions?.find(q => q.id === question.id)?.value}
                                                                    onChange={(_n, value, _) => {
                                                                        setFeedback({
                                                                            ...feedback,
                                                                            forms: feedback?.forms.map(f => {
                                                                                return { ...f, questions: f?.questions?.map(q => { return q.id === question.id ? { ...q, value: value?.toString() } as Question : q }) } as Form
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                    </PivotItem>
                                );
                            })}
                        </Pivot>
                    </div>
                    <div style={FooterContainerStyle}>
                        {selectedKey !== 0 && <>
                            <DefaultButton onClick={() => DefaultButtonOnClick(-1)}>
                                <Icon iconName="Back" />&nbsp;Indietro
                            </DefaultButton>
                        </>}
                        <div style={{ marginLeft: 10 }} />
                        {selectedKey !== formLength - 1 && <>
                            <DefaultButton onClick={() => DefaultButtonOnClick(+1)}>
                                Avanti &nbsp; <Icon iconName="Forward" />
                            </DefaultButton>
                        </>}
                        {selectedKey === formLength - 1 && <>
                            <PrimaryButton {...ButtonProps} text='invia feedback' onClick={send} disabled={_isLocked} />
                        </>}
                    </div>
                    <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} isBlocking>
                        <DialogFooter>
                            <DefaultButton onClick={onDismiss} text="Chiudi" />
                        </DialogFooter>
                    </Dialog>
                </>
            );
        default:
            return <></>
    }
}
