import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';

import { AppDispatch } from '../app/store';

import { setFormData, itemData } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { setResponse, resetResponse } from '../features/response/responseSlice';
import { setOperation } from '../features/operation/operationSlice';

import { client } from '../api/client';

import Theme from '../components/themes/Theme';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Separator from '../components/controls/grid/Separator';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import ComboBox from '../components/controls/ComboBox';
import SearchBox from '../components/controls/SearchBox';
import MultiBox from '../components/controls/MultiBox';
import DateRange from '../components/controls/DateRange';
import { PrimaryButton, DialogFooter, DefaultButton, Modal, mergeStyleSets, IconButton, IIconProps, getTheme, FontSizes, FontWeights, Label } from '@fluentui/react';

import DiscountModal from '../features/plan/DiscountModal';
import PurchaseModal from '../features/plan/PlanPurchaseModal';
import DiscountModalEdit from '../features/plan/DiscountModalEdit';
import DiscountsList from '../features/plan/DiscountsList';
import PeriodList from '../features/plan/PeriodList';
import PurchaseList from '../features/plan/PlanPurchaseList';
import FeeWaiversList from '../features/plan/FeeWaiversList';
import FeeWaiversModal from '../features/plan/FeeWaiversModal';
import PeriodModal from '../features/plan/PeriodModal';
import FeeWaiversModalEdit from '../features/plan/FeeWaiversModalEdit';
import HealthServiceNotDeliverList from '../features/plan/HealthServiceNotDeliverList';
import HealthServiceNotDeliverModal from '../features/plan/HealthServiceNotDeliverModal';
import HealthServiceNotDeliverModalEdit from '../features/plan/HealthServiceNotDeliverModalEdit';
import AgendaPlanHealthList from '../features/plan/AgendaPlanHealthList';
import AgendaPlanHealthServiceModal from '../features/plan/AgendaPlanHealthServiceModal';
import AgendaPlanHealthServiceModalEdit from '../features/plan/AgendaPlanHealthServiceModalEdit';
import HealthServicesAuthorizationList from '../features/plan/HealthServicesAuthorizationList';
import HealthservicesAuthorizationModal from '../features/plan/HealthservicesAuthorizationModal';
import HealthServicesNotMatchList from '../features/plan/HealthServicesNotMatchList';
import HealthservicesNotMatchModal from '../features/plan/HealthservicesNotMatchModal';
import InsuredList from '../features/plan/InsuredList';
import InsuredModal from '../features/plan/InsuredModal';
import InsuredModalEdit from '../features/plan/InsuredModalEdit';
import PlafondModal from '../features/plan/PlafondModal';
import PlafondModalEdit from '../features/plan/PlafondModalEdit';
import PlafondsList from '../features/plan/PlafondsList';
import PlanFamilyList from '../features/plan/PlanFamilyList';
import PlanFamilyModal from '../features/plan/PlanFamilyModal';
import PlanResultList from '../features/plan/PlanResultList';
import PlanFileModal from '../features/plan/PlanFileModal';
import PlanFileList from '../features/plan/PlanFileList';

export const reactivatePlan = createAsyncThunk(
    'item/reactivatePlan',
    async (args: { id: string, newEndDate: any, token: string | undefined }) => {
        const response = await client.post("Plan", {
            form:
            {
                id: args.id,
                newEndDate: args.newEndDate,
            }
        }, 'Reactivate', args.token)
        return response
    }
)

export default () => {
    const dispatch: AppDispatch = useDispatch();

    const controller = "Plan";
    const page = useLeapState(controller)

    const credential = useSelector(security)?.credential
    const iForm = useSelector(itemData)

    const planFileController = "PlanFile"
    const insuredModalController = "InsuredModal";
    const discountModalController = "DiscountModal";
    const plafondModalController = "PlafondModal";
    const planFamilyModalController = "PlanFamilyModal";
    const feeWaiversModalController = "FeeWaiversModal";
    const notCompatibleHealthServicesModalController = "PlanNotCompatibleHealthServicesModal";
    const notUsableHealthServicesController = "PlanNotUsableHealthServices";
    const planHealthServicesController = "AgendaPlanHealthServices";
    const authorizationHealthServiceController = "PlanAuthorizationHealthService";
    const PlanPeriodModalController = "PlanPeriodModal";
    const PurchaseModalController = "PlanPurchaseModal";

    const onExecuteReactivate = (e: any) => {
        hideModal()
        dispatch(reactivatePlan(
            {
                id: iForm.find(i => i.key === controller)?.value.data.id,
                newEndDate: newEndDateValue,
                token: credential.token
            }))
            .then((response: any) => {
                dispatch(setOperation("reload", controller))
                return response
            }).then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
    }

    function showAge(item: string, state: string) {
        if (item) {
            if (item !== "1") {
                return true;
            }
        }
        else if (state !== "1") {
            return true;
        }
        return false;
    }

    const ageBetweenSelected = (item: string, state: string) => {

        if (item) {
            if (item === "4") {
                return true;
            }
        }
        else if (state === "4") {
            return true;
        }
        return false;
    }

    function minage_required(item: string, state: string, showed: boolean) {
        if (!showed) {
            return false;
        }
        if (item) {
            if (item === "2" || item === "4") {
                return true;
            }
        }
        else if (state === "2" || state === "4") {
            return true;
        }
        return false;
    }

    function maxage_required(item: string, state: string, showed: boolean) {
        if (!showed) {
            return false;
        }
        if (item) {
            if (item === "3" || item === "4") {
                return true;
            }
        }
        else if (state === "3" || state === "4") {
            return true;
        }
        return false;
    }

    const welfarePlan = (item: string, state: string) => {
        if (item) {
            if (item === "2") {
                return true;
            }
        }
        else if (state === "2") {
            return true;
        }
        return false;
    }
    const show_family = (item: string, state: string) => {
        if (item) {
            // item has value
            if (item === "1") {
                //item has value and is 1
                return true;
            }
        }
        else if (state === "1") {
            //state is 1 and item is undefined
            return true;
        }
        return false;
    }
    const eval_family = (item: string, state: string, visible: boolean) => {
        if (!visible) {
            return false
        }

        if (item) {
            // item has value
            if (item === "1") {
                //item has value and is 1
                return true;
            }
        }
        else if (state === "1") {
            //state is 1 and item is undefined
            return true;
        }
        return false;
    }

    /*
    logica per welfare
    */
    const management_item: string = page.item?.values?.leap_PlanManagementID?.value;
    const management_state: string = page?.item?.state?.data?.leap_PlanManagementID;
    const isWelfare: boolean = welfarePlan(management_item, management_state);
    const isIndirect: boolean = page?.item?.state?.data?.leap_RefundManagementID === "1" || page?.item?.state?.data?.leap_RefundManagementID === "3"

    /*
    logica per fondo
    */
    const family_item: string = page.item?.values?.leap_PlanFamilyID?.value;
    const family_state: string = page?.item?.state?.data?.leap_PlanFamilyID;

    const display_family: boolean = show_family(management_item, management_state);
    const family_required: boolean = eval_family(family_item, family_state, display_family);

    const code = useLeapData("code", "codice", page)
    const description = useLeapData("description", "descrizione", page)
    const leap_PlanManagementID = useLeapData("leap_PlanManagementID", "tipo gestione", page, "planManagementOption")
    const acronym = useLeapData("acronym", "acronimo", page)
    const leap_PlanStateID = useLeapData("leap_PlanStateID", "attivo", page, "planStateOption")
    const leap_PlanID = useLeapData("leap_PlanID", "tipo piano sanitario", page, "planLeapOption")
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const leap_WelfarePlanRechargeModeID = useLeapData("leap_WelfarePlanRechargeModeID", "modalità di ricarico", page, "welfarePlanRechargeModeOption")
    const odQuote = useLeapData("odQuote", "quota OD", page)
    const welfareQuote = useLeapData("welfareQuote", "quota welfare", page)
    const leap_WelfarePlanPrecisionID = useLeapData("leap_WelfarePlanPrecisionID", "precisione", page, "welfarePlanPrecisionOption")
    const leap_WelfarePlanInvoiceAmountID = useLeapData("leap_WelfarePlanInvoiceAmountID", "importo fatturazione", page, "welfarePlanInvoiceAmountOption")
    const leap_WelfarePlanRateTableSellID = useLeapData("leap_WelfarePlanRateTableSellID", "tariffario di vendita", page, "welfarePlanRateTableSellOption")
    const leap_WelfarePlanRateTableRefundID = useLeapData("leap_WelfarePlanRateTableRefundID", "tariffario di rimborso", page, "welfarePlanRateTableRefundOption")
    const leap_WelfarePlanVATMode = useLeapData("leap_WelfarePlanVATModeID", "calcolo IVA", page, "welfarePlanVATMode")
    const leap_PlanRateTableChoiceID = useLeapData("leap_PlanRateTableChoiceID", "scelta tariffa", page, "planRateTableChoiceOption")
    const companyIDFilter = useLeapData("companyIDFilter", "compagnia", page, "companiesfilter")
    const companyID = useLeapData("companyID", "compagnia", page, "companyactives")
    const activePatients = useLeapData("activePatients", "pazienti attivi", page)
    const saleRateTableID = useLeapData("saleRateTableID", "tariffario di vendita standard", page, "saleRateTables")
    const indirectRateTableID = useLeapData("indirectRateTableID", "tariffario di rimborso indiretta", page, "indirectRateTables")
    const maximalRateTableID = useLeapData("maximalRateTableID", isWelfare ? "tariffario di rimborso standard" : "tariffario massimale", page, "maximalRateTables")
    const rateTableID = useLeapData("rateTableID", "tariffario piano", page, "ratetables")
    const leap_PlanOnlineAccessID = useLeapData("leap_PlanOnlineAccessID", "accesso online", page, "planOnlineAccessOption")
    const leap_PlanFamilyID = useLeapData("leap_PlanFamilyID", "nucleo familiare", page, "planFamilyOption")
    const familyCoreKinshipDegreeID = useLeapData("familyCoreKinshipDegreeID", "tipo parentela", page, "familyCoreKinshipDegreesOption")
    const leap_PlanFamilyInsertID = useLeapData("leap_PlanFamilyInsertID", "inserimento nucleo familiare", page, "planFamilyInsertOption")
    const leap_reservedAmountsID = useLeapData("leap_reservedAmountsID", "importi riservati", page, "planReservedAmountOption")
    const leap_refundFeeID = useLeapData("leap_refundFeeID", "fatturazione quote rimborso", page, "planRefundFeeOption")
    const maxEstimate = useLeapData("maxEstimate", "importo max prev. automatico", page)
    const leap_PlanCoreTypeID = useLeapData("leap_PlanCoreTypeID", "tipo piano nucleo", page, "planCoreTypeOption")
    const leap_PlanCoreHeadID = useLeapData("leap_PlanCoreHeadID", "piano sanitario capo nucleo", page, "planCoreHeadOption")
    const leap_MultiplanCompatibleID = useLeapData("leap_MultiplanCompatibleID", "compatibile multipiano", page, "planMultiplanOption")
    const leap_RefundManagementID = useLeapData("leap_RefundManagementID", "gestione rimborso", page, "planRefundManagement")
    const leap_PlanAgeID = useLeapData("leap_PlanAgeID", "età", page, "planAges")
    const minAge = useLeapData("minAge", "età minima", page)
    const maxAge = useLeapData("maxAge", "età massima", page)

    //periodo
    const periodEndDateValue: string = page?.item?.state?.data?.periodEndDate
    const periodStartDateValue: string = page?.item?.state?.data?.periodStartDate

    const performance_empty = page?.item?.state?.lists?.Performance === null || page?.item?.state?.lists?.Performance?.length === 0
    const discounts_empty = page?.item?.state?.lists?.Discounts === null || page?.item?.state?.lists?.Discounts?.length === 0
    const plafonds_empty = page?.item?.state?.lists?.Plafonds === null || page?.item?.state?.lists?.Plafonds?.length === 0
    const incompatiblePerformance_empty = page?.item?.state?.lists?.IncompatiblePerformance === null || page?.item?.state?.lists?.IncompatiblePerformance?.length === 0
    const feeWaivers_empty = page?.item?.state?.lists?.FeeWaivers === null || page?.item?.state?.lists?.FeeWaivers?.length === 0
    const automaticAuthorization_empty = page?.item?.state?.lists?.AutomaticAuthorization === null || page?.item?.state?.lists?.AutomaticAuthorization?.length === 0
    const planFamily_empty = page?.item?.state?.lists?.PlanFamily === null || page?.item?.state?.lists?.PlanFamily?.length === 0

    const newEndDate = useLeapData("newEndDate", "nuova fine validità", page)
    const StartDateValue: string = page?.item?.state?.data?.startDate
    const EndDateValue: string = page?.item?.state?.data?.endDate

    const isSellingWelfarePlanInvoiceAmount = !!page?.item?.values?.leap_WelfarePlanInvoiceAmountID?.value ? page?.item?.values?.leap_WelfarePlanInvoiceAmountID?.value == "2" : page?.item?.state?.leap_WelfarePlanInvoiceAmountID == "2"

    const newEndDateValue: string = page.item?.values?.newEndDate?.value

    var today = new Date()
    var tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    var tomorrowString = tomorrow.toLocaleDateString("it-IT")

    //eta
    const maxAgeValue = page.item?.values?.maxAge?.value
    const minAgeValue = page.item?.values?.minAge?.value

    const ageOptionItem: string = page.item?.values?.leap_PlanAgeID?.value;
    const ageOptionState: string = page?.item?.state?.data?.leap_PlanAgeID;
    const ageShowed: boolean = showAge(ageOptionItem, ageOptionState);
    const ageBetween: boolean = ageBetweenSelected(ageOptionItem, ageOptionState);

    const minAgeRequired: boolean = minage_required(ageOptionItem, ageOptionState, ageShowed);
    const maxAgeRequired: boolean = maxage_required(ageOptionItem, ageOptionState, ageShowed);


    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                //borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const isExpired = () => {
        if (page?.item?.state?.data?.endDate === undefined || page?.item?.state?.data?.endDate === null) {
            return false;
        }
        const todaysDate = new Date().setHours(0, 0, 0, 0);
        const endDate = new Date(page?.item?.state?.data?.endDate).setHours(0, 0, 0, 0);

        if (endDate >= todaysDate) {
            return false;
        }
        return true;
    }

    const expired: boolean = isExpired();
    const roles = useSecurityRoles()
    const isONHCAccount: boolean = roles.isONHCAccount
    const isCompany: boolean = roles.isCompany

    return <>
        <Content title="Piano Sanitario" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm2" maxlength={25} />
                            <Option {...leap_PlanStateID} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Text {...description} className="ms-sm5" maxlength={50} />
                            <SearchBox {...companyIDFilter} className="ms-sm5" />
                        </Row>
                        <Row>
                            <ComboBox {...leap_PlanID} className="ms-sm2" />
                            <ComboBox {...leap_PlanManagementID} className="ms-sm2" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <PlanResultList controller={controller} items={page.result?.state?.data} isCompany={isCompany} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" type="number" readOnly={page.modality !== 'new'} precision={{ integer: 3, decimal: 0, min: 1, max: 999 }} maxlength={3} />
                            <Text {...acronym} className="ms-sm4" required={page.modality === "new"} maxlength={50} readOnly={page.modality !== "new" && page?.item?.state?.data?.acronym !== ""
                                && page?.item?.state?.data?.acronym !== undefined
                                && page?.item?.state?.data?.acronym !== null} />
                        </Row>
                        <Row>
                            <Text {...activePatients} className="ms-sm2" readOnly hidden={page.modality !== "item"} />
                            <Option {...leap_PlanStateID} className="ms-sm2" type="horizontal" required disabled={expired} />
                        </Row>
                        <Row>
                            <Text {...description} className="ms-sm5" required maxlength={50} />
                            <SearchBox {...companyID} className="ms-sm5" required />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...startDate, className: "ms-sm2", required: page.modality === "new", readOnly: page.modality === "item" }} dateMax={{ ...endDate, className: "ms-sm2", readOnly: expired }} />

                            {false && page.modality === "item" && expired && (<>
                                <PrimaryButton text="Riattiva Piano" onClick={showModal} style={{ marginTop: "20px" }} />
                                <Modal titleAriaId={"Riattiva Piano"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                                    <div className={contentStyles.header}>
                                        <span id={"Riattiva Piano"}>{"Riattiva Piano"}</span>
                                        <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Chiudi modale" onClick={hideModal} />
                                    </div>
                                    <div className={contentStyles.body}>
                                        <>
                                            <Grid>
                                                <Section>
                                                    <Row>
                                                        <Text {...code} className="ms-sm2" readOnly />
                                                        <Text {...acronym} className="ms-sm4" readOnly />
                                                    </Row>
                                                    <Row>
                                                        <Text {...description} className="ms-sm5" readOnly />
                                                    </Row>
                                                    <Row>
                                                        <Text readOnly label="data di inizio validità" value={StartDateValue} className="ms-sm2" type="calendar" />
                                                        <Separator className="ms-sm1"></Separator>
                                                        <Text readOnly label="data di fine validità corrente" value={EndDateValue} className="ms-sm2" type="calendar" />
                                                        <Text {...newEndDate} className="ms-sm2" dateRange={{ minDate: tomorrowString }} type="calendar" />
                                                    </Row>
                                                    <Row>
                                                        {newEndDateValue ?
                                                            <Label style={{ marginTop: "25px" }}><b>Il piano scadrà il {new Date(newEndDateValue).toLocaleDateString("it-IT")}</b></Label>
                                                            :
                                                            <Label style={{ marginTop: "25px" }}><b>Il piano non scadrà</b></Label>
                                                        }
                                                    </Row>
                                                </Section>
                                            </Grid>
                                        </>
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteReactivate} text="Rinnova" />
                                            <DefaultButton onClick={onCancel} text="Annulla" />
                                        </DialogFooter>
                                    </div>
                                </Modal>
                            </>)}
                        </Row>
                    </Section>

                    {(page.modality === "item" && !isWelfare) &&
                        <Section modalwidth="600px" title="periodo di validità" toolbar="grid" modal={<PeriodModal state={page} currentPeriodStartDate={periodStartDateValue} currentPeriodEndDate={periodEndDateValue} controller={PlanPeriodModalController} />} controller={PlanPeriodModalController} modaltitle="Modifica Periodo di Validità" state={page} >
                            <Row>
                                <PeriodList items={page.item.state?.lists.planPeriodList} />
                            </Row>
                        </Section>
                    }
                    <Section title="Età">
                        <Row>
                            <ComboBox {...leap_PlanAgeID} className="ms-sm2 ms-xxl1" required disabled={page.modality !== 'new'} />
                            <Text {...minAge} className="ms-sm2 ms-xxl1" type="number" hidden={!(ageShowed && minAgeRequired)} precision={{ integer: 3, decimal: 0, min: 0, max: maxAgeValue && ageBetween ? Number(maxAgeValue) : 120 }} required={ageShowed && minAgeRequired} readOnly={page.modality !== 'new'} />
                            <Text {...maxAge} className="ms-sm2 ms-xxl1" type="number" hidden={!(ageShowed && maxAgeRequired)} precision={{ integer: 3, decimal: 0, min: minAgeValue && ageBetween ? Number(minAgeValue) : 0 }} required={ageShowed && maxAgeRequired} readOnly={page.modality !== 'new'} />
                        </Row>
                    </Section>
                    <Section title="configurazione">
                        <Row>
                            <Option {...leap_PlanOnlineAccessID} className="ms-sm3" type="horizontal" label="accesso online" />
                            <Separator className="ms-sm1"></Separator>
                            <Option {...leap_PlanID} className="ms-sm2" type="horizontal" disabled={(isWelfare)} required />
                            <Option {...leap_PlanManagementID} className="ms-sm2 ms-smPush1" type="horizontal" disabled={page.modality !== "new"} required
                                onChange={
                                    (name: any, value: any, required: any) => {
                                        page.onchange(name, value, required)
                                        if (value === "2") {
                                            dispatch(setFormData(controller, { name: "leap_PlanID", value: "2" }))
                                        }
                                    }
                                }
                            />
                        </Row>
                        <Row>
                            <Option {...leap_PlanRateTableChoiceID} className="ms-sm3" type="horizontal" required />
                            <Separator className="ms-sm1"></Separator>
                            <Option {...leap_MultiplanCompatibleID} className="ms-sm3" type="horizontal" required />
                        </Row>
                        <Row>
                            <SearchBox {...maximalRateTableID} className="ms-sm4" required={isWelfare && !isSellingWelfarePlanInvoiceAmount} />
                            <SearchBox {...rateTableID} className="ms-sm4" />
                            <SearchBox {...saleRateTableID} className="ms-sm4" hidden={!isWelfare} required={isWelfare && isSellingWelfarePlanInvoiceAmount} />
                        </Row>
                        <Row>
                            <Option {...leap_RefundManagementID} className="ms-sm4" type="horizontal" required />
                            <Option {...leap_refundFeeID} className="ms-sm4" type="horizontal" />
                            <Text {...maxEstimate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2 }} />
                        </Row>
                        <Row>
                            <SearchBox {...indirectRateTableID} className="ms-sm4" hidden={!isIndirect} />
                        </Row>
                    </Section>
                    <Section title="nucleo familiare" hidden={!display_family}>
                        <Row hidden={!display_family}>
                            <Option {...leap_PlanFamilyID} className="ms-sm3" type="horizontal" disabled={page.modality !== "new"} />
                        </Row>
                        <Row hidden={!family_required}>
                            <MultiBox {...familyCoreKinshipDegreeID} required={family_required} className="ms-sm6" titleType="text" />
                        </Row>
                        <Row hidden={!family_required}>
                            <Option {...leap_PlanCoreTypeID} className="ms-sm3" type="horizontal" required={(family_required)} />
                            <Separator className="ms-sm1"></Separator>
                            <Option {...leap_PlanCoreHeadID} className="ms-sm3" type="horizontal" required={(family_required)} />
                            <Option {...leap_PlanFamilyInsertID} className="ms-sm3" type="horizontal" required={(family_required)} />
                        </Row>
                    </Section>
                    <Section title="opzioni di calcolo welfare" hidden={!isWelfare}>
                        <Row hidden={!isWelfare}>
                            <Option {...leap_WelfarePlanRateTableRefundID} className="ms-sm4 ms-xxl3" type="horizontal" required={isWelfare} />
                            <Option {...leap_WelfarePlanRateTableSellID} className="ms-sm4 ms-xxl3" type="horizontal" required={isWelfare} />
                            <Option {...leap_WelfarePlanRechargeModeID} className="ms-sm4 ms-xxl3" type="horizontal" required={isWelfare} />
                            <Option {...leap_WelfarePlanInvoiceAmountID} className="ms-sm4 ms-xxl3" type="horizontal" required={isWelfare} />
                        </Row>
                        <Row hidden={!isWelfare}>
                            <Option {...leap_reservedAmountsID} className="ms-sm4 ms-xxl3" type="horizontal" required={isWelfare} />
                            <Option {...leap_WelfarePlanVATMode} className="ms-sm4 ms-xxl3" type="horizontal" />
                            <Option {...leap_WelfarePlanPrecisionID} className="ms-sm4 ms-xxl3" type="horizontal" hidden />
                        </Row>
                        <Row hidden={!isWelfare}>
                            <Text {...odQuote} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2 }} maxlength={9} hidden />
                            <Text {...welfareQuote} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2 }} required={isWelfare} maxlength={9} />
                        </Row>
                    </Section>
                    {page.modality === "item" && (<>
                        <Section title="elenco assistiti" toolbar="indexed" parentController={controller} modal={<InsuredModal state={page} controller={insuredModalController} />} controller={insuredModalController} modalwidth="600px" modaltitle="Inserisci Copertura" state={page} readOnly={page.item?.values?.leap_PlanStateID?.value === "2"}>
                            <Row>
                                <InsuredList isWelfare={isWelfare} items={page.item.state?.lists.InsuredModal} modal={<InsuredModalEdit isONHCAccount={isONHCAccount} state={page} controller={insuredModalController} />} controller={insuredModalController} modalwidth="40vw" modaltitle="Modifica Copertura" state={page} readOnly={page.item?.values?.leap_PlanStateID?.value === "2"} />
                            </Row>
                        </Section>
                        <Section hidden={roles.isONHCAccount && performance_empty} title="elenco prestazioni di agenda online" toolbar="grid" modal={<AgendaPlanHealthServiceModal state={page} controller={planHealthServicesController} parentController={controller} />} modalwidth="600px" controller={planHealthServicesController} modaltitle="Inserisci prestazione di agenda" state={page} >
                            <Row hidden={roles.isONHCAccount && performance_empty}>
                                <AgendaPlanHealthList items={page.item.state?.lists.AgendaPerformance} modal={<AgendaPlanHealthServiceModalEdit state={page} controller={planHealthServicesController} />} controller={planHealthServicesController} modalwidth="600px" modaltitle="Modifica prestazione di agenda" state={page} />
                            </Row>
                        </Section>
                        <Section hidden={roles.isONHCAccount && performance_empty} title="elenco delle prestazioni non erogabili" toolbar="grid" modal={<HealthServiceNotDeliverModal state={page} controller={notUsableHealthServicesController} parentController={controller} />} modalwidth="600px" controller={notUsableHealthServicesController} modaltitle="Inserisci prestazione non erogabile" state={page} >
                            <Row hidden={roles.isONHCAccount && performance_empty}>
                                <HealthServiceNotDeliverList items={page.item.state?.lists.Performance} modal={<HealthServiceNotDeliverModalEdit state={page} controller={notUsableHealthServicesController} />} controller={notUsableHealthServicesController} modalwidth="600px" modaltitle="Modifica prestazione non erogabile" state={page} />
                            </Row>
                        </Section>
                        {(!isWelfare) &&
                            <Section hidden={roles.isONHCAccount && discounts_empty} title="elenco delle scontistiche" toolbar="grid" modal={<DiscountModal state={page} controller={discountModalController} />} controller={discountModalController} modalwidth="600px" modaltitle="Inserisci Sconto" state={page}  >
                                <Row hidden={roles.isONHCAccount && discounts_empty}>
                                    <DiscountsList items={page.item.state?.lists.Discounts} modal={<DiscountModalEdit state={page} controller={discountModalController} />} controller={discountModalController} modalwidth="600px" modaltitle="Modifica Sconto" state={page} />
                                </Row>
                            </Section>}
                        <Section hidden={roles.isONHCAccount && plafonds_empty} title="elenco dei plafonds" toolbar="grid" modal={<PlafondModal state={page} controller={plafondModalController} />} controller={plafondModalController} modalwidth="600px" modaltitle="Inserisci Plafond" state={page} >
                            <Row hidden={roles.isONHCAccount && plafonds_empty}>
                                <PlafondsList items={page.item.state?.lists.Plafonds} modal={<PlafondModalEdit state={page} controller={plafondModalController} />} controller={plafondModalController} modalwidth="600px" modaltitle="Modifica Plafond" state={page} />
                            </Row>
                        </Section>
                        <Section hidden={roles.isONHCAccount && incompatiblePerformance_empty} title="elenco delle prestazioni non compatibili" toolbar="grid" modal={<HealthservicesNotMatchModal state={page} parentController={controller} controller={notCompatibleHealthServicesModalController} />} modalwidth="600px" controller={notCompatibleHealthServicesModalController} modaltitle="Inserisci Prestazioni non compatibili" state={page} >
                            <Row hidden={roles.isONHCAccount && incompatiblePerformance_empty}>
                                <HealthServicesNotMatchList items={page.item.state?.lists.IncompatiblePerformance} modal={<HealthservicesNotMatchModal state={page} parentController={controller} controller={notCompatibleHealthServicesModalController} />} controller={notCompatibleHealthServicesModalController} modalwidth="600px" modaltitle="Modifica Prestazioni non compatibile" state={page} />
                            </Row>
                        </Section>

                        {(isWelfare) &&
                            <>
                                <Section hidden={roles.isONHCAccount && feeWaivers_empty} title="elenco deroghe quote" toolbar="grid" modal={<FeeWaiversModal state={page} controller={feeWaiversModalController} />} controller={feeWaiversModalController} modalwidth="600px" modaltitle="Inserisci deroga quota" state={page} >
                                    <Row hidden={roles.isONHCAccount && feeWaivers_empty}>
                                        <FeeWaiversList items={page?.item?.state?.lists?.FeeWaivers} modal={<FeeWaiversModalEdit state={page} controller={feeWaiversModalController} />} controller={feeWaiversModalController} modalwidth="600px" modaltitle="Modifica deroga quota" state={page} />
                                    </Row>
                                </Section>
                            </>}

                        <Section hidden={roles.isONHCAccount && automaticAuthorization_empty} title="elenco prestazioni con autorizzazione automatica" toolbar="grid" modal={<HealthservicesAuthorizationModal state={page} controller={authorizationHealthServiceController} parentController={controller} />} modalwidth="600px" controller={authorizationHealthServiceController} modaltitle="Inserisci Prestazione con autorizzazione automatica" state={page} >
                            <Row hidden={roles.isONHCAccount && automaticAuthorization_empty}>
                                <HealthServicesAuthorizationList items={page.item.state?.lists.AutomaticAuthorization} modal={<HealthservicesAuthorizationModal state={page} controller={authorizationHealthServiceController} />} controller={authorizationHealthServiceController} modalwidth="600px" modaltitle="Modifica Prestazione con autorizzazione automatica" state={page} />
                            </Row>
                        </Section>

                        {(!family_required && display_family) &&
                            <>
                                <Section hidden={roles.isONHCAccount && planFamily_empty} title="elenco piani sanitari di nucleo familiare" toolbar="grid" modal={<PlanFamilyModal state={page} controller={planFamilyModalController} />} controller={planFamilyModalController} modalwidth="600px" modaltitle="Inserisci Piano Sanitario Familiare" state={page} >
                                    <Row hidden={roles.isONHCAccount && planFamily_empty}>
                                        <PlanFamilyList items={page.item.state?.lists.PlanFamily} modal={<PlanFamilyModal state={page} controller={planFamilyModalController} />} controller={planFamilyModalController} modalwidth="600px" modaltitle="Modifica Piano Sanitario Familiare" state={page} />
                                    </Row>
                                </Section>
                            </>}

                        {(roles.isSYS || roles.isONHCMaster) && (<>
                            <Section title="documentazione" toolbar="grid" modal={<PlanFileModal state={page} controller={planFileController} />} controller={planFileController} modaltitle="Inserisci un file" state={page} modalwidth="600px">
                                <Row>
                                    <PlanFileList items={page.item.state?.lists.planFile} modal={<PlanFileModal state={page} controller={planFileController} />} controller={planFileController} modaltitle="Modifica un file" modalwidth="600px" state={page} />
                                </Row>
                            </Section>
                        </>)}
                        {(roles.isSYS || roles.isONHCMaster) && (<>
                            <Section modalwidth="800px" title="configurazione modalità di acquisto" toolbar="grid" modal={<PurchaseModal state={page} controller={PurchaseModalController} isFundPlan={!isWelfare} />} controller={PurchaseModalController} modaltitle="modifica configurazione modalità di acquisto" state={page} >
                                <Row>
                                    <PurchaseList items={page.item.state?.lists.planPurchaseList} modal={<PurchaseModal state={page} controller={PurchaseModalController} isFundPlan={!isWelfare} />} modalwidth="800px" controller={PurchaseModalController} state={page} />
                                </Row>
                            </Section>
                        </>)}
                    </>)}
                </Grid>
            </Item>
        </Content>
    </>
};
