import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
}

export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHC: boolean = roles.isONHC;

    return <>
        <DataList {...props} items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
            {!props.readOnly && <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />}
            <DataColumn kid='column1' name='File ID' fieldName='fileID' maxWidth={50} align='center' />
            <DataColumn kid='column2' name='Numero Fattura' fieldName='invoiceNumber' minWidth={100} maxWidth={150} align='center' />
            <DataColumn kid='column4' name='Data Fattura' fieldName='invoiceDate' minWidth={120} maxWidth={150} align='center' />
            <DataColumn kid='column4' name='Importo' fieldName='invoiceImport' minWidth={120} maxWidth={150} align='right' />
            {isONHC && <DataColumn kid='column4' name='Rimborso' fieldName='invoiceRefound' minWidth={120} maxWidth={150} align='right' />}
            <DataColumn kid='column4' name='Data Caricamento' fieldName='uploadDate' minWidth={120} align='center' />
            {!props.readOnly && <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            <DataColumn kid='columnDL' name='' fieldName='' minWidth={10} maxWidth={10} align='center' type='download' />
        </DataList>
    </>

}
