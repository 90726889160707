import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useBoolean, useId } from '@uifabric/react-hooks';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import ComboBox from '../components/controls/ComboBox';
import SearchBox from '../components/controls/SearchBox';
import DateRange from '../components/controls/DateRange';
import GrantModal from '../features/user/GrantModal';
import GrantModalEdit from '../features/user/GrantModalEdit';
import UserResultList from '../features/user/UserResultList';
import UserRoles from '../features/user/UserRoles';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType } from '@fluentui/react';
import { AppDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { itemData } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { setResponse } from '../features/response/responseSlice';
import { setOperation } from '../features/operation/operationSlice';

export const RegeneratePassword = createAsyncThunk(
    'item/RegeneratePassword',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("User", { form: {}, page: args.page }, 'RegeneratePassword', args.token)
        return response
    }
)
export const RegenerateMFA = createAsyncThunk(
    'item/RegenerateMFA',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("User", { form: {}, page: args.page }, 'RegenerateMFA', args.token)
        return response
    }
)

export default () => {
    const controller = "User";
    const grantModalController = "GrantModal";
    const page = useLeapState(controller)

    const id = useLeapData("id", "hidden", page)
    const nickname = useLeapData("nickname", "nickname", page)
    const email = useLeapData("email", "email", page)
    const startDate = useLeapData("startDate", "inizio validità", page)
    const OrganizationTypeID = useLeapData("OrganizationTypeID", "Tipo Organizzazione", page, "organizationTypes")
    const insuredID = useLeapData("insuredID", "assistito", page, "insureds")
    const UIDONHCID = useLeapData("UIDONHCID", "ruolo ONHC", page, "UIDONHCID")
    const UIDSS = useLeapData("UIDSS", "ruolo struttura sanitaria", page, "UIDSS")
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")
    const endDate = useLeapData("endDate", "fine validità", page)

    const OrganizationTypeID_item = page.item?.values?.OrganizationTypeID?.value;
    const OrganizationTypeID_state = page?.item?.state?.data?.OrganizationTypeID;

    const userIsONHC = page?.item?.state?.data?.isONHC;
    const isMFAEnabled = page?.item?.state?.data?.mfaEnabled;

    const ONHC: boolean = (OrganizationTypeID_item === "1" || OrganizationTypeID_state === "1")
    const HealthFacility: boolean = (OrganizationTypeID_item === "2" || OrganizationTypeID_state === "2")
    const Insured: boolean = (OrganizationTypeID_item === "3" || OrganizationTypeID_state === "3")

    const [hideDialogRegeneratePassword, { toggle: toggleHideDialogRegeneratePassword }] = useBoolean(true);
    const [hideDialogRegenerateMFA, { toggle: toggleHideDialogRegenerateMFA }] = useBoolean(true);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const dialogContentPropsRegeneratePassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla rigenerazione della password?',
    };
    const dialogContentPropsRegenerateMFA = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo al reset di MFA per l\'utente corrente?',
    };

    const dispatch: AppDispatch = useDispatch();
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const metadataPage = dItem?.metadata?.page
    const credential = useSelector(security)?.credential

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteRegeneratePassword = () => {
        toggleHideDialogRegeneratePassword()
        dispatch(RegeneratePassword({ token: credential.token, page: metadataPage }))
            .then(dispatchMessage)
            .then(dispatchReload)
    }

    const onExecuteRegenerateMFA = () => {
        toggleHideDialogRegenerateMFA()
        dispatch(RegenerateMFA({ token: credential.token, page: metadataPage }))
            .then(dispatchMessage)
            .then(dispatchReload)
    }

    return <>
        <Content title="User" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...nickname} className="ms-sm4" maxlength={50} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <UserResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <input {...id} type="hidden" />
                            <Text {...nickname} className="ms-sm4" required maxlength={50} readOnly={page.modality !== "new"} />
                            <Text {...email} className="ms-sm8" required={ONHC} maxlength={255} readOnly={page.modality === "item" && !userIsONHC} />
                        </Row>
                        <Row>
                            <Option {...OrganizationTypeID} className="ms-sm5" type="horizontal" hidden={page.modality !== "new"} required={page.modality === "new"} />
                        </Row>
                        <Row hidden={page.modality !== "new"} >
                            <ComboBox {...UIDONHCID} className="ms-sm4" hidden={!ONHC} required={ONHC} />
                        </Row>
                        <Row hidden={page.modality !== "new"} >
                            <ComboBox {...UIDSS} className="ms-sm4" hidden={!HealthFacility} required={HealthFacility} />
                        </Row>
                        <Row hidden={page.modality !== "new"}>
                            <SearchBox {...healthFacilityID} className="ms-sm4" hidden={!HealthFacility} required={HealthFacility} />
                        </Row>
                        <Row hidden={page.modality !== "new"} >
                            <SearchBox {...insuredID} className="ms-sm4" hidden={!Insured} required={Insured} />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2", required: true }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                            {page.modality === "item" && userIsONHC && (
                                <>
                                    <PrimaryButton text="Resetta password" onClick={toggleHideDialogRegeneratePassword} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogRegeneratePassword} onDismiss={toggleHideDialogRegeneratePassword} dialogContentProps={dialogContentPropsRegeneratePassword} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegeneratePassword} text="Resetta password" />
                                            <DefaultButton onClick={toggleHideDialogRegeneratePassword} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                            {page.modality === "item" && isMFAEnabled && (
                                <>
                                    <PrimaryButton text="Resetta MFA" onClick={toggleHideDialogRegenerateMFA} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogRegenerateMFA} onDismiss={toggleHideDialogRegenerateMFA} dialogContentProps={dialogContentPropsRegenerateMFA} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegenerateMFA} text="Resetta MFA" />
                                            <DefaultButton onClick={toggleHideDialogRegenerateMFA} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="Ruoli" toolbar="grid" modal={<GrantModal state={page} controller={grantModalController} />} controller={grantModalController} modalwidth="600px" modaltitle="Inserisci Ruolo" state={page} >
                        <Row>
                            <UserRoles items={page.item.state?.lists.Roles} modal={<GrantModalEdit state={page} controller={grantModalController} />} controller={grantModalController} modalwidth="600px" modaltitle="Modifica Ruolo" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
