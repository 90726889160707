import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branch = useLeapData("branch", "branca", page)
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page)
    const rate = useLeapData("rate", "tariffa", page)
    const maximalRate = useLeapData("maximalRate", "tariffa a massimale", page)
    const dentalElement = useLeapData("dentalElement", "elementi dentali", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...branch} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...healthServiceID} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...rate} className="ms-sm3" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} />
                        <Text {...maximalRate} className="ms-sm3" readOnly type="number"/>
                    </Row>
                    <Row>
                        <Text {...dentalElement} className="ms-sm12" readOnly type="multiline" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
