import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import { useBoolean, useId } from '@uifabric/react-hooks';
import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import Separator from '../components/controls/grid/Separator';
import CompanyResultList from '../features/company/CompanyResultList';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import { setResponse } from '../features/response/responseSlice';
import { itemData } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { setOperation } from '../features/operation/operationSlice';

export const RegeneratePassword = createAsyncThunk(
    'item/RegeneratePassword',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Company", { form: {}, page: args.page }, 'RegeneratePassword', args.token)
        return response
    }
)

export const GenerateUser = createAsyncThunk(
    'item/GenerateUser',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Company", { form: {}, page: args.page }, 'GenerateUser', args.token)
        return response
    }
)

export default () => {
    const roles = useSecurityRoles()
    const isCompany: boolean = roles.isCompany;

    const controller = "Company"
    const page = useLeapState(controller, roles.role !== undefined, isCompany ? "item" : "filter")

    const code = useLeapData("code", "codice", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const leap_CompanyStateID = useLeapData("leap_CompanyStateID", "attivo", page, "companyStateOption")
    const leap_CompanyManagementTypeID = useLeapData("leap_CompanyManagementTypeID", "tipo gestione", page, "companyManagementTypeOption")
    const effectiveDateOfActivity = useLeapData("effectiveDateOfActivity", "decorrenza attività", page)
    const terminationDate = useLeapData("terminationDate", "data cessazione", page)
    const activeHeads = useLeapData("activeHeads", "teste attive", page)

    //dati referemte
    const referentFirstname = useLeapData("referentFirstname", "nome referente", page)
    const referentLastname = useLeapData("referentLastname", "cognome referente", page)
    const telephone = useLeapData("telephone", "telefono", page)
    const mobilephone = useLeapData("mobilephone", "cellulare", page)

    //dati compagnia
    const email = useLeapData("email", "email", page)
    const pec = useLeapData("pec", "PEC", page)
    const address = useLeapData("address", "indirizzo", page)
    const streetNumber = useLeapData("streetNumber", "civico", page)
    const postalCode = useLeapData("postalCode", "cap", page)
    const nationID = useLeapData("nationID", "nazione", page, "nations")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    //estremi bancari
    const cf = useLeapData("cf", "codice fiscale", page)
    const piva = useLeapData("piva", "partita iva", page)
    const sdi = useLeapData("sdi", "SDI", page)
    const iban = useLeapData("iban", "IBAN", page)
    const headerCC = useLeapData("headerCC", "intestazione C/C", page)

    const expired: boolean = page?.item?.values?.terminationDate?.value !== null
        && new Date(page?.item?.values?.terminationDate?.value).getTime() < new Date().getTime();

    const isONHC: boolean = roles.isONHCAccount || roles.isONHCAdmin || roles.isONHCMaster || roles.isONHCOperator;

    const [hideDialogRegeneratePassword, { toggle: toggleHideDialogRegeneratePassword }] = useBoolean(true);
    const [hideDialogGenerateUser, { toggle: togglehideDialogGenerateUser }] = useBoolean(true);


    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const dialogContentPropsRegeneratePassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla rigenerazione della password?',
    };

    const dialogContentPropsGenerateUser = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla generazione dell\'utente?',
    };
    const dispatch: AppDispatch = useDispatch();
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const metadataPage = dItem?.metadata?.page
    const credential = useSelector(security)?.credential

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteRegeneratePassword = () => {
        toggleHideDialogRegeneratePassword()
        dispatch(RegeneratePassword({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }
    const onExecuteGenerateUser = () => {
        togglehideDialogGenerateUser()
        dispatch(GenerateUser({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }

    const hasUser: string = page?.item?.state?.data?.hasUser;

    return <>
        <Content title={isCompany ? "Il mio Profilo" : "Compagnia"} controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm3" maxlength={12} />
                            <Text {...businessName} className="ms-sm5" />
                            <Option {...leap_CompanyStateID} className="ms-sm2" type="horizontal" />
                        </Row>
                        <Row>
                            <SearchBox {...nationID} className="ms-sm3" />
                            <SearchBox {...regionID} className="ms-sm3" />
                        </Row>
                        <Row>
                            <SearchBox {...provinceID} className="ms-sm3" />
                            <SearchBox {...municipalityID} className="ms-sm3" />
                        </Row>
                        <Row>
                            <Option {...leap_CompanyManagementTypeID} className="ms-sm2" type="horizontal" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <CompanyResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm3" readOnly />
                            <Text {...businessName} className="ms-sm5" required readOnly={isCompany} />
                            <Option {...leap_CompanyStateID} className="ms-sm2 ms-xxl1" type="horizontal" disabled={expired || isCompany} />
                            <Option {...leap_CompanyManagementTypeID} className="ms-sm2" type="horizontal" required disabled={page.modality !== "new" || isCompany} />
                        </Row>
                        <Row>
                            <Text {...effectiveDateOfActivity} className="ms-sm2" type="calendar" readOnly />
                            <Text {...terminationDate} dateRange={{ minDate: Date().toLocaleString(), maxDate: undefined }} className="ms-sm2" type="calendar" readOnly={isCompany} />
                            <Separator className="ms-sm1"></Separator>
                            <Text {...activeHeads} className="ms-sm1" readOnly hidden={page.modality === "new"} />
                            {page.modality === "item" && isONHC && hasUser && (
                                <>
                                    <PrimaryButton text="Resetta password" onClick={toggleHideDialogRegeneratePassword} style={{ marginTop: "20px", marginLeft: "15px" }} />
                                    <Dialog hidden={hideDialogRegeneratePassword} onDismiss={toggleHideDialogRegeneratePassword} dialogContentProps={dialogContentPropsRegeneratePassword} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegeneratePassword} text="Resetta password" />
                                            <DefaultButton onClick={toggleHideDialogRegeneratePassword} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                            {page.modality === "item" && isONHC && !hasUser && (
                                <>
                                    <PrimaryButton text="Genera Utente" onClick={togglehideDialogGenerateUser} style={{ marginTop: "20px", marginLeft: "15px" }} />
                                    <Dialog hidden={hideDialogGenerateUser} onDismiss={togglehideDialogGenerateUser} dialogContentProps={dialogContentPropsGenerateUser} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteGenerateUser} text="Genera Utente" />
                                            <DefaultButton onClick={togglehideDialogGenerateUser} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                        </Row>

                        <Section title="dati referente">
                            <Row>
                                <Text {...referentFirstname} className="ms-sm3" required />
                                <Text {...referentLastname} className="ms-sm3" required />
                            </Row>
                            <Row>
                                <Text {...telephone} className="ms-sm3" />
                                <Text {...mobilephone} className="ms-sm3" />
                            </Row>
                        </Section>
                        <Section title="dati compagnia">
                            <Row>
                                <Text {...email} className="ms-sm3" required />
                                <Text {...pec} className="ms-sm3" />
                            </Row>
                            <Row>
                                <Text {...address} className="ms-sm3" />
                                <Text {...streetNumber} className="ms-sm2" maxlength={8} />
                                <Text {...postalCode} className="ms-sm1" maxlength={5} />
                            </Row>
                            <Row>
                                <SearchBox {...nationID} className="ms-sm5" />
                                {/*<ComboBox {...provinceID} className="ms-sm5" children={[{ name: "municipalityID", lookup: "municipalities", controller: controller }]} disabled={!(nationSelected)} />*/}
                                <SearchBox {...municipalityID} className="ms-sm5" />
                            </Row>
                            <Row>
                                <Text {...cf} className="ms-sm3" required readOnly={isCompany} />
                                <Text {...piva} className="ms-sm3" required readOnly={isCompany}/>
                                <Text {...sdi} className="ms-sm3" />
                            </Row>
                        </Section>
                        <Section title="estremi bancari">
                            <Row>
                                <Text {...iban} className="ms-sm3" />
                                <Text {...headerCC} className="ms-sm3" />
                            </Row>
                        </Section>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
