import { SpinButton, FontSizes, ISpinButtonProps, precisionRound } from '@fluentui/react/';
import * as React from 'react';
import { FontFaces } from '../../defs/fonts';
import Theme from '../themes/Theme';
import { useSelector } from 'react-redux';
import { currentResponse } from '../../features/response/responseSlice';

const SpinButtonProps: ISpinButtonProps = {
    styles: {
        root: {
            height: 'auto',
            margin: 0,
            padding: 0,
            selectors: {
                'div': {
                    height: 25,
                },
                'span': {
                    display: 'none',
                },
            }
        },
        input: {
            height: 26,
            textAlign: 'right',
        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            whiteSpace: "nowrap"
        },
    }
};

const ErrorSpinButtonProps: ISpinButtonProps = {
    styles: {
        root: {
            height: 'auto',
            margin: 0,
            padding: 0,
            selectors: {
                'div': {
                    height: 25,
                },
                'div:last-child': {
                    backgroundColor: Theme.leap.customization.error.backgroundColor,
                },
                'span': {
                    display: 'none',
                },
            }
        },
        input: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            height: 25,
            textAlign: 'right',
        },
        
    }
}

type Props = {
    className: string,
    label: string,
    value?: string,
    required?: boolean,
    mandatory?: boolean,
    readOnly?: boolean,
    onChange?: (event: any) => void;
    precision?: {
        min?: number;
        max?: number;
        integer: number;
        decimal: number;
    }
    hidden?: boolean
}

export default (props: Props) => {
    const response = useSelector(currentResponse)

    const defaultPrecision = { min: undefined, max: undefined, integer: 4, decimal: 2 }

    const precision = props.precision ?? defaultPrecision
    const min = precision.min ?? 0
    const max = precision.max ?? (Math.pow(10, precision.integer) - (1 / Math.pow(10, precision.decimal)))

    const SpinButtonStyle = response.notified && !props.mandatory ? { ...ErrorSpinButtonProps } : { ...SpinButtonProps };
    const SpinClassName = response.notified && !props.mandatory ? " leap-NumericText-required-error" : (props.required ? " leap-NumericText-required" : " leap-NumericText");

    const onBlurHandler = (e: any) => {
        var returnValue = e.target.value.replace(",", ".")

        if (returnValue !== null && returnValue.trim().length > 0) {
            var newValue = Number(returnValue)
            if (isNaN(newValue)) {
                e.target.focus()
                return returnValue
            }

            if (newValue < min || newValue > max) {
                newValue = precisionRound(newValue, precision.decimal)

                e.target.focus()
                return String(newValue)
            }

            newValue = precisionRound(newValue, precision.decimal)
            returnValue = String(newValue)
        }

        if (props.onChange)
            props.onChange({ target: { value: returnValue } })

        return returnValue
    }

    const onValidate = (value: string, e: any) => {

        var returnValue = value.replace(",", ".")

        if (returnValue !== null && returnValue.trim().length > 0) {
            var newValue = Number(returnValue)
            if (isNaN(newValue)) {
                e.target.focus()
                return returnValue
            }

            if (newValue < min || newValue > max) {
                newValue = precisionRound(newValue, precision.decimal)

                e.target.focus()
                return String(newValue)
            }

            newValue = precisionRound(newValue, precision.decimal)
            returnValue = String(newValue)
        }

        if (props.onChange)
            props.onChange({ target: { value: returnValue } })

        return returnValue
    }

    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className + SpinClassName} style={hidden}>
            <SpinButton
                {...SpinButtonStyle}
                label={props.label}
                labelPosition={0}
                value={props.value?.replace(",", ".")}
                onValidate={onValidate}
                onBlur={onBlurHandler}
                min={min}
                max={max}
                precision={precision.decimal}
                disabled={props.readOnly}
                inputProps={{ maxLength: precision.integer + precision.decimal + (precision.decimal > 0 ? 1 : 0) }}
            />
        </div>
    </>
};
