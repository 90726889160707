import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList {...props} items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice' fieldName='healthServiceCode' minWidth={120} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Descrizione' fieldName='healthServiceDescription' minWidth={200} align='left' />
                <DataColumn kid='column3' name='Fee per Claim' fieldName='feePerClaim' minWidth={120} maxWidth={150} align='right' />
                <DataColumn kid='column4' name='Service Fee' fieldName='serviceFee' minWidth={120} maxWidth={150} align='right' />
                <DataColumn kid='column5' name='Insurance Cost of Claim' fieldName='insuranceCostOfClaim' minWidth={150} maxWidth={180} align='right' />
                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
