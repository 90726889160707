import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    items: any,
}

export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount
    const isAffiliation: boolean = roles.isHFAffiliation

    return <>
        <DataList items={props.items}>
            <DataColumn kid='column0' name='Branca' fieldName='branch' minWidth={200} maxWidth={300} align='left' />
            <DataColumn kid='column1' name='Prestazione' fieldName='healthservice' minWidth={200} align='left' />
            <DataColumn kid='column2' name='Massimale' fieldName='maximalRate' minWidth={80} maxWidth={100} align='right' />
            <DataColumn kid='column2' name='Tariffa' fieldName='rate' minWidth={80} maxWidth={100} align='right' />
            {!isAffiliation && <DataColumn kid='column3' name='Elementi Dentali' fieldName='dentalElement' minWidth={200} align='left' />}
        </DataList>
    </>
}