import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useBoolean } from '@uifabric/react-hooks'

import { AppDispatch } from '../../app/store'

import { currentResponse, resetResponse } from '../../features/response/responseSlice'
import { resetItemResponse } from '../../features/operation/thunks/itemThunk'
import { Dialog, DialogFooter, DialogType, PrimaryButton, DialogContent, IDialogContentProps, FontSizes, FontWeights, IDialogFooterProps, IDialogProps } from '@fluentui/react'
import Theme from '../themes/Theme'


export type MessageBoxProps = {
}

export default (props: MessageBoxProps) => {
    const dispatch: AppDispatch = useDispatch()

    const response = useSelector(currentResponse)

    const [hideMessageBox, { toggle: toggleHideMessageBox }] = useBoolean(true)
    const [messageMessageBox, setMessageMessageBox] = React.useState('')
    const [iconClassNameTitle, setIconClassNameTitle] = React.useState('')
    const [messageTitle, setMessageTitle] = React.useState('')


    React.useEffect(() => {
        if (response?.level && response?.message) {
            setMessageMessageBox(response.message);
            setIconClassNameTitle(iconClassNameTitleswitch(response.level));
            setMessageTitle(messageTitleswitch(response.level));
            toggleHideMessageBox()
        }
    }, [response, response?.message, response?.level])

    const messageTitleswitch = (level : any) => {
        switch (level) {//response?.level) {
            case 0:
                return "Info"
            case 1:
                return "Si è verificato un errore"
            case 2:
                return "Impossibile procedere"
            case 3:
                return "Operazione interrotta"
            case 4:
                return "Operazione completata"
            case 5:
                return "Operazione non riuscita"
            case 9:
                return "Informazione"
            default:
                return ""
        }
    }

    const iconClassNameTitleswitch = (level : any) => {
        switch (level) {//response?.level) {
            case 0:
                return "leap-messagebox-warning"
            case 1:
                return "leap-messagebox-error"
            case 2:
                return "leap-messagebox-error"
            case 3:
                return "leap-messagebox-warning"
            case 4:
                return "leap-messagebox-success"
            case 5:
                return "leap-messagebox-warning"
            case 9:
                return "leap-messagebox-info"
            default:
                return ""

       }
    }

    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: messageMessageBox,
            title: messageTitle,
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox " + iconClassNameTitle
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const dialogFooterProps: IDialogFooterProps = {}

    const onDismiss = () => {
        toggleHideMessageBox()

        dispatch(resetResponse())
        dispatch(resetItemResponse())
    }

    return <>
        <Dialog {...dialogProps} >
            <DialogFooter {...dialogFooterProps}>
                <PrimaryButton onClick={onDismiss} text="Chiudi" />
            </DialogFooter>
        </Dialog>
    </>
}