import * as React from 'react';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';

import OrganizationUserList from '../features/organization/OrganizationUserList';
import OrganizationResultList from '../features/organization/OrganizationResultList';
import AssignmentModal from '../features/organization/AssignmentModal'
import AssignmentModalEdit from '../features/organization/AssignmentModalEdit'


export default () => {
    const assignmentModalController = "AssignmentModal"
    const controller = "Organization"
    const page = useLeapState(controller)

    const name = useLeapData("name", "nome", page)

    return <>
        <Content title="Organizzazione" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm6" maxlength={50} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <OrganizationResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...name} className="ms-sm6" required maxlength={50} />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="Elenco Utenti" toolbar="grid" modal={<AssignmentModal state={page} controller={assignmentModalController} />} controller={assignmentModalController} modalwidth="600px" modaltitle="Inserisci Utente" state={page} >
                        <Row>
                            <OrganizationUserList items={page.item.state?.lists.Users} modal={<AssignmentModalEdit state={page} controller={assignmentModalController} />} controller={assignmentModalController} modalwidth="600px" modaltitle="Modifica Utente" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
