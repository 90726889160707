import * as React from 'react';
import ComboBox from '../../components/controls/ComboBox';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const position = useLeapData("positionQ", "posizione", page)
    const text = useLeapData("text", "testo", page)
    const leap_QuestionValueTypeID = useLeapData("leap_QuestionValueTypeID", "tipo", page, "valueTypes")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...text} className="ms-sm10" required />
                    </Row>
                    <Row>
                        <ComboBox {...leap_QuestionValueTypeID} className="ms-sm3" required />
                        <Text {...position} className="ms-sm2" type='number' required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
