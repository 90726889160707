import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { setOperation } from '../operation/operationSlice';
import { Icon } from '@fluentui/react';
import { AppDispatch } from '../../app/store';
import { security } from '../security/thunks/securityThunk';
import { lock, unlock } from '../operation/lockSlice';
import { itemDownload } from '../operation/thunks/itemThunk';

const { useEffect } = React

export default (props: {
    controller: string,
    items: any,
    onDownload?: any
}) => {
    const dispatch: AppDispatch = useDispatch()
    const credential = useSelector(security)?.credential

    const handleDownload = (ev: any) => {
        dispatch(lock())

        const args = {
            controller: props.controller,
            id: ev?.id,
            fileID: ev?.id,
            fileName: `PRIVACY-${ev.date}${(ev.insured ? "-" + ev.insured : "")}.zip`,
            token: credential?.token
        }

        dispatch(itemDownload(args))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const onRenderIcon = (e: any) => {
        if (e.hasFiles) {
            return <Icon iconName={"ZipFolder"} style={{ color: 'green', cursor: "pointer" }} onClick={() => handleDownload(e)} />;
        }
        return <></>;
    }

    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Data' fieldName='date' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column1' name='Evento' fieldName='event' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column3' name='Utente' fieldName='user' minWidth={150} maxWidth={250} align='left' />
                <DataColumn kid='column3' name='IP' fieldName='userIP' minWidth={150} maxWidth={150} align='center' />
                <DataColumn kid='column2' name='Assistito' fieldName='insured' minWidth={150} align='left' />
                <DataColumn kid='column2' name='PC' fieldName='quote' minWidth={150} align='left' />
                <DataColumn kid='columnDL' name='Files' fieldName='' minWidth={30} maxWidth={30} align='center' type='icon' onRenderIcon={onRenderIcon} />
            </DataList>
        </>
    );
}