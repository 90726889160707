import * as React from 'react';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Option from '../components/controls/Option';

import CompanyResultList from '../features/company/CompanyResultList';

export default () => {
    const controller = "Stub"
    const page = useLeapState(controller)

    //LEAP AND LOOKUPS TBI, ATTENDERE RISPOSTA PER SPECIFICHE MIGLIORI.

    const odCost = useLeapData("odCost", "costo fisso OD", page, "leap_odCosttable")
    const odQuoteUnvariable = useLeapData("odQuoteUnvariable", "quota fissa od", page, "leap_odQuote")
    const freeOd = useLeapData("freeOd", "gratis a carico di OD", page, "leap_freeOdTable")
    const freeSS = useLeapData("freeSS", "gratis a carico della SS", page, "leap_freeSS")
    const discountOD = useLeapData("discountSS", "sconto a carico della od", page, "leap_ssdiscount")
    const insuredCost = useLeapData("insuredCost", "costo fisso a carico assistito", page, "leap_insuredCost")
    const ssCost = useLeapData("ssCost", "prestazione a carico della ss", page, "leap_ssCost")
    return <>
        <Content title="Categorie di Sconto" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Option {...odCost} className="ms-sm3" type="horizontal" />
                            <Option {...odQuoteUnvariable} className="ms-sm3" type="horizontal" />
                            <Option {...freeOd} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Option {...freeSS} className="ms-sm3" type="horizontal" />
                            <Option {...discountOD} className="ms-sm3" type="horizontal" />
                            <Option {...insuredCost} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Option {...ssCost} className="ms-sm3" type="horizontal" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            
                            <CompanyResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Option {...odCost} className="ms-sm3" type="horizontal" />
                            <Option {...odQuoteUnvariable} className="ms-sm3" type="horizontal" />
                            <Option {...freeOd} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Option {...freeSS} className="ms-sm3" type="horizontal" />
                            <Option {...discountOD} className="ms-sm3" type="horizontal" />
                            <Option {...insuredCost} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Option {...ssCost} className="ms-sm3" type="horizontal" />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
