import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import MultiSearchBox from '../../components/controls/MultiSearchBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")

    return <>
        <Grid>
            <Section>
                <Row>
                    <MultiSearchBox {...healthFacilityID} className="ms-sm12" required itemLimit={99} />
                </Row>
            </Section>
        </Grid>
    </>
}
