import * as React from 'react';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import ComboBox from '../components/controls/ComboBox';

import HealthServiceResultList from '../features/healthService/HealthServiceResultList'

export default () => {
    const controller = "HealthService"
    const page = useLeapState(controller)

    const id = useLeapData("id", "hidden", page)
    const code = useLeapData("code", "codice", page, "branches")
    const branchID = useLeapData("branchID", "branca", page, "branches")
    const description = useLeapData("description", "descrizione", page, "branches")

    const branchStatePrevious = useLeapData("branchStatePreviousDescription", "stato precedente branca", page, "branches")
    const branchStateNext = useLeapData("branchStateNextDescription", "stato successivo branca", page, "branches")

    const dentalElementStatePrevious = useLeapData("leap_DentalElementStatePreviousId", "stato precedente", page, "dentalStates")
    const dentalElementStateNext = useLeapData("leap_DentalElementStateNextId", "stato successivo", page, "dentalStates")

    const [dentalElementStatePreviousValue, setDentalElementStatePreviousValue] = React.useState<any>()
    const dentalElementStatePreviousOnChange = (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => {
        setDentalElementStatePreviousValue(value)
    }

    React.useEffect(() => {
        setDentalElementStatePreviousValue(page.item?.state?.data?.leap_DentalElementStatePreviousId)
    }, [page.item?.state?.data?.leap_DentalElementStatePreviousId])

    const [dentalElementStateNextValue, setDentalElementStateNextValue] = React.useState<any>()
    const dentalElementStateNextOnChange = (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => {
        setDentalElementStateNextValue(value)
    }

    React.useEffect(() => {
        setDentalElementStateNextValue(page.item?.state?.data?.leap_DentalElementStateNextId)
    }, [page.item?.state?.data?.leap_DentalElementStateNextId])

    return <>
        <Content title="Prestazioni" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm2" />
                            <ComboBox {...branchID} className="ms-sm8" />
                        </Row>
                        <Row>
                            <Text {...description} className="ms-sm10" />
                        </Row>

                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <HealthServiceResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <input {...id} type="hidden" />
                            <Text {...code} className="ms-sm2" readOnly={page.modality === "item"} required />
                            <ComboBox {...branchID} className="ms-sm8" required />
                        </Row>
                        <Row>
                            <Text {...branchStatePrevious} className="ms-sm5" readOnly={true} />
                            <Text {...branchStateNext} className="ms-sm5" readOnly={true} />
                        </Row>
                        <Row>
                            <Text {...description} className="ms-sm10" required />
                        </Row>
                        <Row>
                            <ComboBox {...dentalElementStatePrevious} className="ms-sm5" onChange={dentalElementStatePreviousOnChange} selectedKey={dentalElementStatePreviousValue} />
                            <ComboBox {...dentalElementStateNext} className="ms-sm5" required={!!page.item?.values?.leap_DentalElementStatePreviousId?.value} onChange={dentalElementStateNextOnChange} selectedKey={dentalElementStateNextValue} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};


