import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../app/rootReducer'

import { ModalityType } from '../../../defs/modalities'
import { OperationType } from '../../../defs/operations'

import { initialStateThunks } from '../../../defs/thunks'

import { client } from '../../../api/client'
import { addBuilder } from './resultThunkHelpers'

export const resultLoad = createAsyncThunk(
    'result/load',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body

        if (args.body.page) {
            body = args.body
        } else {
            body = { ...args.body, page: { index: 0 } }
        }

        const response = await client.post(args.controller, body, 'Result', args.token)
        return response
    }
)

export const resultFirst = createAsyncThunk(
    'result/first',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body

        if (args.body.page) {
            body = { ...args.body, page: { ...args.body.page, index: 0 } }
        } else {
            body = { ...args.body, page: { index: 0 } }
        }

        const response = await client.post(args.controller, body, 'Result', args.token)
        return response
    }
)

export const resultPrevious = createAsyncThunk(
    'result/previous',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body

        if (args.body.page) {
            body = { ...args.body, page: { ...args.body.page, index: args.body.page.index - 1 } }
        } else {
            body = { ...args.body, page: { index: 0 } }
        }

        const response = await client.post(args.controller, body, 'Result', args.token)
        return response
    }
)

export const resultNext = createAsyncThunk(
    'result/next',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body

        if (args.body.page) {
            body = { ...args.body, page: { ...args.body.page, index: args.body.page.index + 1 } }
        } else {
            body = { ...args.body, page: { index: 0 } }
        }

        const response = await client.post(args.controller, body, 'Result', args.token)
        return response
    }
)

export const resultLast = createAsyncThunk(
    'result/last',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body

        if (args.body.page) {
            body = { ...args.body, page: { ...args.body.page, index: Math.trunc(args.body.page.count / args.body.page.rows) + 1 } }
        } else {
            body = { ...args.body, page: { index: 0 } }
        }

        const response = await client.post(args.controller, body, 'Result', args.token)
        return response
    }
)

export const resultDownload = createAsyncThunk(
    'result/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadResult(args.controller, args.body, 'ResultDownload', args.token)
        return response
    }
)

const ResultSlice = createSlice({
    name: 'result',
    initialState: { ...initialStateThunks },
    reducers: {
        clearFormResult: {
            reducer(state, action: PayloadAction<string>) {
                let controller = action.payload
                let form = state.form
                let formController = form.filter(f => f.key !== controller)

                state.form = formController
            },
            prepare(controller: string) {
                return { payload: controller }
            }
        },
        clearDataResult: {
            reducer(state, action: PayloadAction<string>) {
                let controller = action.payload
                let data = state.data
                let dataController = data.filter(d => d.key !== controller)

                state.data = dataController
            },
            prepare(controller: string) {
                return { payload: controller }
            }
        }
    },
    extraReducers: builder => {
        addBuilder(builder, resultLoad)
        addBuilder(builder, resultFirst)
        addBuilder(builder, resultPrevious)
        addBuilder(builder, resultNext)
        addBuilder(builder, resultLast)
    }
})

export const { clearFormResult, clearDataResult } = ResultSlice.actions

export const resultData = (state: RootState) => state.result.data
export const resultResponse = (state: RootState) => state.result.response

export default ResultSlice.reducer
