import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import ComboBox from '../../components/controls/ComboBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import MultiBox from '../../components/controls/MultiBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthService = useLeapData("healthService", "prestazioni", page, "healthServices")
    const branch = useLeapData("branch", "branca", page, "branches")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...branch} className="ms-sm12" disabled
                            children={[{ name: "healthService", lookup: "healthServices", controller: props.controller }]} />
                    </Row>
                    <Row>
                        <MultiBox {...healthService} className="ms-sm12" titleType='text' />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
