import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentResponse } from '../../features/response/responseSlice';
import { ChoiceGroup, FontSizes, IChoiceGroupOption, IChoiceGroupProps, IChoiceGroupStyleProps, IChoiceGroupStyles } from '@fluentui/react/';
import { FontFaces } from '../../defs/fonts';
import Theme from '../themes/Theme';

const ChoiceGroupProps: IChoiceGroupProps = {
    styles: {
        applicationRole: {
            paddingTop: 2,
        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 2,
            paddingLeft: 4,
        }
    }
};

type Props = {
    name?: string,
    className: string,
    label: string,
    options: IChoiceGroupOption[],
    type: "horizontal" | "vertical",
    required?: boolean,
    disabled?: boolean,
    mandatory?: (name: string) => boolean,
    selectedKey?: number | string | undefined,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined, e?: { cancel: boolean }) => void;
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    hidden?: boolean,
    disabledKeys?: string[]
}

export default (props: Props) => {
    const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>('');

    const response = useSelector(currentResponse)

    useEffect(() => {
        setSelectedKey(props.selectedKey)

        if (props.onInit) {
            props.onInit(props.name, props.selectedKey, props.required)
        }
    }, [props.name, props.selectedKey, props.required, props.disabled])

    const onChange = (event: any, option?: IChoiceGroupOption) => {
        var e = { cancel: false }

        if (props.onChange)
            props.onChange(props.name, option?.key.toString(), props.required, e)

        if (e.cancel) return

        setSelectedKey(option?.key.toString())
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const choiceGroupClassName = response.notified && !mandatory ? "leap-ChoiceFieldGroupError" : "";

    const ChoiceGroupStyles: Partial<IChoiceGroupStyles> = {
        flexContainer: { display: "flex" },
        label: { color: Theme.fluent.palette.black }
    }

    const hidden = props.hidden ? { display: "none" } : undefined

    if (props.disabledKeys) {
        props = {
            ...props, options: props.options?.map(option => {
                if (props.disabledKeys?.some(dk => dk === option.key)) {
                    return { ...option, disabled: true };
                }
                return option;
            })
        }
    }

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <ChoiceGroup {...ChoiceGroupProps} {...props} className={choiceGroupClassName} selectedKey={selectedKey} onChange={onChange} styles={ChoiceGroupStyles} />
        </div>
    </>
};
