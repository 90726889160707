import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
}

export default (props: Props) => {
    return (
        <>
            <DataList
                items={props.items}
                modaltitle={props.modaltitle}
                modalwidth={props.modalwidth}
                modalController={props.controller}
                modal={props.modal}
                state={props.state}
                readOnly={props.readOnly}
                paginate
                navigateToController={props.controller}
            >
                <DataColumn kid='columnN' name='' fieldName='navigate' minWidth={10} maxWidth={10} type='navigate' />
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column4' name='Titolo' fieldName='title' minWidth={200} align='left' />
                <DataColumn kid='column4' name='Posizione' fieldName='position' minWidth={100} maxWidth={100} align='center' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
