import * as React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Session from '../features/security/Session';
import Operation from '../features/operation/Operation'
import Layout from '../components/blueprints/h-centered/Layout';

import Home from '../pages/Home';
import Login from '../pages/Login';
import ChangePassword from '../pages/ChangePassword';
import HealthFacility from '../pages/HealthFacility';
import Insured from '../pages/Insured';
import Corporation from '../pages/Corporation';
import Company from '../pages/Company';
import Privacy from '../pages/Privacy';
import Consultant from '../pages/Consultant';
import MyDocs from '../pages/MyDocs';

import QuoteManagement from '../pages/QuoteManagement';
import InsuredPermission from '../pages/InsuredPermission';
import Bordereau from '../pages/Bordereau';

import Plan from '../pages/Plan';
import planPurchasePublic from '../pages/PlanPurchasePublic';
import DiscountCategory from '../pages/DiscountCategory';
import Discount from '../pages/Discount';
import Plafonds from '../pages/Plafonds';

import RateTable from '../pages/RateTable';
import Branch from '../pages/Branch';
import HealthService from '../pages/HealthService';

import AccountingSituation from '../pages/AccountingSituation';

import User from '../pages/User';
import Organization from '../pages/Organization';
import Role from '../pages/Role';
import Unit from '../pages/Unit';
import Privilege from '../pages/Privilege';
import DataImport from '../pages/DataImport'
import Nation from '../pages/Nation';
import Municipality from '../pages/Municipality';
import Province from '../pages/Province';
import HealthFacilityConsult from '../pages/HealthFacilityConsult';
import HealthFacilityDocumentation from '../pages/HealthFacilityDocumentation';
import HealthFacilityDocumentationConsult from '../pages/HealthFacilityDocumentationConsult';

import MedicalRecords from '../pages/MedicalRecords';
import CostTable from '../pages/CostTable';
import FacilityRateTableConsultation from '../pages/FacilityRateTableConsultation';

import Dental from '../pages/Dental';
import CoverageCounterConsult from '../pages/CoverageCounterConsult';

import InsuredRegistration from '../pages/InsuredRegistration';
import HealthFacilityAffiliationPublic from '../pages/HealthFacilityAffiliationPublic';
import HealthFacilityAffiliation from '../pages/HealthFacilityAffiliation';

import ForgotPassword from '../pages/ForgotPassword';
import ForgotUsername from '../pages/ForgotUsername';
import EmailValidation from '../pages/EmailValidation';

import DEInsured from '../pages/DEInsured';
import DEHealthFacility from '../pages/DEHealthFacility';
import DEOther from '../pages/DEOther';

import Statistics from '../pages/Statistics';

import Reservation from '../pages/Reservation';
import ReservationPublic from '../pages/ReservationPublic';

import Feedback from '../pages/Feedback';
import Form from '../pages/Form';
import FeedbackPublic from '../pages/FeedbackPublic';

import PlanPurchaseRequest from '../pages/PlanPurchaseRequest';

import IndirectManagement from '../pages/IndirectManagement';
import IndirectAccountingSituation from '../pages/IndirectAccountingSituation';

import DentalHistory from '../pages/DentalHistory';

import HealthFacilityMaster from '../pages/HealthFacilityMaster';

import SystemLogConsult from '../pages/SystemLogConsult';

import InsuredBilling from '../pages/InsuredBilling';

import Newsletter from '../pages/Newsletter';
import Magazine from '../pages/Magazine';

import ADE from '../pages/ADE';

export default () => (
    <BrowserRouter>
        <Session />
        <Operation />
        <Layout>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/login/' component={Login} />
                <Route exact path='/changePassword/' component={ChangePassword} />
                <Route exact path='/forgotPassword/' component={ForgotPassword} />
                <Route exact path='/forgotUsername/' component={ForgotUsername} />

                {/*Safe*/}
                <Route exact path='/healthFacility/' component={HealthFacility} />
                <Route exact path='/insured/' component={Insured} />
                <Route exact path='/corporation/' component={Corporation} />
                <Route exact path='/company/' component={Company} />
                <Route exact path='/privacy/' component={Privacy} />
                <Route exact path='/consultant/' component={Consultant} />
                <Route exact path='/dataimport/' component={DataImport} />
                <Route exact path='/mydocs/' component={MyDocs} />

                {/*Care*/}
                <Route exact path='/quoteManagement/' component={QuoteManagement} />
                <Route exact path='/insuredPermission/' component={InsuredPermission} />
                <Route exact path='/bordereau/' component={Bordereau} />

                {/*PlanManagement*/}
                <Route exact path='/plan/' component={Plan} />
                <Route path='/planPurchasePublic/' component={planPurchasePublic} />
                <Route exact path='/discountcategory/' component={DiscountCategory} />
                <Route exact path='/discount/' component={Discount} />
                <Route exact path='/plafond/' component={Plafonds} />

                {/*PriceManagement*/}
                <Route exact path='/rateTable/' component={RateTable} />
                <Route exact path='/branch/' component={Branch} />
                <Route exact path='/healthService/' component={HealthService} />

                {/*ContManagement*/}
                <Route exact path='/AccountingSituation/' component={AccountingSituation} />

                {/*Security*/}
                <Route exact path='/user/' component={User} />
                <Route exact path='/organization/' component={Organization} />
                <Route exact path='/role/' component={Role} />
                <Route exact path='/unit/' component={Unit} />
                <Route exact path='/privilege/' component={Privilege} />

                {/*Domicile*/}
                <Route exact path='/nation/' component={Nation} />
                <Route exact path='/municipality/' component={Municipality} />
                <Route exact path='/province/' component={Province} />

                <Route exact path='/dental/' component={Dental} />

                <Route exact path='/healthFacilityConsult/' component={HealthFacilityConsult} />
                <Route exact path='/healthFacilityDocumentation/' component={HealthFacilityDocumentation} />
                <Route exact path='/HealthFacilityDocumentationConsult/' component={HealthFacilityDocumentationConsult} />

                <Route exact path='/medicalRecords/' component={MedicalRecords} />
                <Route exact path='/costTable/' component={CostTable} />
                <Route exact path='/facilityRateTableConsultation/' component={FacilityRateTableConsultation} />
                <Route exact path='/coverageCounterConsult/' component={CoverageCounterConsult} />

                <Route exact path='/onhc-private-insuredRegistration/' component={InsuredRegistration} />
                <Route exact path='/healthFacilityAffiliationPublic/' component={HealthFacilityAffiliationPublic} />
                <Route exact path='/healthFacilityAffiliation/' component={HealthFacilityAffiliation} />

                <Route exact path='/emailValidation/' component={EmailValidation} />

                {/*Data Extraction*/}
                <Route exact path='/DEInsured/' component={DEInsured} />
                <Route exact path='/DEHealthFacility/' component={DEHealthFacility} />
                <Route exact path='/DEOther/' component={DEOther} />

                <Route exact path='/Statistics/' component={Statistics} />

                <Route exact path='/Reservation/' component={Reservation} />
                <Route exact path='/confirmReservation/' component={ReservationPublic} />

                {/*Feedback*/}
                <Route exact path='/Feedback/' component={Feedback} />
                <Route exact path='/Form/' component={Form} />
                <Route exact path='/feedbackPublic/' component={FeedbackPublic} />

                <Route exact path='/planPurchaseRequest/' component={PlanPurchaseRequest} />

                <Route exact path='/indirectManagement/' component={IndirectManagement} />
                <Route exact path='/indirectAccountingSituation/' component={IndirectAccountingSituation} />

                <Route exact path='/dentalHistory/' component={DentalHistory} />

                <Route exact path='/healthFacilityMaster/' component={HealthFacilityMaster} />

                <Route exact path='/systemLogConsult/' component={SystemLogConsult} />

                <Route exact path='/insuredBilling/' component={InsuredBilling} />

                <Route exact path='/newsletter/' component={Newsletter} />

                <Route exact path='/magazine/' component={Magazine} />

                <Route exact path='/ade/' component={ADE} />

                <Route path='*' component={Home} />
            </Switch>
        </Layout>
    </BrowserRouter>
);
