import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState'
import { useLeapData } from '../hooks/useLeapData'

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import SearchBox from '../components/controls/SearchBox';

import MunicipalityResultList from '../features/municipality/MunicipalityResultList'

export default () => {
    const controller = "Municipality"
    const page = useLeapState(controller)

    const name = useLeapData("name", "nome", page)
    const cap = useLeapData("cap", "CAP", page)
    const istat = useLeapData("istat", "codice istat", page)
    const endDate = useLeapData("endDate", "data fine", page)
    const startDate = useLeapData("startDate", "data inizio", page)
    const cadastralCode = useLeapData("cadastralCode", "codice catastale", page)
    const province = useLeapData("province", "provincia", page, "province")

    return <>
        <Content title="Comune" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm4" maxlength={50} />
                            <SearchBox {...province} className="ms-sm4" />
                        </Row>
                        <Row>
                            <Text {...istat} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 0, min: 0 }} />
                            <Text {...cadastralCode} className="ms-sm2" maxlength={16} />
                            <Text {...cap} className="ms-sm1" maxlength={5} />
                        </Row>
                        <Row>
                            <Text {...startDate} className="ms-sm2" type="calendar" />
                            <Text {...endDate} className="ms-sm2" type="calendar" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <MunicipalityResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...name} className="ms-sm4" maxlength={50} required />
                            <SearchBox {...province} className="ms-sm4" required />
                        </Row>
                        <Row>
                            <Text {...istat} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 0, min: 0 }} required />
                            <Text {...cadastralCode} className="ms-sm2" maxlength={16} required />
                            <Text {...cap} className="ms-sm1" required maxlength={5} />
                        </Row>
                        <Row>
                            <Text {...startDate} className="ms-sm2" type="calendar" required />
                            <Text {...endDate} className="ms-sm2" type="calendar" />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}
