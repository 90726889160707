export enum Permission {
    Undefined = 0,
    Read = 1,
    Create = 2,
    Copy = 3,
    Update = 4,
    Delete = 5,
    Clone = 6,
    Navigate = 7
}
