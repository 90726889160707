import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import ComboBox from '../../components/controls/ComboBox';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import Separator from '../../components/controls/grid/Separator';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const role = useLeapData("role", "nome ruolo", page, "roles")
    const organization = useLeapData("organization", "nome organizzazione", page, "organizations")
    const start_date = useLeapData("start_date", "inizio validità", page)
    const end_date = useLeapData("end_date", "fine validità", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...role} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <ComboBox {...organization} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <Text {...start_date} className="ms-sm5" type="calendar" required />
                        <Separator className="ms-sm2"></Separator>
                        <Text {...end_date} className="ms-sm5" type="calendar" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
