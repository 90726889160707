import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const plafondID = useLeapData("plafondID", "plafond", page)
    const leap_InsuredPlafondTypeID = useLeapData("leap_InsuredPlafondTypeID", "tipo plafond", page, "insuredPlafondTypes")
    const startDate = useLeapData("startDate", "data inizio validita", page)
    const endDate = useLeapData("endDate", "data fine validita", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...leap_InsuredPlafondTypeID} className="ms-sm5" readOnly required />
                    </Row>
                    <Row>
                        <Text {...plafondID} className="ms-sm12" readOnly required />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm5", required: true }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
