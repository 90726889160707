import * as React from 'react';
import { useDispatch } from 'react-redux';
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { setResponse } from '../response/responseSlice';
import { Icon } from '@fluentui/react';
import { unlock } from '../operation/lockSlice';

export default (props: { controller: string, items: any }) => {
    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHFAccount: boolean = roles.isHFAccount;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator || isHFAccount

    const isInsuredUser: boolean = roles.isInsuredUser;


    const dispatch = useDispatch()
    const onRowClick = (e: any, index: any, data: any) => {
        e.preventDefault()
        if (isInsuredUser && data.leap_QuoteManagementQuoteStatesID !== 4 && data.leap_QuoteManagementQuoteStatesID !== 7) {
            dispatch(setResponse("La consultazione del dettaglio del preventivo è possibile solo per stato Autorizzato o Fine Cure.", 9, true))
            dispatch(unlock())
            return
        }
        dispatch(setOperation("read", props.controller, { index: index }))
    }

    const onRenderIconApproveState = (e: any) => {
        if (e.hasHealthServicesToApprove === true) {
            return <Icon iconName={"Help"} style={{ color: 'red' }} />;
        } else {
            return <Icon iconName={"Accept"} style={{ color: 'mediumseagreen' }} />;
        }
    }

    const onRenderIconDocumentatioRequiredState = (e: any) => {
        if (e.leap_DocumentationVerificationID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_DocumentationVerificationID === "2") {
            return <Icon iconName={"CircleFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_DocumentationVerificationID === "3") {
            return <Icon iconName={"CircleFill"} style={{ color: 'red' }} />;
        } else if (e.leap_DocumentationRequiredID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"CircleRing"} style={{ color: '#d0d0d0' }} />;
        }
    }

    const onRenderIconInsuredBillingVerificationState = (e: any) => {
        if (e.leap_InsuredInvoiceVerificationID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "2") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_InsuredInvoiceVerificationID === "3") {
            return <Icon iconName={"CheckboxFill"} style={{ color: 'red' }} />;
        } else if (e.leap_InsuredInvoiceRequiredID === "1") {
            return <Icon iconName={"CheckboxFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"Checkbox"} style={{ color: '#d0d0d0' }} />;
        }
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick}>
                {!isInsuredUser && <DataColumn kid='column1' type="icon" name='' fieldName='hasHealthServicesToApprove' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconApproveState} />}
                {!isInsuredUser && <DataColumn kid='column2' type="icon" name='' fieldName='leap_DocumentationVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconDocumentatioRequiredState} />}
                {!isInsuredUser && <DataColumn kid='column3' type="icon" name='' fieldName='leap_InsuredInvoiceVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconInsuredBillingVerificationState} />}
                {!isInsuredUser && <DataColumn kid='column4' name='Assistito' fieldName='insuredID' minWidth={80} maxWidth={80} align='left' />}
                {!isHF && < DataColumn kid='column5' name='Struttura' fieldName='healthFacilityID' minWidth={80} maxWidth={80} align='left' />}
                <DataColumn kid='column6' name='Piano Cure' fieldName='quoteManagementNumber' minWidth={200} maxWidth={220} align='left' />
                <DataColumn kid='column7' name='Invio in Lavorazione' fieldName='processingDate' minWidth={150} maxWidth={150} align='center' />
                <DataColumn kid='column8' name='Data Autorizzazione' fieldName='authorizeDate' minWidth={150} maxWidth={150} align='center' />
                {!isInsuredUser && <DataColumn kid='column9' name='Data Ultima Modifica' fieldName='lastChange' minWidth={150} maxWidth={150} align='center' />}
                <DataColumn kid='column10' name='Stato' fieldName='leap_QuoteManagementQuoteStateID' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column11' name='Tipo di Gestione' fieldName='leap_PlanManagementDescr' minWidth={110} align='left' />
            </DataList>
        </>
    );
}
