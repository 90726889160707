import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}


export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} >
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice Branca' fieldName='branchCode' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column2' name='Descrizione Branca' fieldName='branchDescription' minWidth={400} align='left' />
                <DataColumn kid='column3' name='Codice Prestazione' fieldName='healthserviceCode' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column4' name='Descrizione Prestazione' fieldName='healthserviceDescription' minWidth={400} align='left' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
