import { FontSizes, PrimaryButton, ILabelStyles, Label, IComboBoxOption} from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import { setModality } from '../features/modality/modalitySlice';
import { lock, unlock } from '../features/operation/lockSlice';
import { itemForm, setFormItem, itemValidation, setFormData } from '../features/operation/thunks/itemThunk';
import ReservationResultList from '../features/reservation/ReservationResultList';
import { setResponse } from '../features/response/responseSlice';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { FontFaces } from '../defs/fonts';
import ReservationRequestStateButtons from '../features/reservation/ReservationRequestStateButtons';
import { DentalElements } from '../components/controls/dental/dentalElements';
import MultiBox from '../components/controls/MultiBox';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import { resultLoad } from '../features/operation/thunks/resultThunk';
import { filterForm } from '../features/operation/thunks/filterThunk';
import Separator from '../components/controls/grid/Separator';
import MapsModal from '../features/maps/MapsModal';
import { useDatagridOperation } from '../hooks/useDatagridOperation';

enum Management {
    Fund = "1",
    Welfare = "2"
}

type Props = {
    hidden?: boolean,
    state?: any
}

export enum States {
    Sent = "1",
    Accepted = "2",
    AwaitingContact = "3",
    AwaitingConfirm = "4",
    Deleted = "5",
}

const labelStyle: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "inherit",
        lineHeight: 20,
        marginTop: 15
    }
}

export const SendHealthFacilityRequest = createAsyncThunk(
    'item/SendHealthFacilityRequest',
    async (args: { form: any, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: args.form }, 'SendHealthFacilityRequest', args.token)
        return response
    }
)

export default (props: Props) => {
    const controller = "Reservation"
    const roles = useSecurityRoles()
    const isONHCUser: boolean = roles.isONHC
    const isHfUser: boolean = roles.isHF
    const isInsuredUser: boolean = roles.isInsuredUser;

    const page = useLeapState(controller, roles.role !== undefined, isInsuredUser ? "result" : "filter")

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const validation = useSelector(itemValidation)

    const iForm = useSelector(itemForm)
    const fForm = useSelector(filterForm)
    const form = iForm.find(i => i.key === controller)?.value

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token
    const datagridOperation = useDatagridOperation("QuoteManagement", token)

    let index = page?.result?.state?.metadata?.page?.index ?? 0

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    //#region data
    const code = useLeapData("code", "codice", page)
    const startDate = useLeapData("startDate", "data dal", page)
    const endDate = useLeapData("endDate", "data al", page)
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServicies")
    const leap_planManagementID = useLeapData("leap_planManagementID", "tipo gestione", page, "planManagementOption")
    const insuredFamilyCoreID = useLeapData("insuredFamilyCoreID", "seleziona familiare", page, "insuredFamilyCore")
    const appointmentDate = useLeapData("appointmentDate", "data", page)
    const appointmentTime = useLeapData("appointmentTime", "ora", page)
    const preferredTimeSlot = useLeapData("preferredTimeSlot", "disponibilità per l\'appuntamento", page)
    const notes = useLeapData("notes", "note", page)
    const isForBeneficiary = useLeapData("isForBeneficiary", "prenotazione per familiare?", page, "yesno")
    const planDescription = useLeapData("planDescription", "piano sanitario", page)
    const insuredCode = useLeapData("insuredCode", "codice assistito", page)
    const insuredFirstName = useLeapData("insuredFirstName", "nome", page)
    const insuredLastName = useLeapData("insuredLastName", "cognome", page)
    const insuredEmail = useLeapData("insuredEmail", "email", page)
    const insuredPhone = useLeapData("insuredPhone", "telefono", page)
    const fundPlanID = useLeapData("fundPlanID", "piano sanitario fondo", page, "fundPlans")
    const welfarePlanID = useLeapData("welfarePlanID", "piano sanitario welfare", page, "welfarePlans")
    const planID = useLeapData("planID", "piano sanitario", page, "plans")
    const leap_ReservationRequestStateID = useLeapData("leap_reservationRequestStateID", "stato", page, "requestStates")
    const leap_ReservationRequestStateDescr = useLeapData("leap_ReservationRequestStateDescr", "stato", page, "requestStates")
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)
    const province = useLeapData("provinceID", "provincia", page, "provincies")
    const municipality = useLeapData("municipalityID", "comune", page, "municipalities")

    const healthServiceDescription = useLeapData("healthServiceDescription", "prestazioni", page)

    const reservationExpiring = useLeapData("reservationExpiring", "visualizza solo prenotazioni in scadenza", page, "yesno")
    const agendaOnline = useLeapData("hasAgendaOnline", "visualizza prenotazioni di struttura sanitaria", page, "agendaOnlineEnabled")
    //#endregion data

    //#region 
    const isItemModality = page.modality === "item"
    const isNewModality = page.modality === "new"

    const isOtherSelected: boolean = page.item?.values?.healthServiceID?.value?.some((key: any) => key == "9999");

    const insuredFamily = page.item?.values?.insuredFamilyCoreID?.value === null || page.item?.values?.insuredFamilyCoreID?.value === undefined

    const yesAppointment = /*page.item?.values?.hasAppointment?.value === '1'*/false;
    const noAppointment = /*page.item?.values?.hasAppointment?.value === '2'*/true;

    const hasOneFundPlan = page.item?.state?.lookups?.fundPlans?.length === 1
    const hasOneWelfarePlan = page.item?.state?.lookups?.welfarePlans?.length === 1
    const hasOneBeneficiaryPlan = page.item?.state?.lookups?.plans?.length === 1

    const itemManagement = page.item?.values?.leap_planManagementID?.value ?? page.item?.state?.data?.leap_planManagementID;
    const selectedManagement = Object.entries(Management).find(([, value]) => value === itemManagement)?.[1];
    const hasOneManagement = page.item?.state?.lookups?.planManagementOption?.length === 1
    const hasManagement = !!selectedManagement

    const hsValue = page.item?.values?.healthServiceID?.value
    const hsSelected = !!hsValue && hsValue.length > 0

    const hfValue = page.item?.values?.healthFacilityID?.value
    const hfSelected = !!hfValue

    const hasFamiliars = page.item?.state?.lookups?.insuredFamilyCore?.length > 0;
    
    const forBeneficiary = page.item?.values?.isForBeneficiary?.value === '1';

    const planValue = page.item?.values?.planID?.value || page.item?.values?.fundPlanID?.value || page.item?.values?.welfarePlanID?.value
    const hasPlan = !!planValue

    const showBeneficiaryOption = hasManagement && selectedManagement === Management.Fund && hasFamiliars;
    const selectedBeneficiary = showBeneficiaryOption && forBeneficiary && !!page.item?.values?.insuredFamilyCoreID?.value

    const fundForHimself = showBeneficiaryOption && page.item?.values?.isForBeneficiary?.value === '2'

    const showPlanSearchBox = selectedBeneficiary || fundForHimself || selectedManagement === Management.Welfare

    const appointmentSelected = /*!!appointmentValue*/'2'

    const leap_ReservationRequestStates: string = page.item?.state?.data?.leap_ReservationRequestStateID;
    const currentState = Object.entries(States).find(([, value]) => value === leap_ReservationRequestStates)?.[1];

    const acceptDisabled: boolean = currentState !== States.Accepted
    const showDialogOnAccept: boolean = page.item.state?.data?.leap_ReservationRequestStateID !== 0;


    React.useEffect(() => {
        if (!hsValue) {
            dispatch(setFormItem(controller, { name: "healthFacilityID", value: undefined }))
        }
    }, [hsValue])

    React.useEffect(() => {
        if (!planValue) {
            dispatch(setFormItem(controller, { name: "healthServiceID", value: undefined }))
            dispatch(setFormItem(controller, { name: "healthFacilityID", value: undefined }))
        }
    }, [planValue])

    const onExecuteRequestHF = (e: any) => {
        toggleHideDialog()
        dispatch(lock())

        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere, compilare i campi richiesti", 5, true))
            dispatch(unlock())
            return;
        }

        dispatch(SendHealthFacilityRequest({ form: { ...form }, token: credential.token }))
            .then((response: any) => {
                dispatch(unlock())
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then((response) => {
                if (response.payload.response.level === 4) {
                    dispatch(setModality("result"))
                    dispatch(resultLoad({ controller, modality: "filter", operation: "search", body: { form: fForm.find(i => i.key === controller)?.value, page: { index: index } }, token: credential.token }))
                }
                return response
            })
            .catch(() => dispatch(unlock()))
    }

    const onMapSearchChange = (item: any) => {
        var itemText = item.buisnessName
        var itemValue = item.id?.toString()

        dispatch(setFormData(controller, { name: healthFacilityID.name, value: { key: itemValue, text: itemText } }))
        healthFacilityID.onChange(healthFacilityID.name, itemValue, false)
    }

    const hasInsuredAgenda: boolean = (!!!page.filter?.state?.lookups && !!!page.result?.state?.lookups) || page.filter?.state?.lookups?.hasAgendaEnabledPlans?.length > 0 || page.result?.state?.lookups?.hasAgendaEnabledPlans?.length > 0

    const navigateToQuoteManagement = () => {
        dispatch(lock())
        datagridOperation.navigate({ id: Number.parseInt(page.item?.state?.data?.quoteManagementID) })
            .then((response) => {
                document.location.href = '/QuoteManagement/?item'
            })
            .then(() => dispatch(unlock()))
    }

    const hasQuotemanagement = !!page.item?.state?.data?.quoteManagementID

    const items: IComboBoxOption[] = DentalElements
    const hasDental = page.item?.state?.data?.dentalElement?.[0] !== ""

    const healthServiceRows = page.item?.state?.data?.healthServiceDescription?.split("\n")?.length ?? 1

    const canViewPlanManagement = !isHfUser && !(isInsuredUser && !isNewModality)
    const canViewPlanDescription = !isHfUser

    const showAppointmentDateTime = !!page.item?.state?.data?.appointmentDate

    //#endregion

    if (isInsuredUser && !hasInsuredAgenda) {
        return <>
            <div className="ms-Grid-col ms-sm12" style={{ textAlign: "center" }}>
                <span style={{ borderColor: Theme.fluent.palette.themeSecondary, borderStyle: "solid", borderWidth: 2, borderRadius: 2, display: "inline-block", marginBottom: 80, marginTop: 100, padding: "4px 16px 16px" }}>
                    <Label style={{ color: Theme.fluent.palette.themePrimary, fontSize: "1.2em", fontWeight: 600, lineHeight: "32px" }}>La funzionalità di prenotazione sarà a breve disponibile anche per il tuo piano sanitario</Label>
                    <Label style={{ color: Theme.fluent.palette.neutralPrimaryAlt, fontSize: "1.1em", fontWeight: 600, lineHeight: "32px" }}>Nell’attesa si prega di contattare direttamente la Struttura Sanitaria</Label>
                </span>
            </div>
        </>
    }

    return <>
        <Content title="Prenotazione" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            {!isHfUser && < SearchBox {...healthFacilityID} className="ms-sm6" />}
                        </Row>
                        <Row>
                            <ComboBox {...leap_planManagementID} className="ms-sm3" />
                            <ComboBox {...leap_ReservationRequestStateID} className="ms-sm3" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            {!isInsuredUser && <>
                                <Option {...reservationExpiring} className="ms-sm2" type="horizontal" />
                                <Separator className="ms-sm1" />
                            </>}
                            {isONHCUser && <Option  {...agendaOnline} className="ms-sm6" type="horizontal" />}
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <ReservationResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    {isNewModality && <>
                        <Section toolbar="item" controller={controller}>
                            <Row>
                                {!hasOneManagement && <>
                                    <Option {...leap_planManagementID} className="ms-sm2" type="horizontal" required />
                                </>}
                                {showBeneficiaryOption && <>
                                    <Option {...isForBeneficiary} className="ms-sm2" type="horizontal" />
                                </>}
                            </Row>
                            {showBeneficiaryOption && forBeneficiary && <>
                                <Row>
                                    <ComboBox {...insuredFamilyCoreID} className="ms-sm4" children={[planID]} />
                                </Row>
                            </>}
                            {hasManagement && <>
                                <Row>
                                    {selectedManagement === Management.Fund && (fundForHimself || !hasFamiliars) && <>
                                        <ComboBox {...fundPlanID} className="ms-sm6" hidden={hasOneFundPlan} />
                                    </>}
                                    {selectedManagement === Management.Welfare && <>
                                        <ComboBox {...welfarePlanID} className="ms-sm6" hidden={hasOneWelfarePlan} />
                                    </>}
                                    {selectedManagement !== Management.Welfare && showPlanSearchBox && forBeneficiary && <>
                                        <ComboBox  {...planID} hidden={hasOneBeneficiaryPlan} className="ms-sm6" />
                                    </>}
                                </Row>
                            </>}
                            {hasManagement && hasPlan && <>
                                <Row>
                                    <SearchBox {...province} className="ms-sm4" />
                                    <SearchBox {...municipality} className="ms-sm4" />
                                </Row>
                                <Row>
                                    <SearchBox {...healthFacilityID} className="ms-sm6" required />
                                    <MapsModal controller={controller} state={page} latitude={undefined} longitude={undefined} onChange={onMapSearchChange} mapType="search" />
                                </Row>
                            </>}
                            {hfSelected && <>
                                <Row>
                                    <MultiSearchBox {...healthServiceID} className="ms-sm8" />
                                </Row>
                            </>}
                        </Section>
                        {hsSelected && <>
                            <Section title=' '>
                                {/*<Row>*/}
                                {/*    <Option {...hasAppointment} selectedKey="2" className="ms-sm2" type="horizontal" required />*/}
                                {/*</Row>*/}

                                <Row>
                                    {yesAppointment &&
                                        <Label styles={labelStyle} className="ms-Grid-col ms-sm8">
                                            * indicare la data e l'ora dell'appuntamento fissato
                                        </Label>}
                                    {noAppointment &&
                                        <Label styles={labelStyle} className="ms-Grid-col ms-sm8">
                                            * indicare la preferenza oraria per l'appuntamento
                                        </Label>}
                                </Row>
                                {appointmentSelected && <>
                                    <Row hidden={noAppointment}>
                                        <Text {...appointmentDate} dateRange={{ minDate: Date().toLocaleString(), maxDate: undefined }} type="calendar" className="ms-sm2" required={yesAppointment} />
                                        <Text {...appointmentTime} type="time" className="ms-sm1" required={yesAppointment} />
                                    </Row>
                                    <Row hidden={yesAppointment}>
                                        <Text {...preferredTimeSlot} type="multiline" rows={2} className="ms-sm8" required={noAppointment} maxlength={500} />
                                    </Row>
                                </>}
                            </Section>
                            {(appointmentSelected) && <>
                                <Section title=' '>
                                    <Row>
                                        <Text {...notes} type="multiline" rows={2} className="ms-sm8" required={isOtherSelected} maxlength={500} />
                                    </Row>
                                    <Row>
                                        <PrimaryButton text="invia richiesta prenotazione" onClick={onExecuteRequestHF} style={{ marginTop: "20px", marginLeft: "15px" }} />
                                    </Row>
                                </Section>
                            </>}
                        </>}
                    </>}
                    {isItemModality && <>
                        <Section toolbar="item" controller={controller}>
                            <Row>
                                <Text {...code} type="plain" className="ms-sm2" readOnly />
                                <Text {...leap_ReservationRequestStateDescr} className="ms-sm2" readOnly />
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 22 }}>
                                    {!isInsuredUser && hasQuotemanagement && <PrimaryButton text="vai al piano di cura" onClick={navigateToQuoteManagement} />}
                                </div>
                                <ReservationRequestStateButtons controller={controller} acceptDisabled={acceptDisabled} currentState={currentState} showDialogOnAccept={showDialogOnAccept} page={page} />
                            </Row>
                            <Row>
                                <Text {...notes} type="multiline" rows={2} className="ms-sm8" readOnly />
                            </Row>
                        </Section>
                        {!isInsuredUser && <>
                            <Section title='assistito'>
                                <Row>
                                    <Text {...insuredCode} className="ms-sm3" readOnly />
                                </Row>
                                <Row>
                                    <Text {...insuredLastName} className="ms-sm3" readOnly />
                                    <Text {...insuredFirstName} className="ms-sm3" readOnly />
                                    <Text {...insuredEmail} className="ms-sm3" readOnly />
                                    <Text {...insuredPhone} className="ms-sm3" readOnly />
                                </Row>
                                {insuredFamily && <>
                                    <Row>
                                        <ComboBox {...insuredFamilyCoreID} className="ms-sm4" label="familiare" disabled />
                                    </Row>
                                </>}
                            </Section>
                        </>}
                        <Section title='prestazioni'>
                            <Row>
                                {canViewPlanManagement && <>
                                    <Option {...leap_planManagementID} className="ms-sm2" type="horizontal" disabled />
                                </>}
                                {canViewPlanDescription && <>
                                    <Text {...planDescription} className="ms-sm6" readOnly hidden={isNewModality} />
                                </>}
                            </Row>
                            <Row>
                                <Text {...healthServiceDescription} readOnly className="ms-sm8" type="multiline" rows={healthServiceRows} />
                            </Row>
                            {isHfUser && !hasDental && <>
                                <Row>
                                    <Label styles={labelStyle} className="ms-Grid-col ms-sm7">
                                        * si prega di selezionare almeno un elemento dentale e salvare prima di procedere con la gestione della prenotazione
                                    </Label>
                                    <MultiBox {...dentalElement} className="ms-sm6" options={items} required />
                                </Row>
                            </>}
                        </Section>
                        <Section title='appuntamento'>
                            <Row>
                                <SearchBox {...healthFacilityID} className="ms-sm6" disabled hidden={isHfUser} />
                            </Row>
                            {showAppointmentDateTime && <>
                                <Row>
                                    <Text {...appointmentDate} readOnly type="calendar" className="ms-sm2" />
                                    <Text {...appointmentTime} readOnly type="time" className="ms-sm1" />
                                </Row>
                            </>}
                            {!showAppointmentDateTime && <>
                                <Row>
                                    <Text {...preferredTimeSlot} type="multiline" rows={2} className="ms-sm8" readOnly />
                                </Row>
                            </>}
                        </Section>
                    </>}
                </Grid>
            </Item>
        </Content>
    </>
}