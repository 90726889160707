import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
}

export default (props: Props) => {    
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} readOnly={props.readOnly} paginate>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column4' name='Piano Sanitario' fieldName='plan' minWidth={200} align='left' />
                {/*<DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />*/}
            </DataList>
        </>
    );
}
