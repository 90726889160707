import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='column3' name='Parentela' fieldName='kinshipDegrees' minWidth={250} align='left' />
                <DataColumn kid='column2' name='Quota' fieldName='membershipFee' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column1' name='Max' fieldName='threshold' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column4' name='Età' fieldName='age' minWidth={100} maxWidth={100} align='center' />
            </DataList>
        </>
    );
}
