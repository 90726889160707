import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    parentDatagridController?: string
    parentDatagridId?: string | number
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} parentDatagridController={props?.parentDatagridController} parentDatagridId={props?.parentDatagridId}>
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Threshold' fieldName='threshold' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column2' name='Valore quota' fieldName='membershipFee' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column3' name='Parentela' fieldName='kinshipDegrees' minWidth={250} align='left' />
                <DataColumn kid='column4' name='Età' fieldName='age' minWidth={150} maxWidth={150} align='left' />
            </DataList>
        </>
    );
}
