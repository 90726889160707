import { useDispatch } from 'react-redux'

import { AppDispatch } from '../app/store'
import { itemNew, itemCreate, itemLoadNoIndex, itemSaveNoIndex, itemDeleteNoIndex, clearDataItem, itemNavigate } from '../features/operation/thunks/itemThunk';

export const useDatagridOperation = (controller: string, token: string) => {
    const dispatch: AppDispatch = useDispatch();
    return {
        load: (body: any) => {
            dispatch(clearDataItem(controller))
            return dispatch(itemLoadNoIndex({ controller, modality: "item", operation: "read", body, token: token }))
        },
        new: (body: any) => {
            return dispatch(itemNew({ controller, modality: "item", operation: "new", body, token: token }))
        },
        create: (body: any) => {
            return dispatch(itemCreate({ controller, modality: "item", operation: "create", body, token: token }))
        },
        save: (body: any) => {
            return dispatch(itemSaveNoIndex({ controller, modality: "item", operation: "save", body, token: token }))
        },
        delete: (body: any) => {
            return dispatch(itemDeleteNoIndex({ controller, modality: "item", operation: "delete", body, token: token }))
        },
        navigate: (body: any) => {
            return dispatch(itemNavigate({ controller, modality: "item", operation: "navigate", body, token: token }))
        },
        first: () => { },
        previous: () => { },
        next: () => { },
        last: () => { }
    }
}