import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';

import { AppDispatch } from '../app/store';
import { lock, unlock } from '../features/operation/lockSlice';
import { filterValidation } from '../features/operation/thunks/filterThunk';
import { setResponse } from '../features/response/responseSlice';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecuritydOperation } from '../hooks/useSecurityOperation';

import { FontSizes, Image, PrimaryButton, Text as TextTitle, Dialog, DialogFooter, FontWeights, DialogType, IDialogProps } from '@fluentui/react/';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import Text from '../components/controls/Text';

export default () => {
    const controller = "Security"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch()
    const validation = useSelector(filterValidation)

    const securityOperation = useSecuritydOperation();

    const firstname = useLeapData("firstname", "nome", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const email = useLeapData("email", "email", page)
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)

    const onForgotUsername = () => {
        setButtonDisabled(true)

        debugger
        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Per procedere al recupero del nome utente è richiesta la compilazione di tutti i dati obbligatori.", 5, true))
            setMessage("Per procedere al recupero del nome utente è richiesta la compilazione di tutti i dati obbligatori.")
            setButtonDisabled(false)
            toggleHideMessageBox();
            return;
        }
        dispatch(lock())

        securityOperation.forgotUsername(
            page?.filter?.values.firstname?.value,
            page?.filter?.values.lastname?.value,
            page?.filter?.values.fiscalcode?.value,
            page?.filter?.values.email?.value,
            undefined)
            .then((response) => {
                setButtonDisabled(false)
                setMessage("Il nome utente e' stato inviato via email qualora i dati inseriti corrispondessero a quelle di un utente presente a sistema.")
                toggleHideMessageBox();
            })
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [message, setMessage] = useState("")
    const [hideMessageBox, { toggle: toggleHideMessageBox }] = useBoolean(true)
    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione completata",
            subText: message,
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    const buttonRef: any = useRef(null)
    const handleKeyboard = (event: any) => {
        if (event.key === "Enter") {
            buttonRef.current.click()
        }
    }
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);
        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [])

    const gotoLogin = <><TextTitle style={{ fontFamily: FontFaces.montserrat, fontWeight: 500 }}>Vuoi accedere? &nbsp;</TextTitle ><TextTitle nowrap onClick={() => { document.location.href = '/login/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Clicca qui</TextTitle></>

    const mainContainerStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: 32
    }
    const logoExternalContainerStyle: React.CSSProperties = {
        backgroundColor: process.env.REACT_APP_STYLE_BODY_BACKGROUNDCOLOR,
        borderRadius: "50%",
        padding: 16,
        zIndex: 1
    }
    const logoInternalContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.white,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: "50%",
        zIndex: 1
    }
    const formContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.themeLight,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: "128px 24px 8px",
        position: "relative",
        top: -96,
    }

    const buttonContainerStyle: React.CSSProperties = {
        marginTop: 32,
        textAlign: "center"
    }

    const buttonStyle: React.CSSProperties = {
        width: 256
    }

    const textContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }

    return <>
        <Dialog {...dialogProps} onDismiss={() => { document.location.href = '/login/'; }}>
            <DialogFooter >
                <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <div style={mainContainerStyle}>
            <div style={logoExternalContainerStyle}>
                <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                    <Image src="/assets/media/images/icon.png"
                        draggable={false}
                        style={{
                            margin: 17,
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }} />
                </div>
            </div>
            <div className="splashForm ms-depth-16" style={formContainerStyle} >
                <Text {...firstname} className="ms-sm12" required />
                <Text {...lastname} className="ms-sm12" required />
                <Text {...fiscalcode} className="ms-sm12" required />
                <Text {...email} className="ms-sm12" required />
                <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                    <PrimaryButton style={buttonStyle} onClick={onForgotUsername} text="recupera nome utente" elementRef={buttonRef} disabled={buttonDisabled} />
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 40 }}>
                    {gotoLogin}
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 32 }}>
                    <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                </div>
            </div>
        </div>
    </>
}