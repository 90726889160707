import * as React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from '../app/store';

import { client } from '../api/client';

import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { lock, unlock, isLocked } from '../features/operation/lockSlice';

import { security } from '../features/security/thunks/securityThunk';
import { itemDownload, itemPrint, itemForm } from '../features/operation/thunks/itemThunk';

import { Icon, IButtonProps, DefaultButton, PrimaryButton, Pivot, PivotItem } from '@fluentui/react';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';

import IndirectManagementResultList from '../features/indirectManagement/IndirectManagementResultList';

import IndirectManagementInvoiceList from '../features/indirectManagement/IndirectManagementInvoiceList';
import IndirectManagementInvoiceModal from '../features/indirectManagement/IndirectManagementInvoiceModal';
import IndirectManagementInvoiceModalEdit from '../features/indirectManagement/IndirectManagementInvoiceModalEdit';

import IndirectManagementHealthServiceList from '../features/indirectManagement/IndirectManagementHealthServiceList';
import IndirectManagementHealthServiceModal from '../features/indirectManagement/IndirectManagementHealthServiceModal';
import IndirectManagementHealthServiceModalEdit from '../features/indirectManagement/IndirectManagementHealthServiceModalEdit';

import IndirectManagementDocumentationList from '../features/indirectManagement/IndirectManagementDocumentationList';
import IndirectManagementDocumentationModal from '../features/indirectManagement/IndirectManagementDocumentationModal';
import IndirectManagementDocumentationModalEdit from '../features/indirectManagement/IndirectManagementDocumentationModalEdit';

import IndirectManagementLogList from '../features/indirectManagement/IndirectManagementLogList';

import IndirectManagementStateButtons from '../features/indirectManagement/IndirectManagementStateButtons';

import Theme from '../components/themes/Theme';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';

import SearchBox from '../components/controls/SearchBox';
import FileUpload from '../components/controls/FileUpload';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import Option from '../components/controls/Option';

export const indirectManagementGuideDownload = createAsyncThunk(
    'indirectManagementGuide/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'IMGuide', args.token)
        return response
    }
)

export const declarationTemplateDownload = createAsyncThunk(
    'declarationTemplate/download',
    async (args: { controller: string, body: any, token?: string }) => {
        const response = await client.downloadPost(args.controller, args.body, 'IMDeclarationTemplate', args.token)
        return response
    }
)

export default () => {
    const dispatch: AppDispatch = useDispatch()

    const credential = useSelector(security)?.credential
    const _isLocked = useSelector(isLocked)

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = roles.isONHC;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = roles.isHF;

    const isInsured = roles.isInsuredUser;


    const controller = "IndirectManagement"
    const indirectManagementInvoiceController = "IndirectManagementInvoice"
    const indirectManagementHealthServiceController = "IndirectManagementHealthService"
    const indirectManagementDocumentationController = "IndirectManagementDocumentation"
    const indirectManagementLogController = "IndirectManagementLog"

    const page = useLeapState(controller, roles.role !== undefined, isInsured ? "result" : "filter")

    const iForm = useSelector(itemForm)
    const form = iForm.find(i => i.key === controller)?.value

    const codeIndirect = useLeapData("indirectManagementNumber", "codice pratica", page)
    const codePolicy = useLeapData("insurancePolicyNumber", "codice assicurazione", page)

    const stateIndirect = useLeapData("indirectManagementStateID", "stato pratica", page, "indirectManagementStates")
    const stateIndirectDescription = useLeapData("leap_IndirectManagementStateDescr", "stato pratica", page)

    const leap_DocumentationRequiredID = useLeapData("leap_DocumentationRequiredID", "documentazione obbligatoria", page, "documentationRequiredOption")
    const leap_DocumentationVerificationID = useLeapData("leap_DocumentationVerificationID", "stato di verifica della documentazione", page, "documentationVerificationOption")

    const insured = useLeapData("insuredID", "assistito", page, "insureds")
    const insuredCode = useLeapData("insuredCode", "codice", page)
    const insuredFirstname = useLeapData("insuredFirstname", "nome", page)
    const insuredLastname = useLeapData("insuredLastname", "cognome", page)
    const insuredFiscalCode = useLeapData("insuredFiscalCode", "codice fiscale", page)

    const ownerCode = useLeapData("ownerCode", "codice", page)
    const ownerFirstname = useLeapData("ownerFirstname", "nome", page)
    const ownerLastname = useLeapData("ownerLastname", "cognome", page)
    const ownerFiscalCode = useLeapData("ownerFiscalCode", "codice fiscale", page)

    const plan = useLeapData("planID", "piano sanitario", page, "plans")
    const planDescr = useLeapData("planDescr", "piano sanitario", page)

    const coverageYear = useLeapData("coverageYear", "anno competenza", page, "coverageYears")

    const description = useLeapData("description", "descrizione", page)
    const note = useLeapData("note", "note", page)

    const issuerName = useLeapData("issuerName", "denominazione", page)
    const issuerVATNumber = useLeapData("issuerVATNumber", "partita iva/codice fiscale", page)
    const issuerAddress = useLeapData("issuerAddress", "indirizzo", page)
    const issuerMunicipality = useLeapData("issuerMunicipalityID", "comune", page, "municipalities")

    const healthServiceDeclarationUploadDate = useLeapData("healthServiceDeclarationUploadDate", "data dichiarazione", page)
    const healthServiceDeclarationFile = useLeapData("healthServiceDeclarationFileID", "allega dichiarazione medico", page)

    const iban = useLeapData("bankAccountIBAN", "IBAN", page)
    const headerCC = useLeapData("bankAccountHolder", "intestazione CC", page)

    const createDate = useLeapData("createDate", "data creazione", page)
    const updateDate = useLeapData("updateDate", "ultimo aggiornamento", page)

    const indirectManagementInvoices = useLeapData("indirectManagementInvoices", "", page)
    const indirectManagementHealthServices = useLeapData("indirectManagementHealthServices", "", page)
    const indirectManagementDocumentations = useLeapData("indirectManagementDocumentations", "", page)
    const indirectManagementLogs = useLeapData("indirectManagementHealthLogs", "", page)

    const cost = useLeapData("cost", "totale importo", page)
    const refound = useLeapData("refound", "totale rimborso", page)
    const refoundDate = useLeapData("refoundDate", "data liquidazione", page)

    const createFromDate = useLeapData("createFromDate", "inserimento dal", page)
    const createToDate = useLeapData("createToDate", "al", page)
    const updateFromDate = useLeapData("updateFromDate", "aggiornamento dal", page)
    const updateToDate = useLeapData("updateToDate", "al", page)
    const refoundFromDate = useLeapData("refoundFromDate", "liquidazione dal", page)
    const refoundToDate = useLeapData("refoundToDate", "al", page)

    const indirectManagementState = page?.item?.state?.data?.leap_IndirectManagementStateID

    const hasManyPlans: boolean = ((page.item.state?.lookups.plans?.length ?? 0) > 1);
    const hasManyYears: boolean = ((page.item.state?.lookups.coverageYears?.length ?? 0) > 1);

    const hasOwner = !!page?.item?.state?.data?.ownerFirstname

    const isNew = (page.modality === "new")
    const canPrint = false
    const canViewRefounds = isONHC || (isInsured && (indirectManagementState === "6" || indirectManagementState === "7" || indirectManagementState === "8" || indirectManagementState === "9"))
    const isPaidable = isONHC && indirectManagementState === "6"
    const isPaid = isONHC && (indirectManagementState === "7" || indirectManagementState === "8" || indirectManagementState === "9")

    const readOnly = !isNew && ((isInsured && indirectManagementState !== "1") || (isONHC && (indirectManagementState === "4" || indirectManagementState === "5" || indirectManagementState === "6" || indirectManagementState === "7")))

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = useState("insured")
    const PivotButtonProps: IButtonProps = {
        style: {
            borderColor: Theme.fluent.palette.white,
            borderRadius: 0,
            borderStyle: "solid",
            borderLeftWidth: 1,
            borderTopWidth: 1,
            transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
            verticalAlign: "bottom"
        },
        styles: {
            root: {
                backgroundColor: Theme.fluent.palette.themeTertiary,
                color: Theme.fluent.palette.neutralLighter,
                height: 38,
                marginTop: 12,
                minWidth: "12%",
            },
            rootDisabled: {
                backgroundColor: Theme.fluent.palette.themePrimary,
                color: Theme.fluent.palette.white,
                height: 42,
                marginTop: 8,
                minWidth: "15%",
            },
            rootHovered: {
                backgroundColor: Theme.fluent.palette.themeLighter,
            },
            rootPressed: {
                backgroundColor: Theme.fluent.palette.themeLight,
            }
        }
    }

    const PrintButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const invoiceFileID = page?.item?.state?.data?.healthServiceDeclarationFileID
    const fileExt = page?.item?.state?.data?.healthServiceDeclarationFileExt

    //const hasBeneficiary = page?.item?.state?.data?.insuredFiscalCode !== page?.item?.state?.data?.beneficiaryFiscalCode

    const documentationRequiredEnabled = isONHC && (indirectManagementState === "2" || indirectManagementState === "3")
    const showDocumentationVerification = isONHC && (indirectManagementState !== "1" && indirectManagementState !== "2") && page.item?.values?.leap_DocumentationRequiredID?.value === "1"

    const downloadIndirectManagementGuide = () => {
        const args = {
            controller: "Files",
            body: {
                form: { fileName: "guida alla compilazione.pdf" },
                page: {}
            },
            token: credential.token
        }

        dispatch(indirectManagementGuideDownload(args))
    }

    const downloadDeclarationTemplate = () => {
        const args = {
            controller: "Files",
            body: {
                form: { fileName: "template dichiarazione medico.pdf" },
                page: {}
            },
            token: credential.token
        }

        dispatch(declarationTemplateDownload(args))
    }

    const downloadAttachmentFile = () => {
        const args = {
            controller: controller,
            fileID: invoiceFileID,
            fileName: "dichiarazione medico - " + page?.item?.state?.data?.insuredFiscalCode + "." + fileExt,
            token: credential.token
        }
        dispatch(itemDownload(args))
    }

    const onClickPrint = () => {
        dispatch(lock())
        dispatch(itemPrint({ controller, lookup: "print", body: { form: form, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    return <>
        <Content title="Richieste" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...codeIndirect} className="ms-sm2" />
                            <Text {...codePolicy} className="ms-sm2" />
                            <ComboBox {...stateIndirect} className="ms-sm2" />
                            <ComboBox {...leap_DocumentationVerificationID} className="ms-sm3" hidden={!isONHC} />
                        </Row>
                        {!isInsured &&
                            <Row>
                                <SearchBox {...insured} className="ms-sm6" />
                            </Row>
                        }
                        <Row>
                            <SearchBox {...plan} className="ms-sm6" />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...createFromDate, className: "ms-sm2" }} dateMax={{ ...createToDate, className: "ms-sm2" }} />
                            <DateRange dateMin={{ ...updateFromDate, className: "ms-sm2" }} dateMax={{ ...updateToDate, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...refoundFromDate, className: "ms-sm2" }} dateMax={{ ...refoundToDate, className: "ms-sm2" }} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <IndirectManagementResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        {!isNew && <>
                            <div className="ms-Grid-col ms-sm8" style={{ height: 50 }}>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "insured"} onClick={() => setPivotItemSelectedKey("insured")}>dati generali</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "healthservices"} onClick={() => setPivotItemSelectedKey("healthservices")}>prestazioni</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "files"} onClick={() => setPivotItemSelectedKey("files")}>allegati</DefaultButton>
                            </div>
                            <div className="ms-Grid-col ms-sm4" style={{ paddingTop: 18, textAlign: "right" }}>
                                <PrimaryButton {...PrintButtonProps} onClick={downloadIndirectManagementGuide} disabled={_isLocked}>scarica guida</PrimaryButton>
                                {canPrint && <PrimaryButton {...PrintButtonProps} onClick={onClickPrint} disabled={_isLocked}>stampa</PrimaryButton>}
                            </div>
                        </>}
                    </Section>
                    <Pivot selectedKey={pivotItemSelectedKey}>
                        <PivotItem headerText="dati anagrafici" itemKey="insured">
                            {!isNew && <>
                                <Section title=" ">
                                    <Row>
                                        {<Text {...codeIndirect} className="ms-sm2" readOnly />}
                                        {isONHC && <Text {...codePolicy} className="ms-sm2" />}
                                        {<Text {...stateIndirectDescription} className="ms-sm2" readOnly />}
                                    </Row>
                                    <Row>
                                        <Option {...leap_DocumentationRequiredID} type="horizontal" className="ms-sm3 ms-xxxl2" disabled={!documentationRequiredEnabled} />
                                        <Option {...leap_DocumentationVerificationID} type="horizontal" className="ms-sm2" hidden={!showDocumentationVerification} />
                                    </Row>
                                </Section>
                                <Section title="beneficiario">
                                    <Text {...insuredCode} className="ms-sm2" readOnly />
                                    <Text {...insuredFirstname} className="ms-sm3" readOnly />
                                    <Text {...insuredLastname} className="ms-sm3" readOnly />
                                    <Text {...insuredFiscalCode} className="ms-sm2" readOnly />
                                </Section>
                                {hasOwner && <>
                                    <Section title="richiedente">
                                        <Text {...ownerCode} className="ms-sm2" readOnly />
                                        <Text {...ownerFirstname} className="ms-sm3" readOnly />
                                        <Text {...ownerLastname} className="ms-sm3" readOnly />
                                        <Text {...ownerFiscalCode} className="ms-sm2" readOnly />
                                    </Section>
                                </>}
                            </>}
                            <Section title="dati generali">
                                <Row>
                                    <Text {...description} className="ms-sm6" required readOnly={readOnly} />
                                </Row>
                                <Row>
                                    {isNew && <>
                                        <SearchBox {...insured} className="ms-sm6" required children={[{ ...plan }, { ...coverageYear }, { ...iban }, { ...headerCC }]} />
                                    </>}
                                </Row>
                                <Row>
                                    {isNew && hasManyPlans && <ComboBox {...plan} className="ms-sm4" required children={[{ ...coverageYear }]} />}
                                    {isNew && hasManyYears && <ComboBox {...coverageYear} className="ms-sm2" required />}
                                    {!isNew && <Text {...planDescr} className="ms-sm4" readOnly />}
                                    {!isNew && <Text {...coverageYear} className="ms-sm2" readOnly />}
                                </Row>
                                <Row>
                                    <Text {...note} className="ms-sm6" type="multiline" readOnly={readOnly && isInsured} />
                                </Row>
                                <Row >
                                    {!isNew && <>
                                        <Text {...createDate} className="ms-sm2" readOnly />
                                        <Text {...updateDate} className="ms-sm2" readOnly />
                                    </>}
                                </Row>
                            </Section>
                            <Section title="estremi bancari">
                                <Row >
                                    <Text {...iban} className="ms-sm3" maxlength={50} required readOnly={readOnly} />
                                    <Text {...headerCC} className="ms-sm3" maxlength={50} required readOnly={readOnly} />
                                </Row>
                            </Section>
                            <Section title="dichiarazione medico">
                                {isNew && <>
                                    <Row>
                                        <FileUpload {...healthServiceDeclarationFile} className="ms-sm6" required />
                                        <div className="ms-Grid-col ms-sm6" >
                                            <PrimaryButton text="scarica modello" onClick={downloadDeclarationTemplate} style={{ marginTop: "23px" }} />
                                        </div>
                                    </Row>
                                </>}
                                {!isNew && <>
                                    <Row>
                                        <Text {...healthServiceDeclarationUploadDate} className="ms-sm2" readOnly />
                                        <div className="ms-Grid-col ms-sm6" >
                                            <PrimaryButton text="scarica dichiarazione" onClick={downloadAttachmentFile} style={{ marginTop: "23px" }} />
                                        </div>
                                    </Row>
                                </>}
                            </Section>
                            <Section title="emittente fattura">
                                <Row>
                                    <Text {...issuerName} className="ms-sm6" required readOnly={readOnly} />
                                    <Text {...issuerVATNumber} className="ms-sm2" required readOnly={readOnly} />
                                </Row>
                                <Row>
                                    <Text {...issuerAddress} className="ms-sm6" required readOnly={readOnly} />
                                    <SearchBox {...issuerMunicipality} className="ms-sm6" required disabled={readOnly} />
                                </Row>
                            </Section>
                            {!isNew && <Section title="elenco fatture" toolbar={readOnly ? undefined : "grid"} modal={<IndirectManagementInvoiceModal state={page} controller={indirectManagementInvoiceController} />} controller={indirectManagementInvoiceController} modaltitle="Inserisci una fattura" state={page} modalwidth="30vw">
                                <Row>
                                    <IndirectManagementInvoiceList {...indirectManagementInvoices} items={page.item.state?.lists.invoices} modal={<IndirectManagementInvoiceModalEdit state={page} controller={indirectManagementInvoiceController} />} controller={indirectManagementInvoiceController} modaltitle="Modifica una fattura" modalwidth="30vw" state={page} readOnly={readOnly} />
                                </Row>
                            </Section>}
                            {!isNew && <Section title="log" toolbar="grid" controller={indirectManagementLogController} collapsable>
                                <Row>
                                    <IndirectManagementLogList {...indirectManagementLogs} items={page.item.state?.lists.logs} controller={indirectManagementLogController} />
                                </Row>
                            </Section>}
                        </PivotItem>
                        {!isNew && <PivotItem headerText="prestazioni" itemKey="healthservices">
                            <SearchBox {...issuerMunicipality} className="" hidden />
                            <Section title=" ">
                                <Row>
                                    {!isNew && <Text {...stateIndirectDescription} className="ms-sm2" readOnly />}
                                    {!isNew && <IndirectManagementStateButtons controller={controller} itemState={page.item?.state} />}
                                </Row>
                            </Section>
                            <Section title="elenco prestazioni" toolbar={readOnly ? undefined : "grid"} modal={<IndirectManagementHealthServiceModal state={page} controller={indirectManagementHealthServiceController} parentController={controller} canViewRefounds={canViewRefounds} />} modalwidth="800px" controller={indirectManagementHealthServiceController} modaltitle="Inserisci una prestazione" state={page} >
                                <Row>
                                    <IndirectManagementHealthServiceList {...indirectManagementHealthServices} items={page.item.state?.lists.healthServices} modal={<IndirectManagementHealthServiceModalEdit state={page} controller={indirectManagementHealthServiceController} canViewRefounds={canViewRefounds} />} controller={indirectManagementHealthServiceController} modalwidth="800px" modaltitle="Modifica una prestazione" state={page} readOnly={readOnly} canViewRefounds={canViewRefounds} />
                                </Row>
                            </Section>
                            <Section>
                                <Row>
                                    <Text {...cost} className="ms-sm2" type='number' readOnly />
                                    {canViewRefounds && <Text {...refound} className="ms-sm2" type='number' readOnly={!isPaidable} />}
                                    {canViewRefounds && <Text {...refoundDate} className="ms-sm2" hidden={!isPaid} readOnly />}
                                </Row>
                            </Section>
                        </PivotItem>}
                        {!isNew && <PivotItem headerText="allegati" itemKey="files">
                            <SearchBox {...issuerMunicipality} className="" hidden />
                            <Section title="elenco allegati" toolbar={readOnly ? undefined : "grid"} modal={<IndirectManagementDocumentationModal state={page} controller={indirectManagementDocumentationController} />} controller={indirectManagementDocumentationController} modaltitle="Inserisci un allegato" state={page} modalwidth="30vw">
                                <Row>
                                    <IndirectManagementDocumentationList {...indirectManagementDocumentations} items={page.item.state?.lists.documentations} modal={<IndirectManagementDocumentationModalEdit state={page} controller={indirectManagementDocumentationController} />} controller={indirectManagementDocumentationController} modaltitle="Modifica un allegato" modalwidth="30vw" state={page} readOnly={readOnly} />
                                </Row>
                            </Section>
                        </PivotItem>}
                    </Pivot>
                </Grid>
            </Item>
        </Content>
    </>
};
