import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

export default (props: { items: any }) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Piano' fieldName='planDescription' minWidth={150} align='left' />
                <DataColumn kid='column2' name='Plafond' fieldName='plafondName' minWidth={200} align='left' />
                <DataColumn kid='column3' name='n. Volte' fieldName='timesNumber' minWidth={80} maxWidth={80} align='right' />
                <DataColumn kid='column4' name='n. Volte Max' fieldName='maxTimesNumber' minWidth={80} maxWidth={80} align='right' />
                <DataColumn kid='column5' name='Spesa' fieldName='expense' minWidth={80} maxWidth={80} align='right' />
                <DataColumn kid='column6' name='Massimale' fieldName='roof' minWidth={80} maxWidth={80} align='right' />
                <DataColumn kid='column7' name='Data Inizio Copertura' fieldName='coverageStartDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column8' name='Data Fine Copertura' fieldName='coverageEndDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column9' name='Ultima Data Autorizzazione' fieldName='maxAuthDate' minWidth={220} align='center' />
            </DataList>
        </>
    );
}
