import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import Separator from '../../components/controls/grid/Separator';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const user = useLeapData("user", "utente", page)
    const start_date = useLeapData("start_date", "data inizio", page)
    const end_date = useLeapData("end_date", "data fine", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...user} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <Text {...start_date} className="ms-sm5" type="calendar" required />
                        <Separator className="ms-sm2" ></Separator>
                        <Text {...end_date} className="ms-sm5" type="calendar" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
