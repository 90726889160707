import { Loader } from "@googlemaps/js-api-loader";
import * as React from 'react';
import { FontIcon, PrimaryButton, DefaultButton } from "@fluentui/react";
import ReactDOMServer from 'react-dom/server';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";
import { AppDispatch } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { security } from "../security/thunks/securityThunk";
import { useBoolean } from '@uifabric/react-hooks';

export const MapResearch = createAsyncThunk(
    'item/MapResearch',
    async (args: { token: string | undefined, latitude: number, longitude: number, planID: number | undefined }) => {
        const response = await client.post("HealthFacilityConsult", {
            form: {
                latitude: args.latitude,
                longitude: args.longitude,
                planID: args.planID,
            }, page: null
        }, 'MapResearch', args.token)
        return response
    }
)
type Props = {
    position: {
        latitude: number,
        longitude: number
    },
    planID?: number,
    readonly: boolean,
    onChange?: any,
    minimal?: boolean
}

export default (props: Props) => {
    const loader = new Loader({
        apiKey: "AIzaSyDYejXRlDJHgXjAR1fRVizz1qgBp2FsP4g",
        version: "weekly",
        language: "it",
        region: "IT"
    });

    const [map, setMap] = React.useState<google.maps.Map>()

    const [markers, setMarkers] = React.useState<any>(null)
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral | null>(null)

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential

    React.useEffect(() => {
        loader.loadCallback(e => {
            if (e) {
                console.log(e);
            } else {
                const mapInstance = new google.maps.Map(document.getElementById("map") as HTMLDivElement, {
                    center: { lat: props.position.latitude, lng: props.position.longitude },
                    zoom: 6,
                })

                mapInstance.addListener('center_changed', () => {
                    if (mapInstance?.getCenter()) {
                        const mapCenter = mapInstance.getCenter()!
                        setCenter({ lat: mapCenter.lat(), lng: mapCenter.lng() })
                        setMap(mapInstance)
                    }
                })
            }
        });
    }, [])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (center && center.lat !== 0 && center.lng !== 180) {
                dispatch(MapResearch(
                    {
                        token: credential?.token,
                        latitude: center.lat,
                        longitude: center.lng,
                        planID: props.planID
                    }))
                    .then((response: any) => {
                        setMarkers(response.payload.data)
                        return response;
                    })
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [center]);

    let customMarkers: google.maps.Marker[] = [];
    let infowindows: google.maps.InfoWindow[] = [];

    React.useEffect(() => {
        for (let i = 0; i < customMarkers.length; i++) {
            customMarkers[i].setMap(null);
        }
        customMarkers = []

        if (!map) {
            return
        }

        if (!markers || markers?.length === 0) {
            return
        }

        markers?.map((item: any, index: any) => {
            let m = new google.maps.Marker({
                position: { lat: item?.latitude, lng: item?.longitude },
                title: "odonto",
                clickable: !!!props.minimal,
                icon: "/assets/media/images/maps.marker.48.png"
            });
            customMarkers.push(m)

            var i = new google.maps.InfoWindow({ content: "" });
            i.setContent(ReactDOMServer.renderToString(markerContent({ index: index, item: item, readonly: props.readonly, onChange: props.onChange })))
            infowindows.push(i);

            if (!!!props.minimal) {
                m.addListener("click", () => {
                    infowindows.map((i: google.maps.InfoWindow) => i.close());
                    i.open(map, m);
                });
                google.maps.event.addListener(i, 'domready', () => {
                    const someButton = document.getElementById("buttonMarker_" + index);
                    if (someButton) {
                        someButton.addEventListener('click', () => {
                            props.onChange(item)
                        })
                    }
                });
            }

            m.setMap(map)
        })

        google.maps.event.addListener(map, 'click', (mapsMouseEvent) => {
            infowindows.map((i: google.maps.InfoWindow) => i.close());
        });

    }, [markers, map]);

    return <div id="map" style={{ height: "68vh" }}></div>
}

type markerProps = {
    index: number,
    item: any,
    readonly: boolean,
    onChange: any
}

const markerContent = (props: markerProps) => {
    const phoneIcon = <FontIcon iconName="Phone" style={{ overflow: "visible" }} />
    const mailIcon = <FontIcon iconName="Mail" style={{ overflow: "visible" }} />
    const yes = <FontIcon iconName="Accept" style={{ overflow: "visible" }} />
    const no = <FontIcon iconName="Cancel" style={{ overflow: "visible" }} />

    const mailto = "mailto:" + props.item?.email;

    return <>
        <div className="mapMarkerPopup">
            <div>
                <b>{props.item?.buisnessName}</b>
            </div>
            <div>
                <i>{props.item?.structureType}</i>
            </div>
            <div>
                {props.item?.infoWindowSubHeading}
            </div>
            <div>
                {mailIcon}&nbsp;&nbsp;&nbsp;<a href={mailto}>{props.item?.email}</a><br />
            </div>
            <div>
                {phoneIcon}&nbsp;&nbsp;&nbsp;{props.item?.phone}
            </div>
            <div>
                {props.item?.opx === "Si" ? yes : no} &nbsp;&nbsp;&nbsp;Ortopantomografo<br /><br />
            </div>
            <div>
                {props.item?.vacations}
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>giorno</th>
                            <th>mattino</th>
                            <th>pomeriggio</th>
                        </tr>
                    </thead>
                    <tbody dangerouslySetInnerHTML={{ __html: props.item?.tableRow }}>
                    </tbody>
                </table>
            </div>
            {!props.readonly && props.onChange && <>
                <div>
                    <DefaultButton id={"buttonMarker_" + props.index} className="anchor" text="seleziona" hidden={props.readonly || !props.onChange} />
                </div>
            </>}
        </div>
    </>
}
