import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState'
import { useLeapData } from '../hooks/useLeapData'

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import SearchBox from '../components/controls/SearchBox';
import Option from '../components/controls/Option';

import ProvinceResultList from '../features/province/ProvinceResultList'

export default () => {
    const controller = "Province"
    const page = useLeapState(controller)

    const name = useLeapData("name", "nome", page)
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const valid = useLeapData("valid", "attiva", page, "yesno")

    return <>
        <Content title="Province" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm6" maxlength={50} />
                            <SearchBox {...regionID} className="ms-sm4" />
                        </Row>

                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <ProvinceResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...name} className="ms-sm6" required maxlength={50} />
                            <Option {...valid} className="ms-sm2" type="horizontal" />                            
                        </Row>
                        <Row>
                            <SearchBox {...regionID} className="ms-sm6" required />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}
