import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import SearchBox from '../components/controls/SearchBox';

import CostTableResultList from '../features/costTable/CostTableResultList';
import CostTablePlanList from '../features/costTable/CostTablePlanList';
import CostTablePlanModal from '../features/costTable/CostTablePlanModal';
import CostTableHealthServiceList from '../features/costTable/CostTableHealthServiceList';
import CostTableHealthServiceModal from '../features/costTable/CostTableHealthServiceModal';
import CostTableHealthServiceModalEdit from '../features/costTable/CostTableHealthServiceModalEdit';

export default () => {
    const controller = "CostTable"
    const page = useLeapState(controller)

    const costTablePlanController = "CostTablePlan"
    const costTableHealthServiceController = "CostTableHealthService"

    const costTableID = useLeapData("costTableID", "tabella costi", page, "costTables")
    const code = useLeapData("code", "codice", page)
    const description = useLeapData("description", "descrizione", page)

    return <>
        <Content title="Tabelle Costi" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...costTableID} className="ms-sm5" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <CostTableResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" readOnly />
                            <Text {...description} className="ms-sm5" required />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<Section title="elenco piani sanitari" toolbar="grid" modal={<CostTablePlanModal state={page} controller={costTablePlanController} />} controller={costTablePlanController} modalwidth="600px" modaltitle="Inserisci un piano sanitario" state={page} >
                        <Row>
                            <CostTablePlanList items={page.item.state?.lists.costTablePlans} controller={costTablePlanController} state={page} />
                        </Row>
                    </Section>)}

                    {page.modality === "item" && (<Section title="elenco costi prestazioni" toolbar="grid" modal={<CostTableHealthServiceModal state={page} controller={costTableHealthServiceController} />} controller={costTableHealthServiceController} modalwidth="600px" modaltitle="Inserisci una prestazione" state={page} >
                        <Row>
                            <CostTableHealthServiceList items={page.item.state?.lists.costTableHealthServices} modal={<CostTableHealthServiceModalEdit state={page} controller={costTableHealthServiceController} />} controller={costTableHealthServiceController} modalwidth="600px" modaltitle="Modifica una prestazione" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
