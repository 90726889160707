import { DialogFooter, FontSizes, getTheme, Icon, ILabelStyles, mergeStyleSets, Modal, PrimaryButton, Label, IconButton, IIconProps, FontWeights, Checkbox } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Coachmark from '../../components/controls/Coachmark';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { itemData } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';
import { filterForm } from '../operation/thunks/filterThunk';
import Option from '../../components/controls/Option';
import { setResponse } from '../response/responseSlice';
import { setOperation } from '../operation/operationSlice';

const DownloadWelfareWarrant = createAsyncThunk(
    'item/DownloadWelfareWarrant',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.downloadPost("HealthFacility", { form: { fileName: "mandatoWelfare.pdf" }, page: args.page }, 'DownloadWelfareWarrant', undefined, args.token)
        return response
    }
)
export const JoinWelfare = createAsyncThunk(
    'item/JoinWelfare',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("HealthFacility", { form: {}, page: args.page }, 'JoinWelfare', args.token)
        return response
    }
)

function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '60vw'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Raleway',
            fontSize: FontSizes.large,
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "justify",
        whiteSpace: "pre-wrap"
    }
}
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonStyles = {
    root: {
        color: Theme.fluent.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: Theme.fluent.palette.neutralDark,
    },
};

type Props = {
    hasWelfareWarrant: boolean
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [didDownload, { setTrue: downloaded }] = useBoolean(false);
    const [didAccept, { setTrue: accept, setFalse: deny }] = useBoolean(false);

    const credential = useSelector(security)?.credential

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === "HealthFacility")?.value
    const page = dItem?.metadata?.page

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", "HealthFacility"))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    React.useEffect(() => {
        if (isModalOpen) {
            timeout(1000).then(() => { showCoachmark() })
        }
    }, [isModalOpen])



    const downloadFile = () => {
        const args = {
            token: credential.token,
            page
        }
        dispatch(DownloadWelfareWarrant(args))
            .then(() => downloaded())
    }
    const joinWelfare = () => {
        dispatch(JoinWelfare({ token: credential.token, page }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
            .then(hideModal)
            .then(hideCoachmark)
    }

    const [coachmarkVisible, { setTrue: showCoachmark, setFalse: hideCoachmark }] = useBoolean(false);


    const hideModalAndCoachmark = () => {
        hideCoachmark();
        hideModal();
    }
    const reloadAndHideModalAndCoachmark = () => {
        dispatch(setOperation("reload", "HealthFacility"));
        hideCoachmark();
        hideModal();
    }

    const [isChecked, setIsChecked] = React.useState(false);
    const onChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsChecked(!!checked);
        },
        [],
    );
    const [isChecked2, setIsChecked2] = React.useState(false);
    const onChange2 = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsChecked2(!!checked);
        },
        [],
    );



    return (
        <>
            <PrimaryButton text="aderisci al welfare" onClick={showModal} style={{ marginTop: "20px" }} />
            {coachmarkVisible && !didDownload && (
                <Coachmark
                    target={"welfareFile"}
                    onDismiss={hideCoachmark}
                    title="Scarica mandato welfare"
                    content={<>per poter aderire al welfare, <br />si prega di scaricare questo file, <br /> premendo <Icon iconName="Download" /></>}
                    isWide={false} />
            )}
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} isBlocking={false} containerClassName={contentStyles.container} onDismiss={didDownload ? reloadAndHideModalAndCoachmark : hideModalAndCoachmark}>
                <div className={contentStyles.header}>
                    <span id={"JoinWelfare"}>{"Adesione Welfare"}</span>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <>
                            {!props.hasWelfareWarrant && <>
                                <>
                                    <Row>
                                        <Text
                                            onChange={() => { }}
                                            className="ms-sm11"
                                            label=""
                                            name=""
                                            type="plainWithIcon"
                                            value="Mandato Welfare"
                                            readOnly
                                            onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile()} style={{ cursor: "pointer", background: "transparent" }} id={"welfareFile"} /></> }} />

                                    </Row>
                                    <br />
                                </>
                                <Row>
                                    <Label styles={noDataFoundLabel}>
                                        Benvenuto nel Circuito Welfare!<br /><br />
                                        Grazie alla sottoscrizione della “CONVENZIONE RELATIVA A PRESTAZIONI SANITARIE MEDICO ODONTOIATRICHE” ONHC Servizi Sanitari e Welfare (Odontonetwork) ha potuto attivare accordi con nuovi Partner (Piattaforme di Welfare o nuove aziende per la gestione Welfare) ampliando cosi la gamma dei prodotti offerti.<br /><br />
                                        Per procedere con l’adesione al Circuito Welfare, è necessario: (i) abilitare la sua Struttura all’interno del gestionale (ii) conferire ad ONHC Servizi Sanitari e Welfare (Odontonetwork) apposito “Mandato Senza Rappresentanza” ai sensi dell’ art. 1705 del codice civile per svolgere in suo favore le seguenti attività:(a) promuovere la Struttura Sanitaria all’interno delle attività del Network; (b) vendere le Prestazioni che vengono erogate direttamente all’interno della Struttura Sanitaria attraverso gli strumenti di vendita attualmente in uso da ONHC Servizi Sanitari e Welfare (Odontonetwork)<br /><br />
                                    </Label>

                                </Row>
                                <Row>
                                    <Checkbox label="DICHIARO DI AVER CONFERITO A ONHC SERVIZI SANITARI E WELFARE MANDATO SENZA RAPPRESENTANZA AI SENSI DELL’ARTICOLO 1705 DEL CODICE CIVILE *" checked={isChecked} onChange={onChange} />
                                </Row>
                                <Row>
                                    <Checkbox label="DICHIARO DI AVER SCARICATO E RESTITUITO A MEZZO PEC L’ALLEGATO SOTTOSCRITTO *" checked={isChecked2} onChange={onChange2} />
                                </Row>
                                <br />
                                <Row>
                                    <Label styles={noDataFoundLabel}>
                                        * Per poter completare l’adesione al circuito welfare occorre dichiarare di aver conferito il mandato, scaricare il documento cliccando sull’apposito link Mandato Senza Rappresentanza e inviare l’allegato sottoscritto a <a href="mailto:onhc-servizi@pec.it">onhc-servizi@pec.it</a><br /><br />
                                    </Label>
                                </Row>
                                <div style={{ paddingBottom: 8 }}></div>
                            </>}
                            {
                                props.hasWelfareWarrant && <>
                                    <Row>
                                        <Label styles={noDataFoundLabel}>
                                            Completando l’adesione al Circuito welfare il nominativo della sua struttura sarà reso disponibile agli assistiti beneficiari di Welfare aziendale.
                                        </Label>
                                    </Row>
                                    <Row>
                                        <Label styles={noDataFoundLabel}>
                                            Procedendo con l’adesione la struttura dichiara di accettare le condizioni e le procedure previste nel contratto di convenzione sottoscritto.
                                        </Label>
                                    </Row>
                                </>
                            }
                        </>
                    </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={joinWelfare} text="aderisci al welfare" disabled={props.hasWelfareWarrant ? false : (!didDownload && !props.hasWelfareWarrant) || !isChecked2 || !isChecked} />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}