import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Codice Piano' fieldName='code' minWidth={100} maxWidth={120} align='right' />
                <DataColumn kid='column2' name='Descrizione Piano' fieldName='description' minWidth={300} maxWidth={500} align='left' />
                <DataColumn kid='column3' name='Matricola' fieldName='registerNumber' minWidth={300} maxWidth={500} align='left' />
                <DataColumn kid='column4' name='Azienda' fieldName='corporationID' minWidth={300} align='left' />
                <DataColumn kid='column5' name='Tipo Piano Sanitario' fieldName='leap_PlanManagementID' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column6' name='Data Inizio Validità' fieldName='startDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column7' name='Data Fine Validità' fieldName='endDate' minWidth={150} maxWidth={200} align='center' />
            </DataList>
        </>
    );
}
