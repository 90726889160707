import * as React from 'react';
import { CheckboxVisibility, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, Dialog, DialogFooter, DialogType, FontSizes, FontWeights, getTheme, IconButton, IDetailsListProps, IDetailsRowStyles, IIconProps, ILabelStyles, Label, mergeStyleSets, Modal, PrimaryButton, Selection, SelectionMode } from '@fluentui/react/';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { FontFaces } from '../../../defs/fonts';
import { Permission } from '../../../defs/permission';
import { isLocked, lock, unlock } from '../../../features/operation/lockSlice';
import { setOperation } from '../../../features/operation/operationSlice';
import { itemDownload, itemForm, itemValidation } from '../../../features/operation/thunks/itemThunk';
import { resetResponse, setResponse } from '../../../features/response/responseSlice';
import { security, units } from '../../../features/security/thunks/securityThunk';
import { useDatagridOperation } from '../../../hooks/useDatagridOperation';
import { useLeapState } from '../../../hooks/useLeapState';
import Theme from '../../themes/Theme';
import DataColumn, { getColumn } from './DataColumn';
import PagedDataList from './PagedDataList';

type Props = {
    className?: string,
    children: React.ReactNode,
    items: any[],
    onRowClick?: any,

    modal?: React.ReactNode,
    modalController?: string,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean

    selectionName?: string
    selectionMode?: SelectionMode
    onSelectionChange?: any
    onSelectionChangeFull?: any
    canSelectItem?: (data: any, index: any) => boolean,

    onEdit?: () => void
    onEditing?: (e: { nativeEvent: any, cancel: boolean, message: string }) => void,
    editLabel?: string

    onDeleting?: (e: { nativeEvent: any, cancel: boolean, message: string }) => void,
    onDelete?: (e: any, id?: any) => void,
    deleteText?: { buttonText?: string, messageText?: string, }

    onNavigating?: any,
    onCancelNavigate?: (e: { nativeEvent: any, cancel: boolean, message: string }) => void,
    navigateToController?: string

    paginate?: boolean,

    parentDatagridController?: string,
    parentDatagridId?: string | number
}

export default (props: Props) => {

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const datagridOperation = useDatagridOperation(props.modalController != null ? props.modalController : "", token)
    const parentDatagridOperation = !!props.parentDatagridController ? useDatagridOperation(props.parentDatagridController, token) : undefined

    const titleId = useId('title');
    const iForm = useSelector(itemForm)

    const dispatch = useDispatch();
    const validation = useSelector(itemValidation)
    const unitsContext = useSelector(units)

    const _isLocked = useSelector(isLocked)

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const [hideErrorDialog, { toggle: toggleHideErrorDialog }] = useBoolean(true);
    const [messageErrorDialog, setMessageErrorDialog] = React.useState('');

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const [currentItemId, setCurretItemId] = React.useState('')

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: props.modalwidth ?? '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                //borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };

    const dialogStyles = { main: { maxWidth: 450 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: props.deleteText?.messageText ?? 'Procedo alla eliminazione dell\'elemento?',
    };

    const dialogErrorContentProps = {
        type: DialogType.normal,
        title: 'Errore',
        closeButtonAriaLabel: 'Chiudi',
        subText: messageErrorDialog,
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const _onRenderRow: IDetailsListProps['onRenderRow'] = eventProps => {
        const customStyles: Partial<IDetailsRowStyles> = {}

        if (eventProps) {
            if (eventProps.itemIndex % 2 === 0) {
                customStyles.root = { backgroundColor: Theme.fluent.palette.themeLighterAlt, color: Theme.fluent.palette.neutralPrimaryAlt };
            }
            else {
                customStyles.root = { color: Theme.fluent.palette.neutralPrimaryAlt };
            }

            if (props.onRowClick) {
                const rowClick = props.onRowClick ?? ((e: any, data: any) => { })
                return <a style={{ cursor: 'pointer', display: 'block' }} onClick={e => { !props.onRowClick || dispatch(lock()); rowClick(e, eventProps.itemIndex, eventProps.item); }}><DetailsRow {...eventProps} styles={customStyles} /></a>
            }
            else {
                customStyles.cell = { cursor: 'default' }
                return <DetailsRow {...eventProps} styles={customStyles} />
            }
        }
        return null
    };

    const noDataFoundLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            whiteSpace: "nowrap"
        }
    }

    let body = {} as any
    const parentController = props?.state?.controller

    const onParentPageReload = () => {
        dispatch(setOperation("reload", parentController))
    }

    const onParentDatagridReload = () => {
        parentDatagridOperation?.load(props.parentDatagridId)
            .then(() => dispatch(unlock()))
    }

    const onReload = !!parentDatagridOperation ? onParentDatagridReload : onParentPageReload

    const onNavigating = (e: any) => {

        if (props.onCancelNavigate) {
            const event = { nativeEvent: e, cancel: false, message: "Impossibile procedere" }
            props.onCancelNavigate(event)

            if (event.cancel) {
                setMessageErrorDialog(event.message)
                toggleHideErrorDialog()
                return
            }
        }

        dispatch(lock())
        datagridOperation.navigate({ id: e.id })
            .then((response) => {
                document.location.href = '/' + props.navigateToController + '/?item'
            })
            .then(() => dispatch(unlock()))
    }

    const onEditing = (e: any) => {
        if (props.onEditing) {
            const event = { nativeEvent: e, cancel: false, message: "Impossibile procedere" }
            props.onEditing(event)

            if (event.cancel) {
                setMessageErrorDialog(event.message)
                toggleHideErrorDialog()
                return
            }
        }

        dispatch(resetResponse(false))

        datagridOperation.load(e.id)
            .then(() => setCurretItemId(e.id))
            .then(() => showModal())
    }
    const onEdit = () => {
        let mandatory = validation.find(v => v.key === props.modalController)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere al salvataggio.", 5, true))
            return;
        }

        if (props.onEdit) {
            hideModal()
            props.onEdit()
            return
        }

        let form = {} as any
        let pForm = {} as any
        form = Object.assign(form, iForm.find(i => i.key === props.modalController)?.value)
        pForm = Object.assign(pForm, iForm.find(i => i.key === parentController)?.value)
        body.form = { ...form, id: currentItemId }
        body.parent = {
            form: { ...pForm },
            page: {}
        }
        dispatch(lock())
        datagridOperation.save(body)
            .then((response) => {
                if (response.payload.response.level === 4) {
                    hideModal()
                    return true
                }
                else {
                    dispatch(unlock())
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    onReload()
                }
            })
    }
    const onDeleting = (e: any) => {
        if (props.onDeleting) {
            const event = { nativeEvent: e, cancel: false, message: "Impossibile procedere" }
            props.onDeleting(event)

            if (event.cancel) {
                setMessageErrorDialog(event.message)
                toggleHideErrorDialog()
                return
            }
        }

        dispatch(resetResponse(false))

        setCurretItemId(e.id)
        toggleHideDialog()
    }
    const onDelete = (e: any) => {
        if (props.onDelete) {
            toggleHideDialog();
            props.onDelete(e, currentItemId);
            return
        }
        let form = {} as any
        form = Object.assign(form, iForm.find(i => i.key === props.modalController)?.value)
        body.form = { ...form, id: currentItemId }

        dispatch(lock())
        datagridOperation.delete(body)
            .then(() => toggleHideDialog())
            .then(() => onReload())
    }

    const credential = useSelector(security)?.credential
    const page = useLeapState(iForm.find(i => i.key === props.modalController)?.value, false)

    const onDownloading = (e: any) => {

        const args = {
            controller: props.modalController ?? "",
            id: e["id"] ?? undefined,
            fileID: parseInt(e["fileID"]),
            //page?.item?.state?.data?.fileID,
            fileName: e["fileName"],
            // page?.item?.state?.data?.fileNameExt,
            //fileName: page?.item?.state?.data?.fileName, 
            token: credential?.token
        }

        dispatch(itemDownload(args))

    }

    const onCancel = (e: any) => {
        e.preventDefault()
        dispatch(resetResponse(false))

        hideModal()
    }

    const onSelection = () => {
        if (props.onSelectionChange) {
            props.onSelectionChange(props.selectionName, selection.getSelection().map((k: any) => k.id), false)
        }
        if (props.onSelectionChangeFull) {
            props.onSelectionChangeFull(props.selectionName, selection.getSelection().map((k: any) => k), false)
        }
    }
    const selection = new Selection({ canSelectItem: props.canSelectItem, onSelectionChanged: onSelection })

    const hasPermission = (permission: Permission): boolean => {
        var permissions = unitsContext?.units.permissions
        if (!permissions) return false
        if (!props?.modalController) {
            return false;
        }
        var controllerPermissions = permissions[props.modalController.toLowerCase()]
        if (!controllerPermissions) return false;

        if (!controllerPermissions.find((cp: Permission) => cp == permission)) return false;

        return true
    }

    const filterDataColumn = (child: any) => {
        if (props.readOnly && (child.props.type === "edit" || child.props.type === "delete")) {
            return false
        }
        if (!hasPermission(Permission.Update) && child.props.type === "edit") {
            return false
        }
        if (!hasPermission(Permission.Delete) && child.props.type === "delete") {
            return false
        }
        if (!hasPermission(Permission.Read) && child.props.type === "navigate") {
            return false
        }
        return true
    }

    if (props?.items?.length > 0) {
        const DetailsListProps: Partial<IDetailsListProps> = {
            className: "alarms-datails-list",
            compact: true,
            layoutMode: DetailsListLayoutMode.justified,
            setKey: "set",
            checkboxVisibility: CheckboxVisibility.always,
            selectionMode: props.selectionMode ?? SelectionMode.none,
            selectionPreservedOnEmptyClick: true,
            onRenderRow: _onRenderRow,
        }

        const dataColumns = React.Children.map(props.children, child => (child as DataColumn))
        const filteringDataColumns = dataColumns?.filter(filterDataColumn)

        if (!filteringDataColumns) return <></>
        const columns = React.Children.map(filteringDataColumns, ((child: DataColumn) => getColumn(child.props, onEditing, onDeleting, props.onNavigating ?? onNavigating, onDownloading)))

        if (!columns) return <></>
        return <>
            <div className={props.className ?? "ms-Grid-col ms-sm12"}>
                {props.paginate &&
                    <PagedDataList
                        items={props.items}
                        columns={columns ?? [{ key: 'NO_KEY', name: 'NO_NAME', fieldName: 'NO_FIELDNAME', minWidth: 0 }]}
                        onRowClick={props.onRowClick}
                        selection={selection}
                        selectionMode={DetailsListProps.selectionMode} />}
                {!props.paginate &&
                    <DetailsList
                        {...DetailsListProps}
                        columns={columns ?? [{ key: 'NO_KEY', name: 'NO_NAME', fieldName: 'NO_FIELDNAME', minWidth: 0 }]}
                        items={props.items}
                        selection={selection} />}

                <Modal
                    titleAriaId={titleId}
                    isOpen={isModalOpen}
                    containerClassName={contentStyles.container}
                    isBlocking={_isLocked}
                    onDismiss={(ev) => { if (_isLocked) { ev?.preventDefault() } else { hideModal() } }}
                    ignoreExternalFocusing={_isLocked}>

                    <div className={contentStyles.header}>
                        <span id={titleId}>{props.modaltitle}</span>
                        <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideModal} disabled={_isLocked} />
                    </div>
                    <div className={contentStyles.body}>
                        {props.modal}
                        <DialogFooter>
                            <PrimaryButton onClick={onEdit} text={props.editLabel ?? "Salva"} disabled={_isLocked} />
                            <DefaultButton onClick={onCancel} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </div>
                </Modal>

                <Dialog
                    hidden={hideDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}
                    isBlocking={_isLocked}
                    onDismiss={(ev) => { if (_isLocked) { ev?.preventDefault() } else { toggleHideDialog() } }}
                    ignoreExternalFocusing={_isLocked}>
                    <DialogFooter>
                        <PrimaryButton onClick={onDelete} text={props.deleteText?.buttonText ?? "Elimina"} disabled={_isLocked} />
                        <DefaultButton onClick={toggleHideDialog} text="Annulla" disabled={_isLocked} />
                    </DialogFooter>
                </Dialog>
                <Dialog hidden={hideErrorDialog} onDismiss={toggleHideErrorDialog} dialogContentProps={dialogErrorContentProps} modalProps={modalProps} isBlocking={_isLocked}>
                    <DialogFooter>
                        <DefaultButton onClick={toggleHideErrorDialog} text="Chiudi" disabled={_isLocked} />
                    </DialogFooter>
                </Dialog>
            </div>
        </>
    } else {
        return <div className={props.className ?? "ms-Grid-col ms-sm12"}>
            <Label styles={noDataFoundLabel}>nessun dato presente a sistema</Label>
        </div>
    }
}
