import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Separator from '../../components/controls/grid/Separator';

import Text from '../../components/controls/Text';
import ComboBox from '../../components/controls/ComboBox';

type Props = {
    state: any,
    controller: any,
    currentPeriodEndDate?: any,
    currentPeriodStartDate?: any,
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)
    const actionType = useLeapData("actionType", "tipo modifica", page, "actionTypes")
    const editOption = useLeapData("editOption", "scelta modifica", page, "editOptions")
    const newEndDate = useLeapData("newEndDate", "fine periodo di validità", page)

    const editOptionValue = page.item?.values?.editOption?.value;

    let minDate: any;
    let maxDate: any;

    let newDate = new Date();

    const getDateRange = (editOptionValue: string) => {
        switch (editOptionValue) {
            case "1":
                minDate = newDate.toString();
                maxDate = props.currentPeriodEndDate;
                return [minDate, maxDate]
            case "2":
                minDate = newDate.toString();
                maxDate = props.currentPeriodEndDate;
                return [minDate, maxDate]
            case "3":
            case "4":
                minDate = props.currentPeriodEndDate;
                maxDate = undefined;
                return [minDate, maxDate]
            default:
                minDate = undefined;
                maxDate = undefined;
                return [minDate, maxDate]
        }
    }

    const editOptionV = (item: string, state: string) => {
        if (item === "1") {
            return true;
        }
        else if (state === "1") {
            return true;
        }
        else if (!item && !state) {
            return true;
        }
        return false;
    }

    const management_item: string = page.item?.values?.editOption?.value;
    const management_state: string = page?.item?.state?.data?.editOption;
    const hasEditOptionValue: boolean = editOptionV(management_item, management_state);


    const result = getDateRange(editOptionValue);
    const min = result[0]
    const max = result[1]
    //new Date(props.currentPeriodEndDate).toLocaleDateString("it-IT")
    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text readOnly label="data di inizio periodo corrente" value={props.currentPeriodStartDate} className="ms-sm5" type="calendar" />
                        <Separator className="ms-sm2"></Separator>
                        <Text readOnly label="data di fine periodo corrente" value={props.currentPeriodEndDate} className="ms-sm5" type="calendar" />
                    </Row>
                    <Row>
                        <ComboBox {...editOption} className="ms-sm12" required />
                        <ComboBox {...actionType} hidden={hasEditOptionValue} className="ms-sm5" required={!hasEditOptionValue} />
                    </Row>
                    <Row>
                        <Text {...newEndDate} dateRange={{ minDate: min, maxDate: max }} defaultDate={min} className="ms-sm5" required type="calendar" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
//
