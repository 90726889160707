import * as React from 'react';

import { useDispatch } from 'react-redux'

import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: 0 }))
    }
    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Descrizione' fieldName='description' minWidth={40} align='left' />
            </DataList>
        </>
    );
}