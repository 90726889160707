import { Dropdown, FontSizes, IDropdown, IDropdownOption, IDropdownProps } from '@fluentui/react/'
import * as React from 'react'
import { useEffect } from 'react'
import { FontFaces } from '../../defs/fonts'
import Theme from '../themes/Theme'
import { useSelector, useDispatch } from 'react-redux'
import { currentResponse } from '../../features/response/responseSlice'
import { itemLookup } from '../../features/operation/thunks/itemThunk'
import { AppDispatch } from '../../app/store'
import { security } from '../../features/security/thunks/securityThunk'
import { currentModality } from '../../features/modality/modalitySlice'
import { filterLookup } from '../../features/operation/thunks/filterThunk'

const DropdownProps: Partial<IDropdownProps> = {
    styles: {
        root: {
            paddingTop: 3,
            paddingBottom: 4,
        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 2,
            paddingLeft: 4,
        },
    }
}
const ErrorDropdownProps: Partial<IDropdownProps> = {
    styles: {
        root: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            borderColor: Theme.leap.customization.error.borderColor,
            borderWidth: 1,
            paddingTop: 3,
            paddingBottom: 4,

        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 2,
            paddingLeft: 4,
        },/*
        input: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            borderColor: Theme.leap.customization.error.borderColor,
            borderWidth: 1,
        },*/
    }
}

type Props = {
    name: string,
    className: string,
    label: string,
    placeHolder?: string,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    selectedKey?: string[],
    options: IDropdownOption[],
    onInit?: (name: string | undefined, value: any, required?: boolean | undefined) => void,
    onChange?: (name: string | undefined, value: any, required?: boolean | undefined) => void,
    onRenderTitle?: (options: IDropdownOption[] | undefined) => JSX.Element | null,
    autocomplete?: ('on' | 'off'),
    allow?: boolean,
    children?: { name: string, lookup: string, controller: string, required?: boolean, defaultValue?: any }[],
    disabled?: boolean,
    hidden?: boolean,
    titleType?: 'key' | 'text'
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality)
    const response = useSelector(currentResponse)

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const DropdownStyle = response.notified && !mandatory ? { ...ErrorDropdownProps } : { ...DropdownProps }

    useEffect(() => {
        if (props.onInit) {
            props.onInit(props.name, props.selectedKey ?? [], props.required ?? false)
        }

        setSelectedKeys(props.selectedKey ?? [])
    }, [props.selectedKey, props.required])

    const onRenderTitle = (options: IDropdownOption[] | undefined) => {
        if (props.onRenderTitle) {
            return props?.onRenderTitle(options)
        }
        const compare = (a: any, b: any) => {
            return a - b
        }
        var selectedKeys = options?.map(o => "[" + o[props.titleType ?? 'key'] + "]").sort(compare).join(" ")
        return <>{selectedKeys}</>
    }

    const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {

        var newSelectedKeys = option ? (option.selected ? [...selectedKeys, option.key as string] : selectedKeys.filter(key => key !== option.key)) : selectedKeys

        if (option) setSelectedKeys(newSelectedKeys)

        if (props.onChange)
            props.onChange(props.name, newSelectedKeys, props.required)

        if (props.children) {
            if (modality === "filter") {
                props.children.forEach((c) => {
                    dispatch(filterLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: { form: { [props.name]: newSelectedKeys } }, token: token })) //option?.key
                    if (props.onChange)
                        props.onChange(c.name, c.defaultValue ?? [], c.required ?? false)
                })
            }
            else if (modality === "item" || modality === "new") {
                props.children.forEach((c) => {
                    dispatch(itemLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: { form: { [props.name]: newSelectedKeys } }, token: token })) //option?.key
                    if (props.onChange)
                        props.onChange(c.name, c.defaultValue ?? [], c.required ?? false)
                })
            }
        }
    }
    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <Dropdown {...DropdownStyle} multiSelect label={props.label} placeholder={props.placeHolder} disabled={props.disabled} required={props.required} selectedKeys={selectedKeys} options={props.options} onChange={onChange} onRenderTitle={onRenderTitle} />
        </div>
    </>
}