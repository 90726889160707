import { PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import FormResultList from '../features/form/FormResultList';
import QuestionList from '../features/form/QuestionList';
import QuestionModal from '../features/form/QuestionModal';
import { isLocked, lock, unlock } from '../features/operation/lockSlice';
import { security } from '../features/security/thunks/securityThunk';
import { useDatagridOperation } from '../hooks/useDatagridOperation';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

export default () => {
    const controller = "Form";
    const questionController = "Question";

    const roles = useSecurityRoles()
    const page = useLeapState(controller, roles.role !== undefined)

    const position = useLeapData("position", "posizione", page)
    const title = useLeapData("titleF", "titolo", page)

    const dispatch: AppDispatch = useDispatch()

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token
    const datagridOperation = useDatagridOperation("Feedback", token)

    const navigateToFeedback = () => {
        dispatch(lock())
        datagridOperation.navigate({ id: page.item?.state?.data?.id })
            .then((response) => {
                document.location.href = '/Feedback/?item'
            })
            .then(() => dispatch(unlock()))
    }

    return <>
        <Content title="Modulo" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...title} className="ms-sm5" />
                            <Text {...position} className="ms-sm1" type='number' />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <FormResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...title} className="ms-sm5" required />
                            <Text {...position} className="ms-sm1" type='number' required />
                            <PrimaryButton text="torna al feedback" onClick={navigateToFeedback} style={{ marginTop: "20px" }} />
                        </Row>
                    </Section>
                    <Section title="Domande" toolbar="grid" modal={<QuestionModal state={page} controller={questionController} />} controller={questionController} modalwidth="800px" modaltitle="Inserisci Domanda" state={page} >
                        <Row>
                            <QuestionList items={page.item.state?.lists?.questions} modal={<QuestionModal state={page} controller={questionController} />} controller={questionController} modalwidth="800px" modaltitle="Modifica Domanda" state={page} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
