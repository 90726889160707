import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { iStateThunks } from "../../../defs/thunks"

export const addBuilder = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload

        let data = state.data.map(d => d.key === key ? { key: key, value: value } : d)
        if (!data.find(d => d.key === key)) {
            data.push({ key: key, value: value })
        }

        let form = state.form.map(f => f.key === key ? { key: key, value: value?.data } : f)
        if (!form.find(f => f.key === key)) {
            form.push({ key: key, value: value?.data })
        }

        state.data = data
        state.response = { message: value?.response?.message, level: value?.response?.level }
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}
export const addBuilderLookup = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload

        let data = state.data.find(d => d.key === key)
        if (!data) {
            return;
        }

        var lookup = action.meta.arg.lookup
        data.value.lookups[lookup] = value.lookups?.[lookup]

        var target = action.meta.arg.target
        data.value.data[target] = ""

        let stateData = state.data.map(d => d.key === key ? { key: key, value: data?.value } : d)

        state.data = stateData
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}