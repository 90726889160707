import * as React from 'react';
import { useLocation } from 'react-router';
import { useSecuritydOperation } from '../hooks/useSecurityOperation';

export default () => {
    const { validateEmail } = useSecuritydOperation();

    const search = useLocation()?.search;
    const ConfirmationCode = new URLSearchParams(search)?.get('g');

    React.useEffect(() => {
        if (!!ConfirmationCode) {
            validateEmail(ConfirmationCode)
                .then(() => document.location.href = '/login/');                
        } else {
            document.location.href = '/login/'
        }
    }, [ConfirmationCode])

    return <></>
}
