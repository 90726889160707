import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)
    const code = useLeapData("code", "codice", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const amount = useLeapData("amount", "importo", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...code} className="ms-sm12" readOnly />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm5", required: true }} />
                    </Row>
                    <Row>
                        <Text {...amount} className="ms-sm5" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
