import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Data di Inizio Sospensione' fieldName='suspensionStartDate' minWidth={250} maxWidth={250} align='center' />
                <DataColumn kid='column2' name='Data di Fine Sospensione' fieldName='suspensionEndDate' minWidth={250} align='center' />
            </DataList>
        </>
    );
}
