import { combineReducers } from '@reduxjs/toolkit'
import modalityReducer from '../features/modality/modalitySlice'
import securityReducer from '../features/security/thunks/securityThunk'
import planReducer from '../features/plan/PlanSlice'
import roleReducer from '../features/role/RoleSlice'
import operationReducer from '../features/operation/operationSlice'
import responseReducer from '../features/response/responseSlice'

import filterReducer from '../features/operation/thunks/filterThunk'
import resultReducer from '../features/operation/thunks/resultThunk'
import itemReducer from '../features/operation/thunks/itemThunk'

import lockReducer from '../features/operation/lockSlice'

const rootReducer = combineReducers({
    security: securityReducer,
    modality: modalityReducer,
    response: responseReducer,
    plan: planReducer,
    role: roleReducer,
    operation: operationReducer,
    filter: filterReducer,
    result: resultReducer,
    item: itemReducer,
    lock: lockReducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;