export type dataThunks = { key: string, value: any }
export type validationThunks = {
    key: string,
    value: {
        mandatory: boolean | undefined,
        validity: boolean | undefined,
    }
}

export interface iStateThunks {
    data: dataThunks[];
    form: dataThunks[];
    validation: validationThunks[];
    status: string;
    error: any;
    response: { message: string, level: number | undefined };
}

export interface iMinPayloadThunks {
    controller: string | "unset";
}

export interface iPayloadThunks {
    controller: string | "unset";
    data: { name: any, value: any };
}
export interface iExtendedPayloadThunks {
    controller: string | "unset";
    parentController: string | "unset";
    data: any;
}

export interface iResponseThunks {
    message: string,
    level: number | undefined
}

export interface iPayloadResponseThunks {
    controller: string | "unset";
    response: iResponseThunks;
}

export interface ResponsePayload {
    response: {
        message: string,
        level: number | undefined,
        notified?: boolean | undefined,
        keep?: boolean | undefined
    }
}

export interface LockPayload {
    locked: boolean
}

export const initialStateThunks: iStateThunks = {
    data: [],
    form: [],
    validation: [],
    status: 'idle',
    error: null,
    response: { message: '', level: undefined } as iResponseThunks
}
