import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';

import { AppDispatch } from '../../app/store';
import { client } from '../../api/client';

import { useSecurityRoles } from '../../hooks/useSecurityRoles';

import { security } from '../security/thunks/securityThunk';
import { itemData } from '../operation/thunks/itemThunk';

import { setResponse } from '../response/responseSlice';
import { setOperation } from '../operation/operationSlice';
import { isLocked, lock, unlock } from '../operation/lockSlice';

import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType, IButtonProps } from '@fluentui/react';

export const gotoSent = createAsyncThunk(
    'item/gotoSent',
    async (args: { indirectManagementID: string, token: string | undefined }) => {
        const response = await client.post("IndirectManagement", { form: { id: args.indirectManagementID } }, 'Send', args.token)
        return response
    }
)
export const gotoProcessing = createAsyncThunk(
    'item/gotoProcessing',
    async (args: { indirectManagementID: string, token: string | undefined }) => {
        const response = await client.post("IndirectManagement", { form: { id: args.indirectManagementID } }, 'Process', args.token)
        return response
    }
)
export const gotoApproved = createAsyncThunk(
    'item/gotoApproved',
    async (args: { indirectManagementID: string, token: string | undefined }) => {
        const response = await client.post("IndirectManagement", { form: { id: args.indirectManagementID } }, 'Approve', args.token)
        return response
    }
)
export const gotoRejected = createAsyncThunk(
    'item/gotoRejected',
    async (args: { indirectManagementID: string, token: string | undefined }) => {
        const response = await client.post("IndirectManagement", { form: { id: args.indirectManagementID } }, 'Reject', args.token)
        return response
    }
)
export const gotoCanceled = createAsyncThunk(
    'item/gotoCanceled',
    async (args: { indirectManagementID: string, token: string | undefined }) => {
        const response = await client.post("IndirectManagement", { form: { id: args.indirectManagementID } }, 'Cancel', args.token)
        return response
    }
)

export default (props: { controller: string, itemState: any }) => {
    const dispatch: AppDispatch = useDispatch();

    const credential = useSelector(security)?.credential
    const _isLocked = useSelector(isLocked)

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHCPower: boolean = isONHCMaster || isONHCAccount
    const isONHC: boolean = roles.isONHC;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF: boolean = roles.isHF;

    const isInsured: boolean = roles.isInsuredUser;

    const onClickSend = (e: any) => {
        e.preventDefault()
        toggleHideDialogSend()
    }
    const [hideDialogSend, { toggle: toggleHideDialogSend }] = useBoolean(true);
    const dialogContentPropsSend = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con l\'invio della richiesta?',
    };
    const onClickProcess = (e: any) => {
        e.preventDefault()
        toggleHideDialogProcess()
    }
    const [hideDialogProcess, { toggle: toggleHideDialogProcess }] = useBoolean(true);
    const dialogContentPropsProcess = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Invio la richiesta in lavorazione?',
    };
    const onClickApprove = (e: any) => {
        e.preventDefault()
        toggleHideDialogApprove()
    }
    const [hideDialogApprove, { toggle: toggleHideDialogApprove }] = useBoolean(true);
    const dialogContentPropsApprove = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Approvo la richiesta?',
    };
    const onClickReject = (e: any) => {
        e.preventDefault()
        toggleHideDialogReject()
    }
    const [hideDialogReject, { toggle: toggleHideDialogReject }] = useBoolean(true);
    const dialogContentPropsReject = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Respingo la richiesta?',
    };
    const onClickCancel = (e: any) => {
        e.preventDefault()
        toggleHideDialogCancel()
    }
    const [hideDialogCancel, { toggle: toggleHideDialogCancel }] = useBoolean(true);
    const dialogContentPropsCancel = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con l\'Annullamento della richiesta?',
    };

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const iForm = useSelector(itemData)
    const args = { indirectManagementID: iForm.find(i => i.key === props.controller)?.value?.data?.id, token: credential?.token }

    const onExecuteSend = (e: any) => {
        toggleHideDialogSend()
        dispatch(lock())
        dispatch(gotoSent(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteProcess = (e: any) => {
        toggleHideDialogProcess()
        dispatch(lock())
        dispatch(gotoProcessing(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteApprove = (e: any) => {
        toggleHideDialogApprove()
        dispatch(lock())
        dispatch(gotoApproved(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteReject = (e: any) => {
        toggleHideDialogReject()
        dispatch(lock())
        dispatch(gotoRejected(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteCancel = (e: any) => {
        toggleHideDialogCancel()
        dispatch(lock())
        dispatch(gotoCanceled(args)).then(dispatchMessage).then(dispatchReload)
    }

    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const indirectManagementType: string = props.itemState?.data?.leap_IndirectManagementStateID
    
    const canSend = isInsured && indirectManagementType === "1" && (props.itemState?.lists?.healthServices?.length > 0)
    const canProcess = isONHC && (indirectManagementType === "1" || indirectManagementType === "2") && (props.itemState?.lists?.healthServices?.length > 0) && (props.itemState?.lists?.invoices?.length > 0)
    const canApprove = isONHC && (indirectManagementType === "3") && (props.itemState?.lists?.healthServices?.length > 0)
    const canReject = isONHC && (indirectManagementType === "2" || indirectManagementType === "3") && (props.itemState?.lists?.healthServices?.length > 0)
    const canCancel = indirectManagementType === "1" || indirectManagementType === "2" || indirectManagementType === "3" || (isONHC && indirectManagementType === "6")

    return (
        <>
            <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 25, textAlign: "right" }}>
                {canSend && <>
                    <PrimaryButton {...ButtonProps} text='invia richiesta' onClick={onClickSend} disabled={_isLocked} />
                    <Dialog hidden={hideDialogSend} onDismiss={toggleHideDialogSend} dialogContentProps={dialogContentPropsSend} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteSend} text='Invia la richiesta' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogSend} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>}
                {canProcess && <>
                    <PrimaryButton {...ButtonProps} text='Invia in lavorazione' onClick={onClickProcess} disabled={_isLocked} />
                    <Dialog hidden={hideDialogProcess} onDismiss={toggleHideDialogProcess} dialogContentProps={dialogContentPropsProcess} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteProcess} text='Invia in lavorazione' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogProcess} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>}
                {canApprove && <>
                    <PrimaryButton {...ButtonProps} text='Approva' onClick={onClickApprove} disabled={_isLocked} />
                    <Dialog hidden={hideDialogApprove} onDismiss={toggleHideDialogApprove} dialogContentProps={dialogContentPropsApprove} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteApprove} text='Approva la richiesta' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogApprove} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>}
                {canReject && <>
                    <PrimaryButton {...ButtonProps} text='Rifiuta' onClick={onClickReject} disabled={_isLocked} />
                    <Dialog hidden={hideDialogReject} onDismiss={toggleHideDialogReject} dialogContentProps={dialogContentPropsReject} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteReject} text='Rifiuta la richiesta' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogReject} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>}
                {canCancel && <>
                    <PrimaryButton {...ButtonProps} text='Annulla' onClick={onClickCancel} disabled={_isLocked} />
                    <Dialog hidden={hideDialogCancel} onDismiss={toggleHideDialogCancel} dialogContentProps={dialogContentPropsCancel} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteCancel} text='Annulla la richiesta' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogCancel} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>}
            </div>
        </>
    );
}