import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import DateRange from '../../components/controls/DateRange';
import { PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { security } from '../security/thunks/securityThunk';
import { setResponse } from '../response/responseSlice';
import { itemData } from '../operation/thunks/itemThunk';
import { setOperation } from '../operation/operationSlice';

type Props = { state: any, controller: any }

export const ResetPassword = createAsyncThunk(
    'item/ResetPassword',
    async (args: { token: string | undefined, id: any }) => {
        const response = await client.post("HealthFacilityOperator", { form: { id: args.id }, page: {} }, 'ResetPassword', args.token)
        return response
    }
)

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const code = useLeapData("code", "codice", page)
    const leap_HealthFacilityOperatorAccessID = useLeapData("leap_HealthFacilityOperatorAccessID", "accesso", page, "accessOptions")
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const email = useLeapData("email", "email", page)

    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === props.controller)?.value

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", "HealthFacility"))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onResetPassword = () => {
        dispatch(ResetPassword(
            {
                token: credential.token,
                id: dItem?.data?.id
            }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }
    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...code} className="ms-sm6" readOnly />
                        <PrimaryButton text="resetta password" onClick={onResetPassword} style={{ marginTop: "20px", marginLeft: "8px" }} />
                    </Row>
                    <Row>
                        <Text {...lastname} className="ms-sm6" />
                        <Text {...firstname} className="ms-sm6" />
                    </Row>
                    <Row>
                        <Text {...email} className="ms-sm6" readOnly />
                        <Option {...leap_HealthFacilityOperatorAccessID} className="ms-sm4" type="horizontal" disabled />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...start_date, className: "ms-sm4", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...end_date, className: "ms-sm4" }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
