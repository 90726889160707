import * as React from 'react';
import { useLocation } from 'react-router';

import Header from './Header';
import Main from './Main';
import Footer from './Footer';

import Theme from '../../themes/Theme';

import './layout.scss';

import PrivacyBlockingModal from '../../../features/insured/PrivacyBlockingModal';
import BackToTop from '../../controls/BackToTop';
import Loading from '../../controls/Loading';
import { useSelector } from 'react-redux';
import { authorized } from '../../../features/security/thunks/securityThunk';

const headerStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.header.backgroundColor,
};
const mainStyles: React.CSSProperties = {
    borderTopColor: Theme.leap.header.backgroundColor,
    borderTopStyle: "solid",
    borderTopWidth: 0,
    flex: 1,
    overflow: 'visible'
};
const footerStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.footer.backgroundColor,
    color: Theme.leap.footer.color,
    width: '100%',
};

export default (props: { children?: React.ReactNode }) => {
    const location = useLocation()
    const isAuthorized = useSelector(authorized)

    return (<>
        {(isAuthorized && location.pathname != '/changepassword/' && location.pathname != '/ChangePassword/') && <>
            <header className="sticky-top" style={headerStyles}>
                <Header />
            </header>
        </>}
        <main style={mainStyles}>
            <Main>
                {props.children}
                <PrivacyBlockingModal />
                <BackToTop />
                <Loading />
            </Main>
        </main>
        {isAuthorized && <>
            <footer className="sticky-bottom" style={footerStyles}>
                <Footer />
            </footer>
        </>}
    </>);
};