import * as React from 'react';
import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { unlock } from '../operation/lockSlice';

export default (props: { controller: string, items: any, isCorporation?: boolean, isCompany?: boolean , isHealthFacility?: boolean}) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        if (!props.isCorporation && !props.isCompany && !props.isHealthFacility) {
            dispatch(setOperation("read", props.controller, { index: data }))
        }
        else
        {
            dispatch(unlock())
        }
    }

    const canViewColumns = !props.isCorporation && !props.isCompany
    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                {canViewColumns && <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={80} maxWidth={100} align='left' />}

                <DataColumn kid='column2' name='Cognome' fieldName='lastname' minWidth={200} maxWidth={250} align='left' />
                <DataColumn kid='column3' name='Nome' fieldName='firstname' minWidth={200} maxWidth={250} align='left' />
                <DataColumn kid='column4' name='Codice Fiscale' fieldName='fiscalcode' minWidth={120} maxWidth={150} align='left' />

                {canViewColumns && <DataColumn kid='column5' name='Email' fieldName='email' minWidth={200} align='left' />}
                {canViewColumns && <DataColumn kid='column6' name='Telefono' fieldName='phoneNumber' minWidth={100} maxWidth={120} align='left' />}
                {canViewColumns && <DataColumn kid='columnYN' type="icon" name='Privacy Accettata' fieldName='leap_InsuredAcceptedPrivacyID' minWidth={120} maxWidth={150} align='center' />}
            </DataList>
        </>
    );
}
