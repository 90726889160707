import * as React from 'react';
import { MaskedTextField, ITextFieldProps } from '@fluentui/react/';
import { Icon } from '@fluentui/react/';

import Theme from '../themes/Theme';
import { useState, useEffect } from 'react';
import { TimeFieldProps } from '../../defs/props';
import { TextFieldProps, ErrorTextFieldProps } from '../../defs/props';
import { useSelector } from 'react-redux';
import { currentResponse } from '../../features/response/responseSlice';

export default (props: TimeFieldProps) => {
    const response = useSelector(currentResponse)
    const [valid, setValid] = useState(true)

    const defaultValue = "  :  "

    const TimeFieldProps: ITextFieldProps = {
        ...TextFieldProps,
        styles: {
            ...TextFieldProps.styles,
            fieldGroup: {
                borderColor: Theme.fluent.palette.neutralTertiary,
                borderWidth: 1,
                marginTop: props.label.length === 0 ? 19 : 0
            },
            suffix: {
                backgroundColor: "transparent",
                cursor: "default",
                marginTop: 1
            }
        }
    }

    const ErrorTimeFieldProps: ITextFieldProps = {
        ...ErrorTextFieldProps,
        styles: {
            ...ErrorTextFieldProps.styles,
            fieldGroup: {
                backgroundColor: Theme.leap.customization.error.backgroundColor,
                borderColor: Theme.leap.customization.error.borderColor,
                borderWidth: 1,
                marginTop: props.label.length === 0 ? 19 : 0
            },
            suffix: {
                backgroundColor: "transparent",
                cursor: "default",
                marginTop: 1
            }
        }
    }

    useEffect(() => {
        if (props.onInit) {
            props.onInit(props.name, props.value, props.required)
        }
    }, [props.value])

    const onBlurHandler = (e: any) => {
        var re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

        var value = e.target.value

        if (value === defaultValue) {
            setValid(true)
            return
        }

        if (value?.match(re)) {
            setValid(true)
            return
        }

        setValid(false)

        if (props.onChange)
            props.onChange(props.name, "", props.required)

        e.target.focus()
    }
    const onChangeHandler = (e: any, value: string | undefined) => {
        if (props.onChange)
            props.onChange(props.name, (value === defaultValue) ? "" : value, props.required)
    }
    const onRenderSuffix = () => {
        return <Icon iconName="Recent" />
    }

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const textStyle = response.notified && !mandatory ? { ...ErrorTimeFieldProps } : { ...TimeFieldProps };

    return <>
        <div className={"ms-Grid-col " + props.className} style={{ paddingTop: props.label.length === 0 ? 8 : 0 }}>
            <MaskedTextField {...textStyle} label={props.label} readOnly={props.readOnly} required={props.required} maskChar=" " mask="99:99" value={props.value ?? defaultValue} onChange={onChangeHandler} autoComplete="off" onRenderSuffix={onRenderSuffix} onBlur={onBlurHandler} />
        </div>
    </>
};
