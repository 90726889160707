import * as React from 'react';

import { Stack, StackItem } from '@fluentui/react/';

import { ToolbarType } from '../../../../defs/toolbars'

import PagerToolbar from '../../../controls/toolbar/PagerToolbar'
import CommandToolbar from '../../../controls/toolbar/CommandToolbar'

import Separator from '../../customization/Separator';
import Row from '../Row';

import Theme from '../../../themes/Theme';
import { useSelector } from 'react-redux';
import { currentModality } from '../../../../features/modality/modalitySlice';

const headerToolbarSectionStyles: React.CSSProperties = {
    padding: Theme.leap.main.content.section.header.unPadded,
};

type Props = { title?: string, toolbar?: ToolbarType, controller: string }

export default (props: Props) => {

    const modality = useSelector(currentModality)

    return <>
        <Row isTitleRow>
            <div className="ms-Grid-col ms-sm12" style={headerToolbarSectionStyles}>
                <Separator>
                    <Stack horizontal style={{ width: "100%" }}>
                        <StackItem className="left-command-toolbar" grow align="start">
                            <CommandToolbar type={props.toolbar} controller={props.controller} />
                        </StackItem>
                        <StackItem className="right-command-toolbar" align="end">
                            {modality === "item" && <PagerToolbar controller={props.controller} />}
                        </StackItem>
                    </Stack>
                </Separator>
            </div>
        </Row>
    </>
};