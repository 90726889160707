import { DatePicker, DayOfWeek, FontSizes, IDatePickerProps, IDatePickerStrings } from '@fluentui/react/';
import * as React from 'react';
import { FontFaces } from '../../defs/fonts';
import Theme from '../themes/Theme';
import { useSelector } from 'react-redux';
import { currentResponse } from '../../features/response/responseSlice';
import TimeField from './TimeField';

import { DatePickerProps } from '../../defs/props';

const firstDayOfWeek = DayOfWeek.Monday;

const DayPickerStrings: IDatePickerStrings = {
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ],

    shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],

    days: ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'],

    shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],

    goToToday: 'Oggi',
    invalidInputErrorMessage: 'Il formato della data è errato',
};

const onFormatDate = (date?: Date): string => {
    if (date)
        return date.getDate().toString().padStart(2, '0') + '/' +
            (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
            (date.getFullYear());

    return ' '
};

const ValidDatePickerProps: IDatePickerProps = {
    showGoToToday: false,
    styles: {
        root: {
            paddingTop: 2,
            selectors: {
                'label': {
                    color: Theme.leap.main.title.color,
                    fontFamily: FontFaces.montserrat,
                    fontSize: FontSizes.medium,
                    fontWeight: 500,
                    paddingBottom: 2,
                    paddingLeft: 4,
                },
                'i': {
                    bottom: 0,
                    top: -5,
                },
                '.ms-TextField-fieldGroup': {
                    borderColor: Theme.leap.customization.textField.fieldgroup.borderColor,
                    borderWidth: 1,
                }
            }
        },
    }
};

const ErrorDatePickerProps: IDatePickerProps = {
    showGoToToday: false,
    styles: {
        root: {
            paddingTop: 2,
            selectors: {
                'label': {
                    color: Theme.leap.main.title.color,
                    fontFamily: FontFaces.montserrat,
                    fontSize: FontSizes.medium,
                    fontWeight: 500,
                    paddingBottom: 2,
                    paddingLeft: 4,
                },
                'i': {
                    bottom: 0,
                    top: -5,
                },
                '.ms-TextField-fieldGroup': {
                    backgroundColor: Theme.leap.customization.error.backgroundColor,
                    borderColor: Theme.leap.customization.error.borderColor,
                    borderWidth: 1,
                }
            }
        },
    }
}

type DateType = Date | undefined

export default (props: DatePickerProps) => {
    const response = useSelector(currentResponse)

    const minDate: DateType = props.minDate ? new Date(props.minDate) : undefined
    const maxDate: DateType = props.maxDate ? new Date(props.maxDate) : undefined
    const defaultDate: DateType = props.defaultDate ? new Date(props.defaultDate) : undefined


    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const datePickerStyle = response.notified && !mandatory ? { ...ErrorDatePickerProps } : { ...ValidDatePickerProps };

    const timezonedDate = (d: Date | null | undefined) => {
        var d_fixed = d
        if (d) {
            var offset = -d?.getTimezoneOffset() * 60000
            var time = d?.getTime()

            d_fixed = new Date(time + offset)
        }

        return d_fixed
    }

    const parseDateFromString = (d: string) => {
        const day = parseInt(d.substring(0, 2))
        const month = parseInt(d.substring(3, 5))
        const year = parseInt(d.substring(6, d.length))

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return null
        }
        let date = new Date(month + "/" + day + "/" + year)

        if (minDate && minDate > date) {
            date = minDate
        }
        if (maxDate && maxDate < date) {
            date = maxDate
        }
        return date
    }

    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <DatePicker
                {...datePickerStyle}
                label={props.label}
                isRequired={props.required}
                allowTextInput={true}
                firstDayOfWeek={firstDayOfWeek}
                strings={DayPickerStrings}
                formatDate={onFormatDate}
                value={props.value}
                onSelectDate={(d) => props.onChange === undefined ? {} : props.onChange({ target: JSON.parse(JSON.stringify({ value: timezonedDate(d) })) })}
                disabled={props.readOnly}
                minDate={minDate}
                maxDate={maxDate}
                initialPickerDate={defaultDate}
                defaultChecked={defaultDate !== undefined}
                today={defaultDate}
                parseDateFromString={parseDateFromString}
            />
        </div>
        {props.time && <TimeField {...props.time} />}
    </>
};
