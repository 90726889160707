import { useSelector } from 'react-redux';
import { currentModality } from '../features/modality/modalitySlice';

export const useLeapData = (name: string, label: string, state: any, lookup?: any) => {
    const modality = useSelector(currentModality)

    let value
    if (modality === "filter") {
        value = state?.filter?.state?.data?.[name]
    }
    else if (modality === "result") {
        value = state?.result?.state?.data?.[name]
    }
    else if (modality === "item" || modality === "new") {
        value = state?.item?.state?.data?.[name]
    }

    let items = []
    if (lookup === null) {
        //do nothing
    }
    else if (modality === "filter") {
        items = state?.filter?.state?.lookups?.[lookup]
    }
    else if (modality === "result") {
        items = state?.result?.state?.lookups?.[lookup]
    }
    else if (modality === "item" || modality === "new") {
        items = state?.item?.state?.lookups?.[lookup]
    }

    return {
        controller: state?.controller,
        name: name,
        label: label,
        lookup: lookup,
        options: items,
        selectedKey: value,
        value: value,
        onChange: state.onchange,
        onInit: state.oninit,
        mandatory: state.getMandatory,
    }
}