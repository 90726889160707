import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList {...props} items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='File ID' fieldName='fileID' maxWidth={50} align='left' />
                <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={100} align='left' />
                <DataColumn kid='column3' name='Numero Preventivo' fieldName='quoteManagementNumber' minWidth={400} align='center' />
                <DataColumn kid='column4' name='Data Documento' fieldName='reportDate' minWidth={120} maxWidth={300} align='center' />
                <DataColumn kid='column4' name='Data Caricamento' fieldName='uploadDate' minWidth={120} maxWidth={300} align='center' />
                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
                <DataColumn kid='columnDL' name='' fieldName='' minWidth={10} maxWidth={10} align='center' type='download' />
            </DataList>
        </>
    );
}
