import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean
    isWelfare?: boolean
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modalwidth={props.modalwidth} modal={props.modal} state={props.state} readOnly={props.readOnly} navigateToController={"Insured"}>
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='columnN' name='' fieldName='navigate' minWidth={10} maxWidth={10} type='navigate' />
                <DataColumn kid='column1' name='Codice Fiscale' fieldName='fiscalCode' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Codice' fieldName='code_insured' minWidth={100} maxWidth={100} align='left' />
                <DataColumn kid='column3' name='Cognome' fieldName='lastName' minWidth={200} align='left' />
                <DataColumn kid='column4' name='Nome' fieldName='firstName' minWidth={200} align='left' />
                <DataColumn kid='column5' name='Matricola' fieldName='registerNumber' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column6' name='Data Inizio Validità' fieldName='startDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column7' name='Data Fine Validità' fieldName='endDate' minWidth={120} maxWidth={120} align='center' />

                {!props.isWelfare &&
                    <DataColumn kid='column8' name='Data Fine Periodo' fieldName='periodEndDate' minWidth={120} maxWidth={150} align='center' />
                }

                {!props.isWelfare &&
                    <DataColumn kid='column9' name='Scadenza Massima' fieldName='maxEndDate' minWidth={120} maxWidth={120} align='center' />
                }

                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
