import * as React from 'react';

type Props = {
    children: React.ReactNode,
    isTitleRow?: boolean,
    hidden?: boolean
}

export default (props: Props) => {
    const hidden = props.hidden ? { display: "none" } : undefined

    if (props.isTitleRow) {
        return <div className="ms-Grid-row leap-grid-row-title" style={hidden}>
            {props.children}
        </div>
    } else {
        return <div className="ms-Grid-row leap-grid-row-body" style={hidden}>
            {props.children}
        </div>
    }
};