import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';
import { useBoolean } from '@uifabric/react-hooks';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import FileUpload from '../../components/controls/FileUpload';

import { useDispatch, useSelector } from 'react-redux';
import { security } from '../security/thunks/securityThunk';
import { itemDownload } from '../operation/thunks/itemThunk';
import { PrimaryButton, DialogType, Dialog, DialogFooter, FontSizes, FontWeights, IDialogProps } from '@fluentui/react';
import { AppDispatch } from '../../app/store';
import Theme from '../../components/themes/Theme';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch()
    const controller = props.controller
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const fileName = useLeapData("fileName", "nome file", page)
    const fileID = useLeapData("fileID", "fileID", page)

    const dialogStyles = { main: { maxWidth: 450 } };

    const [hideDialog, { toggle: toggleHideDialog, setFalse: showhideDialog }] = useBoolean(true);

    const dialogContentProps: IDialogProps = {
        hidden: hideDialog,
        onDismiss: toggleHideDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "File non trovato",
            title: "Informazione",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    };


    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileID,
            fileName: page?.item?.state?.data?.fileNameExt,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args)).then((response) => {

            if (response.payload.size === 0) {
                toggleHideDialog()
                // alert("file non trovato");
                // log file id e guid
            }

        });
    }

    return (
        <>
            <Dialog {...dialogContentProps} >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Chiudi" />
                </DialogFooter>
            </Dialog>
            <Grid>
                <Section>
                    <Row>
                        <Text {...fileName} className="ms-sm12" required />
                    </Row>
                    <Row>
                    </Row>
                    {(page?.item?.state?.data?.fileID === undefined || page?.item?.state?.data?.fileID === null) && (
                        <Row>
                            <FileUpload {...fileID} className="ms-sm12" label="upload file" controller={props.controller} />
                        </Row>
                    )}
                    {(page?.item?.state?.data?.fileID !== undefined && page?.item?.state?.data?.fileID !== null) && (
                        <Row>
                            <PrimaryButton text="download file" onClick={downloadFile} style={{ marginTop: "20px" }} />
                        </Row>
                    )}
                </Section>
            </Grid>
        </>
    );
}
