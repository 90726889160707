import { FontSizes, Icon, IRawStyle, ITextFieldProps, Label, TextField } from '@fluentui/react/';
import { Guid } from "guid-typescript";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { FontFaces } from '../../defs/fonts';
import { lock, unlock } from '../../features/operation/lockSlice';
import { itemData, itemUpload } from '../../features/operation/thunks/itemThunk';
import { setResponse } from '../../features/response/responseSlice';
import { security } from '../../features/security/thunks/securityThunk';

type Props = {
    controller: string,
    name: string,
    label: string,
    className: string,
    required?: boolean,
    readOnly?: boolean,
    uploadedGuidFile?: string | undefined,
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,

}

export default (props: Props) => {
    const [file, setFile] = useState(undefined as any)
    const [content, setContent] = useState(undefined as any)
    const [uid, setUid] = useState(Guid.create().toString())

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const iForm = useSelector(itemData)

    const onRenderSuffix = (p: any) => {
        if (uploadedGuidFile) {
            return <Icon iconName="Accept" />
        }
        if (file) {
            return <>
                <Icon iconName="Cancel" onClick={onClickCancel} />
                <Icon iconName="Upload" onClick={onClickUpload} />
            </>
        } else {
            return <Label htmlFor={uid}><Icon iconName="OpenFolderHorizontal" /></Label>
        }
    }


    const [uploadedGuidFile, setUploadedGuidFile] = React.useState<string | undefined>(props.uploadedGuidFile)

    useEffect(() => {
        setUploadedGuidFile(props.uploadedGuidFile)

        if (props.onInit) {
            props.onInit(props.name, props.uploadedGuidFile, props.required)
        }
    }, [props.uploadedGuidFile, props.required])

    useEffect(() => {
        if (props.onChange) {
            props.onChange(props.name, uploadedGuidFile, props.required)
        }
    }, [uploadedGuidFile])

    const onChange = (e: any) => {
        const fileReader = new FileReader();

        fileReader.onloadend = () => setContent(fileReader.result)
        fileReader.readAsArrayBuffer(e.target.files[0]);

        setFile(e.target.files[0]);
    }

    const onClickCancel = () => {
        setContent(undefined);
        setFile(undefined)
    }

    const onClickUpload = () => {
        dispatch(lock())

        const args = {
            controller: props.controller,
            lookup: props.controller,
            name: file?.name,
            type: file?.type,
            file: Array.from(new Uint8Array(content)),
            idCaller: iForm.find(i => i.key === props.controller)?.value.data.id,
            token: credential?.token
        }

        dispatch(itemUpload(args))
            .then((response: any) => { dispatch(unlock()); return response; })
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then((response: any) => {
                setUploadedGuidFile(response.payload.data)

            })
            .then(() => {
                setContent(undefined)
                // setFile(undefined)
            })
            .catch(() => dispatch(unlock()))
    }

    const IconRawStyle: IRawStyle = {
        cursor: "pointer",
        display: "block",
        fontSize: 16,
        paddingRight: 9
    }
    const TextFieldProps: Partial<ITextFieldProps> = {
        label: props.label,
        readOnly: true,
        required: props.required,
        value: file?.name,
        autoComplete: "off",
        onRenderSuffix: onRenderSuffix,
        styles: {
            field: {
                cursor: "default"
            },
            fieldGroup: {
                cursor: "default"
            },
            subComponentStyles: {
                label: {
                    root: {
                        fontFamily: FontFaces.montserrat,
                        fontSize: FontSizes.medium,
                        fontWeight: 500,
                        paddingBottom: 2,
                        paddingLeft: 4,
                    },
                },
            },
            suffix: {
                backgroundColor: "unset",
                cursor: "default",
                padding: "1px 0 0",
                selectors: {
                    "label": {
                        color: "unset",
                        padding: 0
                    },
                    "i": IconRawStyle
                }
            },

        }
    }

    return <>
        <div className={"ms-Grid-col " + props.className} >
            <TextField {...TextFieldProps} />
            <input id={uid} type="file" onChange={onChange} hidden />
        </div>
    </>
};
