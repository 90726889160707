import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import { security } from '../security/thunks/securityThunk';
import { itemDownload } from '../operation/thunks/itemThunk';

import { PrimaryButton } from '@fluentui/react';

import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import FileUpload from '../../components/controls/FileUpload';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHC: boolean = roles.isONHC;

    const dispatch = useDispatch()
    const controller = props.controller
    const credential = useSelector(security)?.credential

    const page = useLeapState(props.controller, false)

    const invoiceNumber = useLeapData("invoiceNumber", "numero fattura", page)
    const invoiceDate = useLeapData("invoiceDate", "data fattura", page)
    const invoiceImport = useLeapData("invoiceImport", "importo", page)
    const invoiceRefound = useLeapData("invoiceRefound", "rimborso", page)
    const invoiceFile = useLeapData("invoiceFileID", "fattura", page)
    const invoiceNote = useLeapData("note", "note", page)

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.invoiceFileID,
            fileName: page?.item?.state?.data?.fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args))
    }

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...invoiceNumber} className="ms-sm6" required />
                        <Text {...invoiceDate} className="ms-sm5" type="calendar" dateRange={{ maxDate: Date().toLocaleString() }} required />
                    </Row>
                    <Row>
                        <Text {...invoiceImport} className="ms-sm6" type="number" required />
                        {isONHC && <Text {...invoiceRefound} className="ms-sm6" type="number" />}
                    </Row>
                    <Row>
                        <FileUpload {...invoiceFile} className="ms-sm12" label="fattura" controller={props.controller} required />
                    </Row>
                    <Row>
                        <Text {...invoiceNote} className="ms-sm12" type="multiline" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
