import { DialogFooter, FontSizes, getTheme, Icon, ILabelStyles, mergeStyleSets, Modal, PrimaryButton, Label, IconButton, IIconProps, FontWeights, Checkbox, FontIcon, IButtonProps } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { itemData } from '../operation/thunks/itemThunk';
import { security } from '../security/thunks/securityThunk';
import { setResponse } from '../response/responseSlice';
import { setOperation } from '../operation/operationSlice';
import Uploader from '../../components/controls/Uploader';
import { lock, unlock } from '../operation/lockSlice';

export const CheckingDocumentation = createAsyncThunk(
    'item/CheckingDocumentation',
    async (args: { id: string, token: string | undefined, contractID: string | number | undefined, attachmentID: string | number | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", { form: { id: args.id, contractID: args.contractID, attachmentID: args.attachmentID } }, 'CheckingDocumentation', args.token)
        return response
    }
)

export const GetContract = createAsyncThunk(
    'item/GetContract ',
    async (args: { id: string, controller: string, token: string | undefined }) => {
        const response = await client.downloadPost(args.controller, { form: { id: args.id, fileName: "autocertificazione.pdf" } }, 'GetContract', undefined, args.token)
        return response
    }
)
export const GetAttachment = createAsyncThunk(
    'item/GetAttachment',
    async (args: { id: string, controller: string, token: string | undefined }) => {
        const response = await client.downloadPost(args.controller, { form: { id: args.id, fileName: "allegato 2bis.pdf" } }, 'GetAttachment', undefined, args.token)
        return response
    }
)

type Props = {
    hasApprovedRateTable: boolean,
    hasUnchangedRateTable: boolean
}

export default (props: Props) => {
    const controller = "HealthFacilityAffiliation"
    const contractFolderController = "HealthFacilityAffiliationContractFolder"

    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const credential = useSelector(security)?.credential

    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }

    const iForm = useSelector(itemData)
    const args = { id: iForm.find(i => i.key === controller)?.value?.data?.id, controller: contractFolderController, token: credential?.token }

    const onExecuteGetContract = () => {
        dispatch(lock())
        dispatch(GetContract(args))
            .then(() => dispatch(unlock()))
    }
    const onExecuteGetAttachment = () => {
        dispatch(lock())
        dispatch(GetAttachment(args))
            .then(() => dispatch(unlock()))
    }

    const onExecuteCheckingDocumentation = (e: any) => {
        dispatch(lock())
        dispatch(CheckingDocumentation({ ...args, contractID: contractID, attachmentID: attachmentID }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }

    const hideModalAndCoachmark = () => {
        hideModal();
    }

    const [contractID, setContractID] = React.useState<string | number | undefined>(undefined);
    const [hasContract, setHasContract] = React.useState(false);
    const onContractChange = React.useCallback(
        (uploaded?: string | number): void => {
            if (Number.isInteger(uploaded) || !uploaded)
                setContractID(uploaded)
            setHasContract(!!uploaded);
        },
        [],
    );
    const [attachmentID, setAttachmentID] = React.useState<string | number | undefined>(undefined);
    const [hasAttachment, setHasAttachment] = React.useState(false);
    const onAttachmentChange = React.useCallback(
        (uploaded?: string | number): void => {
            if (Number.isInteger(uploaded) || !uploaded)
                setAttachmentID(uploaded)
            setHasAttachment(!!uploaded);
        },
        [],
    );

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '500px',
            width: '40vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 0 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const noDataFoundLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 0,
            textAlign: "justify",
            whiteSpace: "pre-wrap"
        }
    }
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };

    const ButtonProps: IButtonProps = {
        style: {
            borderColor: Theme.fluent.palette.white,
            borderRadius: 2,
            borderStyle: "solid",
            borderLeftWidth: 1,
            fontWeight: 600,
            verticalAlign: "bottom",
        },
        styles: {
            root: {
                height: 34,
                minWidth: "12%",
                marginRight: 4
            }
        }
    }

    return (
        <>
            <PrimaryButton text="invia documentazione" onClick={showModal} style={{ marginRight: "4px" }} />
            <Modal titleAriaId={"send"} isOpen={isModalOpen} isBlocking={false} containerClassName={contentStyles.container} onDismiss={hideModalAndCoachmark}>
                <div className={contentStyles.header}>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Grid>
                        <>
                            {props.hasApprovedRateTable && <>
                                <Row>
                                    <Label styles={noDataFoundLabel}>
                                        Per procedere con la richiesta di adesione al network si prega di effettuare il<br /><br />
                                        download dei documenti, visionarli ed effettuarne la sottoscrizione<br /><br />
                                    </Label>
                                </Row>
                                <Row>
                                    <div className={props.hasUnchangedRateTable ? "ms-Grid-col ms-sm12" : "ms-Grid-col ms-sm6"} style={{ paddingTop: 4, textAlign: props.hasUnchangedRateTable ? "center" : "right" }}>
                                        <PrimaryButton  {...ButtonProps} onClick={onExecuteGetContract}>scarica autocertificazione &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>
                                    </div>
                                    {!props.hasUnchangedRateTable && <div className="ms-Grid-col ms-sm6" style={{ paddingTop: 4, textAlign: "left" }}>
                                        <PrimaryButton  {...ButtonProps} onClick={onExecuteGetAttachment}>scarica allegato 2bis &nbsp; <FontIcon iconName={"download"} /></PrimaryButton>
                                    </div>}
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginTop: 32 }}>
                                        I documenti sottoscritti andranno caricati nello spazio sottostante ed inviati cliccando su Invia documentazione<br /><br />
                                    </Label>
                                </Row>
                                <Row>
                                    <Uploader onChange={(_, value) => { onContractChange(value) }} className={props.hasUnchangedRateTable ? "ms-sm12" : "ms-sm10"} controller="HealthFacilityAffiliationContractFolder" name="fileID" label="caricamento documento di autocertificazione firmato" required={props.hasApprovedRateTable} />
                                    {!props.hasUnchangedRateTable && <Uploader onChange={(_, value) => { onAttachmentChange(value) }} className="ms-sm10" controller="HealthFacilityAffiliationContractFolder" name="attachmentID" label="caricamento allegato 2bis firmato" required={props.hasApprovedRateTable && !props.hasUnchangedRateTable} />}
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginTop: 32 }}>
                                        La Struttura Sanitaria, avendo letto e compreso le condizioni generali di affiliazione, dichiara:
                                    </Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di accettare le condizioni generali di affiliazione;</Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di aderire integralmente alle condizioni generali di affiliazione;</Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di aver preso visione dell’Informativa Privacy;</Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di accettare che le prestazioni presenti nelle condizioni tariffarie contrassegnate con *, per particolari piani sanitari, potranno subire variazioni tariffarie nel limite massimo del +/-15%;</Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di accettare che le prestazioni DR001, DR005 e CO002, presenti nelle condizioni tariffarie, per particolari piani sanitari, potranno essere richieste gratuitamente;</Label>
                                </Row>
                                <Row>
                                    <Label styles={noDataFoundLabel} style={{ marginLeft: "12px", marginRight: "12px", marginTop: "4px", lineHeight: "1.2em" }}><Icon iconName={"CheckboxComposite"} style={{ marginRight: "4px", verticalAlign: "middle" }} />di accettare che le prestazioni DR003, EN001, PF004 e IP002, presenti nelle condizioni tariffarie, per particolari piani sanitari, potranno essere richieste gratuitamente massimo una volta all’anno per ciascun assistito.</Label>
                                </Row>
                            </>}
                            {
                                !props.hasApprovedRateTable && <>
                                    <Row>
                                        <Label styles={noDataFoundLabel}>
                                            Procedendo con l'invio della documentazione prenderemo in carico la vostra proposta di modifica tariffaria e vi daremo riscontro entro 2 giorni lavorativi.
                                        </Label>
                                    </Row>
                                </>
                            }
                        </>
                    </Grid>
                    <DialogFooter>
                        <PrimaryButton onClick={onExecuteCheckingDocumentation} text="invia documentazione" disabled={props.hasApprovedRateTable && !(hasContract && (hasAttachment || props.hasUnchangedRateTable))} />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}