import * as React from 'react';
import { useId } from '@uifabric/react-hooks';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { SelectionMode } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { security } from '../security/thunks/securityThunk';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';


type Props = {
    items: any,
    name: string
    onChange?: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any
    listType: 'new' | 'healthServices' | 'novoucher' | 'deleted'
    readonly: boolean
}


export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONCH: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHFAccount: boolean = roles.isHFAccount;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator || isHFAccount

    const leap_QuoteManagementQuoteStateID = props.state.item?.state?.data?.leap_QuoteManagementQuoteStateID

    const isRefoundVisibleToHF = isHF && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7")

    const DataListProps = {
        items: props.items,
        modaltitle: props.modaltitle,
        modalController: props.controller,
        modal: props.modal,
        state: props.state,
        selectionName: props.name,
        selectionMode: SelectionMode.none,
        onSelectionChange: props.onChange,
        canSelectItem: undefined,
    }


    return (
        <>
            <DataList {...DataListProps}>
                <DataColumn kid='column1' name='Branca' fieldName='branchDescr' minWidth={180} maxWidth={180} align='left' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthServiceDescr' minWidth={180} align='left' />
                <DataColumn kid='column4' name='Elemento Dentale' fieldName='dentalElement' minWidth={130} maxWidth={130} align='left' />
                <DataColumn kid='column5' name='Appuntamento' fieldName='appointmentDate' minWidth={120} maxWidth={120} align='center' />
                {props.listType !== 'deleted' && <DataColumn kid='column6' name='Costo' fieldName='amount' minWidth={80} maxWidth={80} align='right' />}
                {props.listType !== 'deleted' && (isONCH || isRefoundVisibleToHF) && <DataColumn kid='column7' name='Rimborso' fieldName='refound' minWidth={80} maxWidth={80} align='right' />}
                {props.listType !== 'deleted' && isONCH && <DataColumn kid='column8' name='Erosione' fieldName='erosion' minWidth={80} maxWidth={80} align='right' />}
            </DataList>
        </>
    );
}
