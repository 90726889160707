import { Icon } from '@fluentui/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import Uploader from '../components/controls/Uploader';
import DataImportResultList from '../features/dataimport/DataImportResultList';
import { itemDownload } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

export default () => {
    const controller = "DataImport"
    const dispatch: AppDispatch = useDispatch()

    const page = useLeapState(controller)

    const fileID = useLeapData("fileID", "fileID", page)
    const dataImportStateID = useLeapData("importStateID", "stato di importazione", page, "dataImportDocumentState")
    const dataImportState = useLeapData("importState", "stato di importazione", page)
    const dataImportTypeID = useLeapData("importTypeID", "tipo di importazione", page, "dataImportDocumentType")
    const createDate = useLeapData("createtDate", "data di creazione", page)

    const credential = useSelector(security)?.credential

    const downloadFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.fileIDFileID?.toString(),
            fileName: page?.item?.state?.data?.fileIDFilename,
            token: credential.token
        }

        dispatch(itemDownload(args))
    }
    const downloadDataFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.dataFileID.toString(),
            fileName: `RIGHE ERRATE - ${page?.item?.state?.data?.fileName?.replace(/\.[^/.]+$/, "")}.log`,
            token: credential.token
        }
        dispatch(itemDownload(args))
    }
    const downloadLogFile = () => {
        const args = {
            controller: controller,
            fileID: page?.item?.state?.data?.logFileID.toString(),
            fileName: `LOG - ${page?.item?.state?.data?.fileName?.replace(/\.[^/.]+$/, "")}.log`,
            token: credential.token
        }
        dispatch(itemDownload(args))
    }

    const haslogFile = !!page?.item?.state?.data?.logFileID;
    const hasDataFile = !!page?.item?.state?.data?.dataFileID;
    const hasFailed = page?.item?.state?.data?.importStateID === "3";

    return <>
        <Content title="Data Import" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...createDate} className="ms-sm2" type="calendar" />
                        </Row>
                        <Row>
                            <ComboBox {...dataImportStateID} className="ms-sm4" />
                            <ComboBox {...dataImportTypeID} className="ms-sm4" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <DataImportResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        {page.modality === 'new' && (
                            <Row>
                                <Uploader {...fileID} className="ms-sm6" label="carica file" controller={controller} required />
                            </Row>
                        )}
                        <Row>
                            {page.modality === 'item' && <Text onChange={() => { }} className="ms-sm4" label="file originale" name="" type="plainWithIcon" value={page?.item?.state?.data?.fileName} readOnly onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />}
                            <ComboBox {...dataImportTypeID} required={page.modality === "new"} className="ms-sm4" disabled={page.modality !== 'new'} />
                        </Row>
                        {page.modality === 'item' && (
                            <>
                                <Row>
                                    <Text {...dataImportState} className="ms-sm4" readOnly />
                                </Row>
                                <Row>
                                    {haslogFile && hasFailed && <Text onChange={() => { }} className="ms-sm4" label="&nbsp;" name="" type="plainWithIcon" value="file di log" readOnly onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadLogFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />}
                                    {hasDataFile && hasFailed && <Text onChange={() => { }} className="ms-sm4" label="&nbsp;" name="" type="plainWithIcon" value="log righe errate" readOnly onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadDataFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />}
                                </Row>
                            </>)}

                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}