import * as React from 'react';
import { useState, useEffect } from 'react';

import { Text } from '@fluentui/react/lib/Text';

import { currentDateTimeTheme } from '../themes/Fonts';


export const CurrentDateTime = () => {
    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);

        return () => {
            clearInterval(timer);
        }
    });

    return (
        <>
            <Text theme={currentDateTimeTheme}>{date.toLocaleString('it-IT')}</Text>
        </>
    );
}

export default CurrentDateTime;