import { FontSizes, IImageStyles, Image, ImageFit, PrimaryButton, Text as TextTitle, Icon } from '@fluentui/react/';
import { QRCodeSVG } from 'qrcode.react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ComboBox from '../components/controls/ComboBox';
import Text from '../components/controls/Text';
import OTP from '../components/controls/OTP';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import { authenticated, authorized, security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecuritydOperation } from '../hooks/useSecurityOperation';

export default () => {
    const controller = "Security"
    const page = useLeapState(controller)

    const securityContext = useSelector(security)
    const securityOperation = useSecuritydOperation();

    const credential = useSelector(security)?.credential
    const isAuthenticated = useSelector(authenticated)
    const isAuthorized = useSelector(authorized)


    const username = useLeapData("username", "nome utente", page)
    const password = useLeapData("password", "password", page)
    const otp = useLeapData("otp", "otp", page)
    const roles = useLeapData("role", "ruoli", page)

    const [message, setMessage] = useState('')

    const [_username, _setUsername] = useState<string>('')
    const [_password, _setPassword] = useState<string>('')
    const [_qrCode, _setQrCode] = useState<string>('')
    const [_otp, _setOTP] = useState<string>('')

    const [_step, _setStep] = useState<number>(0)

    const onAuthentication = () => {
        securityOperation.login(_username, _password, undefined, undefined)
            .then((response) => {
                _setStep(response?.payload?.data?.step)
                _setQrCode(response?.payload?.data?.uriTOTP)
                return response
            })
            .then((response) => {
                if (response?.payload?.response?.level !== 4) setMessage(response?.payload?.response?.message)
                else setMessage("")
            })
    }
    const onAuthorization = () => {
        securityOperation.setRole(credential.token, page?.filter?.values.role?.value)
            .then((response) => {
                if (response?.payload?.response?.level !== 4) setMessage(response?.payload?.response?.message)
                else setMessage("")
            })
    }
    const onOtp = () => {
        securityOperation.login(_username, _password, undefined, _otp)
            .then((response) => {
                _setStep(response?.payload?.data.step)
                return response
            })
            .then((response) => {
                if (response?.payload?.response?.level !== 4) setMessage(response?.payload?.response?.message)
                else setMessage("")
            })
    }
    const onQrCodeContinue = () => {
        _setStep(2)
    }    

    const infoMessageStyle: React.CSSProperties = {
        color: Theme.fluent.palette.neutralDark,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: 'justify'
    }

    const errorMessageStyle: React.CSSProperties = {
        color: Theme.leap.customization.error.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: 'right'
    }

    const buttonRef: any = useRef(null)
    const handleKeyboard = (event: any) => {
        if (event.key === "Enter") {
            buttonRef.current.click()
        }
    }
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);
        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [])

    const register = <><TextTitle style={{ fontFamily: FontFaces.montserrat, fontWeight: 500 }}>Sei un assistito? &nbsp;</TextTitle ><TextTitle nowrap onClick={() => { document.location.href = '/onhc-private-insuredRegistration/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Registrati</TextTitle></>
    const forgotUsername = <TextTitle nowrap onClick={() => { document.location.href = '/forgotUsername/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Hai dimenticato il nome utente?</TextTitle>
    const forgotPassword = <TextTitle nowrap onClick={() => { document.location.href = '/forgotPassword/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Hai dimenticato la password?</TextTitle>

    const mainContainerStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: 32
    }
    const logoExternalContainerStyle: React.CSSProperties = {
        backgroundColor: process.env.REACT_APP_STYLE_BODY_BACKGROUNDCOLOR,
        borderRadius: "50%",
        padding: 16,
        zIndex: 1
    }
    const logoInternalContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.white,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: "50%",
        zIndex: 1
    }
    const formContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.themeLight,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: "128px 24px 8px",
        position: "relative",
        top: -96,
    }

    const buttonContainerStyle: React.CSSProperties = {
        marginTop: 12,
        textAlign: "center"
    }

    const buttonStyle: React.CSSProperties = {
        width: 192
    }

    const textContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }
    const errorTextContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }
    const infoMessageContainerStyle: React.CSSProperties = {
        textAlign: "justify"
    }

    if (isAuthorized) { //autorizzato: token presente e ruolo selezionato
        document.location.href = '/'
        return <></>
    } else if (_step === 3 && isAuthenticated) { //autenticato: token presente e nessun ruolo selezionato
        return <>
            <div style={mainContainerStyle}>
                <div style={logoExternalContainerStyle}>
                    <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                        <Image src="/assets/media/images/icon.png"
                            draggable={false}
                            style={{
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                                msUserSelect: "none"
                            }} />
                    </div>
                </div>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <Text className="ms-sm12" label="nome utente" name="username" value={_username} readOnly />
                    <ComboBox {...roles} className="ms-sm12" options={securityContext?.roles} required />
                    <div className="ms-Grid-col ms-sm12" style={{ ...errorTextContainerStyle, marginTop: 12 }}>
                        <span style={errorMessageStyle}>&nbsp;{message?.toLowerCase()}&nbsp;</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...buttonContainerStyle, marginBottom: 48 }} >
                        <PrimaryButton style={buttonStyle} onClick={onAuthorization} text="accedi" elementRef={buttonRef} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle }}>
                        <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                    </div>
                </div>
            </div>
        </>
    } else if (_step === 2 && isAuthenticated) { //autenticato: token presente, nessun ruolo selezionato, 2FA richiesto e inizializzato
        return <>
            <div style={mainContainerStyle}>
                <div style={logoExternalContainerStyle}>
                    <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                        <Image src="/assets/media/images/icon.png"
                            draggable={false}
                            style={{
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                                msUserSelect: "none"
                            }} />
                    </div>
                </div>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginBottom: 24, marginTop: 12 }}>
                        <span style={infoMessageStyle}>Per completare l'accesso a ONHC-Odontonetwork, inserisci il codice temporaneo generato dalla tua app di autenticazione</span>
                    </div>
                    <OTP className="ms-sm12" label="OTP" required value={_otp} onChange={(_name, value, _req) => _setOTP(value?.toString() ?? '')} buttonRef={buttonRef} />
                    <div className="ms-Grid-col ms-sm12" style={{ ...errorTextContainerStyle, marginTop: 12 }}>
                        <span style={errorMessageStyle}>&nbsp;{message?.toLowerCase()}&nbsp;</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...buttonContainerStyle, marginBottom: 48 }} >
                        <PrimaryButton style={buttonStyle} onClick={onOtp} text="accedi" elementRef={buttonRef} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle }}>
                        <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                    </div>
                </div>
            </div>
        </>
    } else if (_step === 1 && isAuthenticated) {//autenticato: token presente, nessun ruolo selezionato, 2FA richiesto ma non inizializzato
        return <>
            <div style={mainContainerStyle}>
                <div style={logoExternalContainerStyle}>
                    <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                        <Image src="/assets/media/images/icon.png"
                            draggable={false}
                            style={{
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                                msUserSelect: "none"
                            }} />
                    </div>
                </div>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginBottom: 18, marginTop: 12 }}>
                        <span style={infoMessageStyle}>ONHC-Odontonetwork introduce l'autenticazione a due fattori per aumentare la sicurezza del tuo account</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <QRCodeSVG size={160} value={_qrCode} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginTop: 12 }}>
                        <span style={infoMessageStyle}>Segui questi passaggi per configurarla:</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginTop: 12 }}>
                        <span style={infoMessageStyle}>1. Scarica un'app di autenticazione sul tuo dispositivo, come ad esempio Microsoft Authenticator, Google Authenticator o Authy</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginTop: 12 }}>
                        <span style={infoMessageStyle}>2. Scannerizza il QR code con l'app di autenticazione</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginTop: 12 }}>
                        <span style={infoMessageStyle}>3. Conferma l'abilitazione dell'autenticazione a due fattori</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...infoMessageContainerStyle, marginTop: 12 }}>
                        <span style={infoMessageStyle}>4. Inserisci il codice temporaneo generato dalla tua app di autenticazione</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...errorTextContainerStyle, marginTop: 12 }}>
                        <span style={errorMessageStyle}>&nbsp;{message?.toLowerCase()}&nbsp;</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...buttonContainerStyle, marginBottom: 48 }} >
                        <PrimaryButton style={buttonStyle} onClick={onQrCodeContinue} text="conferma" elementRef={buttonRef} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle }}>
                        <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                    </div>
                </div>
            </div>
        </>
    } else { //non autenticato: token non presente e nessun ruolo selezionato
        return <>
            <div style={mainContainerStyle}>
                <div style={logoExternalContainerStyle}>
                    <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                        <Image src="/assets/media/images/icon.png"
                            draggable={false}
                            style={{
                                MozUserSelect: "none",
                                WebkitUserSelect: "none",
                                msUserSelect: "none"
                            }} />
                    </div>
                </div>
                <div className="splashForm ms-depth-16" style={formContainerStyle} >
                    <Text className="ms-sm12" label="nome utente" name="username" value={_username} required onChange={(_name, value, _req) => _setUsername(value?.toString() ?? '')} />
                    <Text className="ms-sm12" label="password" name="password" value={_password} required onChange={(_name, value, _req) => _setPassword(value?.toString() ?? '')} type='password' />
                    <div className="ms-Grid-col ms-sm12" style={{ ...errorTextContainerStyle, marginTop: 12 }}>
                        <span style={errorMessageStyle}>&nbsp;{message?.toLowerCase()}&nbsp;</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                        <PrimaryButton style={buttonStyle} onClick={onAuthentication} text="accedi" elementRef={buttonRef} />
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 32 }}>
                        {register}
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 40 }}>
                        {forgotUsername}
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginBottom: 48, marginTop: 8 }}>
                        {forgotPassword}
                    </div>
                    <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle }}>
                        <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                    </div>
                </div>
            </div>
        </>
    }
}