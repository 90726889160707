import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import ComboBox from '../../components/controls/ComboBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';


type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const plan_slave = useLeapData("plan_slave", "piano", page, "plans")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...plan_slave} className="ms-sm12" required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
