
import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Separator from '../components/controls/grid/Separator';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import ComboBox from '../components/controls/ComboBox';
import MultiBox from '../components/controls/MultiBox';
import SearchBox from '../components/controls/SearchBox';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { PivotItem, Pivot, IButtonProps, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';

import HealthFacilityWelfareLogs from '../features/healthFacility/HealthFacilityWelfareLogs';

import HealthFacilityContractFolderList from '../features/healthFacility/HealthFacilityContractFolderList';
import HealthFacilityContractFolderModal from '../features/healthFacility/HealthFacilityContractFolderModal';
import HealthFacilityDoctorList from '../features/healthFacility/HealthFacilityDoctorList';
import HealthFacilityDoctorModal from '../features/healthFacility/HealthFacilityDoctorModal';
import HealthFacilityNotUsableHealthServiceList from '../features/healthFacility/HealthFacilityNotUsableHealthServiceList';
import HealthFacilityNotUsableHealthServiceModal from '../features/healthFacility/HealthFacilityNotUsableHealthServiceModal';
import HealthFacilityNotUsableHealthServiceModalEdit from '../features/healthFacility/HealthFacilityNotUsableHealthServiceModalEdit';
import HealthFacilityNotUsablePlanList from '../features/healthFacility/HealthFacilityNotUsablePlanList';
import HealthFacilityNotUsablePlanModal from '../features/healthFacility/HealthFacilityNotUsablePlanModal';
import HealthFacilityOperatorList from '../features/healthFacility/HealthFacilityOperatorList';
import HealthFacilityOperatorModal from '../features/healthFacility/HealthFacilityOperatorModal';
import HealthFacilityOperatorModalEdit from '../features/healthFacility/HealthFacilityOperatorModalEdit';
import HealthFacilityResultList from '../features/healthFacility/HealthFacilityResultList';
import HealthFacilityTimetableList from '../features/healthFacility/HealthFacilityTimetableList';
import HealthFacilityTimetableModal from '../features/healthFacility/HealthFacilityTimetableModal';
import HealthFacilityVacationList from '../features/healthFacility/HealthFacilityVacationList';
import HealthFacilityVacationModal from '../features/healthFacility/HealthFacilityVacationModal';

import SurveyModal from '../features/healthFacility/SurveyHealthFacilityModal';
import SurveyList from '../features/healthFacility/SurveyHealthFacilityList';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';

import MapsModal from '../features/maps/MapsModal';
import { setOperation } from '../features/operation/operationSlice';
import { setResponse } from '../features/response/responseSlice';
import { security } from '../features/security/thunks/securityThunk';
import { itemData } from '../features/operation/thunks/itemThunk';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import InsuredMaps from '../features/insured/InsuredMaps';
import HealthFacilityJoinWelfare from '../features/healthFacility/HealthFacilityJoinWelfare';

export const disableSS = createAsyncThunk(
    'item/disableSS',
    async (args: { healthFacilityID: string, token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: { id: args.healthFacilityID } }, 'disable', args.token)
        return response
    }
)

export const ableSS = createAsyncThunk(
    'item/ableSS',
    async (args: { healthFacilityID: string, token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: { id: args.healthFacilityID } }, 'able', args.token)
        return response
    }
)

export const RegeneratePassword = createAsyncThunk(
    'item/RegeneratePassword',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("HealthFacility", { form: {}, page: args.page }, 'RegeneratePassword', args.token)
        return response
    }
)

export const GenerateMasterSS = createAsyncThunk(
    'item/GenerateMasterSS',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("HealthFacility", { form: {}, page: args.page }, 'GenerateMasterSS', args.token)
        return response
    }
)

export default () => {
    const roles = useSecurityRoles()
    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHFAccount: boolean = roles.isHFAccount;
    const isInsuredUser: boolean = roles.isInsuredUser;
    const isHF: boolean = roles.isHF;
    const isONHC: boolean = roles.isONHC;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAffiliationOperator: boolean = roles.isONHCAffiliationOperator
    const isConsultant = roles.isConsultant;
    const isCorporation = roles.isCorporation;
    const isCompany = roles.isCompany;

    const controller = "HealthFacility"
    const surveyController = "SurveyHealthFacility"

    const page = useLeapState(controller, roles.role !== undefined, isHFMaster ? "result" : isHF ? "item" : "filter")
    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    function show_date(item: string, state: string) {
        if (item) {
            // item has value
            if (item === "1") {
                //item has value and is 1
                return true;
            }
        }
        else if (state === "1") {
            //state is 1 and item is undefined
            return true;
        }
        return false;
    }

    function show_disactive(item: string, state: string) {
        if (item) {
            if (item === "1") {
                return true;
            }
            else if (state === "1") {
                return true;
            }
        }
        return false;
    }

    function show_active(item: string, state: string) {
        if (item) {
            if (item === "2") {
                return true;
            }
            else if (state === "2") {
                return true;
            }
        }
        return false;
    }

    const healthfacility_item: string = page.item?.values?.leap_HealthFacilityStateID?.value;
    const healthfacility_state: string = page?.item?.state?.data?.leap_HealthFacilityStateID;
    const display_disactive: boolean = show_disactive(healthfacility_item, healthfacility_state);
    const display_active: boolean = show_active(healthfacility_item, healthfacility_state);

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const dialogStyles = { main: { maxWidth: 450 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla disattivazione della struttura sanitaria?',
    };

    const dialogContentPropsA = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla riattivazione della struttura sanitaria?',
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialogRegeneratePassword, { toggle: toggleHideDialogRegeneratePassword }] = useBoolean(true);
    const [hideDialogGenerateMaster, { toggle: toggleHideDialogGenerateMaster }] = useBoolean(true);
    const [hideDialogJoinWelfare, { toggle: toggleHideDialogJoinWelfare }] = useBoolean(true);

    const onClickDisabilita = (e: any) => {
        e.preventDefault()
        toggleHideDialog()
    }

    const onClickAbilita = (e: any) => {
        e.preventDefault()
        toggleHideDialog()
    }

    const dialogContentPropsRegeneratePassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla rigenerazione della password del master struttura sanitaria?',
    };

    const dialogContentPropsGenerateUser = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla generazione dell\'utente Master Struttura Sanitaria?',
    };

    const dialogContentPropsJoinWelfare = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo all\'adesione al Welfare?',
    };

    const iForm = useSelector(itemData)

    const onExecuteDisabilita = (e: any) => {
        toggleHideDialog()
        dispatch(disableSS({ healthFacilityID: iForm.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then(dispatchMessage)
            .then(dispatchReload)
    }

    const onExecuteAbilita = (e: any) => {
        toggleHideDialog()
        dispatch(ableSS({ healthFacilityID: iForm.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then(dispatchMessage)
            .then(dispatchReload)
    }


    const adesioneW_item: string = page.item?.values?.leap_HealthFacilityMembershipWelfareID?.value;
    const adesioneW_state: string = page?.item?.state?.data?.leap_HealthFacilityMembershipWelfareID;
    //const affiliationDate_state: string = page?.item?.state?.data?.affiliationDate;
    //const affiliationDate_value: string = page.item?.values?.affiliationDate?.value;
    const display_date: boolean = show_date(adesioneW_item, adesioneW_state);

    const healthFacilityOperatorController = "HealthFacilityOperator"
    const healthFacilityDoctorController = "HealthFacilityDoctor"
    const healthFacilityNotUsableHealthServiceController = "HealthFacilityNotUsableHealthService"
    const healthFacilityContractFolderController = "HealthFacilityContractFolder"
    const healthFacilityNotUsablePlanController = "HealthFacilityNotUsablePlan"
    const healthFacilityVacationController = "HealthFacilityVacation"
    const healthFacilityTimetableController = "HealthFacilityTimetable"

    const code = useLeapData("code", "codice", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const leap_HealthFacilityStateID = useLeapData("leap_HealthFacilityStateID", "attivo", page, "healthFacilityStateOption")

    const denomination = useLeapData("denomination", "denominazione", page)

    //recapiti
    const nationID = useLeapData("nationID", "nazione", page, "nations")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    const address = useLeapData("address", "indirizzo", page)
    const streetAddress = useLeapData("streetAddress", "civico", page)
    const postalCode = useLeapData("postalCode", "cap", page)

    const telephoneNumber = useLeapData("telephoneNumber", "telefono", page)
    const mobilePhone = useLeapData("mobilePhone", "cellulare", page)
    const fax = useLeapData("fax", "fax", page)

    const email = useLeapData("email", "email", page)
    const publicEmail = useLeapData("publicEmail", "email pubblica", page)
    const pec = useLeapData("pec", "PEC", page)
    const administrativeEmail = useLeapData("administrativeEmail", "email amministrativa", page)

    //estremi bancari
    const piva = useLeapData("piva", "partita iva", page)
    const cf = useLeapData("cf", "codice fiscale", page)
    const sdi = useLeapData("sdi", "SDI", page)

    const header_cc = useLeapData("header_cc", "intestazione c/c", page)
    const iban = useLeapData("iban", "IBAN", page)

    //dati rappresentante legale
    const lastnameLegal = useLeapData("lastnameLegal", "cognome legale rappr", page)
    const firstnameLegal = useLeapData("firstnameLegal", "nome legale rappr", page)
    const fiscalcodelegal = useLeapData("fiscalcodeLegal", "codice fiscale legale rappr", page)

    //dati responsabile clinico
    const nationRegistrationClinicalManagerID = useLeapData("nationRegistrationClinicalManagerID", "nazione iscrizione albo resp clinico", page, "nationRegistrationClinicalManagers")
    const provinceRegistrationClinicalManagerID = useLeapData("provinceRegistrationClinicalManagerID", "provincia iscrizione albo resp clinico", page, "provinceRegistrationClinicalManagers")
    const municipalityRegistrationClinicalManagerID = useLeapData("municipalityRegistrationClinicalManagerID", "comune iscrizione albo resp clinico", page, "municipalityRegistrationClinicalManagers")
    const numberRegistrationClinicalManager = useLeapData("numberRegistrationClinicalManager", "n. iscrizione albo resp clinico", page)

    const clinicalManagerLastname = useLeapData("clinicalManagerLastname", "cognome resp clinico", page)
    const clinicalManagerFirstname = useLeapData("clinicalManagerFirstname", "nome resp clinico", page)
    const birthdateClinicalManager = useLeapData("birthdateClinicalManager", "data di nascita resp clinico", page)

    //altro
    const affiliationDate = useLeapData("affiliationDate", "decorrenza affiliazione", page)
    const terminationDate = useLeapData("terminationDate", "data cessazione", page)

    const leap_HealthFacilityStructureTypeID = useLeapData("leap_HealthFacilityStructureTypeID", "tipo di struttura", page, "healthFacilityStructureTypeOption")
    const website = useLeapData("website", "sito web", page)
    /* {const companyID = useLeapData("companyID", "compagnia", page, "companies") }*/

    const dentalImplant = useLeapData("dentalImplant", "impianti dentali", page)

    const leap_HealthFacilityCertificationQualityID = useLeapData("leap_HealthFacilityCertificationQualityID", "certificazione di qualità", page, "healthFacilityCertificationQualityOption")
    const bodyCertification = useLeapData("bodyCertification", "ente certificatore", page)
    const numberCertification = useLeapData("numberCertification", "n. certificazione", page)

    const sanitaryDistrict = useLeapData("sanitaryDistrict", "distretto sanitario", page)

    const numberInsurancePolicy = useLeapData("numberInsurancePolicy", "n. di polizza assicurativa", page)
    const nameInsurancePolicy = useLeapData("nameInsurancePolicy", "compagnia", page)

    const numberDoctors = useLeapData("numberDoctors", "n. di medici", page)
    const numberAggregate = useLeapData("numberAggregate", "n. riuniti", page)

    const healthAgreementID = useLeapData("healthAgreementID", "convenzioni attive", page, "healthAgreement")
    const other = useLeapData("other", "altre", page)

    const leap_HealthFacilityVariationAcceptanceLowerRateID = useLeapData("leap_HealthFacilityVariationAcceptanceLowerRateID", "variazione accettazione tariffario inferiore", page, "healthFacilityVariationAcceptanceLowerRateOption")
    const leap_OrthopantomographID = useLeapData("leap_HealthFacilityOrthopantomographID", "ortopantomografo", page, "healthFacilityOrthopantomographOption")
    const leap_HealthFacilityWelfareWarrantID = useLeapData("leap_HealthFacilityWelfareWarrantID", "mandato welfare", page, "healthFacilityWelfareWarrantOption")
    const leap_HealthFacilityPerformanceMaximumID = useLeapData("leap_HealthFacilityPerformanceMaximumID", "solo prestazioni a massimale", page, "healthFacilityPerformanceMaximumOption")

    const leap_HealthFacilityMembershipWelfareID = useLeapData("leap_HealthFacilityMembershipWelfareID", "adesione welfare", page, "healthFacilityMembershipWelfareOption")
    const membershipWelfareDate = useLeapData("membershipWelfareDate", "data adesione welfare", page)

    const leap_HealthFacilityGenerationPermitID = useLeapData("leap_HealthFacilityGenerationPermitID", "generazione permesso", page, "healthFacilityGenerationPermitOption")
    const leap_HealthFacilityPrivacyAcceptedID = useLeapData("leap_HealthFacilityPrivacyAcceptedID", "privacy accettata", page, "healthFacilityPrivacyAcceptedOption")
    const leap_HealthFacilityNewsletterID = useLeapData("leap_HealthFacilityNewsletterID", "newsletter", page, "healthFacilityNewsletterOption")
    const leap_HealthFacilityOnlineAgendaID = useLeapData("leap_HealthFacilityOnlineAgendaID", "adesione agenda online", page, "healthFacilityOnlineAgendaOption")
    const leap_HealthFacilityOnlineAgendaNotificationID = useLeapData("leap_HealthFacilityOnlineAgendaNotificationID", "promemoria agenda online", page, "healthFacilityOnlineAgendaOption")
    const leap_HealthFacilityAcceptanceDataCommercialPurposesID = useLeapData("leap_HealthFacilityAcceptanceDataCommercialPurposesID", "accettazione dati a fini commerciali", page, "healthFacilityAcceptanceDataCommercialPurposeOption")

    const leap_VoluntaryAcceptanceStrictID = useLeapData("voluntaryAcceptanceStrictID", "consenso minimo", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceMarketingID = useLeapData("voluntaryAcceptanceMarketingID", "consenso marketing", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceOthersID = useLeapData("voluntaryAcceptanceOthersID", "consenso terzi", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceMailingListID = useLeapData("voluntaryAcceptanceMailingListID", "mailing list", page, "voluntaryAcceptance")

    //sede legale
    const municipalityLegalID = useLeapData("municipalityLegalID", "comune sede legale", page, "municipalityLegals")
    const provinceLegal = useLeapData("provinceLegal", "provincia sede legale", page, "provinces")
    const nationLegalID = useLeapData("nationLegalID", "nazione sede legale", page, "nationLegals")

    const addressLegal = useLeapData("addressLegal", "indirizzo sede legale", page)
    const streetAddressLegal = useLeapData("streetAddressLegal", "civico sede legale", page)
    const postalCodeLegal = useLeapData("postalCodeLegal", "cap sede legale", page)

    const longitude = useLeapData("longitude", "", page)
    const latitude = useLeapData("latitude", "", page)

    const isOtherSelected: boolean = page.item?.values?.healthAgreementID?.value.includes("999");
    const nationSelected: boolean = page.item?.values?.nationID?.value !== undefined && page.item?.values?.nationID?.value !== "" && page.item?.values?.nationID?.value !== null;
    //const provinceSelected: boolean = page.item?.values?.provinceID?.value !== undefined && page.item?.values?.provinceID?.value !== "" && page.item?.values?.provinceID?.value !== null;
    //const regionSelected: boolean = page.item?.values?.regionID?.value !== undefined && page.item?.values?.regionID?.value !== "" && page.item?.values?.regionID?.value !== null;    

    const operators_empty = page?.item?.state?.lists?.Operators === null || page?.item?.state?.lists?.Operators?.length === 0
    const doctors_empty = page?.item?.state?.lists?.Doctors === null || page?.item?.state?.lists?.Doctors?.length === 0
    const notUsableHealthService_empty = page?.item?.state?.lists?.NotUsableHealthService === null || page?.item?.state?.lists?.NotUsableHealthService?.length === 0
    const contractFolder_empty = page?.item?.state?.lists?.ContractFolder === null || page?.item?.state?.lists?.ContractFolder?.length === 0
    const notUsablePlan_empty = page?.item?.state?.lists?.NotUsablePlan === null || page?.item?.state?.lists?.NotUsablePlan?.length === 0

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const metadataPage = dItem?.metadata?.page

    const onExecuteRegeneratePassword = () => {
        toggleHideDialogRegeneratePassword()
        dispatch(RegeneratePassword({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }

    const onExecuteGenerateMaster = () => {
        toggleHideDialogGenerateMaster()
        dispatch(GenerateMasterSS({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }

    const hasUser: string = page?.item?.state?.data?.hasUser;
    const hasWelfareWarrant = page?.item?.state?.data?.leap_HealthFacilityWelfareWarrantID === '1'
    const canJoinWelfare = page?.item?.state?.data?.leap_HealthFacilityMembershipWelfareID !== '1'

    const noDetailAccessLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            textAlign: "center",
            whiteSpace: "nowrap"
        }
    }

    return <>
        <Content title={isHF ? "Struttura Sanitaria" : "Strutture Sanitarie"} controller={controller}>
            <Filter>
                <Grid>
                    {(isHF && !roles.isHFMaster) && <div className="ms-Grid-col ms-sm12">
                        <Label styles={noDetailAccessLabel}>non è possibile applicare filtri di ricerca</Label>
                    </div>}
                    <Section toolbar="filter" hidden={!isHFMaster && isHF}>
                        <Row hidden={!isHFMaster && isHF}>
                            <Text {...code} className="ms-sm2" maxlength={12} hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                            <Text {...businessName} className="ms-sm4" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                            <Option {...leap_HealthFacilityStateID} className="ms-sm1" hidden={isInsuredUser || isConsultant || isCorporation} type="horizontal" />
                        </Row>
                        <Row hidden={!isHFMaster && isHF}>
                            <Text {...denomination} className="ms-sm6" />
                            {(isInsuredUser) && <InsuredMaps state={page} controller={controller} />}
                        </Row>
                        <Row hidden={!isHFMaster && isHF}>
                            <Text {...lastnameLegal} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                            <Text {...firstnameLegal} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                            <Text {...fiscalcodelegal} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={!isHFMaster && isHF}>
                            <SearchBox {...nationID} className="ms-sm5" hidden={isInsuredUser} />
                            <SearchBox {...regionID} className="ms-sm5" hidden={isInsuredUser} />
                        </Row>
                        <Row hidden={!isHFMaster && isHF}>
                            <SearchBox {...provinceID} className="ms-sm5" required={!page?.filter?.values?.municipalityID?.value && isInsuredUser} />
                            <SearchBox {...municipalityID} className="ms-sm5" required={!page?.filter?.values?.provinceID?.value && isInsuredUser} />
                        </Row>
                        <Row hidden={!isHFMaster && isHF}>
                            <MultiBox {...healthAgreementID} label="convenzioni" className="ms-sm5" titleType='text' hidden={!isONHC} />
                            <Text {...sanitaryDistrict} className="ms-sm2" hidden={!isONHC} />
                        </Row>
                    </Section >
                    <Section title="altro" hidden={isInsuredUser || isConsultant || isCorporation || isHF}>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation || isHF}>
                            <ComboBox {...leap_HealthFacilityStructureTypeID} className="ms-sm4" />
                            <Separator className="ms-sm1" />
                            <Option {...leap_HealthFacilityMembershipWelfareID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation} disabled={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation || isHF}>
                            <Option {...leap_HealthFacilityVariationAcceptanceLowerRateID} label="variazione tariffario" className=" ms-sm1" type="horizontal" />
                            <Separator className="ms-sm4" />
                            <Option {...leap_HealthFacilityOnlineAgendaID} label="agenda online" className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF} />{/*agenda online*/}
                        </Row>
                    </Section>
                    {false && <Section hidden={isInsuredUser || isConsultant || isCorporation} title="estrazione strutture sanitarie">
                        <Row>
                            <PrimaryButton text="estrazione strutture sanitarie newsletter" style={{ marginTop: "20px" }} readOnly={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row>
                            <PrimaryButton text="estrazione strutture sanitarie a fini commerciali" style={{ marginTop: "20px" }} readOnly={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section >
                    }
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <HealthFacilityResultList controller={controller} items={page.result?.state?.data} isConsultant={isConsultant} isCorporation={isCorporation} isCompany={isCompany} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" hidden={isInsuredUser || isConsultant || isCorporation} readOnly /*={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser}*/ />
                            {page.modality === "item" && isONHC && hasUser && (
                                <>
                                    <PrimaryButton text="Resetta password" onClick={toggleHideDialogRegeneratePassword} style={{ marginTop: "20px", marginLeft: "15px" }} />
                                    <Dialog hidden={hideDialogRegeneratePassword} onDismiss={toggleHideDialogRegeneratePassword} dialogContentProps={dialogContentPropsRegeneratePassword} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegeneratePassword} text="Resetta password" />
                                            <DefaultButton onClick={toggleHideDialogRegeneratePassword} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                            {page.modality === "item" && isONHC && !hasUser && (
                                <>
                                    <PrimaryButton text="Genera Utente Master Struttura Sanitaria" onClick={toggleHideDialogGenerateMaster} style={{ marginTop: "20px", marginLeft: "15px" }} />
                                    <Dialog hidden={hideDialogGenerateMaster} onDismiss={toggleHideDialogGenerateMaster} dialogContentProps={dialogContentPropsGenerateUser} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteGenerateMaster} text="Genera Utente" />
                                            <DefaultButton onClick={toggleHideDialogGenerateMaster} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                        </Row>
                        <Row>
                            <Text {...denomination} className="ms-sm4" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} required />
                            <Option {...leap_OrthopantomographID} className="ms-sm1" type="horizontal" disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section>
                    <Section title="recapiti">
                        <Row>
                            <SearchBox {...nationID} className="ms-sm4" disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <SearchBox {...regionID} className="ms-sm4" disabled={!(nationSelected) || isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden />
                            <SearchBox {...municipalityID} className="ms-sm5" disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            {page.modality === "item" &&
                                <MapsModal controller={controller} state={page} longitude={longitude} latitude={latitude} mapType="location" />
                            }
                        </Row>
                        <Row>
                            <Text {...postalCode} className="ms-sm1" maxlength={5} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...address} className="ms-sm5" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...streetAddress} className="ms-sm1" maxlength={8} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />

                        </Row>
                        <Row>
                            <Text {...telephoneNumber} className="ms-sm2" required readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...mobilePhone} className="ms-sm2" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...fax} className="ms-sm2" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isConsultant || isCorporation}>
                            <Text {...website} className="ms-sm6" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isConsultant || isCorporation} />
                            <Text {...pec} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isConsultant || isCorporation}>
                            <Text {...email} className="ms-sm3" required readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...publicEmail} className="ms-sm3" maxlength={255} />
                            <Text {...administrativeEmail} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section>
                    <Section title="dati sede legale" hidden={isInsuredUser || isConsultant || isCorporation} >
                        <Row>
                            <Text {...businessName} className="ms-sm8 ms-xxl6" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation || isHF} />
                        </Row>
                        <Row>
                            <Text {...addressLegal} className="ms-sm8 ms-xxl6" readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                            <Text {...streetAddressLegal} className="ms-sm1" maxlength={8} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                            <Separator className="ms-sm1 ms-hiddenXxxlUp" />
                            <Text {...postalCodeLegal} className="ms-sm1" maxlength={5} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row>
                            <SearchBox {...nationLegalID} className="ms-sm5" disabled={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                            <ComboBox {...provinceLegal} className="ms-sm5" disabled={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden />
                            <SearchBox {...municipalityLegalID} className="ms-sm5" disabled={isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...piva} className="ms-sm3 ms-xxl2" maxlength={16} required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation || isHF} />
                            <Text {...cf} className="ms-sm3 ms-xxl2" required maxlength={16} hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation || isHF} />
                            <Text {...sdi} className="ms-sm3 ms-xxl2" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section>
                    <Section title="estremi bancari" hidden={isInsuredUser || isConsultant || isCorporation} >
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...iban} className="ms-sm3" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...header_cc} className="ms-sm3" />
                        </Row>
                    </Section>
                    <Section title="dati rappresentante legale" hidden={isInsuredUser || isConsultant || isCorporation}  >
                        <Row>
                            <Text {...lastnameLegal} className="ms-sm3" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...firstnameLegal} className="ms-sm3" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...fiscalcodelegal} className="ms-sm3" required={roles.isHF} hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section >
                    <Section title="dati della struttura" hidden={isConsultant || isCorporation || isInsuredUser}>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...affiliationDate} // onChange={() => {
                                //if (adesioneW_state === "2" && page.modality === "new") {
                                //todo: not have a state or value of affiliationDate
                                //}
                                //                            }}
                                className="ms-sm2" type="calendar" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <Text {...terminationDate} dateRange={{ minDate: Date().toLocaleString(), maxDate: undefined }} className="ms-sm2" type="calendar" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            {page.modality === "item" &&
                                <Option {...leap_HealthFacilityStateID} className="ms-sm1" type="horizontal" disabled={true} />
                            }
                            <Separator className="ms-sm1" />
                            {/*Bottone di disattiva, */}
                            {page.modality === "item" && display_disactive && !isHF && (<>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 18 }} hidden={isInsuredUser || isConsultant || isCorporation || isONHCOperator || isONHCAffiliationOperator}>
                                    <PrimaryButton text="Disattiva SS" onClick={onClickDisabilita} hidden={isInsuredUser || isConsultant || isCorporation || isONHCOperator || isONHCAffiliationOperator} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteDisabilita} text="Disabilita" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                            <DefaultButton onClick={toggleHideDialog} text="Annulla" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>)}
                            {/*Bottone di attiva, */}
                            {page.modality === "item" && display_active && !isHF && (<>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 18 }} hidden={isInsuredUser || isConsultant || isCorporation || isONHCOperator}>
                                    <PrimaryButton text="Attiva SS" onClick={onClickAbilita} hidden={isInsuredUser || isConsultant || isCorporation || isONHCOperator} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentPropsA} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteAbilita} text="Abilita" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                            <DefaultButton onClick={toggleHideDialog} text="Annulla" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isInsuredUser || isConsultant || isCorporation} />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>)}
                        </Row>
                        <Row hidden={isConsultant || isCorporation}>
                            <ComboBox {...leap_HealthFacilityStructureTypeID} className="ms-sm2" hidden={isInsuredUser || isConsultant || isCorporation} disabled={isHF} />
                            <Text {...numberDoctors} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 6, decimal: 0, min: 0 }} hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...numberAggregate} className="ms-sm2 ms-xxl1" type="number" precision={{ integer: 6, decimal: 0, min: 0 }} hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            {/*<ComboBox {...companyID} className="ms-sm4" required  hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />*/}
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Option {...leap_HealthFacilityCertificationQualityID} className="ms-sm2 ms-xxl1" type="horizontal" disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Separator className="ms-sm1" hidden={isInsuredUser || isConsultant || isCorporation} />
                            <Text {...bodyCertification} className="ms-sm3" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...numberCertification} className="ms-sm2" readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} hidden={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...sanitaryDistrict} className="ms-sm2" hidden={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} readOnly={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...numberInsurancePolicy} className="ms-sm3 ms-xxl2" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...nameInsurancePolicy} className="ms-sm4" required hidden={isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            {/*devono essere 2 combox Other deve essere selezionato dal padre e poi mostrtare il box*/}
                            <MultiBox {...healthAgreementID} className="ms-sm6" titleType='text' hidden={isInsuredUser || isConsultant || isCorporation} disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Row hidden={!isOtherSelected} >
                                <Text {...other} className="ms-sm6" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            </Row>
                            {/*-------------------------------------------------------------------------------------*/}
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Text {...dentalImplant} className="ms-sm12" hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Option {...leap_HealthFacilityWelfareWarrantID} className="ms-sm1" type="horizontal" hidden={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} disabled={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            <Separator className="ms-sm2  ms-xxl1" hidden={isHF} />
                            <Option {...leap_HealthFacilityMembershipWelfareID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation} disabled={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            <Separator className="ms-sm1" />
                            {page.modality === "item" && isHF && canJoinWelfare && <HealthFacilityJoinWelfare hasWelfareWarrant={hasWelfareWarrant} />}
                            {/*Mostra la data solo se si aderisce al Welfare--------------------------------------*/}
                            <Row hidden={!display_date}>
                                <Text {...membershipWelfareDate} className="ms-sm2" type="calendar" readOnly={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            </Row>
                            {/*-------------------------------------------------------------------------------------*/}
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Option {...leap_HealthFacilityPerformanceMaximumID} className="ms-sm1" type="horizontal" hidden={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} disabled={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                            <Separator className="ms-sm2  ms-xxl1" />
                            <Option {...leap_HealthFacilityVariationAcceptanceLowerRateID} className="ms-sm1" type="horizontal" hidden={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} disabled={isHFAdmin || isHFMaster || isHFOperator || isHFUser || isInsuredUser || isConsultant || isCorporation || isHFAdmin || isHFOperator || isHFAccount} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Option {...leap_HealthFacilityGenerationPermitID} className="ms-sm1" type="horizontal" hidden={isHF} />
                            <Separator className="ms-sm2 ms-xxl1" />
                            <Option {...leap_HealthFacilityPrivacyAcceptedID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF} />
                            <Separator className="ms-sm1" />
                            <Option {...leap_HealthFacilityNewsletterID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF} />
                        </Row>
                        <Row hidden={isInsuredUser || isConsultant || isCorporation}>
                            <Option {...leap_HealthFacilityOnlineAgendaID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF}
                                onChange={
                                    (name: any, value: any, required: any, e: any) => {
                                        if (value === "2") {
                                            if (window.confirm('Confermi di voler impostare "adesione agenda online" a "no" ?')) {
                                                page.onchange(name, value, required)
                                            } else {
                                                e.cancel = true
                                            }
                                        }
                                        else {
                                            page.onchange(name, value, required)
                                        }
                                    }
                                } />
                            <Separator className="ms-sm2 ms-xxl1" />
                            <Option {...leap_HealthFacilityOnlineAgendaNotificationID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF || page?.item?.state?.data?.leap_HealthFacilityOnlineAgendaID === "1"} />
                            <Separator className="ms-sm2 ms-xxl1" />
                            <Option {...leap_HealthFacilityAcceptanceDataCommercialPurposesID} className="ms-sm1" type="horizontal" hidden={isInsuredUser || isConsultant || isCorporation || isHF} disabled={isInsuredUser || isConsultant || isCorporation || isHF} />
                        </Row>
                    </Section >
                    <Section title="dati responsabile clinico" hidden={isInsuredUser || isConsultant || isCorporation} >
                        <Row>
                            <SearchBox {...nationRegistrationClinicalManagerID} className="ms-sm5" required hidden={isInsuredUser || isConsultant || isCorporation} disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <SearchBox {...provinceRegistrationClinicalManagerID} className="ms-sm5" required hidden={isInsuredUser || isConsultant || isCorporation} disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <SearchBox {...municipalityRegistrationClinicalManagerID} className="ms-sm5" hidden disabled={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                        <Row>
                            <Text {...numberRegistrationClinicalManager} className="ms-sm2" type="number" precision={{ integer: 16, decimal: 0 }} required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Separator className="ms-sm1" />
                            <Text {...clinicalManagerLastname} className="ms-sm3" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...clinicalManagerFirstname} className="ms-sm3" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                            <Text {...birthdateClinicalManager} dateRange={{ minDate: undefined, maxDate: Date().toLocaleString() }} className="ms-sm2" type="calendar" required hidden={isInsuredUser || isConsultant || isCorporation} readOnly={isHFAdmin || isHFOperator || isInsuredUser || isConsultant || isCorporation} />
                        </Row>
                    </Section >
                    {page.modality === "item" && <>
                        <Section title="dichiarazioni trattamento dati" hidden={!isONHC && !isHF} >
                            <Row>
                                <Option {...leap_VoluntaryAcceptanceStrictID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isHF} disabled={!isHF} />
                                <Option {...leap_VoluntaryAcceptanceMarketingID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isHF} disabled={!isHF} />
                                <Option {...leap_VoluntaryAcceptanceOthersID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isHF} disabled={!isHF} />
                                <Option {...leap_VoluntaryAcceptanceMailingListID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isHF} disabled={!isHF} />
                            </Row>
                        </Section >
                        <Section title="elenco operatori" toolbar="grid" modal={<HealthFacilityOperatorModal state={page} controller={healthFacilityOperatorController} />} modalwidth="600px" controller={healthFacilityOperatorController} modaltitle="Inserisci un Operatore" state={page} >
                            <Row>
                                <HealthFacilityOperatorList items={page.item.state?.lists.Operators} modal={<HealthFacilityOperatorModalEdit state={page} controller={healthFacilityOperatorController} />} controller={healthFacilityOperatorController} modalwidth="600px" modaltitle="Modifica un Operatore" state={page} />
                            </Row>
                        </Section>

                        <Section title="elenco medici" toolbar="grid" modal={<HealthFacilityDoctorModal state={page} controller={healthFacilityDoctorController} />} controller={healthFacilityDoctorController} modalwidth="800px" modaltitle="Inserisci un Medico" state={page} >
                            <Row>
                                <HealthFacilityDoctorList items={page.item.state?.lists.Doctors} modal={<HealthFacilityDoctorModal state={page} controller={healthFacilityDoctorController} />} controller={healthFacilityDoctorController} modalwidth="800px" modaltitle="Modifica un Medico" state={page} />
                            </Row>
                        </Section>

                        <Section title="elenco prestazioni non erogabili" toolbar="grid" modal={<HealthFacilityNotUsableHealthServiceModal state={page} controller={healthFacilityNotUsableHealthServiceController} />} controller={healthFacilityNotUsableHealthServiceController} modalwidth="600px" modaltitle="Inserisci una Prestazione" state={page} >
                            <Row>
                                <HealthFacilityNotUsableHealthServiceList items={page.item.state?.lists.NotUsableHealthService} modal={<HealthFacilityNotUsableHealthServiceModalEdit state={page} controller={healthFacilityNotUsableHealthServiceController} />} controller={healthFacilityNotUsableHealthServiceController} modalwidth="600px" modaltitle="Modifica una Prestazione" state={page} />
                            </Row>
                        </Section>

                        <Section title="cartella contratto" toolbar="grid" modal={<HealthFacilityContractFolderModal state={page} controller={healthFacilityContractFolderController} isNew />} controller={healthFacilityContractFolderController} modalwidth="600px" modaltitle="Inserisci File" state={page} >
                            <Row>
                                <HealthFacilityContractFolderList items={page.item.state?.lists.ContractFolder} modal={<HealthFacilityContractFolderModal state={page} controller={healthFacilityContractFolderController} isNew={false} />} controller={healthFacilityContractFolderController} modalwidth="600px" modaltitle={isHF ? "Download File" : "Modifica File"} state={page} />
                            </Row>
                        </Section>

                        <Section title="elenco piani sanitari non erogabili" toolbar="grid" modal={<HealthFacilityNotUsablePlanModal state={page} controller={healthFacilityNotUsablePlanController} />} controller={healthFacilityNotUsablePlanController} modalwidth="600px" modaltitle="Inserisci un Piano Sanitaro" state={page} >
                            <Row>
                                <HealthFacilityNotUsablePlanList items={page.item.state?.lists.NotUsablePlan} modal={<HealthFacilityNotUsablePlanModal state={page} controller={healthFacilityNotUsablePlanController} />} controller={healthFacilityNotUsablePlanController} modalwidth="600px" modaltitle="Modifica un Piano Sanitaro" state={page} />
                            </Row>
                        </Section>

                        <Section title="orari e ferie" toolbar="grid" modal={<HealthFacilityVacationModal state={page} controller={healthFacilityVacationController} />} controller={healthFacilityVacationController} modalwidth="600px" modaltitle="Inserisci le ferie" state={page} >
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <HealthFacilityVacationList items={page.item.state?.lists.Vacations} modal={<HealthFacilityVacationModal state={page} controller={healthFacilityVacationController} />} controller={healthFacilityVacationController} modalwidth="600px" modaltitle="Modifica le ferie" state={page} />
                                <HealthFacilityTimetableList items={page.item.state?.lists.Timetables} modal={<HealthFacilityTimetableModal state={page} controller={healthFacilityTimetableController} />} controller={healthFacilityTimetableController} modalwidth="600px" modaltitle="Modifica l'orario" state={page} />
                            </Row>
                        </Section>

                        {page.modality === "item" && isONHC && (<Section title="elenco invii feedback periodici" toolbar="grid" modal={<SurveyModal state={page} controller={surveyController} />} controller={surveyController} modalwidth="800px" modaltitle="richiedi feedback periodico" state={page} >
                            <Row>
                                <SurveyList items={page.item.state?.lists?.Survey} />
                            </Row>
                        </Section>)}

                        {isONHC && <Section title="variazioni welfare">
                            <Row>
                                <HealthFacilityWelfareLogs items={page.item.state?.lists.WelfareLogs} />
                            </Row>
                        </Section>}
                    </>}
                </Grid>
            </Item>
        </Content>
    </>
};
