import * as React from 'react';

import { useDispatch } from 'react-redux'

import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Nome' fieldName='name' align='left' minWidth={150} />
                <DataColumn kid='column2' name='Codice Catastale' fieldName='cadastralCode' align='left' maxWidth={250} minWidth={150} />
                <DataColumn kid='column3' name='CAP' fieldName='cap' align='right' maxWidth={250} minWidth={150} />
                <DataColumn kid='column4' name='Data Inizio' fieldName='startDate' align='center' maxWidth={250} minWidth={150} />
                <DataColumn kid='column5' name='Data Fine' fieldName='endDate' align='center' maxWidth={250} minWidth={150} />
            </DataList>
        </>
    );
}