import * as React from 'react';

import { Text, ITextStyles } from '@fluentui/react/lib/Text';

import Theme from '../themes/Theme';
import { titleTheme } from '../themes/Fonts';


const titleStyles: ITextStyles = {
    root: {
        lineHeight: 33,
        padding: Theme.leap.header.title.padding,
    },
};

const primaryGreenStyle: React.CSSProperties = {
    color: "#4d858e"
}
const altGreenStyle: React.CSSProperties = {
    color: "#0a5e68"
}

export default () => 
    <>
    </>
