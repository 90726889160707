import { DefaultButton, Dialog, DialogFooter, DialogType, FontSizes, getTheme, mergeStyleSets, Modal, PrimaryButton, Link } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { security } from '../security/thunks/securityThunk';
import Option from '../../components/controls/Option';

export const DoReadDeclarations = createAsyncThunk(
    'item/MagazineReadDeclarations',
    async (args: { token: string | undefined }) => {
        const response = await client.post("Magazine", { form: {}, page: {} }, 'ReadDeclarations', args.token)
        return response
    }
)
export const DoSaveDeclarations = createAsyncThunk(
    'item/MagazineSaveDeclarations',
    async (args: { body: any, token: string | undefined }) => {
        const response = await client.post("Magazine", { form: args.body, page: {} }, 'SaveDeclarations', args.token)
        return response
    }
)

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        maxHeight: "80vh",
        padding: "30px 0 20px",
        width: 800
    },
    content: {
        overflowY: "visible",
    },
    header: {
        color: Theme.fluent.palette.themePrimary,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.xxLargePlus,
        fontVariant: 'small-caps',
        fontWeight: 600,
        padding: "20px 0 30px",
        textAlign: "center"
    },
    body: {
        fontFamily: FontFaces.openSans,
        fontSize: 16,
        overflowY: "visible",
        padding: "0 32px",
        textAlign: "justify",
        selectors: {
            p: { margin: '16px 0' },
            'p:nth-child(1)': { margin: "0 0 16px" },
            'p:nth-child(5)': { margin: "40px 0" },
            'p:nth-child(6)': { margin: "40px 0 40px" },
            'span.orange': { color: "#ff8200", fontWeight: 500 },
            'span.download': { color: "#ff8200", cursor: "pointer", fontWeight: 500, textDecoration: "underline" },
            'div.ms-ChoiceFieldGroup-flexContainer': { justifyContent: "flex-end" },
            'div.ms-ChoiceField-wrapper': { marginRight: 8 },
            'div.ms-ChoiceFieldGroup-flexContainer input': { fontSize: "1.1em", fontWeight: 600 },
            'div.ms-ChoiceFieldGroup-flexContainer label': { fontSize: "1.1em", fontWeight: 600, textTransform: "lowercase" }
        },
    },
    primaryButton: {
        padding: "36px 0 16px",
        textAlign: "center",
        selectors: {
            button: { backgroundColor: "#ff8200", borderColor: "#ff8200", borderRadius: 4, color: Theme.fluent.palette.white, fontWeight: 600, width: 220 },
            'button:active': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" },
            'button:hover': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" }
        },
    },
    standardButton: {
        padding: "16px 32px 8px",
        textAlign: "right",
        selectors: {
            button: { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", borderRadius: 4, color: "#ff8200", fontSize: "0.7em", margin: '8px 0', width: 180 },
            'button:active': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white },
            'button:hover': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white }
        },
    }
});

export default () => {
    const dispatch: AppDispatch = useDispatch();

    const roles = useSecurityRoles()
    const isHF = roles.isHF;
    const isInsured = roles.isInsuredUser;
    const credential = useSelector(security)?.credential

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isDialogOpen, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);

    const [modalData, setModalData] = React.useState({
        voluntaryAcceptanceStrictID: "" as string | number | undefined,
        voluntaryAcceptanceMarketingID: "" as string | number | undefined,
        voluntaryAcceptanceOthersID: "" as string | number | undefined,
        voluntaryAcceptanceMailingListID: "" as string | number | undefined,
    })

    const [dialogText, setDialogText] = React.useState("")

    //const leap_VoluntaryAcceptanceStrictID = useLeapData("voluntaryAcceptanceStrictID", "consenso minimo", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceMarketingID = useLeapData("voluntaryAcceptanceMarketingID", "consenso marketing", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceOthersID = useLeapData("voluntaryAcceptanceOthersID", "consenso terzi", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceMailingListID = useLeapData("voluntaryAcceptanceMailingListID", "mailing list", page, "voluntaryAcceptance")

    const voluntaryAcceptance = [{ key: "1", text: "Acconsento" }, { key: "2", text: "Non acconsento" }]

    const leap_VoluntaryAcceptanceStrictID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceStrictID }
    const leap_VoluntaryAcceptanceMarketingID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceMarketingID }
    const leap_VoluntaryAcceptanceOthersID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceOthersID }
    const leap_VoluntaryAcceptanceMailingListID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceMailingListID }

    React.useEffect(() => {
        if (!!!isHF && !!!isInsured) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoReadDeclarations({ token: credential.token }))
            .then((response) => {
                if (!!response?.payload?.data?.showVoluntaryAcceptance) {
                    setModalData(response?.payload?.data)
                    showModal()
                }
            })

    }, [isHF, credential])

    const saveDeclarations = () => {
        if (!!!isHF && !!!isInsured) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoSaveDeclarations({ body: modalData, token: credential.token }))
            .then((response) => {
                if (response.payload?.response?.level === 4) {
                    setDialogText("Dati correttamente aggiornati.")
                }
                else {
                    setDialogText("Si � verificato un errore. Contattare l'assistenza.")
                }
                return response
            })
            .then((response) => {
                showDialog()
            })
    }

    const dialogStyles = { main: { maxWidth: 600 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Consenso',
        closeButtonAriaLabel: 'Chiudi',
        subText: dialogText,
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    return <>
        <Modal titleAriaId={"ReservationRequest"} isOpen={isModalOpen} isBlocking containerClassName={contentStyles.container} scrollableContentClassName={contentStyles.content}>
            <div className={contentStyles.header}>
                <span>TRATTAMENTO DEI DATI PERSONALI</span>
            </div>
            <div className={contentStyles.body}>
                <p>
                    Odontonetwork e&#768; una societa&#768; di servizi specializzata nello sviluppo e nella gestione di formule di assistenza sanitaria in ambito odontoiatrico.
                    Odontonetwork non somministra direttamente le prestazioni odontoiatriche agli assistiti, ma autorizza l&apos;erogazione di tali prestazioni all&apos;interno
                    delle strutture sanitarie convenzionate inserite nel suo network. La copertura di tali prestazioni e&#768; garantita da contratti o convenzioni sottoscritti
                    da Odontonetwork con le societa&#768; assicuratrici, direttamente o tramite la partnership con Enti, Casse Assistenziali/Fondi Sanitari, aziende pubbliche e
                    private. La informiamo pertanto che, ai sensi dell&apos;Art. 13 del GDPR, per garantire le prestazioni in Suo favore, Odontonetwork ha necessita&#768; di utilizzare
                    alcuni dati personali che possono riguardale Lei e, in caso in cui la copertura sia estesa al nucleo familiare, anche i Suoi famigliari e conviventi,
                    per le finalita&#768; e nei termini di seguito indicati. Odontonetwork ha necessita&#768; di raccogliere, utilizzare, consultare e, piu&#768; in generale,
                    trattare dati personali identificativi (quali nome, cognome, codice fiscale, data di nascita, indirizzo di residenza, recapito telefonico
                    e di posta elettronica), forniti direttamente da Lei o da soggetti terzi da Lei autorizzati (Aziende, Enti, Casse e Fondi Sanitari).
                    Tali dati sono indispensabili per attivare l&apos;erogazione delle prestazioni odontoiatriche rientranti nella copertura di cui Lei beneficia.
                    Il conferimento di questi dati e&#768; necessario per l&apos;erogazione e la gestione della prestazione odontoiatrica, e in alcuni casi e&#768; obbligatorio per legge,
                    regolamento, normativa comunitaria o in base alle disposizioni impartite da soggetti pubblici quali l&apos;Autorita&#768; Giudiziaria o le Autorita&#768; di vigilanza.
                    In assenza di tali dati non saremmo in grado di fornirle correttamente le prestazioni richieste, inclusa la gestione degli appuntamenti e l&apos;invio di avvisi
                    e comunicazioni di servizio che le indirizzeremo attraverso i dati di contatto da lei indicati. Possiamo trattare anche i dati bancari che Lei eventualmente ci fornira&#768;,
                    qualora voglia beneficiare del rimborso di prestazioni a pagamento o della liquidazione degli indennizzi. Raccogliamo inoltre categorie particolari di dati
                    (idonei a rivelare le condizioni di salute, come quelli riportati in prescrizioni, referti, fatture di specialisti, ecc.) che potranno esserci trasmessi
                    dalle strutture sanitarie convenzionate o direttamente da Lei, attraverso l&apos;invio in formato cartaceo o il caricamento in formato digitale nell&apos; 	&quot;Area riservata&quot; della web app
                    Odontonetwork Healthcare System, per dare corso alle prestazioni previste dal suo piano sanitario  Il trattamento di questi dati e&#768; necessario per il perfezionamento del contratto
                    di prestazione sanitaria di cui Lei e&#768; parte in quanto Odontonetwork non potra&#768; adempiere la propria obbligazione (autorizzare l&apos;erogazione e gestire le prestazioni)
                    senza trattare anche i dati sulla salute degli assistiti.
                </p>
                <p>
                    Inoltre, previo suo esplicito consenso facoltativo, revocabile in qualsiasi momento, i suoi dati personali potranno essere utilizzati dal Titolare anche per:
                    i) finalita&#768; promozionali, pubblicitarie e di marketing quali l&apos;invio di newsletter, materiale pubblicitario, promozionale ed informativo su prodotti e servizi erogati
                    da Odontonetwork Srl;
                    ii) analisi statistiche dirette a rilevare il grado di soddisfazione dei servizi offerti,
                    iii) condivisione dei suoi dati personali con soggetti terzi partner di Odontonetwork affinche&#769; gli stessi possano contattarla per finalita&#768; promozionali.
                </p>
                <p>
                    Per visionare l&apos;informativa privacy completa del Servizio si rimanda alla Sezione Documentazione di quest&apos;area web riservata
                </p>
                {/*<Option {...leap_VoluntaryAcceptanceStrictID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceStrictID: value }) }} />*/}
                <p>
                    Acconsento al trattamento dei miei dati personali per finalita&#768; promozionali, pubblicitarie e di marketing quali l&apos;invio di  newsletter,
                    materiale pubblicitario, promozionale ed informativo su prodotti e servizi, nonche&#769; per analisi statistiche dirette a rilevare il grado di soddisfazione 
                    dei servizi / prodotti offerti. 
                </p>
                <Option {...leap_VoluntaryAcceptanceMarketingID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceMarketingID: value }) }} />
                <p>
                    Acconsento al trattamento dei miei dati personali da parte di soggetti terzi partner di Odontonetwork per essere contattato per finalita&#768; promozionali,
                    pubblicitarie e di marketing quali l&apos;invio di materiale pubblicitario, promozionale ed informativo su prodotti e servizi dei soggetti terzi.  
                </p>
                <Option {...leap_VoluntaryAcceptanceOthersID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceOthersID: value }) }} />
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.*/}
                {/*</p>*/}
                {/*<Option {...leap_VoluntaryAcceptanceMailingListID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceMailingListID: value }) }} />*/}
            </div>
            <div className={contentStyles.primaryButton}>
                <PrimaryButton text="SALVA" onClick={saveDeclarations} />
            </div>
            <div className={contentStyles.standardButton}>
                <DefaultButton text="RICORDA PIU&#768; TARDI" onClick={hideModal} />
            </div>
        </Modal>
        <Dialog hidden={!isDialogOpen} dialogContentProps={dialogContentProps} modalProps={modalProps} isBlocking>
            <DialogFooter>
                <DefaultButton onClick={() => { hideDialog(); hideModal() }} text="Chiudi" />
            </DialogFooter>
        </Dialog>
    </>
}
