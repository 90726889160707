import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)


    const odQuote = useLeapData("odQuote", "quota OD", page)
    const welfareQuote = useLeapData("welfareQuote", "quota welfare", page)
    const healthService = useLeapData("healthService", "prestazione", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Row>
                            <Text {...healthService} className="ms-sm12" readOnly />
                        </Row>
                        <Text {...odQuote} className="ms-sm3" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                        <Text {...welfareQuote} className="ms-sm3" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
