import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='N. Iscrizione Albo' fieldName='numberRegistration' minWidth={100} maxWidth={120} align='right' />
                <DataColumn kid='column2' name='Provincia Iscrizione Albo' fieldName='provinceID' minWidth={170} maxWidth={200} align='left' />
                <DataColumn kid='column3' name='Nazione Iscrizione Albo' fieldName='nationID' minWidth={160} maxWidth={200} align='left' />
                <DataColumn kid='column4' name='Cognome' fieldName='lastname' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column5' name='Nome' fieldName='firstname' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column6' name='Tipo' fieldName='leap_HealthFacilityDoctorTypeID' minWidth={100} maxWidth={120} align='left' />
                <DataColumn kid='column7' type="icon" name='Socio Accademie Scientifiche' fieldName='leap_HealthFacilityDoctorMemberScientificAccademiesID' minWidth={200} maxWidth={250} align='left' />
                <DataColumn kid='column8' name='Accademie Scientifiche' fieldName='scientificAccademies' minWidth={600} align='left' />
                <DataColumn kid='column9' name='Altro' fieldName='otherAccademy' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
