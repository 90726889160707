import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState'
import { useLeapData } from '../hooks/useLeapData'

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import { useBoolean, useId } from '@uifabric/react-hooks';
import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';

import ConsultantResultList from '../features/consultant/ConsultantResultList';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import { DialogType, PrimaryButton, Dialog, DialogFooter, DefaultButton } from '@fluentui/react';
import { AppDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { itemData } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { setResponse } from '../features/response/responseSlice';
import DateRange from '../components/controls/DateRange';
import { setOperation } from '../features/operation/operationSlice';

export const RegeneratePassword = createAsyncThunk(
    'item/RegeneratePassword',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Consultant", { form: {}, page: args.page }, 'RegeneratePassword', args.token)
        return response
    }
)

export const GenerateUser = createAsyncThunk(
    'item/GenerateUser',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Consultant", { form: {}, page: args.page }, 'GenerateUser', args.token)
        return response
    }
)

export default () => {
    const roles = useSecurityRoles()
    const isConsultant: boolean = roles.isConsultant;

    const controller = "Consultant"
    const page = useLeapState(controller, roles.role !== undefined, isConsultant ? "item" : "filter")

    const code = useLeapData("code", "codice", page)
    const startDate = useLeapData("startDate", "data inizio validità", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const leap_ConsultantStateID = useLeapData("leap_ConsultantStateID", "attivo", page, "consultantStateOption")
    const leap_ConsultantManagementTypeID = useLeapData("leap_ConsultantManagementTypeID", "tipo gestione", page, "consultantManagementTypeOption")
    const firstname = useLeapData("firstname", "nome", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const email = useLeapData("email", "email", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const phoneNumber = useLeapData("phoneNumber", "telefono", page)

    //domicilio
    const address = useLeapData("address", "indirizzo", page)
    const streetNumber = useLeapData("streetNumber", "civico", page)
    const cap = useLeapData("cap", "cap", page)
    const nationID = useLeapData("nationID", "nazione", page, "nations")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")

    const isONHC: boolean = roles.isONHCAccount || roles.isONHCAdmin || roles.isONHCMaster || roles.isONHCOperator;

    const hasUser: string = page?.item?.state?.data?.hasUser;

    const [hideDialogRegeneratePassword, { toggle: toggleHideDialogRegeneratePassword }] = useBoolean(true);
    const [hideDialogGenerateUser, { toggle: togglehideDialogGenerateUser }] = useBoolean(true);

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const dialogContentPropsRegeneratePassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla rigenerazione della password?',
    };

    const dialogContentPropsGenerateUser = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla generazione dell\'utente?',
    };

    const dispatch: AppDispatch = useDispatch();
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === controller)?.value
    const metadataPage = dItem?.metadata?.page
    const credential = useSelector(security)?.credential

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteRegeneratePassword = () => {
        toggleHideDialogRegeneratePassword()
        dispatch(RegeneratePassword({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
    }
    const onExecuteGenerateUser = () => {
        togglehideDialogGenerateUser()
        dispatch(GenerateUser({ token: credential.token, page: metadataPage }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            })
            .then(dispatchReload)
            .then(dispatchMessage)
    }

    return <>
        <Content title={isConsultant ? "Il mio Profilo" : "Consultatori"} controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm3" maxlength={12} />
                            <Option {...leap_ConsultantStateID} className="ms-sm3" type="horizontal" />
                            <Option {...leap_ConsultantManagementTypeID} className="ms-sm3" type="horizontal" />
                        </Row>
                        <Row>
                            <Text {...firstname} className="ms-sm3" />
                            <Text {...lastname} className="ms-sm3" />
                            <Text {...email} className="ms-sm3" />
                        </Row>
                        <Row>
                            <SearchBox {...nationID} className="ms-sm3" />
                            <SearchBox {...regionID} className="ms-sm3" />
                        </Row>
                        <Row>
                            <SearchBox {...provinceID} className="ms-sm3" />
                            <SearchBox {...municipalityID} className="ms-sm3" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <ConsultantResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm3" readOnly />
                            <Option {...leap_ConsultantStateID} className="ms-sm3" type="horizontal" disabled={isConsultant} />
                            <Option {...leap_ConsultantManagementTypeID} className="ms-sm3" type="horizontal" required disabled={isConsultant} />
                            {page.modality === "item" && isONHC && hasUser && (
                                <>
                                    <PrimaryButton text="Resetta password" onClick={toggleHideDialogRegeneratePassword} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogRegeneratePassword} onDismiss={toggleHideDialogRegeneratePassword} dialogContentProps={dialogContentPropsRegeneratePassword} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteRegeneratePassword} text="Resetta password" />
                                            <DefaultButton onClick={toggleHideDialogRegeneratePassword} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                            {page.modality === "item" && isONHC && !hasUser && (
                                <>
                                    <PrimaryButton text="Genera Utente" onClick={togglehideDialogGenerateUser} style={{ marginTop: "20px", marginLeft: "25px" }} />
                                    <Dialog hidden={hideDialogGenerateUser} onDismiss={togglehideDialogGenerateUser} dialogContentProps={dialogContentPropsGenerateUser} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteGenerateUser} text="Genera Utente" />
                                            <DefaultButton onClick={togglehideDialogGenerateUser} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </>
                            )}
                        </Row>
                        {isONHC && hasUser && (
                            <>
                                <Row>
                                    <DateRange dateMin={{ ...startDate, className: "ms-sm2", readOnly: isConsultant }} dateMax={{ ...endDate, className: "ms-sm2", readOnly: isConsultant }} />
                                </Row>
                            </>)}
                        < Section title="dati anagrafici">
                            <Row>
                                <Text {...firstname} className="ms-sm3" required readOnly={isConsultant} />
                                <Text {...lastname} className="ms-sm3" required readOnly={isConsultant} />
                                <Text {...email} className="ms-sm3" required readOnly={isConsultant} />
                            </Row>
                            <Row>
                                <Text {...businessName} className="ms-sm3" readOnly={isConsultant} />
                                <Text {...phoneNumber} className="ms-sm3" readOnly={isConsultant} />
                            </Row>

                        </Section>
                    </Section>
                    <Section title="domicilio">
                        <Row>
                            <Text {...address} className="ms-sm4" readOnly={isConsultant} />
                            <Text {...streetNumber} className="ms-sm1" readOnly={isConsultant} />
                            <Text {...cap} className="ms-sm1" readOnly={isConsultant} />
                        </Row>
                        <Row>
                            <SearchBox {...nationID} className="ms-sm3" disabled={isConsultant} />
                            <SearchBox {...municipalityID} className="ms-sm3" disabled={isConsultant} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content >
    </>
}
