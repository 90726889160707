import * as React from 'react';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    readOnly?: boolean,
    canViewRefounds?: boolean
}

export default (props: Props) => {
    const DataListProps = {
        items: props.items,
        modaltitle: props.modaltitle,
        modalController: props.controller,
        modal: props.modal,
        state: props.state,
    }

    return (
        <>
            <DataList {...DataListProps} modalwidth={props.modalwidth}>
                {!props.readOnly && <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />}
                <DataColumn kid='column1' name='Branca' fieldName='branchDescr' minWidth={180} maxWidth={180} align='left' />
                <DataColumn kid='column2' name='Prestazione' fieldName='healthServiceDescr' minWidth={180} align='left' />
                <DataColumn kid='column4' name='Elemento Dentale' fieldName='dentalElement' minWidth={130} maxWidth={130} align='left' />
                <DataColumn kid='column6' name='Importo' fieldName='amount' minWidth={100} maxWidth={100} align='right' />
                {props.canViewRefounds && <DataColumn kid='column7' name='Rimborso' fieldName='refound' minWidth={100} maxWidth={100} align='right' />}
                {!props.readOnly && <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            </DataList>
        </>
    );
}
