import * as React from 'react';

import { Stack } from '@fluentui/react/lib/Stack';

import Version from '../../controls/Version';
import CurrentDateTime from '../../controls/CurrentDateTime';

export default () => (
    <Stack horizontal horizontalAlign="space-between">
        <Stack.Item align='start'>
            <Version />
        </Stack.Item>
        <Stack.Item align='end'>
            <CurrentDateTime />
        </Stack.Item>
    </Stack>
);