import * as React from 'react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux'; import { AppDispatch } from '../../app/store';

import { security } from '../security/thunks/securityThunk';

import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType, IButtonProps } from '@fluentui/react';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { setResponse } from '../response/responseSlice';
import { setOperation } from '../operation/operationSlice';
import { itemData } from '../operation/thunks/itemThunk';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { isLocked, lock, unlock } from '../operation/lockSlice';
export const gotoCancellato = createAsyncThunk(
    'item/gotoCancellato',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Cancellato', args.token)
        return response
    }
)
export const gotoBozza = createAsyncThunk(
    'item/gotoBozza',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Bozza', args.token)
        return response
    }
)
export const gotoLavorazione = createAsyncThunk(
    'item/gotoLavorazione',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Lavorazione', args.token)
        return response
    }
)
export const gotoInviato = createAsyncThunk(
    'item/gotoInviato',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Inviato', args.token)
        return response
    }
)
export const gotoAutorizzato = createAsyncThunk(
    'item/gotoAutorizzato',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Autorizzato', args.token)
        return response
    }
)
export const gotoRespinto = createAsyncThunk(
    'item/gotoRespinto',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Respinto', args.token)
        return response
    }
)
export const gotoAnnullato = createAsyncThunk(
    'item/gotoAnnullato',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'Annullato', args.token)
        return response
    }
)
export const gotoFineCure = createAsyncThunk(
    'item/gotoFineCure',
    async (args: { quoteManagementID: string, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: { id: args.quoteManagementID } }, 'FineCure', args.token)
        return response
    }
)

export default (props: { controller: string, itemData: any }) => {
    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONCHPower: boolean = isONHCMaster || isONHCAccount
    const isONCH: boolean = roles.isONHC;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF: boolean = roles.isHF;
    const isHFAccount: boolean = roles.isHFAccount;

    const quoteManagementState: string = props.itemData?.leap_QuoteManagementQuoteStateID;
    const quoteManagementType: string = props.itemData?.leap_PlanManagementID;

    const hasHealthServicesToApprove = !!props.itemData?.hasHealthServicesToApprove;

    const authorizedButtonText: string = quoteManagementState === "4" ? 'invia "Autorizzato"' : 'passa a "Inviato"'
    const authorizedSubText: string = quoteManagementState === "4" ? "Procedo con l'invio ? " : 'Procedo con passaggio allo stato "Inviato" del preventivo?'

    const quoteManagementCancellato_check: boolean = eval_cancellato(quoteManagementState);
    function eval_cancellato(quoteManagementState: string) {
        if (quoteManagementState === "" || quoteManagementState === "1")
            return true;

        return false;
    };
    const quoteManagementBozza_check: boolean = eval_bozza(quoteManagementState);
    function eval_bozza(quoteManagementState: string) {
        if (quoteManagementState === "5")
            return true;

        return false;
    };
    const quoteManagementLavorazione_check: boolean = eval_lavorazione(quoteManagementState);
    function eval_lavorazione(quoteManagementState: string) {
        if (quoteManagementState === "" || quoteManagementState === "1")
            return true;

        return false;
    }
    const quoteManagementInviato_check: boolean = eval_inviato(quoteManagementState);
    function eval_inviato(quoteManagementState: string) {
        if (quoteManagementType === "2" && (quoteManagementState === "2" || quoteManagementState === "3" || quoteManagementState === "4"))
            return true;

        return false;
    }
    const quoteManagementAutorizzato_check: boolean = eval_autorizzato(quoteManagementState);
    function eval_autorizzato(quoteManagementState: string) {
        if ((quoteManagementType === "1" && (quoteManagementState === "1" || quoteManagementState === "2")) || (quoteManagementType === "2" && quoteManagementState === "3"))
            return true;

        return false;
    }
    const quoteManagementRespinto_check: boolean = eval_respinto(quoteManagementState);
    function eval_respinto(quoteManagementState: string) {
        if (quoteManagementState === "2")
            return true;

        return false;
    }
    const quoteManagementAnnullato_check: boolean = eval_annullato(quoteManagementState);
    function eval_annullato(quoteManagementState: string) {
        if ((quoteManagementState === "1") || 
            (isONCHPower && (quoteManagementState === "2" || quoteManagementState === "3" || quoteManagementState === "4" || quoteManagementState === "7")))
            return true;

        return false;
    }
    const quoteManagementFineCure_check: boolean = eval_fineCure(quoteManagementState);
    function eval_fineCure(quoteManagementState: string) {
        if (!hasHealthServicesToApprove && quoteManagementState === "4")
            return true;

        return false;
    }

    const onClickCancellato = (e: any) => {
        e.preventDefault()
        toggleHideDialogCancellato()
    }

    const [hideDialogCancellato, { toggle: toggleHideDialogCancellato }] = useBoolean(true);
    const dialogContentPropsCancellato = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Cancellato" del preventivo?',
    };
    const onClickBozza = (e: any) => {
        e.preventDefault()
        toggleHideDialogBozza()
    }
    const [hideDialogBozza, { toggle: toggleHideDialogBozza }] = useBoolean(true);
    const dialogContentPropsBozza = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con la creazione di una copia del preventivo nello stato "In Bozza"?',
    };
    const onClickLavorazione = (e: any) => {
        e.preventDefault()
        toggleHideDialogLavorazione()
    }
    const [hideDialogLavorazione, { toggle: toggleHideDialogLavorazione }] = useBoolean(true);
    const dialogContentPropsLavorazione = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "In Lavorazione" del preventivo?',
    };
    const onClickInviato = (e: any) => {
        e.preventDefault()
        toggleHideDialogInviato()
    }
    const [hideDialogInviato, { toggle: toggleHideDialogInviato }] = useBoolean(true);
    const dialogContentPropsInviato = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: authorizedSubText,
    };
    const onClickAutorizzato = (e: any) => {
        e.preventDefault()
        toggleHideDialogAutorizzato()
    }
    const [hideDialogAutorizzato, { toggle: toggleHideDialogAutorizzato }] = useBoolean(true);
    const dialogContentPropsAutorizzato = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Autorizzato" del preventivo?',
    };
    const onClickRespinto = (e: any) => {
        e.preventDefault()
        toggleHideDialogRespinto()
    }
    const [hideDialogRespinto, { toggle: toggleHideDialogRespinto }] = useBoolean(true);
    const dialogContentPropsRespinto = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Respinto" del preventivo?',
    };
    const onClickAnnullato = (e: any) => {
        e.preventDefault()
        toggleHideDialogAnnullato()
    }
    const [hideDialogAnnullato, { toggle: toggleHideDialogAnnullato }] = useBoolean(true);
    const dialogContentPropsAnnullato = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Annullato" del preventivo?',
    };
    const onClickFineCure = (e: any) => {
        e.preventDefault()
        toggleHideDialogFineCure()
    }
    const [hideDialogFineCure, { toggle: toggleHideDialogFineCure }] = useBoolean(true);
    const dialogContentPropsFineCure = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Fine Cure" del preventivo?',
    };

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const _isLocked = useSelector(isLocked)

    const iForm = useSelector(itemData)
    const args = { quoteManagementID: iForm.find(i => i.key === props.controller)?.value?.data?.id, token: credential?.token }

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.controller))
        return response
    }
    const dispatchClone = (response: any) => {
        dispatch(setOperation("clone", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteCancellato = (e: any) => {
        toggleHideDialogCancellato()
        dispatch(lock())
        dispatch(gotoCancellato(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteBozza = (e: any) => {
        toggleHideDialogBozza()
        dispatch(lock())
        dispatch(gotoBozza(args)).then(dispatchClone)
    }
    const onExecuteLavorazione = (e: any) => {
        toggleHideDialogLavorazione()
        dispatch(lock())
        dispatch(gotoLavorazione(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteInviato = (e: any) => {
        toggleHideDialogInviato()
        dispatch(lock())
        dispatch(gotoInviato(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteAutorizzato = (e: any) => {
        toggleHideDialogAutorizzato()
        dispatch(lock())
        dispatch(gotoAutorizzato(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteRespinto = (e: any) => {
        toggleHideDialogRespinto()
        dispatch(lock())
        dispatch(gotoRespinto(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteAnnullato = (e: any) => {
        toggleHideDialogAnnullato()
        dispatch(lock())
        dispatch(gotoAnnullato(args)).then(dispatchMessage).then(dispatchReload)
    }
    const onExecuteFineCure = (e: any) => {
        toggleHideDialogFineCure()
        dispatch(lock())
        dispatch(gotoFineCure(args)).then(dispatchMessage).then(dispatchReload)
    }

    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    return (
        <>
            <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 18, textAlign: "right" }}>
                {isONCHPower && quoteManagementCancellato_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "Cancellato"' onClick={onClickCancellato} disabled={_isLocked} />
                    <Dialog hidden={hideDialogCancellato} onDismiss={toggleHideDialogCancellato} dialogContentProps={dialogContentPropsCancellato} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteCancellato} text='passa a "Cancellato"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogCancellato} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {quoteManagementBozza_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "In Bozza"' onClick={onClickBozza} disabled={_isLocked} />
                    <Dialog hidden={hideDialogBozza} onDismiss={toggleHideDialogBozza} dialogContentProps={dialogContentPropsBozza} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteBozza} text='passa a "In Bozza"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogBozza} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {quoteManagementLavorazione_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "In Lavorazione"' onClick={onClickLavorazione} disabled={_isLocked} />
                    <Dialog hidden={hideDialogLavorazione} onDismiss={toggleHideDialogLavorazione} dialogContentProps={dialogContentPropsLavorazione} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteLavorazione} text='passa a "In Lavorazione"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogLavorazione} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {isONCH && quoteManagementInviato_check && (<>
                    <PrimaryButton {...ButtonProps} text={authorizedButtonText} onClick={onClickInviato} disabled={_isLocked} />
                    <Dialog hidden={hideDialogInviato} onDismiss={toggleHideDialogInviato} dialogContentProps={dialogContentPropsInviato} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteInviato} text={authorizedButtonText} disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogInviato} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {isONCHPower && quoteManagementAutorizzato_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "Autorizzato"' onClick={onClickAutorizzato} disabled={_isLocked} />
                    <Dialog hidden={hideDialogAutorizzato} onDismiss={toggleHideDialogAutorizzato} dialogContentProps={dialogContentPropsAutorizzato} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteAutorizzato} text='passa a "Autorizzato"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogAutorizzato} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {isONCHPower && quoteManagementRespinto_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "Respinto"' onClick={onClickRespinto} disabled={_isLocked} />
                    <Dialog hidden={hideDialogRespinto} onDismiss={toggleHideDialogRespinto} dialogContentProps={dialogContentPropsRespinto} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteRespinto} text='passa a "Respinto"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogRespinto} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {(isHF || isONCHPower) && quoteManagementAnnullato_check && (<>
                    <PrimaryButton {...ButtonProps} text='annulla Piano di Cure' onClick={onClickAnnullato} disabled={_isLocked} />
                    <Dialog hidden={hideDialogAnnullato} onDismiss={toggleHideDialogAnnullato} dialogContentProps={dialogContentPropsAnnullato} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteAnnullato} text='passa a "Annullato"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogAnnullato} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
                {(isONCHPower || isHFOperator || isHFUser || isHFAccount) && quoteManagementFineCure_check && (<>
                    <PrimaryButton {...ButtonProps} text='passa a "Fine Cure"' onClick={onClickFineCure} disabled={_isLocked} />
                    <Dialog hidden={hideDialogFineCure} onDismiss={toggleHideDialogFineCure} dialogContentProps={dialogContentPropsFineCure} modalProps={modalProps} >
                        <DialogFooter>
                            <PrimaryButton onClick={onExecuteFineCure} text='passa a "Fine Cure"' disabled={_isLocked} />
                            <DefaultButton onClick={toggleHideDialogFineCure} text="Annulla" disabled={_isLocked} />
                        </DialogFooter>
                    </Dialog>
                </>)}
            </div>
        </>
    );
}
