import * as React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, IButtonProps, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { isLocked, lock, unlock } from '../operation/lockSlice';
import { setOperation } from '../operation/operationSlice';
import { itemData } from '../operation/thunks/itemThunk';
import { setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';
import { States } from '../../pages/HealthFacilityAffiliation';
import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import SearchBox from '../../components/controls/SearchBox';

import HealthFacilityAffiliationConfirm from './HealthFacilityAffiliationConfirm';

export const Reject = createAsyncThunk(
    'item/Reject',
    async (args: { HealthFacilityAffiliationID: string, Notes: string | undefined, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", { form: { id: args.HealthFacilityAffiliationID, Notes: args.Notes } }, 'Reject', args.token)
        return response
    }
)
export const AwaitingData = createAsyncThunk(
    'item/AwaitingData',
    async (args: { HealthFacilityAffiliationID: string, Notes: string | undefined, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", { form: { id: args.HealthFacilityAffiliationID, Notes: args.Notes } }, 'AwaitingData', args.token)
        return response
    }
)
export const CheckingDocumentation = createAsyncThunk(
    'item/CheckingDocumentation',
    async (args: { HealthFacilityAffiliationID: string, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", { form: { id: args.HealthFacilityAffiliationID } }, 'CheckingDocumentation', args.token)
        return response
    }
)
export const SendCredential = createAsyncThunk(
    'item/SendCredential',
    async (args: { HealthFacilityAffiliationID: string, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", { form: { id: args.HealthFacilityAffiliationID } }, 'SendCredential', args.token)
        return response
    }
)
export const Accept = createAsyncThunk(
    'item/Accept',
    async (args: { HealthFacilityAffiliationID: any, RateOption: any, RateTableID: any, NewRateName: any, NewRateCode: any, token: string | undefined }) => {
        const response = await client.post("HealthFacilityAffiliation", {
            form: {
                id: args.HealthFacilityAffiliationID,
                RateOption: args.RateOption,
                RateTableID: args.RateTableID,
                NewRateName: args.NewRateName,
                NewRateCode: args.NewRateCode
            }
        }, 'Accept', args.token)
        return response
    }
)

export default (props: {
    controller: string,
    currentState: States | undefined,
    acceptDisabled?: boolean,
    showRateDialogOnAccept?: boolean
}) => {
    const roles = useSecurityRoles()
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount
    const isAffiliation: boolean = roles.isHFAffiliation

    const currentState = props.currentState

    React.useEffect(() => {
        if (isONHCPower) {
            switch (currentState) {
                case States.Sent:
                    setButtonsToRender([_awaitingData, _reject])
                    break;

                case States.AwaitingData:
                    setButtonsToRender([_sendCredential, _reject])
                    break;

                case States.CheckingDocumentation:
                    setButtonsToRender([_sendCredential, _accept, _reject, _awaitingData])
                    break;

                case States.Accepted:
                case States.Rejected:
                    setButtonsToRender([])
                    break;

                default:
                    setButtonsToRender([])
                    break;
            }
        }
        else if (isAffiliation) {
            switch (currentState) {
                case States.AwaitingData:
                    setButtonsToRender([_checkingDocumentation])
                    break;

                case States.Sent:
                case States.CheckingDocumentation:
                case States.Accepted:
                case States.Rejected:
                    setButtonsToRender([])
                    break;
                default:
                    setButtonsToRender([])
                    break;
            }
        } else {
            setButtonsToRender([])
        }
    }, [currentState])

    const onClickReject = (e: any) => {
        e.preventDefault()
        toggleHideDialogReject()
    }

    const [hideDialogReject, { toggle: toggleHideDialogReject }] = useBoolean(true);
    const dialogContentPropsReject = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Respinta"?',
    };
    const onClickAwaitingData = (e: any) => {
        e.preventDefault()
        toggleHideDialogAwaitingData()
    }
    const [hideDialogAwaitingData, { toggle: toggleHideDialogAwaitingData }] = useBoolean(true);
    const dialogContentPropsAwaitingData = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Attesa Dati"?',
    };

    const onClickCheckingDocumentation = (e: any) => {
        e.preventDefault()
        toggleHideDialogCheckingDocumentation()
    }
    const [hideDialogCheckingDocumentation, { toggle: toggleHideDialogCheckingDocumentation }] = useBoolean(true);
    const dialogContentPropsCheckingDocumentation = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Verifica Documentazione"?',
    };
    const onClickAccept = (e: any) => {
        e.preventDefault()
        toggleHideDialogAccept()
    }
    const [hideDialogAccept, { toggle: toggleHideDialogAccept }] = useBoolean(true);
    const dialogContentPropsAccept = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con passaggio allo stato "Accettata"?',
    };

    const onClickSendCredential = (e: any) => {
        e.preventDefault()
        toggleHideDialogSendCredential()
    }
    const [hideDialogSendCredential, { toggle: toggleHideDialogSendCredential }] = useBoolean(true);
    const dialogContentPropsSendCredential = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con l\'invio delle credenziali?',
    };

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const _isLocked: boolean = false//useSelector(isLocked)

    const iForm = useSelector(itemData)
    const args = { HealthFacilityAffiliationID: iForm.find(i => i.key === props.controller)?.value?.data?.id, token: credential?.token }

    const hslistAccepted = iForm.find(i => i.key === props.controller)?.value?.lists?.HSList?.every((hs: any) => hs.state === 'Accepted' || hs.state === 'Neutral')
    const hslistUnchanged = iForm.find(i => i.key === props.controller)?.value?.lists?.HSList?.every((hs: any) => hs.state === 'Neutral')

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteReject = (e: any) => {
        toggleHideDialogReject()
        dispatch(lock())
        dispatch(Reject({ ...args, Notes: rejectionNotes }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }
    const onExecuteAwaitingData = (e: any) => {
        toggleHideDialogAwaitingData()
        dispatch(lock())
        dispatch(AwaitingData({ ...args, Notes: awaitingDataNotes }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }
    const onExecuteCheckingDocumentation = (e: any) => {
        toggleHideDialogCheckingDocumentation()
        dispatch(lock())
        dispatch(CheckingDocumentation(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }
    const onExecuteSendCredential = (e: any) => {
        toggleHideDialogSendCredential()
        dispatch(lock())
        dispatch(SendCredential(args))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }
    const onExecuteAccept = (e: any) => {
        toggleHideDialogAccept()
        dispatch(lock())
        dispatch(Accept({ ...args, RateOption: rateOption, RateTableID: rateTableID, NewRateName: newRateName, NewRateCode: newRateCode }))
            .then(dispatchMessage)
            .then(dispatchReload)
            .then(() => dispatch(unlock()))
    }

    const [buttonsToRender, setButtonsToRender] = React.useState<JSX.Element[]>([]);
    const _reject = <PrimaryButton {...ButtonProps} text='respingi' onClick={onClickReject} disabled={_isLocked} />
    const _awaitingData = <PrimaryButton {...ButtonProps} text='invio richiesta documentazione' onClick={onClickAwaitingData} disabled={_isLocked} />
    const _checkingDocumentation = <HealthFacilityAffiliationConfirm hasApprovedRateTable={hslistAccepted} hasUnchangedRateTable={hslistUnchanged}/>//<PrimaryButton {...ButtonProps} text='invia documentazione' onClick={onClickCheckingDocumentation} disabled={_isLocked} />
    const _accept = props.acceptDisabled ? <></> : <PrimaryButton {...ButtonProps} text='accetta' onClick={onClickAccept} disabled={_isLocked || props.acceptDisabled} />
    const _sendCredential = <PrimaryButton {...ButtonProps} text='invia credenziali' onClick={onClickSendCredential} disabled={_isLocked} />

    const [rejectionNotes, setRejectionNotes] = React.useState('');
    const [awaitingDataNotes, setAwaitingDataNotes] = React.useState('');
    const [rateOption, setRateOption] = React.useState<string | number | undefined>('');
    const [rateTableID, setRateTableID] = React.useState<string | number | undefined>('');
    const [newRateCode, setNewRateCode] = React.useState<string | undefined>('');
    const [newRateName, setNewRateName] = React.useState<string | undefined>('');

    React.useEffect(() => {
        setRejectionNotes('');
        setAwaitingDataNotes('');
        setRateOption('');
    }, [currentState])

    const executeAcceptDisabled = props.showRateDialogOnAccept && (_isLocked || !rateOption || (rateOption === '1' && !rateTableID) || (rateOption === '2' && !newRateName))

    const dialogs = (<>
        <Dialog hidden={hideDialogReject} onDismiss={toggleHideDialogReject} dialogContentProps={dialogContentPropsReject} modalProps={modalProps}>
            <Text
                className="ms-sm12"
                onChange={(_name, value, _req) => setRejectionNotes(value?.toString() ?? '')}
                label="note"
                name=""
                type="multiline"
                value={rejectionNotes}
                rows={7}
            />
            <DialogFooter>
                <PrimaryButton onClick={onExecuteReject} text='respingi' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogReject} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={hideDialogAwaitingData} onDismiss={toggleHideDialogAwaitingData} dialogContentProps={dialogContentPropsAwaitingData} modalProps={modalProps}>
            {currentState == States.CheckingDocumentation && <Text
                className="ms-sm12"
                onChange={(_name, value, _req) => setAwaitingDataNotes(value?.toString() ?? '')}
                label="note"
                name=""
                type="multiline"
                value={awaitingDataNotes}
                rows={7}
            />}
            <DialogFooter>
                <PrimaryButton onClick={onExecuteAwaitingData} text='invio richiesta documentazione' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogAwaitingData} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={hideDialogCheckingDocumentation} onDismiss={toggleHideDialogCheckingDocumentation} dialogContentProps={dialogContentPropsCheckingDocumentation} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteCheckingDocumentation} text='invia documentazione' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogCheckingDocumentation} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={hideDialogSendCredential} onDismiss={toggleHideDialogSendCredential} dialogContentProps={dialogContentPropsSendCredential} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteSendCredential} text='invia credenziali' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogSendCredential} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>

        <Dialog hidden={hideDialogAccept} onDismiss={toggleHideDialogAccept} dialogContentProps={dialogContentPropsAccept} modalProps={modalProps} maxWidth={800} >
            {props.showRateDialogOnAccept &&
                <> <Option
                    className="ms-sm12"
                    label="tariffario"
                    options={[{ key: '1', text: 'associa a esistente' }, { key: '2', text: 'crea nuovo' }, { key: '3', text: 'associa successivamente' }]}
                    type="horizontal"
                    required
                    onChange={(_name, value, _req) => { setRateOption(value) }}
                    selectedKey={rateOption} />
                    {rateOption && <br />}
                    {rateOption === '1' &&
                        <SearchBox
                            className="ms-sm12"
                            label="tariffario"
                            required
                            onChange={(_name, value, _req) => { setRateTableID(value) }}
                            controller={props.controller}
                            name="RateTableID"
                            lookup="rates"
                            value={rateTableID}
                        />}
                    {rateOption === '2' &&
                        <>
                            <Text
                                className="ms-sm12"
                                onChange={(_name, value, _req) => setNewRateCode(value?.toString() ?? '')}
                                label="codice"
                                name=""
                                value={newRateCode}
                                maxlength={50}
                            />
                            <Text
                                className="ms-sm12"
                                onChange={(_name, value, _req) => setNewRateName(value?.toString() ?? '')}
                                label="nome"
                                name=""
                                value={newRateName}
                                maxlength={50}
                                required
                            />
                        </>
                    }
                </>}
            <DialogFooter>
                <PrimaryButton onClick={onExecuteAccept} text='accetta' disabled={executeAcceptDisabled} />
                <DefaultButton onClick={toggleHideDialogAccept} text="Annulla" disabled={_isLocked} />
            </DialogFooter>
        </Dialog></>);

    return (<>
        <div className="ms-Grid-col ms-sm10" style={{ paddingTop: 18, textAlign: "right" }}>
            {buttonsToRender}
            {dialogs}
        </div>
    </>);
}