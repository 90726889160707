import * as React from 'react';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';

import { AppDispatch } from '../app/store';
import { client } from '../api/client';
import { lock, unlock, isLocked } from '../features/operation/lockSlice';
import { filterValidation } from '../features/operation/thunks/filterThunk';
import { setResponse } from '../features/response/responseSlice';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

import { FontFaces } from '../defs/fonts';

import { Image, Dialog, DialogFooter, DialogType, FontSizes, FontWeights, IDialogProps, PrimaryButton, Text as TextTitle, Checkbox } from '@fluentui/react/';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';

export const _Register = createAsyncThunk(
    'item/_Register',
    async (args: { firstname: string, lastname: string, email: string, confirmEmail: string, fiscalcode: string }) => {
        const response = await client.post("InsuredRegistration", { form: { firstname: args.firstname, lastname: args.lastname, fiscalcode: args.fiscalcode, confirmEmail: args.confirmEmail, email: args.email } }, 'Register', undefined)
        return response
    }
)

export default () => {
    const controller = "InsuredRegistration"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch()

    const validation = useSelector(filterValidation)

    const firstname = useLeapData("firstname", "nome", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const email = useLeapData("email", "email", page)
    const confirmEmail = useLeapData("confirmEmail", "conferma email", page)
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)

    const _isLocked = useSelector(isLocked)

    const onRegister = () => {
        if (!acceptedDeclaration) {
            return
        }

        setButtonDisabled(true)

        let mandatory = validation.find(v => v.key === controller)?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Per procedere alla registrazione è richiesta la compilazione di tutti i dati obbligatori.", 5, true))
            setButtonDisabled(false)
            return;
        }
        dispatch(lock())
        dispatch(_Register({ firstname: page?.filter?.values.firstname?.value, lastname: page?.filter?.values.lastname?.value, email: page?.filter?.values.email?.value, confirmEmail: page?.filter?.values.confirmEmail?.value, fiscalcode: page?.filter?.values.fiscalcode?.value }))
            .then((response) => {
                setMessage(response?.payload?.response?.message)
                setSuccess(response?.payload?.response?.level === 4)
                setButtonDisabled(false)
                toggleHideMessageBox();
            })
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [hideMessageBox, { toggle: toggleHideMessageBox, setFalse: showMessageBox }] = useBoolean(true)

    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    const dialogProps: IDialogProps = {
        hidden: hideMessageBox,
        onDismiss: toggleHideMessageBox,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: success ? "Operazione completata" : "Operazione annullata",
            subText: message,
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-" + (success ? "success" : "error")
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }

    const [acceptedDeclaration, setAcceptedDeclaration] = React.useState(false);
    const onChangeDeclaration = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setAcceptedDeclaration(!!checked);
        }, []);
    const declarationText = "Cliccando sul pulsante \"REGISTRATI\" confermi di essere il titolare dei dati inseriti e di voler procedere con la registrazione per l'accesso ai servizi online di ONHC - Odontonetwork. \n\nRiceverai una mail all'indirizzo indicato, con tutte le istruzioni per completare la registrazione."

    const buttonRef: any = useRef(null)
    const handleKeyboard = (event: any) => {
        if (event.key === "Enter") {
            buttonRef.current.click()
        }
    }
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);
        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [])

    const gotoLogin = <><TextTitle style={{ fontFamily: FontFaces.montserrat, fontWeight: 500 }}>Sei gia registrato? &nbsp;</TextTitle ><TextTitle nowrap onClick={() => { document.location.href = '/login/' }} style={{ color: "#4B858E", fontFamily: FontFaces.montserrat, fontWeight: 500, cursor: "pointer" }}>Accedi</TextTitle></>

    const mainContainerStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: 32
    }
    const logoExternalContainerStyle: React.CSSProperties = {
        backgroundColor: process.env.REACT_APP_STYLE_BODY_BACKGROUNDCOLOR,
        borderRadius: "50%",
        padding: 16,
        zIndex: 1
    }
    const logoInternalContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.white,
        borderStyle: "solid",
        borderWidth: 0,
        borderRadius: "50%",
        zIndex: 1
    }
    const formContainerStyle: React.CSSProperties = {
        backgroundColor: Theme.fluent.palette.white,
        borderColor: Theme.fluent.palette.themeLight,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 8,
        padding: "128px 24px 8px",
        position: "relative",
        top: -96,
    }

    const buttonContainerStyle: React.CSSProperties = {
        marginTop: 20,
        textAlign: "center"
    }

    const buttonStyle: React.CSSProperties = {
        width: 192
    }

    const textContainerStyle: React.CSSProperties = {
        textAlign: "center"
    }

    return <>
        <Dialog {...dialogProps} onDismissed={() => success ? document.location.href = '/login/' : () => { }}>
            <DialogFooter >
                <PrimaryButton onClick={toggleHideMessageBox} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <div style={mainContainerStyle}>
            <div style={logoExternalContainerStyle}>
                <div className="splashLogo ms-depth-16" style={logoInternalContainerStyle}>
                    <Image src="/assets/media/images/icon.png"
                        draggable={false}
                        style={{
                            margin: 17,
                            MozUserSelect: "none",
                            WebkitUserSelect: "none",
                            msUserSelect: "none"
                        }} />
                </div>
            </div>
            <div className="splashForm ms-depth-16" style={formContainerStyle} >
                <Text {...firstname} className="ms-sm12" required maxlength={50} />
                <Text {...lastname} className="ms-sm12" required maxlength={50} />
                <Text {...fiscalcode} className="ms-sm12" required maxlength={16} />
                <Text {...email} className="ms-sm12" required maxlength={50} />
                <Text {...confirmEmail} className="ms-sm12" required maxlength={50} />
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 24, padding: "0 22px 0 8px" }}>
                    <textarea readOnly value={declarationText} rows={7} style={{ borderRadius: 2, color: Theme.fluent.palette.neutralPrimary, fontFamily: FontFaces.montserrat, fontWeight: 500, textAlign: "justify", margin: 0, padding: "4px 6px", width: "100%" }} />
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 16 }}>
                    <Checkbox label="conferma i tuoi dati" checked={acceptedDeclaration} onChange={onChangeDeclaration} styles={{ root: { justifyContent: "center" }, label: { fontFamily: FontFaces.montserrat, fontWeight: 500 } }} />
                </div>
                <div className="ms-Grid-col ms-sm12" style={buttonContainerStyle}>
                    <PrimaryButton style={buttonStyle} onClick={onRegister} text="registrati" elementRef={buttonRef} disabled={buttonDisabled || !acceptedDeclaration || _isLocked} />
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 40 }}>
                    {gotoLogin}
                </div>
                <div className="ms-Grid-col ms-sm12" style={{ ...textContainerStyle, marginTop: 32 }}>
                    <span style={{ color: Theme.fluent.palette.neutralSecondaryAlt, fontFamily: FontFaces.montserrat, fontSize: "0.9em", fontWeight: 500, cursor: "default" }}>&copy; ONHC Servizi Sanitari e Welfare srl</span>
                </div>
            </div>
        </div>
    </>
}