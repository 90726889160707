import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} >
                <DataColumn kid='column1' name='Codice Tariffario' fieldName='rateTableCode' minWidth={100} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Nome Tariffario' fieldName='rateTableName' minWidth={200} maxWidth={300} align='left' />
                <DataColumn kid='column3' name='Tipo Tariffario' fieldName='rateTableTypeID' minWidth={100} maxWidth={120} align='left' />
                <DataColumn kid='column4' name='Tariffa' fieldName='rate' minWidth={80} maxWidth={100} align='right' />
                <DataColumn kid='column5' name='Elementi Dentali' fieldName='dentalElement' minWidth={200} align='left' />
                <DataColumn kid='column6' name='Data fine validita' fieldName='endDate' minWidth={100} maxWidth={120} align='center' />
            </DataList>
        </>
    );
}
