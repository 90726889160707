import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Text from '../../components/controls/Text';
import ComboBox from '../../components/controls/ComboBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';
import MultiBox from '../../components/controls/MultiBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const name = useLeapData("name", "nome permesso", page)
    const unit = useLeapData("unit", "nome funzione menu", page, "units")
    const privilegesID = useLeapData("privilegesID", "nome privilegio", page, "privileges")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <ComboBox {...unit} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <MultiBox {...privilegesID} className="ms-sm12" titleType="text" required />
                    </Row>
                    <Row>
                        <Text {...name} className="ms-sm12" required maxlength={50} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
