import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../app/rootReducer'

import { ModalityType } from '../../../defs/modalities'
import { OperationType } from '../../../defs/operations'

import { initialStateThunks, iPayloadThunks, iPayloadResponseThunks } from '../../../defs/thunks'

import { addBuilder, addBuilderLookup } from './filterThunkHelpers'

import { client } from '../../../api/client'

export const filterLoad = createAsyncThunk(
    'filter/load',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, body: any, token?: string }) => {
        let body = { ...args.body }
        const response = await client.post(args.controller, body, 'Filter', args.token)
        return response
    }
)
export const filterReset = createAsyncThunk(
    'filter/reset',
    async (args: { controller: string, modality: ModalityType, operation: OperationType, token?: string }) => {
        const response = await client.get(args.controller, null, 'Reset', args.token)
        return response
    }
)

export const filterLookup = createAsyncThunk(
    'filter/lookup',
    async (args: { controller: string, target: string, lookup: string, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Lookup/' + args.lookup, args.token)
        return response
    }
)
export const filterSearch = createAsyncThunk(
    'filter/search',
    async (args: { controller: string, lookup: string, body?: any, token?: string }) => {
        const response = await client.post(args.controller, args.body, 'Search/' + args.lookup, args.token)
        return response
    }
)

const FilterSlice = createSlice({
    name: 'filter',
    initialState: { ...initialStateThunks },
    reducers: {
        setFormFilter: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let data = action.payload.data
                let form = state.form

                let formController = form.find(f => f.key === controller)
                if (formController) {
                    formController.value = { ...formController.value, [data.name]: data.value }
                } else {
                    form.push({ key: controller, value: { [data.name]: data.value } })
                }

                state.form = form
            },
            prepare(controller: string, filter: { name: any, value: any }) {
                return { payload: { controller: controller, data: filter } }
            }
        },
        clearFormFilter: {
            reducer(state, action: PayloadAction<string>) {
                let controller = action.payload
                let form = state.form
                let formController = form.filter(f => f.key !== controller)

                state.form = formController
            },
            prepare(controller: string) {
                return { payload: controller }
            }
        },
        clearDataFilter: {
            reducer(state, action: PayloadAction<string>) {
                let controller = action.payload
                let data = state.data
                let dataController = data.filter(d => d.key !== controller)

                state.data = dataController
            },
            prepare(controller: string) {
                return { payload: controller }
            }
        },
        setRequiredFilter: {
            reducer(state, action: PayloadAction<iPayloadThunks>) {
                let controller = action.payload.controller
                let filter = action.payload.data

                let validation = state.validation

                let validationController = validation.find(f => f.key === controller)
                if (validationController) {
                    validationController.value = { ...validationController.value, mandatory: filter.value }
                } else {
                    validation.push({ key: controller, value: { mandatory: filter.value, validity: undefined } })
                }

                state.validation = validation
            },
            prepare(controller: string, item: { name: "mandatory", value: boolean }) {
                return { payload: { controller: controller, data: item } }
            }
        },
        setResponseFilter: {
            reducer(state, action: PayloadAction<iPayloadResponseThunks>) {
                let response = action.payload.response
                state.response = response
            },
            prepare(controller: string, response: { message: string, level: number | undefined }) {
                return { payload: { controller: controller, response: response } }
            }
        },
    },
    extraReducers: builder => {
        addBuilder(builder, filterReset)
        addBuilder(builder, filterLoad)
        addBuilderLookup(builder, filterLookup)
    }
})

export const { setFormFilter, clearFormFilter, clearDataFilter, setRequiredFilter,   setResponseFilter } = FilterSlice.actions

export const filterData = (state: RootState) => state.filter.data
export const filterForm = (state: RootState) => state.filter.form
export const filterValidation = (state: RootState) => state.filter.validation
export const filterResponse = (state: RootState) => state.filter.response

export default FilterSlice.reducer