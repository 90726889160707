import * as React from 'react';

import { createTheme, Customizations, ITheme } from '@fluentui/react';

import { ILeapTheme } from '../../defs/themes';

export default class Theme extends React.Component {
    static fluent = createTheme({
        defaultFontStyle: {
            fontFamily: 'Open Sans',
        },
        palette: {
            themePrimary: "#4B858E",
            themeLighterAlt: "#f6fafb",
            themeLighter: "#dbebed",
            themeLight: "#bed9dd",
            themeTertiary: "#87b5bc",
            themeSecondary: "#5c949c",
            themeDarkAlt: "#447881",
            themeDark: "#3a666d",
            themeDarker: "#2a4b50",
            neutralLighterAlt: "#faf9f8",
            neutralLighter: "#f3f2f1",
            neutralLight: "#edebe9",
            neutralQuaternaryAlt: "#e1dfdd",
            neutralQuaternary: "#d0d0d0",
            neutralTertiaryAlt: "#c8c6c4",
            neutralTertiary: "#a19f9d",
            neutralSecondary: "#605e5c",
            neutralPrimaryAlt: "#3b3a39",
            neutralPrimary: "#323130",
            neutralDark: "#201f1e",
            black: "#000000",
            white: "#ffffff"
        },
    });

    static leap: ILeapTheme = {
        header: {
            account: {
                backgroundColor: Theme.fluent.palette.white,
                color: Theme.fluent.palette.black,
                initialColor: Theme.fluent.palette.themePrimary,
                minWidth: 200,
            },
            logo: {
                backgroundColor: Theme.fluent.palette.white,
                height: 50,
                width: 'auto',
            },
            separator: {
                color: Theme.fluent.palette.themePrimary,
            },
            title: {
                backgroundColor: Theme.fluent.palette.white,
                color: Theme.fluent.palette.themePrimary,
                padding: '0 0 0 10px'
            },
            menu: {
                backgroundImage: "linear-gradient(" + Theme.fluent.palette.themePrimary + " 96%, " + Theme.fluent.palette.themeLight + ")",
                content: {
                    backgroundImage: "linear-gradient(" + Theme.fluent.palette.white + " 96%, " + Theme.fluent.palette.themeLight + ")",
                }
            },
            //TODO: LOCK
            /*
            menu:
            {
                backgroundImage: "linear-gradient(" + Theme.fluent.palette.themePrimary + " 100%, " + Theme.fluent.palette.themeLight + ")",
                content: {
                    backgroundImage: "linear-gradient(" + Theme.fluent.palette.white + " 100%, " + Theme.fluent.palette.themeLight + ")",
                }
            },
             */
            backgroundColor: Theme.fluent.palette.themePrimary,
            className: 'headerWidth',
            color: Theme.fluent.palette.black,
        },
        main: {
            content: {
                section: {
                    header: {
                        unPadded: "0 8px",
                        bottomPadded: "0 8px 8px",
                        middlePadded: "4px 8px",
                        topPadded: "12px 8px 0",
                    },
                    body: {
                        margin: "0",
                        padding: "0 16px",
                    },

                    backgroundColor: Theme.fluent.palette.white,
                    margin: "8px 0 4px",
                    padding: "0 16px",
                },

                backgroundColor: Theme.fluent.palette.white,
            },
            menu: {
                backgroundColor: Theme.fluent.palette.white,
            },
            title: {
                backgroundColor: Theme.fluent.palette.white,
                color: Theme.fluent.palette.neutralPrimary,
            },
            className: 'mainWidth',
        },
        footer: {
            backgroundColor: process.env.REACT_APP_STYLE_FOOTER_BACKGROUNDCOLOR!, //TODO: BACKGROUND
            color: Theme.fluent.palette.white,
        },
        customization: {
            error: {
                backgroundColor: '#ffcccc',
                borderColor: '#ea0909',
                color: '#ea0909',
            },
            pagerToolbar: {
                label: {
                    color: Theme.fluent.palette.neutralPrimary,
                },
            },
            textField: {
                fieldgroup: {
                    borderColor: Theme.fluent.palette.neutralTertiary,
                },
            },
            separator: {
                theme: fluentSeparatorTheme(Theme.fluent)
            }
        }
    }
}

function fluentSeparatorTheme(originalTheme: ITheme): ITheme {

    var customizedTheme = createTheme(originalTheme);
    customizedTheme.palette.neutralLighter = Theme.fluent.palette.themeLight;

    return customizedTheme;
};

export const loadTheme = () => {
    Customizations.applySettings({ theme: Theme.fluent });
};