import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { States } from '../../pages/HealthFacilityAffiliation';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { security } from '../security/thunks/securityThunk';
import { itemDownload } from '../operation/thunks/itemThunk';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    currentState: States | undefined
}

export default (props: Props) => {
    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const dispatch = useDispatch()

    const roles = useSecurityRoles();
    const isONHCPower = roles.isONHCMaster || roles.isONHCAccount
    const isHFAffiliation = roles.isHFAffiliation

    const readOnly = (isONHCPower && props.currentState === States.AwaitingData) ||
        (isHFAffiliation && props.state === States.CheckingDocumentation)

    const onDownloading = (e: any) => {

        console.log(e)

        const args = {
            controller: props.controller ?? "",
            id: e["id"] ?? undefined,
            fileID: parseInt(e["fileID"]),
            //page?.item?.state?.data?.fileID,
            fileName: e["fileNameExt"],
            // page?.item?.state?.data?.fileNameExt,
            //fileName: page?.item?.state?.data?.fileName, 
            token: token
        }

        dispatch(itemDownload(args))
    }

    return (
        <>
            <DataList {...props} items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} readOnly={readOnly}>
                <DataColumn kid='columnG' name='' fieldName='edit' minWidth={10} maxWidth={10} type='download' onClick={onDownloading} />
                <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Nome File' fieldName='fileName' minWidth={250} align='left' />
                {false && props.currentState !== States.Sent && <DataColumn kid='column19' type="icon" name='Firmato' fieldName='hasSigned' minWidth={80} maxWidth={100} align='center' />}
                <DataColumn kid='column2' name='Data Inserimento' fieldName='insertionDate' minWidth={120} maxWidth={120} align='center' />
                {false && <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />}
            </DataList>
        </>
    );
}
