import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import Separator from '../../components/controls/grid/Separator';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const leap_DiscountTimesNumberTypeID = useLeapData("leap_DiscountTimesNumberTypeID", "tipo", page, "discountTimesNumberTypes")
    const timesNumber = useLeapData("timesNumber", "numero di volte", page)
    const fixedAmount = useLeapData("fixedAmount", "importo fisso", page)
    const odQuote = useLeapData("odQuote", "quota od", page)
    const insuredQuote = useLeapData("insuredQuote", "quota assistito", page)

    const categoryID = props.state.item.values.leap_DiscountCategoryID.value
    const timesNumberTypeID = page.item?.values?.leap_DiscountTimesNumberTypeID?.value
    const quoteTypologyID = props.state.item?.values?.leap_DiscountQuoteTypologyID?.value

    const timesNumber_show = timesNumberTypeID === "2"

    const fixedAmount_show = categoryID === "1" || categoryID === "5" || categoryID === "6"

    const odQuote_show = categoryID === "1" || categoryID === "2"
    const insuredQuote_show = categoryID === "1"

    const odQuote_required = categoryID === "2"

    const quoteMax = quoteTypologyID === 2 ? 100 : undefined

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Option {...leap_DiscountTimesNumberTypeID} className="ms-sm4" type="horizontal" required />
                        <Separator className="ms-sm1"></Separator>
                        <Text {...timesNumber} className="ms-sm3" type="number" hidden={!timesNumber_show} required={timesNumber_show} precision={{ integer: 9, decimal: 0, min: 0 }} />
                    </Row>
                    <Row >
                        <Text {...fixedAmount} className="ms-sm3" type="number" hidden={!fixedAmount_show} required={fixedAmount_show} precision={{ integer: 9, decimal: 2, min: 0 }} />
                        <Separator className="ms-sm1"></Separator>
                        <Text {...odQuote} className="ms-sm3" type="number" hidden={!odQuote_show} required={odQuote_required} precision={{ integer: 9, decimal: 2, min: 0, max: quoteMax }} />
                        <Separator className="ms-sm1"></Separator>
                        <Text {...insuredQuote} className="ms-sm3" type="number" hidden={!insuredQuote_show} precision={{ integer: 9, decimal: 2, min: 0, max: quoteMax }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
