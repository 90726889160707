import * as React from 'react';

import { Separator, ISeparatorStyles } from '@fluentui/react/';

import Theme from '../../themes/Theme';

const separatorStyles: ISeparatorStyles = {
    content: {
        backgroundColor: "transparent",
        margin: 0,
        padding: 0,
        width: "100%"
    },
    root: {
        margin: 0,
        padding: 0,
        width: "100%"
    }
};

export default (props: { children: React.ReactNode }) => (
    <>
        <Separator theme={Theme.leap.customization.separator.theme} alignContent="start" styles={separatorStyles}>
            {props.children}
        </Separator>
    </>
);