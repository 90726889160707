import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import SearchBox from '../components/controls/SearchBox';
import DiscountList from '../features/coverageCounterConsult/DiscountList';
import PlafondsList from '../features/coverageCounterConsult/PlafondList';
import ResultList from '../features/coverageCounterConsult/ResultList';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';

export default () => {

    const controller = "CoverageCounterConsult"
    const page = useLeapState(controller)

    const insured = useLeapData("insured", "assistito", page, "insureds")

    return <>
        <Content title="Consultazione Contatori" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...insured} className="ms-sm8" required />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <ResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <SearchBox {...insured} className="ms-sm8" disabled />
                        </Row>
                    </Section>
                    <Section title="Sconti">
                        <Row>
                            <DiscountList items={page.item.state?.lists?.CoverageDiscountDetails} />
                        </Row>
                    </Section>
                    <Section title="Plafond">
                        <Row>
                            <PlafondsList items={page.item.state?.lists?.CoveragePlafonds} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}
