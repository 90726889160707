import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} >
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Nome' fieldName='name' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column2' name='Funzioni' fieldName='unit_name' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column3' name='Contesto' fieldName='ctx_unit' minWidth={100} maxWidth={250} align='left' />
                <DataColumn kid='column4' name='Privilegio' fieldName='privilege_name' minWidth={100} align='left' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
