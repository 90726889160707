import * as React from 'react';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, FontSizes, FontWeights, getTheme, ICommandBarItemProps, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton } from '@fluentui/react/';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Permission } from '../../../defs/permission';
import { ToolbarType } from '../../../defs/toolbars';
import { currentModality } from '../../../features/modality/modalitySlice';
import { setOperation } from '../../../features/operation/operationSlice';
import { itemData, itemForm, itemValidation } from '../../../features/operation/thunks/itemThunk';
import { resetResponse, setResponse } from '../../../features/response/responseSlice';
import { security, units } from '../../../features/security/thunks/securityThunk';
import { useDatagridOperation } from '../../../hooks/useDatagridOperation';
import Theme from '../../themes/Theme';
import { isLocked, lock, unlock } from '../../../features/operation/lockSlice';
import { AppDispatch } from '../../../app/store';

type Props = {
    type?: ToolbarType,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any,
    parentDatagridController?: string,
    parentDatagridId?: string | number
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch()

    const modality = useSelector(currentModality)
    const validation = useSelector(itemValidation)

    const unitsContext = useSelector(units)

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const iForm = useSelector(itemForm)
    const iData = useSelector(itemData)
    const datagridOperation = useDatagridOperation(props.controller, token)
    const parentDatagridOperation = !!props.parentDatagridController ? useDatagridOperation(props.parentDatagridController, token) : undefined

    const _isLocked = useSelector(isLocked)

    const dialogStyles = { main: { maxWidth: 450 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla eliminazione dell\'elemento?',
    };

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const titleId = useId('title');

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: props.modalwidth ?? '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                //borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    let body = {};

    const hasPermission = (permission: Permission): boolean => {
        var permissions = unitsContext?.units.permissions
        if (!permissions) return false

        var controllerPermissions = permissions[props.controller.toLowerCase()]
        if (!controllerPermissions) return false;

        if (!controllerPermissions.find((cp: Permission) => cp == permission)) return false;

        return true
    }

    const parentController = props?.state?.controller

    const onParentPageReload = () => {
        dispatch(setOperation("reload", parentController))
    }

    const onParentDatagridReload = () => {
        parentDatagridOperation?.load(props.parentDatagridId)
            .then(() => dispatch(unlock()))
    }

    const onGridReload = !!parentDatagridOperation ? onParentDatagridReload : onParentPageReload

    if (props.type === "grid") {
        const parentItemForm = iForm.find(i => i.key === parentController)?.value
        const parentItemData = iData.find(d => d.key === parentController)?.value

        let parentItemPage: any = {}
        if (parentItemData?.metadata?.page) {
            Object.assign(parentItemPage, parentItemData?.metadata?.page)

            if (parentItemPage.index > 0) {
                parentItemPage.index = parentItemPage.index - 1
            } else {
                parentItemPage.index = 0
            }
        }
        const gridItemForm = iForm.find(i => i.key === props.controller)?.value

        body = {
            form: gridItemForm,
            parent: {
                form: parentItemForm,
                page: parentItemPage
            }
        }
    }
    const newGridItem: ICommandBarItemProps = {
        key: 'newGridItem',
        text: 'nuovo',
        iconProps: { iconName: 'CalculatorAddition' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any, data: any) => {
            e.preventDefault()
            datagridOperation.new(body)
                .then(() => showModal())
        },
        onRender: hasPermission(Permission.Create) ? undefined : () => { return <></> }
    };
    const newItem: ICommandBarItemProps = {
        key: 'newItem',
        text: 'nuovo',
        iconProps: { iconName: 'CalculatorAddition' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("new", props.controller))
        },
        onRender: hasPermission(Permission.Create) ? undefined : () => { return <></> }
    };
    const copyItem: ICommandBarItemProps = {
        key: 'copyItem',
        text: 'copia',
        iconProps: { iconName: 'Copy' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("copy", props.controller))
        },
        onRender: hasPermission(Permission.Copy) ? undefined : () => { return <></> }
    };
    const cloneItem: ICommandBarItemProps = {
        key: 'cloneItem',
        text: 'clona',
        iconProps: { iconName: 'SaveAll' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault();
            dispatch(lock())
            dispatch(setOperation("clone", props.controller));

        },
        onRender: hasPermission(Permission.Clone) ? undefined : () => { return <></> }
    };
    const insertItem: ICommandBarItemProps = {
        key: 'insertItem',
        text: 'inserisci',
        iconProps: { iconName: 'Save' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {

            e.preventDefault()

            if (modality === "item" || modality === "new") {
                let mandatory = validation.find(v => v.key === props.controller)?.value.mandatory

                if (mandatory === false) {
                    dispatch(setResponse("Impossibile procedere all'inserimento, compilare tutti i campi obbligatori.", 5, true))
                    return;
                }
            }

            dispatch(lock())
            dispatch(setOperation("create", props.controller))
        },
        onRender: hasPermission(Permission.Create) ? undefined : () => { return <></> }
    };


    const saveItem: ICommandBarItemProps = {
        key: 'saveItem',
        text: 'salva',
        iconProps: { iconName: 'Save' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()

            if (modality === "item") {
                let mandatory = validation.find(v => v.key === props.controller)?.value.mandatory

                if (mandatory === false) {
                    dispatch(setResponse("Impossibile procedere al salvataggio, compilare tutti i campi obbligatori.", 5, true))
                    return;
                }
            }

            dispatch(lock())
            dispatch(setOperation("save", props.controller))
        },
        onRender: hasPermission(Permission.Update) ? undefined : () => { return <></> }
    };

    const deleteItem: ICommandBarItemProps = {
        key: 'deleteItem',
        text: 'elimina',
        iconProps: { iconName: 'Cancel' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            toggleHideDialog()
            //dispatch(setOperation("delete", props.controller))
        },
        onRender: hasPermission(Permission.Delete) ? undefined : () => { return <></> }
    };
    const reloadItem: ICommandBarItemProps = {
        key: 'reloadItem',
        text: 'ricarica',
        iconProps: { iconName: 'Refresh' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("reload", props.controller))
        },
    };
    const cancelItem: ICommandBarItemProps = {
        key: 'cancelItem',
        text: 'annulla',
        iconProps: { iconName: 'Refresh' },
        disabled: _isLocked,
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            dispatch(lock())
            dispatch(setOperation("filter", props.controller))
        },
    };

    switch (props.type) {
        case "new":
            return (
                <CommandBar className="leap-commandbar" items={[insertItem, cancelItem]} farItems={[]} />
            );
        case "item":
            let modality = useSelector(currentModality)
            if (modality === "new") {
                return (
                    <CommandBar className="leap-commandbar" items={[insertItem, cancelItem]} farItems={[]} />
                );
            }
            else {
                return (
                    <>
                        <CommandBar className="leap-commandbar" items={[newItem, copyItem, cloneItem, saveItem, deleteItem, reloadItem]} farItems={[]} />
                        <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} isBlocking={_isLocked}>
                            <DialogFooter>
                                <PrimaryButton onClick={() => { toggleHideDialog(); dispatch(lock()); dispatch(setOperation("delete", props.controller)); }} text="Elimina" disabled={_isLocked} />
                                <DefaultButton onClick={toggleHideDialog} text="Annulla" disabled={_isLocked} />
                            </DialogFooter>
                        </Dialog>
                    </>
                );
            }
        case "result":
            return (
                <CommandBar className="leap-commandbar" items={[]} farItems={[]} />
            );
        case "filter":
            return (
                <CommandBar className="leap-commandbar" items={[]} farItems={[]} />
            );
        case "grid":
            const onCreate = () => {
                let mandatory = validation.find(v => v.key === props.controller)?.value.mandatory

                if (mandatory === false) {
                    dispatch(setResponse("Impossibile procedere al salvataggio, compilare tutti i campi obbligatori.", 5, true))
                    return;
                }

                dispatch(lock())
                datagridOperation.create(body)
                    .then((response) => {
                        if (response.payload.response.level === 4) {
                            hideModal()
                            return true
                        }
                        else {
                            dispatch(unlock())
                            return false
                        }
                    })
                    .then((proceed) => {
                        if (proceed) {
                            onGridReload()
                        }
                    })                    
            }
            const onCancel = (e: any) => {
                e.preventDefault()
                dispatch(resetResponse(false))

                hideModal()
            }
            return (
                <>
                    <CommandBar className="leap-commandbar" items={[newGridItem]} farItems={[]} />
                    <Modal
                        titleAriaId={titleId}
                        isOpen={isModalOpen}
                        isBlocking={_isLocked}
                        containerClassName={contentStyles.container}
                        onDismiss={(ev) => { if (_isLocked) { ev?.preventDefault() } else { hideModal() } }}
                        ignoreExternalFocusing={_isLocked}>

                        <div className={contentStyles.header}>
                            <span id={titleId}>{props.modaltitle}</span>
                            <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={hideModal} disabled={_isLocked} />
                        </div>
                        <div className={contentStyles.body}>
                            {props.modal}
                            <DialogFooter>
                                <PrimaryButton onClick={onCreate} text="Inserisci" disabled={_isLocked} />
                                <DefaultButton onClick={onCancel} text="Annulla" disabled={_isLocked} />
                            </DialogFooter>
                        </div>

                    </Modal>
                </>
            );
        default:
            return (
                <></>
            );
    }
};