import Theme from '../components/themes/Theme';
import { FontFaces } from './fonts';

import { ITextFieldProps, FontSizes } from '@fluentui/react/';

export const TextFieldProps: ITextFieldProps = {
    styles: {
        root: {
            paddingTop: 2,
            selectors: {
                'i': {
                    bottom: 0,
                    top: 4,
                },
            }
        },
        fieldGroup: {
            borderWidth: 1,
        },
        icon: {
            lineHeight: '0.9em',
        },
        subComponentStyles: {
            label: {
                root: {
                    fontFamily: FontFaces.montserrat,
                    fontSize: FontSizes.medium,
                    fontWeight: 500,
                    paddingBottom: 2,
                    paddingLeft: 4,
                },
            },
        }
    }
}

export const ErrorTextFieldProps: ITextFieldProps = {
    styles: {
        root: {
            paddingTop: 2,
            selectors: {
                'i': {
                    bottom: 0,
                    top: 4,
                },
            }
        },
        fieldGroup: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            borderColor: Theme.leap.customization.error.borderColor,
            borderWidth: 1,
        },
        icon: {
            lineHeight: '0.9em',
        },
        subComponentStyles: {
            label: {
                root: {
                    fontFamily: FontFaces.montserrat,
                    fontSize: FontSizes.medium,
                    fontWeight: 500,
                    paddingBottom: 2,
                    paddingLeft: 4,
                },
            },
        }
    }
}

export type TimeFieldProps = {
    name?: string,
    className: string,
    label: string,
    value?: any,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    readOnly?: boolean;
}
export type DatePickerProps = {
    name?: string,
    className: string,
    label: string,
    value?: Date,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    onChange?: (event: any) => void,
    readOnly?: boolean;
    minDate?: string,
    maxDate?: string,
    defaultDate?: string,
    time?: TimeFieldProps,
    hidden?: boolean
}

export type DateRangeProps = {
    name?: string,
    className: string,
    label: string,
    readOnly?: boolean,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    value?: any,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void;
}
