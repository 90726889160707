import * as React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie"
import { useLocation } from 'react-router';

import { AppDispatch } from '../../app/store';
import { security, authorized, authenticated, getUnits, loginSecurity, hasExpiredPassword } from './thunks/securityThunk';

export default () => {
    const location = useLocation()
    const dispatch: AppDispatch = useDispatch()
    const securityContext = useSelector(security)

    const [init, setInit] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies([securityContext?.credential])

    const isAuthenticated = useSelector(authenticated)
    const isAuthorized = useSelector(authorized)
    const expiredPassword = useSelector(hasExpiredPassword)

    const tokenCookieName = "session"

    const pathsToIgnore = [
        '/login/',
        '/forgotPassword/',
        '/forgotUsername/',
        '/onhc-private-insuredRegistration/',
        '/healthFacilityAffiliationPublic/',
        '/emailValidation/',
        '/feedbackPublic/',
        '/confirmReservation/'
    ];

    useEffect(() => {
        if (init) return
        setInit(true)

        if (pathsToIgnore.some(p => p === location.pathname)) {
            return
        } else if (location.pathname.startsWith("/planPurchasePublic/")) {
            return;
        } else if (!cookies.session?.username) {
            document.location.href = '/login/'
        } else {
            dispatch(loginSecurity({ username: cookies.session?.username, password: undefined, token: cookies.session?.token, otp: undefined }))
                .then((response) => {
                    var result = response?.payload?.response
                    if (result?.level != 4) {
                        removeCookie(tokenCookieName, { path: '/' })
                        document.location.href = '/login/'
                    }

                    var data = response.payload.data
                    var token = data.token

                    if (!token) {
                        removeCookie(tokenCookieName, { path: '/' })
                        document.location.href = '/login/'
                    }
                })
        }
    }, [init, dispatch, cookies.session, isAuthenticated, isAuthorized, expiredPassword, removeCookie])

    if (isAuthorized) { //autorizzato: token presente e ruolo selezionato
        const date = new Date();
        // Set it expire in 7 days
        date.setTime(date.getTime() + (60 * 60 * 1000));

        setCookie(tokenCookieName, securityContext?.credential, { path: '/', expires: date, secure: true })

        if (expiredPassword) {
            if (location.pathname != '/ChangePassword/') {
                document.location.href = '/ChangePassword/'
                //
            }
        } else {
            dispatch(getUnits({ token: securityContext?.credential?.token }))
        }
    }
    return <></>
};