import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';

import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType, Icon } from '@fluentui/react';

import CoverageHistoryList from '../features/insured/CoverageHistoryList';
import CoveragePlanList from '../features/insured/CoveragePlanList';
import CoveragePlanModal from '../features/insured/CoveragePlanModal';
import CoveragePlanModalEdit from '../features/insured/CoveragePlanModalEdit';
import CoverageWelfareList from '../features/insured/CoverageWelfareList';
import CoverageWelfareModal from '../features/insured/CoverageWelfareModal';
import CoverageWelfareModalEdit from '../features/insured/CoverageWelfareModalEdit';
import InitialComunication from '../features/insured/InitialComunication';
import InsuredFamilyCoreList from '../features/insured/InsuredFamilyCoreList';
import InsuredFamilyCoreModal from '../features/insured/InsuredFamilyCoreModal';
import InsuredResultList from '../features/insured/InsuredResultList';
import InsuredVoucherList from '../features/insured/InsuredVoucherList';
import InsuredVoucherModal from '../features/insured/InsuredVoucherModal';
import InsuredVoucherModalEdit from '../features/insured/InsuredVoucherModalEdit';
import InsuredPlafondList from '../features/insured/InsuredPlafondList';
import InsuredPlafondModal from '../features/insured/InsuredPlafondModal';
import InsuredPlafondModalEdit from '../features/insured/InsuredPlafondModalEdit';
import ComunicationsList from '../features/insured/ComunicationsList';
import SurveyModal from '../features/insured/SurveyModal';
import SurveyList from '../features/insured/SurveyList';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import { security } from '../features/security/thunks/securityThunk';
import { itemData } from '../features/operation/thunks/itemThunk';
import { setResponse } from '../features/response/responseSlice';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { setOperation } from '../features/operation/operationSlice';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import DateRange from '../components/controls/DateRange';
import MultiBox from '../components/controls/MultiBox';
import { filterForm } from '../features/operation/thunks/filterThunk';
import { lock, unlock } from '../features/operation/lockSlice';
import { PivotItem, Pivot, IButtonProps, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';

export const gotoGenerateUser = createAsyncThunk(
    'item/gotoGenerateUser',
    async (args: { insured: string, token: string | undefined }) => {
        const response = await client.post("Insured", { form: { id: args.insured } }, 'GenerateUser', args.token)
        return response
    }
)
export const gotoResetPassword = createAsyncThunk(
    'item/gotoResetPassword',
    async (args: { insured: string, token: string | undefined }) => {
        const response = await client.post("Insured", { form: { id: args.insured } }, 'ResetPassword', args.token)
        return response
    }
)
export const Anonymize = createAsyncThunk(
    'item/Anonymize',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Insured", { form: {}, page: args.page }, 'Anonymize', args.token)
        return response
    }
)

export default () => {
    const roles = useSecurityRoles()
    const isInsuredUser: boolean = roles.isInsuredUser
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isCorporation: boolean = roles.isCorporation;
    const isCompany: boolean = roles.isCompany;
    const isONHCOperator: boolean = roles.isONHCOperator

    const isONHC: boolean = roles.isONHC;
    const isONHCPower: boolean = roles.isONHCMaster || roles.isONHCAccount;
    const isHF: boolean = roles.isHF;

    const controller = "Insured"
    const page = useLeapState(controller, roles.role !== undefined, isInsuredUser ? "item" : "filter")

    const coverageController = "Coverage"
    const insuredFamilyCoreController = "InsuredFamilyCore"
    const insuredVoucherController = "InsuredVoucher"
    const insuredPlafondController = "InsuredPlafond"
    const SurveyController = "Survey"

    function eval_active(item: string, state: string) {
        if (item) {
            if (item === "1") return false;
        }
        else {
            if (state === "2") return false;
        }
        return true;
    }

    function calculate_age(birthDate: Date) {
        var diff_ms = new Date(Date.now()).setHours(0, 0, 0, 0) - new Date(birthDate).getTime();
        return diff_ms / 31556926000;
    }

    const insured_item: string = page.item?.values?.leap_InsuredStateID?.value;
    const insured_state: string = page?.item?.state?.data?.leap_InsuredStateID;
    const insuredState_check: boolean = eval_active(insured_item, insured_state);

    const isMaster: boolean = page?.item?.state?.data?.isMaster;

    const showFamiliars = useLeapData("showFamiliars", "mostra famliliari", page, "insuredShowFamiliars")
    const plan = useLeapData("plan", "piano sanitario", page, "plans")

    const registration_number = useLeapData("registration_number", "matricola", page, /*"registerNumbers"*/)
    const corporation = useLeapData("corporation", "azienda", page, "corporations")

    const code = useLeapData("code", "codice", page)
    const leap_InsuredStateID = useLeapData("leap_InsuredStateID", "attivo", page, "insuredState")
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const birthNations = useLeapData("nation_BirthPlaceID", "nazione di nascita", page, "birthNations")
    const birthMunicipalities = useLeapData("municipality_BirthPlaceID", "comune di nascita", page, "birthMunicipalities")
    const birthDate = useLeapData("birthDate", "data di nascita", page)

    //recapiti
    const address = useLeapData("address", "indirizzo", page)
    const addressNumber = useLeapData("addressNumber", "civico", page)
    const cap = useLeapData("cap", "cap", page)
    const nationID = useLeapData("nationID", "nazione", page, "residenceNations")
    const regionID = useLeapData("regionID", "regione", page, "residenceRegions")
    const provinceID = useLeapData("provinceID", "provincia", page, "residenceProvinces")
    const residenceMunicipalityID = useLeapData("municipality_ResidenceID", "comune", page, "residenceMunicipalities")
    const email = useLeapData("email", "email", page)
    const phoneNumber = useLeapData("phoneNumber", "telefono", page)

    const healthPlanID = useLeapData("healthPlanID", "piano sanitario", page, "healthPlans")
    const companyID = useLeapData("companyID", "compagnia", page, "companies")
    const managementTypeID = useLeapData("managementTypeID", "tipo di gestione", page, "managementType")
    const start_date = useLeapData("start_date", "data inizio validità", page)
    const end_date = useLeapData("end_date", "data fine validità", page)

    const leap_VoluntaryAcceptanceStrictID = useLeapData("voluntaryAcceptanceStrictID", "consenso minimo", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceMarketingID = useLeapData("voluntaryAcceptanceMarketingID", "consenso marketing", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceOthersID = useLeapData("voluntaryAcceptanceOthersID", "consenso terzi", page, "voluntaryAcceptance")
    const leap_VoluntaryAcceptanceMailingListID = useLeapData("voluntaryAcceptanceMailingListID", "mailing list", page, "voluntaryAcceptance")

    const fForm = useSelector(filterForm)
    const insuredFilterForm = fForm.find(i => i.key === controller)?.value

    const downloadInsuredFile = () => {
        client.downloadPost(controller, {
            form: {
                fileName: `Dati Assistiti.csv`,
                ...insuredFilterForm
            }
        }, 'DownloadInsuredCSV', undefined, credential.token)
    }

    //estremi bancari
    const iban = useLeapData("iban", "IBAN", page)
    const account_Header = useLeapData("account_Header", "intestazione C/C", page)

    //privacy
    const leap_InsuredAcceptedPrivacyID = useLeapData("leap_InsuredAcceptedPrivacyID", "privacy accettata", page, "insuredAcceptedPrivacy")
    //const leap_InsuredPrivacyNewsLetterID = useLeapData("leap_InsuredPrivacyNewsLetterID", "newsletter", page, "insureNewsletter")
    //const newsletterAcceptanceDate = useLeapData("newsletterAcceptanceDate", "data accettazione newsletter", page)
    //const leap_InsuredCommercialPrivacyID = useLeapData("leap_InsuredCommercialPrivacyID", "ai fini commerciali", page, "insuredCommercialPrivacy")
    const leap_InsuredOnlineAccessID = useLeapData("leap_InsuredOnlineAccessID", "accesso online", page, "insuredOnlineAccesses")

    const coveragePlan_empty = page?.item?.state?.lists?.CoveragePlan === null || page?.item?.state?.lists?.CoveragePlan?.length === 0
    const coverageWelfare_empty = page?.item?.state?.lists?.CoverageWelfare === null || page?.item?.state?.lists?.CoverageWelfare?.length === 0
    const coverageHistory_empty = page?.item?.state?.lists?.CoverageHistory === null || page?.item?.state?.lists?.CoverageHistory?.length === 0
    const InsuredFamilyCoreList_empty = page?.item?.state?.lists?.InsuredFamilyCore === null || page?.item?.state?.lists?.InsuredFamilyCore?.length === 0
    //const insuredFamilyCore_show = page?.item?.state?.lists?.InsuredFamilyCore !== null && page?.item?.state?.lists?.InsuredFamilyCore?.length !== 0
    const insuredVoucher_empty = page?.item?.state?.lists?.InsuredVoucher === null || page?.item?.state?.lists?.InsuredVoucher?.length === 0

    //const nationSelected: boolean = page.item?.values?.nationID?.value !== undefined && page.item?.values?.nationID?.value !== "";
    //const birthNationSelected: boolean = page.item?.values?.nation_BirthPlaceID?.value !== undefined && page.item?.values?.nation_BirthPlaceID?.value !== "";
    //const provinceSelected: boolean = page.item?.values?.provinceID?.value !== undefined && page.item?.values?.provinceID?.value !== "";

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const dispatch: AppDispatch = useDispatch();
    const onClickGenerateUser = (e: any) => {
        e.preventDefault()
        toggleHideDialogGenerateUser()
    }
    const [hideDialogGenerateUser, { toggle: toggleHideDialogGenerateUser }] = useBoolean(true);
    const dialogContentPropsGenerateUser = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: "Procedo alla generazione dell'utenza?",
    };

    const onClickResetPassword = (e: any) => {
        e.preventDefault()
        toggleHideDialogResetPassword()
    }
    const [hideDialogResetPassword, { toggle: toggleHideDialogResetPassword }] = useBoolean(true);
    const dialogContentPropsResetPassword = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: "Procedo al reset della password?",
    };

    const onClickAnonymize = (e: any) => {
        e.preventDefault()
        toggleHideDialogAnonymize()
    }
    const [hideDialogAnonymize, { toggle: toggleHideDialogAnonymize }] = useBoolean(true);
    const dialogContentPropsAnonymize = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: "Procedo all\'anonimizzazione dell'utenza?",
    };

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const iData = useSelector(itemData)
    const credential = useSelector(security)?.credential
    const onExecuteGenerateUser = () => {
        toggleHideDialogGenerateUser()
        dispatch(gotoGenerateUser({ insured: iData.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then((response) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
                if (response.payload.response.level === 4) {
                    return true
                }
                else {
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(dispatchReload)
                }
            })
    }
    const onExecuteResetPassword = () => {
        toggleHideDialogResetPassword()
        dispatch(gotoResetPassword({ insured: iData.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then((response) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level, true))
                if (response.payload.response.level === 4) {
                    return true
                }
                else {
                    return false
                }
            })
            .then((proceed) => {
                if (proceed) {
                    dispatch(dispatchReload)
                }
            })

    }
    const onExecuteAnonymize = () => {
        dispatch(lock())
        toggleHideDialogAnonymize()
        dispatch(Anonymize({ token: credential.token, page: dPage }))
            .then((response) => {
                dispatchMessage(response)
                response.payload.response.level === 4 && dispatchReload(response)
            })
            .finally(() => dispatch(unlock()))
    }

    const hasUser: boolean = page?.item?.state?.data?.hasUser;
    const hasManualInsert: boolean = page?.item?.state?.data?.hasManualInsert;

    const onlineAccess = page?.item?.state?.data?.leap_InsuredOnlineAccessID;

    const dItem = iData.find(d => d.key === controller)?.value
    const dPage = dItem?.metadata?.page

    const isAnonymized = page?.item?.state?.data?.lastname === "unknown" && page?.item?.state?.data?.firstname === "unknown"

    const noDetailAccessLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            textAlign: "center",
            whiteSpace: "nowrap"
        }
    }

    return <>
        <Content title={isInsuredUser ? "Il mio Profilo" : "Assistito"} controller={controller}>
            <Filter>
                <Grid>
                    {isInsuredUser && <div className="ms-Grid-col ms-sm12">
                        <Label styles={noDetailAccessLabel}>non è possibile applicare filtri di ricerca</Label>
                    </div>}
                    <Section toolbar="filter">
                        <Row hidden={isInsuredUser}>
                            <Text {...code} className="ms-sm2" maxlength={12} />
                            <Option {...leap_InsuredStateID} className="ms-sm2" type="horizontal" hidden={isInsuredUser || isHF} disabled={isInsuredUser || isHFOperator || isHFUser} />
                            <Option {...leap_InsuredAcceptedPrivacyID} className="ms-sm2" type="horizontal" hidden={isHF} />
                            <Option {...showFamiliars} className="ms-sm2" type="horizontal" hidden={isONHCOperator || isONHCAccount || isONHCOperator || isONHCAccount || isHF} disabled={isONHCOperator || isONHCAccount} />
                        </Row>
                        <Row hidden={isHF || isInsuredUser}>
                            <SearchBox {...plan} className="ms-sm4" />
                        </Row>
                        <Row hidden={isInsuredUser}>
                            <Text {...fiscalcode} className="ms-sm2" maxlength={16} />
                            <Text {...lastname} className="ms-sm2" maxlength={50} />
                            <Text {...firstname} className="ms-sm2" maxlength={50} />
                        </Row>
                    </Section>
                    <Section title="posizione lavorativa" hidden={isHF || isInsuredUser}>
                        <Row hidden={isHF || isInsuredUser}>
                            <Text {...registration_number} className="ms-sm2" />
                        </Row>
                        <Row hidden={isHF || isInsuredUser}>
                            <SearchBox {...corporation} className="ms-sm4" />
                        </Row>
                    </Section>
                    <Section title="recapiti" hidden={isHF || isInsuredUser}>
                        <Row hidden={isHF || isInsuredUser}>
                            <Text {...email} className="ms-sm4" maxlength={255} />
                        </Row>
                        <Row hidden={isHF || isInsuredUser}>
                            <SearchBox {...nationID} className="ms-sm4" />
                            <SearchBox {...regionID} className="ms-sm4" />
                        </Row>
                        <Row hidden={isHF || isInsuredUser}>
                            <SearchBox {...provinceID} className="ms-sm4" />
                            <SearchBox {...residenceMunicipalityID} className="ms-sm4" />
                        </Row>
                    </Section>
                    {/*TODO: DATAEXTRACTION */}
                    {false && <Section title="estrazione dati">
                        <Row>
                            <MultiSearchBox {...healthPlanID} className="ms-sm12" itemLimit={99} />
                            <DateRange dateMin={{ ...start_date, className: "ms-sm2" }} dateMax={{ ...end_date, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <MultiSearchBox {...companyID} className="ms-sm12" itemLimit={99} />
                            <MultiBox {...managementTypeID} label="tipo di gestione" className="ms-sm5" titleType='text' />
                        </Row>
                        <Row>
                            <Text
                                onChange={() => { }}
                                className="ms-sm2"
                                label="elenco assistiti"
                                name=""
                                type="plainWithIcon"
                                value="scarica elenco assistiti"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadInsuredFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                        </Row>
                    </Section>}
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <InsuredResultList controller={controller} items={page.result?.state?.data} isCorporation={isCorporation} isCompany={isCompany} isHealthFacility={isHF} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" type="plain" readOnly={page.modality !== "new" || isInsuredUser || isHFOperator || isHFUser} />
                            <Option {...leap_InsuredStateID} className={`ms-sm${isAnonymized ? "4" : "2"}`} type="horizontal" hidden={isInsuredUser} disabled />
                            {page.modality === "item" && !isAnonymized && (isONHCPower) && <>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 20, display: "flex", justifyContent: "flex-end" }}>
                                    <PrimaryButton text="anonimizza" onClick={onClickAnonymize} />
                                    <Dialog hidden={hideDialogAnonymize} onDismiss={toggleHideDialogAnonymize} dialogContentProps={dialogContentPropsAnonymize} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteAnonymize} text='Anonimizza' />
                                            <DefaultButton onClick={toggleHideDialogAnonymize} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>}
                            {page.modality === "item" && (isONHC) && <InitialComunication />}

                        </Row>
                        <Row>
                            <Text {...fiscalcode} className="ms-sm3 ms-xxl2" required maxlength={16} readOnly={isInsuredUser} />
                            <Text {...lastname} className="ms-sm4" required maxlength={50} readOnly={isInsuredUser} />
                            <Text {...firstname} className="ms-sm4" required maxlength={50} readOnly={isInsuredUser} />
                        </Row>
                        <Row>
                            <SearchBox {...birthNations} className="ms-sm5" hidden={isHFOperator || isHFUser} disabled={isHFOperator || isHFUser} />
                            <SearchBox {...birthMunicipalities} className="ms-sm5" hidden={isHFOperator || isHFUser} disabled={/*!(birthNationSelected) ||*/ isHFOperator || isHFUser} />
                            <Text {...birthDate} dateRange={{ minDate: undefined, maxDate: Date().toLocaleString() }} className="ms-sm2" type="calendar" required={page.modality !== "new"} readOnly={isInsuredUser} />
                        </Row>
                    </Section>

                    <Section title="recapiti">
                        <Row>
                            <Text {...address} className="ms-sm3" maxlength={255} hidden={isHFOperator || isHFUser} readOnly={isHFOperator || isHFUser} />
                            <Text {...addressNumber} className="ms-sm1" maxlength={8} hidden={isHFOperator || isHFUser} readOnly={isHFOperator || isHFUser} />
                            <Text {...cap} className="ms-sm1" maxlength={5} hidden={isHFOperator || isHFUser} readOnly={isHFOperator || isHFUser} />
                        </Row>
                        <Row>
                            <SearchBox {...nationID} className="ms-sm5" hidden={isHFOperator || isHFUser} disabled={isHFOperator || isHFUser} />
                            <SearchBox {...residenceMunicipalityID} className="ms-sm5" hidden={isHFOperator || isHFUser} disabled={/*!(nationSelected) ||*/ isHFOperator || isHFUser} />
                        </Row>
                        <Row>
                            <Text {...email} className="ms-sm3" maxlength={255} required={page.modality === 'item' && isInsuredUser} />
                            <Text {...phoneNumber} className="ms-sm3" hidden={isHFOperator || isHFUser} readOnly={isHFOperator || isHFUser} />
                            {page.modality === "item" &&
                                (roles.isONHCMaster || roles.isONHCOperator || roles.isONHCAccount) &&
                                !hasUser &&
                                calculate_age(page.item?.values?.birthDate?.value) >= 18 &&
                                onlineAccess === '1' &&
                                (<>
                                    <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 20 }}>
                                        <PrimaryButton text="Genera Utenza" onClick={onClickGenerateUser} />
                                        <Dialog hidden={hideDialogGenerateUser} onDismiss={toggleHideDialogGenerateUser} dialogContentProps={dialogContentPropsGenerateUser} modalProps={modalProps} >
                                            <DialogFooter>
                                                <PrimaryButton onClick={onExecuteGenerateUser} text='Genera Utenza' />
                                                <DefaultButton onClick={toggleHideDialogGenerateUser} text="Annulla" />
                                            </DialogFooter>
                                        </Dialog>
                                    </div>
                                </>)}
                            {page.modality === "item" &&
                                (roles.isONHCMaster || roles.isONHCOperator || roles.isONHCAccount) &&
                                hasUser &&
                                calculate_age(page.item?.values?.birthDate?.value) >= 18 &&
                                onlineAccess === '1' &&
                                (<>
                                    <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 20 }}>
                                        <PrimaryButton text="Resetta Password" onClick={onClickResetPassword} />
                                        <Dialog hidden={hideDialogResetPassword} onDismiss={toggleHideDialogGenerateUser} dialogContentProps={dialogContentPropsResetPassword} modalProps={modalProps} >
                                            <DialogFooter>
                                                <PrimaryButton onClick={onExecuteResetPassword} text='Resetta Password' />
                                                <DefaultButton onClick={toggleHideDialogResetPassword} text="Annulla" />
                                            </DialogFooter>
                                        </Dialog>
                                    </div>
                                </>)}
                        </Row>
                    </Section>

                    <Section title="estremi bancari" hidden={isHF}>
                        <Row hidden={isHF}>
                            <Text {...iban} className="ms-sm3" maxlength={50} hidden={isHF} readOnly={isHF} />
                            <Text {...account_Header} className="ms-sm3" maxlength={50} hidden={isHF} readOnly={isHF} />
                        </Row>
                    </Section>

                    {page.modality === "item" && (<>
                        <Section title="attivazione infortunio/malattia" toolbar="grid" modal={<InsuredPlafondModal state={page} controller={insuredPlafondController} />} controller={insuredPlafondController} modalwidth="600px" modaltitle="Inserisci un plafond" state={page} >
                            <Row>
                                <InsuredPlafondList items={page.item.state?.lists.InsuredPlafond} modal={<InsuredPlafondModalEdit state={page} controller={insuredPlafondController} />} controller={insuredPlafondController} modalwidth="600px" modaltitle="Modifica un plafond" state={page} />
                            </Row>
                        </Section>
                    </>)}

                    <Section title="privacy">
                        <Row>
                            <Option {...leap_InsuredAcceptedPrivacyID} className="ms-sm2" type="horizontal" disabled />
                            <Option {...leap_InsuredOnlineAccessID} className="ms-sm2" type="horizontal" hidden={isInsuredUser || isHFOperator || isHFUser || isONHCOperator || isONHCAccount} disabled={page.modality !== "item" || !isONHC || onlineAccess === '3'} disabledKeys={['3']} />
                        </Row>
                    </Section>

                    {page.modality === "item" && <>
                        <Section title="dichiarazioni trattamento dati" hidden={!isONHC && !isInsuredUser} >
                            <Row>
                                <Option {...leap_VoluntaryAcceptanceStrictID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isInsuredUser} disabled={!isInsuredUser} />
                                <Option {...leap_VoluntaryAcceptanceMarketingID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isInsuredUser} disabled={!isInsuredUser} />
                                <Option {...leap_VoluntaryAcceptanceOthersID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isInsuredUser} disabled={!isInsuredUser} />
                                <Option {...leap_VoluntaryAcceptanceMailingListID} className="ms-sm3" type="horizontal" hidden={!isONHC && !isInsuredUser} disabled={!isInsuredUser} />
                            </Row>
                        </Section >
                    </>}

                    {page.modality === "item" && (<Section hidden={(roles.isONHCOperator || roles.isInsuredUser) && coveragePlan_empty} title="elenco piani sanitari fondo" toolbar="grid" modal={<CoveragePlanModal state={page} controller={coverageController} />} modalwidth="600px" controller={coverageController} modaltitle="Inserisci un piano sanitario fondo" state={page}>
                        <Row hidden={(roles.isONHCOperator || roles.isInsuredUser) && coveragePlan_empty}>
                            <CoveragePlanList items={page.item.state?.lists.CoveragePlan} modal={<CoveragePlanModalEdit isONHCOperator={isONHCOperator} state={page} controller={coverageController} />} controller={coverageController} modalwidth="600px" modaltitle="Modifica un piano sanitario fondo" state={page} />
                        </Row>
                    </Section>)}

                    {page.modality === "item" && (<Section hidden={(roles.isONHCOperator || roles.isInsuredUser) && coverageWelfare_empty} title="elenco piani sanitari welfare" toolbar="grid" modal={<CoverageWelfareModal state={page} controller={coverageController} />} modalwidth="600px" controller={coverageController} modaltitle="Inserisci un piano sanitario welfare" state={page} >
                        <Row hidden={(roles.isONHCOperator || roles.isInsuredUser) && coverageWelfare_empty}>
                            <CoverageWelfareList items={page.item.state?.lists.CoverageWelfare} modal={<CoverageWelfareModalEdit isONHCOperator={isONHCOperator} state={page} controller={coverageController} />} controller={coverageController} modalwidth="600px" modaltitle="Modifica un piano sanitario welfare" state={page} />
                        </Row>
                    </Section>)}

                    {page.modality === "item" && (<Section hidden={coverageHistory_empty} title="elenco storico piani sanitari" collapsable={coverageHistory_empty ? false : true} >
                        <Row hidden={coverageHistory_empty}>
                            <CoverageHistoryList items={page.item.state?.lists.CoverageHistory} />
                        </Row>
                    </Section>)}

                    {page.modality === "item" && (<Section hidden={InsuredFamilyCoreList_empty && isInsuredUser && !hasManualInsert} title="elenco nucleo familiare" toolbar="grid" modal={<InsuredFamilyCoreModal state={page} controller={insuredFamilyCoreController} />} controller={insuredFamilyCoreController} modalwidth="800px" modaltitle="Inserisci un familiare" state={page} readOnly={isInsuredUser && (!isMaster || !hasManualInsert)}>
                        <Row hidden={InsuredFamilyCoreList_empty && (isInsuredUser && !hasManualInsert)}>
                            <InsuredFamilyCoreList canDelete={(isMaster && hasManualInsert) || !isInsuredUser} items={page.item.state?.lists.InsuredFamilyCore} modal={<InsuredFamilyCoreModal state={page} controller={insuredFamilyCoreController} />} controller={insuredFamilyCoreController} navigateToController={controller} modaltitle="Modifica un familiare" state={page} />
                        </Row>
                    </Section>)}

                    {page.modality === "item" && (<>
                        <Section hidden={roles.isInsuredUser && insuredVoucher_empty} title="elenco vouchers" toolbar="grid" modal={<InsuredVoucherModal state={page} controller={insuredVoucherController} />} controller={insuredVoucherController} modalwidth="600px" modaltitle="Inserisci un voucher" state={page} >
                            <Row hidden={roles.isInsuredUser && insuredVoucher_empty}>
                                <InsuredVoucherList items={page.item.state?.lists.InsuredVoucher} modal={<InsuredVoucherModalEdit state={page} controller={insuredVoucherController} />} controller={insuredVoucherController} modalwidth="600px" modaltitle="Modifica un voucher" state={page} />
                            </Row>
                        </Section>
                    </>)}

                    {page.modality === "item" &&
                        (<Section hidden={!isONHC} title="elenco comunicazioni">
                            <Row hidden={!isONHC}>
                                <ComunicationsList items={page.item.state?.lists?.InitialComunicationLogs} />
                            </Row>
                        </Section>)}

                    {page.modality === "item" && isONHC && (<Section title="elenco invii feedback periodici" toolbar="grid" modal={<SurveyModal state={page} controller={SurveyController} />} controller={SurveyController} modalwidth="800px" modaltitle="richiedi feedback periodico" state={page} >
                        <Row>
                            <SurveyList items={page.item.state?.lists?.Survey} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
