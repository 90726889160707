type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'DOWNLOADPOST' | 'DOWNLOADPOSTMULTI' | 'PRINTPOST' | 'DOWNLOADRESULT'

const { REACT_APP_LEAP_API_ENDPOINT } = process.env;

export async function client(controller: string, method: HttpMethod, body?: any, path?: string, querystring?: string, token?: string) {
    var url = REACT_APP_LEAP_API_ENDPOINT + "/api/" + controller + "/"
    if (path) {
        url += path + "/"
    }
    if (querystring) {
        url += "?" + querystring
    }


    if (method === 'DOWNLOADPOST') {
        return fetch(url, {
            'method': 'POST',
            'headers': {
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json',
                'Leap-Session': token ?? ''
            },
            'body': JSON.stringify(body)
        })
            .then(response => {
                return response.blob()
            })
            .then(myBlob => {
                downloadBlob(myBlob, body.form.fileName)
                return myBlob
            })
            .catch(err => {
                console.log(err);
            });

    }
    else if (method === 'PRINTPOST') {
        return fetch(url, {
            'method': 'POST',
            'headers': {
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json',
                'Leap-Session': token ?? ''
            },
            'body': JSON.stringify(body)
        })
            .then(response => response.blob())
            .then(myBlob => {
                printBlob(myBlob, "Stampa.pdf")
            })
            .catch(err => { console.log(err); });
    }
    else if (method === 'DOWNLOADRESULT') {
        return fetch(url, {
            'method': 'POST',
            'headers': {
                'Accept': 'application/octet-stream',
                'Content-Type': 'application/json',
                'Leap-Session': token ?? ''
            },
            'body': JSON.stringify(body)
        })
            .then(response => response.blob())
            .then(myBlob => {
                printBlob(myBlob, "risultatiDiRicerca.csv")
            })
            .catch(err => { console.log(err); });
    }
    else if (body) {
        return fetch(url, {
            'method': method,
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Leap-Session': token ?? ''
            },
            'body': JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => json)
            .catch(err => { console.log(err); });

    } else {
        return fetch(url, {
            'method': method,
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Leap-Session': token ?? ''
            },
        })
            .then(response => response.json())
            .then(json => json)
            .catch(err => { console.log(err); });

    }
}

client.get = function (controller: string, body?: any, path?: string, token?: string) {
    return client(controller, 'GET', body, path, undefined, token)
}

client.downloadPost = function (controller: string, body?: any, path?: string, querystring?: string, token?: string) {
    return client(controller, 'DOWNLOADPOST', body, path, querystring, token)
}

client.post = function (controller: string, body: any, path?: string, token?: string) {
    return client(controller, 'POST', body, path, undefined, token)
}

client.put = function (controller: string, body: any, path?: string, token?: string) {
    return client(controller, 'PUT', body, path, undefined, token)
}

client.delete = function (controller: string, body: any, path?: string, token?: string) {
    return client(controller, 'DELETE', body, path, undefined, token)
}

client.printPost = function (controller: string, body?: any, path?: string, querystring?: string, token?: string | undefined) {
    return client(controller, 'PRINTPOST', body, path, querystring, token)
}

client.downloadResult = function (controller: string, body: any, path?: string, token?: string) {
    return client(controller, 'DOWNLOADRESULT', body, path, undefined, token)
}

function downloadBlob(blob: Blob, filename: string) {
    if (blob.size == 0) {
        return undefined
    }
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = filename || 'packet.bin';

    a.click();

    setTimeout(() => URL.revokeObjectURL(url), 1000);

    return a;
}

function printBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');

    a.href = url;
    a.download = filename || 'print.pdf';

    a.click();

    setTimeout(() => URL.revokeObjectURL(url), 1000);

    return a;
}