import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import { useSecurityRoles } from '../../hooks/useSecurityRoles';

import { Icon } from '@fluentui/react';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const onRenderIconDocumentatioRequiredState = (e: any) => {
        if (e.leap_DocumentationVerificationID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: 'mediumseagreen' }} />;
        } else if (e.leap_DocumentationVerificationID === "2") {
            return <Icon iconName={"CircleFill"} style={{ color: 'gold' }} />;
        } else if (e.leap_DocumentationVerificationID === "3") {
            return <Icon iconName={"CircleFill"} style={{ color: 'red' }} />;
        } else if (e.leap_DocumentationRequiredID === "1") {
            return <Icon iconName={"CircleFill"} style={{ color: '#d0d0d0' }} />;
        }
        else {
            return <Icon iconName={"CircleRing"} style={{ color: '#d0d0d0' }} />;
        }
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick}>
                {isONHC && <DataColumn kid='column2' type="icon" name='' fieldName='leap_DocumentationVerificationID' minWidth={20} maxWidth={20} align='center' onRenderIcon={onRenderIconDocumentatioRequiredState} />}
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Descrizione' fieldName='description' minWidth={250} align='left' />
                <DataColumn kid='column3' name='Codice Assistito' fieldName='insuredCode' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column4' name='Assistito' fieldName='insured' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column5' name='Codice Fiscale' fieldName='fiscalCode' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column6' name='Piano Sanitario' fieldName='plan' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column11' name='Rimborso' fieldName='refound' minWidth={100} maxWidth={100} align='right' />
                <DataColumn kid='column10' name='Data di Rimborso' fieldName='refoundDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='column7' name='Stato' fieldName='state' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column8' name='Inserimento' fieldName='createDate' minWidth={160} maxWidth={160} align='center' />
                <DataColumn kid='column9' name='Ultimo Aggiornamento' fieldName='updateDate' minWidth={160} maxWidth={160} align='center' />
            </DataList>
        </>
    );
}
