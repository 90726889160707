import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

type Props = {
    items: any,
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column3' name='Feedback' fieldName='feedback' minWidth={300} align='left' />
                <DataColumn kid='column5' name='Operatore' fieldName='user' minWidth={150} maxWidth={200} align='left' />
                <DataColumn kid='column1' name='Data Invio' fieldName='date' minWidth={150} maxWidth={250} align='right' />
            </DataList>
        </>
    );
}
