import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState'
import { useLeapData } from '../hooks/useLeapData'
import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';
import NationResultList from '../features/nation/NationResultList'
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';

export default () => {

    const controller = "Nation"
    const page = useLeapState(controller)

    const id = useLeapData("id", "id", page)
    const name = useLeapData("name", "nome", page)

    return <>
        <Content title="Nazione" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm5" maxlength={50} />
                        </Row>

                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <NationResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <input {...id} type="hidden" />
                            <Text {...name} className="ms-sm5" required maxlength={50} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
}
