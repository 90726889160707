import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux'; import { AppDispatch } from '../app/store';

import { security } from '../features/security/thunks/securityThunk';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';

import BordereauResultList from '../features/bordereau/BordereauResultList';

import BordereauQuoteManagementHealthServiceList from '../features/bordereau/BordereauQuoteManagementHealthServiceList';

import { useSecurityRoles } from '../hooks/useSecurityRoles';
import SearchBox from '../components/controls/SearchBox';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { itemForm } from '../features/operation/thunks/itemThunk';
import { mergeStyleSets, PrimaryButton } from '@fluentui/react';
import { setResponse } from '../features/response/responseSlice';
import Separator from '../components/controls/grid/Separator';

export const CSVDownload = createAsyncThunk(
    'item/download',
    async (args: { form: any, token: string | undefined }) => {
        const response = await client.downloadPost("Bordereau", { form: args.form }, 'Download', args.token)
        return response
    }
)

export default () => {
    const controller = "Bordereau"
    const page = useLeapState(controller)

    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria ", page, "healthFacilities")
    const BorderauStateID = useLeapData("leap_BorderauStateID", "stato preventivo", page, "states")
    const planID = useLeapData("planID", "piano sanitario", page, "plans")
    const StartDateDraft = useLeapData("startDateDraft", "data bozza da", page)
    const EndDateDraft = useLeapData("endDateDraft", "data bozza a", page)
    const StartDateProcessing = useLeapData("startDateProcessing", "data lavorazione da", page)
    const EndDateProcessing = useLeapData("endDateProcessing", "data lavorazione a", page)
    const StartDateAuthorized = useLeapData("startDateAuthorized", "data autorizzazione da", page)
    const EndDateAuthorized = useLeapData("endDateAuthorized", "data autorizzazione a", page)
    const StartDateCure = useLeapData("startDateCure", "data fine cure da", page)
    const EndDateCure = useLeapData("endDateCure", "data fine cure a", page)
    const fileName = useLeapData("fileName", "nome estrazione", page)

    const CreationDate = useLeapData("creationDate", "data creazione", page)
    const CreationDateFrom = useLeapData("creationDateFrom", "data creazione da", page)
    const CreationDateTo = useLeapData("creationDateTo", "data creazione a", page)

    const InvoiceDateFrom = useLeapData("invoiceDateFrom", "data fattura da", page)
    const InvoiceDateTo = useLeapData("invoiceDateTo", "data fattura a", page)
    const InvoiceVerificationDateFrom = useLeapData("invoiceVerificationDateFrom", "data verifica da", page)
    const InvoiceVerificationDateTo = useLeapData("invoiceVerificationDateTo", "data verifica a", page)

    const leap_InsuredInvoiceVerificationID = useLeapData("leap_InsuredInvoiceVerificationID", "stato di verifica della fattura assistito", page, "documentationVerificationOption")

    const roles = useSecurityRoles()
    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isInsuredUser: boolean = roles.isInsuredUser;

    const credential = useSelector(security)?.credential

    const dispatch: AppDispatch = useDispatch();

    const iForm = useSelector(itemForm)
    const form = iForm.find(i => i.key === controller)?.value

    const isZip = page.item?.state?.data?.multiDownload

    const _fileName = (form?.fileName ? form?.fileName?.replace(/ /g, '') : "bordereau") + (isZip ? ".zip" : ".csv");

    const downloadCSV = (e: any) => {
        dispatch(CSVDownload({ form: { ...form, healthFacilityID: page.item?.values?.healthFacilityID?.value, fileName: _fileName }, token: credential.token }))
    }

    const contentStyles = mergeStyleSets({
        body: {
            marginRight: '10px'
        },
    });


    return <>
        <Content title="Bordereau" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm4" />
                            <SearchBox {...planID} className="ms-sm4" />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...CreationDateFrom, className: "ms-sm2" }} dateMax={{ ...CreationDateTo, className: "ms-sm2" }} />
                            <ComboBox {...BorderauStateID} className="ms-sm2" />
                        </Row>
                    </Section>
                    <Section title="date di riferimento a preventivo">
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateDraft, className: "ms-sm2" }} dateMax={{ ...EndDateDraft, className: "ms-sm2" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateAuthorized, className: "ms-sm2" }} dateMax={{ ...EndDateAuthorized, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateProcessing, className: "ms-sm2" }} dateMax={{ ...EndDateProcessing, className: "ms-sm2" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateCure, className: "ms-sm2" }} dateMax={{ ...EndDateCure, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...InvoiceDateFrom, className: "ms-sm2" }} dateMax={{ ...InvoiceDateTo, className: "ms-sm2" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...InvoiceVerificationDateFrom, className: "ms-sm2" }} dateMax={{ ...InvoiceVerificationDateTo, className: "ms-sm2" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <ComboBox {...leap_InsuredInvoiceVerificationID} className="ms-sm4" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <BordereauResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...fileName} className="ms-sm8" />
                        </Row>
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm4" disabled={page.modality !== "new"} />
                            <SearchBox {...planID} className="ms-sm4" disabled={page.modality !== "new"} />
                        </Row>
                        <Row >
                            <Text {...CreationDate} className="ms-sm2" type="calendar" readOnly hidden={page.modality === "new"} />
                            <ComboBox {...BorderauStateID} className="ms-sm2" disabled={page.modality !== "new"} />
                            <div className="ms-Grid-col ms-sm8">
                                {page.modality !== "new" && <PrimaryButton text="estrazione dati" onClick={downloadCSV} className={contentStyles.body} style={{ marginTop: 23 }} />}
                            </div>
                        </Row>
                    </Section>
                    <Section title="date di riferimento a preventivo">
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateDraft, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...EndDateDraft, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateAuthorized, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...EndDateAuthorized, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateProcessing, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...EndDateProcessing, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <DateRange defaultMinTomorrow dateMin={{ ...StartDateCure, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...EndDateCure, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...InvoiceDateFrom, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...InvoiceDateTo, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                        </Row>
                        <Row>
                            <DateRange defaultMinTomorrow dateMin={{ ...InvoiceVerificationDateFrom, className: "ms-sm2", readOnly: page.modality !== "new" }} dateMax={{ ...InvoiceVerificationDateTo, className: "ms-sm2", readOnly: page.modality !== "new" }} />
                            <Separator className="ms-sm1 ms-hiddenXxlUp" />
                            <ComboBox {...leap_InsuredInvoiceVerificationID} className="ms-sm4" disabled={page.modality !== "new"} />
                        </Row>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
