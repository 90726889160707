import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';

export default (props: { items: any }) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Piano' fieldName='planDescription' minWidth={100} align='left' />
                <DataColumn kid='column1' name='Sconto' fieldName='discountName' minWidth={200} align='left' />
                <DataColumn kid='column1' name='n. Volte' fieldName='timesNumber' minWidth={150} maxWidth={150} align='right' />
                <DataColumn kid='column6' name='Data Inizio Copertura' fieldName='coverageStartDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column7' name='Data Fine Copertura' fieldName='coverageEndDate' minWidth={150} maxWidth={200} align='center' />
                <DataColumn kid='column8' name='Ultima Data Autorizzazione' fieldName='maxAuthDate' minWidth={220} align='center' />
            </DataList>
        </>
    );
}
