import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from '../../../app/store';
import { currentModality } from '../../../features/modality/modalitySlice';
import { itemLookup, itemForm, itemData } from '../../../features/operation/thunks/itemThunk';
import { security } from '../../../features/security/thunks/securityThunk';

import Theme from '../../themes/Theme'

import { DefaultButton, Stack, StackItem } from '@fluentui/react';
import Teeth, { TeethState } from './Teeth';

type DentalChartProps = {
    controller: string,
    name: string,
    value: string[],
    state?: TeethState[],
    selectionMode?: 'none' | 'single' | 'multiple'
    onInit?: (name: string | undefined, value: string[], required?: boolean | undefined) => void,
    onChange?: (name: string | undefined, value: string[], required?: boolean | undefined) => void,
    required?: boolean,
    readOnly?: boolean,
    children?: { name: string, lookup: string, controller: string, required?: boolean, defaultValue?: any }[],
    parentController?: string
}

export default (props: DentalChartProps) => {
    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality)

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const iForm = useSelector(itemForm)
    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === props.controller)?.value

    const [selected, setSelected] = useState(props.value ?? [])
    const [buttonState, setButtonState] = useState({
        tlb: { index: ["1"], selected: false },
        a_sup: { index: ["6"], selected: false },
        a_inf: { index: ["7"], selected: false },
        q_sup_dx: { index: ["3"], selected: false },
        q_sup_sx: { index: ["2"], selected: false },
        q_inf_sx: { index: ["4"], selected: false },
        q_inf_dx: { index: ["5"], selected: false },
        s_1: { index: ["91"], selected: false },
        s_2: { index: ["92"], selected: false },
        s_3: { index: ["93"], selected: false },
        s_4: { index: ["94"], selected: false },
        s_5: { index: ["95"], selected: false },
        s_6: { index: ["96"], selected: false }
    })

    useEffect(() => {
        setSelected(props.value ?? [])
        console.log("select : " + props.value)
        if (props.onInit) {
            props.onInit(props.name, props.value ?? [], props.required)
        }
    }, [props.value])

    useEffect(() => {
        if (props.selectionMode === "none") {
            return
        }

        const newButtonState: any = { ...buttonState }
        for (let key in newButtonState) {
            newButtonState[key].selected = true
            newButtonState[key].index.forEach((tooth: string) => selected?.indexOf(tooth) === -1 ? newButtonState[key].selected = false : {})
        }
        setButtonState(newButtonState)

        if (props.onChange)
            props.onChange(props.name, selected, props.required)

    }, [selected])

    const selectToothGroup = (value: any) => {
        if (props.selectionMode === "none") {
            return
        }

        let newSelected = [] as string[]

        if (props.selectionMode === "single") {
            newSelected = value.selected ? [] : value.index;

        } else if (value.selected) {
            newSelected = selected?.filter(item => !value.index.includes(item))
        }
        else {
            const unfilterSelected = selected.concat(value.index).sort()
            newSelected = unfilterSelected.filter((item, index) => unfilterSelected.indexOf(item) === index)
        }

        setSelected(newSelected ?? [])

        dispatchToChildren(newSelected)
    }

    const onTeethSelectionChange = (name: string, value: string[], required?: boolean | undefined) => {
        if (props.selectionMode === "none") {
            return
        }

        setSelected(value ?? [])

        dispatchToChildren(value)
    }

    const dispatchToChildren = (newValue: string[]) => {
        let parentItemForm: any = {}
        let parentItemData: any = {}
        let parentItemPage: any = {}

        if (props.children && (modality === "item" || modality === "new")) {
            if (props.parentController) {
                parentItemForm = iForm.find(i => i.key === props.parentController)?.value
                parentItemData = iData.find(d => d.key === props.parentController)?.value

                if (parentItemData?.metadata?.page) {
                    Object.assign(parentItemPage, parentItemData?.metadata?.page)

                    if (parentItemPage.index > 0) {
                        parentItemPage.index = parentItemPage.index - 1
                    } else {
                        parentItemPage.index = 0
                    }
                }
            }

            const form = iForm.find(i => i.key === props.controller)?.value
            const page = dItem?.metadata?.page

            const body = {
                form: {
                    ...form, [props.name]: newValue
                },
                parent: {
                    form: { ...parentItemForm },
                    page: parentItemPage
                }, page: page
            }

            props.children.forEach((c) => {
                dispatch(itemLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: body, token: token }))
                if (props.onChange)
                    props.onChange(c.name, c.defaultValue ?? [], c.required ?? false)
            })
        }
    }

    return <>
        <Stack horizontalAlign="center">
            <Stack horizontalAlign="center">
                <StackItem><DefaultButton text="tutta la bocca" style={{ margin: "3px 0" }} onClick={() => selectToothGroup(buttonState.tlb)} styles={{ root: { backgroundColor: buttonState.tlb.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
            </Stack>
            <Stack horizontalAlign="center">
                <StackItem><DefaultButton text="arcata superiore" style={{ margin: "3px 0" }} onClick={() => selectToothGroup(buttonState.a_sup)} styles={{ root: { backgroundColor: buttonState.a_sup.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                <StackItem><DefaultButton text="sestante 2" style={{ margin: "3px 0" }} onClick={() => selectToothGroup(buttonState.s_2)} styles={{ root: { backgroundColor: buttonState.s_2.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
            </Stack>
            <Stack horizontal>
                <Stack verticalAlign="space-around" horizontalAlign="center">
                    <StackItem><DefaultButton text="quadrante sup. destro" onClick={() => selectToothGroup(buttonState.q_sup_dx)} styles={{ root: { backgroundColor: buttonState.q_sup_dx.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="sestante 1" onClick={() => selectToothGroup(buttonState.s_1)} styles={{ root: { backgroundColor: buttonState.s_1.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="sestante 6" onClick={() => selectToothGroup(buttonState.s_6)} styles={{ root: { backgroundColor: buttonState.s_6.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="quadrante inf. destro" onClick={() => selectToothGroup(buttonState.q_inf_dx)} styles={{ root: { backgroundColor: buttonState.q_inf_dx.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                </Stack>
                <Stack>
                    <Teeth name={props.name} selectionMode={props.selectionMode} value={selected} state={props.state} onChange={onTeethSelectionChange} />
                </Stack>
                <Stack verticalAlign="space-around" horizontalAlign="center">
                    <StackItem><DefaultButton text="quadrante sup. sinistro" onClick={() => selectToothGroup(buttonState.q_sup_sx)} styles={{ root: { backgroundColor: buttonState.q_sup_sx.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="sestante 3" onClick={() => selectToothGroup(buttonState.s_3)} styles={{ root: { backgroundColor: buttonState.s_3.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="sestante 4" onClick={() => selectToothGroup(buttonState.s_4)} styles={{ root: { backgroundColor: buttonState.s_4.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                    <StackItem><DefaultButton text="quadrante inf. sinistro" onClick={() => selectToothGroup(buttonState.q_inf_sx)} styles={{ root: { backgroundColor: buttonState.q_inf_sx.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                </Stack>
            </Stack>
            <Stack horizontalAlign="center">
                <StackItem><DefaultButton text="sestante 5" style={{ margin: "3px 0" }} onClick={() => selectToothGroup(buttonState.s_5)} styles={{ root: { backgroundColor: buttonState.s_5.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
                <StackItem><DefaultButton text="arcata inferiore" style={{ margin: "3px 0" }} onClick={() => selectToothGroup(buttonState.a_inf)} styles={{ root: { backgroundColor: buttonState.a_inf.selected ? Theme.fluent.palette.themeLight : Theme.fluent.palette.white } }}></DefaultButton></StackItem>
            </Stack>
        </Stack>
    </>
}