import * as React from 'react';
import { useSelector } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';

import { Permission } from '../../../defs/permission';
import { ToolbarType } from '../../../defs/toolbars';
import { units } from '../../../features/security/thunks/securityThunk';
import Theme from '../../themes/Theme';

import TitleHeader from './headers/TitleHeader';
import FilterHeader from './headers/FilterHeader';
import ResultHeader from './headers/ResultHeader';
import ItemHeader from './headers/ItemHeader';
import GridHeader from './headers/GridHeader';

import IndexedHeader from './headers/IndexedHeader';

import { PrimaryButton, FontIcon, ICommandBarItemProps } from '@fluentui/react';

type Props = {
    children: React.ReactNode,
    title?: string,
    toolbar?: ToolbarType,
    controller?: string,
    parentController?: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
    readOnly?: boolean
    hidden?: boolean
    collapsable?: boolean
    farItems?: JSX.Element[]
    parentDatagridController?: string
    parentDatagridId?: string | number
}

export default (props: Props) => {

    const unitsContext = useSelector(units)

    const hasPermission = (permission: Permission): boolean => {
        if (!props.controller) return true;

        var permissions = unitsContext?.units.permissions
        if (!permissions) return false

        var controllerPermissions = permissions[props.controller.toLowerCase()]
        if (!controllerPermissions) return false;

        if (!controllerPermissions.find((cp: Permission) => cp == permission)) return false;

        return true
    }

    if (props.toolbar === "grid" && !hasPermission(Permission.Read)) {
        return <></>
    }

    var header;
    const [collapse, { toggle: toggleCollapse }] = useBoolean(props.collapsable ?? false);

    switch (props.toolbar) {
        case "filter":
            header = <FilterHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} />
            break;
        case "result":
            header = <ResultHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} />
            break;
        case "item":
            header = <ItemHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} />
            break;
        case "grid":
            header = <GridHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} modal={props.modal} modaltitle={props.modaltitle} modalwidth={props.modalwidth} state={props.state} readOnly={props.readOnly} farItems={props.farItems} parentDatagridController={props.parentDatagridController} parentDatagridId={props.parentDatagridId} />
            break;
        case "indexed":
            header = <IndexedHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} parentController={props.parentController ?? "UNSET"} modal={props.modal} modaltitle={props.modaltitle} modalwidth={props.modalwidth} state={props.state} readOnly={props.readOnly} />
            break;
        default:
            header = props.title ? <TitleHeader title={props.title} toolbar={props.toolbar} controller={props.controller ?? "UNSET"} /> : null
            break;
    }

    const collapseIconStyle: React.CSSProperties = {
        color: Theme.fluent.palette.themePrimary,
        cursor: "pointer", float: 'right',
        position: 'relative',
        top: collapse ? 42 : 46,
        zIndex: 100,
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        fontSize: '1.2em',
        padding: '0 6px',
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    }

    return <>
        {props.collapsable && !props.hidden && <FontIcon iconName={collapse ? "FlickRight" : "FlickUp"} onClick={toggleCollapse} style={collapseIconStyle} />}
        {!props.hidden && header}
        {!collapse && props.children}
    </>
};