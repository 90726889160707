import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DateRange from '../../components/controls/DateRange';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const startDate = useLeapData("startDate", "inizio ferie", page)
    const endDate = useLeapData("endDate", "fine ferie", page)
    const description = useLeapData("description", "descrizione", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...description} className="ms-sm12" />
                    </Row>
                    <Row>
                        <DateRange dateMin={{ ...startDate, className: "ms-sm5", required: true }} separator={{ className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm5", required: true }} />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
