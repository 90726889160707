import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import SearchBox from '../../components/controls/SearchBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const branchID = useLeapData("branchID", "branca", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServices")
    const feePerClaim = useLeapData("feePerClaim", "fee per claim", page)
    const serviceFee = useLeapData("serviceFee", "service fee", page)
    const insuranceCostOfClaim = useLeapData("insuranceCostOfClaim", "insurance cost of claim", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...branchID} className="ms-sm12" />
                    </Row>
                    <Row>
                        <SearchBox {...healthServiceID} className="ms-sm12" required />
                    </Row>
                    <Row>
                        <Text {...feePerClaim} className="ms-sm4" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                        <Text {...serviceFee} className="ms-sm4" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                        <Text {...insuranceCostOfClaim} className="ms-sm4" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
