import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
type Props = {
    state: any,
    controller: any,
    parentController: any
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)
    const parentPage = useLeapState(props.parentController, false)

    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONCH: boolean = isONHCMaster || isONHCAdmin || isONHCOperator || isONHCAccount

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = isHFMaster || isHFAdmin || isHFUser || isHFOperator

    const leap_QuoteManagementQuoteStateID = props.state.item?.state?.data?.leap_QuoteManagementQuoteStateID
    const isRefoundVisibleToHF = isHF && (leap_QuoteManagementQuoteStateID === "4" || leap_QuoteManagementQuoteStateID === "7")

    const canEditAmount = !!page.item?.state?.data?.freeRate

    const appointmentDate = useLeapData("appointmentDate", "data appuntamento", page)
    const branchDescr = useLeapData("branchDescr", "branca", page)
    const healthServiceID = useLeapData("healthServiceDescr", "prestazione", page)
    const amount = useLeapData("amount", "costo", page)
    const refound = useLeapData("refound", "rimborso", page)
    const erosion = useLeapData("erosion", "erosione", page)
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)
    const dentalElements = useLeapData("dentalElements", "elemento dentale", page)

    const voucherCode = useLeapData("voucherCode", "codice voucher", page)
    const voucherEndDate = useLeapData("voucherEndDate", "data scadenza voucher ", page)

    const isONHC = useSecurityRoles()?.isONHC

    const planType_item: string = parentPage.item?.state?.data?.leap_PlanManagementID;

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...appointmentDate} type="calendar" className="ms-sm3" />
                        <Text {...branchDescr} className="ms-sm9" readOnly />
                    </Row>
                    <Row>
                        <Text {...dentalElements} className="ms-sm3" readOnly value={page.item.values?.dentalElement?.value != null ? page.item.values.dentalElement?.value : dentalElement?.value} />
                        <Text {...healthServiceID} className="ms-sm9" readOnly />
                    </Row>
                    <Row>
                        <Text {...amount} className="ms-sm2" type="number" readOnly={!canEditAmount} required={canEditAmount} />
                        {(isONCH || isRefoundVisibleToHF) && !canEditAmount && <Text {...refound} className="ms-sm2" type="number" readOnly />}
                        {isONCH && !canEditAmount && <Text {...erosion} className="ms-sm2" type="number" readOnly />}
                    </Row>
                    {isONHC && planType_item === "2" && page.modality === "item" && <>
                        <Row>
                            <Text {...voucherCode} className="ms-sm3" maxlength={100} />
                            <Text {...voucherEndDate} className="ms-sm3" type="calendar" />
                        </Row>
                    </>}
                </Section>
            </Grid>
        </>
    );
}