import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../app/rootReducer'

import { ModalityType } from '../../defs/modalities'

interface CurrentModalityPayload {
    modalityType: ModalityType
}

const modalitySlice = createSlice({
    name: 'modality',
    initialState: { modalityType: "none" as ModalityType },
    reducers: {
        setModality: {
            reducer(state, action: PayloadAction<CurrentModalityPayload>) {
                const { modalityType } = action.payload
                state.modalityType = modalityType
            },
            prepare(modalityType: ModalityType) {
                return { payload: { modalityType: modalityType } }
            }
        },
    }
})

export const { setModality } = modalitySlice.actions

export const currentModality = (state: RootState) => state.modality.modalityType

export default modalitySlice.reducer