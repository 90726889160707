import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';
import { useBoolean } from '@uifabric/react-hooks';
import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import FileUpload from '../../components/controls/FileUpload';

import { useDispatch, useSelector } from 'react-redux';
import { security } from '../security/thunks/securityThunk';
import { itemDownload } from '../operation/thunks/itemThunk';
import { PrimaryButton, DialogType, Dialog, DialogFooter, FontSizes, FontWeights, IDialogProps, Icon } from '@fluentui/react';
import { AppDispatch } from '../../app/store';
import Theme from '../../components/themes/Theme';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { States } from '../../pages/HealthFacilityAffiliation';

type Props = { state: any, controller: any, currentState?: States }

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch()
    const controller = props.controller
    const credential = useSelector(security)?.credential

    const roles = useSecurityRoles()
    const isONHCPower = roles.isONHCMaster || roles.isONHCAccount

    const page = useLeapState(props.controller, false)

    const fileName = useLeapData("fileName", "nome file", page)
    const _signedFileID = useLeapData("signedFileID", "signedFileID", page)
    const _counterSignedFileID = useLeapData("counterSignedFileID", "signedFileID", page)

    const dialogStyles = { main: { maxWidth: 450 } };

    const [hideDialog, { toggle: toggleHideDialog, setFalse: showhideDialog }] = useBoolean(true);

    const dialogContentProps: IDialogProps = {
        hidden: hideDialog,
        onDismiss: toggleHideDialog,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            subText: "File non trovato",
            title: "Informazione",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-info"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    };


    const downloadFile = (fileID: any, fileName: string) => {
        const args = {
            controller: controller,
            fileID: fileID,
            fileName: fileName,
            //fileName: 
            token: credential.token
        }

        dispatch(itemDownload(args)).then((response) => {
            if (response.payload.size === 0) {
                toggleHideDialog()
            }
        });
    }

    const originalFileID = page?.item?.state?.data?.originalFileID;
    const originalFileNameExt = page?.item?.state?.data?.originalFileNameExt;

    const hasSigned = !!page?.item?.state?.data?.signedFileID;
    const signedFileID = page?.item?.state?.data?.signedFileID;
    const signedFileNameExt = page?.item?.state?.data?.signedFileNameExt;

    const hasCounterSigned = !!page?.item?.state?.data?.counterSignedFileID;
    const counterSignedFileID = page?.item?.state?.data?.counterSignedFileID;
    const counterSignedFileNameExt = page?.item?.state?.data?.counterSignedFileNameExt;

    const showCounterSign = props.currentState === States.CheckingDocumentation && isONHCPower

    return (
        <>
            <Dialog {...dialogContentProps} >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Chiudi" />
                </DialogFooter>
            </Dialog>
            <Grid>
                <Section>
                    <Row>
                        <Text {...fileName} className="ms-sm12" required readOnly={!isONHCPower} />
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        <Text
                            onChange={() => { }}
                            className="ms-sm6"
                            label="&nbsp;"
                            name=""
                            type="plainWithIcon"
                            value="file originale"
                            readOnly
                            onRenderSuffix={() => {
                                return <><Icon iconName="Download" onClick={() => downloadFile(originalFileID, originalFileNameExt)} style={{ cursor: "pointer", background: "transparent" }} /></>
                            }} />
                        {hasSigned &&
                            <Text
                                onChange={() => { }}
                                className="ms-sm6"
                                label="&nbsp;"
                                name=""
                                type="plainWithIcon"
                                value="file firmato"
                                readOnly
                                onRenderSuffix={() => {
                                    return <><Icon iconName="Download" onClick={() => downloadFile(signedFileID, signedFileNameExt)} style={{ cursor: "pointer", background: "transparent" }} /></>
                                }} />
                        }
                        {!hasSigned && <FileUpload {..._signedFileID} className="ms-sm6" label="carica file firmato" controller={props.controller} />}

                    </Row>
                    <br />
                    <Row>
                        {hasCounterSigned && showCounterSign &&
                            <Text
                                onChange={() => { }}
                                className="ms-sm6"
                                label="&nbsp;"
                                name=""
                                type="plainWithIcon"
                                value="file contro firmato"
                                readOnly
                                onRenderSuffix={() => {
                                    return <><Icon iconName="Download" onClick={() => downloadFile(counterSignedFileID, counterSignedFileNameExt)} style={{ cursor: "pointer", background: "transparent" }} /></>
                                }} />
                        }
                        {!hasCounterSigned && showCounterSign &&
                            <>
                                <FileUpload {..._counterSignedFileID} className="ms-sm6" label="carica file contro firmato" controller={props.controller} />
                            </>}
                    </Row>
                </Section>
            </Grid>
        </>
    );
}