import * as React from 'react';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { useDispatch, useSelector } from 'react-redux'; import { AppDispatch } from '../app/store';

import { security } from '../features/security/thunks/securityThunk';

import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';

import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Separator from '../components/controls/grid/Separator';

import Text from '../components/controls/Text';
import ComboBox from '../components/controls/ComboBox';
import Option from '../components/controls/Option';
import DateRange from '../components/controls/DateRange';
import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType, PivotItem, Pivot, IButtonProps, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import QuoteManagementStateButtons from '../features/quoteManagement/QuoteManagementStateButtons';

import QuoteManagementRODAndOODResultList from '../features/quoteManagement/QuoteManagementRODAndOODResultList';
//import QuoteManagementSSResultList from '../features/quoteManagement/QuoteManagementSSResultList';

import LogList from '../features/quoteManagement/LogList';
import SentFiles from '../features/quoteManagement/SentFiles';
import QuoteManagementFamilyList from '../features/quoteManagement/QuoteManagementFamilyList';
import QuoteManagementFamilyModal from '../features/quoteManagement/QuoteManagementFamilyModal';
import QuoteManagementFileModal from '../features/quoteManagement/QuoteManagementFileModal';
import QuoteManagementFileModalEdit from '../features/quoteManagement/QuoteManagementFileModalEdit';
import QuoteManagementFiles from '../features/quoteManagement/QuoteManagementFiles';
import QuoteManagementHealthServiceList from '../features/quoteManagement/QuoteManagementHealthServiceList';
import QuoteManagementHealthServiceModal from '../features/quoteManagement/QuoteManagementHealthServiceModal';
import QuoteManagementHealthServiceModalEdit from '../features/quoteManagement/QuoteManagementHealthServiceModalEdit';
import QuoteManagementHistoryList from '../features/quoteManagement/QuoteManagementHistoryList';
import QuoteManagementHistoryModal from '../features/quoteManagement/QuoteManagementHistoryModal';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { setResponse } from '../features/response/responseSlice';
import { useState } from 'react';
import Theme from '../components/themes/Theme';
import { useSecurityRoles } from '../hooks/useSecurityRoles';
import SearchBox from '../components/controls/SearchBox';
import { itemForm, itemPrint } from '../features/operation/thunks/itemThunk';
import HFBlockingModal from '../features/quoteManagement/HFBlockingModal';
import { FontFaces } from '../defs/fonts';
import { setFormFilter } from '../features/operation/thunks/filterThunk';
import { setModality } from '../features/modality/modalitySlice';
import { setOperation } from '../features/operation/operationSlice';
import CoveragePlanList from '../features/insured/CoveragePlanList';
import { isLocked, lock, unlock } from '../features/operation/lockSlice';
import { useDatagridOperation } from '../hooks/useDatagridOperation';

export const createNewPatient = createAsyncThunk(
    'item/createNewPatient',
    async (args: { form: any, token: string | undefined }) => {
        const response = await client.post("QuoteManagement", { form: args.form }, 'NewPatient', args.token)
        return response
    }
)

export default () => {
    const _isLocked = useSelector(isLocked)
    const roles = useSecurityRoles()
    const isONHCMaster: boolean = roles.isONHCMaster;
    const isONHCAdmin: boolean = roles.isONHCAdmin;
    const isONHCOperator: boolean = roles.isONHCOperator;
    const isONHCAccount: boolean = roles.isONHCAccount;
    const isONHC: boolean = roles.isONHC;

    const isHFMaster: boolean = roles.isHFMaster;
    const isHFAdmin: boolean = roles.isHFAdmin;
    const isHFUser: boolean = roles.isHFUser;
    const isHFOperator: boolean = roles.isHFOperator;
    const isHF = roles.isHF;

    const isInsuredUser: boolean = roles.isInsuredUser;

    const controller = "QuoteManagement"
    const page = useLeapState(controller, roles.role !== undefined, isInsuredUser ? "result" : "filter")

    const quoteManagementFileModalController = "QuoteManagementFileModal"
    const quoteManagementFamilyController = "QuoteManagementHealthServiceFamily"
    const quoteManagementHealthServiceController = "QuoteManagementHealthService"
    const quoteManagementHistoryController = "QuoteManagementHistory"

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token
    const datagridOperation = useDatagridOperation("Reservation", token)
    const datagridOperationDental = useDatagridOperation("DentalHistory", token)


    const leap_QuoteManagementHistoricizationStateID = useLeapData("leap_QuoteManagementHistoricizationStateID", "stato storicizzazione", page, "quoteManagementHistoricizationStateOption")
    const leap_QuoteManagementRefundID = useLeapData("leap_QuoteManagementRefundID", "rimborsi", page, "quoteManagementRefundsOption")

    const startDate = useLeapData("startDate", "data dal", page)
    const endDate = useLeapData("endDate", "data al", page)

    //const fiscalCode = useLeapData("insuredFiscalcode", "codice fiscale", page)

    //const lastname = useLeapData("lastnamePersonal", "cognome", page)
    //const firstname = useLeapData("firstnamePersonal", "nome", page)
    //const leap_QuoteManagementPrivacyConsentID = useLeapData("leap_QuoteManagementPrivacyConsentID", "consenso privacy", page, "quoteManagementPrivacyConsentOption")

    const quoteManagementNumber = useLeapData("quoteManagementNumber", "numero piano di cure", page)
    const leap_PlanManagementID = useLeapData("leap_PlanManagementID", "tipo gestione piano sanitario", page, "planManagementOption")
    const newPatientFiscalcode = useLeapData("newPatientFiscalcode", "cf nuovo paziente", page)

    const leap_DocumentationRequiredID = useLeapData("leap_DocumentationRequiredID", "documentazione obbligatoria", page, "documentationRequiredOption")
    const leap_DocumentationVerificationID = useLeapData("leap_DocumentationVerificationID", "stato di verifica della documentazione", page, "documentationVerificationOption")

    const leap_InsuredInvoiceVerificationID = useLeapData("leap_InsuredInvoiceVerificationID", "stato di verifica della fattura assistito", page, "documentationVerificationOption")

    const healthFacilityID = useLeapData("healthFacilityID", "codice struttura sanitaria", page, "healthFacilities")
    const healthPlanID = useLeapData("healthPlanID", "piano sanitario", page, "healthPlan")
    const healthFacilityName = useLeapData("healthFacilityName", "struttura sanitaria", page)
    const pi_cf_HealthFacility = useLeapData("pi_cf_HealthFacility", "pi-cf struttura sanitaria", page)

    const plafondID = useLeapData("plafondID", "plafond infortunio/malattia", page, "plafonds")
    const plafondDescr = useLeapData("plafondDescr", "plafond infortunio/malattia", page)

    //assistito
    const insuredID = useLeapData("insuredID", "assistito", page, "insureds")
    const registration_number = useLeapData("registration_number", "matricola", page)
    const corporationID = useLeapData("corporationID", "azienda assistito", page, "corporations")
    const corporationDescr = useLeapData("corporationDescr", "azienda assistito", page)

    const planID = useLeapData("planDescr", "piano sanitario", page)
    const fundPlanID = useLeapData("fundPlanID", "piano sanitario", page, "fundPlans")
    const welfarePlanID = useLeapData("welfarePlanID", "piano sanitario", page, "welfarePlans")

    const insuredFiscalcode = useLeapData("insuredFiscalcode", "cf assistito", page)
    const lastnamePersonal = useLeapData("lastnamePersonal", "cognome anagrafico", page)
    const firstnamePersonal = useLeapData("firstnamePersonal", "nome anagrafico", page)

    const note = useLeapData("note", "note", page)
    const voucherCost = useLeapData("costVoucher", "valore prestazioni a voucher", page)
    const voucherDiscount = useLeapData("discountVoucher", "sconto applicato", page)
    const estimatedCost = useLeapData("estimatedCost", "totale a carico assistito", page)
    const leap_QuoteManagementQuoteStateID = useLeapData("leap_QuoteManagementQuoteStateID", "stato piano di cure", page, "quoteManagementQuoteStateOption")
    const leap_QuoteManagementQuoteStateDescr = useLeapData("leap_QuoteManagementQuoteStateDescr", "stato piano di cure", page)

    //periodo di cura
    const processing = useLeapData("processing", "data invio in lavorazione", page)
    const authorization = useLeapData("authorization", "data di autorizzazione", page)
    const startTreatment = useLeapData("startTreatment", "data inizio cura", page)
    const endTreatment = useLeapData("endTreatment", "data fine cura", page)

    const creationDate = useLeapData("creationDate", "data di creazione", page)
    const lastChange = useLeapData("lastChange", "data ultima modifica", page)
    const lastSubmissions = useLeapData("lastSubmissions", "data ultimo invio", page)

    //pagamento
    const codeVoucher = useLeapData("codeVoucher", "codice voucher", page)
    const endVoucher = useLeapData("endVoucher", "data scadenza voucher ", page)
    const importVoucher = useLeapData("importVoucher", "valore voucher ", page)

    const numberInvoice = useLeapData("numberInvoice", "numero fattura", page)
    const dateInvoice = useLeapData("dateInvoice", "data fattura", page)
    const paymentDate = useLeapData("paymentDate", "data pagamento", page)

    const numberInsuredInvoice = useLeapData("numberInsuredInvoice", "numero fattura", page)
    const dateInsuredInvoice = useLeapData("dateInsuredInvoice", "data fattura", page)
    const importInsuredInvoice = useLeapData("importInsuredInvoice", "importo fattura", page)

    const quoteManagementHealthServices = useLeapData("quoteManagementHealthServices", "", page)
    const quoteManagementNoVoucher = useLeapData("quoteManagementNoVoucher", "", page)
    const quoteManagementDeleted = useLeapData("quoteManagementDeleted", "", page)

    const beneficiaryLastname = useLeapData("beneficiaryLastname", "cognome", page)
    const beneficiaryFirstname = useLeapData("beneficiaryFirstname", "nome", page)
    const beneficiaryFiscalcode = useLeapData("beneficiaryFiscalcode", "codice fiscale", page)
    const leap_QuoteManagementFamilyKinshipDegreeID = useLeapData("leap_QuoteManagementFamilyKinshipDegreeID", "grado parentela", page, "quoteManagementFamilyKinshipDegrees")

    const hasHealthServicesToApprove = useLeapData("hasHealthServicesToApprove", "stato richiesta variazione", page)

    const beneficiaryHiddenIfEmpy = (!page?.item?.state?.data?.beneficiaryLastname &&
        !page?.item?.state?.data?.beneficiaryFirstname &&
        !page?.item?.state?.data?.beneficiaryFiscalcode &&
        !page?.item?.state?.data?.leap_QuoteManagementFamilyKinshipDegreeID) && isInsuredUser

    const qmStateID: string = page.item?.state?.data?.leap_QuoteManagementQuoteStateID;
    const isHFDisabled = isHF && qmStateID !== "1" && qmStateID !== "2"

    function eval_readOnly(item: string) {
        //1. In Bozza, 2. In Lavorazione
        if (item == null || item === "" || item === "1" || item === "2" || item === "3" || item === "4" || item === "7")
            return false;
        return true;
    }
    function eval_readOnlyBeneficiary(item: string) {
        //1. In Bozza, 2. In Lavorazione , 3. Inviato
        if ((item === "1" || item === "2" || item === "3") && (isONHC || isHF))
            return false;
        return true;
    }    
    const readOnly_check: boolean = eval_readOnly(qmStateID);
    const readOnlyBeneficiary: boolean = eval_readOnlyBeneficiary(qmStateID);

    function eval_planType(item: string, state: string, planType?: string) {
        if (!planType) {
            planType = "2"
        }

        //1. Fondo, 2. Welfare
        if (item) {
            //item has value and is 2
            if (item === planType) return true;
        }
        else {
            //item is undefined and state is 2
            if (state === planType) return true;
        }
        return false;
    }
    const planType_item: string = page.item?.values?.leap_PlanManagementID?.value;
    const planType_state: string = page?.item?.state?.data?.leap_PlanManagementID;
    const planType_check: boolean = eval_planType(planType_item, planType_state);

    const isFundPlan: boolean = eval_planType(planType_item, planType_state, "1");
    const isWelfarePlan: boolean = eval_planType(planType_item, planType_state, "2");

    const hasTwoPlanManagement: boolean = ((page.item.state?.lookups.planManagementOption?.length ?? 0) == 2);
    const hasOneFundPlan: boolean = ((page.item.state?.lookups.fundPlans?.length ?? 0) < 3);
    const hasOneWelfarePlan: boolean = ((page.item.state?.lookups.welfarePlans?.length ?? 0) < 3);
    const hasOneCorporation: boolean = ((page.item.state?.lookups.corporations?.length ?? 0) < 3);

    const hasPlafonds: boolean = ((page.item.state?.lookups.plafonds?.length ?? 0) > 0);
    const hasPlafond: boolean = ((page?.item?.state?.data?.plafondDescr?.length ?? 0) > 0);

    const _hasHealthServicesToApprove = !!page.item.state?.data?.hasHealthServicesToApprove;

    function eval_payment(plan: string, state: string) {
        //leap_PlanManagementID === "Fondo" && leap_QuoteManagementQuoteStateID === "Fine Cure"
        //|| leap_PlanManagementID === "Welfare" && leap_QuoteManagementQuoteStateID === "Autorizzato"
        if (((plan === "1") && (state === "7"))
            || ((plan === "2") && (state === "4")))
            return true;
        return false;
    }
    const quoteState_item: string = page.item?.values?.leap_QuoteManagementQuoteStateID?.value;
    const payment_check: boolean = eval_payment(planType_item, quoteState_item);

    const planManagementID = page.item?.state?.data?.leap_PlanManagementID
    const quoteManagementQuoteStateID = page.item?.state?.data?.leap_QuoteManagementQuoteStateID

    const isInvoiceHidden = quoteManagementQuoteStateID === "1" || quoteManagementQuoteStateID === "2" || quoteManagementQuoteStateID === "3" || (planManagementID === "1" && quoteManagementQuoteStateID === "4")
    const isVoucherReadonly = planManagementID === "2" && !(quoteManagementQuoteStateID === "1" || quoteManagementQuoteStateID === "2" || quoteManagementQuoteStateID === "3")

    const isInsuredInvoiceHidden = quoteManagementQuoteStateID !== "7" || page.item?.state?.data?.leap_PlanRefundFeeID === "1"

    const isReadonly = (isHF && (quoteManagementQuoteStateID === "2" || quoteManagementQuoteStateID === "3")) || quoteManagementQuoteStateID === "7"
    const showDocumentationVerification = isONHC && quoteManagementQuoteStateID === "7" && page.item?.values?.leap_DocumentationRequiredID?.value === "1"

    const paid = page.item?.values?.paymentDate?.value !== null

    const onClickNewPatient = (e: any) => {
        e.preventDefault()
        toggleHideDialog()
    }
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Nuovo Paziente',
        closeButtonAriaLabel: 'Chiudi',
    };

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles
        }),
        [labelId, subTextId, dialogStyles],
    );

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential

    const iForm = useSelector(itemForm)
    const form = iForm.find(i => i.key === controller)?.value

    const onExecuteNewPatient = (e: any) => {
        toggleHideDialog()
        dispatch(lock())
        dispatch(createNewPatient({ form: { ...form, insuredFiscalcode: page.filter?.values?.newPatientFiscalcode?.value }, token: credential.token }))
            .then((response: any) => {
                dispatch(unlock())
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
                return response
            }).then((response) => {
                if (response.payload.response.level === 4) {
                    page.onchange('insuredID', response.payload.data, isHF)
                }
                return response
            }).then((response) => {
                if (response.payload.response.level === 4) {
                    dispatch(setOperation("new", controller))
                }
            })
    }

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = useState("insured")
    const PivotButtonProps: IButtonProps = {
        style: {
            borderColor: Theme.fluent.palette.white,
            borderRadius: 0,
            borderStyle: "solid",
            borderLeftWidth: 1,
            borderTopWidth: 1,
            transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
            verticalAlign: "bottom"
        },
        styles: {
            root: {
                backgroundColor: Theme.fluent.palette.themeTertiary,
                color: Theme.fluent.palette.neutralLighter,
                height: 38,
                marginTop: 12,
                minWidth: "12%",
            },
            rootDisabled: {
                backgroundColor: Theme.fluent.palette.themePrimary,
                color: Theme.fluent.palette.white,
                height: 42,
                marginTop: 8,
                minWidth: "15%",
            },
            rootHovered: {
                backgroundColor: Theme.fluent.palette.themeLighter,
            },
            rootPressed: {
                backgroundColor: Theme.fluent.palette.themeLight,
            }
        }
    }

    const PrintButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const noDetailAccessLabel: ILabelStyles = {
        root: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            textAlign: "center",
            whiteSpace: "nowrap"
        }
    }

    const onClickPrint = () => {
        dispatch(lock())
        dispatch(itemPrint({ controller, lookup: "print", body: { form: form, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    const canPrint = !isInsuredUser && (
        quoteManagementQuoteStateID == "1" ||
        quoteManagementQuoteStateID == "2" ||
        quoteManagementQuoteStateID == "3" ||
        quoteManagementQuoteStateID == "4" ||
        quoteManagementQuoteStateID == "7"
    )

    const navigateToReservation = () => {
        dispatch(lock())
        datagridOperation.navigate({ id: Number.parseInt(page.item?.state?.data?.reservationID) })
            .then((response) => {
                document.location.href = '/reservation/?item'
            })
            .then(() => dispatch(unlock()))
    }
    const navigateToDentalHistory = () => {
        dispatch(lock())
        datagridOperationDental.navigate({ id: Number.parseInt(page.item?.state?.data?.insuredID.key), quoteid: Number.parseInt(page.item?.state?.data?.id) })
            .then((response) => {
                document.location.href = '/DentalHistory/?item'
            })
            .then(() => dispatch(unlock()))
    }

    const hasReservation = !!page.item?.state?.data?.reservationID

    const canInsuredView = quoteManagementQuoteStateID === undefined || !isInsuredUser || (isInsuredUser && (quoteManagementQuoteStateID === "4" || quoteManagementQuoteStateID === "7"));
    const canViewInsuredBillingMessage = (isONHC || isHF) && page.item?.state?.data?.leap_PlanRefundFeeID == "2";
    const documentationRequiredEnabled = isONHC && (quoteManagementQuoteStateID === "1" || quoteManagementQuoteStateID === "2" || quoteManagementQuoteStateID === "3" || quoteManagementQuoteStateID === "4")

    return <>
        < Content title="Piani di Cure" controller={controller} >
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm4" hidden={!isONHC && !isHFMaster} />
                            <Text {...pi_cf_HealthFacility} className="ms-sm2" hidden={!isONHC} />
                            <SearchBox {...healthPlanID} className="ms-sm4" hidden={!isONHC} />
                            <Option {...leap_PlanManagementID} type="horizontal" className="ms-sm2" hidden={!isONHC} />
                        </Row>
                        <Row>
                            <SearchBox {...insuredID} className="ms-sm6" required={isHF} />
                            <Text {...registration_number} className="ms-sm2" hidden={!isONHC} />
                            {isHF && (<>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 18 }}>
                                    <PrimaryButton text="Nuovo Paziente" onClick={onClickNewPatient} disabled={_isLocked} />
                                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} >
                                        <Text {...newPatientFiscalcode} className="ms-sm10" />
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteNewPatient} text="Nuovo Paziente" disabled={_isLocked} />
                                            <DefaultButton onClick={toggleHideDialog} text="Annulla" disabled={_isLocked} />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>)}
                        </Row>
                        <Row>
                            <Text {...quoteManagementNumber} className="ms-sm2" hidden={isInsuredUser} readOnly={isInsuredUser} />
                            <ComboBox {...leap_QuoteManagementHistoricizationStateID} hidden={isInsuredUser} className="ms-sm2" />
                            <ComboBox {...leap_QuoteManagementQuoteStateID} className="ms-sm2" />
                            <ComboBox {...leap_QuoteManagementRefundID} hidden={isInsuredUser} className="ms-sm2" />
                            <ComboBox {...leap_DocumentationVerificationID} className="ms-sm3" hidden={!isONHC} />
                        </Row>
                        <Row>
                            <DateRange dateMin={{ ...startDate, className: "ms-sm2" }} dateMax={{ ...endDate, className: "ms-sm2" }} />
                            <Option {...hasHealthServicesToApprove} className="ms-sm4" type="horizontal" hidden={!isONHC && !isHF} options={[{ key: "", text: "tutti" }, { key: "1", text: "in attesa di approvazione" }]} />
                            <ComboBox {...leap_InsuredInvoiceVerificationID} className="ms-sm3" hidden={!isONHC} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <QuoteManagementRODAndOODResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        {canInsuredView && (page.modality === "item" || page.modality === "new") && <HFBlockingModal />}

                        {canInsuredView && page.modality === "item" && <>
                            <div className="ms-Grid-col ms-sm8" style={{ height: 50 }}>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "insured"} onClick={() => setPivotItemSelectedKey("insured")}>dati anagrafici</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "healthservices"} onClick={() => setPivotItemSelectedKey("healthservices")}>piano di cure</DefaultButton>
                                <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "files"} onClick={() => setPivotItemSelectedKey("files")}>documentazione</DefaultButton>
                            </div>
                            <div className="ms-Grid-col ms-sm12" style={{ paddingTop: 22, textAlign: "right" }}>
                                {isONHC && <PrimaryButton text="vai allo storico bocca" onClick={navigateToDentalHistory} />}
                                {!isInsuredUser && hasReservation && <PrimaryButton text="vai alla prenotazione" onClick={navigateToReservation} />}
                                {canPrint && !_hasHealthServicesToApprove && <PrimaryButton {...PrintButtonProps} onClick={onClickPrint} disabled={_isLocked}>stampa</PrimaryButton>}
                            </div>
                        </>}
                        {!canInsuredView && <div className="ms-Grid-col ms-sm12">
                            <Label styles={noDetailAccessLabel}>la consultazione del dettaglio del piano di cure è possibile solo per stato Autorizzato o Fine Cure</Label>
                        </div>}
                        {canViewInsuredBillingMessage && <>
                            <div className="ms-Grid-col ms-sm12" style={{ textAlign: "center" }}>
                                <span style={{ borderColor: Theme.fluent.palette.themeSecondary, borderStyle: "solid", borderWidth: 2, display: "inline-block", marginTop: 18, padding: "4px 12px" }}>
                                    <Label style={{ color: Theme.fluent.palette.themePrimary, fontSize: "1.1em", fontWeight: 600 }}>ATTENZIONE</Label>
                                    <Label styles={noDetailAccessLabel}>Per questo piano di cura anche le quote di rimborso andranno fatturate all’assistito</Label>
                                    <Label styles={noDetailAccessLabel}>A fine cure, se presenti quote di rimborso, si prega di consultare il Fac-Simile di fattura scaricabile dal menù Fatturazione Assistito</Label>
                                    <Label styles={noDetailAccessLabel}>e procedere con la registrazione e l’upload della fattura emessa per richiederne il rimborso</Label>
                                </span>
                            </div>
                        </>}
                    </Section>
                    <Pivot selectedKey={pivotItemSelectedKey}>
                        <PivotItem headerText="dati anagrafici" itemKey="insured">
                            {page.modality === "item" && (
                                <Section title=" ">
                                    <Row>
                                        <Text {...leap_QuoteManagementQuoteStateDescr} className="ms-sm2" readOnly />
                                    </Row>
                                </Section>
                            )}
                            <Section title={page.modality === "item" ? "piano di cure" : ""}>
                                <Row>
                                    {page.modality === "item" && <Text {...quoteManagementNumber} className="ms-sm3 ms-xxl2" readOnly />}
                                    {canInsuredView && <>
                                        <Option {...leap_PlanManagementID} type="horizontal" className="ms-sm3 ms-xxxl2" required={page.modality === "new" && hasTwoPlanManagement} disabled={page.modality === "item"} hidden={page.modality !== "item" && !hasTwoPlanManagement || isInsuredUser} />
                                        <Option {...leap_DocumentationRequiredID} type="horizontal" className="ms-sm3 ms-xxxl2" hidden={page.modality !== "item" || isInsuredUser} disabled={!documentationRequiredEnabled} />
                                        <Option {...leap_DocumentationVerificationID} type="horizontal" className="ms-sm2" hidden={!showDocumentationVerification} disabled={paid && !isONHCMaster && !isONHCAccount} />
                                    </>}
                                </Row>
                                <Row>
                                    {!isInsuredUser && page.modality === "item" && <>
                                        <Text {...estimatedCost} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />
                                        <Separator className="ms-sm1 ms-hiddenXxlUp" />
                                    </>}
                                    {isONHC && planType_item === "2" && page.modality === "item" && <>
                                        <Text {...voucherCost} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />
                                        <Separator className="ms-sm1 ms-hiddenXxlUp" />
                                        <Text {...voucherDiscount} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} />
                                    </>}
                                </Row>
                                <Row hidden={!isONHC}>
                                    {hasPlafonds && isONHC && <ComboBox {...plafondID} className="ms-sm6" hidden={page.modality === "item"} />}
                                    {hasPlafond && isONHC && <Text {...plafondDescr} className="ms-sm9" hidden={page.modality !== "item"} readOnly />}
                                </Row>
                                <Row hidden={isInsuredUser}>
                                    <Text {...note} type="multiline" className="ms-sm9" />
                                </Row>
                            </Section>
                            {canInsuredView && <Section title="struttura sanitaria">
                                <Row>
                                    {!isInsuredUser && <SearchBox {...healthFacilityID} className="ms-sm3 ms-xxl2" required disabled={page.modality === "item"} children={[{ ...leap_PlanManagementID }, { ...fundPlanID }, { ...welfarePlanID }, { ...corporationID }]} />}
                                    {page.modality === "item" && (
                                        <>
                                            <Text {...healthFacilityName} className="ms-sm6 ms-xxl5" readOnly />
                                            <Text {...pi_cf_HealthFacility} className="ms-sm2" readOnly />
                                        </>
                                    )}
                                </Row>
                            </Section>}
                            {canInsuredView && <Section title="assistito">
                                <Row>
                                    <SearchBox {...insuredID} className="ms-sm6" required disabled={page.modality === "item"} children={[{ ...leap_PlanManagementID }, { ...fundPlanID }, { ...welfarePlanID }, { ...corporationID }, { ...plafondID }]} />
                                </Row>
                                <Row hidden={page.modality === "new" || isInsuredUser}>
                                    <Text {...planID} className="ms-sm5" readOnly />
                                </Row>
                                <Row hidden={page.modality === "item" || !isFundPlan || hasOneFundPlan}>
                                    <ComboBox {...fundPlanID} className="ms-sm5" required={page.modality === "new" && isFundPlan && !hasOneFundPlan} />
                                </Row>
                                <Row hidden={page.modality === "item" || !isWelfarePlan || hasOneWelfarePlan}>
                                    <ComboBox {...welfarePlanID} className="ms-sm5" required={page.modality === "new" && isWelfarePlan && !hasOneWelfarePlan} children={[{ ...corporationID }]} />
                                </Row>
                                {page.modality === "item" && (<>
                                    <Row>
                                        <Text {...insuredFiscalcode} className="ms-sm3 ms-xxl2" readOnly />
                                        <Text {...lastnamePersonal} className="ms-sm3" readOnly />
                                        <Text {...firstnamePersonal} className="ms-sm3" readOnly />
                                    </Row>
                                </>)}
                                <Row hidden={page.modality === "item" || !isWelfarePlan || hasOneCorporation}>
                                    <ComboBox {...corporationID} className="ms-sm5" required={page.modality === "new" && isWelfarePlan && !hasOneCorporation} disabled={page.modality === "item"} />
                                </Row>
                                <Row hidden={!isWelfarePlan || page.modality === "new"}>
                                    <Text {...corporationDescr} className="ms-sm5" readOnly />
                                </Row>
                            </Section>}
                            {canInsuredView && page.modality === "item" && planType_item === "2" && <Section title="beneficiario" hidden={beneficiaryHiddenIfEmpy}>
                                <Row hidden={beneficiaryHiddenIfEmpy}>
                                    <Text {...beneficiaryLastname} className="ms-sm3" readOnly={readOnlyBeneficiary} hidden={beneficiaryHiddenIfEmpy} />
                                    <Text {...beneficiaryFirstname} className="ms-sm3" readOnly={readOnlyBeneficiary} hidden={beneficiaryHiddenIfEmpy} />
                                </Row>
                                <Row hidden={beneficiaryHiddenIfEmpy}>
                                    <Text {...beneficiaryFiscalcode} className="ms-sm3" readOnly={readOnlyBeneficiary} hidden={beneficiaryHiddenIfEmpy} />
                                    <ComboBox {...leap_QuoteManagementFamilyKinshipDegreeID} className="ms-sm2" disabled={readOnlyBeneficiary} hidden={beneficiaryHiddenIfEmpy} />
                                </Row>
                            </Section>}
                            {canInsuredView && page.modality === "item" && (<>
                                <Section title="periodo di cura">
                                    <Row>
                                        <Text {...creationDate} className="ms-sm2" type="calendar" readOnly hidden={!isInsuredUser} />
                                        <Text {...processing} className="ms-sm2" type="calendar" readOnly />
                                        <Text {...lastSubmissions} className="ms-sm2" type="calendar" hidden={!isWelfarePlan || isInsuredUser || isHFAdmin || isHFMaster || isHFOperator || isHFUser} readOnly />
                                        <Text {...authorization} className="ms-sm2" type="calendar" readOnly />
                                        <Text {...endTreatment} className="ms-sm2" type="calendar" readOnly hidden={!isInsuredUser} />
                                        <DateRange dateMin={{ ...startTreatment, className: "ms-sm2", readOnly: readOnly_check }} dateMax={{ ...endTreatment, className: "ms-sm2", readOnly: true }} hidden={isInsuredUser} />
                                    </Row>
                                    <Row>
                                        <DateRange dateMin={{ ...creationDate, className: "ms-sm2", readOnly: true }} dateMax={{ ...lastChange, className: "ms-sm2", readOnly: true }} hidden={isInsuredUser} />
                                    </Row>
                                </Section>
                                <Section title="pagamento" hidden={!planType_check && (isInsuredUser || isInvoiceHidden) || isHF}>
                                    <Row hidden={!planType_check || isHF}>
                                        <Text {...codeVoucher} className="ms-sm4" readOnly={readOnly_check || !isONHC} type="multiline" />
                                    </Row>
                                    <Row hidden={!planType_check || isHF}>
                                        <Text {...endVoucher} className="ms-sm2" type="calendar" readOnly={readOnly_check || !isONHC} />
                                        <Text {...importVoucher} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly={readOnly_check || isInsuredUser} hidden={isHF} />
                                    </Row>
                                    <Row hidden={isInsuredUser || isInvoiceHidden || isHF}>
                                        <Text {...numberInvoice} className="ms-sm2" readOnly />
                                        <Text {...dateInvoice} className="ms-sm2" type="calendar" dateRange={{ maxDate: Date().toLocaleString() }} readOnly />
                                        <Text {...paymentDate} className="ms-sm2" type="calendar" hidden={isInsuredUser} readOnly />
                                    </Row>
                                </Section>
                                <Section title="fatturazione assistito" hidden={isInsuredInvoiceHidden || !isONHC}>
                                    <Row hidden={isInsuredInvoiceHidden || !isONHC}>
                                        <Text {...numberInsuredInvoice} className="ms-sm2" readOnly />
                                        <Text {...dateInsuredInvoice} className="ms-sm2" type="calendar" readOnly />
                                        <Text {...importInsuredInvoice} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />
                                    </Row>
                                </Section>
                            </>
                            )}
                            {canInsuredView && page.modality === "item" &&
                                <Section title="log" hidden={isInsuredUser}>
                                    <Row hidden={isInsuredUser}>
                                        <LogList items={page.item.state?.lists.log} />
                                    </Row>
                                    <Row hidden={isInsuredUser || isHF}>
                                        &nbsp;
                                    </Row>
                                    <Row hidden={isInsuredUser || isHF}>
                                        &nbsp;
                                    </Row>
                                    {Object.keys(page.item.state?.lists.quoteManagementSentFileList ?? {}).length > 0 &&
                                        (<Row hidden={isInsuredUser || isHF}>
                                            <SentFiles items={page.item.state?.lists.quoteManagementSentFileList} controller={controller} />
                                        </Row>
                                        )}
                                </Section>
                            }
                        </PivotItem>

                        {canInsuredView && page.modality === "item" && (
                            <PivotItem headerText="dati prestazioni" itemKey="healthservices">
                                <Section title=" ">
                                    <Row>
                                        <Text {...leap_QuoteManagementQuoteStateDescr} className="ms-sm2" readOnly />
                                        {!isInsuredUser && <QuoteManagementStateButtons controller={controller} itemData={page.item?.state?.data} />}
                                    </Row>
                                    <Row hidden={!isONHC}>
                                        {hasPlafond && isONHC && <Text {...plafondDescr} className="ms-sm9" hidden={page.modality !== "item"} readOnly />}
                                    </Row>
                                </Section>
                                <SearchBox {...insuredID} className="" hidden />
                                <SearchBox {...healthFacilityID} className="" hidden />
                                <SearchBox {...planID} className="" hidden />
                                <Option {...leap_PlanManagementID} type="horizontal" className="" hidden />
                                <Section title="elenco prestazioni" toolbar={readOnly_check || isVoucherReadonly || isReadonly || isHFDisabled ? undefined : "grid"} modal={<QuoteManagementHealthServiceModal state={page} controller={quoteManagementHealthServiceController} parentController={controller} />} modalwidth="800px" controller={quoteManagementHealthServiceController} modaltitle="Inserisci una prestazione" state={page} >
                                    <Row>
                                        <QuoteManagementHealthServiceList {...quoteManagementHealthServices} readonly={readOnly_check} listType='healthServices' items={page.item.state?.lists.healthServices} modal={<QuoteManagementHealthServiceModalEdit state={page} controller={quoteManagementHealthServiceController} parentController={controller} />} controller={quoteManagementHealthServiceController} modalwidth="800px" modaltitle="Modifica una Prestazione" state={page} />
                                    </Row>
                                </Section>
                                {planType_item === "1" && <Section>
                                    <Row>
                                        <Text {...estimatedCost} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />
                                    </Row>
                                </Section>}
                                {planType_item === "2" && <Section>
                                    <Row hidden={!isONHC}>
                                        <Text {...voucherCost} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly hidden={isHF} />
                                        <Text {...voucherDiscount} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} hidden={isHF} />
                                    </Row>
                                </Section>}
                                {planType_item === "2" && page.item.state?.lists.healthServicesNoVoucher?.length > 0 && <>
                                    {
                                        !isHF &&
                                        <Section title="elenco prestazioni non coperte da voucher" >
                                            <Row>
                                                <QuoteManagementHealthServiceList {...quoteManagementNoVoucher} readonly={readOnly_check} listType='novoucher' items={page.item.state?.lists.healthServicesNoVoucher} modal={<QuoteManagementHealthServiceModalEdit state={page} controller={quoteManagementHealthServiceController} parentController={controller} />} controller={quoteManagementHealthServiceController} modaltitle="Modifica una Prestazione" state={page} />
                                            </Row>
                                        </Section>
                                    }
                                    <Section>
                                        <Row>
                                            <Text {...estimatedCost} className="ms-sm2" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />
                                        </Row>
                                    </Section>
                                </>}
                                <Section title="elenco prestazioni eliminate" hidden={isInsuredUser}>
                                    <Row>
                                        <QuoteManagementHealthServiceList {...quoteManagementDeleted} readonly={readOnly_check} listType='deleted' items={page.item.state?.lists.healthServicesDeleted} modal={<QuoteManagementHealthServiceModalEdit state={page} controller={quoteManagementHealthServiceController} parentController={controller} />} controller={quoteManagementHealthServiceController} modaltitle="Modifica una Prestazione" state={page} />
                                    </Row>
                                </Section>
                                <Section title="elenco piani sanitari fondo" hidden={!isONHC}>
                                    <Row hidden={!isONHC}>
                                        <CoveragePlanList readOnly items={page.item.state?.lists.coveragePlan} controller="" />
                                    </Row>
                                </Section>
                                <Section title="elenco piani sanitari welfare" hidden={!isONHC}>
                                    <Row hidden={!isONHC}>
                                        <CoveragePlanList readOnly items={page.item.state?.lists.coverageWelfare} controller="" />
                                    </Row>
                                </Section>
                                <Section title="elenco storico prestazioni" hidden={!isONHC} collapsable={page.item.state?.lists?.quoteManagementHistory?.length > 0}>
                                    <Row hidden={!isONHC}>
                                        <QuoteManagementHistoryList items={page.item.state?.lists.quoteManagementHistory} navigateToController={controller} state={page} />
                                    </Row>
                                </Section>
                            </PivotItem>
                        )}

                        {page.modality === "item" && (
                            <PivotItem headerText="files" itemKey="files">
                                <Section title="elenco files caricati" toolbar="grid" modal={<QuoteManagementFileModal state={page} controller={quoteManagementFileModalController} />} controller={quoteManagementFileModalController} modaltitle="Inserisci un documento" state={page} modalwidth="40vw">
                                    <Row>
                                        <QuoteManagementFiles items={page.item.state?.lists.files} modal={<QuoteManagementFileModalEdit state={page} controller={quoteManagementFileModalController} />} controller={quoteManagementFileModalController} modaltitle="Modifica un documento" modalwidth="40vw" state={page} />
                                    </Row>
                                </Section>
                            </PivotItem>
                        )}
                    </Pivot>
                </Grid>
            </Item>
        </Content >
    </>
};