import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import { security } from '../features/security/thunks/securityThunk';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

import { useId, useBoolean } from '@uifabric/react-hooks';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import Option from '../components/controls/Option';

import RateTableResultList from '../features/rateTable/RateTableResultList';

import RateTableHealthFacilityList from '../features/rateTable/RateTableHealthFacilityList';
import RateTableHealthFacilityModal from '../features/rateTable/RateTableHealthFacilityModal'
import RateTableHealthFacilityModalEdit from '../features/rateTable/RateTableHealthFacilityModalEdit'
import RateTableHealthServiceAssociateList from '../features/rateTable/RateTableHealthServiceAssociateList';
import RateTableHealthServiceAssociateModal from '../features/rateTable/RateTableHealthServiceAssociateModal'
import RateTableHealthServiceAssociateModalEdit from '../features/rateTable/RateTableHealthServiceAssociateModalEdit'
import RateTableHealthServiceHistoryList from '../features/rateTable/RateTableHealthServiceHistoryList';
import RateTableHealthServiceHistoryModal from '../features/rateTable/RateTableHealthServiceHistoryModal';

import { PrimaryButton, Dialog, DialogFooter, DefaultButton, DialogType, Icon } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { setResponse } from '../features/response/responseSlice';
import { setOperation } from '../features/operation/operationSlice';
import { itemData, itemDownload, itemForm, setFormItem } from '../features/operation/thunks/itemThunk';
import SearchBox from '../components/controls/SearchBox';
import { filterForm } from '../features/operation/thunks/filterThunk';
import MultiSearchBox from '../components/controls/MultiSearchBox';

export const disableRateTable = createAsyncThunk(
    'item/disableRateTable',
    async (args: { rateTableID: string, token: string | undefined }) => {
        const response = await client.post("RateTable", { form: { id: args.rateTableID } }, 'Disable', args.token)
        return response
    }
)
export const enableRateTable = createAsyncThunk(
    'item/enableRateTable',
    async (args: { rateTableID: string, token: string | undefined }) => {
        const response = await client.post("RateTable", { form: { id: args.rateTableID } }, 'Enable', args.token)
        return response
    }
)
export default () => {
    const controller = "RateTable"
    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential

    const page = useLeapState(controller)
    const roles = useSecurityRoles()

    const rateTableHealthFacilityController = "RateTableHealthFacility";
    const rateTableHealthServiceAssociateController = "RateTableHealthServiceAssociate";

    function eval_rateTableType(item: string, state: string) {
        //1. Massimale, 2. SS, 3. Piano Sanitario
        if (item) {
            //item has value and is 2
            if (item === "2") return true;
        }
        else {
            //item is undefined and state is 2
            if (state === "2") return true;
        }
        return false;
    }
    const rateTableType_item: string = page.item?.values?.leap_RateTableTypeID?.value;
    const rateTableType_state: string = page?.item?.state?.data?.leap_RateTableTypeID;
    const rateTableType_check: boolean = eval_rateTableType(rateTableType_item, rateTableType_state);
    const isSS = page.item?.values?.leap_RateTableTypeID?.value === "2";

    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")
    const code = useLeapData("code", "codice", page)
    const endDate = useLeapData("endDate", "data fine validità", page)
    const name = useLeapData("name", "nome", page)
    const leap_RateTableManagementID = useLeapData("leap_RateTableManagementID", "tipo gestione", page, "rateTableManagementOption")
    const leap_RateTableTypeID = useLeapData("leap_RateTableTypeID", "tipo tariffario", page, "rateTableTypeOption")
    const leap_RateTableStateID = useLeapData("leap_RateTableStateID", "attivo", page, "rateTableStateOption")

    const branchID = useLeapData("branchID", "branche", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazioni", page, "healthServices")

    const rateTableHealthServiceAssociate_empty = page?.item?.state?.lists?.RateTableHealthServiceAssociate === null || page?.item?.state?.lists?.RateTableHealthServiceAssociate?.length === 0
    const rateTableHealthServiceHistory_empty = page?.item?.state?.lists?.RateTableHealthServiceHistory === null || page?.item?.state?.lists?.RateTableHealthServiceHistory?.length === 0

    function eval_active(ratetable_state: string) {
        if (ratetable_state) {

            if (ratetable_state === "1") return true;
        }

        return false;
    }

    //function eval_enddate(endDate: string) {
    //    if (!endDate) {
    //        return true;
    //    }
    //    if (new Date(endDate).getTime() > new Date().getTime()) {
    //        return true;
    //    }

    //    return false;
    //}

    const today = new Date();
    const isExpired = () => {
        if (page?.item?.state?.data?.endDate === undefined || page?.item?.state?.data?.endDate === null) {
            return false;
        }
        if (today <= new Date(page?.item?.state?.data?.endDate)) {
            return false;
        }
        return true;
    }
    const expired: boolean = isExpired();

    const ratetable_state: string = page?.item?.state?.data?.leap_RateTableStateID;
    const rateTableState_check: boolean = eval_active(ratetable_state);
    //const enddate_val: string = page?.item?.state?.data?.endDate;
    //const rateTableEndDate_check: boolean = eval_enddate(enddate_val);

    const dialogStyles = { main: { maxWidth: 450 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla disattivazione del tariffario?',
    };
    const dialogContentPropsAb = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo alla riattivazione del tariffario?',
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const onClickDisabilita = (e: any) => {
        e.preventDefault()
        toggleHideDialog()
    }
    const iForm = useSelector(itemData)

    const onExecuteDisabilita = (e: any) => {
        toggleHideDialog()
        //alert(iForm.find(i => i.key === controller)?.value.data.id)
        dispatch(disableRateTable({ rateTableID: iForm.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then((response: any) => {
                dispatch(setOperation("reload", controller))
                return response
            }).then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
    }

    const [hideDialogAb, { toggle: toggleHideDialogAb }] = useBoolean(true);

    const onClickAbilita = (e: any) => {
        e.preventDefault()
        toggleHideDialogAb()
    }

    const onExecuteAbilita = (e: any) => {
        toggleHideDialogAb()
        //alert(iForm.find(i => i.key === controller)?.value.data.id)
        dispatch(enableRateTable({ rateTableID: iForm.find(i => i.key === controller)?.value.data.id, token: credential.token }))
            .then((response: any) => {
                dispatch(setOperation("reload", controller))
                return response
            }).then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
    }

    const rateID = iForm.find(i => i.key === controller)?.value?.data?.id
    const rateTableCode = page?.item?.state?.data?.code

    const downloadFile = (methodName: string, fileDescription: string) => {
        client.downloadPost(controller, { form: { id: rateID, fileName: `${fileDescription} ${rateTableCode}.csv` } }, methodName, undefined, credential.token)
    }

    const downloadRatesFile = () => {
        client.downloadPost(controller, {
            form: {
                id: rateID,
                fileName: `Costo Prestazioni.csv`,
                branchID: page?.filter?.values?.branchID?.value,
                healthServiceID: page?.filter?.values?.healthServiceID?.value,
            }
        }, 'DownloadHealthFacilitiesAndBranchesCSV', undefined, credential.token)
    }

    const isONHC: boolean = roles.isONHC;

    return <>
        <Content title="Tariffario" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm2" />
                            <Option {...leap_RateTableStateID} type="horizontal" className="ms-sm2" />
                        </Row>
                        <Row>
                            <Option {...leap_RateTableManagementID} type="horizontal" className="ms-sm2" />
                            <Option {...leap_RateTableTypeID} type="horizontal" className="ms-sm3" />
                        </Row>
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm5" />
                        </Row>
                    </Section>

                    {/*TODO: DATAEXTRACTION */}
                    {false && <Section toolbar="filter" title="estrazione dati">
                        <Row>
                            <MultiSearchBox {...branchID} className="ms-sm12" itemLimit={99} />
                        </Row>
                        <Row>
                            <MultiSearchBox {...healthServiceID} className="ms-sm12" itemLimit={99} />
                        </Row>
                        <Row>
                            <Text
                                onChange={() => { }}
                                className="ms-sm2"
                                label="elenco prestazioni"
                                name=""
                                type="plainWithIcon"
                                value="scarica elenco prestazioni"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={downloadRatesFile} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                        </Row>
                    </Section>}
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <RateTableResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" required={page.modality === "new"} readOnly={page.modality !== "new"} />
                            <Option {...leap_RateTableStateID} type="horizontal" className="ms-sm2" disabled />
                            {page.modality === "item" && rateTableState_check && (<>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 18 }} hidden={!roles.isSYS || roles.isONHCMaster} >
                                    <PrimaryButton text="Disabilita" onClick={onClickDisabilita} />
                                    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps} >
                                        <DialogFooter >
                                            <PrimaryButton onClick={onExecuteDisabilita} text="Disabilita" />
                                            <DefaultButton onClick={toggleHideDialog} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>)}
                            {page.modality === "item" && (!rateTableState_check) && (<>
                                <div className="ms-Grid-col ms-sm2" style={{ paddingTop: 18 }}>
                                    <PrimaryButton text="Abilita" onClick={onClickAbilita} />
                                    <Dialog hidden={hideDialogAb} onDismiss={toggleHideDialogAb} dialogContentProps={dialogContentPropsAb} modalProps={modalProps} >
                                        <DialogFooter>
                                            <PrimaryButton onClick={onExecuteAbilita} text="Abilita" />
                                            <DefaultButton onClick={toggleHideDialogAb} text="Annulla" />
                                        </DialogFooter>
                                    </Dialog>
                                </div>
                            </>)}
                        </Row>
                        <Row>
                            <Text {...name} className="ms-sm4" required />
                            <Text {...endDate} className="ms-sm2" type="calendar" />
                        </Row>
                        <Row>
                            <Option {...leap_RateTableManagementID} type="horizontal" className="ms-sm2" disabled={page.modality !== "new"} />
                            <Option {...leap_RateTableTypeID} type="horizontal" className="ms-sm3" required disabled={page.modality !== "new"} />
                        </Row>
                    </Section>
                    <Section toolbar="filter" title="estrazione dati">
                        <Row>
                            <Text
                                onChange={() => { }}
                                className="ms-sm2"
                                label="base"
                                name=""
                                type="plainWithIcon"
                                value="scarica allegato base"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile("DownloadBaseCSV", "Tariffario")} style={{ cursor: "pointer", background: "transparent" }} /></> }} />

                            <Text
                                onChange={() => { }}
                                className="ms-sm2"
                                label="elenco prestazioni"
                                name=""
                                type="plainWithIcon"
                                value="scarica elenco prestazioni"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile("DownloadHealthServiceCSV", "Prestazioni")} style={{ cursor: "pointer", background: "transparent" }} /></> }} />

                            <Text
                                hidden={!isSS}
                                onChange={() => { }}
                                className="ms-sm2"
                                label="elenco SS"
                                name=""
                                type="plainWithIcon"
                                value="scarica elenco SS"
                                readOnly
                                onRenderSuffix={() => { return <><Icon iconName="Download" onClick={() => downloadFile("DownloadHealthFacilityCSV", "SS")} style={{ cursor: "pointer", background: "transparent" }} /></> }} />
                        </Row>
                    </Section>

                    {page.modality === "item" && rateTableType_check && (<>
                        <Section title="elenco strutture sanitarie" toolbar="grid" modal={<RateTableHealthFacilityModal state={page} controller={rateTableHealthFacilityController} />} controller={rateTableHealthFacilityController} modalwidth="600px" modaltitle="Inserisci una struttura sanitaria" state={page} readOnly={expired || !rateTableState_check}>
                            <Row>
                                <RateTableHealthFacilityList items={page.item.state?.lists.RateTableHealthFacility} modal={<RateTableHealthFacilityModalEdit state={page} controller={rateTableHealthFacilityController} />} controller={rateTableHealthFacilityController} modalwidth="600px" modaltitle="Modifica la struttura sanitaria" state={page} />
                            </Row>
                        </Section>
                    </>)}

                    {page.modality === "item" && (<>
                        <Section hidden={(roles.isHFUser || roles.isHFOperator || roles.isHFAdmin || roles.isHFMaster) && rateTableHealthServiceAssociate_empty} title="elenco prestazioni associate" toolbar="grid" modal={<RateTableHealthServiceAssociateModal state={page} controller={rateTableHealthServiceAssociateController} />} controller={rateTableHealthServiceAssociateController} parentController={controller} modalwidth="800px" modaltitle="Inserisci una prestazione associata" state={page} >
                            <Row hidden={(roles.isHFUser || roles.isHFOperator || roles.isHFAdmin || roles.isHFMaster) && rateTableHealthServiceAssociate_empty}>
                                <RateTableHealthServiceAssociateList items={page.item.state?.lists.RateTableHealthServiceAssociate} modal={<RateTableHealthServiceAssociateModalEdit state={page} controller={rateTableHealthServiceAssociateController} />} controller={rateTableHealthServiceAssociateController} modalwidth="800px" modaltitle="Modifica la prestazione associata" state={page} />
                            </Row>
                        </Section>
                    </>)}

                    {page.modality === "item" && (<Section hidden={(roles.isONHCMaster || roles.isONHCAccount || roles.isHFUser || roles.isHFOperator || roles.isHFAdmin || roles.isHFMaster) && rateTableHealthServiceHistory_empty} title="elenco storico prestazioni">
                        <Row hidden={(roles.isONHCMaster || roles.isONHCAccount || roles.isHFUser || roles.isHFOperator || roles.isHFAdmin || roles.isHFMaster) && rateTableHealthServiceHistory_empty}>
                            <RateTableHealthServiceHistoryList items={page.item.state?.lists.RateTableHealthServiceHistory} modal={<RateTableHealthServiceHistoryModal state={page} controller={rateTableHealthServiceAssociateController} />} controller={rateTableHealthServiceAssociateController} modalwidth="800px" modaltitle="Riattiva la prestazione associata" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
