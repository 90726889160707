import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import PlanPurchaseQuoteList from './PlanPurchaseQuoteList';
import PlanPurchaseQuoteModal from './PlanPurchaseQuoteModal';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';
import Separator from '../../components/controls/grid/Separator';

import Text from '../../components/controls/Text';
import MultiBox from '../../components/controls/MultiBox';
import Option from '../../components/controls/Option';
import FileUploader from '../../components/controls/FileUploader';

type Props = {
    state: any,
    controller: any,
    isFundPlan: any
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const PurchaseQuoteModalController = "PlanPurchaseQuote"

    const description = useLeapData("description", "descrizione", page)
    const welcomeMessage = useLeapData("welcomeMessage", "messaggio di benvenuto", page)
    const link = useLeapData("link", "link di accesso", page)
    const membershipFee = useLeapData("membershipFee", "valore quota associativa", page)
    const duration = useLeapData("duration", "durata mesi", page)
    const docsID = useLeapData("docsID", "documenti", page, "docs")
    const leap_PlanPurchaseMembershipFeeID = useLeapData("leap_membershipFee", "quota associativa", page, "leap_membershipFee")
    const leap_PlanPurchaseCorporationID = useLeapData("leap_corporation", "richiesta azienda", page, "leap_corporation")
    const leap_PlanPurchaseCorporationRequiredID = useLeapData("leap_corporationRequired", "azienda obbligatoria", page, "leap_corporationRequired")
    const filePrivacy = useLeapData("filePrivacy", "informativa privacy", page)
    const privacyText = useLeapData("privacyText", "testo privacy", page)
    const consentText = useLeapData("consentText", "testo consenso", page)

    const linkRoot = window.location.origin;
    const linkFolder = "/planPurchasePublic/"
    const linkItem = page?.item?.values?.link?.value

    const show_corporation = (item: string) => {
        if (item) {
            if (item === "1") {
                return true;
            }
        }
        return false;
    }
    const corporation_item: string = page.item?.values?.leap_corporation?.value;
    const corporation_required: boolean = show_corporation(corporation_item);

    const show_membershipFee = (item: string) => {
        if (item) {
            if (item === "1") {
                return true;
            }
        }
        return false;
    }
    const membershipFee_item: string = page.item?.values?.leap_membershipFee?.value;
    const membershipFee_required: boolean = show_membershipFee(membershipFee_item);

    const linkLabel = linkRoot + linkFolder + linkItem + "/"

    const viewQuotes = !!page?.item?.state?.data?.id && membershipFee_required

    return <>
        <Grid>
            <Section>
                <Row>
                    <Text {...link} className="ms-sm12" />
                </Row>
                {!!linkItem && <Row>
                    <div className="ms-Grid-col ms-sm12">
                        {linkLabel}
                    </div>
                </Row>}
                <Row>
                    <Text type="multiline" rows={5} {...welcomeMessage} className="ms-sm12" maxlength={1000} />
                </Row>
                <Row>
                    <Text type="multiline" rows={5} {...description} className="ms-sm12" maxlength={1000} />
                </Row>
                <Row>
                    <MultiBox {...docsID} className="ms-sm12" titleType="text" />
                </Row>
                <Row>
                    <Option {...leap_PlanPurchaseCorporationID} className="ms-sm4 ms-xxl4" type="horizontal" required />
                    <Option {...leap_PlanPurchaseCorporationRequiredID} className="ms-sm4 ms-xxl4" type="horizontal" hidden={(!corporation_required)} />
                </Row>
                <Row>
                    <Text {...duration} className="ms-sm2" type="number" />
                    <Separator className="ms-sm2" />
                    <Option {...leap_PlanPurchaseMembershipFeeID} className="ms-sm5" type="horizontal" required />
                    <Text {...membershipFee} className="ms-sm3" type="number" precision={{ integer: 10, decimal: 2 }} hidden={(!membershipFee_required)} />
                </Row>
                <Row>
                    <FileUploader {...filePrivacy} className="ms-sm12" controller={props.controller} />
                </Row>
                <Row>
                    <Text type="multiline" rows={5} {...privacyText} className="ms-sm12" />
                </Row>
                <Row>
                    <Text type="multiline" rows={5} {...consentText} className="ms-sm12" />
                </Row>
            </Section>
            {viewQuotes && <>
                <Section modalwidth="800px" title="configurazione modalità di acquisto" toolbar="grid" modal={<PlanPurchaseQuoteModal state={page} controller={PurchaseQuoteModalController} />} controller={PurchaseQuoteModalController} modaltitle="modifica configurazione modalità di acquisto" state={page} parentDatagridController={props.controller} parentDatagridId={page?.item?.state?.data?.id}>
                    <Row>
                        <PlanPurchaseQuoteList items={page.item.state?.lists.planPurchaseQuoteList} modal={<PlanPurchaseQuoteModal state={page} controller={PurchaseQuoteModalController} />} modalwidth="800px" controller={PurchaseQuoteModalController} state={page} parentDatagridController={props.controller} parentDatagridId={page?.item?.state?.data?.id} />
                    </Row>
                </Section>
            </>}
        </Grid>
    </>
}