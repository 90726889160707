import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import Text from '../../components/controls/Text';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const position = useLeapData("position", "posizione", page)
    const title = useLeapData("titleF", "titolo", page)

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...title} className="ms-sm10" required/>
                        <Text {...position} className="ms-sm2" type='number' required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
