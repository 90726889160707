import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { iStateThunks } from "../../../defs/thunks"

export const addBuilderClean = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload

        let data = state.data.map(d => d.key === key ? { key: key, value: value } : d)
        if (!data.find(d => d.key === key)) {
            data.push({ key: key, value: value })
        }

        let form = state.form.map(f => f.key === key ? { key: key, value: value.data } : f)
        if (!form.find(f => f.key === key)) {
            form.push({ key: key, value: value.data })
        }

        let validation = state.validation.map(d => d.key === key ? { key: key, value: value.metadata.validation } : d)
        if (!validation.find(d => d.key === key)) {
            validation.push({ key: key, value: value.metadata.validation })
        }

        state.data = data
        state.form = form
        state.validation = validation
        state.response = { message: value.response.message, level: value.response.level }
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}

export const addBuilderMin = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload

        let data = state.data.map(d => d.key === key ? { key: key, value: value } : d)
        if (!data.find(d => d.key === key)) {
            data.push({ key: key, value: value })
        }

        let validation = state.validation.map(d => d.key === key ? { key: key, value: value.metadata.validation } : d)
        if (!validation.find(d => d.key === key)) {
            validation.push({ key: key, value: value.metadata.validation })
        }

        state.data = data
        state.validation = validation
        state.response = { message: value.response.message, level: value.response.level }
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}

export const addBuilderFull = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload
        let data = state.data

        if (value.response.level && value.response.level > -1 && value.response.level !== 4) {
            const controllerForm = state.form.find(f => f.key === key)?.value
            value.data = { ...controllerForm }
        } else {
            data = state.data.map(d => d.key === key ? { key: key, value: value } : d)
        }

        if (!data.find(d => d.key === key)) {
            data.push({ key: key, value: value })
        }

        let form = state.form.map(f => f.key === key ? { key: key, value: value.data } : f)
        if (!form.find(f => f.key === key)) {
            form.push({ key: key, value: value.data })
        }

        let validation = state.validation.map(d => d.key === key ? { key: key, value: value.metadata.validation } : d)
        if (!validation.find(d => d.key === key)) {
            validation.push({ key: key, value: value.metadata.validation })
        }

        for (const [cPage, iPage] of Object.entries(value.metadata.pages)) {
            if (data.find(d => d.key === cPage)) {
                data = data.map(d => d.key === cPage ? { key: cPage, value: { metadata: { page: iPage } } } : d)
            } else {
                data.push({ key: cPage, value: { metadata: { page: iPage } } })
            }
        }

        state.data = data
        state.form = form
        state.validation = validation
        state.response = { message: value.response.message, level: value.response.level }
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}
export const addBuilderLookup = (builder: ActionReducerMapBuilder<iStateThunks>, thunkMethod: any) => {
    builder.addCase(thunkMethod.pending, (state, action) => {
        state.status = 'loading'
        state.response = { message: '', level: -1 }
    })
    builder.addCase(thunkMethod.fulfilled, (state, action) => {
        state.status = 'succeeded'

        let key = action.meta.arg.controller
        let value = action.payload

        let form = state.form.find(d => d.key === key)
        if (!form) {
            return;
        }
        let data = state.data.find(d => d.key === key)
        if (!data) {
            return;
        }

        var lookup = action.meta.arg.lookup
        data.value.lookups[lookup] = value.lookups?.[lookup]

        var target = action.meta.arg.target
        data.value.data = form.value
        data.value.data[target] = value.data?.[target] ?? data.value.data[target] ?? ""

        let stateData = state.data.map(d => d.key === key ? { key: key, value: { ...d.value, data: { ...data?.value.data } } } : d)

        state.data = stateData
    })
    builder.addCase(thunkMethod.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
    })
}