import * as React from 'react';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DentalChart from '../../components/controls/dental/DentalChart';
import { DentalElements } from '../../components/controls/dental/dentalElements';
import SearchBox from '../../components/controls/SearchBox';
import MultiBox from '../../components/controls/MultiBox';
import { IComboBoxOption } from '@fluentui/react';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthService")
    const dentalElement = useLeapData("dentalElement", "elementi dentali", page)
    const rate = useLeapData("rate", "tariffa", page)
    const endDate = useLeapData("endDate", "data fine validità", page)

    const items: IComboBoxOption[] = DentalElements
    const selectedTeeth = page.item.values?.dentalElement !== undefined ? page.item.values.dentalElement?.value : dentalElement?.value !== null ? dentalElement?.value : [];

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...healthServiceID} className="ms-sm10" required />
                    </Row>
                    <Row>
                        <Text {...rate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} required />
                        <Text {...endDate} className="ms-sm2" type="calendar" />
                    </Row>
                    <Row>
                        <MultiBox {...dentalElement} className="ms-sm10" options={items} selectedKey={selectedTeeth} />
                    </Row>
                </Section>
                <Section title="elementi dentali">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Row>
                            <DentalChart {...dentalElement} selectionMode="multiple" value={selectedTeeth} />
                        </Row>
                    </div>
                </Section>
            </Grid>
        </>
    );
}
