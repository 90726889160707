import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';

import Text from '../components/controls/Text';
import ComboBox from '../components/controls/ComboBox';
import Option from '../components/controls/Option';

import DiscountResultList from '../features/discount/DiscountResultList';

import DiscountDetailList from '../features/discount/DiscountDetailList';
import DiscountDetailModal from '../features/discount/DiscountDetailModal';

export default () => {
    const controller = "Discount"
    const page = useLeapState(controller)

    const discountDetailController = "DiscountDetail"

    const code = useLeapData("code", "codice", page)
    const name = useLeapData("name", "nome", page)
    const leap_DiscountFrequencyID = useLeapData("leap_DiscountFrequencyID", "frequenza", page, "discountFrequencyOption")
    const duration = useLeapData("duration", "durata (in mesi)", page)
    const leap_DiscountAgeID = useLeapData("leap_DiscountAgeID", "età", page, "discountAgeOption")
    const minAge = useLeapData("minAge", "età minima", page)
    const maxAge = useLeapData("maxAge", "età massima", page)
    const leap_DiscountCategoryID = useLeapData("leap_DiscountCategoryID", "categoria di sconto", page, "discountCategories")
    const leap_DiscountQuoteTypologyID = useLeapData("leap_DiscountQuoteTypologyID", "tipologia quote", page, "discountQuoteTypologies")

    const leap_DiscountTimesNumberTypeID = useLeapData("leap_DiscountTimesNumberTypeID", "tipo", page, "discountTimesNumberTypes")
    const timesNumber = useLeapData("timesNumber", "numero di volte", page)
    const fixedAmount = useLeapData("fixedAmount", "importo fisso", page)
    const fixedQuote = useLeapData("fixedQuote", "quota fissa", page)
    const odQuote = useLeapData("odQuote", "quota od", page)

    //Age
    const ageID = page.item.values.leap_DiscountAgeID?.value
    const minAge_value = page.item.values.minAge?.value
    const maxAge_value = page.item.values.maxAge?.value
    const minAge_show = ageID === "2" || ageID === "4"
    const maxAge_show = ageID === "3" || ageID === "4"

    //documentazione obbligatoria
    const leap_DocumentationRequiredID = useLeapData("leap_DocumentationRequiredID", "documentazione obbligatoria", page, "documentationRequiredOption")

    //DiscountCategory
    const frequencyID = page.item.values.leap_DiscountFrequencyID?.value
    const numberTypeID = page.item.values.leap_DiscountTimesNumberTypeID?.value
    const categoryID = page.item.values.leap_DiscountCategoryID?.value
    const quoteTypologyID = page.item.values.leap_DiscountQuoteTypologyID?.value

    const duration_show = frequencyID === "1"
    const quoteTypologyID_show = (frequencyID !== "3" && categoryID === "1") || categoryID === "2"

    //DiscountDetail
    const discountDetail_datagrid =
        (frequencyID !== "3") && (categoryID === "1" || categoryID === "2" || categoryID === "5" || categoryID === "6")

    const discountDetail_fields =
        (frequencyID === "3" && categoryID === "2") ||
        (frequencyID !== "3" && (categoryID === "3" || categoryID === "4" || categoryID === "7"))

    const timesNumberType = discountDetail_fields && !(frequencyID === "3" && categoryID === "2")

    const timesNumber_show = discountDetail_fields && numberTypeID === "2"
    const fixedQuote_show = frequencyID === "3" && categoryID === "2"
    const fixedAmount_show = categoryID === "7"
    const odQuote_show = frequencyID === "3" && categoryID === "1"

    const isDiscountPermanent = page.item.state?.lists?.discountDetail?.find((dd: any) => dd.leap_DiscountTimesNumberTypeID === "Sempre")
    const gridInsertAllow = page.item.state?.lists?.discountDetail?.length < 5 ? (isDiscountPermanent ? undefined : "grid") : undefined

    const quoteMax = quoteTypologyID === 2 ? 100 : undefined

    return <>
        <Content title="Sconti" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...code} className="ms-sm2" type="number" precision={{ integer: 6, decimal: 0, min: 0 }} />
                            <Text {...name} className="ms-sm6" />
                        </Row>
                        <Row>
                            <ComboBox {...leap_DiscountCategoryID} className="ms-sm8" />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <Row>
                            <DiscountResultList controller={controller} items={page.result?.state?.data} />
                        </Row>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...code} className="ms-sm2" type="number" readOnly precision={{ integer: 6, decimal: 0, min: 0 }} />
                            <Text {...name} className="ms-sm6" required maxlength={50} />
                        </Row>
                        <Row>
                            <ComboBox {...leap_DiscountFrequencyID} className="ms-sm2" required disabled={page.modality === "item"} />
                            <Text {...duration} className="ms-sm2 ms-xxl1" type="number" hidden={!duration_show} required={duration_show} precision={{ integer: 6, decimal: 0, min: 0, max: 96 }} />
                        </Row>
                        <Row>
                            <ComboBox {...leap_DiscountAgeID} className="ms-sm2 ms-xxl1" required />
                            <Text {...minAge} className="ms-sm2 ms-xxl1" type="number" hidden={!minAge_show} required={minAge_show} precision={{ integer: 3, decimal: 0, min: 0, max: maxAge_value ? Number(maxAge_value) : 120 }} />
                            <Text {...maxAge} className="ms-sm2 ms-xxl1" type="number" hidden={!maxAge_show} required={maxAge_show} precision={{ integer: 3, decimal: 0, min: minAge_value ? Number(minAge_value) : 0, max: 120 }} />
                        </Row>
                        <Row>
                            <Option {...leap_DocumentationRequiredID} required type="horizontal" className="ms-sm2" />
                        </Row>
                    </Section>
                    <Section title="categorie di sconto">
                        <Row>
                            <ComboBox {...leap_DiscountCategoryID} className="ms-sm4" required disabled={page.modality === "item"} />
                            <Option {...leap_DiscountQuoteTypologyID} className="ms-sm4" type="horizontal" hidden={!quoteTypologyID_show} required={quoteTypologyID_show} disabled={page.modality === "item"} />
                            <Option {...leap_DiscountTimesNumberTypeID} className="ms-sm2" type="horizontal" hidden={!timesNumberType} required={timesNumberType} />
                            <Text {...timesNumber} className="ms-sm1" type="number" hidden={!timesNumber_show} required={timesNumber_show} precision={{ integer: 9, decimal: 0, min: 0 }} />
                        </Row>
                    </Section>
                    <Section title="dettaglio sconto" hidden={!fixedAmount_show && !odQuote_show}>
                        <Row hidden={!fixedAmount_show}>
                            <Text {...fixedAmount} className="ms-sm2" type="number" required={fixedAmount_show} precision={{ integer: 9, decimal: 2, min: 0 }} />
                        </Row>
                        <Row hidden={!fixedQuote_show}>
                            <Text {...fixedQuote} className="ms-sm2" type="number" required={fixedQuote_show} precision={{ integer: 9, decimal: 2, min: 0, max: quoteMax }} />
                        </Row>
                        <Row hidden={!odQuote_show}>
                            <Text {...odQuote} className="ms-sm2" type="number" required={odQuote_show} precision={{ integer: 9, decimal: 2, min: 0, max: quoteMax }} />
                        </Row>
                    </Section>
                    {page.modality === "item" && discountDetail_datagrid && (
                        <Section title="dettaglio sconto" toolbar={gridInsertAllow} modal={<DiscountDetailModal state={page} controller={discountDetailController} />} controller={discountDetailController} modaltitle="Inserisci uno Sconto" modalwidth="500px" state={page} >
                            <Row>
                                <DiscountDetailList items={page.item.state?.lists.discountDetail} modal={<DiscountDetailModal state={page} controller={discountDetailController} />} controller={discountDetailController} modaltitle="Modifica un importo" modalwidth="500px" state={page} />
                            </Row>
                        </Section>
                    )}
                </Grid>
            </Item>
        </Content>
    </>
};
