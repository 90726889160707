import * as React from 'react';
import { Image, PrimaryButton, ImageFit, ActionButton, IIconProps, FontSizes, mergeStyleSets } from '@fluentui/react/';
import { useSecurityRoles } from '../hooks/useSecurityRoles';

import ReservationBlockingModal from '../features/healthFacility/ReservationBlockingModal';
import { Permission } from '../defs/permission';
import { security, units } from '../features/security/thunks/securityThunk';
import { useDispatch, useSelector } from 'react-redux';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';


export const Welfare2024Download = createAsyncThunk(
    'item/Welfare2024Download',
    async (args: { token?: string | undefined }) => {
        const response = await client.downloadPost("Files", { form: { fileName: "Welfare2024.pdf" }, page: {} }, 'Welfare2024', args.token)
        return response
    }
)

export default () => {

    const dispatch: AppDispatch = useDispatch();

    const roles = useSecurityRoles()
    const isHF = roles.isHF;
    const credential = useSelector(security)?.credential

    if (roles.isHFAffiliation) {
        document.location.href = '/HealthFacilityAffiliation/'
    }

    const unitsContext = useSelector(units)

    const canViewMagazine = (): boolean => {
        var permissions = unitsContext?.units.permissions
        if (!!!permissions) return false

        var controllerPermissions = permissions["magazine"]
        if (!!!controllerPermissions) return false;

        if (!controllerPermissions.find((cp: Permission) => cp == Permission.Read)) return false;

        return true
    }

    const welfare2024Download = () => {
        if (!!!isHF) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(Welfare2024Download({ token: credential.token }))
    }

    const magazineContainerStyle: Partial<React.CSSProperties> = {
        textAlign: "right"
    }
    const magazineButtonStyle: Partial<React.CSSProperties> = {
        //color: "#4b858e",
        cursor: "pointer",
        fontFamily: "Montserrat",
        fontSize: FontSizes.large,
        minWidth: 166,
        paddingTop: 12
    }
    const newsIcon: IIconProps = {
        iconName: 'News',
        style: {
            color: "#ff8200",
            fontSize: FontSizes.xLargePlus,
        }
    };

    const contentStyles = mergeStyleSets({
        container: {
            maxHeight: "80vh",
            padding: "30px 0 20px",
            width: 800
        },
        content: {
            overflowY: "visible",
        },
        header: {
            color: Theme.fluent.palette.themePrimary,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.xxLarge,
            fontVariant: 'small-caps',
            fontWeight: 600,
            padding: "10px 0 0",
            textAlign: "center"
        },
        body: {
            fontFamily: FontFaces.openSans,
            fontSize: 16,
            overflowY: "visible",
            padding: "30px 32px 24px",
            textAlign: "center",
            selectors: {
                p: { margin: '16px 0 0' },
                'span.orange': { color: "#ff8200", fontWeight: 500 },
                'span.download': { color: "#ff8200", cursor: "pointer", fontWeight: 500, textDecoration: "underline" },
            },
        },
        primaryButton: {
            padding: "0 0 50px",
            textAlign: "center",
            selectors: {
                button: { backgroundColor: "#ff8200", borderColor: "#ff8200", borderRadius: 4, color: Theme.fluent.palette.white, fontWeight: 600, width: 200 },
                'button:active': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" },
                'button:hover': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" }
            },
        },
        standardButton: {
            padding: "0 32px",
            textAlign: "right",
            selectors: {
                button: { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", borderRadius: 4, color: "#ff8200", fontSize: "0.7em", margin: '8px 0', width: 180 },
                'button:active': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white },
                'button:hover': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white }
            },
        }
    });


    const body = isHF ?
        <>
            <div className={contentStyles.header} style={{ paddingTop: 32 }}>
                <span>WELFARE: NUOVO TARIFFARIO E NUOVA PROCEDURA</span>
            </div>
            <div className={contentStyles.header}>
                <span>GESTIONE CONTABILE PIANI DI CURA</span>
            </div>
            <div className={contentStyles.body}>
                <p>
                    A partire dal prossimo 1° gennaio 2024 verrà modificato il tariffario relativo ai piani di cura di tipo welfare e la relativa modalità di gestione del rimborso.
                </p>
                <p>
                    Il tariffario sarà <span className="orange">maggiorato</span> rispetto all’attuale e le commissioni spettanti a ONHC Servizi Sanitari e Welfare (Odontonetwork) vi saranno fatturate. Il relativo importo sarà compensato all’atto del rimborso.
                </p>
                <p>
                    In occasione del bimestre di pagamento il sistema emetterà alla vostra struttura apposita fattura elettronica relativa alle commissioni di gestione dei welfare voucher e l’importo sarà trattenuto in compensazione.
                </p>
                <p>
                    Nessuna modifica sarà applicata alla vostra consueta modalità di fatturazione: l’importo da richiedere sarà sempre quello visibile in situazione contabile.
                </p>
                <p>
                    Vi chiediamo di verificare la corretta compilazione dei campi <span className="orange">SDI</span> e <span className="orange">PEC</span> nella vostra anagrafica a gestionale per il corretto recapito della fattura.
                </p>
                <p>
                    Vi ricordiamo altresì di verificare sul gestionale la corretta compilazione del nuovo campo <span className="orange">e-mail amministrazione</span> perché è lì che verranno inviate le comunicazioni di avviso di contabilizzazione dei pagamenti.
                </p>
                <p>
                    In caso di più strutture con iban uguale andrà inserita la stessa <span className="orange">e-mail amministrazione</span> in tutte le schede anagrafiche delle singole sedi.
                </p>
                <p>
                    Se il campo <span className="orange">e-mail amministrazione</span> non venisse compilato, le notifiche di contabilizzazione verranno inviate alla mail inserita in <span className="orange">e-mail pubblica e/o e-mail</span>. Verificate quindi che le persone addette alla gestione amministrativa abbiano accesso alle suddette caselle di posta elettronica.
                </p>
                <p>
                    Prendete visione <span className="download" onClick={welfare2024Download}>qui</span> delle nuove condizioni tariffarie dedicate al Welfare.
                </p>
                <p>
                    Comunicazione e tariffario saranno sempre disponibili all’interno dell’area riservata, alla sezione documentazione.
                </p>
                <p>
                    Il Customer Care e l’Amministrazione restano a vostra disposizione per ogni eventuale chiarimento o supporto ai consueti recapiti telefonici ed e-mail.
                </p>
            </div>
        </> :
        <>
            <div style={{ backgroundColor: "#fff", borderRadius: 4, marginTop: 40, padding: "0 20px" }}>
                <Image
                    src="/assets/media/images/odontonetwork-oral-health-care-management-logo-vector.png"
                    alt="Odontonetwork Oral Health Care Management"
                    imageFit={ImageFit.centerContain}
                    height="60vh"
                    style={{
                        width: "85%",
                        MozUserSelect: "none",
                        WebkitUserSelect: "none",
                        msUserSelect: "none"
                    }}
                    draggable={false}
                />
            </div>
        </>

    return <>
        <ReservationBlockingModal />
        {canViewMagazine() && <>
            <div style={magazineContainerStyle}>
                <ActionButton style={magazineButtonStyle} iconProps={newsIcon} allowDisabledFocus href="/magazine/">
                    News e Promozioni
                </ActionButton>
            </div>
        </>}

        {body}
    </>
}
