import * as React from 'react';
import Grid from '../../components/controls/grid/Grid';
import Row from '../../components/controls/grid/Row';
import Section from '../../components/controls/grid/Section';
import SearchBox from '../../components/controls/SearchBox';
import { useLeapData } from '../../hooks/useLeapData';
import { useLeapState } from '../../hooks/useLeapState';



type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const feedbackID = useLeapData("feedbackID", "feedback", page, "feedbacks")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...feedbackID} className="ms-sm12" />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
