import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import SearchBox from '../../components/controls/SearchBox';

type Props = { state: any, controller: any }

export default (props: Props) => {
    const page = useLeapState(props.controller, false)

    const planID = useLeapData("planID", "piano sanitario", page, "plans")

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <SearchBox {...planID} className="ms-sm12" required />
                    </Row>
                </Section>
            </Grid>
        </>
    );
}
