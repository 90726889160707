import * as React from 'react';
import { FontIcon } from '@fluentui/react';
import Theme from '../themes/Theme';

export default () => {
    const checkScrollTop = () => {
        if (document?.getElementById("root")?.scrollTop ?? 0 > 300) {
            setFadeClass('item-fadein')
        } else {
            setFadeClass('item-fadeout')
        }
    };

    const scrollToTop = () => {
        document?.getElementById("root")?.scrollTo({ top: 0, behavior: "smooth" })
    };

    document?.getElementById("root")?.addEventListener('scroll', checkScrollTop)

    const collapseIconStyle: React.CSSProperties = {
        color: Theme.fluent.palette.themePrimary,
        position: 'absolute',
        bottom: 30,
        right: "12%",
        zIndex: 100,
        backgroundColor: Theme.fluent.palette.white,
        fontSize: '1.2em',
        padding: "10px 10px 10px 10px",
        border: "1px solid " + Theme.fluent.palette.themePrimary,
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        borderRadius: "50%",
    }

    const [fadeClass, setFadeClass] = React.useState('item-hidden')

    return (
        <>
            <div className={fadeClass}>
                <FontIcon iconName={"DoubleChevronUp8"} onClick={scrollToTop} style={collapseIconStyle} />
            </div>
        </>
    );
}