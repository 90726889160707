import { DefaultButton, DialogFooter, FontSizes, FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Separator from '../../components/controls/grid/Separator';
import Maps from '../../components/controls/Maps';
import DynamicMap from '../../features/maps/DynamicMap';
import Theme from '../../components/themes/Theme';
import { itemData } from '../operation/thunks/itemThunk';
import { resetResponse, setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';

type Props = { state: any, controller: any, longitude?: any, latitude?: any, hidden?: boolean, canSendMail?: boolean }

export const GetMarkers = createAsyncThunk(
    'item/GetMarkers',
    async (args: { token: string | undefined, page: any, controller: any }) => {
        const response = await client.post(args.controller, { form: {}, page: args.page }, 'GetMarkers', args.token)
        return response
    }
)

export const SendMail = createAsyncThunk(
    'item/SendMail',
    async (args: { token: string | undefined, page: any }) => {
        const response = await client.post("Insured", { form: {}, page: args.page }, 'SendMail', args.token)
        return response
    }
)

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }

    const credential = useSelector(security)?.credential

    const [items, setItems] = React.useState(null as any)

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === props.controller)?.value
    const page = dItem?.metadata?.page
    const Load = () => {
        showModal();
        dispatch(GetMarkers(
            {
                token: credential.token,
                page: page,
                controller: props.controller
            }))
            .then((response: any) => {
                setItems(response.payload.data)
                return response;
            })
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
            .then(() => setPosition({ latitude: 41.87194, longitude: 12.56738 }))
    }

    const onExecuteSendMail = (e: any) => {
        dispatch(SendMail(
            {
                token: credential.token,
                page: page,
            }))
            .then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
    }

    const [position, setPosition] = React.useState<{ latitude: number, longitude: number } | null>(null);

    return (
        <>
            <Separator className="ms-sm1"></Separator>
            <PrimaryButton text="consulta strutture sanitarie" onClick={Load} style={props.hidden ? { display: "none" } : { marginTop: "20px" }} />
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi mappa" onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    {Object.keys(items ?? {}).length > 0 ?
                        <Maps controller={props.controller} longitude={props.longitude} latitude={props.latitude} markers={items} />
                        :
                        <DynamicMap position={position ?? { longitude: props.longitude, latitude: props.latitude }} readonly />
                    }
                    <DialogFooter>
                        {props.canSendMail && <PrimaryButton onClick={onExecuteSendMail} text="invia elenco strutture sanitarie" disabled={Object.keys(items ?? {}).length <= 0} />}
                        <DefaultButton onClick={onCancel} text="Annulla" />
                    </DialogFooter>
                </div>
            </Modal>
        </>
    );
}
