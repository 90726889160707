import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    state?: any,
    canDelete?: boolean
    navigateToController?: string

}

export default (props: Props) => {

    const onDeleting = (e: any) => {
        if (e.nativeEvent.relationshipTier === "capo nucleo") {
            e.cancel = true
            e.message = "Impossibile cancellare un capo nucleo"
        }
    }
    const onNavigating = (e: any) => {

        if (e.nativeEvent.canNavigate === false) {
            e.cancel = true
            e.message = "Impossibile procedere alla navigazione per un assistito maggiorenne"
        }
    }
    return (
        <>
            <DataList onDeleting={onDeleting} onCancelNavigate={onNavigating} items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} navigateToController={props.navigateToController} state={props.state}>
                {
                    props.canDelete &&
                    <DataColumn kid='columnD' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                }

                <DataColumn kid='columnN' name='' fieldName='navigate' minWidth={10} maxWidth={10} type='navigate' />
                <DataColumn kid='column1' name='Codice Fiscale' fieldName='fiscalcode' minWidth={200} maxWidth={250} align='left' />
                <DataColumn kid='column2' name='Cognome' fieldName='lastname' minWidth={100} align='left' />
                <DataColumn kid='column3' name='Nome' fieldName='firstname' minWidth={390} maxWidth={400} align='left' />
                <DataColumn kid='column4' name='Grado di Parentela' fieldName='relationshipTier' minWidth={150} maxWidth={200} align='left' />

            </DataList>
        </>
    );
}
