import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';

import { useSecurityRoles } from '../hooks/useSecurityRoles';

import Content, { Filter, Result, Item } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Separator from '../components/controls/grid/Separator';

import Text from '../components/controls/Text';
import DentalChart from '../components/controls/dental/DentalChart';
import SearchBox from '../components/controls/SearchBox';

import FacilityRateTableConsultationResultList from '../features/facilityRateTableConsultation/FacilityRateTableConsultationResultList';

export default () => {
    const controller = "FacilityRateTableConsultation"

    const roles = useSecurityRoles()
    const defaultToResult = roles.isHF && !roles.isHFMaster

    const page = useLeapState(controller, roles.role !== undefined, defaultToResult ? "result" : "filter")
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthFacilities")
    const branchID = useLeapData("branchID", "branca", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServices")
    const dentalElement = useLeapData("dentalElement", "elementi dentali", page)
    const maxRate = useLeapData("maxRate", "tariffa massimale", page)
    const ssRate = useLeapData("ssRate", "tariffa struttura sanitaria", page)

    return <>
        <Content title="Consultazione Tariffario Struttura" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <SearchBox {...healthFacilityID} className="ms-sm5" required />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <FacilityRateTableConsultationResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <Text {...branchID} className="ms-sm5" readOnly />
                            <Text {...healthServiceID} className="ms-sm5" readOnly />
                        </Row>
                        <Row>
                            <Text {...maxRate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} readOnly hidden={useSecurityRoles().isHF} />
                            <Separator className="ms-sm1"></Separator>
                            <Text {...ssRate} className="ms-sm2" type="number" precision={{ integer: 9, decimal: 2, min: 0 }} readOnly />
                            <Text {...healthFacilityID} className="ms-sm5" readOnly hidden={useSecurityRoles().isHF} />
                        </Row>
                        <Row>
                            <Text {...dentalElement} className="ms-sm10" readOnly />
                        </Row>
                        <Section title="elementi dentali">
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}>
                                <Row>
                                    <DentalChart {...dentalElement} selectionMode="multiple" readOnly />
                                </Row>
                            </div>
                        </Section>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
