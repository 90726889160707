import * as React from 'react';
import { useLeapState } from '../hooks/useLeapState';
import { useLeapData } from '../hooks/useLeapData';
import Content from '../components/blueprints/core/Content';
import { Filter, Result, Item } from '../components/blueprints/core/Content';
import PrivilegeResultList from '../features/privilege/PrivilegeResultList';
import PrivilegeUnitList from '../features/privilege/PrivilegeUnitList';
import Grid from '../components/controls/grid/Grid';
import Section from '../components/controls/grid/Section';
import Row from '../components/controls/grid/Row';
import Text from '../components/controls/Text';
import PermissionModal from '../features/privilege/PermissionModal'
import PermissionModalEdit from '../features/privilege/PermissionModalEdit'



export default () => {
    const controller = "Privilege";
    const privilegePermissionModal = "PrivilegePermissionModal";

    const page = useLeapState(controller)

    const id = useLeapData("id", "hidden", page)
    const name = useLeapData("name", "nome", page)
    const group_type = useLeapData("group_type", "gruppo", page)

    return <>
        <Content title="Privilegi" controller={controller}>
            <Filter>
                <Grid>
                    <Section toolbar="filter">
                        <Row>
                            <Text {...name} className="ms-sm4" maxlength={50} />
                            <Text {...group_type} className="ms-sm4" maxlength={50} />
                        </Row>
                    </Section>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                        <PrivilegeResultList controller={controller} items={page.result?.state?.data} />
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                        <Row>
                            <input {...id} type="hidden" />
                            <Text {...name} className="ms-sm4" required maxlength={50} />
                            <Text {...group_type} className="ms-sm4" required maxlength={50} />
                        </Row>
                    </Section>
                    {page.modality === "item" && (<Section title="Elenco Funzioni" toolbar="grid" modal={<PermissionModal state={page} controller={privilegePermissionModal} />} controller={privilegePermissionModal} modalwidth="600px" modaltitle="Inserisci Funzione" state={page} >
                        <Row>
                            <PrivilegeUnitList items={page.item.state?.lists.Units} modal={<PermissionModalEdit state={page} controller={privilegePermissionModal} />} controller={privilegePermissionModal} modalwidth="600px" modaltitle="Inserisci Funzione" state={page} />
                        </Row>
                    </Section>)}
                </Grid>
            </Item>
        </Content>
    </>
};
