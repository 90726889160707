import { PrimaryButton, Button, ICommandBarItemProps, CommandBar } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import Text from '../components/controls/Text';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import ResultList from '../features/HealthFacilityConsult/ResultList';
import DynamicMap from '../features/maps/DynamicMap';
import Maps from '../components/controls/Maps';
import { useState } from 'react';


import { Stack, StackItem, Text as Text2 } from '@fluentui/react/';
import { unitTheme } from '../components/themes/Fonts';
import Theme from '../components/themes/Theme';
import MultiBox from '../components/controls/MultiBox';
import ComboBox from '../components/controls/ComboBox';

export const TextResearch = createAsyncThunk(
    'item/TextResearch',
    async (args: { token: string | undefined, denomination: string, address: string, province: string, municipality: string, opendays: string[], openhour: string, hfcap: string }) => {
        const response = await client.post("HealthFacilityConsult", {
            form: {
                denomination: args.denomination,
                address: args.address,
                province: args.province,
                municipality: args.municipality,
                opendays: args.opendays,
                openhour: args.openhour,
                hfcap: args.hfcap,
            }, page: null
        }, 'TextResearch', args.token)
        return response
    }
)

export default () => {
    const controller = "HealthFacilityConsult"
    const page = useLeapState(controller)
    const researchType = useLeapData("researchType", "tipologia ricerca", page, "researchOptions")
    const denomination = useLeapData("denomination", "denominazione", page)
    const address = useLeapData("address", "indirizzo", page)
    const province = useLeapData("province", "provincia", page, "provinces")
    const municipality = useLeapData("municipality", "comune", page, "municipalities")
    const opendays = useLeapData("opendays", "giorno apertura", page, "opendays")
    const openhour = useLeapData("openhour", "orario apertura a partire da:", page)
    const hfcap = useLeapData("hfcap", "CAP", page, "searchCaps")
    console.log(page)
    const textResearch = page.filter?.values?.researchType?.value === '1'
    const mapResearch = page.filter?.values?.researchType?.value === '2'
    const buttonDisabled = !(page.filter?.values.municipality?.value || page.filter?.values.province?.value || page.filter?.values.hfcap?.value)

    const [pageState, setPage] = React.useState<"filter" | "item">("filter")
    const [result, setResult] = React.useState<any>(null)

    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const headerSectionStyles: React.CSSProperties = {
        backgroundColor: Theme.leap.main.content.section.backgroundColor,
        margin: Theme.leap.main.content.section.margin,
        padding: Theme.leap.main.content.section.padding,
    };

    const Load = () => {
        setPage("item")
        dispatch(TextResearch(
            {
                token: credential.token,
                address: page.filter?.values.address?.value,
                denomination: page.filter?.values.denomination?.value,
                municipality: page.filter?.values.municipality?.value,
                province: page.filter?.values.province?.value,
                hfcap: page.filter?.values.hfcap?.value,
                opendays: page.filter?.values.opendays?.value,
                openhour: page.filter?.values.openhour?.value
            }))
            .then((response: any) => {
                setResult(response.payload.data)
                return response;
            })
    }

    const [error, setError] = useState(null);
    const [position, setPosition] = useState<{ latitude: number, longitude: number }>({ latitude: 41.87194, longitude: 12.56738 });

    const handlePosition = (ev: any) => {
        setPosition(position)
    }

    const onError = (error: any) => {
        setError(error.message);
        setPosition(position)
    };

    React.useEffect(() => {
        if (mapResearch) {
            const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
            navigator.geolocation.getCurrentPosition(handlePosition, onError, options);
        }

    }, [mapResearch]);

    const reloadResult: ICommandBarItemProps = {
        key: 'reloadResult',
        text: 'ricarica pagina',
        iconOnly: true,
        iconProps: { iconName: 'Refresh' },
        renderedInOverflow: false,
        onClick: (e: any) => {
            e.preventDefault()
            window.location.reload();
        },
    };
    return <>
        <section style={headerSectionStyles}>
            <Stack horizontal style={{ paddingRight: 8, width: "100%" }}>
                <StackItem grow align="start">
                    <Text2 theme={unitTheme}>Consultazione Strutture Sanitarie</Text2>
                </StackItem>
                <StackItem align="end">
                    {pageState === "item" && textResearch && <CommandBar className="leap-commandbar" items={[]} farItems={[reloadResult]} />}
                </StackItem>
            </Stack>
            <Filter>
                <Grid>
                    {pageState === "filter" &&
                        <>
                            <Section>
                                <Row>
                                    <Option {...researchType} className="ms-sm3" type="horizontal" />
                                </Row>
                            </Section>
                            <Section title="opzioni di ricerca" hidden={!textResearch}>
                                <Row hidden={!textResearch}>
                                    <Text {...denomination} className="ms-sm4" />
                                    <Text {...address} className="ms-sm4" />
                                </Row>
                                <Row hidden={!textResearch}>
                                    <SearchBox {...province} className="ms-sm4" label={buttonDisabled ? province.label + " *" : province.label} />
                                    <SearchBox {...municipality} className="ms-sm4" label={buttonDisabled ? municipality.label + " *" : municipality.label} />
                                    <SearchBox {...hfcap} className="ms-sm3" label={buttonDisabled ? hfcap.label + " *" : hfcap.label} />
                                </Row>
                                <Row hidden={!textResearch}>
                                    <MultiBox {...opendays} className="ms-sm2" titleType="text" />
                                    <Text {...openhour} type="time" className="ms-sm1" />
                                </Row>
                                <Row hidden={!textResearch}>
                                    <PrimaryButton text="mostra risultati" onClick={() => Load()} style={{ marginTop: "20px" }} disabled={buttonDisabled} />
                                </Row>
                            </Section>
                        </>}
                    {pageState === "item" && textResearch &&
                        <>
                            <Section title="Elenco Studi Odontoiatrici">
                                {Object.keys(result ?? {}).length > 0 &&
                                    <>
                                        <Row>
                                            <Maps controller={controller} markers={result} />
                                        </Row>
                                        <Row>
                                            &nbsp;
                                        </Row>
                                        <Row>
                                            &nbsp;
                                        </Row>
                                    </>}
                                <Row>
                                    <ResultList controller={controller} items={result} />
                                </Row>
                            </Section>
                        </>}

                    {mapResearch &&
                        <>
                            <Section title="Mappa">

                                <Row>
                                    <DynamicMap position={position} readonly />
                                </Row>
                            </Section>
                        </>}
                </Grid>
            </Filter>
        </section>
    </>
};
