import * as React from 'react';
import { useDispatch } from 'react-redux';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { setOperation } from '../operation/operationSlice';



export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Codice Struttura Sanitaria' fieldName='healthFacilityID' minWidth={250} align='left' />
                <DataColumn kid='column2' name='Codice Assistito' fieldName='insuredID' minWidth={250} maxWidth={250} align='left' />
                <DataColumn kid='column3' name='Dalla Data' fieldName='startDate' minWidth={250} maxWidth={250} align='center' />
                <DataColumn kid='column4' name='Alla Data' fieldName='endDate' minWidth={250} maxWidth={250} align='center' />
            </DataList>
        </>
    );
}
