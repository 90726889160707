import { DefaultButton, DialogFooter, FontSizes, FontWeights, getTheme, IconButton, IIconProps, mergeStyleSets, Modal, PrimaryButton } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import DynamicMap from './DynamicMap';
import Maps from '../../components/controls/Maps';
import Theme from '../../components/themes/Theme';
import { setOperation } from '../operation/operationSlice';
import { itemData } from '../operation/thunks/itemThunk';
import { resetResponse, setResponse } from '../response/responseSlice';
import { security } from '../security/thunks/securityThunk';
import { useState } from 'react';

type Props = {
    state: any,
    controller: any,
    longitude: any,
    latitude: any,
    planID?: number,
    label?: string,
    hidden?: boolean,
    readonly?: boolean,
    mapType: "location" | "search" | "places" | "view"
    onChange?: any
}

export const UpdatePosition = createAsyncThunk(
    'item/UpdatePosition',
    async (args: { latitude: any, longitude: any, page: any, token: string | undefined }) => {
        const response = await client.post("HealthFacility", { form: { latitude: args.latitude, longitude: args.longitude }, page: args.page }, 'updatePosition', args.token)
        return response
    }
)

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const iconButtonStyles = {
        root: {
            color: Theme.fluent.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: Theme.fluent.palette.neutralDark,
        },
    };
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '70vw'
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Raleway',
                fontSize: FontSizes.large,
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });

    const mapIcon: IIconProps = { iconName: 'MapPin' };

    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const onCancel = () => {
        dispatch(resetResponse(false))
        hideModal()
    }

    const credential = useSelector(security)?.credential

    const latitudeState: string = props.state?.item?.state?.data?.latitude
    const latitudeItem: string = props.state?.item?.values?.latitude?.value
    const longitudeState: string = props.state?.item?.state?.data?.longitude
    const longitudeItem: string = props.state?.item?.values?.longitude?.value

    const iData = useSelector(itemData);
    const dItem = iData.find(d => d.key === props.controller)?.value
    const page = dItem?.metadata?.page
    const onExecuteUpdatePosition = (e: any) => {
        hideModal()
        dispatch(UpdatePosition(
            {
                page: page,
                latitude: latitudeItem ? latitudeItem : latitudeState,
                longitude: latitudeItem ? longitudeItem : longitudeState,
                token: credential.token
            }))
            .then((response: any) => {
                dispatch(setOperation("reload", props.controller))
                return response
            }).then((response: any) => {
                dispatch(setResponse(response.payload.response.message, response.payload.response.level))
            })
    }

    const onChangeHandler = (args: any) => {
        if (props.onChange) props.onChange(args)
        onCancel()
    }

    const [position, setPosition] = useState<{ latitude: number, longitude: number }>({ latitude: props.latitude ?? 41.87194, longitude: props.longitude ?? 12.56738 });

    const popupBlock = (props.mapType === "location") ?
        <div className={contentStyles.body}>
            <Maps controller={props.controller} longitude={props?.longitude} latitude={props?.latitude} readonly={props.readonly} />
            <DialogFooter>
                <PrimaryButton onClick={onExecuteUpdatePosition} text="aggiorna posizione" style={props.readonly ? { display: "none" } : {}} />
                <DefaultButton onClick={onCancel} text="Annulla" />
            </DialogFooter>
        </div> :
        <div className={contentStyles.body}>
            <DynamicMap position={position} readonly={props.mapType === "places"} onChange={onChangeHandler} minimal={props.mapType === "view"} planID={props.planID} />
            <DialogFooter>
                <DefaultButton onClick={onCancel} text="Chiudi" />
            </DialogFooter>
        </div>

    return (
        <>
            <PrimaryButton text={props.label ?? props.mapType === "view" ? "scopri il network delle strutture di Odontonetwork" : "trova sulla mappa"} onClick={showModal} iconProps={mapIcon} style={props.hidden ? { display: "none" } : { marginTop: "22px" }} />
            <Modal titleAriaId={"maps"} isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="chiudi mappa" onClick={hideModal} />
                </div>
                {popupBlock}
            </Modal>
        </>
    );
}
