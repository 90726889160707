import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items}>
                <DataColumn kid='column1' name='Data Modifica' fieldName='changeDate' minWidth={150} maxWidth={150} align='center' />
                <DataColumn kid='column2' name='Inizio Periodo' fieldName='startDate' minWidth={150} maxWidth={150} align='center' />
                <DataColumn kid='column3' name='Fine Periodo' fieldName='endDate' minWidth={150} maxWidth={150} align='center' />
                <DataColumn kid='column4' name='Modifica' fieldName='editOption' minWidth={200} align='left' />
                <DataColumn kid='column5' name='Azione' fieldName='actionType' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column6' name='Autore' fieldName='author' minWidth={150} align='left' />
            </DataList>
        </>
    );
}
