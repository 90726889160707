import * as React from 'react';

import Text from './Text'
import Separator from './grid/Separator';
import { isNumber } from 'util';
import { TimeFieldProps, DateRangeProps } from '../../defs/props';
import { useEffect } from 'react';

type Props = {
    dateMin: DateRangeProps,
    timeMin?: TimeFieldProps,
    dateMax: DateRangeProps,
    timeMax?: TimeFieldProps,
    separator?: {
        className: string,
    },
    withinYear?: boolean,
    defaultMinTomorrow?: boolean,
    hidden?: boolean
}

export default (props: Props) => {
    const timezonedDate = (s: string) => {
        var d = new Date(s)
        var d_fixed = d
        if (d) {
            var offset = d?.getTimezoneOffset() * 60000
            var time = d?.getTime()

            d_fixed = new Date(time + offset)
        }

        return d_fixed.toString()
    }

    const [startValue, setStartValue] = React.useState<string | undefined>(props.withinYear ? (props.dateMin.value ? timezonedDate(new Date(props.dateMin.value).getFullYear().toString() + "-01-01T00:00:00.000Z") : undefined) : undefined)
    const [minValue, setMinValue] = React.useState<string | undefined>(props.dateMin.value)
    const [maxValue, setMaxValue] = React.useState<string | undefined>(props.dateMax.value)
    const [endValue, setEndValue] = React.useState<string | undefined>(props.withinYear ? (props.dateMax.value ? timezonedDate(new Date(props.dateMax.value).getFullYear().toString() + "-12-31T23:59:59.999Z") : undefined) : undefined)

    const dateMinProps = { ...props.dateMin }
    const dateMaxProps = { ...props.dateMax }
    const separator = props.separator && <Separator className={props.separator.className} />

    useEffect(() => {
        setMinValue(props.dateMin.value)
    }, [props.dateMin.value])

    useEffect(() => {
        setMaxValue(props.dateMax.value)
    }, [props.dateMax.value])

    const getMinDate = () => {

        var today = new Date()
        var tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)

        if (!minValue) {
            if (props.defaultMinTomorrow) {
                return tomorrow.toLocaleString("it-IT")
            }

        }
        if (minValue) {
            if (new Date(minValue) > tomorrow) {
                return minValue
            } else {
                return tomorrow.toLocaleString("it-IT")
            }
        }
        return minValue
    }
    const evaluatedMinDate = getMinDate()


    const onMinChangeHandler = (name: string | undefined, value: string | number | undefined) => {
        if (isNumber(value)) return

        setMinValue(value)
        if (props.withinYear) {
            const year = value ? new Date(value).getFullYear().toString() : maxValue ? new Date(maxValue).getFullYear().toString() : undefined

            setStartValue(year ? timezonedDate(year + "-01-01T00:00:00.000Z") : undefined)
            setEndValue(year ? timezonedDate(year + "-12-31T23:59:59.999Z") : undefined)
        }

        if (dateMinProps.onChange)
            dateMinProps.onChange(name, value, dateMinProps.required)
    }

    const onMaxChangeHandler = (name: string | undefined, value: string | number | undefined) => {
        if (isNumber(value)) return

        setMaxValue(value)
        if (props.withinYear) {
            const year = value ? new Date(value).getFullYear().toString() : minValue ? new Date(minValue).getFullYear().toString() : undefined

            setStartValue(year ? timezonedDate(year + "-01-01T00:00:00.000Z") : undefined)
            setEndValue(year ? timezonedDate(year + "-12-31T23:59:59.999Z") : undefined)
        }

        if (dateMaxProps.onChange)
            dateMaxProps.onChange(name, value, dateMaxProps.required)
    }

    return <>
        <Text {...dateMinProps} type="calendar" dateRange={{ minDate: startValue, maxDate: maxValue }} onChange={onMinChangeHandler} time={props.timeMin} hidden={props.hidden} />
        {separator}
        <Text {...dateMaxProps} type="calendar" dateRange={{ minDate: minValue, maxDate: endValue }} onChange={onMaxChangeHandler} time={props.timeMax} hidden={props.hidden} />
    </>
}