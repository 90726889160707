import { DefaultButton, Dialog, DialogFooter, DialogType, FontSizes, getTheme, mergeStyleSets, Modal, PrimaryButton, Link } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import Theme from '../../components/themes/Theme';
import { FontFaces } from '../../defs/fonts';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { security } from '../security/thunks/securityThunk';
import Option from '../../components/controls/Option';

export const DoReadDeclarations = createAsyncThunk(
    'item/MagazineReadDeclarations',
    async (args: { token: string | undefined }) => {
        const response = await client.post("Magazine", { form: {}, page: {} }, 'ReadDeclarations', args.token)
        return response
    }
)
export const DoSaveDeclarations = createAsyncThunk(
    'item/MagazineSaveDeclarations',
    async (args: { body: any, token: string | undefined }) => {
        const response = await client.post("Magazine", { form: args.body, page: {} }, 'SaveDeclarations', args.token)
        return response
    }
)

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        maxHeight: "80vh",
        padding: "30px 0 20px",
        width: 800
    },
    content: {
        overflowY: "visible",
    },
    header: {
        color: Theme.fluent.palette.themePrimary,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.xxLargePlus,
        fontVariant: 'small-caps',
        fontWeight: 600,
        padding: "20px 0 30px",
        textAlign: "center"
    },
    body: {
        fontFamily: FontFaces.openSans,
        fontSize: 16,
        overflowY: "visible",
        padding: "0 32px",
        textAlign: "justify",
        selectors: {
            p: { margin: '16px 0' },
            'p:nth-child(1)': { margin: "0 0 16px" },
            'p:nth-child(5)': { margin: "40px 0" },
            'p:nth-child(6)': { margin: "40px 0 40px" },
            'span.orange': { color: "#ff8200", fontWeight: 500 },
            'span.download': { color: "#ff8200", cursor: "pointer", fontWeight: 500, textDecoration: "underline" },
            'div.ms-ChoiceFieldGroup-flexContainer': { justifyContent: "flex-end" },
            'div.ms-ChoiceField-wrapper': { marginRight: 8 },
            'div.ms-ChoiceFieldGroup-flexContainer input': { fontSize: "1.1em", fontWeight: 600 },
            'div.ms-ChoiceFieldGroup-flexContainer label': { fontSize: "1.1em", fontWeight: 600, textTransform: "lowercase" }
        },
    },
    primaryButton: {
        padding: "36px 0 16px",
        textAlign: "center",
        selectors: {
            button: { backgroundColor: "#ff8200", borderColor: "#ff8200", borderRadius: 4, color: Theme.fluent.palette.white, fontWeight: 600, width: 220 },
            'button:active': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" },
            'button:hover': { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", color: "#ff8200" }
        },
    },
    standardButton: {
        padding: "16px 32px 8px",
        textAlign: "right",
        selectors: {
            button: { backgroundColor: Theme.fluent.palette.white, borderColor: "#ff8200", borderRadius: 4, color: "#ff8200", fontSize: "0.7em", margin: '8px 0', width: 180 },
            'button:active': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white },
            'button:hover': { backgroundColor: "#ff8200", borderColor: "#ff8200", color: Theme.fluent.palette.white }
        },
    }
});

export default () => {
    const dispatch: AppDispatch = useDispatch();

    const roles = useSecurityRoles()
    const isHF = roles.isHF;
    const isInsured = roles.isInsuredUser;
    const credential = useSelector(security)?.credential

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isDialogOpen, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);

    const [modalData, setModalData] = React.useState({
        voluntaryAcceptanceStrictID: "" as string | number | undefined,
        voluntaryAcceptanceMarketingID: "" as string | number | undefined,
        voluntaryAcceptanceOthersID: "" as string | number | undefined,
        voluntaryAcceptanceMailingListID: "" as string | number | undefined,
    })

    const [dialogText, setDialogText] = React.useState("")

    //const leap_VoluntaryAcceptanceStrictID = useLeapData("voluntaryAcceptanceStrictID", "consenso minimo", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceMarketingID = useLeapData("voluntaryAcceptanceMarketingID", "consenso marketing", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceOthersID = useLeapData("voluntaryAcceptanceOthersID", "consenso terzi", page, "voluntaryAcceptance")
    //const leap_VoluntaryAcceptanceMailingListID = useLeapData("voluntaryAcceptanceMailingListID", "mailing list", page, "voluntaryAcceptance")

    const voluntaryAcceptance = [{ key: "1", text: "Acconsento" }, { key: "2", text: "Non acconsento" }]


    const leap_VoluntaryAcceptanceStrictID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceStrictID }
    const leap_VoluntaryAcceptanceMarketingID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceMarketingID }
    const leap_VoluntaryAcceptanceOthersID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceOthersID }
    const leap_VoluntaryAcceptanceMailingListID = { label: "", options: voluntaryAcceptance, selectedKey: modalData.voluntaryAcceptanceMailingListID }

    React.useEffect(() => {
        if (!!!isHF && !!!isInsured) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoReadDeclarations({ token: credential.token }))
            .then((response) => {
                if (!!response?.payload?.data?.showVoluntaryAcceptance) {
                    setModalData(response?.payload?.data)
                    showModal()
                }
            })

    }, [isHF, credential])

    const saveDeclarations = () => {
        if (!!!isHF && !!!isInsured) {
            return
        }

        if (!!!credential) {
            return
        }

        dispatch(DoSaveDeclarations({ body: modalData, token: credential.token }))
            .then((response) => {
                if (response.payload?.response?.level === 4) {
                    setDialogText("Dati correttamente aggiornati.")
                }
                else {
                    setDialogText("Si � verificato un errore. Contattare l'assistenza.")
                }
                return response
            })
            .then((response) => {
                showDialog()
            })
    }

    const dialogStyles = { main: { maxWidth: 600 } };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Consenso',
        closeButtonAriaLabel: 'Chiudi',
        subText: dialogText,
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    return <>
        <Modal titleAriaId={"ReservationRequest"} isOpen={isModalOpen} isBlocking containerClassName={contentStyles.container} scrollableContentClassName={contentStyles.content}>
            <div className={contentStyles.header}>
                <span>TRATTAMENTO DEI DATI PERSONALI</span>
            </div>
            <div className={contentStyles.body}>
                <p>
                    La informiamo che, ai sensi dell&apos;art. 13 del Codice Privacy e dell&apos;art.13 del GDPR, recanti disposizioni a tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali, i dati personali forniti in sede di Affiliazione a Odontonetwork Srl (di seguito il "Servizio") saranno raccolti e registrati da Odontonetwork Srl in qualita&#768; di Titolare del trattamento, con sede legale in Milano, Via Uberto Visconti di Modrone 28, rappresentata pro-tempore nella persona del legale rappresentante.
                    I dati personali da Lei forniti, saranno trattati in forma automatizzata e/o manuale su supporti cartacei e informatici, nel rispetto di quanto previsto dall&apos;art. 32 del GDPR e dall&apos;Allegato B del Codice della Privacy in materia di misure di sicurezza, ad opera di soggetti appositamente incaricati e in ottemperanza a quanto previsto dagli art. 29 del GDPR.
                    I dati fornitici verranno utilizzati per finalita&#768; strettamente connesse e strumentali alla esecuzione del Servizio e per gli adempimenti di legge da esso derivanti. Secondo i principi di limitazione e minimizzazione, saranno raccolti solo i dati strettamente necessari per le finalita&#768; individuate: tali dati saranno trattati secondo i principi di correttezza, liceita&#768;, trasparenza e di tutela della riservatezza dei soggetti interessati.
                    A tal fine, e a titolo meramente esemplificativo, i suoi dati potranno essere trattati per l&apos;esecuzione e la gestione del Servizio, la fatturazione dell&apos;importo e l&apos;assolvimento di ogni altro onere di legge, da dipendenti e collaboratori del Titolare, o da soggetti autorizzati tra cui i consulenti legali, fiscali, il fondo sanitario e la compagnia di assicurazione che eroga le prestazioni sanitarie medico odontoiatriche in favore del fondo Sanitario.
                    Il conferimento dei dati personali per le finalita&#768; sopra indicate e&#768; obbligatorio in quanto la mancata comunicazione di tali dati impedisce l&apos;erogazione del Servizio stesso.
                    Inoltre, previo suo esplicito consenso facoltativo, revocabile in qualsiasi momento, i suoi dati personali potranno essere utilizzati dal Titolare anche per: i) finalita&#768; promozionali, pubblicitarie e di marketing quali l&apos;invio di newsletter, materiale pubblicitario, promozionale ed informativo su prodotti e servizi erogati da Odontonetwork Srl; ii) analisi statistiche dirette a rilevare il grado di soddisfazione dei servizi offerti, iii) condivisione dei suoi dati personali con soggetti terzi partner di Odontonetwork affinche&#769; gli stessi possano contattarla per finalita&#768; promozionali.
                    In qualita&#768; di interessato, La informiamo, infine, che potra&#768; esercitare in qualunque momento i diritti previsti dall&apos;art. 7 del Codice Privacy e dagli articoli dal 15 al 22 del GDPR (tra cui, a mero titolo esemplificativo, il diritto di accesso ai propri dati, l&apos;aggiornamento, la rettifica o l&apos;integrazione dei dati, la cancellazione, la trasformazione in forma anonima o il blocco dei dati personali trattati, e il diritto di opporsi, in tutto o in parte, al relativo utilizzo, ecc.) rivolgendo la loro Sua richiesta al Titolare all'indirizzo e-mail <a href="mailto:privacy@odontonetwork.it">privacy@odontonetwork.it</a> o al Responsabile della Protezione dei Dati (DPO) di Odontonetwork s.r.l. all&apos;indirizzo email: <a href="mailto:privacy-dpo@odontonetwork.it">privacy-dpo@odontonetwork.it</a>
                </p>
                <p>
                    Dichiaro di aver letto l'informativa privacy riportata sopra e di acconsentire al trattamento dei miei dati personali per le finalita&#768; di erogazione del Servizio e per l'adempimento degli obblighi contrattuali e di legge.
                </p>
                <Option {...leap_VoluntaryAcceptanceStrictID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceStrictID: value }) }} />
                <p>
                    Acconsento al trattamento dei miei dati personali per finalita&#768; promozionali, pubblicitarie e di marketing quali l&apos;invio di newsletter,
                    materiale pubblicitario, promozionale ed informativo su prodotti e servizi, nonche&#769; per analisi statistiche dirette a rilevare il grado
                    di soddisfazione dei servizi / prodotti offerti.

                </p>
                <Option {...leap_VoluntaryAcceptanceMarketingID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceMarketingID: value }) }} />
                <p>
                    Acconsento al trattamento dei miei dati personali da parte di soggetti terzi partner di Odontonetwork per essere contattato per finalita&#768; promozionali,
                    pubblicitarie e di marketing quali l&apos;invio di materiale pubblicitario,
                    promozionale ed informativo su prodotti e servizi dei soggetti terzi
                </p>
                <Option {...leap_VoluntaryAcceptanceOthersID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceOthersID: value }) }} />
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.*/}
                {/*</p>*/}
                {/*<Option {...leap_VoluntaryAcceptanceMailingListID} className="ms-sm12" type="horizontal" onChange={(name, value) => { setModalData({ ...modalData, voluntaryAcceptanceMailingListID: value }) }} />*/}
            </div>
            <div className={contentStyles.primaryButton}>
                <PrimaryButton text="SALVA" onClick={saveDeclarations} />
            </div>
            <div className={contentStyles.standardButton}>
                <DefaultButton text="RICORDA PIU&#768; TARDI" onClick={hideModal} />
            </div>
        </Modal>
        <Dialog hidden={!isDialogOpen} dialogContentProps={dialogContentProps} modalProps={modalProps} isBlocking>
            <DialogFooter>
                <DefaultButton onClick={() => { hideDialog(); hideModal() }} text="Chiudi" />
            </DialogFooter>
        </Dialog>
    </>
}
