import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick}>
                <DataColumn kid='column1' name='Piano Sanitario' fieldName='plan' minWidth={200} align='left' />
                <DataColumn kid='column1' name='Nome' fieldName='firstname' minWidth={200} align='left' />
                <DataColumn kid='column1' name='Cognome' fieldName='lastname' minWidth={200} align='left' />
                <DataColumn kid='column1' name='Email' fieldName='email' minWidth={200} align='left' />
                <DataColumn kid='column1' name='Codice Fiscale' fieldName='fiscalcode' minWidth={200} align='left' />
                <DataColumn kid='column1' name='Stato' fieldName='state' maxWidth={150} align='left' />
            </DataList>
        </>
    );
}