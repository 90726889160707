import * as React from 'react';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import MultiBox from '../components/controls/MultiBox';
import Option from '../components/controls/Option';
import SearchBox from '../components/controls/SearchBox';
import DateRange from '../components/controls/DateRange';
import Text from '../components/controls/Text';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import { AppDispatch } from '../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { security } from '../features/security/thunks/securityThunk';
import { filterForm } from '../features/operation/thunks/filterThunk';
import { resultDownload } from '../features/operation/thunks/resultThunk';
import { lock, unlock } from '../features/operation/lockSlice';
import { Icon, PivotItem, Pivot, IButtonProps, DefaultButton, Label, ILabelStyles, FontSizes } from '@fluentui/react';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';

const PivotButtonProps: IButtonProps = {
    style: {
        borderColor: Theme.fluent.palette.white,
        borderRadius: 0,
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderTopWidth: 1,
        transition: "height 0.15s ease, width 0.30s ease, margin-top 0.10s ease",
        verticalAlign: "bottom"
    },
    styles: {
        root: {
            backgroundColor: Theme.fluent.palette.themeTertiary,
            color: Theme.fluent.palette.neutralLighter,
            height: 38,
            marginTop: 12,
            minWidth: "12%",
        },
        rootDisabled: {
            backgroundColor: Theme.fluent.palette.themePrimary,
            color: Theme.fluent.palette.white,
            height: 42,
            marginTop: 8,
            minWidth: "15%",
        },
        rootHovered: {
            backgroundColor: Theme.fluent.palette.themeLighter,
        },
        rootPressed: {
            backgroundColor: Theme.fluent.palette.themeLight,
        }
    }
}
const noDataFoundLabel: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "inherit",
        whiteSpace: "pre-wrap",
        lineHeight: 30
    }
}
const multiSearchBoxItemLimit = 99;

export default () => {
    const controller = "DEHealthFacility"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch();

    const code = useLeapData("code", "codice", page)
    const businessName = useLeapData("businessName", "ragione sociale", page)
    const leap_HealthFacilityStateID = useLeapData("leap_HealthFacilityStateID", "attivo", page, "yesno")
    const denomination = useLeapData("denomination", "denominazione", page)
    const nationID = useLeapData("nationID", "nazione", page, "nations")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")
    const lastnameLegal = useLeapData("lastnameLegal", "cognome legale rappr", page)
    const firstnameLegal = useLeapData("firstnameLegal", "nome legale rappr", page)
    const sanitaryDistrict = useLeapData("sanitaryDistrict", "distretto sanitario", page)

    const lastnameDoctor = useLeapData("lastnameDoctor", "cognome dottore", page)
    const firstnameDoctor = useLeapData("firstnameDoctor", "nome dottore", page)

    const healthAgreementID = useLeapData("healthAgreementID", "convenzioni attive", page, "healthAgreements")

    const leap_HealthFacilityOnlineAgendaID = useLeapData("leap_HealthFacilityOnlineAgendaID", "adesione agenda online", page, "yesno")
    const leap_HealthFacilityMembershipWelfareID = useLeapData("leap_HealthFacilityMembershipWelfareID", "adesione welfare", page, "yesno")
    const leap_HealthFacilityWelfareWarrantID = useLeapData("leap_HealthFacilityWelfareWarrantID", "mandato welfare", page, "yesno")
    const leap_HealthFacilityVariationAcceptanceLowerRateID = useLeapData("leap_HealthFacilityVariationAcceptanceLowerRateID", "variazione accettazione tariffario inferiore", page, "yesno")
    const leap_HealthFacilityStructureTypeID = useLeapData("leap_HealthFacilityStructureTypeID", "tipo di struttura", page, "healthFacilityStructureTypeOption") //cbox
    const hasRateTable = useLeapData("hasRateTable", "presenza di tariffario di SS", page, "yesno")

    const affiliationDateFrom = useLeapData("affiliationDateFrom", "data affiliazione DA", page)
    const affiliationDateTo = useLeapData("affiliationDateTo", "data affiliazione A", page)

    const terminationDateFrom = useLeapData("terminationDateFrom", "data cessazione DA", page)
    const terminationDateTo = useLeapData("terminationDateTo", "data cessazione A", page)

    const membershipWelfareDateFrom = useLeapData("membershipWelfareDateFrom", "data adesione welfare DA", page)
    const membershipWelfareDateTo = useLeapData("membershipWelfareDateTo", "data adesione welfare A", page)

    const compatiblePlans = useLeapData("compatiblePlans", "piano sanitario erogabile", page, "compatiblePlans")
    const notCompatiblePlans = useLeapData("notCompatiblePlans", "piano sanitario non erogabile", page, "notCompatiblePlans")

    const compatibleBranches = useLeapData("compatibleBranches", "branche erogabili", page, "compatibleBranches")
    const notCompatibleBranches = useLeapData("notCompatibleBranches", "branche non erogabili", page, "notCompatibleBranches")

    const compatibleHealthServices = useLeapData("compatibleHealthServices", "prestazioni erogabili", page, "compatibleHealthServices")
    const notCompatibleHealthServices = useLeapData("notCompatibleHealthServices", "prestazioni non erogabili", page, "notCompatibleHealthServices")

    const healthFacilityMedic = useLeapData("healthFacilityMedic", "strutture sanitarie", page, "medicHealthfacilities")
    const rateHealthServices = useLeapData("rateHealthServices", "prestazioni", page, "rateHealthServices")

    const from = useLeapData("from", "da", page)
    const to = useLeapData("to", "a", page)

    const startDate = useLeapData("startDate", "da", page)
    const endDate = useLeapData("endDate", "a", page)

    const credential = useSelector(security)?.credential

    const fForm = useSelector(filterForm)
    const formF = fForm.find(i => i.key === controller)?.value

    const extract = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller, body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const extractMedic = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEHealthFacilityDoctor", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const extractRate = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEHealthFacilityRate", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const extractRegistry = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEHealthFacilityRegistry", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }
    const extractNoNetwork = () => {
        dispatch(lock())
        dispatch(resultDownload({ controller: "DEHealthFacilityNoNetwork", body: { form: { ...formF }, page: {} }, token: credential.token }))
            .then(() => dispatch(unlock()))
    }

    const [pivotItemSelectedKey, setPivotItemSelectedKey] = React.useState<"hf" | "medics" | "registry" | "rate" | "noNet">("hf")

    const rateDisabled = !(Object.keys(formF?.rateHealthServices ?? {}).length > 0)
    const registryDisabled: boolean = !(formF?.from && formF?.to)

    return <>
        <Content title="Estrazione dati - Struttura Sanitaria" controller={controller} noResult>
            <Filter>
                <Grid>
                    <Section toolbar="filter" controller={controller}>
                        <div className="ms-Grid-col ms-sm12" style={{ minHeight: 50 }}>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "hf"} onClick={() => setPivotItemSelectedKey("hf")}>struttura sanitaria</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "medics"} onClick={() => setPivotItemSelectedKey("medics")}>medici</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "registry"} onClick={() => setPivotItemSelectedKey("registry")}>variazioni anagrafiche</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "rate"} onClick={() => setPivotItemSelectedKey("rate")}>tariffe diverse da massimale</DefaultButton>
                            <DefaultButton {...PivotButtonProps} disabled={pivotItemSelectedKey === "noNet"} onClick={() => setPivotItemSelectedKey("noNet")}>strutture sanitarie senza piani di cura attivi</DefaultButton>
                        </div>
                    </Section>
                    <Pivot selectedKey={pivotItemSelectedKey}>
                        <PivotItem headerText="struttura sanitaria" itemKey="hf">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm3"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="estrai dati"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={extract} style={{ cursor: "pointer", background: "transparent" }} id={"extract"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <Text {...code} className="ms-sm2" maxlength={12} />
                                    <Text {...businessName} className="ms-sm4" />
                                    <Option {...leap_HealthFacilityStateID} className="ms-sm1" type="horizontal" />
                                </Row>
                                <Row>
                                    <Text {...denomination} className="ms-sm6" />
                                </Row>
                                <Row>
                                    <SearchBox {...nationID} className="ms-sm5" />
                                    <SearchBox {...regionID} className="ms-sm5" />
                                </Row>
                                <Row>
                                    <SearchBox {...provinceID} className="ms-sm5" />
                                    <SearchBox {...municipalityID} className="ms-sm5" />
                                </Row>
                                <Row>
                                    <Text {...lastnameLegal} className="ms-sm3" />
                                    <Text {...firstnameLegal} className="ms-sm3" />
                                </Row>
                                <Row>
                                    <Text {...lastnameDoctor} className="ms-sm3" />
                                    <Text {...firstnameDoctor} className="ms-sm3" />
                                </Row>
                                <Row>
                                    <MultiBox {...healthAgreementID} label="convenzioni" className="ms-sm5" titleType='text' />
                                    <Text {...sanitaryDistrict} className="ms-sm2" />
                                </Row>
                                <Row>
                                    <Option {...leap_HealthFacilityOnlineAgendaID} className="ms-sm2" type="horizontal" />
                                    <Option {...leap_HealthFacilityMembershipWelfareID} className="ms-sm2" type="horizontal" />
                                    <Option {...leap_HealthFacilityWelfareWarrantID} className="ms-sm2" type="horizontal" />
                                    <Option {...hasRateTable} className="ms-sm2" type="horizontal" />
                                    <Option {...leap_HealthFacilityVariationAcceptanceLowerRateID} className="ms-sm2" type="horizontal" />
                                </Row>
                                <Row>
                                    <MultiBox {...leap_HealthFacilityStructureTypeID} className="ms-sm5" titleType='text' />
                                </Row>

                                <Row>
                                    <DateRange dateMin={{ ...affiliationDateFrom, className: "ms-sm2" }} dateMax={{ ...affiliationDateTo, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <DateRange dateMin={{ ...terminationDateFrom, className: "ms-sm2" }} dateMax={{ ...terminationDateTo, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <DateRange dateMin={{ ...membershipWelfareDateFrom, className: "ms-sm2" }} dateMax={{ ...membershipWelfareDateTo, className: "ms-sm2" }} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...compatiblePlans} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                    <MultiSearchBox {...notCompatiblePlans} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...compatibleBranches} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                    <MultiSearchBox {...notCompatibleBranches} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                                <Row>
                                    <MultiSearchBox {...compatibleHealthServices} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                    <MultiSearchBox {...notCompatibleHealthServices} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem >
                        <PivotItem headerText="medici" itemKey="medics">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm3"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="estrai medici"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={extractMedic} style={{ cursor: "pointer", background: "transparent" }} id={"extractMedic"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <MultiSearchBox {...healthFacilityMedic} className="ms-sm12" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="variazioni anagrafiche" itemKey="registry">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm3"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="estrai variazioni"
                                        readOnly
                                        onRenderSuffix={() => { return registryDisabled ? <></> : <><Icon iconName="Download" onClick={extractRegistry} style={{ cursor: "pointer", background: "transparent" }} id={"extractRegistry"} /></> }} />
                                    <Label styles={noDataFoundLabel}>
                                        selezionare l'intervallo di date
                                    </Label>
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange dateMin={{ ...from, className: "ms-sm2" }} dateMax={{ ...to, className: "ms-sm2" }} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="tariffe diverse da massimale" itemKey="rate">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm3"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="estrai tariffe"
                                        readOnly
                                        onRenderSuffix={() => { return rateDisabled ? <></> : <><Icon iconName="Download" onClick={extractRate} style={{ cursor: "pointer", background: "transparent" }} id={"extractRate"} /></> }} />
                                    <Label styles={noDataFoundLabel}>
                                        selezionare almeno una prestazione
                                    </Label>
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <MultiSearchBox {...rateHealthServices} className="ms-sm12" itemLimit={multiSearchBoxItemLimit} />
                                </Row>
                            </Section>
                        </PivotItem>
                        <PivotItem headerText="strutture senza piani di cura attivi" itemKey="noNet">
                            <Section title=" ">
                                <Row>
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm3"
                                        label=""
                                        name=""
                                        type="plainWithIcon"
                                        value="estrai ss senza network"
                                        readOnly
                                        onRenderSuffix={() => { return <><Icon iconName="Download" onClick={extractNoNetwork} style={{ cursor: "pointer", background: "transparent" }} id={"extractNoNet"} /></> }} />
                                </Row>
                            </Section>
                            <Section title=" ">
                                <Row>
                                    <DateRange
                                        dateMin={{ ...startDate, className: "ms-sm2" }}
                                        dateMax={{ ...endDate, className: "ms-sm2" }} />
                                </Row>
                            </Section>
                        </PivotItem>
                    </Pivot>
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};
