import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Codice Operatore' fieldName='code' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Accesso' fieldName='leap_HealthFacilityOperatorAccessID' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column3' name='Cognome' fieldName='lastname' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column4' name='Nome' fieldName='firstname' minWidth={200} maxWidth={200} align='left' />
                <DataColumn kid='column5' name='Email' fieldName='email' minWidth={250} align='left' />
                <DataColumn kid='column6' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
