import { DefaultButton, Dialog, DialogFooter, DialogType, FontIcon, FontSizes, FontWeights, IButtonProps, Icon, IDialogProps, Pivot, PivotItem, PrimaryButton, Checkbox } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import ComboBox from '../components/controls/ComboBox';
import FileUpload from '../components/controls/FileUpload';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import { filterForm, filterValidation } from '../features/operation/thunks/filterThunk';
import PlanPurchaseQuoteList from '../features/planPurchase/PlanPurchaseQuoteList';
import PlanPurchaseDocumentsList from '../features/planPurchase/PlanPurchaseDocumentsList';
import PlanPurchasePrivacyDocumentList from '../features/planPurchase/PlanPurchasePrivacyDocumentList';
import PlanPurchaseFamilyCoreList from '../features/planPurchase/PlanPurchaseFamilyCoreList';
import { setResponse, resetResponse } from '../features/response/responseSlice';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import Separator from '../components/controls/grid/Separator';
import { itemDownload } from '../features/operation/thunks/itemThunk';
import { lock, unlock } from '../features/operation/lockSlice';
import MapsModal from '../features/maps/MapsModal';
import SearchBox from '../components/controls/SearchBox';

const dialogContentPropsInsertRelative = {
    type: DialogType.normal,
    title: 'Inserisci familiare',
    closeButtonAriaLabel: 'Chiudi',
    subText: '',
};
const dialogStyles = { main: { maxWidth: 450 } };
const iconStyle: React.CSSProperties = {
    color: Theme.fluent.palette.themePrimary,
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    fontSize: '1.2em'
}
const divStyle: React.CSSProperties = {
    flexDirection: "row",
    fontSize: '14px',
    marginBottom: 10,
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    paddingLeft: 10,
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    cursor: "pointer",
}
const textStyle: React.CSSProperties = {
    verticalAlign: "top",
    paddingLeft: 10,
    fontFamily: 'Montserrat',
    fontWeight: "normal",
    fontSize: "14"
}
const ButtonProps: IButtonProps = {
    styles: {
        root: {
            marginLeft: 4,
            marginRight: 4
        }
    }
}

export const purchase = createAsyncThunk(
    'item/Purchase',
    async (args: { form: any, relatives: Relative[] | undefined }) => {
        const response = await client.post("PlanPurchase", { form: { ...args.form, relatives: args.relatives } }, 'Purchase', undefined)
        return response
    }
)

type Relative = {
    fiscalcode: string,
    firstname: string,
    lastname: string,
    relationshipTier: string,
    relationshipTierDescr: string,
    email: string,
    familyPlanID: string,
    planPurchaseQuoteID: string,
    planPurchaseQuoteFee: number
}
type Document = {
    description: string
}
type PrivacyDocument = {
    id: number,
    fileID: number,
    description: string,
    fileName: string,
    isOnhcFile: boolean,
    downloaded: boolean
}

export default () => {
    const controller = "PlanPurchase"
    const planPurchaseFamilyCoreController = "PlanPurchaseFamilyCore"

    const page = useLeapState(controller)

    const urls = useLocation()?.pathname.split('/').reverse()
    const url = urls[0] == "" ? urls[1] : ""

    const fForm = useSelector(filterForm)
    const form = fForm.find(i => i.key === controller)?.value
    const validation = useSelector(filterValidation)

    const dispatch: AppDispatch = useDispatch()
    const credential = useSelector(security)?.credential

    useEffect(() => {
        dispatch(getPlanToPurchase({ controller }));
    }, [url])

    //campi del piano sanitario
    const [welcomeMessage, setWelcomeMessage] = React.useState<any>();
    const [description, setDescription] = React.useState<any>();
    const [link, setLink] = React.useState<any>();
    const [membershipFee, setMembershipFee] = React.useState<any>();
    const [planID, setPlanID] = React.useState<any>();
    const [planName, setPlanName] = React.useState<any>();
    const [isCorporationVisible, setIsCorporationVisible] = React.useState<any>();
    const [isCorporationRequired, setIsCorporationRequired] = React.useState<any>();
    const [billingRequired, setBillingRequired] = React.useState<boolean>(false);
    const [canAssociateMembers, setCanAssociateMembers] = React.useState<boolean>(false);

    const [familyPlans, setFamilyPlans] = React.useState<any>();
    const getFamilyDegrees: any = () => {
        const familyDegreeQuotes = planPurchaseQuotes.flatMap(ppq => ppq.kinshipDegreeIDs)
        const familyDegreeUse = planPurchaseFamilyCore.map(ppfc => ppfc.relationshipTier)
        const occurrences = familyDegreeUse.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());

        const familyDegrees = page?.filter?.state?.lookups?.familydegree?.filter(fd => familyDegreeQuotes.includes(fd.key))
        const usableFamilyDegrees = familyDegrees?.filter((fd) => {
            if (familyDegreeQuotes == null) {
                return true
            }
            if (planPurchaseQuotes == null) {
                return true
            }

            const quotes = planPurchaseQuotes.filter(ppq => ppq.kinshipDegreeIDs.some(kdi => kdi === fd.key))
            const thresholdDegrees = quotes.flatMap(ppq => ppq.kinshipDegreeIDs)
            const thresholdValue = quotes.map(ppq => ppq.threshold)

            let occurence = 0;
            occurrences.forEach((v, k) => {
                if (thresholdDegrees.some(td => td === k)) {
                    occurence += v;
                }
            });

            if (thresholdValue.some(t => t === "" || Number(t) > occurence))
                return true

            return false
        })

        return usableFamilyDegrees
    }

    const [rFiscalcode, setRFiscalcode] = React.useState<any>();
    const [rLastname, setRLastname] = React.useState<any>();
    const [rFirstname, setRFirstname] = React.useState<any>();
    const [rEmail, setREmail] = React.useState<any>();
    const [rPhoneNumber, setRPhoneNumber] = React.useState<any>();
    const [rCompany, setRCompany] = React.useState<any>();
    const [rBillingAddress, setRBillingAddress] = React.useState<any>();
    const [rBillingAddressNumber, setRBillingAddressNumber] = React.useState<any>();
    const [rBillingCAP, setRBillingCAP] = React.useState<any>();
    const [rBillingMunicipalityID, setRBillingMunicipalityID] = React.useState<any>();
    const [rAffiliationDate, setRAffiliationDate] = React.useState<any>();

    const [total, setTotal] = React.useState<any>();

    const welcomeField = useLeapData(welcomeMessage, "messaggio di benvenuto", page)
    const descField = useLeapData(description, "descrizione", page)
    const nameField = useLeapData(planName, "nome piano", page)
    const membershipFeeField = useLeapData(membershipFee, "quota associativa", page)

    //gestione degli step di registrazione
    const [phase, setPhase] = React.useState<any>();

    const [planPurchaseQuotes, setPlanPurchaseQuotes] = React.useState<any[]>([]);
    const [PlanPurchaseDocuments, setPlanPurchaseDocuments] = React.useState<Document[]>([]);
    const [privacyDocuments, setPrivacyDocuments] = React.useState<PrivacyDocument[]>([]);
    const [planPurchaseFamilyCore, setPlanPurchaseFamilyCore] = React.useState<Relative[]>([]);

    //campi dell'aderente 
    const fiscalcode = useLeapData("fiscalcode", "codice fiscale", page)
    const lastname = useLeapData("lastname", "cognome", page)
    const firstname = useLeapData("firstname", "nome", page)
    const email = useLeapData("email", "email", page)
    const phoneNumber = useLeapData("phoneNumber", "telefono", page)
    const company = useLeapData("company", "azienda", page)
    const billingAddress = useLeapData("billingAddress", "indirizzo fatturazione", page)
    const billingAddressNumber = useLeapData("billingAddressNumber", "Numero", page)
    const billingCAP = useLeapData("billingCAP", "CAP", page)
    const billingMunicipalityID = useLeapData("billingMunicipalityID", "comune", page, "billingMunicipalities")
    const affiliationDate = useLeapData("affiliationDate", "data adesione", page)
    const attachmentID = useLeapData("attachmentID", "attachmentID", page)

    //campi del familiare
    const fiscalcodeRel = useLeapData("fiscalcodeRel", "codice fiscale", page)
    const lastnameRel = useLeapData("lastnameRel", "cognome", page)
    const firstnameRel = useLeapData("firstnameRel", "nome", page)
    const emailRel = useLeapData("emailRel", "email", page)
    const leap_relationshipTierID = useLeapData("leap_relationshipTierID", "grado di parentela", page, "familydegree")
    const familyPlanID = useLeapData("familyPlanID", "piano sanitario", page, "familyPlans")

    const getPlanToPurchase = createAsyncThunk(
        'item/getPlanToPurchase',
        async (args: { controller: string }) => {
            const response = await client.post(args.controller, { form: { ...form, "link": url }, page: page }, 'getPlanToPurchase', credential?.token)

            if (response?.response?.level !== 4) {
                document.location.href = '/login/'
                return
            }

            setWelcomeMessage(response.data?.welcomeMessage);
            setDescription(response.data?.description);
            setLink(response.data?.link);
            setMembershipFee(response.data?.membershipFee);
            setPlanID(response.data?.planID);
            setPlanName(response.data?.planName);
            setIsCorporationRequired(response.data?.isCorporationRequired)
            setIsCorporationVisible(response.data?.isCorporationVisible)
            setPlanPurchaseDocuments(response.lists.PlanPurchaseDocuments)
            setPlanPurchaseQuotes(response.lists.PlanPurchaseQuotes ?? [])

            setPrivacyDocuments(response.lists.PrivacyFiles)

            setBillingRequired(!!response.data?.billingRequired)
            setCanAssociateMembers(!!response.data?.canAssociateMembers)

            setFamilyPlans(response.lists?.FamilyPlans)

            setPhase(0);
            return response
        })

    const handlePhases = (action: 'Forward' | 'Back' = 'Forward') => {
        if (action === 'Back') {
            const newPhase = phase === 1 ? 1 : phase - 1
            if (newPhase === 3 && !canAssociateMembers) {
                setPhase(2);
                return
            }

            setPhase(newPhase);
            return;
        }

        switch (phase) {
            case 0:
                setPhase(1);
                break;

            case 1:
                setPhase(2);
                break;

            case 2:
                const mandatory = !!validation.find(v => v.key === controller)?.value?.mandatory
                if (!mandatory) {
                    dispatch(setResponse("Per procedere alla sottoscrizione del piano è necessario compilare tutti i campi richiesti.", 5, true))
                    toggleHideMessageBoxMandatory()
                    return;
                }

                if (!/^[A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1}$/.test(pageFilterValue('fiscalcode'))) {
                    dispatch(setResponse("Il formato del Codice Fiscale non è corretto.", 5, true))
                    toggleHideMessageBoxFiscalCode()
                    return;
                }
                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(pageFilterValue('email'))) {
                    dispatch(setResponse("Il formato del Email non è corretto.", 5, true))
                    toggleHideMessageBoxEmail()
                    return;
                }
                const cap = pageFilterValue('billingCAP')
                if (!!cap && !/^[0-9]{5}$/.test(cap)) {
                    dispatch(setResponse("Il formato del CAP non è corretto.", 5, true))
                    toggleHideMessageBoxCAP()
                    return;
                }

                dispatch(resetResponse(false))

                setRFiscalcode(pageFilterValue('fiscalcode'))
                setRLastname(pageFilterValue('lastname'))
                setRFirstname(pageFilterValue('firstname'))
                setREmail(pageFilterValue('email'))
                setRPhoneNumber(pageFilterValue('phoneNumber'))
                setRCompany(pageFilterValue('company'))
                setRBillingAddress(pageFilterValue('billingAddress'))
                setRBillingAddressNumber(pageFilterValue('billingAddressNumber'))
                setRBillingCAP(pageFilterValue('billingCAP'))
                setRBillingMunicipalityID(pageFilterValue('billingMunicipalityID'))
                setRAffiliationDate(pageFilterValue('affiliationDate'))

                if (!!canAssociateMembers) {
                    setPhase(3);
                }
                else {
                    setPhase(4);
                }

                setTotal(Number(membershipFee))

                break;

            case 3:
                dispatch(resetResponse(false))
                setPhase(4);

                break;

            case 4:
                if (!downloadedEveryDocument) {
                    dispatch(setResponse("Per procedere alla sottoscrizione del piano è necessario scaricare tutti i files.", 5, true))
                    toggleHideMessageBoxMandatory()
                    return;
                }

                dispatch(purchase({
                    form: {
                        firstname: pageFilterValue('firstname'),
                        lastname: pageFilterValue('lastname'),
                        fiscalcode: pageFilterValue('fiscalcode'),
                        email: pageFilterValue('email'),
                        phoneNumber: pageFilterValue('phoneNumber'),
                        affiliationDate: pageFilterValue('affiliationDate'),
                        company: pageFilterValue('company'),
                        attachmentID: pageFilterValue('attachmentID'),
                        billingAddress: pageFilterValue('billingAddress'),
                        billingAddressNumber: pageFilterValue('billingAddressNumber'),
                        billingCAP: pageFilterValue('billingCAP'),
                        billingMunicipalityID: pageFilterValue('billingMunicipalityID'),
                        link: url,
                        fileIDs: privacyDocuments.map(i => i.fileID)
                    }, relatives: planPurchaseFamilyCore
                })).then(() => setPhase(5))
                break;
            default:
                break;
        }
    }

    const labelId: string = useId('dialogLabel');

    const [hideMessageBoxMandatory, { toggle: toggleHideMessageBoxMandatory, setFalse: showMessageBoxMandatory }] = useBoolean(true)
    const dialogPropsMandatory: IDialogProps = {
        hidden: hideMessageBoxMandatory,
        onDismiss: toggleHideMessageBoxMandatory,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione non riuscita",
            subText: "Per procedere alla sottoscrizione del piano è necessario compilare tutti i campi richiesti.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-warning"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const [hideMessageBoxFiscalCode, { toggle: toggleHideMessageBoxFiscalCode, setFalse: showMessageBoxFiscalCode }] = useBoolean(true)
    const dialogPropsFiscalCode: IDialogProps = {
        hidden: hideMessageBoxFiscalCode,
        onDismiss: toggleHideMessageBoxFiscalCode,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione non riuscita",
            subText: "Il formato del Codice Fiscale non è corretto.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-warning"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const [hideMessageBoxCAP, { toggle: toggleHideMessageBoxCAP, setFalse: showMessageBoxCAP }] = useBoolean(true)
    const dialogPropsCAP: IDialogProps = {
        hidden: hideMessageBoxCAP,
        onDismiss: toggleHideMessageBoxCAP,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione non riuscita",
            subText: "Il formato del CAP non è corretto.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-warning"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const [hideMessageBoxEmail, { toggle: toggleHideMessageBoxEmail, setFalse: showMessageBoxEmail }] = useBoolean(true)
    const dialogPropsEmail: IDialogProps = {
        hidden: hideMessageBoxEmail,
        onDismiss: toggleHideMessageBoxEmail,
        minWidth: 400,
        maxWidth: 600,
        dialogContentProps: {
            type: DialogType.normal,
            closeButtonAriaLabel: 'Chiudi',
            title: "Operazione non riuscita",
            subText: "Il formato della Email non è corretto.",
            titleProps: {
                color: Theme.fluent.palette.themePrimary,
                className: "leap-messagebox leap-messagebox-warning"
            },
            styles: {
                innerContent: {
                    minHeight: 50
                },
                title: {
                    color: Theme.fluent.palette.neutralPrimary,
                    alignItems: 'center',
                    fontFamily: 'Raleway',
                    fontSize: FontSizes.large,
                    fontWeight: FontWeights.semibold,
                    padding: '20px 12px 14px 24px',
                }
            }
        }
    }
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );

    const [hideDialogInsertRelative, { toggle: toggleHideDialogInsertRelative }] = useBoolean(true);

    const onExecuteInsert = (e: any) => {
        if ([page?.filter?.values?.fiscalcodeRel?.value,
        page?.filter?.values?.firstnameRel?.value,
        page?.filter?.values?.lastnameRel?.value,
        page?.filter?.values?.familyPlanID?.value]
            .some(i => !i || i === "" || i === undefined)) {

            dispatch(setResponse("Per procedere alla sottoscrizione del piano è necessario compilare tutti i campi richiesti..", 5, true))
            toggleHideMessageBoxMandatory()
            return;
        }

        if (!/^[A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1}$/.test(page?.filter?.values?.fiscalcodeRel?.value)) {
            dispatch(setResponse("Il formato del codice fiscale non è corretto.", 5, true))
            toggleHideMessageBoxFiscalCode()
            return;
        }

        const rmail = page?.filter?.values?.emailRel?.value
        if (!!rmail && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(rmail)) {
            dispatch(setResponse("Il formato della Email non è corretto.", 5, true))
            toggleHideMessageBoxEmail()
            return;
        }

        const familyDegreeID = page?.filter?.values?.leap_relationshipTierID?.value
        const planPurchaseOccurrences = planPurchaseFamilyCore.map(ppfc => ppfc.planPurchaseQuoteID).reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map())

        const purchasableQuotes = planPurchaseQuotes.filter((ppq) => {
            if (!ppq.kinshipDegreeIDs.some(kdi => kdi === familyDegreeID))
                return false

            const occurrence = planPurchaseOccurrences.get(ppq.id) ?? 0

            if (occurrence < ppq.threshold) {
                return true
            }
            if (ppq.threshold === "") {
                return true
            }

            return false
        }).sort((a, b) => { return a.id - b.id })

        if (purchasableQuotes.length == 0) {
            return false
        }

        setTotal(Number(total) + Number(purchasableQuotes[0].membershipFee?.replace(",", ".")))

        const newRewlative: Relative = {
            fiscalcode: page?.filter?.values?.fiscalcodeRel?.value,
            firstname: page?.filter?.values?.firstnameRel?.value,
            lastname: page?.filter?.values?.lastnameRel?.value,
            email: page?.filter?.values?.emailRel?.value,
            relationshipTier: page?.filter?.values?.leap_relationshipTierID?.value,
            relationshipTierDescr: page?.filter?.state?.lookups?.familydegree?.find((fd: any) => fd.key === page?.filter?.values?.leap_relationshipTierID?.value)?.text,
            familyPlanID: page?.filter?.values?.familyPlanID?.value,
            planPurchaseQuoteID: purchasableQuotes[0].id,
            planPurchaseQuoteFee: Number(purchasableQuotes[0].membershipFee?.replace(",", "."))
        };

        setPlanPurchaseFamilyCore([...planPurchaseFamilyCore, newRewlative])
        dispatch(resetResponse(false))
        toggleHideDialogInsertRelative()
    }

    const pageFilterValue = (fieldName: string): string | undefined => page?.filter?.values?.[fieldName]?.value

    const openFamilyInsertDialog = (e) => {
        e?.preventDefault()
        toggleHideDialogInsertRelative()
    }
    const farItems = [
        <div style={divStyle} onClick={openFamilyInsertDialog}>
            <FontIcon iconName={"CalculatorAddition"} style={iconStyle} />
            <span style={textStyle}>inserisci familiare</span>
        </div >
    ]

    const [acceptedDeclaration, setAcceptedDeclaration] = React.useState(false);
    const onChangeDeclaration = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setAcceptedDeclaration(!!checked);
        }, []);
    const declarationText = "Cliccando sul pulsante \"completa la procedura\" confermi di essere il titolare dei dati inseriti e di voler procedere con la registrazione per l'accesso ai servizi online di ONHC - Odontonetwork. \n\nRiceverai una mail all'indirizzo indicato con le istruzioni per completare la registrazione"

    const handleDownload = (ev: any) => {
        dispatch(lock())

        const args = {
            controller: controller,
            id: ev?.id,
            fileID: ev?.fileID,
            fileName: ev?.fileName,
            token: credential?.token
        }

        dispatch(itemDownload(args))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))

        let doc = privacyDocuments.find(i => i.fileID == ev.fileID)
        if (doc) {
            doc.downloaded = true
        }

        if (privacyDocuments.every(i => i.downloaded)) {
            setDownloadedEveryDocument(true)
        }
    }

    const [downloadedEveryDocument, setDownloadedEveryDocument] = React.useState(false)

    const toolbar = <>
        <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", borderRadius: 4, margin: "10px auto 10px", padding: "15px 20px", width: "60%", height: "auto", display: "flex", justifyContent: "end" }} >
            {phase > 1 && phase < 5 && <DefaultButton
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handlePhases('Back')}
            >
                <Icon iconName="Back" />&nbsp;Indietro
            </DefaultButton>}

            <div style={{ marginLeft: 10 }} />

            {phase < 4 && <DefaultButton
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => handlePhases()}
            >
                Avanti &nbsp; <Icon iconName="Forward" />
            </DefaultButton>}

            {phase === 4 && <PrimaryButton {...ButtonProps} text='completa la procedura' onClick={() => handlePhases()} disabled={!downloadedEveryDocument || !acceptedDeclaration /* _isLocked */} />}
        </div>
    </>

    return <>
        <Dialog {...dialogPropsMandatory} >
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBoxMandatory} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <Dialog {...dialogPropsFiscalCode} >
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBoxFiscalCode} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <Dialog {...dialogPropsEmail} >
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBoxEmail} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        <Dialog {...dialogPropsCAP} >
            <DialogFooter>
                <PrimaryButton onClick={toggleHideMessageBoxCAP} text="Chiudi" />
            </DialogFooter>
        </Dialog>
        {toolbar}
        <div style={{ backgroundColor: "#fff", border: "1px solid #d0d0d0", borderRadius: 4, margin: "10px auto 10px", padding: "10px 20px 0", width: "60%", height: "auto" }} >
            <Pivot className="ms-Pivot-PlanPurchase" selectedKey={String(phase)} >
                <PivotItem headerText='benvenuto' style={{ marginTop: 10 }} itemKey='0'>
                    <Grid>
                        <Section title="">
                            <Row>
                                <span>{welcomeMessage}</span>
                            </Row>
                            <MapsModal controller={controller} state={page} latitude={undefined} longitude={undefined} planID={planID} mapType="view" />
                        </Section>
                    </Grid>
                </PivotItem>
                <PivotItem headerText='panoramica del piano sanitario' style={{ marginTop: 10 }} itemKey='1'>
                    <Grid>
                        <Section title="">
                            <Row>
                                <Text {...nameField} value={planName} className={!!membershipFee ? "ms-sm9" : "ms-sm12"} readOnly />
                                {!!membershipFee && <Text {...membershipFeeField} value={membershipFee} className="ms-sm3" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />}
                            </Row>
                            <Row>
                                <Text type="multiline" {...descField} value={description} className="ms-sm12" readOnly rows={5} />
                            </Row>
                        </Section>
                        {canAssociateMembers && !!planPurchaseQuotes && planPurchaseQuotes.length > 0 && <>
                            <Section title="quote familiari" toolbar="grid" modalwidth="600px" state={page} >
                                <Row>
                                    <PlanPurchaseQuoteList items={planPurchaseQuotes} controller={controller} state={page} />
                                </Row>
                            </Section>
                        </>}
                        <Section title="documentazione" toolbar="grid" modalwidth="600px" state={page} >
                            <Row>
                                <PlanPurchaseDocumentsList items={PlanPurchaseDocuments} controller={controller} state={page} />
                            </Row>
                        </Section>
                    </Grid>
                </PivotItem>
                <PivotItem headerText={'inserisci i tuoi dati anagrafici'} style={{ marginTop: 10 }} itemKey='2'>
                    <Grid>
                        <Section title="dati aderente">
                            <Row>
                                <Text {...firstname} value={rFirstname ?? firstname.value} className="ms-sm4" required maxlength={50} />
                                <Text {...lastname} value={rLastname ?? lastname.value} className="ms-sm4" required maxlength={50} />
                                <Text {...fiscalcode} value={rFiscalcode ?? fiscalcode.value} className="ms-sm4" required maxlength={50} />
                            </Row>
                            <Row>
                                <Text {...email} value={rEmail ?? email.value} className="ms-sm4" required maxlength={50} />
                                <Text {...phoneNumber} value={rPhoneNumber ?? phoneNumber.value} className="ms-sm4" required maxlength={50} />
                                <Text {...affiliationDate} value={rAffiliationDate ?? affiliationDate.value} className="ms-sm4" type="calendar" required />
                            </Row>
                            <Row hidden={isCorporationVisible == 2}>
                                <Text {...company} value={rCompany ?? company.value} className="ms-sm8" required={isCorporationVisible == 1 && isCorporationRequired == 1} maxlength={50} />
                            </Row>
                            {(!!membershipFee || (!!planPurchaseQuotes && planPurchaseQuotes.length > 0)) && <>
                                <Row>
                                    <Text {...billingAddress} value={rBillingAddress ?? billingAddress.value} className="ms-sm6" required={billingRequired} maxlength={500} />
                                    <Text {...billingAddressNumber} value={rBillingAddressNumber ?? billingAddressNumber.value} className="ms-sm1" maxlength={8} />
                                    <Text {...billingCAP} value={rBillingCAP ?? billingCAP.value} className="ms-sm1" maxlength={5} />
                                    <SearchBox {...billingMunicipalityID} value={rBillingMunicipalityID ?? billingMunicipalityID.value} required={billingRequired} className="ms-sm4" />
                                </Row>
                            </>}
                        </Section>
                    </Grid>
                </PivotItem>
                <PivotItem headerText={'aggiungi i dati anagrafici del tuo nucleo familiare'} style={{ marginTop: 10 }} itemKey={'3'}>
                    <Section title=" " toolbar="grid" state={page} farItems={farItems}>
                        <Row>
                            <PlanPurchaseFamilyCoreList items={planPurchaseFamilyCore} controller={planPurchaseFamilyCoreController} state={page} />
                        </Row>
                        <div style={{ height: 24 }} />
                        <Dialog hidden={hideDialogInsertRelative} minWidth="600px" onDismiss={toggleHideDialogInsertRelative} dialogContentProps={dialogContentPropsInsertRelative} modalProps={modalProps} >
                            <Grid>
                                <Section>
                                    <Row>
                                        <Text {...firstnameRel} className="ms-sm6" maxlength={50} required />
                                        <Text {...lastnameRel} className="ms-sm6" maxlength={50} required />
                                    </Row>
                                    <Row>
                                        <Text {...fiscalcodeRel} className="ms-sm6" maxlength={16} required />
                                        <Text {...emailRel} className="ms-sm6" maxlength={50} />
                                    </Row>
                                    <Row>
                                        <ComboBox {...leap_relationshipTierID} className="ms-sm6" options={getFamilyDegrees()} required />
                                    </Row>
                                    <Row>
                                        <ComboBox {...familyPlanID} className="ms-sm12" options={familyPlans} required />
                                    </Row>
                                </Section>
                            </Grid>
                            <DialogFooter>
                                <PrimaryButton onClick={onExecuteInsert} text='inserisci' />
                                <DefaultButton onClick={toggleHideDialogInsertRelative} text="annulla" />
                            </DialogFooter>
                        </Dialog>
                    </Section>
                </PivotItem>
                <PivotItem headerText={'completa la procedura di adesione'} style={{ marginTop: 10 }} itemKey={'4'}>
                    {!!membershipFee && <Section title="informazioni per il pagamento">
                        <Row>
                            <FileUpload {...attachmentID} className="ms-sm12" label="attestazione pagamento" controller={controller} />
                        </Row>
                        {!!total && total > 0 && <Text {...membershipFeeField} value={total.toString()} className="ms-sm3" type="number" precision={{ integer: 10, decimal: 2 }} readOnly />}
                    </Section>}
                    {privacyDocuments.filter(i => i.isOnhcFile)?.length > 0 ?
                        <Section title="odontonetwork">
                            <Row>
                                <PlanPurchasePrivacyDocumentList items={privacyDocuments.filter(i => i.isOnhcFile)} controller={controller} state={page} onDownload={handleDownload} />
                            </Row>
                        </Section> :
                        <></>
                    }
                    {privacyDocuments.filter(i => !i.isOnhcFile)?.length > 0 ?
                        <Section title="piano sanitario">
                            <Row>
                                <PlanPurchasePrivacyDocumentList items={privacyDocuments.filter(i => !i.isOnhcFile)} controller={controller} state={page} onDownload={handleDownload} />
                            </Row>
                        </Section> :
                        <></>
                    }
                    <Section title=" ">
                        <Text
                            className="ms-sm12"
                            readOnly
                            onChange={() => { }}
                            label=""
                            name=""
                            type="multiline"
                            value={declarationText}
                            rows={7}
                        />
                        <Row>
                            &nbsp;
                        </Row>
                        <Row>
                            <Separator className="ms-sm8" />
                            <Checkbox className="ms-Grid-col ms-sm4" label="conferma dati" checked={acceptedDeclaration} onChange={onChangeDeclaration} disabled={!downloadedEveryDocument} />
                        </Row>
                        <div style={{ paddingBottom: 25 }} />
                    </Section>
                </PivotItem>
                <PivotItem headerText='richiesta inviata' style={{ marginTop: 10 }} itemKey='5'>
                    <Grid>
                        <Section title="">
                            <Row>
                                <span>messaggio completamento</span>
                            </Row>
                        </Section>
                    </Grid>
                </PivotItem>
            </Pivot>
        </div>
        {toolbar}
    </>
}