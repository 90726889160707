import { FontSizes, IComboBoxOption, Icon, ILabelStyles, Label } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { client } from '../api/client';
import { AppDispatch } from '../app/store';
import Content, { Filter, Item, Result } from '../components/blueprints/core/Content';
import ComboBox from '../components/controls/ComboBox';
import DateRange from '../components/controls/DateRange';
import { DentalElements } from '../components/controls/dental/dentalElements';
import Grid from '../components/controls/grid/Grid';
import Row from '../components/controls/grid/Row';
import Section from '../components/controls/grid/Section';
import MultiBox from '../components/controls/MultiBox';
import MultiSearchBox from '../components/controls/MultiSearchBox';
import Text from '../components/controls/Text';
import Theme from '../components/themes/Theme';
import { FontFaces } from '../defs/fonts';
import { lock, unlock } from '../features/operation/lockSlice';
import { filterForm } from '../features/operation/thunks/filterThunk';
import { itemPrint } from '../features/operation/thunks/itemThunk';
import { security } from '../features/security/thunks/securityThunk';
import { useLeapData } from '../hooks/useLeapData';
import { useLeapState } from '../hooks/useLeapState';
import Separator from '../components/controls/grid/Separator';

const labelStyle: ILabelStyles = {
    root: {
        color: Theme.leap.main.title.color,
        fontFamily: FontFaces.montserrat,
        fontSize: FontSizes.medium,
        fontWeight: 500,
        textAlign: "inherit",
        lineHeight: 30,
        marginTop: 20
    }
}

enum Ages {
    Equals = "1",
    Greater = "2",
    Less = "3",
    Between = "4"
}

export default () => {
    const controller = "Statistics"
    const page = useLeapState(controller)

    const dispatch: AppDispatch = useDispatch();

    const planManagementID = useLeapData("planManagementID", "tipo di gestione", page, "planManagementOptions")
    const planID = useLeapData("planID", "piano sanitario", page, "plans")
    const companyID = useLeapData("companyID", "compagnia assicurativa", page, "companies")
    const healthFacilityID = useLeapData("healthFacilityID", "struttura sanitaria", page, "healthfacilities")
    const corporationID = useLeapData("corporationID", "azienda", page, "corporations")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServicies")
    const branchID = useLeapData("branchID", "branca", page, "branch")
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)
    const healthFacilityTypeID = useLeapData("healthFacilityTypeID", "tipo di struttura sanitaria", page, "healthFacilityTypes")
    const leap_quoteManagementStateID = useLeapData("leap_quoteManagementStateID", "stato del piano di cura", page, "leap_quoteManagementStates")
    const provinceID = useLeapData("provinceID", "provincia", page, "provinces")
    const regionID = useLeapData("regionID", "regione", page, "regions")
    const sanitaryDistrict = useLeapData("sanitaryDistrict", "distretto sanitario", page)
    const municipalityID = useLeapData("municipalityID", "comune", page, "municipalities")
    const ageID = useLeapData("ageID", "età", page, "ageOptions")
    const minAge = useLeapData("minAge", "età minima", page)
    const maxAge = useLeapData("maxAge", "età massima", page)
    const exactAge = useLeapData("exactAge", "=", page)

    const from = useLeapData("from", "da", page)
    const to = useLeapData("to", "a", page)

    const viewType = useLeapData("viewType", "tipo grafico", page, "viewTypes")
    const printType = useLeapData("printType", "tipo estrazione", page, "printTypes")

    const multiSearchBoxItemLimit = 99;

    const credential = useSelector(security)?.credential

    const fForm = useSelector(filterForm)
    const formF = fForm.find(i => i.key === controller)?.value

    const downloadData = () => {
        dispatch(lock())

        const args = {
            controller: "Statistics",
            fileName: "datiStatistici.csv",
            token: credential?.token,
            lookup: formF?.printType
        }

        dispatch(downloadDataThunk(args))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }
    const downloadDataThunk = createAsyncThunk(
        'item/Download',
        async (args: {
            controller: string,
            token: string | undefined,
            fileName: string | undefined,
            lookup: string | undefined
        }) => {
            const response = await client.downloadPost(args.controller, { form: { ...formF, fileName: args.fileName, lookup: args.lookup }, page: page }, 'Download', undefined, args.token)
            return response
        }
    )
    const print = () => {
        dispatch(lock())
        dispatch(itemPrint({ controller, lookup: (formF?.printType ?? ""), body: { form: { ...formF }, page: {} }, token: credential?.token }))
            .then(() => dispatch(unlock()))
            .catch(() => dispatch(unlock()))
    }

    const selectedAge = Object.entries(Ages).find(([, value]) => value === formF?.ageID)?.[1];

    const exactAge_show = selectedAge === Ages.Equals
    const minAge_show = selectedAge === Ages.Greater || selectedAge === Ages.Between
    const maxAge_show = selectedAge === Ages.Less || selectedAge === Ages.Between

    const minAge_value = formF?.minAge
    const maxAge_value = formF?.maxAge

    const viewTypeSelected = !!formF?.viewType
    const printTypeSelected = !!formF?.printType
    const fromSelected = !!formF?.from
    const toSelected = !!formF?.to

    const dentalElements: IComboBoxOption[] = DentalElements

    const dateRangeRequired = !['15', '16', '24', '25', '26', '61', '62', '63',].some(i => i === formF?.printType)

    const emptyViewTypeLookup = page.filter?.state?.lookups?.viewTypes?.length < 1;

    return <>
        <Content title="Statistiche" controller={controller} noResult>
            <Filter>
                <Grid>
                    <Section toolbar="filter" controller={controller}>
                    </Section>
                    <Section title=" ">
                        <Row>
                            <ComboBox {...printType} className="ms-sm10" required children={[{ ...viewType }]} />
                        </Row>
                    </Section>
                    <Section title="periodo" hidden={!printTypeSelected || formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '24' || formF?.printType === '25'}>
                        <Row hidden={!printTypeSelected || formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '24' || formF?.printType === '25'}>
                            <DateRange dateMin={{ ...from, className: "ms-sm2", required: dateRangeRequired }} dateMax={{ ...to, className: "ms-sm2", required: dateRangeRequired }} hidden={formF?.printType === '15'} />
                        </Row>
                    </Section>
                    <Section title="estrazione dati" hidden={!printTypeSelected}>
                        <Row hidden={!printTypeSelected}>
                            <Text
                                onChange={() => { }}
                                className="ms-sm2"
                                label="&nbsp;"
                                name=""
                                type="plainWithIcon"
                                value="scarica dati"
                                readOnly
                                onRenderSuffix={() => {
                                    return (printTypeSelected && (!dateRangeRequired || (fromSelected && toSelected))) ?
                                        <><Icon iconName="Download" onClick={downloadData} style={{ cursor: "pointer", background: "transparent" }} id={"extract"} /></> :
                                        <></>
                                }} />
                            {(!printTypeSelected || (dateRangeRequired && (!fromSelected || !toSelected))) &&
                                <Label styles={labelStyle} className="ms-Grid-col ms-sm7">
                                    *per poter scaricare i dati selezionare un intervallo di date
                                </Label>}
                        </Row>
                    </Section>
                    <Section title="stampa grafico" hidden={!printTypeSelected || emptyViewTypeLookup}>
                        <Row hidden={!printTypeSelected || emptyViewTypeLookup}>
                            {!emptyViewTypeLookup &&
                                <>
                                    <ComboBox {...viewType} className="ms-sm2" required />
                                    <Text
                                        onChange={() => { }}
                                        className="ms-sm2"
                                        label="&nbsp;"
                                        name=""
                                        type="plainWithIcon"
                                        value="stampa"
                                        readOnly
                                        onRenderSuffix={() => {
                                            return (viewTypeSelected && printTypeSelected && (!dateRangeRequired || (fromSelected && toSelected))) ?
                                                <><Icon iconName="Download" onClick={print} style={{ cursor: "pointer", background: "transparent" }} id={"extract"} /></> :
                                                <></>
                                        }} />
                                </>
                            }
                            {(!viewTypeSelected || !printTypeSelected || (dateRangeRequired && (!fromSelected || !toSelected))) &&
                                <Label styles={labelStyle} className="ms-Grid-col ms-sm7">
                                *per poter stampare, selezionare il tipo di grafico {dateRangeRequired ? 'e l\'intervallo di date' : ''}
                                </Label>}
                        </Row>
                    </Section>
                    {printTypeSelected &&
                        <Section title="filtri">
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26'}>
                                <MultiSearchBox {...planID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26'} />
                                <MultiBox {...planManagementID} className="ms-sm2" titleType="text" hidden={formF?.printType === '16' || formF?.printType === '26'} />
                            </Row>
                            <Row>
                                <MultiSearchBox {...companyID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26'} />
                                <MultiSearchBox {...corporationID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} />
                            </Row>
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'}>
                                <MultiSearchBox {...healthFacilityID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                            </Row>
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'}>
                                <MultiSearchBox {...branchID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                                <MultiSearchBox {...healthServiceID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                            </Row>
                            <Row hidden={formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'}>
                                <MultiBox  {...dentalElement} className="ms-sm5" options={dentalElements} hidden={formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                            </Row>
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'}>
                                <MultiBox {...healthFacilityTypeID} className="ms-sm5" titleType='text' hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                                <MultiBox {...leap_quoteManagementStateID} className="ms-sm5" titleType='text' hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '15' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                            </Row>
                            <Row hidden={formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'}>
                                <MultiSearchBox {...regionID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} />
                                <MultiSearchBox {...provinceID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} />
                            </Row>
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26'}>
                                <MultiSearchBox {...municipalityID} className="ms-sm5" itemLimit={multiSearchBoxItemLimit} hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} />
                            </Row>
                            <Row hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'}>
                                <Text {...sanitaryDistrict} className="ms-sm5" hidden={formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25' || formF?.printType === '61' || formF?.printType === '62' || formF?.printType === '63'} />
                            </Row>
                            <Row hidden={formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'}>
                                <ComboBox {...ageID} className="ms-sm2 ms-xxl1" hidden={formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} />
                                <Text {...minAge} className="ms-sm2 ms-xxl1" type="number" hidden={!minAge_show || formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} precision={{ integer: 3, decimal: 0, min: 0, max: maxAge_value ? Number(maxAge_value) : 120 }} />
                                <Text {...maxAge} className="ms-sm2 ms-xxl1" type="number" hidden={!maxAge_show || formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} precision={{ integer: 3, decimal: 0, min: minAge_value ? Number(minAge_value) : 0, max: 120 }} />
                                <Text {...exactAge} className="ms-sm2 ms-xxl1" type="number" hidden={!exactAge_show || formF?.printType === '15' || formF?.printType === '16' || formF?.printType === '26' || formF?.printType === '23' || formF?.printType === '24' || formF?.printType === '25'} precision={{ integer: 3, decimal: 0, min: 0, max: 120 }} />
                            </Row>
                        </Section>
                    }
                </Grid>
            </Filter>
            <Result>
                <Grid>
                    <Section toolbar="result" controller={controller}>
                    </Section>
                </Grid>
            </Result>
            <Item controller={controller}>
                <Grid>
                    <Section toolbar="item" controller={controller}>
                    </Section>
                </Grid>
            </Item>
        </Content>
    </>
};