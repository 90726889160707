import { Icon } from '@fluentui/react';
import * as React from 'react';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import DataList from '../../components/controls/datagrid/DataList';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    const roles = useSecurityRoles()
    const isONHC: boolean = roles.isONHC

    const onEditing = (e: any) => {
        if (e.nativeEvent.received === "yes") {
            e.cancel = true
            e.message = "Impossibile procedere, file già caricato"
        }
    }
    const onRenderIcon = (e: any) => {
        switch (e?.received) {
            case "yes":
                return <Icon iconName={"Accept"} style={{ color: 'green' }} />;
            case "no":
                return <Icon iconName={"Cancel"} style={{ color: 'red' }} />;
            case "receivedNoFile":
                return <Icon iconName={"CalculatorSubtract"} style={{ color: 'darkgray' }} />;
            default:
                return <></>
        }
    }

    return (
        <>
            <DataList {...props} items={props.items} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state} onEditing={onEditing}>
                <DataColumn kid='column1' name='Descrizione' fieldName='description' minWidth={200} align='left' />
                <DataColumn kid='column2' name='Data Inizio Validità' fieldName='startDate' minWidth={120} maxWidth={150} align='center' />
                <DataColumn kid='column3' name='Data Fine Validità' fieldName='endDate' minWidth={120} maxWidth={150} align='center' />
                {isONHC && <DataColumn kid='column4' name='Modalità' fieldName='privacyChannelType' minWidth={120} maxWidth={150} align='center' />}
                {isONHC && <DataColumn kid='columnA' name='Data Presa Visione' fieldName='acknowledgedDate' minWidth={120} maxWidth={150} align='center' />}
                {isONHC && <DataColumn kid='columnT' name='Ricevuto Con File' fieldName='received' minWidth={120} maxWidth={200} align='center' type='icon' onRenderIcon={onRenderIcon} />}
                <DataColumn kid='columnDL' name='' fieldName='' minWidth={10} maxWidth={10} align='center' type='download' />
                <DataColumn kid='columnE' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
