import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../../app/rootReducer'

import { IChoiceGroupOption, IComboBoxOption } from '@fluentui/react/';

interface PlanPayload {
    nullablePlanType: IComboBoxOption[],
    nullableManagementType: IComboBoxOption[],
    nullableYesNoChoice: IComboBoxOption[],
    planType: IChoiceGroupOption[],
    managementType: IChoiceGroupOption[],
    yesNoChoice: IChoiceGroupOption[],
    profitTypeChoice: IChoiceGroupOption[],
    roundingChoice: IChoiceGroupOption[],
    rateTableChoice: IChoiceGroupOption[],
    rateTypeChoice: IChoiceGroupOption[]
    automaticManual: IChoiceGroupOption[]

}

const planSlice = createSlice({
    name: 'PlanSlice',
    initialState: {
        nullablePlanOption: [{ key: '1', text: 'Si\u00A0\u00A0' }, { key: '2', text: 'No' },],
        nullableManagementOption: [{ key: '', text: '' }, { key: '1', text: 'Collettivo', selected: true }, { key: '2', text: 'Individuale' }],
        nullableYesNoChoiceOption: [{ key: '', text: '' }, { key: '1', text: 'Fondo', selected: true }, { key: '2', text: 'Welfare' }],
        planOption: [{ key: '1', text: 'Collettivo\u00A0\u00A0' }, { key: '2', text: 'Individuale' }],
        managementOption: [{ key: '1', text: 'Fondo\u00A0\u00A0' }, { key: '2', text: 'Welfare' }],
        yesNoChoiceOption: [{ key: '1', text: 'Si\u00A0\u00A0' }, { key: '2', text: 'No' }],
        refundFeeOption: [{ key: '1', text: 'OD\u00A0\u00A0' }, { key: '2', text: 'assistito' }],
        profitTypeChoiceOption: [{ key: '1', text: '%\u00A0\u00A0' }, { key: '2', text: 'Fisso' }],
        roundingChoiceOption: [{ key: '1', text: '.00\u00A0\u00A0' }, { key: '2', text: 'Arrotondamento' }],
        rateTableChoiceOption: [{ key: '1', text: 'Default\u00A0\u00A0' }, { key: '2', text: 'SS\u00A0\u00A0' }, { key: '3', text: 'Sanitario' }],
        rateTypeChoiceOption: [{ key: '1', text: 'Piano Sanitario\u00A0\u00A0' }, { key: '2', text: 'Piu Economico' }],
        automaticManualOption: [{ key: '1', text: 'Manuale' }, { key: '2', text: 'Automatico' }],
    },
    reducers: {},
})

export const nullablePlanOption = (state: RootState) => state.plan.nullablePlanOption
export const nullableManagementOption = (state: RootState) => state.plan.nullableManagementOption
export const nullableYesNoChoiceOption = (state: RootState) => state.plan.nullableYesNoChoiceOption
export const planOption = (state: RootState) => state.plan.planOption
export const managementOption = (state: RootState) => state.plan.managementOption
export const yesNoChoiceOption = (state: RootState) => state.plan.yesNoChoiceOption
export const refundFeeOption = (state: RootState) => state.plan.refundFeeOption
export const profitTypeChoiceOption = (state: RootState) => state.plan.profitTypeChoiceOption
export const roundingChoiceOption = (state: RootState) => state.plan.roundingChoiceOption
export const rateTableChoiceOption = (state: RootState) => state.plan.rateTableChoiceOption
export const rateTypeChoiceOption = (state: RootState) => state.plan.rateTypeChoiceOption
export const automaticManualOption = (state: RootState) => state.plan.automaticManualOption

export default planSlice.reducer