import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../app/rootReducer'

import { ResponsePayload } from '../../defs/thunks'

const ResponseSlice = createSlice({
    name: 'Response',
    initialState: { response: { message: '', level: -1 } } as ResponsePayload,
    reducers: {
        setResponse: {
            reducer(state, action: PayloadAction<ResponsePayload>) {
                const { response } = action.payload
                let newResponse = state.response

                newResponse.message = response.message
                newResponse.level = response.level
                newResponse.notified = response.keep ? state.response.notified : response.notified

                state.response = newResponse
            },
            prepare(message, level, notified?, keep?) {
                return { payload: { response: { message: message, level: level, notified: notified, keep: keep } } }
            }
        },
        resetResponse: {
            reducer(state, action: PayloadAction<ResponsePayload>) {
                const { response } = action.payload
                let newResponse = state.response

                newResponse.message = response.message
                newResponse.level = response.level
                newResponse.notified = response.notified ?? state.response.notified

                state.response = newResponse
            },
            prepare(notified?: boolean) {
                return { payload: { response: { message: '', level: -1, notified: notified } } }
            }
        },
    }
})

export const { setResponse, resetResponse } = ResponseSlice.actions

export const currentResponse = (state: RootState) => state.response.response

export default ResponseSlice.reducer