import * as React from 'react';

import { IColumn, IDetailsColumnStyles, ColumnActionsMode } from '@fluentui/react/';
import { Link, Icon } from '@fluentui/react/';
import { FontSizes, } from '@fluentui/react/';

import Theme from '../../themes/Theme';

const BaseColumnStyles: Partial<IDetailsColumnStyles> = {
    root: {
        backgroundColor: Theme.fluent.palette.themePrimary,
        borderTopColor: Theme.fluent.palette.white,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderLeftColor: Theme.fluent.palette.white,
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
        color: Theme.fluent.palette.white,
        fontSize: FontSizes.mini,
        width: '100%',
    },
}

const LeftAlignColumnStyles: Partial<IDetailsColumnStyles> =
    Object.assign({}, { ...BaseColumnStyles })

const CenterAlignColumnStyles: Partial<IDetailsColumnStyles> =
    Object.assign({
        cellTitle: { display: 'inline-block', padding: 0 },
        cellTooltip: { textAlign: 'center' },
    }, { ...BaseColumnStyles })

const RightAlignColumnStyles: Partial<IDetailsColumnStyles> =
    Object.assign({
        cellTitle: { display: 'inline-block', padding: 0 },
        cellTooltip: { textAlign: 'center' },
    }, { ...BaseColumnStyles })

const LeftAlignColumnBase: Partial<IColumn> = {
    className: 'height--auto text-align--left',
    columnActionsMode: ColumnActionsMode.disabled,
    isResizable: false,
    styles: LeftAlignColumnStyles,
}
const CenterAlignColumnBase: Partial<IColumn> = {
    className: 'height--auto text-align--center',
    columnActionsMode: ColumnActionsMode.disabled,
    isResizable: false,
    styles: CenterAlignColumnStyles,
}
const RightAlignColumnBase: Partial<IColumn> = {
    className: 'height--auto text-align--right',
    columnActionsMode: ColumnActionsMode.disabled,
    isResizable: false,
    styles: RightAlignColumnStyles
}

type State = typeof initialState
type Props = {
    kid: string,
    name: string
    fieldName: string
    minWidth: number
    maxWidth?: number
    align: 'left' | 'center' | 'right',
    type: 'data' | 'edit' | 'delete' | 'navigate' | 'icon' | 'download',
    onClick?: any,
    onRenderIcon?: any
}

const initialState = Object.freeze({})

export const getColumn = (props: Props, onEditClick?: any, onDeleteClick?: any, onNavigateClick?: any, onDownloadingClick?: any): IColumn => {

    const onRenderIconDefault = (e: any) => {
        if (e[props.fieldName] === 'Si')
            return <Icon iconName={"Accept"} style={{ color: 'green' }} />;
        else
            return <Icon iconName={"Cancel"} style={{ color: 'red' }} />;
    }

    var columnBase: Partial<IColumn> = {}
    var align = props.align
    var onRender;

    switch (props.type) {
        case 'edit':
            align = 'center'
            onRender = (e: any) => {
                const onClick = props.onClick ?? onEditClick
                return <Link onClick={() => { onClick(e) }}><Icon iconName='Edit' style={{ color: 'orange' }} /></Link>;
            }
            break;
        case 'delete':
            align = 'center'
            onRender = (e: any) => {
                const onClick = props.onClick ?? onDeleteClick
                return <Link onClick={() => { onClick(e) }}><Icon iconName='Cancel' style={{ color: 'red' }} /></Link>;
            }
            break;
        case 'navigate':
            align = 'center'
            onRender = (e: any, data: any) => {
                const onClick = props.onClick ?? onNavigateClick
                return <Link onClick={() => { onClick(e, data) }}><Icon iconName='TextDocument' style={{ color: 'dodgerblue' }} /></Link>;
            }
            break;
        case 'icon':
            align = 'center'
            onRender = props.onRenderIcon ?? onRenderIconDefault
            
            break;
        case 'download':
            align = 'center'
            onRender = (e: any) => {
                const onClick = props.onClick ?? onDownloadingClick
                return <Link onClick={() => { onClick(e) }}><Icon iconName='DownloadDocument' style={{ color: 'dodgerblue' }} /></Link>;
            }
            break;
        case 'data':
        default:
            break;
    }

    switch (align) {
        case 'right':
            columnBase = { ...RightAlignColumnBase }
            break;
        case 'center':
            columnBase = { ...CenterAlignColumnBase }
            break;
        case 'left':
        default:
            columnBase = { ...LeftAlignColumnBase }
            break;
    }

    return Object.assign({ key: props.kid, name: props.name, fieldName: props.fieldName, minWidth: props.minWidth, maxWidth: props.maxWidth, onRender: onRender }, { ...columnBase })
}

export class DataColumn extends React.PureComponent<Props, State> {
    static defaultProps = Object.freeze({ key: 'NO_KEY', name: 'NO_NAME', fieldName: 'NO_FIELDNAME', minWidth: 0, align: 'left', type: 'data' })

    public render() { return null; }
}

export default DataColumn;