import * as React from 'react';

import { useDispatch } from 'react-redux'
import { setOperation } from '../operation/operationSlice';

import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
const { useEffect } = React

export default (props: { controller: string, items: any }) => {
    const dispatch = useDispatch()
    const roles = useSecurityRoles()
    const onRowClick = (e: any, data: any) => {
        e.preventDefault()
        dispatch(setOperation("read", props.controller, { index: data }))
    }

    useEffect(() => {
        if (props?.items?.length === 1 && roles.isInsuredUser) {
            dispatch(setOperation("read", props.controller, { index: 0 }))
        }
    }, [props?.items])

    return (
        <>
            <DataList items={props.items} onRowClick={onRowClick} >
                <DataColumn kid='column1' name='Codice' fieldName='code' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column2' name='Codice Fiscale' fieldName='fiscalcode' minWidth={150} maxWidth={150} align='left' />
                <DataColumn kid='column3' name='Cognome' fieldName='lastname' minWidth={150} maxWidth={250} align='left' />
                <DataColumn kid='column4' name='Nome' fieldName='firstname' minWidth={150} align='left' />
            </DataList>
        </>
    );
}