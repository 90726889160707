import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    return (
        <>
            <DataList {...props} items={props.items} modalwidth={props.modalwidth} modaltitle={props.modaltitle} modalController={props.controller} modal={props.modal} state={props.state}>
                <DataColumn kid='columnE' name='' fieldName='delete' minWidth={10} maxWidth={10} type='delete' />
                <DataColumn kid='column1' name='Nome File' fieldName='fileName' minWidth={250} align='left' />
                <DataColumn kid='column2' name='Data Inserimento' fieldName='insertionDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
