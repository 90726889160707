import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';

import { client } from '../../api/client';
import { AppDispatch } from '../../app/store';
import { setResponse } from '../response/responseSlice';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';
import { isLocked, lock, unlock } from '../operation/lockSlice';

import { setModality } from '../modality/modalitySlice';
import { security } from '../security/thunks/securityThunk';
import { itemData, itemValidation } from '../operation/thunks/itemThunk';
import { resultLoad } from '../operation/thunks/resultThunk';
import { filterForm } from '../operation/thunks/filterThunk';

import { States } from '../../pages/Reservation';

import { DefaultButton, Dialog, DialogFooter, DialogType, IButtonProps, PrimaryButton } from '@fluentui/react';

import Text from '../../components/controls/Text';
import Option from '../../components/controls/Option';
import Row from '../../components/controls/grid/Row';
import Grid from '../../components/controls/grid/Grid';
import { setOperation } from '../operation/operationSlice';

export const proposeNewAppointment = createAsyncThunk(
    'item/ProposeNewAppointment',
    async (args: { ID: string, createNewQuote: any, _newAppointmentDate: any, _newAppointmentTime: any, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: { id: args.ID, createNewQuote: args.createNewQuote, newAppointmentDate: args._newAppointmentDate, newAppointmentTime: args._newAppointmentTime } }, 'ProposeNewAppointment', args.token)
        return response
    }
)
export const accept = createAsyncThunk(
    'item/Accept',
    async (args: { ID: string, createNewQuote: any, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: { id: args.ID, createNewQuote: args.createNewQuote } }, 'Accept', args.token)
        return response
    }
)
export const reject = createAsyncThunk(
    'item/Reject',
    async (args: { ID: string, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: { id: args.ID } }, 'Reject', args.token)
        return response
    }
)
export const cancel = createAsyncThunk(
    'item/Cancel',
    async (args: { ID: string, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: { id: args.ID } }, 'Cancel', args.token)
        return response
    }
)
const getQuoteManagement = createAsyncThunk(
    'item/GetQuoteManagement',
    async (args: { ID: any, token: string | undefined }) => {
        const response = await client.post("Reservation", { form: { id: args.ID } }, 'GetQuoteManagement', args.token)
        return response
    }
)

export type Props = {
    controller: string,
    currentState: States | undefined,
    acceptDisabled?: boolean,
    showDialogOnAccept?: boolean,
    page?: any
}

export default (props: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const credential = useSelector(security)?.credential
    const _isLocked: boolean = false

    const iForm = useSelector(itemData)
    const args = { ID: iForm.find(i => i.key === props.controller)?.value?.data?.id, token: credential?.token }

    const roles = useSecurityRoles()
    const isInsured: boolean = roles.isInsuredUser

    const validation = useSelector(itemValidation)

    const hasQuote = props.page.item?.state?.data?.hasQuoteManagement || !!props.page.item?.state?.data?.quoteManagementID

    const currentState = props.currentState

    const dentalElement = props.page.item?.state?.data?.dentalElement?.[0] !== ""
    const hasAppointment = !!props.page.item?.state?.data?.appointmentDate

    const onClickNewPropose = (e: any) => {
        e.preventDefault()
        toggleHideDialogNewPropose()
    }
    const [hideDialogNewPropose, { toggle: toggleHideDialogNewPropose }] = useBoolean(true);
    const dialogContentPropsNewPropose = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Procedo con la nuova proposta di appuntamento?',
    };

    const onClickAccept = (e: any) => {
        e.preventDefault()
        toggleHideDialogAccept()
    }
    const [hideDialogAccept, { toggle: toggleHideDialogAccept }] = useBoolean(true);
    const dialogContentPropsAccept = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Accetto la proposta di appuntamento?',
    };

    const onClickReject = (e: any) => {
        e.preventDefault()
        toggleHideDialogReject()
    }
    const [hideDialogReject, { toggle: toggleHideDialogReject }] = useBoolean(true);
    const dialogContentPropsReject = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: 'Rifiuto la proposta di appuntamento?',
    };

    const onClickCancel = (e: any) => {
        e.preventDefault()
        toggleHideDialogCancel()
    }
    const [hideDialogCancel, { toggle: toggleHideDialogCancel }] = useBoolean(true);
    const dialogContentPropsCancel = {
        type: DialogType.normal,
        title: 'Richiesta conferma',
        closeButtonAriaLabel: 'Chiudi',
        subText: isInsured ? "Procedo con l'annullamento della prenotazione?" : "",
    };

    const dispatchReload = (response: any) => {
        dispatch(setOperation("reload", props.controller))
        return response
    }
    const dispatchMessage = (response: any) => {
        dispatch(setResponse(response.payload.response.message, response.payload.response.level))
    }

    const onExecuteNewPropose = (e: any) => {
        toggleHideDialogNewPropose()
        dispatch(lock())

        let mandatory = validation.find(v => v.key === "Reservation")?.value.mandatory
        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere, compilare i campi richiesti", 5, true))
            dispatch(unlock())
            return;
        }
        dispatch(proposeNewAppointment({ ...args, createNewQuote, _newAppointmentDate, _newAppointmentTime })).then(dispatchMessage).then(dispatchReload)
    }

    const onExecuteAccept = (e: any) => {
        toggleHideDialogAccept()
        dispatch(lock())

        let mandatory = validation.find(v => v.key === "Reservation")?.value.mandatory

        if (mandatory === false) {
            dispatch(setResponse("Impossibile procedere, compilare i campi richiesti", 5, true))
            dispatch(unlock())
            return;
        }

        dispatch(accept({ ...args, createNewQuote })).then(dispatchMessage).then(dispatchReload)
    }

    const onExecuteReject = (e: any) => {
        toggleHideDialogReject()
        dispatch(lock())
        dispatch(reject(args)).then(dispatchMessage).then(dispatchReload)
    }

    const onExecuteCancel = (e: any) => {
        toggleHideDialogCancel()
        dispatch(lock())
        dispatch(cancel({ ...args })).then(dispatchMessage).then(dispatchReload)
    }

    const [createNewQuote, setCreateNewQuote] = React.useState<string | number | undefined>(undefined);
    const [buttonsToRender, setButtonsToRender] = React.useState<JSX.Element[]>([]);

    const dialogStyles = { main: { maxWidth: 450 } };
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId, dialogStyles],
    );
    const ButtonProps: IButtonProps = {
        styles: {
            root: {
                marginLeft: 4,
                marginRight: 4
            }
        }
    }

    const _propose = <PrimaryButton {...ButtonProps} text='proponi appuntamento' onClick={onClickNewPropose} disabled={_isLocked} />
    const _accept = <PrimaryButton {...ButtonProps} text='accetta appuntamento' onClick={onClickAccept} disabled={_isLocked} />
    const _reject = <PrimaryButton {...ButtonProps} text='chiedi altra data' onClick={onClickReject} disabled={_isLocked} />
    const _cancel = <PrimaryButton {...ButtonProps} text='annulla richiesta prenotazione' onClick={onClickCancel} disabled={_isLocked} />

    const [_newAppointmentDate, _setNewAppointmentDate] = useState<string>('')
    const [_newAppointmentTime, _setNewAppointmentTime] = useState<string>('')

    const dialogs = <>
        <Dialog hidden={hideDialogNewPropose} onDismiss={toggleHideDialogNewPropose} dialogContentProps={dialogContentPropsNewPropose} modalProps={modalProps} maxWidth={800}>
            <Grid>
                <Row>
                    <Text className="ms-sm6"
                        label="data"
                        name="newAppointmentDate"
                        value={_newAppointmentDate}
                        required
                        onChange={(_name, value, _req) => _setNewAppointmentDate(value?.toString() ?? '')}
                        type='calendar'
                        dateRange={{ minDate: new Date().toString() }}
                    />
                    <Text className="ms-sm4"
                        label="ora"
                        name="newAppointmentTime"
                        value={_newAppointmentTime}
                        required
                        onChange={(_name, value, _req) => _setNewAppointmentTime(value?.toString() ?? '')}
                        type='time' />
                </Row>
                {!isInsured && hasQuote && <>
                    <Row>
                        <Option
                            className="ms-sm12"
                            label="Procedere con la creazione di un nuovo piano di cure?"
                            options={[{ key: '1', text: 'si' }, { key: '2', text: 'no' }]}
                            type="horizontal"
                            required
                            onChange={(_name, value, _req) => { setCreateNewQuote(value) }}
                            selectedKey={createNewQuote} />
                    </Row>
                </>}
            </Grid>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteNewPropose} text='procedi' disabled={!_newAppointmentDate || !_newAppointmentTime} />
                <DefaultButton onClick={toggleHideDialogNewPropose} text="chiudi" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={hideDialogAccept} onDismiss={toggleHideDialogAccept} dialogContentProps={dialogContentPropsAccept} modalProps={modalProps} maxWidth={800}>
            {!isInsured && hasQuote && <>
                <Option
                    className="ms-sm12"
                    label="Procedere con la creazione di un nuovo piano di cure ?"
                    options={[{ key: '1', text: 'si' }, { key: '2', text: 'no' }]}
                    type="horizontal"
                    required
                    onChange={(_name, value, _req) => { setCreateNewQuote(value) }}
                    selectedKey={createNewQuote} />
            </>}
            <DialogFooter>
                <PrimaryButton onClick={onExecuteAccept} text='accetta' disabled={isInsured ? false : (hasQuote ? !createNewQuote : false)} />
                <DefaultButton onClick={toggleHideDialogAccept} text="chiudi" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
        <Dialog hidden={hideDialogReject} onDismiss={toggleHideDialogReject} dialogContentProps={dialogContentPropsReject} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteReject} text='rifiuta' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogReject} text="chiudi" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>

        <Dialog hidden={hideDialogCancel} onDismiss={toggleHideDialogCancel} dialogContentProps={dialogContentPropsCancel} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onExecuteCancel} text='annulla richiesta prenotazione' disabled={_isLocked} />
                <DefaultButton onClick={toggleHideDialogCancel} text="chiudi" disabled={_isLocked} />
            </DialogFooter>
        </Dialog>
    </>
    React.useEffect(() => {
        var buttons: any = []
        switch (currentState) {
            case States.Sent:
                if (isInsured) {
                    buttons = [_cancel]
                } else if (hasAppointment) {
                    buttons = [_propose, _accept, _cancel]
                } else {
                    buttons = [_propose, _cancel]
                }
                break;

            case States.AwaitingContact:
                if (isInsured) {
                    buttons = [_cancel]

                } else {
                    buttons = [_propose, _cancel]
                }
                break;

            case States.AwaitingConfirm:
                if (isInsured) {
                    buttons = [_accept, _reject, _cancel]
                } else {
                    buttons = [_cancel]
                }
                break;

            case States.Accepted:
            default:
                buttons = []
                break;
        }

        setButtonsToRender(buttons)

    }, [currentState, dentalElement])

    return (<>
        <div className="ms-Grid-col ms-sm6" style={{ paddingTop: 22, textAlign: "right" }}>
            {buttonsToRender}
            {dialogs}
        </div>
    </>)
}