import * as React from 'react';
import DataList from '../../components/controls/datagrid/DataList';
import DataColumn from '../../components/controls/datagrid/DataColumn';

import { useDispatch, useSelector } from 'react-redux'; import { AppDispatch } from '../../app/store';

import { security } from '../security/thunks/securityThunk';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { setOperation } from '../operation/operationSlice';
import { itemData } from '../operation/thunks/itemThunk';
export const gotoAttiva = createAsyncThunk(
    'item/gotoAttiva',
    async (args: { rateTableHealthServiceAssociateID: string, token: string | undefined, childController: string }) => {
        const response = await client.post(args.childController, { form: { id: args.rateTableHealthServiceAssociateID } }, 'Attiva', args.token)
        return response
    }
)

type Props = {
    items: any,
    controller: string,
    modal?: React.ReactNode,
    modaltitle?: string,
    modalwidth?: string,
    state?: any
}

export default (props: Props) => {
    const parentController = "RateTable"

    const dispatch: AppDispatch = useDispatch();
    const iForm = useSelector(itemData)
    const credential = useSelector(security)?.credential
    const onExecuteAttiva = () => {
        dispatch(gotoAttiva({ rateTableHealthServiceAssociateID: iForm.find(i => i.key === props.controller)?.value.data.id, token: credential.token, childController: props.controller }))
            .then(() => dispatch(setOperation("reload", parentController)))
    }

    return (
        <>
            <DataList items={props.items} modaltitle={props.modaltitle} modalwidth={props.modalwidth} modalController={props.controller} modal={props.modal} state={props.state} editLabel="Riattiva" onEdit={onExecuteAttiva}>
                <DataColumn kid='column1' name='Prestazione' fieldName='healthservice' minWidth={120} maxWidth={880} align='left' />
                <DataColumn kid='column2' name='Tariffa' fieldName='rate' minWidth={120} maxWidth={120} align='right' />
                <DataColumn kid='column3' name='Elementi Dentali' fieldName='dentalElement' minWidth={120} maxWidth={120} align='left' />
                <DataColumn kid='column4' name='Data fine validità' fieldName='endDate' minWidth={120} maxWidth={120} align='center' />
                <DataColumn kid='columnF' name='' fieldName='edit' minWidth={10} maxWidth={10} type='edit' />
            </DataList>
        </>
    );
}
