import { ComboBox, FontSizes, IComboBoxOption, IComboBoxProps } from '@fluentui/react/'
import * as React from 'react'
import { useEffect } from 'react'
import { FontFaces } from '../../defs/fonts'
import Theme from '../themes/Theme'
import { useSelector, useDispatch } from 'react-redux'
import { currentResponse } from '../../features/response/responseSlice'
import { itemLookup, itemForm, itemData } from '../../features/operation/thunks/itemThunk'
import { AppDispatch } from '../../app/store'
import { security } from '../../features/security/thunks/securityThunk'
import { currentModality } from '../../features/modality/modalitySlice'
import { filterLookup, filterForm, filterData } from '../../features/operation/thunks/filterThunk'

const ComboBoxProps: Partial<IComboBoxProps> = {
    styles: {
        root: {
            paddingTop: 3,
            paddingBottom: 4,
        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 2,
            paddingLeft: 4,
        },
    }
}
const ErrorComboBoxProps: Partial<IComboBoxProps> = {
    styles: {
        root: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            borderColor: Theme.leap.customization.error.borderColor,
            borderWidth: 1,
            paddingTop: 3,
            paddingBottom: 4,

        },
        label: {
            color: Theme.leap.main.title.color,
            fontFamily: FontFaces.montserrat,
            fontSize: FontSizes.medium,
            fontWeight: 500,
            paddingBottom: 2,
            paddingLeft: 4,
        },
        input: {
            backgroundColor: Theme.leap.customization.error.backgroundColor,
            borderColor: Theme.leap.customization.error.borderColor,
            borderWidth: 1,
        },
    }
}

type Props = {
    name: string,
    className: string,
    label: string,
    required?: boolean,
    mandatory?: (name: string) => boolean,
    selectedKey?: string | number | any,
    options: IComboBoxOption[],
    onChange?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    onInit?: (name: string | undefined, value: string | number | undefined, required?: boolean | undefined) => void,
    autocomplete?: ('on' | 'off'),
    allow?: boolean,
    children?: { name: string, lookup: string, controller: string, required?: boolean, defaultValue?: any }[],
    disabled?: boolean,
    hidden?: boolean
    controller?: string
    parentController?: string
}

export default (props: Props) => {
    const iForm = useSelector(itemForm)
    const fForm = useSelector(filterForm)
    const iData = useSelector(itemData);
    const fData = useSelector(filterData);
    const dItem = iData.find(d => d.key === props.controller)?.value
    const dFilter = fData.find(d => d.key === props.controller)?.value

    const dispatch: AppDispatch = useDispatch();
    const modality = useSelector(currentModality)
    const response = useSelector(currentResponse)

    const securityContext = useSelector(security)
    let token = securityContext?.credential?.token

    const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>('')

    let mandatory = true
    if (props.mandatory && props.name) {
        mandatory = props.mandatory(props.name)
    }

    const comboBoxStyle = response.notified && !mandatory ? { ...ErrorComboBoxProps } : { ...ComboBoxProps }

    useEffect(() => {
        setSelectedKey(props.selectedKey)

        if (props.onInit) {
            props.onInit(props.name, props.selectedKey, props.required)
        }
    }, [props.selectedKey, props.required, props.disabled])

    const onChange = (event: any, option: any) => {
        setSelectedKey(option?.key)

        if (props.onChange)
            props.onChange(props.name, option?.key, props.required)

        if (props.children) {
            let parentItemForm: any = {}
            let parentItemData: any = {}
            let parentItemPage: any = {}

            if (props.parentController) {
                parentItemForm = iForm.find(i => i.key === props.parentController)?.value
                parentItemData = iData.find(d => d.key === props.parentController)?.value

                if (parentItemData?.metadata?.page) {
                    Object.assign(parentItemPage, parentItemData?.metadata?.page)

                    if (parentItemPage.index > 0) {
                        parentItemPage.index = parentItemPage.index - 1
                    } else {
                        parentItemPage.index = 0
                    }
                }
            }

            const form = iForm.find(i => i.key === props.controller)?.value
            const page = dItem?.metadata?.page
            const body = {
                form: {
                    ...form, [props.name]: option?.key
                },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: page
            }

            const formF = fForm.find(i => i.key === props.controller)?.value
            const pageF = dFilter?.metadata?.page
            const bodyF = {
                form: { ...formF, [props.name]: option?.key },
                parent: {
                    form: parentItemForm,
                    page: parentItemPage
                }, page: pageF
            }

            if (modality === "filter") {
                props.children.forEach((c) => {
                    dispatch(filterLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: body, token: token }))
                })
            }
            else if (modality === "item" || modality === "new") {
                props.children.forEach((c) => {
                    dispatch(itemLookup({ controller: c.controller, target: c.name, lookup: c.lookup, body: body, token: token }))
                })
            }
        }
    }
    const hidden = props.hidden ? { display: "none" } : undefined

    return <>
        <div className={"ms-Grid-col " + props.className} style={hidden}>
            <ComboBox {...comboBoxStyle} label={props.label} disabled={props.disabled} required={props.required} useComboBoxAsMenuWidth selectedKey={selectedKey} options={props.options} onChange={onChange} autoComplete='off' allowFreeform={props.allow === undefined ? false : props.allow} />
        </div>
    </>
}