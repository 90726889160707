import * as React from 'react';

import { useSelector } from 'react-redux';

import { resultData } from '../../../../features/operation/thunks/resultThunk';

import { Stack, StackItem, Text, Icon } from '@fluentui/react/';

import { ToolbarType } from '../../../../defs/toolbars'

import CommandToolbar from '../../../controls/toolbar/CommandToolbar'
import PagerToolbar from '../../../controls/toolbar/PagerToolbar'

import Separator from '../../customization/Separator';
import Row from '../Row';

import Theme from '../../../themes/Theme';
import { sectionTheme } from '../../../themes/Fonts';

const headerToolbarSectionStyles: React.CSSProperties = {
    padding: Theme.leap.main.content.section.header.topPadded,
};

const titleStyles: React.CSSProperties = {
    backgroundColor: Theme.leap.main.content.section.backgroundColor,
    color: Theme.fluent.palette.neutralPrimaryAlt,
    display: "inline-block",
    lineHeight: "44px",
    paddingRight: "12px",
};

type Props = {
    title?: string, toolbar?: ToolbarType, controller: string, parentController?: string, modal?: React.ReactNode, modaltitle?: string, modalwidth?: string, state?: any, readOnly?: boolean
}

export default (props: Props) => {
    return <>
        <Row isTitleRow>
            <div className="ms-Grid-col ms-sm12" style={headerToolbarSectionStyles}>
                <Separator>
                    <Stack horizontal style={{ width: "100%" }}>
                        <StackItem className="left-command-toolbar" align="start">
                            <Text style={titleStyles} theme={sectionTheme}>{props.title}</Text>
                        </StackItem>
                        <StackItem className="left-command-toolbar" grow align="start">
                            {
                                !props.readOnly &&
                                <CommandToolbar type="grid" controller={props.controller} modal={props.modal} modaltitle={props.modaltitle} modalwidth={props.modalwidth} state={props.state} />
                            }
                        </StackItem>
                        <StackItem className="right-command-toolbar" align="end">
                            <PagerToolbar controller={props.controller} parentController={props.parentController} useMetadata />
                        </StackItem>
                    </Stack>
                </Separator>
            </div>
        </Row>
    </>
};