import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { currentModality } from '../features/modality/modalitySlice';
import { setOperation } from '../features/operation/operationSlice';
import { filterData, setFormFilter, setRequiredFilter } from '../features/operation/thunks/filterThunk';
import { itemData, setFormItem, setRequiredItem } from '../features/operation/thunks/itemThunk';
import { resultData } from '../features/operation/thunks/resultThunk';
import { ModalityType } from '../defs/modalities';
import { authorized } from '../features/security/thunks/securityThunk';

export const useLeapState = (controller: string, doInit?: boolean, defaultModality?: ModalityType) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const modality = useSelector(currentModality)

    const fData = useSelector(filterData)
    const rData = useSelector(resultData)
    const iData = useSelector(itemData)
    const fState = fData.find(d => d.key === controller)?.value
    const rState = rData.find(d => d.key === controller)?.value
    const iState = iData.find(d => d.key === controller)?.value

    const [filterValues, setFilterValues] = useState({} as any)
    const [filterMandatoryState, setFilterMandatoryState] = useState({} as any)
    //const [resultValues, setResultValues] = useState({})
    const [itemValues, setItemValues] = useState({} as any)
    const [itemMandatoryState, setItemMandatoryState] = useState({} as any)

    const [init, setInit] = useState(false)

    const isAuthorized = authorized
    useEffect(() => {
        if (doInit === false) return
        if (init || !isAuthorized) return

        if (location.search === '?item') {
            dispatch(setOperation("read", controller))
        } else if (location.search === '?result') {
            dispatch(setOperation("search", controller))
        } else {
            switch (defaultModality) {
                case "item":
                    dispatch(setOperation("read", controller))
                    break;
                case "new":
                    dispatch(setOperation("new", controller))
                    break;
                case "result":
                    dispatch(setOperation("search", controller))
                    break;
                case "filter":
                default:
                    dispatch(setOperation("filter", controller))
                    break;
            }
        }

        setInit(true)

    }, [doInit, isAuthorized])

    const evalRequired = (value: string | number | [] | undefined, required?: boolean | undefined): boolean => {
        return !(required && (value === undefined || value === null || value === "" || value.toString().trim() === "" || (Array.isArray(value) && value?.length === 0)))
    }

    useEffect(() => {
        let mandatory = true
        for (let key in filterValues) {
            let keyValue = filterValues[key]?.value
            let keyRequired = filterValues[key]?.required

            if (evalRequired(keyValue, keyRequired)) continue

            mandatory = false
            break
        }
        dispatch(setRequiredFilter(controller, { name: "mandatory", value: mandatory }))
    }, [filterValues]);

    useEffect(() => {
        let mandatory = true
        for (let key in itemValues) {
            let keyValue = itemValues[key]?.value
            let keyRequired = itemValues[key]?.required

            if (evalRequired(keyValue, keyRequired)) continue

            mandatory = false
            break
        }
        dispatch(setRequiredItem(controller, { name: "mandatory", value: mandatory }))
    }, [itemValues]);

    return {
        controller: controller,
        modality: modality,
        filter: {
            data: fData,
            state: fState,
            values: filterValues
        },
        result: {
            data: rData,
            state: rState
        },
        item: {
            data: iData,
            state: iState,
            values: itemValues
        },
        data: () => {
            if (modality === "filter") {
                return fState?.data
            }
            else if (modality === "result") {
                return rState?.data
            }
            else if (modality === "item" || modality === "new") {
                return iState?.data
            }
        },
        lookups: () => {
            if (modality === "filter") {
                return fState?.lookups
            }
            else if (modality === "result") {
                return rState?.lookups
            }
            else if (modality === "item" || modality === "new") {
                return iState?.lookups
            }
        },
        oninit: (name: any, value: any, required?: boolean | undefined) => {
            if ((modality === "filter")) {
                setFilterValues((values: any) => { return { ...values, [name]: { value: value, required: required } } })

                dispatch(setFormFilter(controller, { name, value }))

                if (evalRequired(value, required)) {
                    setFilterMandatoryState(Object.assign(filterMandatoryState, { [name]: { value: true } }))
                } else {
                    setFilterMandatoryState(Object.assign(filterMandatoryState, { [name]: { value: false } }))
                }
            } else if (modality === "result") {
            } else if ((modality === "item" || modality === "new")) {
                setItemValues((values: any) => { return { ...values, [name]: { value: value, required: required } } })
                dispatch(setFormItem(controller, { name, value }))

                if (evalRequired(value, required)) {
                    setItemMandatoryState(Object.assign(itemMandatoryState, { [name]: { value: true } }))
                } else {
                    setItemMandatoryState(Object.assign(itemMandatoryState, { [name]: { value: false } }))
                }
            }
        },
        onchange: (name: any, value: any, required?: boolean | undefined) => {
            if (modality === "filter") {
                setFilterValues((values: any) => { return { ...values, [name]: { value: value, required: required } } })
                dispatch(setFormFilter(controller, { name, value }))

                if (evalRequired(value, required)) {
                    setFilterMandatoryState(Object.assign(filterMandatoryState, { [name]: { value: true } }))
                } else {
                    setFilterMandatoryState(Object.assign(filterMandatoryState, { [name]: { value: false } }))
                }
            } else if (modality === "result") {
            } else if (modality === "item" || modality === "new") {
                setItemValues((values: any) => { return { ...values, [name]: { value: value, required: required } } })
                dispatch(setFormItem(controller, { name, value }))

                if (evalRequired(value, required)) {
                    setItemMandatoryState(Object.assign(itemMandatoryState, { [name]: { value: true } }))
                } else {
                    setItemMandatoryState(Object.assign(itemMandatoryState, { [name]: { value: false } }))
                }
            }
        },
        getMandatory: (name: string) => {
            if (modality === "filter") {
                return filterMandatoryState[name]?.value ?? true
            }
            else if (modality === "result") {
                return true
            }
            else if (modality === "item" || modality === "new") {
                return itemMandatoryState[name]?.value ?? true
            }
        }
    }
}
