import * as React from 'react';
import { useLeapState } from '../../hooks/useLeapState';
import { useLeapData } from '../../hooks/useLeapData';

import { IComboBoxOption } from '@fluentui/react';

import Grid from '../../components/controls/grid/Grid';
import Section from '../../components/controls/grid/Section';
import Row from '../../components/controls/grid/Row';

import Text from '../../components/controls/Text';
import DentalChart from '../../components/controls/dental/DentalChart';
import MultiBox from '../../components/controls/MultiBox';

import { DentalElements } from '../../components/controls/dental/dentalElements';
import SearchBox from '../../components/controls/SearchBox';
import ComboBox from '../../components/controls/ComboBox';
import { useSecurityRoles } from '../../hooks/useSecurityRoles';

type Props = {
    state: any,
    controller: any,
    parentController: any
}

export default (props: Props) => {
    const page = useLeapState(props.controller, false)
    const parentPage = useLeapState(props.parentController, false)    

    const appointmentDate = useLeapData("appointmentDate", "data appuntamento", page)
    const branchID = useLeapData("branchID", "branca", page, "branches")
    const healthServiceID = useLeapData("healthServiceID", "prestazione", page, "healthServices")
    const dentalElement = useLeapData("dentalElement", "elemento dentale", page)

    const amount = useLeapData("amount", "costo", page)
    const freeRate = useLeapData("freeRate", "freeRate", page, "canFreeRate")

    const voucherCode = useLeapData("voucherCode", "codice voucher", page)
    const voucherEndDate = useLeapData("voucherEndDate", "data scadenza voucher ", page)

    const canFreeRate = page?.item?.state?.lookups?.["canFreeRate"]?.length > 0 ? page.item.state.lookups?.["canFreeRate"][0] : false

    const items: IComboBoxOption[] = DentalElements
    const selectedTeeth = page.item.values?.dentalElement ? page.item.values.dentalElement.value : (dentalElement ? (dentalElement.value ?? []) : []);

    const isONHC = useSecurityRoles()?.isONHC

    const planType_item: string = parentPage.item?.state?.data?.leap_PlanManagementID;

    return (
        <>
            <Grid>
                <Section>
                    <Row>
                        <Text {...appointmentDate} type="calendar" className="ms-sm3" />
                        <ComboBox {...branchID} className="ms-sm9" children={[{ name: "healthServiceID", lookup: "healthServices", controller: props.controller }]} />
                    </Row>
                    <Row>
                        <SearchBox {...healthServiceID} parentController={props.parentController} className="ms-sm12" children={[{ ...freeRate }]} required />
                    </Row>
                    <Row>
                        <MultiBox {...dentalElement} className="ms-sm12" options={items} selectedKey={selectedTeeth} children={[{ ...freeRate }]} required />
                    </Row>
                    <Row>
                        <Text {...amount} className="ms-sm2" type="number" hidden={!canFreeRate} required={canFreeRate} />
                    </Row>
                    {isONHC && planType_item === "2" && page.modality === "item" && <>
                        <Row>
                            <Text {...voucherCode} className="ms-sm3" maxlength={100} />
                            <Text {...voucherEndDate} className="ms-sm3" type="calendar" />
                        </Row>
                    </>}
                </Section>
                <Section title="elementi dentali">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Row>
                            <DentalChart {...dentalElement} parentController={props.parentController} selectionMode="multiple" value={selectedTeeth} children={[{ ...freeRate }]} required />
                        </Row>
                    </div>
                </Section>
            </Grid>
        </>
    );
}